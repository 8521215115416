import React, { useState } from "react";
import { toast } from "react-toastify";
import useTheme from "hooks/useTheme";
import Button from "components/Button/Button";
import Storage from "classes/Storage/Storage";
import { t } from "i18next";

export default function useSelect(forceValue, custom_key) {
	const storage = new Storage(null, null, sessionStorage);
	const key = custom_key || "selected";

	const getSelected = () => {
		let items = storage.getItem(key);
		if (!items?.length)
			return ([]);
		return (items?.length ? items : []);
	};
	// eslint-disable-next-line
	const [selected, setSelected] = useState(forceValue ? forceValue : getSelected());
	const {theme} = useTheme();

	const confirmDelete = (onOk, onCancel, text, items) => {
		let prom = new Promise((resolve, reject) => {
			toast(
				<ConfirmDeleteToast
					onOk={() => resolve((onOk ? onOk() : false))}
					onCancel={() => resolve((onCancel ? onCancel() : false))}
					items={items}
				>
					{text}
				</ConfirmDeleteToast>, {
					autoClose: false,
					theme: theme ? theme : "light"
				}
			);
			return (false);
		});
		return (prom);
	};

	const saveSelected = (items) => {
		storage.setItem(key, items);
		setSelected(items);
	};

	return ({
		selected: getSelected(),
		setSelected: saveSelected,
		confirmDelete
	});
}

export function ConfirmDeleteToast(props) {
	const {onOk, onCancel} = props;

	function handleCancel() {
		if (onCancel)
			onCancel();
		return (false);
	}

	function handleOk() {
		if (onOk)
			onOk();
	}

	return (
		<div className='CloseEditToast d-flex flex-column'>
			{props.children}
			<div className='d-flex justify-content-between align-items-stretch'>
				{onCancel && <Button className='btn btn-red me-2' onClick={() => handleCancel()}>{t("Commons.CANCEL")}</Button>}
				{onOk && <Button className='btn btn-green' onClick={() => handleOk()}>{t("Commons.DELETE")}</Button>}
			</div>
		</div>
	);
}

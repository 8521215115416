import React from "react";
import {useQuery} from "react-query";
import Moment from "react-moment";
import "moment/locale/fr";
import genRequest from "includes/request";
import "./EventsList.css";
import { t } from "i18next";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import useUnread from "classes/Accounts/hooks/useUnread";
import { NewLabel } from "components/Tags/Label/TagLabel";
import formatDate from "includes/formatDate";

export default function EventsList(props) {

	let events = (props.events || []);

	if (!events.length)
	{
		return (
			<div className="px-4 pb-2 pt-3">
				{props.date && <Moment date={props.date} locale={"fr"} format={"DD/MM/YYYY"}/>}
				<div className="events-list-ph mt-2">{t("Events.NO_EVENT")}</div>
			</div>
		);
	}

	events = events.sort((a, b) => {
		if ( a.start < b.start)
			return (-1);
		return (1);
	});

	return (
		<div className={"EventsList px-4 pb-2 pt-3" + (props.className ? " " + props.className : "")}>
			{props.date && <Moment date={props.date} locale={"fr"} format={"DD/MM/YYYY"}/>}
			<div className="events-list">
				{
					events.map((event) => {
						return (
							<EventElem
								key={event.id}
								onClick={() => props.onItemClick(event)}
								eventId={event.id}
								color={event.backgroundColor}
								start={event.start}
								end={event.end}
								allDay={event.allDay}
								title={event.title}
								extendedProps={event.extendedProps}
							/>
						);
					})
				}
			</div>
		</div>
	);
}

function EventElem(props) {

	const {isReaded } = useUnread();
	const {isLoading, data} = useQuery(
		["Posts/PostOfAnimation", props.eventId],
		() => genRequest("Posts/PostOfAnimation/" + props.eventId),
		{
			enabled: (props.eventId ? true : false),
			retry: 0
		}
	);

	if (isLoading)
		return (
			<div className="event-loading loading">
				<div></div>
			</div>
		);

	let readed_event = isReaded("NotViewedEvent", parseInt(props.eventId));

	const isMultipleDay = () => {
		let start = formatDate(props.start).split("T")[0];
		let end = formatDate(props.end).split("T")[0];
		return (!(start === end));
	};

	let format = (isMultipleDay() ? t("Events.DATE_TIME_FORMAT") : "hh:mm");
	return (
		<div className="event-cont" onClick={props.onClick}>
			<div className="event-date-cont">
				{
					props.allDay ?
						t("Events.ALL_DAY")
						:
						<>
							<div className="event-start-date">
								{props.start && <Moment date={props.start} format={format}/>}
							</div>
							<div className="event-end-date">
								{props.end && <Moment date={props.end} format={format}/>}
							</div>
						</>
				}
			</div>
			<div className="event-color-bullet mx-3 p-1" style={{backgroundColor: props.color}}></div>
			<div className="event-content-cont py-3 ml-2">
				<div className="event-content-left">
					<div className="d-flex align-items-center">
						{!readed_event && <NewLabel className="me-1"/>}
						<div className="event-name">
							{data?.title || props.title}
						</div>
					</div>
					<div className="event-worked-on">
						{data?.Txt}
					</div>
				</div>
				<div className="event-content-right">
					<QueryTagsList
						queryKey={["Categories", "CategoriesOfAnimation", props.eventId]}
						queryFn={() => genRequest("Categories/CategoriesOfAnimation/" + props.eventId).catch(() => {})}
					/>
				</div>
			</div>
		</div>
	);
}

import React, { useState } from "react";
import { usePopper } from "react-popper";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import { t } from "i18next";
import genRequest from "includes/request";
import "./Targets.css";
import TagLabel from "components/Tags/Label/TagLabel";
import noAccent from "includes/no_accents";
import useAccount from "classes/Accounts/hooks/useAccount";
/* eslint-disable */
const DocsTargetsCell = React.memo(({ item }) => {
	const { account } = useAccount();
	const { DocumentId, IsTargetDoc, CompanyId } = item;
	const [LtSites, setLtSites] = useState([]);
	const [LtGroups, setLtGroups] = useState([]);
	// Popper
	const [TM, setTM] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [referenceElement, setReferenceElement] = useState(null);
	const [SitesState, setSitesState] = useState({
		fetching: IsTargetDoc ? false : true,
		fetchingLt: IsTargetDoc ? false : true,
	});
	const [GroupsState, setGroupsState] = useState({
		fetching: IsTargetDoc ? true : false,
		fetchingLt: IsTargetDoc ? true : false,
	});

	const siteFormatText = (count, max) => {
		return t("Targets.published", {
			context: count === max ? "sites_all" : "sites",
			count,
		});
	};

	const groupFormatText = (count, max) => {
		return t("Targets.published", {
			context: count === max ? "groups_all" : "groups",
			count,
		});
	};

	const handleShowPopper = () => {
		resetTm(
			setTimeout(() => {
				setIsOpen(true);
			}, 200)
		);
	};

	const resetTm = (new_tm) => {
		clearTimeout(TM);
		setTM(new_tm ? new_tm : false);
	};

	const closePopper = () => {
		setIsOpen(false);
		resetTm();
	};

	const fetchSites = () =>
		genRequest("Sites/SitesOfCompany/" + account.CompanyId);

	const fetchDocSites = (sites_list) => {
		let req = genRequest("LtDocumentSite/DocumentId/" + DocumentId).then(
			(resp) => {
				let ret = [];
				if (resp?.length) {
					ret = resp
						.map((a) => {
							if (sites_list?.length) {
								let site = sites_list.find(
									(s_a) => s_a.SiteId === a.SiteId
								);
								if (site !== -1) return site;
							}
							return false;
						})
						.filter((a) => a);
				}
				setLtSites(ret?.length ? ret : false);
				return ret;
			},
			() => {
				setLtSites(false);
				return false;
			}
		);
		return req;
	};

	const fetchGroups = () =>
		genRequest("Groups/OfCompany/" + account.CompanyId);

	const fetchDocGroups = () => {
		let req = genRequest("Groups/GroupOfDoc/" + DocumentId).then(
			(resp) => {
				let ret = resp?.length ? resp : [];
				setLtGroups(ret);
				return ret;
			},
			() => {
				setLtGroups(false);
				return false;
			}
		);
		return req;
	};

	return (
		<div ref={setReferenceElement}>
			{!IsTargetDoc && (
				<InfosFetcher
					queryFn={fetchSites}
					queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
					options={{
						retry: 0,
						onSettled: () =>
							setSitesState({
								fetchingLt: !IsTargetDoc,
								fetching: false,
							}),
						enabled: !IsTargetDoc,
					}}
					treat={(sites) => {
						return (
							<InfosFetcher
								queryFn={() => fetchDocSites(sites)}
								queryKey={[
									"Sites",
									"FullAllSiteOfDoc",
									DocumentId,
								]}
								options={{
									retry: 0,
									enabled: !IsTargetDoc,
									onSettled: () =>
										setSitesState({
											fetching: false,
											fetchingLt: false,
										}),
								}}
								treat={(resp) => {
									if (!resp?.length || IsTargetDoc) {
										if (CompanyId) return false;
									}
									return (
										<div
											className="d-flex align-items-center"
											onMouseEnter={handleShowPopper}
											onMouseLeave={closePopper}
										>
											<div>
												{siteFormatText(
													resp.length,
													sites.length
												)}
											</div>
											<TargetsPopper
												referenceElement={
													referenceElement
												}
												postId={DocumentId}
												type="sites"
												items={resp}
												isOpen={isOpen}
												closePopper={closePopper}
											/>
										</div>
									);
								}}
							/>
						);
					}}
				/>
			)}
			{IsTargetDoc && (
				<InfosFetcher
					queryFn={fetchGroups}
					queryKey={["Groups", "OfCompany", account.CompanyId]}
					options={{
						retry: 0,
						onSettled: () =>
							setGroupsState({
								fetchingLt: IsTargetDoc,
								fetching: false,
							}),
						enabled: IsTargetDoc,
					}}
					treat={(groups) => {
						return (
							<InfosFetcher
								queryFn={fetchDocGroups}
								queryKey={[
									"Groups",
									"FullGroupOfDoc",
									DocumentId,
								]}
								options={{
									retry: 0,
									onSettled: () =>
										setGroupsState({
											fetching: false,
											fetchingLt: false,
										}),
								}}
								treat={(resp) => {
									if (!resp?.length) return false;
									return (
										<div
											className="d-flex align-items-center"
											onMouseEnter={handleShowPopper}
											onMouseLeave={closePopper}
											ref={setReferenceElement}
										>
											<div>
												{groupFormatText(
													resp?.length,
													groups?.length
												)}
											</div>
											<TargetsPopper
												referenceElement={
													referenceElement
												}
												documentId={DocumentId}
												type="groups"
												items={resp}
												isOpen={isOpen}
												closePopper={closePopper}
											/>
										</div>
									);
								}}
							/>
						);
					}}
				/>
			)}
			{/* {!GroupsState.fetching &&
				!GroupsState.fetchingLt &&
				!LtGroups?.length &&
				!SitesState.fetching &&
				!SitesState.fetchingLt &&
				!LtSites?.length &&
				(IsTargetDoc ? "Aucune cible" : "Aucun site (?)")} */}
		</div>
	);
});
DocsTargetsCell.displayName = "DocsTargetsCell";

function TargetsPopper(props) {
	const { type, isOpen } = props;
	const { items } = props;
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(
		props.referenceElement,
		popperElement,
		{
			placement: "right",
		}
	);

	const genDom = (data) => {
		if (type === "groups")
			return data
				.sort((a, b) => (a.Name < b.Name ? -1 : 1))
				.map((a) => (
					<TagLabel
						key={a.GroupId}
						text={a.Name}
						color={a.DefaultHexaColor}
					/>
				));
		if (data && !data?.length) data = [data];
		let dom = data
			.sort((a, b) => {
				let a_name = noAccent(
					(
						a.Name + (a.Town ? " - " + a.Town : "")
					).toLocaleLowerCase()
				);
				let b_name = noAccent(
					(
						b.Name + (b.Town ? " - " + b.Town : "")
					).toLocaleLowerCase()
				);
				return a_name < b_name ? -1 : 1;
			})
			.map((a) => {
				let name = a.Name + (a.Town ? " - " + a.Town : "");
				return <TagLabel key={a.SiteId} text={name} />;
			});
		return dom;
	};

	return (
		<>
			{isOpen && (
				<div
					className="targets-popper"
					ref={setPopperElement}
					onMouseLeave={props.closePopper}
					style={styles.popper}
					{...attributes.popper}
				>
					<div className="popper d-flex flex-wrap flex-row gap-1">
						{genDom(items)}
					</div>
				</div>
			)}
		</>
	);
}

export default DocsTargetsCell;

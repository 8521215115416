import React from "react";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import { t } from "i18next";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import TagLabel from "components/Tags/Label/TagLabel";
import { Members } from "Pages/Groups/Groups";
import "./GroupsTable.css";
import GroupsTableToolbar from "./GroupsTableToolbar";

export default function GroupsTable(props) {

	const {account} = useAccount();
	const {search, onItemClick, setSelected, selected} = props;

	return (
		<div className="d-flex flex-column overflow-hidden">
			<GroupsTableToolbar
				selected={selected}
				setSelected={setSelected}
			/>
			<AsyncScrollTable
				companyId={account.CompanyId}
				itemId={"GroupId"}
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				queryFn={() => genRequest("Groups/OfCompany/" + account.CompanyId)}
				checkbox={true}
				search={search}
				onItemClick={onItemClick}
				setSelected={setSelected}
				selected={selected}
				className="GroupsTable"
				cols={[
					{
						name: "Name",
						title: t("Table.NAME")
					},
					{
						name: "Description",
						title: t("Table.DESC")
					},
					{
						name: "DefaultHexaColor",
						title: t("Table.COLOR"),
						component: ColorTag,
					},
					{
						title: t("Groups.MEMBERS"),
						name: "Members",
						component: Members,
						sort: false
					}
				]}
				sortBy="Name"
			/>
		</div>
	);
}

function ColorTag({item}) {
	return (<TagLabel color={item.DefaultHexaColor} title={item.Description} text={item.Name}/>);
}

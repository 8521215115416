import useAccount from "classes/Accounts/hooks/useAccount";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

const RssContext = createContext();

const RssProvider = ({ children }) => {
	const [rssFilters, setRssFilters] = useState([]);

	// Return the context provider with the value
	return (
		<RssContext.Provider value={{ rssFilters, setRssFilters }}>
			{children}
		</RssContext.Provider>
	);
};

export function useRssContext() {
	return useContext(RssContext);
}

export { RssContext, RssProvider };

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAccount from "classes/Accounts/hooks/useAccount";
import Button from "components/Button/Button";
import ColorPicker from "components/ColorPicker/ColorPicker";
import useTemplate from "hooks/useTemplate";
import { t } from "i18next";
import React, { useState } from "react";

export default function PrimaryColor({ handleRefresh }) {
	const { template, applyTemplate } = useTemplate();
	const { getSettings, setSettings } = useAccount();
	const [current, setCurrent] = useState(
		getSettings("PrimaryColor") || template?.primaryColor()
	);

	function changePreset(color) {
		setCurrent(color ? color : template.primaryColor());
		setSettings("PrimaryColor", color);
		// handleRefresh();
		applyTemplate();
	}

	return (
		<div className="d-flex gap-2">
			<div style={{ width: "100px" }}>
				<ColorPicker
					mode="Picker"
					locale={t("Code")}
					value={current}
					mainColor={template?.primaryColor()}
					onChange={(color) => changePreset(color)}
					enableOpacity={false}
				/>
			</div>
			{template?.primaryColor() !== current && (
				<Button className="w-auto" onClick={() => changePreset(false)}>
					<FontAwesomeIcon
						icon={faCircle}
						color={template?.primaryColor()}
						className="me-1"
					/>
					{t("Settings.RESET")}
				</Button>
			)}
		</div>
	);
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import "./FormEditToolbar.css";
import { t } from "i18next";

export default function FormEditToolbar(props) {
	const {form} = props;
	const {curSection, setCurSection} = props;
	const {curQuestion, setCurQuestion} = props;

	function addSection() {
		let sec = form.addSection();
		setCurSection(sec);
		return (sec);
	}

	function addQuestion() {
		if (!curSection)
			return (false);
		let quest = curSection.addQuestion(false, curQuestion.Position);
		setCurQuestion(quest);
		return (quest);
	}

	return (
		<div className="FormEditToolbar">
			<div className="toolbar-items">
				<div className="add-question-btn" title={t("Forms.ADD_QUESTION")} onClick={addQuestion}>
					<FontAwesomeIcon icon={faPlus}/>
				</div>
				<div className="add-section-btn" title={t("Forms.ADD_SECTION")} onClick={addSection}>
					<FontAwesomeIcon icon={faFolderPlus}/>
				</div>
			</div>
		</div>
	);
}

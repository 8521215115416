export const WelcomePhrases = [
	{
		type: "first_conn",
		forceType: true,
		min_date: null,
		max_date: null,
		list: [
			{
				phrase: "WELCOME"
			},
			{
				phrase: "DELIGHTED"
			},
			{
				phrase: "NTMY"
			}
		]
	},
	{
		type: "connect",
		forceType: true,
		min_date: null,
		max_date: {
			minutes: -5
		},
		list: [
			{
				phrase: "FORGOT"
			},
			{
				phrase: "ALREADY_BACK"
			},
			{
				phrase: "MISSING_YOU"
			}
		]
	},
	{
		min_date: null,
		max_date: {
			hours: -1
		},
		list: [
			{
				phrase: "GO"
			},
			{
				phrase: "SEATBELT"
			},
			{
				phrase: "FORCE"
			},
			{
				phrase: "LOVE"
			},
			{
				phrase: "STRETCH"
			},
			{
				phrase: "GOOD_DAY"
			}
		]
	},
	{
		min_date: null,
		max_date: {
			days: -1
		},
		list: [
			{
				phrase: "INSOMNIA",
				min_time: "00:00",
				max_time: "05:00"
			},
			{
				phrase: "GOOD_SLEEP",
				min_time: "05:00",
				max_time: "10:00"
			},
			{
				phrase: "MORNING_DRINK",
				min_time: "05:00",
				max_time: "10:00"
			},
			{
				phrase: "BG",
				min_time: "08:00",
				max_time: "10:00"
			},
			{
				phrase: "EAT",
				min_time: "11:30",
				max_time: "12:30"
			},
			{
				phrase: "HAPPY_MEAL",
				min_time: "11:30",
				max_time: "12:30"
			},
		]
	},
	{
		min_date: null,
		max_date: null,
		list: [
			{
				phrase: "NIGHT",
				min_time: "00:00",
				max_time: "05:00"
			},
			{
				phrase: "MORNING",
				min_time: "05:00",
				max_time: "12:00"
			},
			{
				phrase: "AFTERNOON",
				min_time: "12:00",
				max_time: "17:30"
			},
			{
				phrase: "EVENING",
				min_time: "17:30",
				max_time: "23:59"
			},
		]
	},
	{
		type: "menu",
		forceType: true,
		min_date: null,
		max_date: {
			weeks: -1
		},
		list: [
			{
				phrase: "MENU_WELCOME"
			},
			{
				phrase: "MENU_NIGHT",
				min_time: "00:00",
				max_time: "05:00"
			},
			{
				phrase: "MENU_MORNING",
				min_time: "05:00",
				max_time: "12:00"
			},
			{
				phrase: "MENU_AFTERNOON",
				min_time: "12:00",
				max_time: "17:30"
			},
			{
				phrase: "MENU_EVENING",
				min_time: "17:30",
				max_time: "23:59"
			},
		]
	},
	{
		min_date: {
			weeks: -1
		},
		max_date: {
			months: -1
		},
		list: [
			{
				phrase: "WELCOME_BACK"
			},
			{
				phrase: "MISSING"
			},
		]
	},
	{
		min_date: {
			months: -1
		},
		max_date: null,
		list: [
			{
				phrase: "MISSING_WOW"
			},
			{
				phrase: "EVOLVED"
			},
			{
				phrase: "MISSING"
			},
		]
	}
];

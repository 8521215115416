import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { t } from "i18next";
import genRequest from "includes/request";
import React from "react";

export default function SitesSelect(props) {

	const {companyId} = props
	return (
		<QuerySelect
			placeholder={t("Sites.SELECT_SITES")}
			className={"bg-color w-100 rounded"}
			classNamePrefix="dropdown"
			queryKey={["Sites", "SitesOfCompany", companyId]}
			queryFn={() => genRequest("Sites/SitesOfCompany/" + companyId)}
			treat={(site) => ({
				value: site.SiteId,
				SiteId: site.SiteId,
				label: [site.Name, site.Town].join(" - ")
			})}
			isSearchable={true}
			{...props}
		/>
	)
}

import { faCopy } from "@fortawesome/free-regular-svg-icons";
import TableToolBar from "components/ScrollTable/components/TableToolBar/TableToolBar";
import promiseToast from "components/Toasts/PromiseToast";
import useSelect from "hooks/useSelect";
import { t } from "i18next";
import genRequest from "includes/request";
import React from "react";
import { useQueryClient } from "react-query";
import CopyCatsPopUp from "../components/CopyCatsPopUp/CopyCatsPopUp";

export default function CategoriesTableToolbar(props) {
	const {selected, setSelected} = props;
	const {confirmDelete} = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		if (!selected?.length)
			return (false);
		let title = t(
			"Categories.ASKDELETE",
			{
				count: selected.length,
				name: selected[0].Name,
				type: t("Categories.TYPE-" + selected[0].CategoryType)
			}
		);
		confirmDelete(
			() => { delSelecteds(); },
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selected.map((cat) => genRequest("Categories/" + cat.CategoryId, null, "delete"));
		let prom = Promise.allSettled(proms).then(() => {
			QCL.resetQueries(["Categories"]);
			setSelected([]);
		});
		promiseToast(
			prom,
			{
				pending: t("Commons.DELETING", {count: selected.length})
			}
		);
		return (prom);
	}

	const handleClose = (resp) => {
		QCL.refetchQueries(["Categories"])
		if (resp)
			setSelected([])
	}

	return (
		<div>
			<TableToolBar
				selected={selected}
				setSelected={setSelected}
				handleDelete={handleDelete}
				buttons={[
					{
						title: t("Categories.DUP_CATEGORY"),
						value: t("Categories.DUP"),
						icon: faCopy,
						popperTitle: t("Categories.DUPLICATION"),
						popper: CopyCatsPopUp,
						onClose: handleClose
					}
				]}
			/>
		</div>
	);
}

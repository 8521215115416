
import PageHeadBarHelper from "./PageHeadHelper";

const UIHelperTour = {
	tourId: "UI",
	title: "Interface",
	description: "Menus et pages",
	page: "/news",
	steps: [
		{
			target: "#main-menu",
			title: "Menu principal",
			content: "Utilisez le menu pour naviguer entre les pages",
			placement: "right",
			disableBeacon: true
		},
		{
			target: ".PlateformChanger",
			title: "Mode de visualisation",
			content: "Utiliser le switch pour afficher les fonctionnalitées d'administration",
			spotlightClicks: true
		},
		...PageHeadBarHelper,
		{
			target: "#main-menu .account-menu .user-image",
			title: "Mon espace",
			content: "Cliquez sur votre image de profil pour acceder à votre espace.\nVous pourrez modifier vos informations et visionner vos publications ou documents",
			placement: "top",
			spotlightClicks: true
		},
		{
			target: "#main-menu .account-menu .account-menu-infos",
			title: "Menu",
			content: "Cliquez ici pour ouvrir le menu du compte, ou vous déconnecter",
			placement: "top",
			spotlightClicks: true
		},
	]
};

export default UIHelperTour;

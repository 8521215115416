import React from "react";
import EditDrawer from "components/EditDrawer/EditDrawer";
import "./SiteEdit.css";
import LocationStep, {checkLocation} from "./components/LocationStep/LocationStep";
import IdentityStep, {checkIdentity} from "./components/IdentityStep/IdentityStep";
import ContactStep, {checkContact} from "./components/ContactStep/ContactStep";
import OptionsStep from "./components/OptionsStep/OptionsStep";
import SendSiteStep from "./components/SendStep/SendSiteStep";
import EndSiteStep from "./components/EndStep/EndSiteStep";
import { t } from "i18next";

function SiteEdit(props) {
	if (!props.isOpen)
		return (false);

	const SiteSteps = [
		{
			component: IdentityStep,
			name: "content",
			title: t("Commons.IDENTITY"),
			onNext: checkIdentity,
		},
		{
			component: ContactStep,
			name: "contact",
			title: t("Sites.CONTACT_STEP"),
			onNext: checkContact
		},
		{
			component: LocationStep,
			name: "location",
			title: t("Sites.LOCATION_STEP"),
			onNext: checkLocation,
		},
		{
			component: OptionsStep,
			name: "options",
			title: t("Commons.OPTIONS"),
			disabled: props.site.siteId()
		},
		{
			component: SendSiteStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("Commons.SEND_STEP")
		},
		{
			component: EndSiteStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true
		}
	];

	return (
		<div className="SiteEdit">
			<EditDrawer
				isOpen={props.isOpen}
				object={props.site}
				onClose={props.onClose}
				account={props.account}
				initQueries={props.initQueries}
				title={props.title}
				steps={SiteSteps}
				sendBtnText={t("EditDrawer.VALIDATE_BTN")}
			/>
		</div>
	);
}

export default SiteEdit;

import React from "react";
import FormInput from "components/Inputs/FormInput";
import ColorPicker from "components/ColorPicker/ColorPicker";
import { t } from "i18next";
import useTemplate from "hooks/useTemplate";

export default function IdentityStep(props) {
	const { template } = useTemplate();
	let group = props.object;

	return (
		<div className="inner-step-cont">
			<FormInput className="mb-2 w-100" value={group.name()} onChange={(val) => group.name(val)} title={t("Table.NAME")}/>
			<FormInput className="mb-2 w-100" value={group.description()} onChange={(val) => group.description(val)} title={t("Table.DESC")}/>
			<div className="picker-cont" style={{width: "100px"}}>
				<div>{t("Table.COLOR")}</div>
				<div>
					<ColorPicker
						mainColor={template?.primaryColor()}
						className="light-bg"
						onChange={(val) => {group.defaultHexaColor(val);}}
						initialValue={group.defaultHexaColor()}
						enableOpacity={false}
						value={group.defaultHexaColor()}
					/>
				</div>
			</div>
		</div>
	);
}

export function checkIdentity(group) {
	let errors = [];
	if (!group.name()?.length)
		errors.push(t("Groups.NAME_ERROR"));
	return (errors.length ? errors : true);
}

import React, {useState} from "react";
import {useQuery} from "react-query";
import genRequest from "includes/request";
import "./ReactionsBank.css";
import ReactionIcon from "Pages/Reactions/components/ReactionIcon/ReactionIcon";

export default function ReactionsBank(props) {
	const [selected, setSelected] = useState(props.selected || []);

	function handleSelect(item) {
		let check = selected.findIndex((a) => a.ReactionId === item.ReactionId);
		let dup = [...selected];

		if (check >= 0)
			dup.splice(check, 1);
		else if (!props.max || props.max > selected?.length)
			dup.push(item);
		if (props.onSelect)
			props.onSelect(dup);
		setSelected(dup);
	}

	const isSelected = (item) => {
		let check = selected.findIndex((a) => a.ReactionId === item.ReactionId);
		return (check !== -1 ? check : false);
	};

	const {isLoading, data} = useQuery(
		["Reactions"],
		() => genRequest("Reactions")
	);

	if (isLoading)
		return (false);

	return (
		<div className="ReactionsBank d-flex flex-wrap">
			{
				data.map((a) => {
					return (<ReactionBankItem {...a} key={a.ReactionId} onClick={() => handleSelect(a)} isSelected={isSelected(a)}/>);
				})
			}
		</div>
	);
}

function ReactionBankItem(props) {

	return (
		<div className={"ReactionBankItem d-flex align-items-center justify-content-center" + (props.isSelected !== false ? " selected" : "")} onClick={props.onClick}>
			<ReactionIcon {...props}/>
			{
				props.isSelected !== false &&
					<div className="selected-index">
						{props.isSelected + 1}
					</div>

			}
		</div>
	);
}

import { AsyncGallery } from "Pages/Posts/components/ImagesSender/components/Gallery/Gallery";
import { DragDropContext } from "react-beautiful-dnd";
import React from "react";
import { t } from "i18next";

export default function GalleryTab() {
	return (
		<DragDropContext className="h-100">
			<div className="mb-1">
				{t("Gallery.DROP_MSG")}
			</div>
			<AsyncGallery isDragDisabled={true}/>
		</DragDropContext>
	);
}

import React, { createContext, useContext, useState } from "react";

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
    const [totalUsers, setTotalUsers] = useState([]);
    const [usersToExport, setUsersToExport] = useState(0);
    const [request, setRequest] = useState({});
    const [totalRequest, setTotalRequest] = useState({});

    // Return the context provider with the value
    return (
        <UsersContext.Provider value={{ totalUsers, setTotalUsers, request, setRequest, usersToExport, setUsersToExport, totalRequest, setTotalRequest }}>
            {children}
        </UsersContext.Provider>
    );
};

export function useUsersContext() {
    return useContext(UsersContext);
}

export { UsersContext, UsersProvider };

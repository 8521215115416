import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostObject from "classes/Posts/PostObject";
import BackButton from "components/Button/BackButton";
import Button from "components/Button/Button";
import FormInput from "components/Inputs/FormInput";
import Summary from "components/Summary/Summary";
import promiseToast from "components/Toasts/PromiseToast";
import { t } from "i18next";
import generateUUID from "includes/UUID";
import React, { useState } from "react";
import ReactModal from "react-modal";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import TourPreview from "./components/TourPreview";
import "./TourEditor.css";
import RecorderToolbar from "./components/RecorderToolbar";

const killEvent = (e) => {
	if (!e) return false;
	e.preventDefault();
	e.stopPropagation();
};

export default function TourEditor(props) {
	const { account } = useAccount();
	const QCL = useQueryClient();
	const [IsRecording, setIsRecording] = useState(false);
	const [IsAddingStep, setIsAddingStep] = useState(false);
	const [IsEnded, setIsEnded] = useState(false);
	const [SelectingTarget, setSelectingTarget] = useState(false);
	const [IsPreviewing, setIsPreviewing] = useState(false);
	const [Tour, setTour] = useState(false);
	const old = React.useRef(false);

	const highlight = React.useCallback(
		(e, forced) => {
			if (!SelectingTarget && !forced) return false;
			if (
				!forced &&
				(!IsRecording ||
					IsAddingStep ||
					(old?.current && old?.current === e.target))
			)
				return false;
			document
				.querySelectorAll(".tour-editor-highlight")
				.forEach((a) => a.classList.remove("tour-editor-highlight"));
			if (!e?.target) return false;
			document
				.querySelectorAll(".tour-editor-highlight")
				.forEach((a) => a.classList.remove("tour-editor-highlight"));
			e?.target?.classList.add("tour-editor-highlight");
			old.current = e.target;
		},
		[old, IsRecording, IsAddingStep, SelectingTarget]
	);

	const saveEvent = React.useCallback(
		(step, event) => {
			killEvent(event);
			if (!Tour) return false;
			Tour.steps.push(step);
			setIsAddingStep(false);
			highlight(false, true);
		},
		[Tour, setIsAddingStep, highlight]
	);

	const addStep = React.useCallback(
		(e) => {
			if (SelectingTarget) killEvent(e);
			if (!SelectingTarget || e.altKey) return false;
			if (!IsAddingStep) setIsAddingStep(e);
			setSelectingTarget(false);
		},
		[IsAddingStep, setIsAddingStep, SelectingTarget]
	);

	React.useEffect(() => {
		const cont = document.querySelector("#root");
		if (IsRecording && SelectingTarget) {
			cont.addEventListener("click", addStep);
			cont.addEventListener("mousemove", highlight);
		}

		return () => {
			cont.removeEventListener("click", addStep);
			cont.removeEventListener("mousemove", highlight);
		};
	}, [Tour, addStep, highlight, SelectingTarget, IsRecording]);

	function startRecord(e) {
		killEvent(e);
		setIsRecording(true);
	}

	function handleCancel(e) {
		killEvent(e);
		setIsAddingStep(false);
		setSelectingTarget(false);
		highlight(false, true);
	}

	function handleEndTour() {
		setIsRecording(false);
		setIsEnded(true);
	}

	function sendTour() {
		let post = new PostObject({
			PostTypeId: 22,
			Title: Tour.title,
			Txt: JSON.stringify(Tour, null, 4),
			CompanyId: 1,
			SiteId: 1,
			EmployesId: account.EmployesId,
			ValidationModeration: true,
			NotifyPost: true,
		});

		let isNew = true;
		if (Tour.PostId) {
			post.postId(Tour.PostId);
			isNew = false;
		}

		const notify = () => {
			let url = "NewsNotifyCustomizeWithoutPost";
			let data = {
				Title: "Nouveau tutoriel a intégrer",
				Message: post.title(),
				ListUsers: [{ ...account }],
				Author: account,
				TimeToNotify: post.publishedDate(),
			};
			return post.notify(url, data);
		};

		let req = post.send().then((resp) => {
			if (isNew) notify();
			QCL.setQueryData(["TourEditor", "AddTour"], false);
			QCL.resetQueries(["RetrievePosts"]);
		});
		promiseToast(req);
		return req;
	}

	function closeEditor(andSendTour) {
		let prom = Promise.resolve(true);
		if (andSendTour) prom = Tour.onSend ? Tour.onSend(Tour) : sendTour();
		prom.then((resp) => {
			highlight(false, true);
			setIsEnded(false);
			setTour(false);
			QCL.setQueriesData(["TourEditor", "AddTour"], false);
		});
	}

	function previewTour(state) {
		let new_state = typeof state !== "undefined" ? state : true;
		setIsPreviewing(new_state);
		let data =
			new_state === false
				? false
				: {
						...Tour,
						onClose: () => setIsPreviewing(false),
				  };
		QCL.setQueriesData(["Helper", "CurrentTour"], data);
	}

	const { data } = useQuery(["TourEditor", "AddTour"], () => {}, {
		onSettled: (resp) => {
			if (resp && !Tour) {
				let tour = resp.tour || {
					tourId: generateUUID(),
					title: "",
					description: "",
					steps: [],
				};
				tour.steps.forEach((a) => (a.stepId = generateUUID()));
				tour.onSend = resp.onSend || false;
				setTour(tour);
			}
		},
	});

	if (!data) return false;

	if (IsEnded) {
		if (IsPreviewing) return false;
		return (
			<ReactModal isOpen={true}>
				<div className="TourEndStep d-flex flex-column h-100 overflow-hidden gap-2">
					<div className="d-flex gap-2">
						<BackButton
							onClick={() => {
								setIsPreviewing(false);
								setIsEnded(false);
								setIsRecording(true);
								setIsAddingStep(false);
							}}
							value="Retour à l'éditeur"
						/>
						<Button
							className="btn-primary w-auto"
							onClick={previewTour}
						>
							Visualiser le tour
						</Button>
						<Button
							className="btn-green w-auto"
							onClick={() => closeEditor(true)}
						>
							Envoyer et fermer
						</Button>
						<Button
							className="btn-red w-auto"
							onClick={() => closeEditor(false)}
						>
							Fermer sans enregistrer
						</Button>
					</div>
					<div className="overflow-auto pe-1">
						<div className="mt-3 bg-color p-2 radius-2">
							<h5 className="font-bold">{Tour.title}</h5>
							<div className="font-italic">
								{Tour.description}
							</div>
						</div>
						<div className="mt-3 bg-color p-2 radius-2">
							<div className="font-bold">Étapes</div>
							<TourPreview tour={Tour} setTour={setTour} />
						</div>
						<Summary title="Code" className="mt-3">
							<Button
								className="btn-cyan w-auto gap-2"
								text={"Copier le code"}
								onClick={() => {
									navigator.clipboard.writeText(
										JSON.stringify(Tour, null, 4)
									);
									toast(
										t("Commons.DATA_COPIED_TO_CLIPBOARD"),
										{
											autoClose: 3000,
										}
									);
								}}
							>
								<FontAwesomeIcon icon={faCopy} />
								Copier
							</Button>
							<pre className="bg-light p-2 radius-2">
								{JSON.stringify(Tour, null, 4)}
							</pre>
						</Summary>
					</div>
				</div>
			</ReactModal>
		);
	}

	if (IsRecording && Tour)
		return (
			<RecorderToolbar
				tour={Tour}
				steps={Tour.steps?.length}
				setTour={setTour}
				saveEvent={saveEvent}
				onCancel={handleCancel}
				onEnd={handleEndTour}
				isAdding={IsAddingStep}
				highlight={highlight}
				setSelectingTarget={setSelectingTarget}
				selectingTarget={SelectingTarget}
				previewTour={previewTour}
			/>
		);

	return (
		<div className="no-highlight">
			<ReactModal isOpen={!IsRecording}>
				<BackButton onClick={closeEditor} value="Fermer l'éditeur" />
				<div className="mt-3">
					<FormInput
						title="Titre du tutoriel"
						value={Tour.title}
						onChange={(val) => (Tour.title = val)}
					/>
				</div>
				<div className="mt-3">
					<FormInput
						title="Description"
						textarea={"true"}
						value={Tour.description}
						onChange={(val) => (Tour.description = val)}
					/>
				</div>
				<div className="mt-2">
					<Button className="w-auto btn-green" onClick={startRecord}>
						Enregistrer les étapes
					</Button>
				</div>
			</ReactModal>
		</div>
	);
}

import React from "react";

import CompanyHomePage from "./SubPages/Home/CompanyHomePage";
import { t } from "i18next";
import NestedPages from "components/NestedPages/NestedsPages";

export default function CompanyPageBody(props) {

	const pages = [
		{
			url: "/home",
			paths: [
				"",
				"/home",
			],
			exact: true,
			title: t("Pages.HOME"),
			component: CompanyHomePage,
			childprops: {
				typeId: 1,
				companyId: props.companyId
			},
			appFunction: ""
		},
		{
			url: "/news",
			params_url: "/news/:id",
			paths: [
				"/news",
				"/news/:id",
			],
			title: t("Pages.NEWS"),
			component: false,
			childprops: {
				typeId: 1,
				companyId: props.companyId
			},
			exact: false,
			appFunction: ""
		},
		{
			url: "/offers",
			params_url: "/offers/:id",
			paths: [
				"/offers",
				"/offers/:id",
			],
			title: t("Pages.OFFERS"),
			component: false,
			exact: false,
			childprops: {
				typeId: 4
			},
			appFunction: ""
		},
		{
			url: "/careers",
			params_url: "/careers/:id",
			paths: [
				"/careers",
				"/careers/:id",
			],
			title: t("Pages.CAREERS"),
			component: false,
			childprops: {
				typeId: 5
			},
			appFunction: ""
		},
		{
			url: "/documents",
			title: t("Pages.DOCUMENTS"),
			component: false,
			appFunction: ""
		},
		{
			url: "/contacts",
			title: t("Pages.CONTACTS"),
			component: false,
			appFunction: ""
		}
	];

	return (
		<div className="CompanyPageBody p-4 pt-0">
			<NestedPages baseUrl={(props.baseUrl ? props.baseUrl : "/company") + (!props.noBaseId ? "/" + props.companyId : "")} pages={pages} home={"/home"}/>
		</div>
	);
}

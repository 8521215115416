import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
//SendingCont
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./SendingCont.css";
import { t } from "i18next";

export default function SendingCont(props)
{
	const {isLoading, isSended, status, isIdle, error, title} = props;

	function getIcon()
	{
		if (error)
			return (<FontAwesomeIcon icon={faTimes}/>);
		else if (status === "success" || isSended)
			return (<FontAwesomeIcon icon={faCheck}/>);
		else if ((isIdle || isLoading))
			return (<MoonLoader color={"white"} size={30}/>);
	}

	return (
		<div className="d-flex flex-column SendingCont">
			<div className="d-flex sending-step-cont">
				<div className={"state-icon me-2" + ((!isSended && (isIdle || isLoading) ) ? " loading" : " done") + (error ? " error" : "")}>
					{getIcon()}
				</div>
				<div className="req-title">{title}</div>
			</div>
			{
				error && <pre className="error-cont">
					{t("Desktop.ERROR")}<br/>
					<hr/>
					Datas: <br/> { JSON.stringify(error?.response?.config.data)?.replace(/(\\r|)\\n/gi, "\n")}
					<hr/>
					Error:<br/>
					{ JSON.stringify(error.response?.data)?.replace(/(\\r|)\\n/gi, "\n")}
				</pre>
			}
		</div>
	);
}

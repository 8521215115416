import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import Post from "classes/Posts/PostModel";
import "./PostsListWidget.css";
import PostsFetcher from "classes/Posts/PostsFetcher";
import useCurPage from "hooks/useCurPage";

export default function PostsListWidget({companyId}) {
	const {account} = useAccount();
	let id = (companyId ? companyId : account.CompanyId);

	return (
		<div className="posts-list-widget-cont">
			<PostsFetcher
				companyId={id}
				typeId={1}
				childComponent={PostsWidgetTiles}
				maxPosts={10}
				notFirst={true}
				state="published"
				sortBy="PriorityTo"
			/>
		</div>
	);
}

function PostsWidgetTiles({posts}) {
	const {setCurPage} = useCurPage();

	function handleClick(params) {
		setCurPage(params, true);
	}

	return (
		<div className="posts-list-widget-list">
			{
				posts.map((post) => {
					return (<PostsWidgetTile key={post.PostId} post={post} onClick={handleClick}/>);
				})
			}
		</div>
	);
}

function PostsWidgetTile({post, onClick}) {
	function handleClick() {
		let params = "/news/" + post.PostId;
		onClick(params);
	}
	return (
		<Post author={false} datas={post} onClick={handleClick}/>
	);
}

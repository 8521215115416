import React, { useState } from "react";
// import { DragDropContext } from "react-beautiful-dnd";
import FormSection from "./components/FormSection/FormSection";
import FormEditToolbar from "./components/Toolbar/FormEditToolbar";
import "./FormGenerator.css";

export default function FormGenerator({form, parentScroll}) {
	const [lastUpdate, setLastUpdate] = useState(Date.now());
	const [curSection, setCurSection] = useState(form.sections()[0]);
	const [curQuestion, setCurQuestion] = useState(false);
	const cont_ref = React.useRef();
	let parent_scroll = document.querySelector(parentScroll);

	const forceRefresh = () => setLastUpdate(Date.now());

	function changeCurSection(new_sec, from_quest) {
		form.curSection(new_sec);
		setCurSection(new_sec);
		if (!from_quest)
		{
			setCurQuestion(false);
			setTimeout(() => {
				scrollCont("[data-form-section-id='" + new_sec.id + "']");
			}, 50);
		}
	}

	function changeCurQuestion(new_quest) {
		form.curQuestion(new_quest);
		setCurQuestion(new_quest);
		setCurSection(new_quest.Parent, true);
		setTimeout(() => {
			scrollCont("[data-form-question-id='" + new_quest.id + "']");
		}, 50);
	}

	function scrollCont(selector) {
		let cont = cont_ref?.current;
		if (!cont)
			return (false);
		let sections = cont.querySelector(".FormSections");
		let elem = cont.querySelector(selector);
		if (!elem)
			return (false);
		if (elem && (sections.scrollTop < (elem.offsetTop - 30 - elem.offsetHeight) || sections.scrollTop > elem.offsetTop))
			sections.scrollTop = elem.offsetTop - (sections.offsetHeight / 2 - elem.offsetHeight/2);
		let toolbar = cont.querySelector(".FormEditToolbar .toolbar-items");
		if (toolbar)
			toolbar.style.top = elem.offsetTop + "px";
		if (parent_scroll)
			parent_scroll.scrollTop = elem.offsetTop;
	}

	return (
		<div className="FormGenerator" ref={cont_ref}>
			<FormEditToolbar
				form={form}
				curSection={curSection}
				setCurSection={changeCurSection}
				curQuestion={curQuestion}
				setCurQuestion={changeCurQuestion}
			/>
			<SectionsCont
				items={form.sections()}
				lastUpdate={lastUpdate}
				curSection={curSection}
				setCurSection={changeCurSection}
				curQuestion={curQuestion}
				// curQuestionId={curQuestion?.QuestionId}
				setCurQuestion={changeCurQuestion}
				refreshForm={forceRefresh}
			/>
		</div>
	);
}

function SectionsCont(props) {
	const {items} = props;
	const {curSection, setCurSection, refreshForm} = props;
	const {curQuestion, setCurQuestion} = props;

	if (!items)
		return (false);
	return (
		<div className="FormSections">
			{
				items.map((a) => {
					return (
						<FormSection
							key={a.id}
							section={a}
							canDelete={items?.length > 1}
							curSection={curSection}
							setCurSection={setCurSection}
							curQuestion={curQuestion}
							setCurQuestion={setCurQuestion}
							refreshForm={refreshForm}
						/>
					);
				})
			}
		</div>
	);
}

import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";
import FormQuestionModel from "./FormQuestionModel";

export default class FormSectionModel {

	constructor(obj, parent) {

		let date = formatDate();
		this.Parent = parent;
		this.FormSectionId = (obj?.FormSectionId ? obj.FormSectionId : null);
		this.FormId = (obj?.FormId ? obj.FormId : null);
		this.TempId = generateUUID();
		this.Title = (obj?.Title ? obj.Title : "");
		this.Txt = (obj?.Txt ? obj.Txt : "");
		this.Position = (obj?.Position != null ? obj.Position : null);
		this.Requiered = (obj?.Requiered ? obj.Requiered : false);
		this.LastChanged = (obj?.LastChanged ? obj.LastChanged : date);
		this.LastChanged_AuthorId = (obj?.LastChanged_AuthorId ? obj.LastChanged_AuthorId : date);
		this.Questions = [];

		if (obj?.Questions) {
			for (let x in obj.Questions)
				this.Questions.push(new FormQuestionModel(obj.Questions[x], this));
		}
	}

	send() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			this.sendSection()
				.then(() => {
					that.sendQuestions(true);
				}, reject)
				.then(resolve, reject);
		});
		return (prom);
	}

	sendSection() {
		let that = this;
		let req = genRequest(
			"FormSections" + (this.FormSectionId ? "/" + this.FormSectionId : ""),
			this.values(),
			(this.FormSectionId ? "put" : "post")
		)
			.then((resp) => {
				if (!that.FormSectionId)
					that.id = resp.FormSectionId;
			});
		return (req);
	}

	sendQuestions() {
		let proms = this.Questions.map((a) => a.send());
		return (Promise.all(proms));
	}

	addQuestion(quest, index) {
		if (!quest)
			quest = {
				FormId: this.FormId,
				FormSectionId: this.id,
				TempId: generateUUID(),
				QuestionDesignId: 1,
				Type: "shorttext",
				Title: "",
				Txt: "",
			};
		let quest_obj = new FormQuestionModel(quest, this);

		if (this.Parent.curQuestion() && index === null)
		{
			let cur_quest = this.questions(
				this.Parent.curQuestion().QuestionId,
				true
			);
			index = cur_quest.Position + 1;
		} else if (index === null)
			index = this.Questions.length;
		this.Questions.splice(index + 1, 0, quest_obj);
		this.reorderQuestions();
		return (quest_obj);
	}

	removeQuestion(questId) {
		for (let x in this.Questions)
			if (this.Questions[x].id === questId)
			{
				let quest = this.Questions[x];
				if (quest.id === this.Parent.curQuestion()?.id)
					this.Parent.curQuestion(false);
				let deleted = this.Questions.splice(x, 1);
				this.reorderQuestions();
				return (deleted);
			}
		return (false);
	}

	cloneQuestion(questId) {
		for (let x in this.Questions)
			if (this.Questions[x].id === questId)
			{
				let quest = this.Questions[x].values(true);
				quest.Options = quest.Options.map((ans, key) => {
					ans.Position = key;
					ans.TempId = generateUUID();
					return (ans);
				});
				quest.TempId = generateUUID();
				return (this.addQuestion(quest, parseInt(x)));
			}
	}

	questions(quest_id, with_index) {
		if (!quest_id)
			return (this.Questions);
		for (let x in this.Questions) {
			if (this.Questions[x].id === quest_id) {
				if (with_index)
					return ({
						index: parseInt(x),
						question: this.Questions[x]
					});
				else
					return (this.Questions[x]);
			}
		}
	}

	order(new_pos) {
		if (new_pos)
			this.Position = new_pos;
		return (this.Position);
	}

	moveQuestion(from_index, to_index) {
		const [removed] = this.Questions.splice(from_index, 1);
		this.Questions.splice(to_index, 0, removed);
		this.reorderQuestions();
		return (removed);
	}

	reorderQuestions() {
		let pos = 0;
		for (let x in this.Questions)
			this.Questions[x].Position = pos++;
	}

	checkValues() {
		if (!this.Title?.length)
			return ({
				selector: "[data-form-section-id='" + this.FormSectionId+ "']",
				error: "FORM_EMPTY_SECTION_TITLE"
			});
		if (!this.Questions?.length)
			return ({
				selector: "[data-form-section-id='" + this.FormSectionId+ "']",
				error: "FORM_NO_SECTION_QUESTION"
			});
		for (let q of this.Questions)
		{
			let check = q.checkValues();
			if (check !== true)
				return ({
					question: q,
					...check
				});
		}
		return (true);
	}

	delete() {
		this.Parent.removeSection(this.id);
	}

	formId(value) {
		if (value)
		{
			this.FormId = value;
			for (let quest of this.Questions)
				quest.formId(value);
		}
		return (this.FormId);
	}

	get parentForm() {
		return (this.Parent);
	}

	get id() {
		return (this.FormSectionId ? this.FormSectionId : this.TempId);
	}

	set id(value) {
		if (value)
		{
			this.FormSectionId = value;
			for (let quest of this.Questions)
				quest.sectionId(value);
		}
	}

	values() {
		let obj = {
			FormId: this.FormId,
			TempId: this.TempId,
			Title: this.Title,
			Txt: this.Txt,
			Position: this.Position,
			Requiered: this.Requiered,
			LastChanged: this.LastChanged,
			LastChanged_AuthorId: this.LastChanged_AuthorId
		};
		if (this.FormSectionId)
			obj.FormSectionId = this.FormSectionId;
		return (obj);
	}
}

import React, { useState } from "react";
import "./DocPreview.css";
import genRequest from "includes/request";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import SaveDocBtn from "../SaveDocBtn/SaveDocBtn";
import Button from "components/Button/Button";
import BackButton from "components/Button/BackButton";
import ServError from "components/ServError/ServError";
import AsyncDocPreview from "./AsyncDocPreview";
import { t } from "i18next";
import Modal from "react-modal";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import DocumentTile from "../Tile/DocumentTile";
import useAccount from "classes/Accounts/hooks/useAccount";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";

export default function DocPreview(props) {
	const data = props.document;
	const [isOpen, setIsOpen] = useState(true);
	const [isSharing, setIsSharing] = useState(false);
	const { account } = useAccount();

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);

		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	function closePreview() {
		setIsOpen(false);
		if (props.onClose) props.onClose(false);
	}

	function handleShare(doc) {
		setIsSharing(true);
	}

	if (!props.document) {
		if (props.documentId && props.async !== false)
			return <AsyncDocPreview {...props} />;
		return false;
	}
	if (props.frameOnly)
		return (
			<iframe
				className={
					"pdf-viewer" +
					(props.className ? " " + props.className : "")
				}
				title={data.Name}
				src={
					data.Url_Origin + "?" + encodeURIComponent(data.LastChanged)
				}
			/>
		);
	return (
		<div
			className={
				"pdf-viewer-cont" +
				(isOpen ? " open" : "") +
				(props.className ? " " + props.className : "")
			}
		>
			<div className="viewer-top-bar mb-1">
				{props.closable !== false && (
					<div className="mb-2">
						<BackButton
							className="close-pdf-viewer"
							onClick={closePreview}
						/>
					</div>
				)}
				{data && (
					<div className="doc-infos">
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center">
								<div className="doc-name font-bold">
									{data.Name}
								</div>
								<SaveDocBtn
									className="ms-2"
									docId={data.DocumentId}
								/>
							</div>
							<div className="btn-group gap-1">
								{data.IsShareable && (
									<div>
										<Button
											className="btn-green"
											onClick={() => handleShare(data)}
										>
											{t("Commons.SHARE")}
										</Button>
									</div>
								)}
								{props.handleModify && checkRight() && (
									<div>
										<Button
											className="btn-blue"
											onClick={() =>
												props.handleModify(data)
											}
										>
											{t("Commons.MODIFY")}
										</Button>
									</div>
								)}
							</div>
						</div>
						<div className="doc-cats mb-2">
							{
								<QueryTagsList
									className="mt-1"
									queryKey={[
										"Categories",
										"Document",
										data.DocumentId,
									]}
									queryFn={() =>
										genRequest(
											"Categories/Document/" +
											data.DocumentId
										)
									}
								/>
							}
						</div>
					</div>
				)}
			</div>
			{data && (
				<iframe
					className="pdf-viewer"
					title={data.Name}
					src={
						data.Url_Origin +
						"?" +
						encodeURIComponent(data.LastChanged)
					}
				/>
			)}
			{!data && (
				<ServError
					title={`${t("Desktop.ERROR")}: ${props?.error?.response?.status
						}`}
					text={t("Docs.LOAD_DOC_ERROR")}
				/>
			)}
			{isSharing && (
				<DocumentSharePopper setIsSharing={setIsSharing} doc={data} />
			)}
		</div>
	);
}

function DocumentSharePopper(props) {
	const { doc } = props;
	const ref = React.useRef();

	useOutsideAlerter(ref, () => props.setIsSharing(false));

	const copy = () => {
		navigator.clipboard.writeText(url);
		toast(t("Commons.LINK_COPIED_TO_CLIPBOARD"), {
			autoClose: 3000,
		});
	};

	const sendEmail = (message) => {
		var email = "";
		var subject = `[${t("Docs.SHARED_DOC")}] - ${doc.Name}`;
		var emailBody = doc.Url_Origin;
		document.location =
			"mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
	};

	let url = decodeURIComponent(doc.Url_Origin);

	return (
		<div className="DocSharer">
			<Modal
				isOpen={true}
				style={{
					content: {
						width: "90%",
						height: "fit-content",
						left: "45%",
						top: "10%",
						transform: "translate(-45%, 0%)",
					},
				}}
			>
				<div ref={ref} className="document-sharer-popper">
					<div className="d-flex align-items-center gap-2 mb-3">
						<BackButton onClick={() => props.setIsSharing(false)} />
						<div>{t("Docs.SHARE_DOC")}</div>
					</div>
					<DocumentTile
						doc={doc}
						className="w-100 preview-tile mb-3"
					/>
					<div className="mb-3">
						{t("Commons.LINK")}:
						<div
							className="link-field cursor-pointer"
							title={t("Commons.COPY_LINK")}
							onClick={copy}
						>
							{url}
						</div>
					</div>
					<div className="d-flex gap-2">
						<Button className="gap-2 btn-cyan" onClick={copy}>
							<FontAwesomeIcon icon={faCopy} />
							{t("Commons.COPY_LINK")}
						</Button>
						<Button className="gap-2 btn-green" onClick={sendEmail}>
							<FontAwesomeIcon
								icon={faEnvelope}
								style={{ marginBottom: "1px" }}
							/>
							{t("Commons.SEND_BY_MAIL")}
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

import clsx from "clsx";
import Button from "components/Button/Button";
import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";
import { useAccountContext } from "providers/AccountProvider";
import { RssContext, useRssContext } from "providers/RssProvider";
import { useState, useEffect } from "react";
import Select from "react-select";
import { RssService } from "service/rss.service";

export const RssFilter = () => {
    const [rssItems, setRssItems] = useState([]);
    const [activeElement, setActiveElement] = useState("");
    const { accountObj } = useAccountContext();
    const { setRssFilters, rssFilters } = useRssContext();

    const getRssFeeds = async (CompanyId) => {
        const data = await RssService.getRssFeeds(CompanyId);
        setRssItems(data.filter((item) => item.IsDeletable === true));
    };

    useEffect(() => {
        const { CompanyId } = accountObj.account;
        getRssFeeds(CompanyId);
    }, []);

    const onClick = (item) => {
        const { RssUrl, Id } = item;
        if (Id === activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(Id);
        }
        if (!rssFilters.includes(RssUrl)) {
            setRssFilters([RssUrl]);
        } else {
            setRssFilters(rssFilters.filter((item) => item !== RssUrl));
        }
    };

    return (
        <div className="rss-filters">
            {rssItems.map((item) => (<div className={clsx(activeElement === item.Id && "active")} onClick={() => onClick(item)}>{item.Name}</div>))}
        </div>
    );
};

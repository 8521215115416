import React from "react";
import { Draggable } from "react-beautiful-dnd";

// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./ImagesItems.css";
import { t } from "i18next";

export default function ImagesItems(props) {
	if (!props.images || !props.images?.length)
	{
		return (
			<div className="image-items-placeholder">
				{t("Commons.DROP")}
			</div>
		);
	}
	return (
		props.images.map((img, index) => {
			let dragId = img.DocumentId;
			if (img.IsDeleted)
				return (false);
			if (img.TempId)
				dragId = img.TempId;
			return (
				<DraggableImageItem
					img={img}
					handleDelete={props.handleDelete}
					index={index}
					key={dragId}
					onClick={props.onClick}
					{...props}
				/>
			);
		})
	);
}

function DraggableImageItem(props) {
	const {img, index, onClick, handleDelete} = props;

	function handleClick() {
		if (onClick)
			onClick(img);
	}

	let dragId = img.DocumentId;
	if (img.TempId)
		dragId = img.TempId;

	let bg_img = img.Url_Min;
	if (img.DocumentFile)
	{
		if (img.DocumentFile.indexOf("data:image") === -1)
			bg_img = "data:image/png;base64," + img.DocumentFile;
		else
			bg_img = img.DocumentFile;
	}

	return (
		<Draggable
			draggableId={dragId} index={index}
			isDragDisabled={props.isDragDisabled}
		>
			{(provided) => {
				return (
					<div
						onClick={handleClick}
						ref={provided?.innerRef}
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
					>
						<div className="image-preview" style={{backgroundImage: "url(" + bg_img + ")"}}>
							{
								handleDelete &&
									<div
										className="del-image-btn"
										title={t("Images.RM_IMAGE")}
										onClick={(e) => {
											e.preventDefault(); e.stopPropagation(); handleDelete(img);
										}}
									>
										<FontAwesomeIcon icon={faTimes}/>
									</div>
							}
						</div>
					</div>
				);
			}}
		</Draggable>
	);
}

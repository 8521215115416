import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";
import { t } from "i18next";
import useTemplate from "hooks/useTemplate";
import {useParams} from "react-router-dom";
function OffersPage(props) {
	const {account, isSup} = useAccount();
	const { template } = useTemplate();

	let perm = template.getAppFunction("PeoplePage.PostAd");
	let { id } = useParams();

	return (
		<PostsPage
			baseUrl={props.baseUrl}
			isAdmin={props.isAdmin}
			title={props.title}
			companyId={account.CompanyId}
			typeId={4}
			forceCurId={id}
			add={perm || isSup("SiteAdmin") >= 0}
			canComment={true}
			commentBtnTxt={t("Posts.REPLY")}
			showComments={false}
			isMenuOpen={props.isMenuOpen}
			setIsMenuOpen={props.setIsMenuOpen}
			platform={props.platform}
		/>
	);
}

export default OffersPage;

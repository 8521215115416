import React, {useState} from "react";
import genRequest from "includes/request";
import { usePopper } from "react-popper";
import {useQuery, useQueryClient} from "react-query";
import { ReactionButton } from "Pages/Reactions/AdminReactionsPage";
import "./ReactionsPopper.css";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function PostReactionsPopper({postId, close, referenceElement}) {
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "top"
	});

	const {isLoading, data} = useQuery(
		["Lt_PostAvailableReaction", "PostId", postId],
		() => genRequest("Lt_PostAvailableReaction/PostId/" + postId)
	);

	if (isLoading || !data?.length)
		return (false);

	return (
		<div
			className="ReactionsPopper"
			ref={setPopperElement}
			onMouseLeave={close}
			style={styles.popper}
			{...attributes.popper}
		>
			<ReactionsList item={data[0]} postId={postId}/>
		</div>
	);
}

export function PostReactions({postId, onClick, title, titleClassName}) {
	const {isLoading, data} = useQuery(
		["Lt_PostAvailableReaction", "PostId", postId],
		() => genRequest("Lt_PostAvailableReaction/PostId/" + postId)
	);

	if (isLoading || !data?.length)
		return (false);

	if (title)
		return (
			<div>
				<div className={"mb-1" + (titleClassName ? " " + titleClassName : "")}>{title}</div>
				<PostReactions postId={postId} onClick={false}/>
			</div>
		);
	return (
		<ReactionsList
			item={data[0]}
			postId={postId}
			onClick={onClick}
		/>
	);
}

export function ReactionsList(props) {
	const {account} = useAccount();
	const QCL = useQueryClient();
	const {item, postId, onClick} = props;

	const unReact = () => genRequest(
		"LT_Employe_Reaction_Post/EmployeIdAndPostIdAndReactionTypeId/" + account.EmployesId + "/" + postId,
		null,
		"delete"
	);

	const sendReact = (reaction) => genRequest(
		"Lt_Employe_Reaction_Post",
		{
			ReactionId: reaction.ReactionId,
			EmployesId: account.EmployesId,
			PostId: postId
		},
		"post"
	);

	const handleClick = (reac) => {
		if (onClick === false)
			return (false);
		else if (onClick)
			onClick(reac);
		else {
			let req = unReact()
				.then(() => sendReact(reac))
				.then(() => {
					QCL.resetQueries(["Reactions", "Typed", item.ReactionTypeId]);
				});
			return (req);
		}
	};

	const {data, isLoading} = useQuery(
		["Reactions", "Typed", item.ReactionTypeId],
		() => genRequest("Reactions/Typed/" + item.ReactionTypeId)
	);

	if (isLoading || !data?.length)
		return (false);

	return (
		<div
			className={"ReactionListCell d-flex w-100 justify-content-around align-items-center px-2" + (onClick === false ? " pe-none" : "")}>
			{
				data.map((a) => {
					return (
						<ReactionButton
							key={a.ReactionId}
							{...a}
							onClick={() => handleClick(a)}
							countQueryKey={["LT_Employe_Reaction_Post", "PostIdAndReactionId", postId, a.ReactionId]}
							countQueryFn={() => genRequest("LT_Employe_Reaction_Post/PostIdAndReactionId/" + postId + "/" + a.ReactionId)}
						/>
					);
				})
			}
		</div>
	);
}


import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import UserImage from "components/User/Image/UserImage";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";
import { components } from "react-select";

const { Option } = components;

export default function UserAnswerSelect(props) {
	const {formId, isAnonymous} = props;

	return (
		<QuerySelect
			queryKey={["Employes", "EmployeAnsweredForm", formId]}
			queryFn={() => genRequest("Employes/EmployeAnsweredForm/" + formId)}
			treat={(a, b) => ({
				label: (isAnonymous ? ("Utilisateur " + (b + 1)) : (a.FirstName + " " + a.LastName)),
				value: a.EmployesId,
				user: a,
				isAnonymous
			})}
			components={{Option: SelectOption, Control: SelectLabel}}
			all={{value: 0, label: t("Forms.ALL_ANSWERS")}}
			{...props}
		/>
	);
}

const SelectLabel = ({ children, ...props }) => {
	let value = props.getValue();

	return (
		<components.Control {...props}>
			{
				value?.length > 0 && !value[0].isAnonymous ?
					<UserImage className="ms-1" width={"30px"} employeId={value[0].user?.EmployesId}/>
					: false
			}
			{children}
		</components.Control>
	);
};

const SelectOption = (props) => {
	let user = props.data.user;

	return (
		<Option {...props} className="d-flex align-items-center gap-2">
			{
				(user && !props.data.isAnonymous) ?
					<>
						<UserImage width={20} employeId={user.EmployesId}/>
						{user.FirstName} {user.LastName}
					</>
					:
					props.data.label
			}
		</Option>
	);
};

import React, { useState } from "react";
import PasswordInput from "components/Inputs/PasswordInput";
import generateUUID from "includes/UUID";
import genRequest from "includes/request";
import { t } from "i18next";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import { toast } from "react-toastify";
import Button from "components/Button/Button";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function PasswordChangerForm(props) {
	const {resetToken, disconnect} = useAccount();
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [newPassVerif, setNewPassVerif] = useState("");
	const [isSending, setIsSending] = useState();
	const [UUID] = useState(generateUUID());
	const [errors, setErrors] = useState([]);

	function checkInfos() {
		let check_errors = [];
		let passReg = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?.,;!@$%^&*-]).{8,}$", "g");

		if (oldPass === "")
			check_errors.push(t("Password.EMPTY_OLD_ERROR"));
		if (!passReg.test(newPass))
			check_errors.push(genNotValidError());
		if (newPass !== newPassVerif)
			check_errors.push(t("Password.NO_MATCH_ERROR"));
		setErrors(check_errors);
		return (!(check_errors.length > 0));
	}

	function genNotValidError() {
		return (
			<>
				<div>
					{t("Password.NOT_VALID_ERROR")}<br/>
					{t("Password.LENGTH_ERROR")}<br/>
					{t("Password.CHARS_ERROR")}<br/>
					{t("Password.SPEC_CHARS_ERROR")}<br/>
				</div>
			</>
		);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!checkInfos())
			return (Promise.reject(false));
		setIsSending(true);
		let data = {
			OldPassword: oldPass,
			NewPassword: newPass,
			ConfirmPassword: newPassVerif
		};
		setErrors([]);
		let req = genRequest("Account/ChangePassword", data, "post").then(() => {
			resetToken().then(() => {
				resetForm();
				disconnect(t("Accounts.DISCONNECTING"), 3000);
			});
		}).catch((error) => {
			if (error.response) {
				if (error.response.data) {
					if (error.response.data.ModelState[""][0].search("Incorrect") > -1)
						setErrors(t("Password.OLD_ERROR"));
				}
			} else {
				console.error("Error", error.message);
			}
			setIsSending(false);
			return (Promise.reject(false));
		});
		toast.promise(
			req,
			{
				pending: t("Toasts.PROM_PENDING"),
				success: t("Toasts.PROM_SUCCESS"),
				error: t("Toasts.PROM_ERROR"),
				autoClose: 7000
			}
		);
		return (req);
	};

	function resetForm()
	{
		document.querySelectorAll("input[formid='" + UUID + "']").forEach(a => {a.value = "";});
		setOldPass("");
		setNewPass("");
		setNewPassVerif ("");
		setIsSending(false);
		setErrors([]);
	}

	let hasChanged = (oldPass.length || newPass.length || newPassVerif.length);

	if (!hasChanged && errors.length)
		setErrors([]);

	if (props.disabled && props.disabledMsg)
		return (<div>{props.disabledMsg}</div>);
	return (
		<form onSubmit={handleSubmit}>
			<PasswordInput disabled={props.disabled ? true : false} formid={UUID} className="w-100 mb-3" title={t("Password.OLD_PASS")} onChange={setOldPass}/>
			<PasswordInput disabled={props.disabled ? true : false} formid={UUID} className="w-100 mb-3" title={t("Password.NEW_PASS")} onChange={setNewPass}/>
			<PasswordInput disabled={props.disabled ? true : false} formid={UUID} className="w-100" title={t("Password.CONFIRM_NEW_PASS")} onChange={setNewPassVerif}/>
			<ErrorsCont className="mt-2" errors={errors}/>
			{
				(
					hasChanged ?
						<div className="d-flex mt-3">
							<Button className="btn btn-primary btn-save-infos" disabled={isSending ? true : false} type="submit">{isSending ? t("Commons.SENDING") : t("Password.SAVE_PASS")}</Button>
						</div>
						:
						false
				)
			}
		</form>
	);
}

import {flatten} from "flat";
import { b64ToText, toBase64 } from "includes/base64";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";

function AppTemplate(template, values) {

	let Template = (template ? template : {
		AppTemplateId: null,
		AppTemplateFile: null
	});

	if (typeof template === "string")
		Template = {
			AppTemplateId: template,
			AppTemplateFile: null
		};

	let Values;

	this.setValues = function(vals) {
		try {
			if (!vals && Template.AppTemplateFile)
				Values = JSON.parse(b64ToText(Template.AppTemplateFile));
			else
				Values = vals;
		} catch (e) {
			Values = false;
			throw (e);
		}
	};

	this.fetch = function() {
		let that = this;
		let req = genRequest(
			"AppTemplates/" + Template.AppTemplateId
		).then((resp) => {
			Template = resp;
			that.setValues();
			return ({Template, Values});
		});
		return (req);
	};

	this.values = function(flat, forSend) {
		if (!Template)
			return (false);
		if (flat)
			return (flatten(Values));
		if (forSend)
			return ({
				AppTemplateFile: toBase64(JSON.stringify(Values)),
				AppTemplateId: Template.AppTemplateId
			});
		return (Values);
	};

	this.getMenus = function(menuName) {
		if (!Values?.MenuIcons)
			return (false);
		if (menuName)
			return (
				Values.MenuIcons[menuName] ?
					Values.MenuIcons[menuName]
					: false
			);
		return (Values.MenuIcons);
	};

	this.setMenus = function(values) {
		Values.MenuIcons = values;
		return (Values.MenuIcons);
	};

	this.send = function() {
		Template.AppTemplateFile = toBase64(JSON.stringify(Values));
		let req = genRequest(
			"Apptemplates/TemplateFromString" + (Template.AppTemplateId ? "?id=" + Template.AppTemplateId : ""),
			{
				"TemplateId": Template.AppTemplateId || generateUUID(),
				"FileTemplateString": Template.AppTemplateFile
			},
			(Template.AppTemplateId ? "put" : "post")
		).then((resp) => {
			if (!Template.AppTemplateId)
				Template.AppTemplateId = resp;
			return (Template.AppTemplateId);
		});
		return (req);
	};

	this.setAppFunctions = function(values) {
		Values.AppFunctions = values;
		for (let x in values)
		{
			let af = values[x];
			if (af.rootId)
				Values[af.rootId] = af.checked;
		}
	};

	this.getAppFunctions = function(flat) {
		let template = this.values();

		if (template)
		{
			if (flat)
				return (flatten(template.AppFunctions));
			return (template.AppFunctions);
		}
		return (false);
	};

	this.getAppFunction = function(id) {
		let funcs = this.getAppFunctions(true);
		if (funcs[id])
			return (funcs[id]);
		return (false);
	};

	this.primaryColor = function(setValue) {
		let template = this.values();

		if (setValue)
			return (Values.PrimaryColorNavigation = setValue);
		if (template)
			return (template.PrimaryColorNavigation);
		return ("#15acbd");
	};

	this.secondaryColor = function(setValue) {
		let template = this.values();

		if (setValue)
			return (Values.SecondaryColorNavigation = setValue);
		if (template)
			return (template.SecondaryColorNavigation);
		return ("#15acbd");
	};

	this.adminSiteId = (setId) => {
		if (setId !== "undefined")
			Values.Company.AdminSiteId = setId;
		return (Values.Company.AdminSiteId);
	};

	this.lastChanged = () => (Template?.LastChanged);

	this.setValues(values);

	this.getValue = (key) => (Values[key] ? Values[key] : false);
}

export default AppTemplate;

import React, { memo } from "react";
import Moment from "react-moment";
import { t } from "i18next";
import formatDate from "includes/formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import ChartsTableToolbar from "./ChartsTableToolbar";
import useAccount from "classes/Accounts/hooks/useAccount";
import Table from "components/Table/Table";
import useUnread from "classes/Accounts/hooks/useUnread";
import { NewLabel } from "components/Tags/Label/TagLabel";

export default function ChartsTable(props) {
	const { isAdmin } = useAccount();
	const cols = [
		{
			title: t("Table.TITLE"),
			field: "Title",
			classes: "post-title-cell",
			render: (data) => <TitleCell item={data} />,
		},
		{
			title: "Position",
			field: "Position",
			classes: "post-order-cell",
			hidden: !isAdmin(),
		},
		{
			title: t("Posts.LAST_CHANGED"),
			field: "LastChanged",
			classes: "post-date-cell",
			render: (data) => <DateCell item={data} />,
		},
	];

	return (
		<div className="ChartsTable d-flex flex-column overflow-hidden">
			<Table
				columns={cols}
				data={props.charts}
				rowIdKey={"ChartId"}
				activeRowId={props.curChart?.ChartId}
				options={{
					selection: isAdmin(),
				}}
				onRowClick={(e, item) => props.setCurChart(item)}
				overloads={{
					Toolbar: (cprops, options) => (
						<ChartsTableToolbar {...cprops} {...options} />
					),
				}}
			/>
		</div>
	);
}

const TitleCell = ({ item }) => {
	const { isReaded } = useUnread();
	let isViewed = isReaded("NotViewedChart", item.ChartId);

	return (
		<div className="d-flex align-item-center">
			{!isViewed && <NewLabel className="me-1" />}
			{item.Title}
		</div>
	);
};

const DateCell = ({ item }) => {
	let date = formatDate();

	return (
		<div className="d-flex align-items-center flex-wrap gap-1 justify-content-between">
			<Moment
				titleFormat="D MMM YYYY HH:mm"
				withTitle
				format={t("Events.TILE_DATE_FORMAT")}
			>
				{item.PublishedDate ? item.PublishedDate : item.LastChanged}
			</Moment>
			{(item.PriorityTo >= date || item.PublishedDate >= date) && (
				<div className="d-flex flex-wrap gap-1 align-items-center justify-content-center me-1 date-pictos">
					{item.PublishedDate >= date && (
						<div
							title={`${t("Commons.DEFERRED_TO")} ${formatDate(
								item.PublishedDate,
								t("Commons.CUSTOM_FORMAT_DATE")
							)}`}
						>
							<FontAwesomeIcon
								className="differed-picto"
								title={t("Commons.DEFERRED_PUBLISH")}
								icon={faCalendarAlt}
							/>
						</div>
					)}
					{item.PriorityTo >= date && (
						<div
							title={`${t("Commons.PRIORITY_UNTIL")} ${formatDate(
								item.PriorityTo,
								t("Commons.CUSTOM_FORMAT_DATE")
							)}`}
						>
							<FontAwesomeIcon
								className="priority-picto"
								title={t("Commons.PRIORITY_PUBLISH")}
								icon={faThumbtack}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
DateCell.displayName = "DateCell";

import React, { useState } from "react";
import {
	CustomSelect,
	QuerySelect,
} from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { components } from "react-select";
import FormInput from "components/Inputs/FormInput";
import "./CustomPagesNames.css";
import { PICTOS_URL } from "includes/paths";
import Button from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import generateUUID from "includes/UUID";
import { t } from "i18next";
import { toast } from "react-toastify";
import genRequest from "includes/request";
import useToken from "classes/Accounts/hooks/useToken";

const { Option } = components;

const getPages = () => [
	{
		Title: t("Pages.NEWS"),
		PageId: "Menu1",
		IconName: "News_Picto.png",
		IsMenu: true,
		IsDefault: true,
	},
	{
		Title: t("Pages.CORPORATE"),
		PageId: "Menu2",
		IconName: "Corporate_Picto.png",
		IsMenu: true,
		IsDefault: true,
	},
	{
		Title: t("Pages.PEOPLES"),
		PageId: "Menu3",
		IconName: "People_Picto.png",
		IsMenu: true,
		IsDefault: true,
	},
	{
		Title: t("Pages.CONTACTS"),
		PageId: "Menu4",
		IconName: "Contact_Picto.png",
		IsMenu: true,
		IsDefault: true,
	},
	{
		Title: "Menu 5",
		PageId: "Menu5",
		IconName: "",
		IsMenu: true,
		IsDefault: true,
		IsDisabled: true,
	},
	{
		Title: t("Pages.PRO_OFFERS"),
		PageId: "ProOffers",
		IconName: "Pro_Offers_Picto.png",
		IsDisabled: true,
	},
	{
		Title: t("Pages.OFFERS"),
		PageId: "Offers",
		IconName: "Offers_Picto.png",
		IsDisabled: true,
	},
	{
		Title: t("Pages.CAREERS"),
		PageId: "Careers",
		IconName: "Careers_Picto.png",
		IsDisabled: true,
	},
	{
		Title: t("Pages.ARTICLES"),
		PageId: "About",
		IconName: "Articles_Picto.png",
		IsDisabled: true,
	},
	{
		Title: t("Pages.CHARTS"),
		PageId: "Charts",
		IconName: "Charts_Picto.png",
		IsDisabled: true,
	},
	{
		Title: t("Pages.DOCUMENTS"),
		PageId: "Documents",
		IconName: "Docs_Picto.png",
		IsDisabled: true,
	},
	{
		Title: t("Pages.AGENDA"),
		PageId: "Agenda",
		IconName: "Animations_Picto.png",
		IsDisabled: true,
	},
];

export default function CustomPagesNames(props) {
	const Pages = getPages();
	const { template } = props;

	const initValues = () => {
		let list = genList();
		return list;
	};

	const lastUpdate = React.useRef(Date.now());
	const [Values, setValues] = useState(initValues());

	function genList() {
		let ret = Pages.map((a) => {
			let exist = template.getMenus(a.PageId ? a.PageId : a.Id);
			if ((exist || a.IsDefault) && !a.IsDisabled)
				return exist
					? {
							...a,
							...exist,
							DefaultTitle: a.Title,
							DefaultIcon: a.IconName,
					  }
					: a;
			return false;
		}).filter((a) => a);
		return ret;
	}

	function getFirstUnused() {
		let ret = Pages.filter((a) => {
			if (a.IsDisabled) return false;
			let exist = Values.find((b) => b.PageId === a.PageId);
			return exist ? false : a;
		});
		if (!ret.length) return false;
		return ret[0];
	}

	function addTitle() {
		let first = getFirstUnused();
		let item = {
			PageId: generateUUID(),
			...first,
			DefaultTitle: first.Title,
			LastUpdate: Date.now(),
		};
		setValues([...Values, item]);
	}

	function updateItem(pageId, values) {
		let index = Values.findIndex((a) => a.PageId === pageId);

		if (index === -1) return false;
		let ret = [...Values];
		ret[index] = values;
		lastUpdate.current = Date.now();
		setValues(ret);
	}

	function removeItem(pageId) {
		let index = Values.findIndex((a) => a.PageId === pageId);

		if (index === -1) return false;
		let vals = [...Values];
		vals.splice(index, 1);
		setValues(vals);
	}

	function saveItems() {
		let ret = {};

		Values.forEach((a) => {
			ret[a.PageId] = {
				IconName: a.IconName,
				Title: a.Title || a.DefaultTitle,
			};
		});
		updateAppMenus(ret);
	}

	function updateAppMenus(menus) {
		let proms = [];

		if (props.Templates?.length) {
			for (let x in props.Templates) {
				props.Templates[x].setMenus(menus);
				proms.push(props.Templates[x].send());
			}
			props.setTemplate(props.Templates[0].values());
		}

		let prom = Promise.all(proms).then(() => {
			props.forceRefresh();
		});

		toast.promise(prom, {
			pending: t("Toasts.PROM_PENDING"),
			success: t("Toasts.PROM_SUCCESS"),
			error: t("Toasts.PROM_ERROR"),
			autoClose: 7000,
		});
		return prom;
	}

	// const checkChanges = () => {
	// 	let res = Values.filter((a) => a.LastUpdate || a.LastReset)

	// 	return (res?.length ? true : false )
	// }

	return (
		<div className={"CustomPagesNames " + props.className}>
			<h5>{t("Appearance.CUSTOM_TITLES")}</h5>
			<i>{t("Appearance.CUSTOM_TITLES_MSG")}</i>
			<div className="d-flex flex-column gap-2 mt-3">
				{Values.map((a) => {
					if (a.IsDisabled) return false;
					return (
						<PageCustomInfos
							key={a.PageId}
							lastUpdate={lastUpdate.current}
							page={a}
							title={a.Title}
							onChange={updateItem}
							onDelete={removeItem}
							used={Values}
						/>
					);
				})}
			</div>
			<div className="d-flex gap-2 mt-4">
				{getFirstUnused() && (
					<Button
						className="btn-blue"
						text={t("Appearance.ADD_CUSTOM_TITLE")}
						onClick={addTitle}
					/>
				)}
				<Button
					className="btn-green"
					text={t("Commons.SAVE")}
					onClick={saveItems}
				/>
			</div>
		</div>
	);
}

function PageCustomInfos(props) {
	const { page, onChange, onDelete, used } = props;
	if (!page) return false;

	const Pages = getPages();

	const genOptions = () => {
		let ret = Pages.map((a) => {
			let id = a.PageId ? a.PageId : a.Id;
			let isUsed = used.findIndex((b) => b.PageId === id) !== -1;
			if (a.IsDisabled || isUsed) return false;
			return {
				...a,
				DefaultTitle: a.Title,
				DefaultIcon: a.IconName,
				label: a.Title,
				value: id,
				PageId: id,
				icon: a.IconName,
			};
		}).filter((a) => a);
		return ret;
	};

	const updatePage = (item) => {
		item.LastUpdate = Date.now();
		onChange(page.PageId, item);
	};

	const updateTitle = (value) => {
		let item = { ...page };
		item.Title = value;
		item.LastUpdate = Date.now();
		updatePage(item);
	};

	const updateIcon = (icon) => {
		let item = { ...page };
		item.IconName = icon.value;
		updatePage(item);
	};

	const deleteItem = () => {
		onDelete(page.PageId);
	};

	const resetItem = () => {
		let date = Date.now();
		let res = {
			...page,
			Title: page.DefaultTitle,
			IconName: page.DefaultIcon,
			LastReset: date,
		};
		updatePage(res);
	};

	let icon = page.IconName || page.DefaultIcon;

	return (
		<div
			className={
				"PageCustomInfos d-flex gap-2 w-100 align-items-center" +
				(page.IsDefault ? " default" : "")
			}
		>
			{!page.IsDefault ? (
				<Button
					className="w-auto unstyled delete-btn"
					onClick={deleteItem}
					title="Supprimer le titre personnalisé"
				>
					<FontAwesomeIcon icon={faTrashAlt} color="red" />
				</Button>
			) : (
				<Button
					className="w-auto unstyled reset-btn"
					onClick={resetItem}
					title="Remettre les valeurs par défaut"
				>
					<FontAwesomeIcon icon={faUndo} color="#0080B9" />
				</Button>
			)}
			<div className="w-100">
				<CustomSelect
					className="IconsSelect light-bg"
					components={{ Option: PageOption, Control: PageLabel }}
					options={genOptions()}
					initialValue={{
						label: page.DefaultTitle,
						value: page.PageId,
						icon: page.IconName,
					}}
					onChange={updatePage}
					isDisabled={page.IsDefault}
				/>
			</div>
			<div className="w-100">
				<FormInput
					value={page.Title}
					onChange={updateTitle}
					externvalue={"true"}
				/>
			</div>
			<div className="w-100">
				<IconsSelect
					key={props.lastUpdate}
					onChange={updateIcon}
					initialValue={{ label: icon, value: icon, icon: icon }}
				/>
			</div>
		</div>
	);
}

const IconsSelect = (props) => {
	const { token } = useToken();

	const fetchPictos = () => {
		let params = new URLSearchParams({
			token,
		}).toString();

		return genRequest("https://pictos.onlyall.fr/?" + params, null, "get", {
			forceUrl: true,
		});
	};

	return (
		<QuerySelect
			queryKey={["Pictos"]}
			queryFn={fetchPictos}
			className="IconsSelect light-bg"
			components={{ Option: PageOption, Control: PageLabel }}
			// options={genOptions()}
			menuPosition={"fixed"}
			isMulti={false}
			treat={(a) => {
				return {
					label: a,
					value: a,
					icon: a,
				};
			}}
			{...props}
		/>
	);
};

const PageLabel = ({ children, ...props }) => {
	let value = props.getValue();
	let icon = value[0];

	return (
		<components.Control {...props} className="ps-2">
			<PageIcon {...icon} /> {children}
		</components.Control>
	);
};

const PageIcon = (props) => {
	if (!props.icon) return false;
	return (
		<img
			loading="lazy"
			src={PICTOS_URL + encodeURIComponent(props.icon)}
			style={{ width: props.width || 20 }}
			alt={props.label}
			className={props.className}
		/>
	);
};

const PageOption = (props) => {
	return (
		<Option {...props} className="d-flex align-items-center">
			<img
				loading="lazy"
				src={PICTOS_URL + encodeURIComponent(props.data.icon)}
				style={{ width: 20 }}
				alt={props.data.label}
				className="me-2"
			/>
			{props.data.label}
		</Option>
	);
};

import markerSDK from "@marker.io/browser";
import useAccount from "classes/Accounts/hooks/useAccount";
import useToken from "classes/Accounts/hooks/useToken";
import { t } from "i18next";
import React, { useState } from "react";
import "./MarkerIoButton.css";

export default function MarkerIoButton() {
	const [Widget, setWidget] = useState(false);
	const { token } = useToken();
	const { account } = useAccount();
	const initied = React.useRef(false);

	const initWidget = async () => {
		const widget = await markerSDK.loadWidget({
			project: "6400af931e234965e1cde5e7",
		});

		if (account.EmployesId && token) {
			widget.setReporter({
				email: account.Mail || account.Login,
				fullName: account.LastName + " " + account.FirstName,
			});
			widget.setCustomData({
				EmployeId: account.EmployesId,
				CompanyId: account.CompanyId,
				SiteId: account.siteId,
				Role: account.Role,
				AspNetUserId: account.AspNetUserId,
				UserTemplateId: account.UserTemplateId,
				FirstConnexion: account.FirstConnexion,
				LastConnexion: account.LastConnexion,
				Token_Regeneration: account.Token_Regeneration,
				ByPass_Moderation: account.ByPass_Moderation,
				IsVisibleInApp: account.IsVisibleInApp,
				WorkFamillyId: account.WorkFamillyId,
				WorkFunctionId: account.WorkFunctionId,
				ClientVersion: process.env.REACT_APP_VERSION,
			});
		}
		widget.hide();
		widget.on("show", () => {
			widget.hide();
		});
		setWidget(widget);
	};

	React.useEffect(() => {
		if (!initied?.current) initWidget();
		initied.current = true;
	}, []);

	if (!Widget) return false;
	return (
		<div className="markerio-io-btn" onClick={() => Widget?.capture()}>
			{t("Commons.REPORT_BTN")}
		</div>
	);
}

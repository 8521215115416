import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import { getChartType } from "./Types/ChartTypes";

class ChartObject {
	#ChartId = null;
	#ChartTypeId = null;
	#Title = "";
	#Position = null;
	#Chartvalues = "";
	#TitleColorHex = "";
	#LastChanged = null;
	#LastChange_AuthorId = null;
	#IsDraft = true;
	#IsTopTitled = true;

	#CompanyId = false;
	#IsLinked = false;
	#Categories = [];
	#Notification = true;
	#Options = {};

	constructor (props) {
		this.#setValues(props);
		if (props.ChartId)
			this.#Notification = false;
		this.#CompanyId = (props?.CompanyId || null);
	}

	#setValues(vals) {
		this.#ChartId = (vals?.ChartId || null);
		this.#ChartTypeId = (vals?.ChartTypeId || null);
		this.#Title = (vals?.Title || null);
		this.#Position = (vals?.Position || 0);
		this.#Chartvalues = (vals?.Chartvalues || null);
		this.#TitleColorHex = (vals?.TitleColorHex || "#000000");
		this.#LastChanged = (vals?.LastChanged || formatDate());
		this.#LastChange_AuthorId = (vals?.LastChange_AuthorId || null);
		this.#IsDraft = (vals?.IsDraft || false);
		this.#IsTopTitled = (vals?.IsTopTitled || true);

		if (this.#ChartTypeId)
			this.SFChartType = getChartType(this.#ChartTypeId);
		return (this.values());
	}

	fetchForEdit() {
		let proms = [
			this.fetchCompanyLink(),
			this.fetchCategories()
		];

		return (Promise.all(proms));
	}

	fetchCompanyLink() {
		let that = this;
		if (!this.#ChartId)
			return (Promise.resolve(true));
		let prom = genRequest(
			"LtChartCompanies/ChartId/" + this.#ChartId
		).then((resp) => {
			if (!resp?.length)
				that.#IsLinked = false;
			else if (!that.#CompanyId)
			{
				that.#CompanyId = resp[0]?.CompanyId;
				that.#IsLinked = true;
			}
		});
		return (prom);
	}

	fetchCategories = () => genRequest("LtChartCategories/ChartId/" + this.#ChartId).then((resp) => {
		this.#Categories = resp;
		return (resp);
	});

	send() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			that.sendChart()
				.then(() => {
					let proms = [
						this.unlinkCategories()
					];
					return (Promise.all(proms));
				})
				.then(() => {
					return (that.linkCompany());
				}, reject)
				.then(() => {
					return (that.linkCategories());
				}, reject)
				.then(() => {
					resolve(true);
					return (true);
				}, reject);
		});
		return (prom);
	}

	sendChart() {
		let that = this;
		let prom = genRequest(
			`Charts${this.#ChartId ? "/" + this.#ChartId : ""}`,
			this.values(),
			this.#ChartId ? "put" : "post"
		).then((resp) => {
			if (!that.#ChartId && resp?.ChartId)
				that.#setValues({...resp, CompanyId: that.#CompanyId});
			return (that.values());
		});
		return (prom);
	}

	linkCompany() {
		if (!this.#CompanyId || !this.#ChartId)
			return (Promise.reject("Company link error: No CompanyId or ChartId provided"));
		if (this.#IsLinked)
			return (Promise.resolve(true));
		let prom = genRequest(
			"LtChartCompanies",
			{
				ChartId: this.#ChartId,
				CompanyId: this.#CompanyId
			},
			"post"
		);
		return (prom);
	}

	notify() {
		if (!this.#Notification || !this.#ChartId || this.#IsDraft)
			return (Promise.resolve(true));
		let prom = genRequest(
			"PushNotification/IndicatorNotifyCompany/" + this.#ChartId,
			null,
			"post"
		);
		return (prom);
	}

	unlinkCategories() {
		if (!this.#ChartId)
			return (Promise.reject("Categories link error: No ChartId provided"));
		let req = genRequest(
			"LtChartCategories/ChartId/" + this.#ChartId,
			null,
			"delete"
		);
		return (req);
	}

	linkCategories() {
		let proms = this.#Categories.map((a) => this.linkCategory(a.CategoryId));
		return (Promise.all(proms));
	}

	linkCategory(catId) {
		if (!catId || !this.#ChartId)
			return (Promise.reject("Category link error: No CategoryId or ChartId provided"));
		let req = genRequest(
			"LtChartCategories",
			{
				ChartId: this.#ChartId,
				CategoryId: catId
			},
			"post"
		);
		return (req);
	}

	chartId(setChartId) {
		if (typeof setChartId !== "undefined")
			this.#ChartId = setChartId;
		return (this.#ChartId);
	}

	chartTypeId(setChartTypeId) {
		if (typeof setChartTypeId !== "undefined")
		{
			this.#ChartTypeId = setChartTypeId;
			if (this.#ChartTypeId)
				this.SFChartType = getChartType(this.#ChartTypeId);
		}
		return (this.#ChartTypeId);
	}

	title(setTitle) {
		if (typeof setTitle !== "undefined")
			this.#Title = setTitle;
		return (this.#Title);
	}

	position(setPosition) {
		if (typeof setPosition !== "undefined")
			this.#Position = setPosition;
		return (this.#Position);
	}

	chartvalues(setChartvalues) {
		if (typeof setChartvalues !== "undefined")
			this.#Chartvalues = setChartvalues;
		return (this.#Chartvalues);
	}

	parsedChartValues() {
		let vals = this.chartvalues();
		let ret = false;
		try {
			ret = JSON.parse(vals);
		} catch (e) {
			return (false);
		}
		return (ret);
	}

	titleColorHex(setTitleColorHex) {
		if (typeof setTitleColorHex !== "undefined")
			this.#TitleColorHex = setTitleColorHex;
		return (this.#TitleColorHex);
	}

	lastChanged(setLastChanged) {
		if (typeof setLastChanged !== "undefined")
			this.#LastChanged = setLastChanged;
		return (this.#LastChanged);
	}

	lastChange_AuthorId(setLastChange_AuthorId) {
		if (typeof setLastChange_AuthorId !== "undefined")
			this.#LastChange_AuthorId = setLastChange_AuthorId;
		return (this.#LastChange_AuthorId);
	}

	isDraft(setIsDraft) {
		if (typeof setIsDraft !== "undefined")
			this.#IsDraft = setIsDraft;
		return (this.#IsDraft);
	}

	isTopTitled(setIsTopTitled) {
		if (typeof setIsTopTitled !== "undefined")
			this.#IsTopTitled = setIsTopTitled;
		return (this.#IsTopTitled);
	}

	options(key, value, ifNotSetted) {
		if (
			key
			&& typeof value !== "undefined"
			&& (!ifNotSetted || (ifNotSetted && typeof this.#Options[key] === "undefined"))
		)
			this.#Options[key] = value;
		return (key ? this.#Options[key] : this.#Options);
	}

	categories(setCats) {
		if (typeof setCats !== "undefined")
			this.#Categories = setCats;
		return (this.#Categories);
	}

	notification(setNotify) {
		if (typeof setNotify !== "undefined")
			this.#Notification = setNotify;
		return (this.#Notification);
	}

	values() {
		let ret = {
			ChartTypeId: this.#ChartTypeId,
			Title: this.#Title,
			Position: this.#Position,
			Chartvalues: this.#Chartvalues,
			TitleColorHex: this.#TitleColorHex,
			LastChanged: this.#LastChanged,
			LastChange_AuthorId: this.#LastChange_AuthorId,
			IsDraft: this.#IsDraft,
			IsTopTitled: this.#IsTopTitled,
		};
		if (this.#ChartId)
			ret.ChartId = this.#ChartId;
		return (ret);
	}
}

export default ChartObject;

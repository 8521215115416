import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisVertical, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackButton from "components/Button/BackButton";
import Button from "components/Button/Button";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import TagLabel from "components/Tags/Label/TagLabel";
import generateUUID from "includes/UUID";
import React, { useState } from "react";
import ReactModal from "react-modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StepTarget from "./StepTarget";

const killEvent = (e) => {
	if (!e)
		return (false);
	e.preventDefault();
	e.stopPropagation();
}

export function TourPreviewModal(props) {
	return (
		<ReactModal isOpen={true}>
			<div className="TourPreviewModal no-highlight d-flex flex-column overflow-hidden h-100 gap-2">
				<div>
					<BackButton onClick={props.onClose}/>
				</div>
				<div className="h-100 overflow-auto">
					<TourPreview {...props}/>
				</div>
			</div>
		</ReactModal>
	)
}

export default function TourPreview(props) {
	const [, setLastUpdate] = useState(false);
	let steps = props.tour.steps;

	const rmStep = (index) => {
		props.tour.steps.splice(index, 1);
		setLastUpdate(Date.now());
		props.setTour(props.tour);
	}

	const updateStep = (index, value) => {
		props.tour.steps[index] = value;
		setLastUpdate(Date.now());
		props.setTour(props.tour);
	}
	
	const copyStep = (index) => {
		let step = {...props.tour.steps[index]};
		step.title += " copie"
		step.stepId = generateUUID();
		props.tour.steps.splice(index + 1, 0, step)
		setLastUpdate(Date.now());
		props.setTour(props.tour);
	}

	const onDragEnd = (result) => {
		let steps = [...props.tour.steps];
		if (!(result.source && result.destination))
			return (false);
		let spliced = steps.splice(result.source.index, 1)
		steps.splice(result.destination.index, 0, spliced[0])
		props.tour.steps = steps;
		setLastUpdate(Date.now());
		props.setTour(props.tour);
	}

	return (
		<div className="no-highlight">
			<DragDropContext onDragEnd={onDragEnd} className="h-100">
				<div className="d-flex flex-column gap-2">
					<Droppable
						droppableId="tour-preview-steps-cont"
						direction="vertical"
					>
						{
						(provided) => {
							return (
								<div
									className="d-flex flex-column gap-2 mt-3"
									ref={provided.innerRef} {...provided.droppableProps}
								>
									{
										steps.length > 0 ?
											steps?.map((a, b) => (
												<TourPreviewItem
													key={a.stepId}
													index={b}
													step={a}
													onDelete={(e) => {killEvent(e); rmStep(b)}}
													onUpdate={(val) => {updateStep(b, val)}}
													onCopy={() => {copyStep(b)}}
													highlight={props.highlight}
												/>
											))
											:
											"Aucune étape dans le tutoriel"
									}
									{provided.placeholder}
								</div>
							)
						}
					}
					</Droppable>
				</div>
			</DragDropContext>
		</div>
	)
}

function TourPreviewItem(props) {
	const {
		step,
		onDelete,
		onCopy,
		onUpdate,
		highlight,
		index
	} = props;

	return (
		<Draggable
			draggableId={step.stepId}
			index={index}
			isDragDisabled={props.isDragDisabled}
		>
			{
				(provided) => {
					return (
						<div
							ref={provided?.innerRef}
							{...provided?.draggableProps}
						>
							<div className="TourPreviewItem d-flex justify-content-between align-items-center bg-color p-2 radius-2 gap-2">
								<div
									className={"item-drag-handle me-1"}
									{...provided?.dragHandleProps}
									onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
								>
									<FontAwesomeIcon icon={faEllipsisVertical} color="grey"/>
								</div>
								<div className="w-100">
									<div className="d-flex align-items-start justify-content-between gap-2 mb-2">
										<div>
											<b>{step.title}</b>
											<div className="font-italic">{step.content}</div>
										</div>
										<div className="buttons-group">
											<Button onClick={onCopy}>
												<FontAwesomeIcon icon={faCopy} color="var(--color-primary)"/>
											</Button>
											<Button onClick={onDelete}>
												<FontAwesomeIcon icon={faTrashAlt} color="red"/>
											</Button>
										</div>
									</div>
									<hr></hr>
									<div className="d-flex flex-column gap-2" style={{fontSize: "14px"}}>
										{step.page?.length > 0 && <div>Page: <TagLabel style={{fontSize: "12px"}}>{step.page.replace("/", "")}</TagLabel></div>}
										<SwitchInput
											label={"Activer le clic sur l'élément"}
											checked={step.spotlightClicks}
											onChange={(val) => {
												step.spotlightClicks = val
												onUpdate(step)
											}}
											onlyState={true}
										/>
										<StepTarget step={step} highlight={highlight}/>
									</div>
								</div>
							</div>
						</div>
					)
				}
			}
		</Draggable>
	)
}

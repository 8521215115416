import React, { useEffect, useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import formatDate, { dateAdd } from "includes/formatDate";
import genRequest from "includes/request";
import { useQuery } from "react-query";
import "./OnlineUsers.css";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";
import AuthorPreview, { DatePreview } from "components/User/AuthorPreview/AuthorPreview";
import Button from "components/Button/Button";

export default function OnlineUsers(props) {
	const { account } = useAccount();
	const [display, setDisplay] = useState("day");
	const [ShowActived, setShowActived] = useState(false);

	let propKey = "LastConnexion";
	if (ShowActived)
		propKey = "FirstConnexion";

	const fetchLastConnexion = () => {
		let date = getDate();
		let now = new Date();
		const url = ShowActived ? "Stats/Activated_Account_In_Period_For_Company" : "Stats/Last_Connected_Account_In_Period_For_Company";
		return genRequest(url, {
			TargetCompanyId: account.CompanyId,
			FromDate: date,
			ToDate: now,
		}, "POST");
	}

	// const fetchEmployes = () => genRequest("Employes/EmployeOfCompany/" + account.CompanyId);

	const { isLoading, data, refetch, isFetching } = useQuery(
		["LastConnectionEmployeId"],
		fetchLastConnexion
	);

	const getDate = () => {
		let date = new Date();
		if (display === "day")
			return (dateAdd(date, { days: -1 }));
		else if (display === "week")
			return (dateAdd(date, { weeks: -1 }));
		else if (display === "month")
			return (dateAdd(date, { months: -1 }));
	};

	// const getUsers = (list) => {
	// 	let diffDate = getDate();

	// 	if (!list)
	// 		return ([]);
	// 	let ret = list.sort((a, b) => (a[propKey] < b[propKey] ? 1 : -1)).map((a) => {
	// 		let date = new Date(a[propKey]);
	// 		if (diffDate < date && a.EmployesId !== account.EmployesId)
	// 			return (a);
	// 		return (false);
	// 	}).filter(a => a);
	// 	return (ret);
	// };

	// let users = getUsers(data);

	useEffect(() => {
		refetch();
	}, [display, ShowActived]);

	return (
		<div className="OnlineUsers">
			<div className="head d-flex flex-wrap mb-2 justify-content-between flex-column">
				<div className="me-2">
					<div className="stat-module-title">
						{t(ShowActived ? "Analytics.ACTIVATED_ACCOUNTS" : "Analytics.LAST_CONN")}
					</div>
					<div className="sub-info">
						{
							t("Analytics.userscount",
								{
									context: ShowActived ? "activated" : "connection",
									count: data?.length
								}
							)
						}
					</div>
				</div>
				<div className="d-flex justify-content-between flex-wrap gap-1">
					<div className="head-btns buttons-group">
						<Button onClick={() => setShowActived(true)} className={"unstyled" + (ShowActived ? " active" : "")}>{t("Analytics.ACTIVATIONS_BTN")}</Button>
						<Button onClick={() => setShowActived(false)} className={"unstyled" + (!ShowActived ? " active" : "")}>{t("Analytics.BTN_CONNECTIONS")}</Button>
					</div>
					<div className="ms-auto head-btns buttons-group">
						<Button onClick={() => setDisplay("month")} className={"unstyled" + (display === "month" ? " active" : "")}>{t("Analytics.MONTH")}</Button>
						<Button onClick={() => setDisplay("week")} className={"unstyled" + (display === "week" ? " active" : "")}>{t("Analytics.WEEK")}</Button>
						<Button onClick={() => setDisplay("day")} className={"unstyled" + (display === "day" ? " active" : "")}>{t("Analytics.DAY")}</Button>
					</div>
				</div>
			</div>
			{
				isLoading || isFetching ?
					<div className="d-flex justify-content-center">
						<LoadCont text={t("Commons.LOADING")} />
					</div>
					:
					<UsersList max={props.max} users={data} />
			}
		</div>
	);
}

function UsersList({ users, max }) {
	if (!users?.length)
		return (
			<div className="list-ph">
				{t("Analytics.NO_USERS")}
			</div>
		);
	let result = users;
	// let propKey = "LastConnexion";
	// if (firstConnexion)
	// 	propKey = "FirstConnexion";
	// let list = users.sort((a, b) => (a[propKey] < b[propKey] ? 1 : -1));
	if (max)
		result = users.slice(0, max);
	return (
		<div className="OnlineUsersList">
			{
				result.slice(0, max).map(({ Key, Value }) => <UserTile key={Key} userId={Key} connectedDate={Value} />)
			}
			{
				users.length - result.length > 0 && <div className="list-ph w-100">
					{
						t(
							"Analytics.others",
							{ count: users.length - result.length }
						)
					}
				</div>
			}
		</div>
	);
}

function UserTile({ userId, connectedDate }) {
	let isOnline = new Date(connectedDate) > dateAdd(new Date(), { minutes: -5 });
	return (
		<div className="UserTile d-flex align-items-center justify-content-between">
			<div className="d-flex align-items-center">
				<AuthorPreview
					className={"user-preview"}
					imgWidth={20}
					employeId={userId}
					format={[
						"EmployeFirstName EmployeLastName",
					]}
					modifiers={{
						EmployeLastName: (item) => item.toUpperCase()
					}}
				/>
			</div>
			<div className="last-conn">
				{
					isOnline ?
						<div className="d-flex align-items-center">
							<div className="me-2">
								{t("Analytics.ONLINE")}
							</div>
							<div className="online-dot"></div>
						</div>
						:
						<DatePreview
							date={connectedDate}
							max={1}
						/>
				}
			</div>
		</div>
	);
}

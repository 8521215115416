import React, {useState} from "react";
import FormInput from "components/Inputs/FormInput";
import ImageSender from "Pages/Appearance/components/ImageSender/ImageSender";
import DocumentObject from "classes/Documents/DocumentObject";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";

function IdentityStep(props) {
	let site = props.object;
	const [image, setImage] = useState(false);
	const { account } = useAccount();

	function imageChange(type, img) {
		setImage(img);
		if (!img)
			return (site.logo(false));
		if (type === "file")
		{
			let doc = new DocumentObject({
				IsNew: true,
				DocType: 10,
				CompanyId: account.CompanyId,
				DocumentFile: img.data,
				IsTargetDoc: false,
				Name: "Logo_" + (site.name() || "Site")
			});
			site.logo(doc);
		}
		else {
			img.IsDefault = true;
			site.logo(img, true);
		}
		return (true);
	}

	return (
		<div className="inner-step-cont">
			<FormInput className="w-100 mb-3" title={t("Sites.NAME")} value={site.name()} onChange={(e) => site.name(e)}/>
			<ImageSender
				title="Logo"
				onChange={(file) => imageChange("file", file)}
				onFetched={(file) => imageChange("document", file)}
				companyId={account.CompanyId}
				siteId={(site?.siteId() || false)}
				typeId={10}
				imgUrl={image?.data}
				sendImage={false}
				delete={((image?.data || image?.type === "site") ? true : false)}
				deleteOrigin={(image?.type === "site")}
				nullable={false}
			/>
		</div>
	);
}

export function checkIdentity(site) {
	let errors = [];
	if (!site.name()?.length)
		errors.push(t("Sites.NAME_ERROR"));
	return (errors.length ? errors : true);
}

export default IdentityStep;


import { t } from "i18next";
// Generator
import FormChoicesOption from "./components/FormGenerator/components/QuestionTypes/FormChoices/FormChoicesOption";
import FormRangeOption from "./components/FormGenerator/components/QuestionTypes/Range/FormRangeOption";
import FormTextOption from "./components/FormGenerator/components/QuestionTypes/Text/FormTextOption";
// Filler
import TextFiller from "./components/FormViewer/components/FormQuestion/components/Text/FormTextOption";
import ChoicesFiller from "./components/FormViewer/components/FormQuestion/components/FormChoices/FormChoicesOption";
import RangeFiller, {formRangOptionChecker} from "./components/FormViewer/components/FormQuestion/components/Range/FormRangeOption";
//Answers
import {
	ChoicesQuestionViewer,
	TextQuestionViewer,
	RangeQuestionViewer
} from "./components/FormAnswersPreview/FormAnswersPreview";

const getQuestionsTypes = () => [
	{
		QuestionDesignId: 1,
		Type: "ShortText",
		Title: t("Forms.SHORT_TEXT"),
		Component: FormTextOption,
		Filler: TextFiller,
		Viewer: TextQuestionViewer
	},
	{
		QuestionDesignId: 6,
		Type: "LongText",
		Title: t("Forms.LONG_TEXT"),
		Component: FormTextOption,
		Filler: TextFiller,
		Viewer: TextQuestionViewer
	},
	{
		QuestionDesignId: 7,
		Type: "UniqueChoice",
		Title: t("Forms.UNIQUE_CHOICES"),
		Multiple: true,
		UniqueChoice: true,
		Component: FormChoicesOption,
		Filler: ChoicesFiller,
		Viewer: ChoicesQuestionViewer
	},
	{
		QuestionDesignId: 8,
		Type: "MultipleChoice",
		Title: t("Forms.MULTIPLE_CHOICE"),
		Multiple: true,
		Component: FormChoicesOption,
		Filler: ChoicesFiller,
		Viewer: ChoicesQuestionViewer
	},
	{
		QuestionDesignId: 9,
		Type: "BasicRange",
		Title: t("Forms.RANGE_INPUT"),
		Component: FormRangeOption,
		Filler: RangeFiller,
		fillValidation: formRangOptionChecker,
		Viewer: RangeQuestionViewer
	},
	// {
	// 	TypeId: 10,
	// 	Type: "buttons",
	// 	Title: t("Forms.SHORT_TEXT"),
	// 	Multiple: true,
	// },
];

export function getQuestionType(typeIdOrName) {
	if (!typeIdOrName)
		return (false);
	let types = getQuestionsTypes();
	let found = types.find((a) => (a.QuestionDesignId === typeIdOrName || a.Type === typeIdOrName));
	return (found || false)
}

export default getQuestionsTypes;

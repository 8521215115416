import { t } from "i18next";
import React from "react";
import { useQuery } from "react-query";

export default function InfosFetcher(props) {
	const req = useQuery(
		props.queryKey,
		props.queryFn,
		props.options
	);

	function getContent(fetched_data) {
		if (props.treat)
		{
			if ((!fetched_data || error) && props.displayOnError)
				return (props.treat([]));
			return (props.treat(fetched_data));
		}
		return (fetched_data);
	}

	const {data, error, isLoading } = req;

	if (isLoading)
		return (<div>{props.loadText || t("Commons.LOADING")}</div>);

	if (!data && !props.displayOnError)
		return (false);

	return (
		<div className={props.className}>
			{ getContent(data) }
		</div>
	);
}

import { t } from "i18next";
import NewsPage from "Pages/Posts/news";
import ArticlesPage from "Pages/Posts/articles";
import CareersPage from "Pages/Posts/careers";
import OffersPage from "Pages/Posts/offers";
import ProOffersPage from "Pages/Posts/pro_offers";
import ContactsPage from "Pages/Contacts/contacts";
import DocumentsPage from "Pages/Documents/documents";
// import ChartsPage from "Pages/Charts/charts";
import PostEditDrawer from "Pages/Posts/components/PostEditDrawer/PostEditDrawer";
import MyOnlyAll from "Pages/MyOnlyAll/MyOnlyAll";
import Alertspage from "Pages/Alerts/alerts";
import SitesPage from "Pages/Sites/SitesPage";
import FormsPage from "Pages/Forms/Forms";
import AppearancePage from "Pages/Appearance/AppearancePage";
import ProfessionsPage from "Pages/Professions/Professions";
import CategoriesPage from "Pages/Categories/Categories";
import GroupsPage from "Pages/Groups/Groups";
import AnalyticsPage from "Pages/Analytics/AnalyticsPage";
import CompaniesPage from "Pages/Companies/Companies";
// import Home from "Pages/Home/Home";
import PagesGroupPage from "./components/PagesGroupPage/PagesGroupPage";
import CompanyPage from "components/CompanyPage/CompanyPage";
import SettingsPage from "Pages/Settings/Settings";
import Playground from "Debug/Playground/Playground";
import { PICTOS_URL } from "includes/paths";
import ReportingPage from "Pages/Reporting/Reporting";
import AdminReactionsPage from "Pages/Reactions/AdminReactionsPage";
import ReactionsConfigPage from "Pages/Reactions/ReactionsConfigPage";
import AnimationsPage from "Pages/Animations/AnimationsPage";
import ChartsPage from "Pages/Charts/ChartsPage";
import TutorialsPage from "Pages/Tutorials/Tutorials";
import { TypeRights, returnRightsFromName } from "includes/rightType";
import { RssPage } from "Pages/RSS/rss.page";
import { RssPostsPage } from "Pages/RSS/rss-posts.page";

const showProfessions = (rights) => {
	if (rights) {
		return (
			returnRightsFromName(rights, TypeRights.AdminWFU) ||
			returnRightsFromName(rights, TypeRights.AdminWFY)
		);
	}
};

// return MenuItems with a function to avoid "undefined" trads from i18n
const getMenuItems = (rights, settings, rssPerm) => [
	{
		title: "Développeurs",
		name: "devs",
		url: "/devs",
		role: "EloAdmin",
		platform: "admin",
		isVisible: settings !== void 0 && settings.DevFunctions,
		settingName: "DevFunctions",
		icon: PICTOS_URL + "Dev_Picto.png",
		component: PagesGroupPage,
		items: [
			{
				title: t("Pages.REACTIONS"),
				name: "reactions",
				url: "/adminreactions",
				exact: true,
				isVisible: true,
				icon: PICTOS_URL + "Reactions_Picto.png",
				component: AdminReactionsPage,
			},
			{
				title: t("Pages.COMPANIES"),
				name: "companies",
				url: "/companies",
				params_url: "/companies/:id/:page",
				paths: ["/companies", "/companies/:id", "/companies/:id/:page"],
				exact: true,
				isVisible: true,
				icon: PICTOS_URL + "Dev_Picto.png",
				component: CompaniesPage,
			},
			{
				title: "Reporting",
				name: "reporting",
				url: "/reporting",
				exact: true,
				isVisible: true,
				icon: PICTOS_URL + "Dev_Picto.png",
				component: ReportingPage,
			},
		],
	},
	{
		title: t("Pages.CONFIGURATION"),
		name: "config",
		url: "/config",
		role: "SuperAdmin",
		platform: "admin",
		isVisible: true,
		icon: PICTOS_URL + "Corporate_Picto.png",
		component: PagesGroupPage,
		items: [
			{
				title: t("Pages.APPEARANCE"),
				name: "appearance",
				url: "/appearance",
				icon: PICTOS_URL + "Appearance_Picto.png",
				isVisible: returnRightsFromName(
					rights,
					TypeRights.AdminAppearance
				),
				component: AppearancePage,
			},
			{
				title: t("Pages.PROFESSIONS"),
				name: "professions",
				url: "/professions",
				headBar: false,
				isVisible: showProfessions(rights),
				icon: PICTOS_URL + "Professions_Picto.png",
				component: ProfessionsPage,
			},
			{
				title: t("Pages.GROUPS"),
				name: "groups",
				url: "/groups",
				headBar: false,
				isVisible: returnRightsFromName(rights, TypeRights.AdminGroups),
				icon: PICTOS_URL + "People_Picto.png",
				component: GroupsPage,
			},
			{
				title: t("Pages.CATEGORIES"),
				name: "categories",
				url: "/categories",
				headBar: false,
				isVisible: returnRightsFromName(
					rights,
					TypeRights.AdminCategories
				),
				icon: PICTOS_URL + "Label_Picto.png",
				component: CategoriesPage,
			},
			{
				title: t("Pages.SITES"),
				name: "sitesadmin",
				url: "/sitesadmin",
				icon: PICTOS_URL + "Sites_Picto.png",
				headBar: false,
				isVisible: returnRightsFromName(rights, TypeRights.AdminSites),
				component: SitesPage,
			},
			{
				title: t("Pages.REACTIONS"),
				settingName: "Reactions",
				name: "reactions",
				url: "/reactions",
				icon: PICTOS_URL + "Reactions_Picto.png",
				isVisible: true,
				component: ReactionsConfigPage,
			},
			{
				title: t("AppFunctions.PeoplePage_Rss"),
				name: "Flux externe",
				url: "/rss-admin",
				exact: true,
				appFunction: "PeoplePage.Rss",
				isVisible: rssPerm,
				icon: PICTOS_URL + "Dev_Picto.png",
				component: RssPage,
			},
		],
	},
	{
		title: t("Pages.NEWS"),
		name: "news",
		url: "/news",
		params_url: "/news/:id",
		paths: ["/", "/news", "/news/:id"],
		headBar: false,
		isVisible: true,
		component: NewsPage,
		editDrawer: PostEditDrawer,
		icon: PICTOS_URL + "News_Picto.png",
		type: "post",
		appMenu: "Menu1",
		appFunction: "",
		notifTypeId: 1,
		notifUrl: "NotViewedPost",
	},
	{
		title: t("Pages.ALERTS"),
		name: "alerts",
		url: "/alerts",
		params_url: "/alerts/:id",
		paths: ["/alerts", "/alerts/:id"],
		icon: PICTOS_URL + "Alerts_Picto.png",
		headBar: false,
		isVisible: true,
		component: Alertspage,
		// inMenu: false,
		notifTypeId: 13,
		notifUrl: "NotViewedPost",
	},
	{
		title: t("Pages.CORPORATE"),
		name: "corporate",
		url: "/corporate",
		isVisible: true,
		icon: PICTOS_URL + "Corporate_Picto.png",
		appMenu: "Menu2",
		component: PagesGroupPage,
		items: [
			{
				title: t("Pages.ARTICLES"),
				name: "articles",
				url: "/articles",
				params_url: "/articles/:id",
				paths: ["/articles", "/articles/:id"],
				type: "post",
				headBar: false,
				isVisible: true,
				component: ArticlesPage,
				editDrawer: PostEditDrawer,
				icon: PICTOS_URL + "Articles_Picto.png",
				appFunction: "CorporatePage.Articles",
				notifTypeId: 3,
				appMenu: "About",
				notifUrl: "NotViewedPost",
			},
			{
				title: t("Pages.DOCUMENTS"),
				name: "documents",
				url: "/documents",
				component: DocumentsPage,
				headBar: false,
				isVisible: true,
				icon: PICTOS_URL + "Docs_Picto.png",
				appFunction: "CorporatePage.DocsUtils",
				notifUrl: "NotViewedDocUtils",
				appMenu: "Documents",
			},
			{
				title: t("Pages.CHARTS"),
				name: "charts",
				url: "/charts",
				component: ChartsPage,
				isVisible: true,
				icon: PICTOS_URL + "Charts_Picto.png",
				appFunction: "CorporatePage.Charts",
				notifUrl: "NotViewedChart",
				appMenu: "Charts",
			},
			{
				title: t("Pages.AGENDA"),
				name: "agenda",
				url: "/agenda",
				headBar: false,
				component: AnimationsPage,
				isVisible: true,
				icon: PICTOS_URL + "Animations_Picto.png",
				appFunction: "PeoplePage.Agenda",
				notifUrl: "NotViewedEvent",
				appMenu: "Agenda",
			},
			{
				title: t("Pages.SITES"),
				name: "sites",
				url: "/sites",
				icon: PICTOS_URL + "Sites_Picto.png",
				isVisible: true,
				headBar: false,
				appFunction: "SitesPage",
				component: SitesPage,
			},
		],
	},
	{
		title: t("Pages.PEOPLES"),
		name: "peoples",
		url: "/peoples",
		icon: PICTOS_URL + "People_Picto.png",
		appMenu: "Menu3",
		isVisible: true,
		component: PagesGroupPage,
		items: [
			{
				title: t("Pages.CAREERS"),
				name: "careers",
				url: "/careers",
				params_url: "/careers/:id",
				paths: ["/careers", "/careers/:id"],
				headBar: false,
				isVisible: true,
				component: CareersPage,
				editDrawer: PostEditDrawer,
				icon: PICTOS_URL + "Careers_Picto.png",
				appFunction: "PeoplePage.Career",
				notifTypeId: 5,
				notifUrl: "NotViewedPost",
				appMenu: "Careers",
			},
			{
				title: t("Pages.PRO_OFFERS"),
				name: "pro_offers",
				url: "/pro_offers",
				params_url: "/pro_offers/:id",
				paths: ["/pro_offers", "/pro_offers/:id"],
				headBar: false,
				isVisible: true,
				component: ProOffersPage,
				editDrawer: PostEditDrawer,
				icon: PICTOS_URL + "Pro_Offers_Picto.png",
				appFunction: "PeoplePage.ViewProAd",
				notifTypeId: 14,
				notifUrl: "NotViewedPost",
				appMenu: "ProOffers",
			},
			{
				title: t("Pages.OFFERS"),
				name: "offers",
				url: "/offers",
				params_url: "/offers/:id",
				paths: ["/offers", "/offers/:id"],
				headBar: false,
				isVisible: true,
				component: OffersPage,
				editDrawer: PostEditDrawer,
				icon: PICTOS_URL + "Offers_Picto.png",
				appFunction: "PeoplePage.ViewAd",
				notifTypeId: 4,
				notifUrl: "NotViewedPost",
				appMenu: "Offers",
			},
			{
				title: t("Pages.FORMS"),
				name: "forms",
				appFunction: "Forms",
				url: "/forms",
				component: FormsPage,
				headBar: false,
				isVisible: true,
				icon: PICTOS_URL + "Articles_Picto.png",
			},
		],
	},
	{
		title: t("Pages.CONTACTS"),
		// adminTitle: "Utilisateurs",
		name: "contacts",
		url: "/contacts",
		component: ContactsPage,
		headBar: false,
		isVisible: true,
		icon: PICTOS_URL + "Contact_Picto.png",
		appMenu: "Menu4",
		appFunction: "ContactPage",
	},
	{
		title: "Tutoriels",
		name: "tutorials",
		// appFunction: "Forms",
		settingName: "Tutorials",
		url: "/tutorials",
		isVisible: true,
		component: TutorialsPage,
		icon: PICTOS_URL + "Articles_Picto.png",
	},
	{
		title: t("Pages.ANALYTICS"),
		name: "analytics",
		url: "/analytics",
		component: AnalyticsPage,
		platform: "admin",
		headBar: false,
		isVisible: true,
		icon: PICTOS_URL + "Analytics_Picto.png",
	},
	/*
	 ** -- Not directly in menu links
	 */
	{
		title: t("Pages.COMPANY"),
		name: "company",
		url: "/company",
		params_url: "/company/:id/:page",
		paths: ["/company", "/company/:id", "/company/:id/:page"],
		exact: true,
		headBar: false,
		isVisible: true,
		component: CompanyPage,
		inMenu: false,
		role: "EloAdmin",
	},
	{
		title: t("Pages.MY_SPACE"),
		name: "myonlyall",
		url: "/myonlyall",
		exact: false,
		isVisible: true,
		component: MyOnlyAll,
		inMenu: false,
	},
	// {
	// 	component: Home,
	// 	name: "home",
	// 	url: "/",
	// 	paths: [
	// 		"",
	// 		"/home",
	// 	],
	// 	headBar: false,
	// 	inMenu: false,
	// 	hideMenu: true,
	// 	forceRefresh: true
	// },
	{
		title: t("Pages.MY_SETTINGS"),
		name: "settings",
		url: "/settings",
		component: SettingsPage,
		inMenu: false,
		isVisible: true,
	},

	//DEBUG
	{
		title: "Playground",
		name: "playground",
		url: "/playground",
		role: "EloAdmin",
		component: Playground,
		inMenu: false,
		isVisible: true,
	},
];

export function getMenuItem(url) {
	let MenuItems = getMenuItems();

	for (let item of MenuItems) {
		let path_check = item.paths?.find((a) => a === url);
		if (item.name === url || item.url === url || path_check)
			return { ...item };
		if (item.items?.length) {
			let check = item.items.find((a) =>
				a.url === url || a.name === url ? a : false
			);
			if (check) return { ...check, parent: item };
		}
	}
	return false;
}

export default getMenuItems;

import useAccount from "classes/Accounts/hooks/useAccount";
import FormAnswerModel from "classes/Forms/FormAnswerModel";
import getQuestionsTypes from "classes/Forms/QuestionsTypes";
import TagLabel from "components/Tags/Label/TagLabel";
import { linkify } from "includes/linkify";
import { t } from "i18next";
import React, { useState } from "react";

export default function FormQuestion(props) {
	const { account } = useAccount();
	const { isActive, refreshForm } = props;
	const { question } = props;
	const { AnswerRequired } = question;
	const [Answers, setAnswers] = useState(question.Answers || []);

	function handleUpdate() {
		if (refreshForm) refreshForm();
	}

	function getType(typeId) {
		let QTypes = getQuestionsTypes();
		let found = QTypes.find((a) => a.QuestionDesignId === typeId);
		return found ? found : false;
	}

	function selectAnswers(values) {
		question.Answers = values.map((a) => {
			a.EmployesId = account.EmployesId;
			return a instanceof FormAnswerModel
				? a
				: new FormAnswerModel(a, this);
		});
		setAnswers(values);
	}

	return (
		<div
			className={
				"FormQuestion" +
				(AnswerRequired ? " requiered" : "") +
				(isActive ? " active" : "")
			}
			data-form-question-id={question.QuestionId}
		>
			<div className="d-flex flex-column w-100 mb-2">
				<div className="col-12 pr-0">
					{question.AnswerRequired && (
						<div className="d-flex mb-2">
							<TagLabel
								className="required-tag"
								color={"red"}
								text={t("Forms.REQUIERED")}
							/>
						</div>
					)}
					{question.ImgUrl && (
						<div className="question-image d-flex justify-content-center mb-2">
							<img
								alt={question.Title}
								style={{
									maxHeight: "60vh",
									width: "auto",
									maxWidth: "100%",
								}}
								src={question.ImgUrl}
							/>
						</div>
					)}
					<div className="question-title">{question.Title}</div>
					<div className="question-text mt-1">
						{linkify(question.Txt)}
					</div>
				</div>
			</div>
			<div className="question-options-cont">
				<AnswersList
					question={question}
					options={question.Options}
					setAnswers={selectAnswers}
					answers={Answers}
					type={getType(question.QuestionDesignId)}
					handleUpdate={handleUpdate}
				/>
			</div>
		</div>
	);
}

function AnswersList({
	question,
	options,
	type,
	handleUpdate,
	setAnswers,
	answers,
}) {
	function isSelected(optionId) {
		if (!answers?.length) return false;
		let found = answers.find((a) => a.OptionId === optionId);
		return found ? found : false;
	}

	function handleChange(val) {
		if (val.unique || type.UniqueChoice) return setAnswers([val]);
		let dup = [...answers];
		for (let x in answers) {
			let ans = answers[x];
			if (ans.OptionId === val.OptionId) {
				if (!val.state) {
					dup.splice(x, 1);
					setAnswers(dup);
				}
				return dup;
			}
		}
		dup.push(val);
		setAnswers(dup);
	}

	if (!options.length) return false;

	return (
		<div className="AnswersList mt-2">
			{options.map((a, key) => {
				if (!type?.Component) return false;
				let option_ans = isSelected(a.OptionId);
				return (
					<type.Filler
						index={key}
						handleUpdate={handleUpdate}
						unique={type.UniqueChoice}
						key={a.OptionId}
						question={question}
						option={a}
						type={type}
						onChange={handleChange}
						selected={option_ans}
						isSelected={isSelected}
						answers={answers}
					/>
				);
			})}
		</div>
	);
}

import React, { useState } from "react";
import { useQuery } from "react-query";
import PageLoad from "components/PageLoad/PageLoad";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import genRequest from "includes/request";
import useTemplate from "hooks/useTemplate";

export default function AnimationsController(props) {
	const { account } = useAccount();

	const [TypeId] = useState(props.typeId ? props.typeId : 1);
	const [CompanyId] = useState(
		props.companyId ? props.companyId : account.CompanyId
	);
	const [SiteId] = useState(props.siteId ? props.siteId : false);
	const [State] = useState(props.state ? props.state : "published");
	const [CurAnimation, setCurAnimation] = useState(false);
	const { template } = useTemplate();

	function getRetrieveReq() {
		if (!CompanyId)
			return {
				error: true,
			};

		//TODO TARGETS EVENTS

		if (SiteId)
			return {
				queryKey: ["Animations", "AnimationOfSite", SiteId],
				queryFn: retrieveSiteDocs,
			};

		return {
			queryKey: ["Animations", "AnimationOfCompany", CompanyId],
			queryFn: retrieveCompanyDocs,
		};
	}

	const treat_items = (items) => {
		if (!items?.length) return [];
		let ret = items.map((a) => {
			return a;
		});
		return ret;
	};

	const retrieveCompanyDocs = async () =>
		await genRequest("Animations/AnimationOfCompany/" + CompanyId).catch(
			() => []
		);

	const retrieveSiteDocs = async () =>
		await genRequest("Animations/AnimationOfSite/" + SiteId).catch(
			() => []
		);

	const { isLoading, data, error } = useQuery(getRetrieveReq());

	if (isLoading)
		return (
			<PageLoad
				background={false}
				color={template?.primaryColor()}
				text={t("Events.LOADING")}
			/>
		);

	if (error) return <div>{t("Events.LOAD_ERROR")}</div>;

	if (props.childComponent)
		return (
			<>
				<props.childComponent
					typeId={TypeId}
					events={treat_items(data)}
					state={State}
					onPostClick={setCurAnimation}
					cur={CurAnimation}
					{...props.childprops}
				/>
			</>
		);
	return false;
}

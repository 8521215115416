class Company {

	#CompanyId;
	#OfferId;
	#Name;
	#TotalUsers;
	#TotalSites;
	#SuperAdminId;
	#LastChanged;
	#LastChange_AuthorId;
	#StatTemplateId;
	#CreationDate;

	constructor(props) {
		this.#CompanyId = (props?.CompanyId ? props.CompanyId : null);
		this.#OfferId = (props?.OfferId ? props.OfferId : null);
		this.#Name = (props?.Name ? props.Name : null);
		this.#TotalUsers = (props?.TotalUsers ? props.TotalUsers : 0);
		this.#TotalSites = (props?.TotalSites ? props.TotalSites : 0);
		this.#SuperAdminId = (props?.SuperAdminId ? props.SuperAdminId : null);
		this.#LastChanged = (props?.LastChanged ? props.LastChanged : null);
		this.#LastChange_AuthorId = (props?.LastChange_AuthorId ? props.LastChange_AuthorId : null);
		this.#StatTemplateId = (props?.StatTemplateId ? props.StatTemplateId : null);
		this.#CreationDate = (props?.CreationDate ? props.CreationDate : null);
	}

	companyId(setCompanyId) {
		if (typeof setCompanyId !== "undefined")
			this.#CompanyId = setCompanyId;
		return (this.#CompanyId);
	}

	offerId(setOfferId) {
		if (typeof setOfferId !== "undefined")
			this.#OfferId = setOfferId;
		return (this.#OfferId);
	}

	name(setName) {
		if (typeof setName !== "undefined")
			this.#Name = setName;
		return (this.#Name);
	}

	totalUsers(setTotalUsers) {
		if (typeof setTotalUsers !== "undefined")
			this.#TotalUsers = setTotalUsers;
		return (this.#TotalUsers);
	}

	totalSites(setTotalSites) {
		if (typeof setTotalSites !== "undefined")
			this.#TotalSites = setTotalSites;
		return (this.#TotalSites);
	}

	superAdminId(setSuperAdminId) {
		if (typeof setSuperAdminId !== "undefined")
			this.#SuperAdminId = setSuperAdminId;
		return (this.#SuperAdminId);
	}

	lastChanged(setLastChanged) {
		if (typeof setLastChanged !== "undefined")
			this.#LastChanged = setLastChanged;
		return (this.#LastChanged);
	}

	lastChange_AuthorId(setLastChange_AuthorId) {
		if (typeof setLastChange_AuthorId !== "undefined")
			this.#LastChange_AuthorId = setLastChange_AuthorId;
		return (this.#LastChange_AuthorId);
	}

	statTemplateId(setStatTemplateId) {
		if (typeof setStatTemplateId !== "undefined")
			this.#StatTemplateId = setStatTemplateId;
		return (this.#StatTemplateId);
	}

	creationDate(setCreationDate) {
		if (typeof setCreationDate !== "undefined")
			this.#CreationDate = setCreationDate;
		return (this.#CreationDate);
	}

	values = () => ({
		CompanyId: this.#CompanyId,
		OfferId: this.#OfferId,
		Name: this.#Name,
		TotalUsers: this.#TotalUsers,
		TotalSites: this.#TotalSites,
		SuperAdminId: this.#SuperAdminId,
		LastChanged: this.#LastChanged,
		LastChange_AuthorId: this.#LastChange_AuthorId,
		StatTemplateId: this.#StatTemplateId,
		CreationDate: this.#CreationDate
	});
}

export default Company;

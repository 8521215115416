import React, { useState } from "react";
import genRequest from "includes/request";
import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import useAccount from "classes/Accounts/hooks/useAccount";
import { toast } from "react-toastify";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";

export default function ProfileForm(props) {
	const {setAccount} = useAccount();
	let user = props.user;
	let company = props.company;
	let CompanyName = company?.Name;
	let site = props.site;
	let SiteName = (site ? site.Name + " - " + site.Town : "");
	let FamillyName = props.wfy?.WorkFamillyName;
	let FunctionName = props.wfu?.WorkFunctionName;

	let isEditable = props.editable;
	const [sending, setSending] = useState(false);
	const [curValues, setCurValues] = useState(JSON.parse(JSON.stringify(user)));
	const [newValues, setNewValues] = useState(JSON.parse(JSON.stringify(user)));
	const [DisplayMail, setDisplayMail] = useState(user.Display_Mail);
	const [DisplayPhone, setDisplayPhone] = useState(user.Display_Phone);
	const [hasChanged, setHasChanged] = useState(false);
	const [errors, setErrors] = useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!checkInfos())
			return (Promise.reject());
		setSending(true);
		let req = genRequest("Employes/" + user.EmployesId, newValues, "put").then(() => {
			let values = JSON.parse(JSON.stringify(newValues));
			setSending(false);
			setHasChanged(false);
			setCurValues(values);
			setAccount(values);
		}).catch(() => {
			setSending(false);
			return (Promise.reject(false));
		});
		toast.promise(
			req,
			{
				pending: t("Toasts.PROM_PENDING"),
				success: t("Toasts.PROM_SUCCESS"),
				error: t("Toasts.PROM_ERROR"),
				autoClose: 7000
			}
		);
		return (req);
	};

	function checkInfos() {
		let check_errors = [];
		let mail_regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		//let phone_regexp = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
		let phone_regexp = /^[0-9]{10}$/;

		if (!newValues.FirstName.length)
			check_errors.push(t("Contacts.ERROR_FIRSTNAME"));
		if (!newValues.LastName.length)
			check_errors.push(t("Contacts.ERROR_LASTNAME"));
		if (!(newValues.Mail.length || newValues.Telephone.length))
			check_errors.push(t("Contacts.ERROR_NO_CONTACT"));
		if (newValues.Mail.length && !newValues.Mail.match(mail_regexp))
			check_errors.push(t("Contacts.ERROR_MAIL"));

		//------------------  NEW PHONE FORMAT -> NEED API FIX
		// if (newValues.Telephone?.length && !newValues.Telephone.match(phone_regexp))
		// 	check_errors.push(t("Contacts.ERROR_PHONE"))

		if (newValues.Telephone?.length && (!newValues.Telephone.match(phone_regexp) || newValues.Telephone.length > 10))
			check_errors.push(t("Contacts.OLD_ERROR_PHONE"));
		setErrors(check_errors);
		return (!(check_errors?.length > 0));
	}

	function handleChange(prop, value)
	{
		let cur_values = newValues;
		cur_values[prop] = value;
		setNewValues(cur_values);
		let has_changed = infosHasChanged();
		if (!has_changed)
			setErrors([]);
		setHasChanged(has_changed);
	}

	function infosHasChanged()
	{
		for (let x in curValues)
			if (curValues[x] !== newValues[x])
				return (true);
		return (false);
	}

	function handleDispChange(prop, value)
	{
		if (prop === "Display_Mail")
			setDisplayMail(value);
		else if (prop === "Display_Phone")
			setDisplayPhone(value);
		handleChange(prop, value);
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="row mb-3">
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6 mb-2 mb-sm-0 mb-md-2 mb-lg-0" disabled={!isEditable} title={t("User.LAST_NAME")} prop="LastName" value={user.LastName} onChange={handleChange}/>
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6" disabled={!isEditable} title={t("User.FIRST_NAME")} prop="FirstName" value={user.FirstName} onChange={handleChange}/>
			</div>
			<div className="d-flex mb-3">
				<div className="w-100">
					<div className="d-flex justify-content-between align-items-center mb-1">
						<div>{t("User.MAIL")}</div>
						<div className="d-flex align-items-center">
							<SwitchInput label={t("User.VISIBLE")} reverse={true} checked={DisplayMail} onChange={() => handleDispChange("Display_Mail", !newValues.Display_Mail)}/>
						</div>
					</div>
					<FormInput titlevalue={"true"} className="w-100" disabled={!isEditable} placeholder={t("User.MAIL")} prop="Mail" value={user.Mail} onChange={handleChange}/>
				</div>
			</div>
			<div className="d-flex mb-3">
				<div className="w-100">
					<div className="d-flex justify-content-between align-items-center mb-1">
						<div>{t("User.PHONE")}</div>
						<div className="d-flex align-items-center">
							<SwitchInput label={t("User.VISIBLE")} reverse={true} checked={DisplayPhone} onChange={() => handleDispChange("Display_Phone", !newValues.Display_Phone)}/>
						</div>
					</div>
					<FormInput titlevalue={"true"} className="w-100" disabled={!isEditable} placeholder={t("User.PHONE")} prop="Telephone" value={user.Telephone} onChange={handleChange}/>
				</div>
			</div>
			<div className="row mb-3">
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6 mb-2 mb-sm-0 mb-md-2 mb-lg-0" title={t("User.LOGIN")} disabled value={user.Login}/>
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6" title={t("User.ROLE")} disabled value={user.Role}/>
			</div>
			<div className="row mb-3">
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6 mb-2 mb-sm-0 mb-md-2 mb-lg-0" title={t("User.COMPANY")} disabled value={CompanyName}/>
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6" title={t("User.SITE")} disabled value={SiteName}/>
			</div>
			<div className="row mb-3">
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6 mb-2 mb-sm-0 mb-md-2 mb-lg-0" title={t("User.WORK_FAM")} disabled value={FamillyName}/>
				<FormInput titlevalue={"true"} className="col-12 col-sm-6 col-md-12 col-lg-6" title={t("User.WORK_FUNC")} disabled value={FunctionName}/>
			</div>
			<div className="d-flex">
				<FormInput className="w-100" disabled={!isEditable} title={t("User.HOBBY")} textarea="true" value={user.Hobby} prop="Hobby" onChange={handleChange}/>
			</div>
			<ErrorsCont errors={errors}/>
			{
				(hasChanged ?
					<div className="d-flex mt-3">
						<Button className="btn btn-primary btn-save-infos" disabled={sending ? true : false} type="submit">{sending ? t("User.SENDING_BTN") : t("User.SAVE_BTN")}</Button>
					</div>
					:
					false
				)
			}
		</form>
	);
}

import React, {useState, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faBullseye } from "@fortawesome/free-solid-svg-icons";
import "./TableToolBar.css";
import Button from "components/Button/Button";
import TagLabel from "components/Tags/Label/TagLabel";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import BackButton from "components/Button/BackButton";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";

const buttons = [
	{
		title: "Définir les cibles",
		icon: faBullseye,
		value: "Cibles",
		enabled: false
	}
];

export default function TableToolBar(props) {
	const [activeMenu, setActiveMenu] = useState(false);
	const cont_ref = useRef(null);

	useOutsideAlerter(cont_ref, closeMenu);

	function closeMenu() {
		if (activeMenu)
			setActiveMenu(false);
	}

	if (!props?.selected?.length)
		return (false);

	return (
		<div className="TableToolBar ms-1" ref={cont_ref}>
			<div className="d-flex gap-2">
				<Button className="unstyled" onClick={() => props.setSelected([])}>
					<TagLabel className="align-items-center gap-1">
						<FontAwesomeIcon icon={faTimes}/>
						{ t("Commons.ITEMS", {count: props.selected?.length})}
					</TagLabel>
				</Button>
				<Sep/>
				<ToolBarButons
					items={props.buttons || buttons}
					active={activeMenu}
					setActive={setActiveMenu}
					selected={props.selected}
					setSelected={props.setSelected}
				/>
			</div>
			<div>
				<Button className="btn-red gap-2" onClick={props.handleDelete}>
					<div>
						{ t("Commons.DELETE") }
					</div>
					<FontAwesomeIcon icon={faTrashAlt}/>
				</Button>
			</div>
		</div>
	);
}

const Sep = (props) => ( <div className={"sep" + (props.className ? " " + props.className : "")}></div> );

function ToolBarButons(props) {
	const {active, setActive, items} = props;

	const focus = (item) => {
		if (active.title !== item.title)
			setActive(item);
		else
			setActive(false);
	};

	if (!items)
		return (false);

	return (
		<div className="d-flex gap-2">
			{
				items.map((a, key) => {
					if (a.enabled === false)
						return (false);
					return (
						<ToolBarButon
							key={key}
							item={a}
							isFocused={active ? true : false}
							setIsFocused={focus}
							display={active?.title === a.title}
							selected={props.selected}
							setSelected={props.setSelected}
						/>
					);
				})
			}
		</div>
	);
}

function ToolBarButon(props) {
	const {item, setIsFocused} = props;
	const {display} = props;
	const [TM, setTM] = useState(false);

	const showPopper = (e) => {
		e.stopPropagation();
		resetTm();
		setIsFocused(item);
	};

	const resetTm = (new_tm) => {
		clearTimeout(TM);
		setTM((new_tm ? new_tm : false));
	};

	const handleClose = (resp) => {
		setIsFocused(false);
		if (item.onClose)
			item.onClose(resp);
	}

	return (
		<div className="ToolbarButton">
			<Button className="gap-1" title={item.title} onClick={showPopper}>
				{item.icon && <FontAwesomeIcon icon={item.icon}/>}
				<div className="btn-title">{item.value}</div>
			</Button>
			{
				display &&
					<ButtonPopper
						{...item}
						item={item.popper}
						selected={props.selected}
						setSelected={props.setSelected}
						handleClose={handleClose}
					/>
			}
		</div>
	);
}

function ButtonPopper(props) {
	const [isLoading, setIsLoading] = useState(false);

	if (!props.item)
		return (false);

	return (
		<div className="popper-overlay">
			<div className="popper-cont">
				<div className="d-flex gap-2 align-items-center mb-3">
					<BackButton onClick={props.handleClose}/>
					<h4>{props.popperTitle || props.title}</h4>
				</div>
				<div className="popper-inner-cont">
					<props.item
						handleClose={props.handleClose}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						{...props}
					/>
				</div>
				{
					isLoading &&
						<div className="popper-load">
							<LoadCont
								text={props.isLoading?.length ? props.isLoading : false}
							/>
						</div>
				}
			</div>
		</div>
	);
}

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { motion } from "framer-motion";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import Markdown from "markdown-to-jsx";
import "./PostTile.css";
import { t } from "i18next";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import genRequest from "includes/request";
import TileImage from "../Images/TileImage/TileImage";
import { NewLabel } from "components/Tags/Label/TagLabel";
import formatDate from "includes/formatDate";
import SavePostBtn from "../PostPreview/components/BottomBar/components/SavePostBtn/SavePostBtn";
import clsx from "clsx";

class PostTile extends Component {

	constructor(props) {
		super(props);

		this.PostId = props.datas?.PostId;
		this.state = {
			Images: [],
			Author: false,
			hasImages: true,
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleImageFetchStatus = (status) => {
		// console.log("status", status);
		// if (this.state.hasImages !== status) {
		// 	this.setState({ hasImages: status });
		// }
	};

	handleClick() {
		this.props.onClick(this);
	}

	genTitle() {
		let post = this.props.datas;

		if (post.PostTypeId !== 3)
			return (
				<div className="post-title font-bold">
					{post.Title}
				</div>
			);

		let title = post.Title.split(/\/__CUT__\\/gi);
		return (
			<div>
				<div className="post-sub-title">
					{title.length > 1 ? title[0] : false}
				</div>
				<div className="post-title font-bold">
					{title.length > 1 ? title[1] : title[0]}
				</div>
			</div>
		);
	}

	MdLink({ children, ...props }) {
		return (
			<span {...props}>{children}</span>
		);
	}

	genText() {
		let post = this.props.datas;
		let txt = post.Txt.replace(/\/__CUT__\\/gi, "\n");
		let join = txt.slice(0, 300);

		return (
			<div className="post-text mt-1" tabIndex={-1}>
				<Markdown
					options={{
						overrides: {
							a: {
								component: this.MdLink
							},
						},
					}}
				>
					{join}
				</Markdown>
			</div>
		);
	}

	render() {
		let post = this.props.datas;
		let txt = post.Txt.substr(0, 256);
		// let hasResponse = this.props.hasResponse;
		// let hasImages = (this.props.images && this.props.dispImages !== false ? true : false);
		let disp_direction = this.props.direction;

		const { hasImages } = this.state;

		let anims = {
			hidden: {
				y: "100%",
				opacity: 0,
			},
			show: {
				y: "0%",
				opacity: 1,
				transition: {
					ease: "easeOut",
					duration: 0.2,
				}
			}
		};

		if (this.props.isLoading)
			return (
				<motion.div
					variants={anims}
					initial="hidden"
					animate="show"
					className="post-tile loading p-2" postid={post.PostId}
				>
					<div className="w-100"></div>
					<div className="w-50"></div>
					<div className="w-25"></div>
					<div className="w-35"></div>
				</motion.div>
			);

		return (
			<motion.div
				variants={anims}
				initial="hidden"
				animate="show"
				className={
					"post-tile p-2 gap-2 tile-head-display" +
					(disp_direction ? " " + disp_direction : "") +
					(!hasImages ? " no-image" : "") +
					(this.props.isActive ? " active" : "") +
					(this.props.className ? " " + this.props.className : "") +
					(this.props.isMobile ? " mobile" : "")
				}
				postId={post.PostId}
				onClick={this.handleClick}
			>
				{this.props.isMobile ? (
					<div className={clsx("PostMobile", hasImages ? "" : "no-images")}>
						<TileImage images={this.props.images} postTypeId={post.PostTypeId} postId={post.PostId} onImageFetch={this.handleImageFetchStatus} />
						<div className="PostTileHead justify-content-between gap-2">
							<PostAuthorPreview post={post} className={clsx("mobile-author-preview", hasImages ? "" : "no-images")} isMobile={this.props.isMobile} />
						</div>
						<div>
							{this.genTitle()}
						</div>
					</div>

				) : (<>
					<div className="PostTileHead justify-content-between gap-2">
						<PostAuthorPreview post={post} className="mobile-author-preview" />
						<PostDates postId={post.PostId} isReaded={!this.props.isReaded} publishedDate={post.PublishedDate} priorityTo={post.PriorityTo} />
					</div>
					<TileImage images={this.props.images} postTypeId={post.PostTypeId} postId={post.PostId} onImageFetch={() => { }} />
					<div className={"post-content gap-1"}>
						<PostInfos post={post} isReaded={!this.props.isReaded} />
						<QueryTagsList
							queryKey={["Categories", "Post", post.PostId]}
							queryFn={() => genRequest("Categories/Post/" + post.PostId)}
						/>
						{
							post.ModerationComment &&
							<div className="tag moderated-tag">{t("Posts.MODERATED")}</div>
						}
						<div className="overflow-hidden">
							{this.genTitle()}
							{this.genText()}
						</div>
						{
							txt.length >= 128 &&
							<div className="post-text-hidder pe-3">
								{
									!this.props.isActive &&
									t("Posts.READ_MORE")
								}
							</div>
						}
					</div>
				</>)
				}
			</motion.div>
		);
	}
}

function PostInfos({ post, isReaded }) {
	return (
		<div className="PostInfos d-flex justify-content-between gap-1 align-items-center">
			<PostAuthorPreview post={post} />
			<PostDates postId={post.PostId} isReaded={isReaded} publishedDate={post.PublishedDate} priorityTo={post.PriorityTo} />
		</div>
	);
}

function PostAuthorPreview({ post, className, isMobile }) {
	return (
		<div className={className}>
			<AuthorPreview
				employeId={post.EmployesId}
				isMobile={isMobile}
				className={"posts-list-author-preview"}
				format={[
					"EmployeFirstName EmployeLastName",
					"WFU",
					{
						date: {
							date: post.PublishedDate,
							format: t("Events.TILE_DATE_FORMAT")
						}
					}
				]}
				modifiers={{
					EmployeLastName: (item) => item.toUpperCase()
				}}
			/>
		</div>
	);
}

function PostDates({ postId, publishedDate, priorityTo, isReaded }) {
	let date = formatDate();

	return (
		<div className="PostDates gap-1 align-items-start">
			{isReaded && <NewLabel className="m-0" />}
			{(publishedDate && publishedDate > date) && <div className="d-flex align-items-center justify-content-center" style={{ paddingTop: "1px" }}><FontAwesomeIcon className="differed-picto" title="Publication programmée" icon={faCalendarAlt} /></div>}
			{(priorityTo && priorityTo > date) && <div className="d-flex align-items-center justify-content-center" style={{ marginTop: "2px" }}><FontAwesomeIcon className="priority-picto" title="Publication prioritaire" icon={faThumbtack} /></div>}
			<SavePostBtn style={{ marginTop: "-2px" }} postId={postId} iconOnly={true} hideIfNotSaved={true} />
		</div>
	);
}

export default PostTile;

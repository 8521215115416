import React from "react";
import PostsListWidget from "Pages/Home/Widgets/Posts/PostsList/PostsListWidget";

export default function CompanyHomePage(props) {
	return (
		<div className="CompanyHomePage">
			<PostsListWidget companyId={props.companyId}/>
		</div>
	);
}

export function getSpecs(type, chart) {
	let specs = {
		"title": "",
		"titleStyle": {
			"fontFamily": "Poppins-Light"
		},
		"subTitleStyle": {
			"textOverflow": "auto",
			"fontFamily": "Poppins-Light"
		},
		"primaryXAxis": {
			"title": chart?.options("AxeX") || "",
			"valueType": "Category",
			"interval": 1,
			"labelStyle": {
				"fontFamily": "Poppins-Light"
			},
			"titleStyle": {
				"fontFamily": "Poppins-Light"
			},
			"edgeLabelPlacement": "Shift"
		},
		"primaryYAxis": {
			"title": chart?.options("AxeY") || "",
			"labelStyle": {
				"fontFamily": "Poppins-Light"
			},
			"titleStyle": {
				"fontFamily": "Poppins-Light"
			}
		},
		"chartArea": {
			"border": {
				"width": 0
			}
		},
		"width": "100%",
		"tooltip": {
			"enable": true,
			"textStyle": {
				"fontFamily": "Poppins-Light"
			}
		},
		"legendSettings": {
			"textStyle": {
				"fontFamily": "Poppins-Light"
			}
		}
	};

	if (["Area", "SplineArea"].indexOf(type) !== -1)
		specs.primaryXAxis.edgeLabelPlacement = "Shift";
	return (specs);
}

export function getChartColors(table) {
	let colors = [];
	table.querySelectorAll(".color-picker input").forEach((a) => {
		colors.push(a.value);
	});
	return (colors);
}

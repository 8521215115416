import React, { useEffect, useState } from "react";
import Konami from "konami";
import "./App.css";
import "./fonts.css";
import useToken from "classes/Accounts/hooks/useToken";
import ForgetPage from "Pages/Login/ForgetPage/ForgetPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BrainWrapper from "components/BrainWrapper/Wrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoginPage from "Pages/Login/LoginPage";
import Storage from "classes/Storage/Storage";
// import useMenu from "hooks/useMenu";
import useAccount from "classes/Accounts/hooks/useAccount";
import ReactModal from "react-modal";
import useEditing from "components/EditDrawer/useEditing";
import { AccountProvider } from "providers/AccountProvider";
import { FilterProvider } from "providers/FilterProvider";
import { PostProvider } from "providers/PostProvider";

// import CreatePage from "Pages/Create/Create";

// eslint-disable-next-line
const easterEgg = new Konami("https://shimos.fr/?boot");

export default function App() {
	const { token } = useToken();
	const storage = new Storage();
	const { account, setSettings, getSettings } = useAccount();

	const [langCode, setLang] = useState(getNavigatorLanguage());
	useEditing(false);

	function changeLang(code) {
		if (account?.CompanyId) setSettings("Language", code);
		else {
			localStorage.setItem("lang-code", code);
			window.location.reload();
		}
	}

	function getNavigatorLanguage() {
		let lang = navigator.language || navigator.userLanguage;

		if (account) {
			let from_settings = getSettings("Language");
			if (from_settings) return from_settings;
		}
		let from_local = localStorage.getItem("lang-code");
		if (from_local) return from_local;
		if (!lang) return "en-EN";
		if (lang.indexOf("-") === -1) lang = lang + "-" + lang.toUpperCase();
		return lang;
	}

	const get_load_img = () => storage.getItem("comp_image");

	if (!token)
		return (
			<Router>
				<Switch>
					{/* <Route exact path={"/create"}>
						<CreatePage setLangCode={setLang}/>
					</Route> */}
					<Route exact path="/forget">
						<ForgetPage setLangCode={setLang} />
					</Route>
					<Route exact path={["/:accountId", "*"]}>
						<LoginPage setLangCode={setLang} />
					</Route>
				</Switch>
			</Router>
		);

	return (
		<AccountProvider>
			<PostProvider>
				<FilterProvider>
					<div
						ref={(ref) => ReactModal.setAppElement(ref)}
						className="wrapper"
						style={{
							backgroundImage: `url(${
								get_load_img()?.Url_Origin
							})`,
						}}
					>
						<ToastContainer
							position="top-right"
							autoClose={7000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick={true}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<BrainWrapper
							code={langCode}
							setLangCode={changeLang}
							// getMenuItems={getMenuItems}
							// getMenuItem={getMenuItem}
						/>
					</div>
				</FilterProvider>
			</PostProvider>
		</AccountProvider>
	);
}

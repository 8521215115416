import { useTranslation } from 'react-i18next';
import "./LanguageSwitcher.css"

const Trads = [
	{
		minCode: "fr",
		code: "fr-FR",
		title: "Français",
	},
	{
		minCode: "en",
		code: "en-EN",
		title: "English",
		flag_code: "US",
	}
];

export default function LanguageSwitcher({handleChange}) {

	const { i18n } = useTranslation();
	const flags_url = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";

	function handleClick(code) {
		i18n.changeLanguage(code);
		if (handleChange)
			handleChange(code);
		else
			window.location.reload();
	}

	return (
		<div className="locales-menu-cont">
			{
				Trads.map((lang) => {
					let flag_code;
					if (lang.flag_code)
						flag_code = lang.flag_code;
					else
						flag_code = lang.code.split("-")[1];
					return (
						<div className="locales-item" key={lang.code}>
							<a href={"#" + lang.code} onClick={() => handleClick(lang.code)}>
								<div className={"flag-icon me-2"}>
									<img loading="lazy" alt={"flag-icon-" + lang.code} src={flags_url + flag_code + ".svg"}/>
								</div>
								<div>
									{lang.title}
								</div>
							</a>
						</div>
					);
				})
			}
		</div>
	);
}

import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import CategoriesSelect from "components/CustomSelects/CategoriesSelect";
import CustomDatePicker from "components/DatePIcker/DatePicker";
import FormInput from "components/Inputs/FormInput";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import formatDate, { dateAdd } from "includes/formatDate";
import { t } from "i18next";

function AnimationContentStep(props) {
	const { account } = useAccount();
	let cur_anim = props.object;

	function catsSelect(values) {
		cur_anim.categories(
			values.map((a) => ({
				CategoryId: (!a.isNew ? a.value : false),
				DefaultHexaColor: a.color,
				Name: a.label,
				isNew: a.isNew ? true : false
			}))
		);
	}

	return (
		<>
			<div className="inner-step-cont">
				<FormInput name="animation-title" onChange={(val) => cur_anim.title(val)} className="w-100 p-2" title={t("Events.EVENT_TITLE")} value={cur_anim.title()} />
				<CategoriesSelect
					noTitle={false}
					onSelect={catsSelect}
					initialValue={cur_anim.categories ? cur_anim.categories().map(a => ({ label: a.Name, value: a.CategoryId })) : false}
					type={"post-8"}
					companyId={account.CompanyId}
				/>
			</div>
			<DatesSelect event={cur_anim} />
		</>
	);
}

function DatesSelect(props) {
	const { event } = props;
	const [startDate, setStartDate] = useState(new Date(event.dateStart()));
	const [endDate, setEndDate] = useState(new Date(event.dateEnd()));
	const [isAllDay, setIsAllDay] = useState(event.isAllDay() || false);

	function handleAllDay(checked) {
		setEndDate(startDate);
		if (event)
			event.isAllDay(checked);
		setIsAllDay(checked);
	}

	function handleStartDate(date) {
		if (event) {
			event.dateStart(formatDate(date));
			if (isAllDay) {
				event.dateStart(formatDate(date.setHours(0, 0, 0, 0)));
				event.dateEnd(formatDate(date.setHours(23, 59, 59, 0)));
			}
		}
		setStartDate(date);
		if (date > endDate) {
			let end = new Date(dateAdd(date, { hours: 1 }));
			event.dateEnd(formatDate(end));
			setEndDate(end);
		}
	}

	function handleEndDate(date) {
		if (event)
			event.dateEnd(formatDate(date));
		setEndDate(date);
	}

	return (
		<div className="inner-step-cont dates-cont">
			<div className="d-flex justify-content-between align-items-center">
				<div>{t("Events.DATES")}</div>
				<SwitchInput reverse={true} checked={isAllDay} onChange={(e) => handleAllDay(e.target.checked)} label={t("Events.FULL_DAY")} />
			</div>
			<div className="mt-3">
				{!isAllDay && <div>{t("Events.START")}</div>}
				<CustomDatePicker
					showTimeSelect={!isAllDay}
					selectStart={!isAllDay}
					handleChange={handleStartDate}
					startDate={!isAllDay ? startDate : false}
					endDate={!isAllDay ? endDate : false}
					selected={startDate}
					minDate={new Date()}
				/>
			</div>
			{
				!isAllDay &&
				<div className="mt-3">
					<div>{t("Events.END")}</div>
					<CustomDatePicker
						selectEnd
						handleChange={handleEndDate}
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
						selected={endDate}
					/>
				</div>
			}
		</div>
	);
}

export function checkAnimContent(anim, account, isSup) {
	let post = anim.linkedPost();
	if (!anim.title())
		return (t("Events.TITLE_ERROR"));
	post.title(anim.title());
	if (!post.draft()) {
		if (isSup("SiteAdmin") >= 0)
			post.setAsPublished();
		else
			post.setAsToModerate();
	}
	return (true);
}

export default AnimationContentStep;

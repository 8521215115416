import React from "react";
import {useQueryClient} from "react-query";
import { t } from "i18next";
import AnimationPreview from "Pages/Animations/components/AnimationPreview/AnimationPreview";

export default function AnimationEndStep(props) {
	let anim = props.object;
	let post = anim.linkedPost();
	const queryClient = useQueryClient();

	if (!props.isActive)
		return (false);

	function genPreviewText() {
		if (!anim.isDraft())
			return (t("Posts.POST_PUBLISHED"));
		else {
			// eslint-disable-next-line
			if (!anim.isDraft() && false) // disabled moderation for now
			{
				return (
					<div>
						<div>{t("Posts.POST_MODERATION")}</div>
						<div>{t("Posts.POST_MODERATION_2")}</div>
					</div>
				);
			} else {
				return ( t("Posts.POST_DRAFTED") );
			}
		}
	}
	queryClient.resetQueries(["FullPostnDoc", post.PostId]);
	let event = anim.values();
	event.id = event.AnimationId;
	event.start = event.DateStart;
	event.end = event.DateEnd;
	event.id = event.AnimationId;
	return (
		<div className="d-flex flex-column flex-md-row justify-content-between overflow-auto inner-step-cont">
			<div className="ms-2">
				{
					genPreviewText()
				}
			</div>
			<AnimationPreview
				id="post-preview-after-send"
				className="mt-3 mt-md-0"
				extend={false}
				responsive={false}
				event={event}
				export={false}
			/>
		</div>
	);
}

import React from "react";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import genRequest from "includes/request";
import { t } from "i18next";

export function Origin({siteId}) {
	return (
		<div>
			<b>{t("Posts.ORIGIN")}</b>
			<InfosFetcher
				queryFn={() => genRequest("Sites/" + siteId)}
				queryKey={["Sites", siteId]}
				treat={(resp) => ([resp.Name, resp.Town].join(" - "))}
			/>
		</div>
	);
}


export function CreatedFrom({value}) {

	if (!value)
		return (false);

	let links = value.match(/((^|[^(])http(s|):\/\/[a-zA-Z0-9?&\-%./=]{3,}($|))/gi);

	return (
		<div>
			<div className="font-bold">{t("Posts.CREATED_FROM")}:</div>
			<div>
				{
					links ?
						<a target={"blank_"} href={links[0]}>{links[0]}</a>
						:
						value
				}
			</div>
		</div>
	);
}

import React from "react";
import { useQuery } from "react-query";
import GroupTile from "./Tile/GroupTile";
import "./GroupsTiles.css";
import noAccent from "includes/no_accents";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import { t } from "i18next";

function GroupsTiles(props) {
	const { account } = useAccount();
	const { isLoading, data, error } = useQuery(
		["Groups", "OfCompany", account.CompanyId],
		() => genRequest("Groups/OfCompany/" + account.CompanyId)
	);

	const isDiplayed = (item, search) => {
		if (!search)
			return (true);
		for (let x in item)
			if (noAccent(item[x]).match(search))
				return (true);
		return (false);
	};

	if (isLoading)
		return (<div className="w-100 text-center">{t('Commons.LOADING')}</div>);

	if (error)
		return (<div className="w-100 text-center">{t("ErrorMsg.GroupError1")}</div>);

	if (data?.length) {
		let reg = null;
		if (props.search)
			reg = new RegExp(noAccent(props.search), "gi");
		let items = data.sort((a, b) => (a.Name < b.Name ? -1 : 1)).map(a => (
			isDiplayed(a, reg) ?
				<GroupTile onClick={() => props.onClick(a)} key={a.GroupId} group={a} />
				:
				false
		)).filter(a => a);

		return (
			<div className="GroupsTiles">
				{
					(items?.length) ?
						items
						:
						<div className="w-100 text-center mt-4">
							{t("Commons.NO_RESULT")}
						</div>
				}
			</div>
		);
	}
}

export default GroupsTiles;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import { t } from "i18next";
import React, { useState } from "react";
import { TourPreviewModal } from "./TourPreview";
import { TourStepPopper } from "./TourStepEditor";

const killEvent = (e) => {
	if (!e)
		return (false);
	e.preventDefault();
	e.stopPropagation();
}

export default function RecorderToolbar(props) {
	const {tour, isAdding, previewTour} = props;
	const [referenceElement, setReferenceElement] = useState(null);
	const [ShowSteps, setShowSteps] = useState(false);

	const handleStop = (e) => {
		killEvent(e);
		props.onEnd();
	}

	const handleAddStep = (e) => {
		props.setSelectingTarget(!props.selectingTarget);
	}

	return (
		<div className="RecorderToolbar d-flex gap-2 align-items-center no-highlight" ref={setReferenceElement}>
			<Button
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					setShowSteps(!ShowSteps)
				}}
				className="gap-1 no-wrap btn-bg"
				title="Afficher les étapes du tutoriel"
			>
				{t("HelperTourEditor.STEPS", {count: tour.steps?.length})}
			</Button>
			<vr style={{height: "20px"}}></vr>
			<Button
				title={props.selectingTarget ? "Annuler la selection d'élément" : "Ajouter une étape au tutoriel"}
				className={"no-wrap gap-1 " + (props.selectingTarget ? "btn-yellow" : "btn-green")}
				onClick={(e) => {e.stopPropagation(); e.preventDefault(); handleAddStep()}}
			>
				{
					props.selectingTarget ?
						"Annuler"
						:
						<>
							<FontAwesomeIcon icon={faPlus} color="white"/>
							Nouvelle étape
						</>
				}
			</Button>
			<Button
				onClick={previewTour}
				className="gap-1 btn-cyan"
				title="Visualiser"
				disabled={tour.steps.length <= 0}
			>
				Visualiser
			</Button>
			<Button
				onClick={handleStop}
				className="gap-1 btn-red"
				title="Terminer l'édition du tutoriel"
			>
				Terminer
			</Button>
			{
				isAdding &&
					<TourStepPopper
						refElem={referenceElement}
						isAdding={isAdding}
						saveEvent={props.saveEvent}
						onCancel={props.onCancel}
						highlight={props.highlight}
					/>
			}
			{ShowSteps &&
				<TourPreviewModal
					tour={tour}
					setTour={props.setTour}
					onClose={(e) => {
						killEvent(e);
						setShowSteps(false);
						props.highlight(false, true)
					}}
					highlight={props.highlight}
				/>
			}
		</div>
	)
}

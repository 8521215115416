import useAccount from "classes/Accounts/hooks/useAccount";
import CustomAsyncSelect from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";
import React from "react";

function ContactStep(props) {
	const { account } = useAccount();
	let site = props.object;

	const treatAdmins = (res) => {
		return ({
			label: res.LastName.toUpperCase() + " " + res.FirstName,
			value: res.EmployesId
		});
	};
	return (
		<div className="inner-step-cont">
			<div className="row">
				<FormInput className="col-6" name="phone" type="phone" pattern={"[0-9]{1,10}"} value={site.phone()} onChange={(val) => site.phone(val)} title={t("Contacts.PHONE")}/>
				<FormInput
					className="col-6"
					type="email"
					name="mail"
					value={site.mail()}
					onChange={(val) => site.mail(val)}
					title={t("Contacts.MAIL")}/>
			</div>
			<div className="d-flex mt-3">
				<FormInput className="w-100" name="website" title={t("Contacts.WEBSITE")} value={site.website()} onChange={(val) => site.website(val)}/>
			</div>
			<div className="d-flex mt-3">
				<CustomAsyncSelect
					url={"Employes/AdminsOfCompany/" + account.CompanyId }
					treat={treatAdmins}
					title={t("Contacts.MAIN_CONTACT")}
					onSelect={(val) => site.adminId(val)}
					initialValue={site.adminId()}
					placeholder={t("Contacts.MAIN_CONTACT_PH")}
					text={t("Contacts.MAIN_CONTACT_MSG")}
					timeout={10}
				/>
			</div>
		</div>
	);
}

export function checkContact(site) {
	let mail_regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	let phone_regexp = /^[0-9]{10}$/;
	let site_regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

	let errors = [];
	if (site.phone()?.length && !site.phone().match(phone_regexp))
		errors.push(t("Contacts.ERROR_PHONE"));
	if (site.mail()?.length && !site.mail().match(mail_regexp))
		errors.push(t("Contacts.ERROR_MAIL"));
	if (site.website()?.length && !site.website().match(site_regexp))
		errors.push(t("Contacts.ERROR_WEBSITE"));

	return (errors.length ? errors : true);
}

export default ContactStep;

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import useUnread from "classes/Accounts/hooks/useUnread";

function useCurPage(force_page) {
	const history = useHistory();
	const { updatePageTitle } = useUnread();
	const QCL = useQueryClient();

	const getPage = () => {
		const page = sessionStorage.getItem("current_page");
		if (!page || page === "undefined") return "/";
		return page;
	};

	const [CurPage, setPage] = useState(
		force_page !== "undefined" ? setSessCurPage(force_page) : getPage()
	);

	const setCurPage = (page, force_reload) => {
		let new_page = page && page !== "undefined" ? page : "/";
		sessionStorage.setItem("current_page", new_page);
		if (history) history.push(new_page);
		if (force_reload) QCL.refetchQueries(["Template", "CurPage"]);
		return setPage(new_page);
	};

	function changePage(page, force_reload) {
		if (typeof page === "string") setCurPage(page, force_reload);
		else if (page) {
			updatePageTitle(page.title);
			if (CurPage === page.url || force_reload)
				QCL.refetchQueries(["Template"]);
			setCurPage(page.url, force_reload);
		} else setCurPage(force_page);
	}

	return {
		setCurPage: changePage,
		getCurPage: getPage,
		CurPage,
	};
}

const setSessCurPage = (page) => {
	sessionStorage.setItem("current_page", page);
	return page;
};

export default useCurPage;

import React, { useState } from "react";
import { useQuery } from "react-query";
import PageLoad from "components/PageLoad/PageLoad";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import { t } from "i18next";
import useTemplate from "hooks/useTemplate";

export default function ChartsController(props) {
	const {account} = useAccount();
	const {template} = useTemplate();
	const [TypeId] = useState(props.typeId ? props.typeId : 1);
	const [CompanyId] = useState(props.companyId ? props.companyId : account.CompanyId);
	const [State] = useState(props.state ? props.state : "published");
	const [CurChart, setCurChart] = useState(false);

	const retrieveCompanyCharts = async () => genRequest("Charts/GetChartsOfCompany/" + CompanyId);

	function handlePostClick(post) {
		setCurChart(post);
	}

	const sortCharts = (charts) => {
		if (!charts?.length)
			return ([]);

		let ret = charts.sort((a, b) => {
			if (a.Position && b.Position)
			{
				if (a.Position < b.Position)
					return (-1);
			} else if (a.LastChanged < b.LastChanged)
				return (-1);
			return (1);
		});
		return (ret);
	};

	const filterCharts = (charts) => {
		if (!charts?.length)
			return ([]);
		let res = charts.filter((a) => {
			if (a.IsDraft === props.showDrafts)
				return (true);
			return (false);
		});
		res = sortCharts(res);
		return (res);
	};

	const {isLoading, data, isFetching} = useQuery(
		["Charts", "GetChartsOfCompany", CompanyId, "Drafts=" + props.showDrafts],
		retrieveCompanyCharts,
		{
			retry: 0,
			refetchOnWindowFocus: false
		}
	);

	if (isLoading || isFetching)
		return ( <PageLoad background={false} color={template.primaryColor()} text={t("Charts.LOADING")}/>);

	if (props.childComponent)
		return (
			<props.childComponent
				typeId={TypeId}
				theme={props.theme}
				charts={filterCharts(data)}
				state={State}
				onPostClick={handlePostClick}
				curChart={CurChart}
				{...props.childProps}
			/>
		);
	return (false);

}

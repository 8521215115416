import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import PageLoad from "components/PageLoad/PageLoad";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import genRequest from "includes/request";
import documents_treat from "includes/documents_treat";
import useTemplate from "hooks/useTemplate";

export default function DocumentsController(props) {
	const { account } = useAccount();
	const { template } = useTemplate();
	const [TypeId] = useState(props.typeId ? props.typeId : 1);
	const [CompanyId] = useState(
		props.companyId ? props.companyId : account.CompanyId
	);
	const [Site, setSite] = useState({
		value: 0,
		label: t("Commons.ALL_SITES"),
	});
	const [SiteId, setSiteId] = useState(props.siteId ? props.siteId : false);
	const [State] = useState(props.state ? props.state : "published");
	const [CurDoc, setCurDoc] = useState(false);
	const [Saved, setSaved] = useState(false);
	const QCL = useQueryClient();

	function setCurSite(new_site) {
		if (new_site.value === 0) setSiteId(false);
		else setSiteId(new_site.value);
		setSite(new_site);
	}

	function getRetrieveReq() {
		if (Saved)
			return {
				queryKey: [
					"DocumentsController",
					"Documents",
					"LT_Employe_Saved_Document",
					"EmployeId",
					account.EmployesId,
				],
				queryFn: fetchSavedDocs,
			};
		if (SiteId)
			return {
				queryKey: [
					"DocumentsController",
					"Documents",
					"AllDocumentsOfSiteTyped",
					TypeId,
					SiteId,
				],
				queryFn: retrieveSiteDocs,
			};
		if (!CompanyId)
			return {
				error: true,
			};
		return {
			queryKey: [
				"DocumentsController",
				"Documents",
				"AllDocumentsOfCompanyTyped",
				TypeId,
				CompanyId,
			],
			queryFn: retrieveCompanyDocs,
		};
	}

	// const fetchUserSite = async () =>
	// 	genRequest("Sites/" + account.SiteId).then((resp) =>
	// 		resp ? [resp] : []
	// 	);

	// const fetchSites = async () =>
	// 	genRequest("Sites/SitesOfCompany/" + CompanyId);

	const retrieveSiteDocs = async (forceSite) => {
		let siteId = forceSite ? forceSite : SiteId;
		let req = genRequest(
			"Documents/AllDocumentsOfSiteTyped/" + siteId + "/" + TypeId
		).then(
			(resp) => {
				return documents_treat(resp, false, false, { SiteId: siteId });
			},
			() => []
		);
		return req;
	};

	const fetchCompanyDoc = async () => {
		let prom = genRequest(
			"Documents/AllDocumentsOfCompanyTyped/" + CompanyId + "/" + TypeId
		).then(
			(resp) => {
				if (!resp?.length) return [];
				let mapped = resp.map((a) => {
					let ret = { ...a };
					ret.CompanyId = CompanyId;
					return ret;
				});
				return mapped;
			},
			() => {}
		);
		return prom;
	};

	const retrieveCompanyDocs = async () => {
		let prom = new Promise((resolve, reject) => {
			let proms = [];
			proms.push(fetchCompanyDoc());
			if (!account.IsTemp) proms.push(genRequest("Me/TargetDocument"));
			// if (isAdmin())
			// 	proms.push(
			// 		genRequest(
			// 			"Documents/TargetsDocumentsOfCompanyTyped/" +
			// 				CompanyId +
			// 				"/" +
			// 				TypeId
			// 		).catch(() => {})
			// 	);
			Promise.all(proms).then((resp) => {
				let res = rm_double(resp);
				res = documents_treat(res, false, false, {
					CompanyId: CompanyId,
				});
				resolve(res);
				return true;
			}, reject);
		});
		return prom;
	};

	const fetchSavedList = async () => {
		let prom = new Promise((resolve, reject) => {
			let req = genRequest(
				"LT_Employe_Saved_Document/EmployeId/" + account.EmployesId
			);

			req.then(
				(resp) => resolve(resp),
				(e) => {
					if (e.response.status === 404) {
						resolve([]);
						return [];
					}
					reject([]);
					return false;
				}
			);
			return req;
		});
		return prom;
	};

	const fetchSavedDocs = async () => {
		let prom = new Promise((resolve, reject) => {
			let docs = [];
			retrieveCompanyDocs()
				.then((resp) => {
					docs = resp;
					return fetchSavedList();
				}, reject)
				.then(
					(lt_docs) => {
						let ret = docs.filter((a) =>
							lt_docs.find((b) => a.DocumentId === b.DocumentId)
						);
						resolve(ret);
					},
					() => reject([])
				);
		});
		return prom;
	};

	function rm_double(docs) {
		let arr = [];
		let indexes = [];
		for (let x = 0; x < docs.length; x++)
			for (let y = 0; y < docs[x]?.length; y++)
				if (indexes.indexOf(docs[x][y].DocumentId) === -1) {
					arr.push(docs[x][y]);
					indexes.push(docs[x][y].DocumentId);
				}
		return arr;
	}

	function refreshList() {
		let prom = getRetrieveReq();
		QCL.resetQueries(prom.queryKey);
	}

	let prom = getRetrieveReq();

	const { isLoading, data, error } = useQuery(prom);

	if (isLoading)
		return (
			<PageLoad
				background={false}
				color={template?.primaryColor()}
				text={t("Docs.LOADING")}
			/>
		);
	if (error) return <div>{t("Docs.LOAD_ERROR")}</div>;

	if (props.childComponent)
		return (
			<props.childComponent
				curSite={Site}
				setSite={setCurSite}
				savedState={Saved}
				setSaved={setSaved}
				typeId={TypeId}
				docs={data ? data : []}
				title={props.title}
				state={State}
				onPostClick={setCurDoc}
				curDoc={CurDoc}
				account={account}
				refreshList={refreshList}
				{...props.childProps}
			/>
		);
	return false;
}

import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";
import React from "react";
import { useParams } from "react-router";

export default function Alertspage(props) {
	const { account, isAdmin } = useAccount();

	let { id } = useParams();

	return (
		<PostsPage
			type="alerts"
			baseUrl={props.baseUrl}
			title={props.title}
			companyId={account.CompanyId}
			typeId={13}
			forceCurId={id}
			isAdmin={props.isAdmin}
			add={isAdmin()}
			addTitle={t("Alerts.New_Alert")}
			modify={true}
			canComment={false}
			showComments={false}
			isMenuOpen={props.isMenuOpen}
			setIsMenuOpen={props.setIsMenuOpen}
			moderation={false}
			platform={props.platform}
		/>
	);
}

import useAccount from "classes/Accounts/hooks/useAccount";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import generateUUID from "includes/UUID";
import React, { useState } from "react";
import "./Assignator.css";
import AssignRow from "./components/AssignRow/AssignRow";
import { getAssignTypes, getNextTypes } from "./components/AssignTypes";
import Select from "react-select";
import AssignResume from "./components/AssignResume/AssignResume";
import Button from "components/Button/Button";
import assignsBuilder from "./components/AssignsBuilder";
import { t } from "i18next";

export default function Assignator(props) {
	const { account } = useAccount();
	const [CurRow, setCurRow] = useState(false);
	const [Assigns, setAssigns] = useState([]);
	const { handleUpdate } = props;
	const isInitied = React.useRef();

	React.useEffect(() => {
		if (!isInitied.current) setAssigns(initAssigns(props.initAssigns));
		isInitied.current = true;
		return () => {
			isInitied.current = false;
			setAssigns([]);
		}
	}, [props, setAssigns]);

	function initAssigns(linkeds) {
		let ret = [];

		if (!linkeds?.length) return ret;
		ret = assignsBuilder(linkeds);
		return ret;
	}

	function updateAssigns(values) {
		if (handleUpdate) handleUpdate(getValues(values));
		setAssigns(values);
	}

	function addAssign(item) {
		let dup = [...Assigns];

		item.CompanyId = account.CompanyId;
		item.deepLevel = 0;
		item.rowId = generateUUID();
		item.ids = {
			CompanyId: account.CompanyId,
		};
		item.assigns = [];
		dup.push(item);
		setCurRow(item);
		updateAssigns(dup);
	}

	function rmAssign(item) {
		let res = Assigns.map((a) => {
			if (a.rowId === item.rowId) return false;
			return a;
		}).filter((a) => a);

		if (item.rowId === CurRow.rowId || !res?.length)
			setCurRow(res?.length ? res[res.length - 1] : false);

		updateAssigns(res);
	}

	function updateAssign(item) {
		let dup = Assigns.map((a) => {
			if (a.rowId === item.rowId) return item;
			return a;
		});
		updateAssigns(dup);
	}

	function getValues(forceAssigns) {
		let ret = [];
		let assigns = Assigns;
		if (forceAssigns) assigns = forceAssigns;
		assigns.forEach((assi, i) =>
			ret.push(
				...getAssignValues(
					assi,
					{},
					(!isCompanyAssign(assi) ? "0-" : "") + i.toString() // définit l'affectation de compagnie si l'affectation n'est pas relié directement a la compagnie
				)
			)
		);
		return clean_assigns(ret);
	}

	function isCompanyAssign(assign) {
		let types = getAssignTypes();
		return assign.deepLevel === 0 && assign.type.value === types[0].value;
	}

	function getAssignValues(assign, item, assignGroupId) {
		let ret = [];

		if (!assign.values?.length) return [item];
		assign.values.forEach((val) => {
			let new_item = {
				...item,
				AffectationGroupId: assignGroupId,
				[assign.type.itemId]: val.value,
				CompanyId: val.CompanyId,
			};
			if (assign.assigns?.length) {
				let deep = [];
				assign.assigns.forEach((sub, i) =>
					deep.push(
						...getAssignValues(
							sub,
							new_item,
							assignGroupId + "-" + i
						)
					)
				);
				ret.push(...deep);
			} else ret.push(new_item);
		});
		return ret;
	}

	function clean_assigns(assigns) {
		let ret = [];
		let parsed = [];

		if (!assigns.length) return [];
		for (let assi of assigns) {
			if (typeof assi === "undefined") continue;
			let string = JSON.stringify(assi);
			if (!(ret.indexOf(string) >= 0 && string !== "undefined"))
				ret.push(string);
		}
		if (ret?.length) parsed = ret.map((a) => JSON.parse(a));
		return parsed;
	}

	return (
		<div className="Assignator">
			<RowAdder
				onAdd={addAssign}
				isActive={!CurRow}
				onClick={() => {
					if (Assigns?.length > 1) setCurRow(false);
				}}
				isFirst={Assigns?.length < 1}
			/>
			<hr />
			<AssignationsList
				length={Assigns.length}
				items={Assigns}
				cur={CurRow}
				setCur={setCurRow}
				onDelete={rmAssign}
				onUpdate={updateAssign}
			/>
		</div>
	);
}

function RowAdder(props) {
	const { onAdd, isActive, onClick } = props;
	const [Type, setType] = useState();
	const [error, setError] = useState([]);

	function handleAdd(e, forcedType) {
		let addType = "AND";
		if (e) {
			e.preventDefault();
			e.stopPropagation();
			addType = e.target.getAttribute("addtype");
		}

		let item = {
			type: forcedType || Type,
			addType,
		};

		if (!item?.type) {
			setError(t("Assignator.NO_TYPE_SELECTED_ERROR"));
			return false;
		}
		setError(false);
		if (onAdd) onAdd(item);
	}

	return (
		<div
			className={`AssignRow RowAdder ${isActive ? "active" : ""}`}
			onClick={onClick}
		>
			<div className="row-head">
				<div className="row-type">{t("Assignator.TARGET")}</div>
			</div>
			<div className="row-body flex-row justify-content-between">
				<div className="d-flex align-items-center">
					<TypeSelect
						onChange={(e) => {
							setType(e);
							if (props.isFirst) handleAdd(false, e);
						}}
					/>
					<ErrorsCont className="ms-2" errors={error} />
				</div>
				<div className="row-buttons flex-row">
					<Button
						addtype="AND"
						className="btn btn-blue me-2"
						onClick={handleAdd}
					>
						{t("Assignator.NEW_TARGET")}
					</Button>
					{/* <Button addtype="OR" className="btn btn-primary" onClick={handleAdd}>Or</Button> */}
				</div>
			</div>
		</div>
	);
}

export function AssignationsList({
	items,
	className,
	forceResume,
	onDelete,
	cur,
	setCur,
	onUpdate,
	isSubAssigns,
}) {
	if (!items?.length) return false;

	return (
		<div className={`AssignationList ${className ? className : ""}`}>
			{items.map((a) => {
				let isActive = cur?.rowId === a.rowId;

				if (isActive || (isSubAssigns && !forceResume))
					return (
						<AssignRow
							key={a.rowId}
							onDelete={onDelete}
							onUpdate={onUpdate}
							item={a}
							setCur={setCur}
							isSubAssign={isSubAssigns}
							isActive={cur?.rowId === a.rowId}
						/>
					);
				return (
					<AssignResume
						key={a.rowId}
						isSubAssigns={isSubAssigns}
						setCur={setCur}
						item={a}
					/>
				);
			})}
		</div>
	);
}

export function TypeSelect(props) {
	const { isSup } = useAccount();
	const { onChange, value, className, isSubAssign, baseType } = props;
	const options = getNextTypes(
		isSubAssign ? baseType?.value : baseType?.value,
		isSup
	);

	function handleSelect(e) {
		if (onChange) onChange(e);
	}

	return (
		<Select
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			onChange={handleSelect}
			classNamePrefix="dropdown"
			className={"assign-drop" + (className ? " " + className : "")}
			options={options}
			value={value}
		/>
	);
}

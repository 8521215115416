import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import PageLoad from "components/PageLoad/PageLoad";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import useTemplate from "hooks/useTemplate";
import noAccent from "includes/no_accents";
import useUnread from "classes/Accounts/hooks/useUnread";
import { useAccountContext } from "providers/AccountProvider";

function PostsFetcher(props) {
	const [TypeId] = useState(
		typeof props.typeId !== "undefined" ? props.typeId : 1
	);
	const [CompanyId] = useState(props.companyId ? props.companyId : false);
	const { account, isSup } = useAccount();
	const { getUnread, setAsReaded } = useUnread();
	const { template } = useTemplate();
	const queryClient = useQueryClient();
	const { userOrAdmin } = useAccountContext();

	const getRetrieveReq = () => {
		let siteId = props.siteId;

		if (props.queryFn)
			return {
				queryFn: props.queryFn,
				queryKey: props.queryKey,
			};

		if (props.employeId && !props.saveds)
			return {
				queryFn: fetchEmployePosts,
				queryKey: [
					"RetrievePosts",
					"Posts",
					"TypedPostOfEmploye",
					TypeId,
					account.EmployesId,
				],
			};

		if (props.employeId && !TypeId && !props.saveds)
			return {
				queryFn: fetchEmployePosts,
				queryKey: [
					"RetrievePosts",
					"Posts",
					"PostsOfEmploye",
					account.EmployesId,
				],
			};

		if (props.saveds)
			return {
				queryFn: fetchSavedPosts,
				queryKey: [
					"RetrievePosts",
					"Posts",
					"PostsSavedByEmployeId",
					account.EmployesId,
				],
			};

		if (
			!props.forceCompany &&
			(props.siteId || !(isSup("SiteAdmin") >= 0))
		) {
			if (!siteId) siteId = account.SiteId;
			return {
				queryKey: [
					"RetrievePosts",
					"Posts",
					"PostsOfSiteTyped",
					TypeId,
					siteId,
				],
				queryFn: () => retrieveSitePosts(siteId),
			};
		}

		if (
			props.favSites.length > 0 &&
			TypeId !== 13 &&
			userOrAdmin === "admin"
		) {
			const sites = props.favSites;
			return {
				queryFn: () => retrievesMultipleSitesPosts(sites),
				queryKey: [
					"FavSites",
					"Posts",
					"PostsOfFavSites",
					TypeId,
					props.favSites.join("-"),
				],
			};
		}
		return {
			queryKey: [
				"RetrievePosts",
				"Posts",
				"PostsOfCompanyTyped",
				TypeId,
				CompanyId,
			],
			queryFn: retrieveCompanyPosts,
		};
	};

	const retrieveCompanyPosts = async () => {
		let comp_posts;
		const prom = new Promise((resolve, reject) => {
			genRequest("Posts/PostsOfCompanyTyped/" + CompanyId + "/" + TypeId)
				.then((posts) => {
					comp_posts = posts;
					return fetchTargetedPosts();
				}, reject)
				.then(
					(targeted_posts) => {
						if (targeted_posts && targeted_posts.length) {
							targeted_posts = targeted_posts.filter((a) =>
								!getPost(comp_posts, a.PostId) ? a : false
							);
							comp_posts = comp_posts.concat(targeted_posts);
						}
						resolve(comp_posts);
						return comp_posts;
					},
					() => {
						resolve(true);
						return true;
					}
				);
		});
		return prom;
	};

	const retrieveSitePosts = async (siteId) => {
		let sites_post;
		const prom = new Promise((resolve) => {
			genRequest("Posts/PostsOfSiteTyped/" + siteId + "/" + TypeId)
				.then(
					(posts) => {
						sites_post = posts;
						return fetchTargetedPosts();
					},
					() => {
						sites_post = [];
						return fetchTargetedPosts();
					}
				)
				.then(
					(targeted_posts) => {
						if (targeted_posts && targeted_posts.length) {
							targeted_posts = targeted_posts.filter((a) =>
								!getPost(sites_post, a.PostId) ? a : false
							);
						}
						sites_post = sites_post.concat(targeted_posts);
						resolve(sites_post);
						return sites_post;
					},
					() => {
						resolve([]);
						return true;
					}
				);
		});
		return prom;
	};

	const retrievesMultipleSitesPosts = async (sites) => {
		let sites_post = [];
		const prom = new Promise((resolve) => {
			const promises = [];
			sites.forEach((site) => {
				promises.push(
					genRequest("Posts/PostsOfSiteTyped/" + site + "/" + TypeId)
				);
			});
			Promise.all(promises).then((AllPosts) => {
				AllPosts.forEach((posts) => {
					sites_post = sites_post.concat(posts);
				});
				const unique = sites_post.filter(
					(obj, index) =>
						sites_post.findIndex(
							(item) => item.PostId === obj.PostId
						) === index
				);
				resolve(unique);
				return unique;
			});
		});
		return prom;
	};

	const fetchTargetedPosts = async () => {
		let url = "Me/TargetPost";

		if (account.IsTemp)
			url =
				"Posts/TargetPostOfCompanyTyped/" +
				account.CompanyId +
				"/" +
				TypeId;
		const req = await genRequest(url).then((posts) => {
			if (!posts?.length) return [];
			return posts;
		});
		return req;
	};

	const fetchSavedPosts = async () => {
		const req = await genRequest(
			"Posts/PostsSavedByEmployeId/" + account.EmployesId
		)
			.then((posts) => {
				return posts;
			})
			.catch(() => {
				return [];
			});
		return req;
	};

	const fetchEmployePosts = async () => {
		let url = "Posts/PostsOfEmploye/" + account.EmployesId;
		if (TypeId)
			url = `Posts/TypedPostOfEmploye/${TypeId}/${account.EmployesId}`;
		const req = await genRequest(url)
			.then((posts) => {
				return posts;
			})
			.catch(() => {
				return [];
			});
		return req;
	};

	function checkState(post) {
		let state = props.state;
		if (!state) state = "published";
		if (
			post.PostTypeId === TypeId &&
			((state === "waiting" &&
				post.ValidationModeration === false &&
				post.Draft === false &&
				!post.ValidationModeration) ||
				(state === "moderated" &&
					post.ModerationComment &&
					post.ValidationModeration === false &&
					post.Draft === true) ||
				(state === "published" &&
					post.ValidationModeration === true &&
					post.Draft === false) ||
				(state === "drafts" &&
					post.Draft === true &&
					!post.ModerationComment &&
					!post.ValidationModeration &&
					post.EmployesId === account.EmployesId))
		)
			return true;
		return false;
	}

	function searchFilter(posts, name_filter) {
		if (!name_filter?.length) return posts;
		let filter_reg = new RegExp(noAccent(name_filter), "gi");
		let ret = posts.filter((post) => {
			if (
				!name_filter ||
				noAccent(post.Title).search(filter_reg) !== -1 ||
				noAccent(post.Txt).search(filter_reg) !== -1
			)
				return post;
			return false;
		});
		return ret;
	}

	function treatPosts(posts) {
		if (!posts?.length) return [];
		let unreadeds = getUnread("NotViewedPost_" + TypeId);

		if (unreadeds && unreadeds.length)
			unreadeds.map((unr_post) => {
				let check = posts.filter((post) => {
					if (post.PostId === unr_post) return true;
					return false;
				});
				if (!check.length)
					setAsReaded("NotViewedPost_" + TypeId, unr_post);
				return check;
			});
		let current_date = new Date();
		let treated_posts = searchFilter(posts, props.search).filter((a) => {
			if (props.favSites.length === 0) {
				if (props.siteId && a.SiteId !== props.siteId) {
					return false;
				}
			} else if (userOrAdmin === "admin") {
				if (!props.favSites.includes(a.SiteId)) {
					return false;
				}
			}
			if (!a.PriorityTo || new Date(a.PriorityTo) < current_date)
				a.PriorityTo = a.PublishedDate;
			if (checkState(a)) return a;
			return false;
		});
		if (props.sortBy)
			treated_posts = treated_posts.sort((a, b) => {
				if (a[props.sortBy] < b[props.sortBy])
					return props.reverseSort ? -1 : 1;
				return props.reverseSort ? 1 : -1;
			});
		if (props.maxPosts)
			treated_posts = treated_posts.slice(0, props.maxPosts);
		if (props.notFirst) treated_posts.splice(0, 1);
		return treated_posts;
	}

	function getPost(posts, postId) {
		postId = parseInt(postId);
		let found = posts.find((a) => a.PostId === postId);
		return found ? found : false;
	}

	function forceRetrieve() {
		queryClient.resetQueries(["RetrievePosts"]);
	}

	let req = getRetrieveReq();
	const { isLoading, data } = useQuery(req.queryKey, req.queryFn);

	if (isLoading)
		return (
			<PageLoad
				background={false}
				color={template?.primaryColor()}
				text={t("Posts.LOADING")}
			/>
		);

	if (props.childComponent)
		return (
			<>
				<props.childComponent
					typeId={TypeId}
					posts={treatPosts(data)}
					forceRetrieve={forceRetrieve}
					{...props.childProps}
				/>
			</>
		);
	return false;
}

export default PostsFetcher;

import { getMedianColor } from "includes/Colors";
import { t } from "i18next";
import React from "react";
import "./TagLabel.css";

export default function TagLabel(props)
{
	let color = props?.color;
	let unselected_color = props?.color;

	if (props.color)
	{
		if (typeof props.color === "string")
			color = props.color;
		else {
			color = props.color.enabled;
			unselected_color = props.color.unselected;
			if (!props.selected)
				color = unselected_color;
		}
	}

	let fontColor = "#FFFFFF";
	if (color?.length && color?.indexOf("#") !== -1 && getMedianColor(color) > 140)
		fontColor = "#000000";

	return (
		<div
			title={props.title || props.text}
			className={"TagLabel" +
				(props.onClick && !props.noClick ? " cursor-pointer" : "") +
				(props.unstyled ? " unstyled" : "") +
				(props.selected ? " selected" : "") +
				(props.className ? " " + props.className : "")
			}
			style={props.unstyled !== true || (props.unstyled !== true && props.selected) ? {backgroundColor: color, color: fontColor, ...props.style} : (props.style || {})}
			onClick={props.onClick}
		>
			{ props.text || props.children }
		</div>
	);
}

export function NewLabel(props) {
	return (
		<TagLabel className={"squared new-tag" + (props.className ? " " + props.className : "")} text={t("Commons.NEW")}/>
	);
}

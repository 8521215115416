import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Button.css";

export default function Button(props) {

	return (
		<button
			{...props}
			className={
				"Button btn" +
				(props.unstyled ? " unstyled" : "") +
				(props.primary === true ? " btn-primary" : "") +
				(props.className ? ` ${props.className}` : "")
			}
		>
			{props.children || props.text}
		</button>
	);
}

export function ButtonPrev(props) {

	return (
		<Button {...props}>
			<FontAwesomeIcon className="me-auto" icon={faChevronLeft}/>
			<div className="mx-3">
				{props.children || "Précédent"}</div>
			<FontAwesomeIcon className="ms-auto" icon={faChevronRight}  color="transparent"/>
		</Button>
	)
}

export function ButtonNext(props) {

	return (
		<Button {...props}>
			<FontAwesomeIcon className="me-auto" icon={faChevronLeft} color="transparent"/>
			<div className="mx-3">{props.children || "Suivant"}</div>
			<FontAwesomeIcon className="ms-auto" icon={faChevronRight}/>
		</Button>
	)
}

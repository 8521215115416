import useAccount from "classes/Accounts/hooks/useAccount";
import EditDrawer from "components/EditDrawer/EditDrawer";
import { t } from "i18next";
import React from "react";
import FormAssignStep, { checkAssigns } from "../FormAssignStep/FormAssignStep";
import FormEndStep from "../FormEndStep/FormEndStep";
import FormGenStep, { checkForm } from "../FormGenStep/FormGenStep";
import FormOptionsStep from "../FormOptionsStep/FormOptionsStep";
import FormSendStep from "../FormSendStep/FormSendStep";

export default function FormEdit(props) {
	const { account } = useAccount();

	if (!props.isOpen)
		return (false);

	const FormSteps = [
		{
			component: FormGenStep,
			name: "content",
			title: t("Commons.IDENTITY"),
			onNext: checkForm,
		},
		{
			component: FormOptionsStep,
			name: "options",
			title: t("Commons.OPTIONS"),
		},
		{
			component: FormAssignStep,
			name: "assign",
			title: t("Commons.TARGETS"),
			onNext: checkAssigns,
		},
		{
			component: FormSendStep,
			name: "send",
			title: t("Commons.SEND_STEP"),
			sendStep: true,
		},
		{
			component: FormEndStep,
			name: "end",
			title: t("Commons.END_STEP"),
			props: {
				dispDrafts: props.dispDrafts
			}
		}
	];

	return (
		<div className="FormEdit">
			<EditDrawer
				isOpen={props.isOpen}
				object={props.form}
				onClose={props.onClose}
				account={account}
				title={props.title}
				steps={FormSteps}
				onDraft={(draft_props) => {
					let post = draft_props.object;
					post.isDraft(true);
					return (true);
				}}
			/>
		</div>
	);
}

import { useEffect, useState } from "react";
import Storage from "classes/Storage/Storage";
import { useAccountContext } from "providers/AccountProvider";

function usePlatform() {
	const storage = new Storage(null, null, sessionStorage);
	const [platform, setPlatform] = useState(getPlatform(storage));

	const chagePlatform = (set_platform) => {
		storage.setItem("Platform", set_platform);
		setPlatform(set_platform);
		return set_platform;
	};

	return {
		setPlatform: chagePlatform,
		getPlatform,
		platform,
	};
}

const getPlatform = (storage) => {
	try {
		let res = storage.getItem("Platform");
		return res ? res : "Desktop";
	} catch (e) {
		return false;
	}
};

export default usePlatform;

import React from "react";
import { useQuery } from "react-query";
import DocumentTile from "../../Pages/Documents/components/Tile/DocumentTile";
import genRequest from "includes/request";
import useTemplate from "hooks/useTemplate";

export default function Document(props) {
	let doc = props.datas;
	let DocumentId = props.datas.DocumentId;
	const { template } = useTemplate();
	const retrieveCategories = async () => genRequest("Categories/Document/" + DocumentId).catch(() => {});

	function handleClick()
	{
		if (props.onClick)
			props.onClick(props.datas);
	}

	const {isLoading, data} = useQuery(
		["Categories", "Document", DocumentId],
		retrieveCategories,
		{
			retry: 0
		}
	);

	if (isLoading)
		return (
			<div className={"document-tile tile loading p-2 " + props.className}>
				<div className="w-100"></div>
				<div className="w-50"></div>
				<div className="w-25"></div>
				<div className="w-35"></div>
			</div>
		);

	let cats = data;
	let filter = props.catsFilter;
	let cat_check = true;
	doc.Categories = cats;
	if (filter && filter.length)
	{
		cat_check = filter.every((filter_cat) => {
			let check = false;
			if (!cats)
				return (false);
			cats.map((a) => {
				if (filter_cat === a.CategoryId)
					check = true;
				return (false);
			});
			return (check);
		});
	}
	if (!cat_check)
		return (false);
	return (
		<DocumentTile
			doc={doc}
			handleDelete={props.handleDelete}
			color={template?.primaryColor()}
			cats={cats}
			onClick={handleClick}
			className={props.className}
		/>
	);
}

import AppTemplate from "classes/AppTemplate/AppTemplate";
import genRequest from "includes/request";

class SiteObject {
	#Adress = null;
	#AppTemplateId = null;
	#CompanyId = null;
	#Country = "";
	#LastChanged = null;
	#LastChange_AuthorId = null;
	#LanguageId = 1;
	#Localisation = "";
	#Mail = null;
	#Name = "";
	#Phone = null;
	#PostalCode = null;
	#SiteId = null;
	#Town = "";
	#Website = null;

	#Logo = null;
	#OldLogo = null;
	#AdminId = null;
	#Template = false;
	#DuplicatePosts = false;

	constructor(props) {
		this.#Adress = props.Adress;
		this.#AppTemplateId = props?.AppTemplateId;
		this.#CompanyId = props?.CompanyId;
		this.#Country = props?.Country;
		this.#LastChanged = Date.now();
		this.#LastChange_AuthorId = props?.LastChange_AuthorId;
		this.#LanguageId = props?.LanguageId || 1;
		this.#Localisation = props?.Localisation;
		this.#Mail = props?.Mail;
		this.#Name = props?.Name;
		this.#Phone = props?.Phone;
		this.#PostalCode = props?.PostalCode;
		this.#SiteId = props?.SiteId;
		this.#Town = props?.Town;
		this.#Website = props?.Website;

		if (props.Template) this.#Template = props.Template;
		else if (this.#SiteId && this.#AppTemplateId)
			this.#Template = new AppTemplate(this.#AppTemplateId);
		this.#DuplicatePosts = props.DuplicatePosts || false;
	}

	fetchTemplate = () => this.#Template.fetch();

	send() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			that.#Template.adminSiteId(this.#AdminId);
			let xhr = that.#Template
				.send()
				.then((resp) => {
					that.#AppTemplateId = resp;
					return that.sendSite();
				}, reject)
				.then((resp) => {
					resolve(resp);
				}, reject);
			return xhr;
		});
		return prom;
	}

	sendSite() {
		let that = this;

		let url = "Sites" + (this.#SiteId ? "/" + this.#SiteId : "");
		if (this.#DuplicatePosts)
			url = "Sites/PostSiteWithAllSitesPublishedNews";
		let xhr = genRequest(
			url,
			this.values(),
			this.#SiteId ? "put" : "post"
		).then((resp) => {
			if (!that.#SiteId) {
				that.#SiteId = resp.SiteId;
				if (that.#DuplicatePosts) that.#SiteId = resp.Site.SiteId;
				that.#DuplicatePosts = false;
			}
			return resp;
		});
		return xhr;
	}

	sendLogo() {
		if (!this.#Logo) return Promise.resolve();

		this.#Logo.resetLinks(this.#CompanyId, this.#SiteId);

		return this.#Logo.send();
	}

	deleteLogo(documentId) {
		let xhr = genRequest(
			"Documents/DocumentFileId=" + documentId + "AnddeleteExpenses=true",
			null,
			"delete"
		);
		return xhr;
	}

	logo(setLogo, onlyOld) {
		if (typeof setLogo !== "undefined") {
			if (!this.#OldLogo)
				this.#OldLogo = this.#Logo ? this.#Logo : setLogo;
			if (!onlyOld) this.#Logo = setLogo;
		}
		return this.#Logo;
	}

	adminId(setAdminId) {
		if (typeof setAdminId !== "undefined") this.#AdminId = setAdminId;
		return this.#AdminId;
	}
	adress(setAdress) {
		if (typeof setAdress !== "undefined") this.#Adress = setAdress;
		return this.#Adress;
	}

	appTemplateId(setAppTemplateId) {
		if (typeof setAppTemplateId !== "undefined")
			this.#AppTemplateId = setAppTemplateId;
		return this.#AppTemplateId;
	}

	companyId(setCompanyId) {
		if (typeof setCompanyId !== "undefined") this.#CompanyId = setCompanyId;
		return this.#CompanyId;
	}

	country(setCountry) {
		if (typeof setCountry !== "undefined") this.#Country = setCountry;
		return this.#Country;
	}

	lastChanged(setLastChanged) {
		if (typeof setLastChanged !== "undefined")
			this.#LastChanged = setLastChanged;
		return this.#LastChanged;
	}

	languageId(setLanguageId) {
		if (typeof setLanguageId !== "undefined")
			this.#LanguageId = setLanguageId;
		return this.#LanguageId;
	}

	localisation(setLocalisation) {
		if (typeof setLocalisation !== "undefined")
			this.#Localisation = setLocalisation;
		return this.#Localisation;
	}

	mail(setMail) {
		if (typeof setMail !== "undefined") this.#Mail = setMail;
		return this.#Mail;
	}

	name(setName) {
		if (typeof setName !== "undefined") this.#Name = setName;
		return this.#Name;
	}

	phone(setPhone) {
		if (typeof setPhone !== "undefined") this.#Phone = setPhone;
		return this.#Phone;
	}

	postalCode(setPostalCode) {
		if (typeof setPostalCode !== "undefined")
			this.#PostalCode = setPostalCode;
		return this.#PostalCode;
	}

	siteId(setSiteId) {
		if (typeof setSiteId !== "undefined") this.#SiteId = setSiteId;
		return this.#SiteId;
	}

	town(setTown) {
		if (typeof setTown !== "undefined") this.#Town = setTown;
		return this.#Town;
	}

	website(setWebsite) {
		if (typeof setWebsite !== "undefined") this.#Website = setWebsite;
		return this.#Website;
	}

	duplicate(setDuplicate) {
		if (typeof setDuplicate !== "undefined")
			this.#DuplicatePosts = setDuplicate;
		return this.#DuplicatePosts;
	}

	template(setTemplate) {
		if (setTemplate) this.#Template = setTemplate;
		return this.#Template;
	}

	values() {
		return {
			Adress: this.#Adress,
			AppTemplateId: this.#AppTemplateId,
			CompanyId: this.#CompanyId,
			Country: this.#Country,
			LanguageId: this.#LanguageId,
			Localisation: this.#Localisation,
			Mail: this.#Mail,
			Name: this.#Name,
			Phone: this.#Phone,
			PostalCode: this.#PostalCode,
			SiteId: this.#SiteId,
			Town: this.#Town,
			Website: this.#Website,
		};
	}
}

export default SiteObject;

import React from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faExpandAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle, faFilePdf, faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import "./PostPreviewBottomBar.css";
import { t } from "i18next";
import LikePostBtn from "./components/LikePostBtn/LikePostBtn";
import SavePostBtn from "./components/SavePostBtn/SavePostBtn";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import genRequest from "includes/request";
import Button from "components/Button/Button";
import useAccount from "classes/Accounts/hooks/useAccount";
import DocumentsButton from "./DocumentsButtonBottomBar";

function PostPreviewBottomBar(props) {
	const { account, isAdmin } = useAccount();
	const { post, setIsCommentsExtended, toggleDocsExtend, toggleExtend, isExtended, extend, toggleStats, documents } = props;

	const postTypeIdCommentBtnMobileToHide = [4, 5, 14]

	return (
		<>

			<div className="post-bottom-bar mt-2 mt-auto d-flex w-100 justify-start flex-column">
				{
					props.canComment && !props.isMobile ?
						(<Button className="btn btn-primary mb-2" onClick={() => setIsCommentsExtended(true)}>{props.commentBtnTxt ? props.commentBtnTxt : "Commenter"}</Button>)
						:
						false
				}
				<div className="d-flex w-100 align-items-center">
					{
						props.stats !== false &&
						<div className="d-flex align-items-center gap-3">
							{
								(account.EmployesId === post.EmployesId || isAdmin()) && !props.isMobile &&
								<Button
									className="unstyled btn-h-bg p-1"
									title={"Statistiques de la publication"}
									onClick={toggleStats}
								>
									<FontAwesomeIcon icon={faChartColumn} />
								</Button>
							}
							<LikePostBtn postId={post.PostId} likesCount={post.LikesCount} />
							{!props.isMobile && <SavePostBtn postId={post.PostId} />}
							{
								props.showComments && (
									(!props.isMobile || (props.isMobile && !postTypeIdCommentBtnMobileToHide.includes(post.PostTypeId))) && (
										<div className="post-comments-count-cont d-flex align-items-center cursor-pointer" title={t("Posts.SHOW_COMMENTS")} onClick={() => setIsCommentsExtended(true)}>
											<FontAwesomeIcon icon={faCommentAlt} />
											<div className="post-comments-count ms-1">
												<InfosFetcher
													queryKey={["Comments", "CommentOfPost", + post.PostId]}
													queryFn={() => genRequest("Comments/CommentOfPost/" + post.PostId)}
													treat={(resp) => (resp.length === 0 && props.isMobile ? <div className="no-comment">{t('Posts.WRITE_COMMENT1')}<br /> {t('Posts.WRITE_COMMENT2')} </div> : resp.length)}
													loadText={"..."}
												/>
											</div>
										</div>)
								)
							}
						</div>
					}
					{!props.isMobile && <div className="d-flex align-items-centers">
						<DocumentsButton postId={post.PostId} toggleDocsExtend={toggleDocsExtend} documents={props.documents} isMobile={false} />
					</div>}

					{
						props.isMobile && <><div className="closeBtn" onClick={props.close}><FontAwesomeIcon icon={faXmark} /></div></>
					}
					{
						extend ?
							<div className="post-extend-btn cursor-pointer ms-3" title={!isExtended ? t("Posts.EXTEND_POST") : t("Posts.REDUCE_POST")} onClick={toggleExtend}>
								<FontAwesomeIcon icon={isExtended || (post && document.body.clientWidth <= 1200) ? faTimesCircle : faExpandAlt} />
							</div>
							:
							false
					}
				</div>
			</div>
		</>
	);
}

export default PostPreviewBottomBar;

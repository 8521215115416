import React, {useState} from "react";
import { t } from "i18next";

export default function FormTextOption({type, option, onChange, answers}) {
	const [value, setValue] = useState(answers[0]?.Value || "");

	function handleChange(val) {
		onChange({
			unique: true,
			...option,
			Value: val
		});
		setValue(val);
	}

	return (
		<div className="">
			{
				type.Type === "LongText" ?
					<textarea className="w-100" value={value} onChange={(e) => handleChange(e.target.value)} placeholder={t("Forms.ENTER_ANSWER")}/>
					:
					<input className="w-100" value={value} onChange={(e) => handleChange(e.target.value)} placeholder={t("Forms.ENTER_ANSWER")} type="text"/>
			}
		</div>
	);
}

import React, { useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Droppable } from "react-beautiful-dnd";
import formatDate from "includes/formatDate";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import "./Gallery.css";
import ImagesItems from "../ImagesItems/ImagesItems";
import { t } from "i18next";
import documents_treat from "includes/documents_treat";
import promiseToast from "components/Toasts/PromiseToast";
import Resizer from "react-image-file-resizer";

export default function Gallery(props) {
	let req = props.req;
	let cont_ref = useRef();
	const [isTooBig, setIsTooBig] = useState(false);
	const QCL = useQueryClient();
	const { account, isSup } = useAccount();
	let can_manage = props.readOnly ? false : isSup("SuperAdmin") >= 0;

	if (!req) return false;
	if (req.error)
		return (
			<div className="gallery-cont">
				<div>{t("Gallery.LOAD_ERROR")}</div>
			</div>
		);

	if (req.isLoading)
		return (
			<div className="gallery-cont">
				<div>{t("Gallery.LOADING")}</div>
			</div>
		);

	function treat_gallery_files(files) {
		let x;
		let proms = [];

		for (x = 0; x < files.length; x++) {
			let file = files[x];
			Resizer.imageFileResizer(
				file,
				1500,
				1000,
				"JPEG",
				100,
				0,
				(resizedFile) => {
					let prom = new Promise((resolve) => {
						file.reader = new FileReader();
						file.reader.readAsDataURL(resizedFile);
						file.reader.onload = function () {
							file.ext = file.name.split(".");
							file.ext = file.ext[file.ext.length - 1];
							file.FileName = file.name.replace(
								RegExp("." + file.ext, "gi"),
								""
							);
						};
						file.reader.onloadend = function (e) {
							file.data = e.target.result;
							file.DocumentFile = e.target.result.split(",")[1];
							sendImage(file)
								.then((resp) => {
									return linkGalleryImage(resp);
								})
								.then(() => {
									resolve(file.data);
								});
						};
					});
					proms.push(prom);
				}
			);


			// if (file.size < 2097152) {
			// 	let prom = new Promise((resolve) => {
			// 		file.reader = new FileReader();
			// 		file.reader.readAsDataURL(file);
			// 		file.reader.onload = function () {
			// 			file.ext = file.name.split(".");
			// 			file.ext = file.ext[file.ext.length - 1];
			// 			file.FileName = file.name.replace(
			// 				RegExp("." + file.ext, "gi"),
			// 				""
			// 			);
			// 		};
			// 		file.reader.onloadend = function (e) {
			// 			file.data = e.target.result;
			// 			file.DocumentFile = e.target.result.split(",")[1];
			// 			sendImage(file)
			// 				.then((resp) => {
			// 					return linkGalleryImage(resp);
			// 				})
			// 				.then(() => {
			// 					resolve(file.data);
			// 				});
			// 		};
			// 	});
			// 	proms.push(prom);
			// } else {
			// 	setIsTooBig(true);
			// 	setTimeout(() => {
			// 		setIsTooBig(false);
			// 	}, 3000);
			// }
		}

		let ret = Promise.all(proms).then(() => {
			if (props.retrieve) props.retrieve();
			else if (req?.queryKey) QCL.resetQueries(req.queryKey);
		});
		promiseToast(ret);
		return ret;
	}

	function sendImage(image) {
		let data = {
			FileDocString: image.DocumentFile,
			DocTypeId: 13,
			CompanyId: account.CompanyId,
			Name:
				image.FileName + "_" + formatDate(Date.now()) + "." + image.ext,
			LastChanged: formatDate(),
		};

		let prom = genRequest(
			"Documents/DocumentFileFromString",
			data,
			"post"
		).then((resp) => {
			return resp;
		});
		return prom;
	}

	function linkGalleryImage(documentId) {
		let prom = genRequest(
			"LtDocumentCompanies",
			{
				DocumentId: documentId,
				CompanyId: account.CompanyId,
			},
			"post"
		).then((resp) => {
			return resp;
		});
		return prom;
	}

	function deleteImage(img) {
		if (!img?.DocumentId) return false;
		let prom = genRequest(
			"Documents/DocumentId=" + img.DocumentId + "AnddeleteExpenses=true",
			null,
			"delete"
		)
			.then(() => {
				if (props.handleDelete) props.handleDelete();
				else if (props.retrieve) props.retrieve();
				if (req?.queryKey) QCL.resetQueries(req.queryKey);
			})
			.catch(() => { });
		promiseToast(prom);
		return prom;
	}

	function handleDrop(e) {
		e.stopPropagation();
		e.preventDefault();

		cont_ref.current.classList.remove("drag-over");
		if (e.dataTransfer?.files) treat_gallery_files(e.dataTransfer.files);
		else if (e.target?.files) treat_gallery_files(e.target.files);
	}

	function handleDragOver(e, leaving) {
		e.stopPropagation();
		e.preventDefault();
		let className = can_manage ? "drag-over" : "disabled";
		if (leaving) cont_ref.current.classList.remove(className);
		else cont_ref.current.classList.add(className);
	}

	let handles = {};

	if (can_manage)
		handles = {
			onDrop: handleDrop,
			onDragOverCapture: handleDragOver,
			onDragLeaveCapture: (e) => handleDragOver(e, true),
		};

	return (
		<>
			<div className="gallery-cont" ref={cont_ref} {...handles}>
				<Droppable
					droppableId="gallery-cont"
					direction="vertical"
					isDragDisabled={props.isDragDisabled}
					isDropDisabled={true}
				>
					{(provided) => {
						return (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={
									!req.data?.length
										? "d-flex flex-column-reverse align-items-center"
										: ""
								}
							>
								{can_manage && (
									<div
										className="image-preview upload-post-image-btn cursor-pointer"
										title={t("Images.IMAGE_OVERLAY")}
									>
										<div className="add-image-input-cont">
											<input
												className="add-image-input"
												type="file"
												accept=".png, .jpeg, .jpg"
												multiple
												onChange={handleDrop}
												title={t("Images.IMAGE_OVERLAY")}
											/>
											<span>2Mb max</span>
										</div>
										<div className="d-flex align-items-center justify-content-between flex-column">
											<div className="import-msg">
												{t("Images.ADD_IMAGE")}
											</div>
											<div className="import-plus-btn">+</div>
										</div>
									</div>
								)}
								{req.data && req.data.length ? (
									<ImagesItems
										isDragDisabled={props.isDragDisabled}
										onClick={props.onItemClick}
										images={req.data}
										clone={true}
										handleDelete={
											can_manage ? deleteImage : false
										}
									/>
								) : (
									<div className="d-block align-self-center">
										{t("Gallery.NO_IMAGE")}
									</div>
								)}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</div>
			{isTooBig && <div className="error-msg">{t("Images.IMAGE_SIZE_ERROR")} {t("Images.IMAGE_SIZE_ERROR_2")} 2Mb</div>}
		</>
	);
}

export function AsyncGallery(props) {
	const { account } = useAccount();
	const QCL = useQueryClient();

	const retrieveGalleryImages = async () => {
		let prom = genRequest(
			"Documents/DocumentOfCompanyAndType/" + account.CompanyId + "/13"
		).then((resp) => {
			return documents_treat(resp);
		});
		return prom;
	};

	const queryKey = props.queryKey || [
		"Documents",
		"DocumentOfCompanyAndType",
		account.CompanyId,
		13,
	];
	const queryFn = props.queryFn || retrieveGalleryImages;

	const req = useQuery(queryKey, queryFn);

	return (
		<Gallery
			req={req}
			retrieve={() => QCL.resetQueries(queryKey)}
			{...props}
		/>
	);
}

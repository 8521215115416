import React from "react";
import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import { useQueryClient } from "react-query";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
// import UsersExporter from "../../UsersManager/components/UsersExporter/UsersExporter";
import { t } from "i18next";
// import promiseToast from "components/Toasts/PromiseToast";
import TableToolbar from "components/Table/TableToolbar/TableToolbar";
import useAccount from "classes/Accounts/hooks/useAccount";
import clsx from "clsx";
import { RssService } from "service/rss.service";
import promiseToast from "components/Toasts/PromiseToast";

export default function RssTableToolbar(props) {
	const { selectedRows, clearSelection } = props;
	const { confirmDelete } = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		// if (!selectedRows?.length) return false;
		let title = t("Rss.ASKDELETE", {
			count: selectedRows?.length,
			name: selectedRows[0].Name,
		});
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selectedRows.map((rss, x) => {
			let prom = false;
			prom = new Promise((resolve, reject) => {
				setTimeout(() => {
					RssService.deteleRssFeed(rss.Id).then((resp) => {
						resolve(resp);
					}, reject);
				}, 500 * x);
			});
			return prom;
		});

		let prom = Promise.allSettled(proms).then(() => {
			clearSelection();
			props.resetFc();
		});

		promiseToast(prom, {
			pending: t("Rss.DELETING", { count: selectedRows?.length }),
		});
		return prom;
	}

	return (
		<div className={clsx(selectedRows.length > 0 && "rssToolbar")}>
			<TableToolbar
				{...props}
				handleDelete={handleDelete}
				buttons={[
					{
						title: "delete",
						onClick: handleDelete,
					},
				]}
			/>
		</div>
	);
}

import React, { useRef } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import marker_icon from "leaflet/dist/images/marker-icon.png";
import marker_shadow from "leaflet/dist/images/marker-shadow.png";
import "./Map.css";
import SiteLogo from "../SiteLogo/SiteLogo";

function Map(props) {
	const mapRef = useRef();

	React.useEffect((old_props) => {
		if (
			props.updateOnRefocus !== false &&
			old_props?.points[0] !== props.points[0] &&
			mapRef
		)
			if (props.points?.length)
				mapRef.current?.setView(props.points[0], 13);
		if (
			old_props?.flyTo?.lat !== props.flyTo?.lat ||
			old_props?.flyTo?.lng !== props.flyTo?.lng
		)
			if (props.flyTo)
				mapRef.current?.flyTo(props.flyTo, props.flyTo.zoom);
	}, []);

	let DefaultIcon = L.icon({
		iconUrl: marker_icon,
		shadowUrl: marker_shadow,
		iconAnchor: [17, 46],
		popupAnchor: [-4, -30],
	});

	let points = props.points;
	if (!props.points?.length) {
		if (props.forcePoints) return false;
		points = [];
	}

	return (
		<div
			className={
				"map-cont" + (props.className ? " " + props.className : "")
			}
		>
			<MapContainer
				className="site-map"
				center={props.center ? props.center : props?.points[0]}
				zoom={props.zoom ? props.zoom : 13}
				flyTo={props.flyTo}
				whenCreated={(mapInstance) => {
					if (!mapRef?.current) mapRef.current = mapInstance;
					else if (mapRef.current !== mapInstance) {
						mapRef.current.remove();
						mapRef.current = mapInstance;
					}
				}}
			>
				{
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
				}
				{points?.map((a, key) => {
					return (
						<Marker key={key} position={a} icon={DefaultIcon}>
							<Popup>
								{props.popUp ? props.popUp : false}
								{props.popUpChild ? (
									<props.popUpChild {...a.popUpProps} />
								) : (
									false
								)}
							</Popup>
						</Marker>
					);
				})}
			</MapContainer>
		</div>
	);
}

export const SiteMapPopup = ({ site, img_url }) => {
	let website = site.Website;
	if (website?.search(/(https|https)/gi) === -1)
		website = "https://" + website;
	return (
		<div className="d-flex site-popup flex-column">
			<div className="d-flex align-items-center">
				<SiteLogo
					className="me-3 site-logo"
					imgAlt={[site.Name, site.Town, site.Country].join("-")}
					siteId={site.SiteId}
					companyId={site.CompanyId}
					url={img_url}
				/>
				<div className="d-flex justify-content-between flex-column">
					<h4 className="title mb-1">{site.Name}</h4>
					<div className="site-infos">
						<div>{site.Adress}</div>
						<div>
							{site.PostalCode && site.PostalCode !== "null"
								? site.PostalCode
								: ""}
							{(site.PostalCode && site.Town ? " - " : "") +
								site.Town}
						</div>
						<div>{site.Country}</div>
						{(site.Mail || site.Phone || site.Website) && (
							<>
								<hr className="mb-3" />
								{site.Mail && site.Mail !== "null" && (
									<div>
										Mail:{" "}
										<a href={"mailto:" + site.Mail}>
											{site.Mail}
										</a>
									</div>
								)}
								{site.Phone && site.Mail !== "null" && (
									<div>
										Téléphone:{" "}
										<a href={"tel:" + site.Phone}>
											{site.Phone}
										</a>
									</div>
								)}
								{site.Website && site.Mail !== "null" && (
									<div>
										Site:{" "}
										<a
											href={website}
											rel="noreferrer"
											target="_blank"
										>
											{site.Website}
										</a>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Map;

import React, {useState} from "react";
import axios from "axios";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import FormInput from "components/Inputs/FormInput";
import Map from "Pages/Sites/components/Map/Map";
import CustomAsyncSelect from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { SiteMapPopup } from "Pages/Sites/components/Map/Map";
import { t } from "i18next";

function LocationStep(props) {
	const [location, setLocation] = useState(false);
	const [error, setError] = useState(false);
	const [isInitied, setIsInitied] = useState(false);
	let site = props.object;
	let map_tm = null;

	if (!props.isActive)
		return (false);
	if (!isInitied && site.localisation())
	{
		searchAdress(true);
		setIsInitied(true);
	}

	function changePos(loc) {
		if (loc === false)
		{
			site.localisation("");
			setLocation(false);
		}
		else
		{
			setLocation({
				lat: loc.lat,
				lng: loc.lon
			});
			site.localisation(loc.lat + ", " + loc.lon);
			setError(false);
		}
	}

	function searchAdress(no_tm) {
		let site = props.object;

		clearTimeout(map_tm);
		let term = [
			site.adress(),
			site.postalCode(),
			site.town(),
			site.country()
		].join(" ");

		map_tm = setTimeout(() => {
			let prom = fetchLocation(term).then((resp) => {
				if (resp.data?.length)
					changePos(resp.data[0]);
				else {
					setError(t("Sites.ADRESS_ERROR"));
					changePos(false);
				}
			});
			return (prom);
		}, (!no_tm ? 1000 : 0));
	}

	const fetchLocation = (term) => axios.get(
		"https://nominatim.openstreetmap.org/search?format=json&q=" + encodeURIComponent(term)
	);

	const treatLangRes = (res) => {
		if (!res.Code && !res.Libelle)
			return (false);
		return ({
			label: res.Libelle,
			value: res.Id
		});
	};

	return (
		<div className="inner-step-cont">
			<div className="d-flex">
				<FormInput className="w-100" name="streen" title={t("Sites.ADDRESS")} value={site.adress()} onChange={(val) => { site.adress(val); searchAdress();}}/>
			</div>
			<div className="d-flex mt-3">
				<FormInput className="w-100 me-3" name="postalCode" title={t("Sites.POSTAL_CODE")} value={site.postalCode()} onChange={(val) => { site.postalCode(val); searchAdress();}}/>
				<FormInput className="w-100" name="town" title={t("Sites.TOWN")} value={site.town()} onChange={(val) => {site.town(val); searchAdress();}}/>
			</div>
			<div className="d-flex mt-3">
				<FormInput className="w-100 me-3" name="country" title={t("Sites.COUNTRY")} value={site.country()} onChange={(val) => { site.country(val); searchAdress();}}/>
				<CustomAsyncSelect
					url="Langues"
					treat={treatLangRes}
					title={t("Sites.LANGUAGE")}
					timeout={0}
					initialValue={site.languageId()}
					onSelect={(val) => site.languageId(val)}
					placeholder={t("Sites.LANG_MSG")}
				/>
			</div>
			<div>
				<ErrorsCont className="mt-3 warn" errors={error ? [error] : false} setErrors={setError}/>
				<Map
					className="mt-4"
					location={location}
					points={location ? [location] : false}
					forcePoints={true}
					popUp={
						<EditSiteMapPopup site={site}/>
					}
				/>
			</div>
		</div>
	);
}

export const EditSiteMapPopup = ({site}) => {

	let logo = site.logo();
	let logo_url = "";
	if (logo)
		logo_url = (logo.isNew() ? logo.documentFile() : logo.urlOrigin());
	return (
		<SiteMapPopup site={site.values()} img_url={logo_url}/>
	);
};

export function checkLocation(site) {
	let errors = [];
	if (!site.country())
		errors.push(t("Sites.COUNTRY_ERROR"));
	return (errors.length ? errors : true);
}

export default LocationStep;

import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";
import {useQuery} from "react-query";
import PostContent from "../PostContent/PostContent";
import "./PostResponse.css";
export default function PostResponse({postId, response}) {

	const {isLoading, data, error} = useQuery(
		["Posts", "ResponseOfPost", postId],
		() => genRequest("Posts/ResponseOfPost/" + postId).catch(() => {}),
		{
			enabled: (postId && !response ? true : false),
			retry: 0,
		}
	);

	if (isLoading)
		return (
			<div className="post-content loading p-4">
				<div className="w-100"/>
				<div className="w-50"/>
				<div className="w-25"/>
				<div className="w-35"/>
			</div>
		);
	let resp = response || data;
	if (!resp || !resp?.Txt?.length || error)
		return (false);
	return (<PostContent className="w-100 PostResponse" post={{...resp, Title: t("Posts.POST_RESPONSE")}}/>);
}

import React, { useState } from "react";
import { motion } from "framer-motion";
import AccountsMenu from "classes/Accounts/components/AccountsMenu";
import PagesGroup from "./components/PagesGroup/PagesGroup";
import PageLink, { CustomPageLink } from "./components/PageLink/PageLink";
import "./AccountMenu.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import SiteLogo from "Pages/Sites/components/SiteLogo/SiteLogo";
import AdminMenuHead from "components/AdminMenuHead/AdminMenuHead";
import useTheme from "hooks/useTheme";
import MenuToggler from "components/MenuToggler/MenuToggler";
import { PICTOS_URL } from "includes/paths";
import "./Menu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button/Button";

function Menu(props) {
	const menu_links_ref = React.useRef();
	const { account, isAdmin, isSup, getSettings, setSettings } = useAccount();
	const { isMenuOpen, setIsMenuOpen, platform } = props;
	const [isReduced, setIsReduced] = useState({
		state: getSettings("ReducedMenu") || false,
		hover: false,
	});
	const display = getSettings("MenuStyle") || "default";
	const callback = props.callback;
	const MenuItems = props.items;
	const template = props.appTemplate;
	const { theme, setTheme } = useTheme();
	let AppFunctions = false;

	if (template) AppFunctions = template.getAppFunctions(true);

	function handleScroll(page) {
		let cont = menu_links_ref?.current;

		if (!cont) return false;

		let selector = "." + page.name + "-page-link";
		if (page.items) selector = "." + page.name + "-pages-group";
		let elem = cont.querySelector(selector);
		if (!elem) return false;
		cont.scrollTop =
			elem.offsetParent.scrollHeight -
			elem.offsetParent.offsetTop +
			elem.offsetTop;
	}

	function handleClick(page, force) {
		handleScroll(page);
		callback(page, force);
	}

	function isMenuDisabled(menu) {
		if (menu.settingName && !getSettings(menu.settingName)) return true;
		if (menu.role && isSup(menu.role) < 0) return true;
		if (AppFunctions) {
			let check = menu.items.filter((item) => {
				if (item.appFunction) {
					let func = getAppFunction(item.appFunction);
					if (!func && platform !== "admin") return false;
				}
				return true;
			});
			return check.length ? false : true;
		}

		if (platform !== "admin") return true;
		return false;
	}

	function isItemDisabled(item) {
		if (item.settingName && !getSettings(item.settingName)) return true;
		if (item.appFunction) {
			let func = getAppFunction(item.appFunction);
			if (!func && platform !== "admin") return true;
		}
		if (item.inMenu === false) return true;
		return false;
	}

	function switchTheme(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		setTheme(ev.target?.checked === true ? "dark" : "light");
	}

	function getAppFunction(name) {
		if (AppFunctions) return AppFunctions[name];
		return true;
	}

	function isItemActive(item) {
		let isActive = props.cur === item.url ? true : false;
		if (item.paths && !isActive) {
			let check = item.paths.indexOf(props.cur);
			if (check !== -1) isActive = true;
		}
		return isActive;
	}

	function handleReduce(state, forced) {
		setIsReduced({
			...isReduced,
			...state,
		});
		if (!forced) setSettings("ReducedMenu", state.state);
	}

	const itemAnim = {
		hidden: (i) => ({
			y: -50 * i,
			opacity: 0,
			zIndex: 3,
		}),
		show: {
			y: 0,
			opacity: 1,
			zIndex: 3,
			transition: {
				duration: 0.5,
				ease: "easeInOut",
			},
		},
	};

	return (
		<div
			id={props.id}
			className={
				"Menu" +
				(isMenuOpen ? " open" : "") +
				" default-display" +
				(isReduced.state && !isReduced.hover ? " reduced-menu" : "")
			}
			onMouseLeave={() => {
				setIsReduced({
					state: isReduced.state,
					hover: false,
				});
			}}
		>
			<nav className="menu-nav p-1 pt-2">
				<Button
					className="unstyled w-auto reduce-menu-btn"
					onClick={() =>
						handleReduce({
							...isReduced,
							state: !isReduced.state,
						})
					}
				>
					<FontAwesomeIcon icon={faChevronRight} />
				</Button>
				<div className="d-flex flex-column align-items-center">
					<MenuToggler
						className="me-3"
						isMenuOpen={isMenuOpen}
						setIsMenuOpen={setIsMenuOpen}
					/>
					<CustomPageLink
						activeOnlyWhenExact={true}
						to="/"
						className="d-block"
						onClick={() => handleClick("/", true)}
					>
						<SiteLogo
							className="comp-logo"
							siteId={account.SiteId}
							theme={theme}
							companyId={account.CompanyId}
						/>
					</CustomPageLink>
					{isAdmin() && (
						<AdminMenuHead
							className={display === "circles" ? "mb-2" : ""}
							platform={platform}
							setPlatform={props.setPlatform}
							isReduced={isReduced.state && !isReduced.hover}
						/>
					)}
				</div>
				<div className="menu-links p-1" ref={menu_links_ref}>
					{MenuItems.map((item, index) => {
						if (item.platform && item.platform !== platform)
							return false;
						if (item.role && isSup(item.role) < 0) return false;
						if (item.appMenu && template) {
							let menu = template.getMenus(item.appMenu);
							if (menu) {
								if (menu.Title?.length) item.title = menu.Title;
								if (menu.IconName?.length)
									item.icon =
										PICTOS_URL +
										encodeURIComponent(menu.IconName);
							}
						}
						if (item.items) {
							if (isMenuDisabled(item)) return false;
							let check_active_child = item.items
								.map((child_page) => {
									return isItemActive(child_page);
								})
								.filter((a) => a);
							let isActive = check_active_child.length
								? true
								: false;
							return (
								<motion.div
									key={item.name + "-" + item.title}
									custom={index}
									variants={itemAnim}
									initial="hidden"
									animate="show"
								>
									<PagesGroup
										display={display}
										item={item}
										getAppFunction={getAppFunction}
										isActive={isActive}
										cur={props.cur}
										callback={handleClick}
										platform={props.platform}
										template={template}
									/>
								</motion.div>
							);
						} else {
							if (isItemDisabled(item)) return false;
							let isActive = isItemActive(item);

							return (
								<motion.div
									key={item.name + "-" + item.title}
									custom={index}
									variants={itemAnim}
									initial="hidden"
									animate="show"
								>
									<PageLink
										display={display}
										platform={props.platform}
										item={item}
										isActive={isActive}
										onClick={handleClick}
									/>
								</motion.div>
							);
						}
					})}
				</div>
				<div className="menu-footer w-100">
					<AccountsMenu
						handlePageLinks={handleClick}
						theme={{ theme, switchTheme }}
						setIsReduced={handleReduce}
						isReduced={isReduced}
					/>
				</div>
			</nav>
		</div>
	);
}

export default Menu;

import { t } from "i18next";

export const getRightsList = () => [
	//Appearance
	{
		type: "appearance",
		label: t("Pages.APPEARANCE"),
		rights: {
			admin: { Id: 25, Name: "AdminAppearance", state: false},
		}
	},
	//AppFunctions
	{
		label: t("Appearance.APP_FUNCTIONS"),
		type: "appFunctions",
		rights: {
			admin: { Id: 26, Name: "AdminAppFunctions", state: false},
		}
	},
	//WorkFamillies
	{
		label: t("WorkFamillies.WORK_FAMILLIES"),
		type: "workFamillies",
		rights: {
			admin: { Id: 27, Name: "AdminWFY", state: false},
		}
	},
	//WorkFunctions
	{
		label: t("WorkFunctions.WORK_FUNCTIONS"),
		type: "workFunctions",
		rights: {
			admin: { Id: 28, Name: "AdminWFU", state: false},
		}
	},
	//Sites
	{
		label: t("Pages.SITES"),
		type: "sites",
		rights: {
			admin: { Id: 29, Name: "AdminSites", state: false},
		}
	},
	//Groups
	{
		label: t("Pages.GROUPS"),
		type: "groups",
		rights: {
			admin: { Id: 38, Name: "AdminGroups", state: false},
		}
	},
	//Users
	{
		label: t("Pages.CONTACTS"),
		type: "users",
		rights: {
			read: { Id: 42, Name: "ReadEmploye", state: false},
			write: { Id: 43, Name: "WriteEmploye", state: false},
			modify: { Id: 44, Name: "ModifyEmploye", state: false},
			admin: { Id: 45, Name: "AdminEmploye", state: false},
		}
	},
	//Alerts
	{
		label: t("Pages.ALERTS"),
		type: "alerts",
		rights: {
			// read: { Id: 1, Name: "ReadAlert", state: false},
			// write: { Id: 1, Name: "WriteAlert", state: false},
			// modify: { Id: 1, Name: "ModifyAlert", state: false},
			admin: { Id: 40, Name: "AdminAlert", state: false},
		}
	},
	//News
	{
		label: t("Pages.NEWS"),
		type: "news",
		rights: {
			read: { Id: 1, Name: "ReadNews", state: false},
			write: { Id: 2, Name: "WriteNews", state: false},
			modify: { Id: 3, Name: "ModifyNews", state: false},
			admin: { Id: 30, Name: "AdminNews", state: false},
		}
	},
	//Ads
	{
		label: t("Pages.OFFERS"),
		type: "ads",
		rights: {
			read: { Id: 4, Name: "ReadAds", state: false},
			write: { Id: 5, Name: "WriteAds", state: false},
			modify: { Id: 6, Name: "ModifyAds", state: false},
			admin: { Id: 31, Name: "AdminAds", state: false},
		}
	},
	//ProAds
	{
		label: t("Pages.PRO_OFFERS"),
		type: "proAds",
		rights: {
			read: { Id: 7, Name: "ReadProAds", state: false},
			write: { Id: 8, Name: "WriteProAds", state: false},
			modify: { Id: 9, Name: "ModifyProAds", state: false},
			admin: { Id: 32, Name: "AdminProAds", state: false},
		}
	},
	//Career
	{
		label: t("Pages.CAREERS"),
		type: "career",
		rights: {
			read: { Id: 10, Name: "ReadCareer", state: false},
			write: { Id: 11, Name: "WriteCareer", state: false},
			modify: { Id: 12, Name: "ModifyCareer", state: false},
			admin: { Id: 33, Name: "AdminCareer", state: false},
		}
	},
	//Articles
	{
		label: t("Pages.ARTICLES"),
		type: "articles",
		rights: {
			read: { Id: 13, Name: "ReadArticle", state: false},
			write: { Id: 14, Name: "WriteArticle", state: false},
			modify: { Id: 15, Name: "ModifyArticle", state: false},
			admin: { Id: 34, Name: "AdminArticle", state: false},
		}
	},
	//Charts
	{
		label: t("Pages.CHARTS"),
		type: "charts",
		rights: {
			read: { Id: 16, Name: "ReadChart", state: false},
			write: { Id: 17, Name: "WriteChart", state: false},
			modify: { Id: 18, Name: "ModifyChart", state: false},
			admin: { Id: 35, Name: "AdminChart", state: false},
		}
	},
	//Documents
	{
		label: t("Pages.DOCUMENTS"),
		type: "documents",
		rights: {
			read: { Id: 19, Name: "ReadDocument", state: false},
			write: { Id: 20, Name: "WriteDocument", state: false},
			modify: { Id: 21, Name: "ModifyDocument", state: false},
			admin: { Id: 36, Name: "AdminDocument", state: false},
		}
	},
	//Animations
	{
		label: t("Pages.AGENDA"),
		type: "animations",
		rights: {
			read: { Id: 22, Name: "ReadEvent", state: false},
			write: { Id: 23, Name: "WriteEvent", state: false},
			modify: { Id: 24, Name: "ModifyEvent", state: false},
			admin: { Id: 37, Name: "AdminEvent", state: false},
		}
	},
	// Forms
	{
		label: t("Pages.FORMS"),
		type: "forms",
		rights: {
			read: { Id: 61, Name: "ReadForm", state: false},
			write: { Id: 60, Name: "WriteForm", state: false},
			modify: { Id: 59, Name: "ModifyForm", state: false},
			admin: { Id: 58, Name: "AdminForms", state: false},
		}
	}
];

export function getRightsFromPerms(permsObj) {
	if (!permsObj)
		return ({});

	let RightsList = getRightsList();
	let retRights = RightsList.map((perms) => {
		let ret = JSON.parse(JSON.stringify(perms));
		for (let x in perms.rights)
		{
			ret.rights[x] = {...perms.rights[x]};
			ret.rights[x].state = permsObj[perms.rights[x].Name] || false;
		}
		return (ret);
	});
	return (retRights);
}

export function getPermsOfRights(rights) {
	if (!rights)
		return ([]);

	let retPerms = [];
	rights.forEach((perm) => {
		for (let x in perm.rights)
			if (perm.rights[x].state)
				retPerms.push(perm.rights[x]);
	});
	return (retPerms);
}

export const BasicRights = {
	ReadEmploye: true,
	ReadNews: true,
	ReadProAds: true,
	ReadAds: true,
	ReadArticle: true,
	ReadCareer: true,
	ReadChart: true,
	ReadDocument: true,
	ReadEvent: true,
	ReadForm: true,
};

export const UserRights = {
	...BasicRights,
	WriteAds: true,
	WriteProAds: true,
	WriteNews: true
};

export const AdminRights = {
	...UserRights,
	AdminAds: true,
	ModifyAds: true,
	AdminAlert: true,
	WriteCareer: true,
	ModifyCareer: true,
	AdminCareer: true,
	WriteDocument: true,
	ModifyDocument: true,
	AdminDocument: true,
	WriteEmploye: true,
	ModifyEmploye: true,
	AdminEmploye: true,
	WriteEvent: true,
	ModifyEvent: true,
	AdminEvent: true,
	AdminGroups: true,
	ModifyNews: true,
	AdminNews: true,
	WriteProAds: true,
	ModifyProAds: true,
	AdminProAds: true,
	WriteForm: true,
	ModifyForm: true,
	AdminForms: true,
};

export const SuperAdminRights = {
	...AdminRights,
	AdminAppearance: true,
	AdminAppFunctions: true,
	WriteArticle: true,
	ModifyArticle: true,
	AdminArticle: true,
	WriteChart: true,
	ModifyChart: true,
	AdminChart: true,
	AdminSites: true,
	AdminWFU: true,
	AdminWFY: true,
};

export const EloAdminRights = {
	...SuperAdminRights
};

import { useState } from "react";
import Storage from "classes/Storage/Storage";
import AppTemplate from "classes/AppTemplate/AppTemplate";
import useAccount, { getCurAccount } from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import { getMedianColor, hexToRgb } from "includes/Colors";
import useTheme from "./useTheme";

function useTemplate() {
	const { getTheme } = useTheme();
	const { getSettings } = useAccount();
	const storage = new Storage(null, null, sessionStorage);
	const [template, setTemplate] = useState(getTemplate(storage));

	const applyTemplate = (force_template) => {
		let cur_template = force_template || template;
		const color =
			getSettings("PrimaryColor") || cur_template?.primaryColor();
		const median_color = getMedianColor(color);
		const trigger_value = 128;

		const root = document.documentElement;
		root.style.setProperty("--color-primary", color);
		root.style.setProperty("--color-primary-rgb", hexToRgb(color));
		root.style.setProperty(
			"--color-primary-font-color",
			median_color < trigger_value ? "#FFFFFF" : "#000000"
		);
		root.style.setProperty(
			"--color-primary-invert-icons",
			median_color < trigger_value ? 1 : 0
		);
		root.style.setProperty(
			"--color-primary-brightness",
			median_color < 80 && getTheme() === "dark" ? 3 : 1
		);
		root.style.setProperty(
			"--main-widget-weather-brightness",
			median_color < trigger_value ? 3 : 0
		);
		root.style.setProperty("--toastify-color-progress-light", color);

		// create meta theme-color
		const meta = document.createElement("meta");
		meta.name = "theme-color";
		meta.content = color;
		document.getElementsByTagName("head")[0].appendChild(meta);
	};

	const changeTemplate = (set_template) => {
		storage.setItem("Template", set_template);
		let temp = new AppTemplate(set_template);
		applyTemplate(temp);
		setTemplate(temp);
		return temp;
	};

	const getAppFunction = (app_function_id) => {
		if (!template) return false;
		return template.getAppFunction(app_function_id);
	};

	const fetchTemplate = async () => {
		return genRequest(
			"Apptemplates/TemplateOfSite/" + getCurAccount().SiteId
		).then((resp) => {
			return changeTemplate(resp);
		});
	};

	return {
		setTemplate: changeTemplate,
		getAppFunction,
		applyTemplate,
		fetchTemplate,
		template,
	};
}

const getTemplate = (storage) => {
	try {
		let res = storage.getItem("Template");
		if (res) return new AppTemplate(res);
	} catch (e) {
		return false;
	}
	return false;
};

export default useTemplate;

import React, {useState} from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import "./CopyCatsPopUp.css";
import CategoryObject from "classes/Categories/CategoryObject";
import genRequest from "includes/request";
import Button from "components/Button/Button";
import TagsList from "components/Tags/TagsList/TagsList";
import CategoriesTypesDropDown from "../CategoriesTypesDropDown/CategoriesTypesDropDown";
import promiseToast from "components/Toasts/PromiseToast";
import { t } from "i18next";

export default function CopyCatsPopUp(props) {
	const {account} = useAccount();
	const [types, setCurTypes] = useState([]);

	const fetchCategoriesOfType = (type) => {
		let prom = genRequest("Categories/CompanyTyped/" + account.CompanyId + "/" + type.value)
			.then((resp) => {
				return ({
					...type,
					items: (resp?.length ? resp : [])
				});
			});
		return (prom);
	};

	function setIsLoading(state) {
		if (props.setIsLoading)
			props.setIsLoading(state)
	}

	function treatCopy() {
		setIsLoading(t("Categories.DUPLICATING"));
		let prom = new Promise((resolve, reject) => {
			filteredCats().then((filtered) => {
				let proms = filtered.map((a) => sendCats(a));
				return (Promise.all(proms));
			}).then(resolve, reject)
		}).then(() => {
			if (props.handleClose)
				props.handleClose(true);
		}, () => {
			setIsLoading(false);
		});
		promiseToast(prom);
		return (prom);
	}

	function filteredCats() {
		const proms = types.map((a) => fetchCategoriesOfType(a));

		let prom = Promise.all(proms).then((resp) => {
			return (filterCats(resp));
		});
		return (prom);
	}

	function filterCats(fetched) {
		if (!fetched?.length)
			return ([]);

		let filtered = fetched.map((type) => { // pour chaque type récupéré
			let dup_cats = [...props.selected];
			let ret = dup_cats.map((a) => {
				if (!type.items.find((cat) => (cat.Name === a.Name)))
					return ({...a, CategoryType: type.value});
				return (false);
			}).filter(a => a);
			return (ret);
		});
		return (filtered);
	}

	function sendCats(cats) {
		let proms = cats.map((a) => {
			if (a.CompanyId && a.CategoryType && a.Name)
			{
				let dup = new CategoryObject(a);
				dup.categoryId(false);
				return (dup.send());
			}
			return (false);
		});
		return (Promise.all(proms));
	}

	return (
		<div className="CopyCatsPopUp">
			<div className="popup p-4">
				<div>{t("Commons.SELECTED")}:</div>
				<TagsList items={props.selected.map((a) => ({id: a.CategoryId, text: a.Name, color: a.DefaultHexaColor}))}/>
				<div className="mt-3">
					{ t("Categories.DUP_FOR") }
				</div>
				<CategoriesTypesDropDown
					className={"light-bg copy-dropdown"}
					isMulti={true}
					setCurType={setCurTypes}
					curType={types}
					closeMenuOnSelect={false}
				/>
				<div className="d-flex w-100 justify-content-center mt-3 gap-2">
					<div>
						<Button className="btn-red" onClick={props.handleClose}>{ t("Commons.CANCEL") }</Button>
					</div>
					<div>
						<Button className="btn-green" onClick={treatCopy}>{ t("Commons.CONFIRM") }</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

import ProfilePage from "Pages/Profile/ProfilePage";
import React from "react";
import useTemplate from "hooks/useTemplate";
import MyPosts from "./Pages/MyPosts/MyPosts";
import { t } from "i18next";
import MyDocuments from "./Pages/MyDocuments/MyDocuments";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import "./MyOnlyall.css";
import NestedPages from "components/NestedPages/NestedsPages";
// import MyCareers from "./Pages/MyPosts/customs/MyCareers";

export default function MyOnlyAll(props) {

	const SubPages = [
		{
			url: "/profile",
			paths: [
				"",
				"/profile",
			],
			title: t("MyOnlyAll.PROFILE_BTN"),
			component: ProfilePage,
			appFunction: ""
		},
		{
			url: "/mynews",
			params_url: "/mynews/:id",
			paths: [
				"/mynews",
				"/mynews/:id",
			],
			title: t("MyOnlyAll.NEWS_BTN"),
			component: MyPosts,
			childprops: {
				typeId: 1,
				showComments: false,
				canComment: false
			},
			appFunction: "ProfilePage.PersonalDocumentPage.MyNews"
		},
		{
			url: "/myoffers",
			params_url: "/myoffers/:id",
			paths: [
				"/myoffers",
				"/myoffers/:id",
			],
			title: t("MyOnlyAll.OFFERS_BTN"),
			component: MyPosts,
			childprops: {
				typeId: 4,
				showComments: true,
				canComment: false
			},
			appFunction: "ProfilePage.PersonalDocumentPage.MyAd"
		},
		{
			url: "/mypro_offers",
			params_url: "/mypro_offers/:id",
			paths: [
				"/mypro_offers",
				"/mypro_offers/:id",
			],
			title: t("MyOnlyAll.PRO_OFFERS_BTN"),
			component: MyPosts,
			childprops: {
				typeId: 14,
				showComments: true,
				canComment: false
			},
			appFunction: "ProfilePage.PersonalDocumentPage.MyAd"
		},
		{
			url: "/mycareers",
			params_url: "/mycareers/:id",
			paths: [
				"/mycareers",
				"/mycareers/:id",
			],
			title: t("MyOnlyAll.CAREERS_BTN"),
			component: MyPosts,
			childprops: {
				typeId: 5,
				showComments: true,
				canComment: false
			},
			appFunction: "ProfilePage.PersonalDocumentPage.MyAd"
		},
		{
			url: "/mydocuments",
			title: t("MyOnlyAll.DOCS_BTN"),
			component: MyDocuments,
			appFunction: "ProfilePage.PersonalDocumentPage.PaySlip"
		}
	];

	
	// The `path` lets us build <Route> paths that are
	// relative to the parent route, while the `url` lets
	// us build relative links.
	const { template } = useTemplate();
	let appFunctions = template.getAppFunctions(true);
	const baseUrl = "/myonlyall";
	let pages = SubPages;

	if (appFunctions)
	{
		pages = pages.filter((page) => {
			if (page.appFunction)
			{
				let func = appFunctions[page.appFunction];
				if (!func)
					return (false);
			}
			return (page);
		});
	}

	return (
		<div className="MyOnlyall d-flex overflow-hidden flex-column">
			<PageHeadBar
				title={props.title}
				siteSelect={false}
				search={false}
				isMenuOpen={props.isMenuOpen}
				setIsMenuOpen={props.setIsMenuOpen}
			/>
			<NestedPages pages={pages} baseUrl={baseUrl} home={"/profile"}/>
		</div>
	);
}

import useAccount from "classes/Accounts/hooks/useAccount";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import genRequest from "includes/request";
import SiteLogo from "Pages/Sites/components/SiteLogo/SiteLogo";
import React from "react";
import CompanyBanner from "./components/CompanyBanner/CompanyBanner";
import {useParams} from "react-router-dom";
import "./CompanyPage.css";
import CompanyPageBody from "./CompanyPageBody";

export default function CompanyPage(props) {
	const {account} = useAccount();

	let {id} = useParams();

	const getId = () => {
		if (props.companyId && !isNaN(props.companyId))
			return (props.companyId);
		return (id && !isNaN(parseInt(id)) ? id : account.CompanyId);
	};

	const companyId = getId();

	return (
		<div className="CompanyPage">
			<CompanyBanner className={"banner"} companyId={companyId}/>
			<div className="infos-cont">
				<div className="infos-cont-bg">
					<SiteLogo companyId={companyId}/>
					<InfosFetcher
						queryKey={["Companies", companyId]}
						queryFn={() => genRequest("Companies/" + companyId)}
						treat={(a) => a.Name}
						className={"company-name"}
					/>
				</div>
			</div>
			<CompanyPageBody baseUrl={props.baseUrl} noBaseId={isNaN(parseInt(id))} companyId={companyId}/>
		</div>
	);
}

import React from "react";
import getMenuItems from "components/Menu/MenuItems";
import Page from "Pages/Page/Page";
import { Switch, Route } from "react-router-dom";
import useAccount from "classes/Accounts/hooks/useAccount";
import ServError from "components/ServError/ServError";
import useTemplate from "hooks/useTemplate";
import { PICTOS_URL } from "includes/paths";

export default function RoutesWrapper(props) {
	const { isAdmin, getSettings, isSup } = useAccount();
	const { template } = useTemplate();
	const { platform, curPage } = props;
	const { isMenuOpen, setIsMenuOpen, setLangCode } = props;

	function isMenuDisabled(menu) {
		if (menu.settingName && !getSettings(menu.settingName)) return true;
		if (menu.role && isSup(menu.role) < 0) return true;
		if (menu.platform === "admin" && !isAdmin()) return true;
		if (menu.appFunction && template.getAppFunctions()) {
			let func = template.getAppFunction(menu.appFunction);
			if (!func && platform !== "admin") return true;
		}
		return false;
	}

	function isItemDisabled(item) {
		if (item.settingName && !getSettings(item.settingName)) return true;
		if (item.role && isSup(item.role) < 0) return true;
		if (item.platform === "admin" && !isAdmin()) return true;
		if (item.appFunction) {
			let func = template.getAppFunction(item.appFunction);
			if (!func && platform !== "admin") return true;
		}
		return false;
	}

	function getCustomName(item) {
		let ret = { ...item };
		if (item.appMenu && template) {
			let menu = template.getMenus(item.appMenu);
			if (menu) {
				if (menu.Title?.length) ret.title = menu.Title;
				if (menu.IconName?.length)
					ret.icon = PICTOS_URL + encodeURIComponent(menu.IconName);
			}
		}
		return ret;
	}

	const genGroupRoutes = (group) => {
		let ret = [];

		if (isMenuDisabled(group)) return false;

		let custom_group = getCustomName(group);

		ret.push(
			<Route
				exact
				path={group.paths ? group.paths : group.url}
				key={group.url}
			>
				<Page
					{...custom_group}
					setLangCode={setLangCode}
					items={group.items}
					curPage={curPage}
					isAdmin={isAdmin}
					curPlatform={platform}
					isMenuOpen={isMenuOpen}
					setIsMenuOpen={setIsMenuOpen}
				/>
			</Route>
		);

		let inner_routes = genRoutes(group.items);
		if (inner_routes) ret.push(...inner_routes);
		return ret;
	};

	const genPageRoutes = (page) => {
		if (isItemDisabled(page)) return false;

		let custom_page = getCustomName(page);

		return (
			<Route
				exact={page.exact !== false ? true : false}
				path={page.paths ? page.paths : page.url}
				key={page.url}
			>
				<Page
					{...custom_page}
					setLangCode={setLangCode}
					isAdmin={isAdmin}
					curPlatform={platform}
					isMenuOpen={isMenuOpen}
					setIsMenuOpen={setIsMenuOpen}
				/>
			</Route>
		);
	};

	const genRoutes = (force_items) => {
		let ret = [];

		let items = getMenuItems();
		if (force_items) items = force_items;
		items.forEach((item) => {
			if (item.items) {
				let inner_routes = genGroupRoutes(item);
				if (inner_routes) ret.push(...inner_routes);
			} else ret.push(genPageRoutes(item));
		});
		return ret.filter((a) => a);
	};

	const routes = genRoutes();

	return (
		<div
			id="main-cont"
			className={
				"w-100 h-100 content" +
				(["", "/", "/home"].indexOf(curPage) === -1 ? "" : " p-0")
			}
		>
			<Switch>
				{routes}
				<Route path="*">
					<ServError />
				</Route>
			</Switch>
		</div>
	);
}

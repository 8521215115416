import formatDate from "includes/formatDate";

import React, { Component } from "react";
import DocsTable from "../DocsTable/DocsTable";
import DocsTiles from "../DocsTiles/DocsTiles";
// import "./DocsList.css";

export default class DocsList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Docs: props.docs ? props.docs : [],
			Displayed: [],
			DispIndex: 0,
			DispStep: 30,
			HasMore: true,
			DispFavs: false,
			DispCats: [],
			CurDoc: false,
		};

		this.getNextDocs = this.getNextDocs.bind(this);
	}

	componentDidMount() {
		this.sortDocs();
		this.getNextDocs();
	}

	componentDidUpdate(old_props) {
		let old_docs = old_props.docs;
		let new_docs = this.props.docs;
		let addeds = new_docs
			? new_docs?.filter(
				(o1) =>
					!old_docs.some((o2) => o1.DocumentId === o2.DocumentId)
			)
			: [];
		let removed = old_docs
			? old_docs?.filter(
				(o1) =>
					!new_docs.some((o2) => o1.DocumentId === o2.DocumentId)
			)
			: [];

		if (
			old_props.state !== this.props.state ||
			addeds.length ||
			removed.length ||
			old_docs?.length !== new_docs?.length
		) {
			this.setState(
				{
					Docs: this.props.docs,
					Displayed: [],
					DispIndex: 0,
					DispStep: 30,
					HasMore: true,
					CurDoc: false,
				},
				() => {
					this.sortDocs();
					this.getNextDocs();
				}
			);
		}
	}

	sortDocs(sort_by, asc_order) {
		if (!sort_by) sort_by = "PublishedDate";
		let docs = this.state.Docs.sort((a, b) => {
			if (a[sort_by] < b[sort_by]) return asc_order ? -1 : 1;
			return asc_order ? 1 : -1;
		});
		this.setState({
			Docs: docs,
		});
	}

	checkState() {
		// let curDispState = this.props.state;

		// if ((!this.props?.typeId || (post.PostTypeId === this.props.typeId))
		// 	&& ((curDispState === "waiting" && post.ValidationModeration === false && post.Draft === false && !post.ValidationModeration)
		// 	|| (curDispState === "moderated" && post.ModerationComment && post.ValidationModeration === false && post.Draft === true)
		// 	|| (curDispState === "published" && post.ValidationModeration === true && post.Draft === false)
		// 	|| (curDispState === "drafts" && post.Draft === true && !post.ModerationComment && !post.ValidationModeration))
		// )
		// 	return (true);
		// return (false);
		return true;
	}

	checkDate(doc) {
		let date = formatDate();
		return (
			date >= doc.PublishedDate ||
			this.props.state !== "published" ||
			this.props.isAdmin ||
			doc.EmployesId === this.props.account?.EmployesId
		);
	}

	checkSite(doc) {
		let siteId = this.state.CurSite?.value;
		if (siteId) if (doc.SiteId !== siteId) return false;
		return true;
	}

	getNextDocs(max_limit) {
		let disp_count = 0;
		let total_count = 0;
		let items = [];
		let docs = this.state.Docs;

		if (this.props.search) docs = this.props.searchRes;

		for (
			let x = this.state.DispIndex;
			disp_count < this.state.DispStep &&
			x < docs?.length &&
			(!max_limit || (max_limit && disp_count < max_limit));
			x++
		) {
			let doc = docs[x];
			total_count++;
			if (
				this.checkDate(doc) &&
				this.checkState(doc) &&
				this.checkSite(doc)
			) {
				disp_count++;
				items.push(doc);
			}
		}

		this.setState({
			Displayed: this.state.Displayed.concat(items),
			DispIndex: this.state.DispIndex + total_count,
			HasMore:
				(!max_limit && disp_count < this.state.DispStep) ||
					this.state.Docs?.length < this.state.DispStep
					? false
					: true,
		});
		return items;
	}

	render() {
		let childProps = {
			curDoc: this.props.curDoc,
			displayed: this.state.Displayed,
			hasMore: this.state.HasMore,
			handleDocClick: this.props.handleDocClick,
			getNextItems: this.getNextDocs,
			dispCats: this.props.dispCats,
			saveds: this.props.Saveds,
			searching: this.props.searching,
			state: this.props.state,
		};

		return (
			<div className={"DocsList d-flex overflow-hidden h-100"}>
				{this.props.display === "table" ? (
					<DocsTable
						itemsLength={this.state.Displayed?.length}
						{...childProps}
						displayed={this.props.docs}
					/>
				) : (
					<DocsTiles
						itemsLength={this.state.Displayed?.length}
						{...childProps}
					/>
				)}
			</div>
		);
	}
}

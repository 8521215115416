import React, { useState } from "react";
import { useQueryClient } from "react-query";
import Moment from "react-moment";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import genRequest from "includes/request";
import { t } from "i18next";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import ReportPreview from "./ReportPreview";
import useSelect from "hooks/useSelect";

export default function ReportingPage() {
	const [active, setActive] = useState(false);
	const {confirmDelete} = useSelect();
	const [selected, setSelected] = useState([]);
	const QCL = useQueryClient();
	const cols = [
		{
			title: "Titre",
			name: "Title",
			classes: "post-title-cell",
			component: TitleCell
		},
		{
			title: "Author",
			name: "Author",
			classes: "post-author-cell",
			component: AuthorCell,
			sort: false
		},
		{
			title: "Plateforme",
			name: "Adress"
		},
		{
			title: "Date de publication",
			name: "PublishedDate",
			classes: "post-date-cell",
			component: DateCell
		}
	];

	function handleDelete() {
		let title = selected?.length + " publications seront supprimées, voulez vous continuer ?";
		if (selected?.length < 2)
			title = "La publication \"" +selected[0].Title + "\" sera supprimée, voulez vous continuer ?";
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = [];
		for (let x in selected)
			if (selected[x]?.PostId)
				proms.push(new Promise((resolve, reject) => {
					setTimeout(() => {
						genRequest(
							"Posts/PostId" + selected[x]?.PostId+ "AndDeleteDocument=falseAndDeleteComment=trueAndDeleteAnimation=trueAndDeleteParticipation=true",
							null,
							"delete"
						).then((resp) => {
							resolve(resp);
						}, reject);
					}, 500 * x);
				}));
		return (Promise.allSettled(proms).then(() => {
			QCL.resetQueries(["Posts", "PostsOfType", 15]);
			setSelected([]);
		}));
	}

	return (
		<div className="ReportPage d-flex flex-column overflow-hidden">
			<PageHeadBar
				title="Reporting"
				buttons={[
					(selected?.length ?
						{
							forceTitle: true,
							title: t("Commons.DELETE") + " " + t("Commons.ITEMS", {count: selected.length}),
							classes: "btn-red btn",
							onClick: () => handleDelete()
						}
						: false
					)
				]}
				search={false}
			/>
			<AsyncScrollTable
				queryFn={() => genRequest("Posts/PostsOfType/" + 15)}
				queryKey={["Posts", "PostsOfType", 15]}
				cols={cols}
				sortBy="PublishedDate"
				revSort={true}
				itemId="PostId"
				onItemClick={setActive}
				activeId={active?.PostId ? active?.PostId : false}
				max={30}
				setSelected={setSelected}
				checkbox={true}
			/>
			{
				active &&
				<PreviewDrawer
					report={active}
					child={ReportPreview}
					fullScreen={true}
					isOpen={active}
					childProps={{
						report: active
					}}
					onClose={() => setActive(false)}
				/>
			}
		</div>
	);
}

function TitleCell({item}) {
	return (
		<div>
			{item.Title}
		</div>
	);
}

function AuthorCell({item}) {
	let datas = JSON.parse(item.Txt);
	let userId = item.EmployesId;

	if (datas?.user?.TempValues)
		userId = datas.user.TempValues.EmployesId;
	return (
		<AuthorPreview
			employeId={userId}
			className="posts-table-author-preview mb-0"
			imgWidth={20}
			format={
				[
					"EmployeFirstName EmployeLastName"
				]
			}
			modifiers={{
				EmployeLastName: (item) => item.toUpperCase()
			}}
		/>
	);
}

function DateCell({item}) {
	return (
		<div className="d-flex align-items-center">
			<Moment format={t("Events.TILE_DATE_FORMAT")}>
				{item.PublishedDate}
			</Moment>
		</div>
	);
}

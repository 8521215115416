import useAccount from "classes/Accounts/hooks/useAccount";
import WorkFamillyObject from "classes/Professions/WorkFamillyObject";
import { ConditionalSelect } from "components/Inputs/CustomAsyncSelect/CustomCreatableSelect";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";

export default function WorkFamilySelect(props) {
	const { isAdmin } = useAccount();

	function handleAddWFY(new_item) {
		let obj = new WorkFamillyObject({
			WorkFamillyName: new_item.label,
			CompanyId: props.companyId
		});
		let req = obj.send().then((resp) => {
			return ({
				label: resp.WorkFamillyName,
				value: resp.WorkFamillyId,
				WorkFamillyId: resp.WorkFamillyId,
			});
		});
		return (req);
	}

	return (
		<ConditionalSelect
			placeholder={t("WorkFamillies.SELECT_FAM")}
			className={"bg-color w-100 rounded"}
			classNamePrefix="dropdown"
			queryKey={["WorkFamillies", "WorkFamillyOfCompany", props.companyId]}
			queryFn={() => genRequest("WorkFamillies/WorkFamillyOfCompany/" + props.companyId)}
			treat={(wfy) => ({
				value: wfy.WorkFamillyId,
				WorkFamillyId: wfy.WorkFamillyId,
				label: wfy.WorkFamillyName
			})}
			isSearchable={true}
			onCreateOption={isAdmin() ? handleAddWFY : false}
			{...props}
		/>
	);
}

import { t } from "i18next";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Table.css";
import LoadCont from "components/LoadCont/LoadCont";
import ScrollTable from "./ScrollTable";
import DraggableTableBody from "./components/TableBody/DraggableTableBody";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableTableHead from "./components/TableHead/DraggableTableHead";

class DraggableTable extends ScrollTable {

	constructor(props) {
		super(props);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(e) {
		this.props.onDragEnd(e);
	}

	onColAdd(e, before) {
		let cols = [...this.props.cols];
		let new_items = this.props.ctxMenuHandles?.genColumn();
		if (before)
		{
			if (e.colIndex > 0)
				cols.splice(e.colIndex - 1, 0, new_items.col);
			else
				cols.unshift(new_items.col);
		}
		else
			cols.splice(e.colIndex + 1, 0, new_items.col);
		this.props.setItems(new_items.rows);
		this.props.setCols(cols);
	}

	onRowAdd(e, before) {
		let rows = [...this.props.items];
		let new_row = this.props.ctxMenuHandles?.genRow();
		if (before)
		{
			if (e.rowIndex > 0)
				rows.splice(e.rowIndex - 1, 0, new_row);
			else
				rows.unshift(new_row);
		} else
			rows.splice(e.rowIndex + 1, 0, new_row);
		this.props.setItems(rows);
	}

	onColDelete(e) {
		let cols = [...this.props.cols];
		cols.splice(e.colIndex, 1);
		this.props.setCols(cols);
	}

	onRowDelete(e) {
		let rows = [...this.props.items];
		rows.splice(e.rowIndex, 1);
		this.props.setItems(rows);
	}

	onColClean(e) {
		let rows = [...this.props.items].map((a) => {
			return ({
				...a,
				["col-" + e.colId]: ""
			});
		});
		this.props.setItems(rows);
	}

	onRowClean(e) {
		let rows = [...this.props.items];
		this.props.cols.forEach((a) => {
			rows[e.rowIndex][a.name] = "";
		});
		this.props.setItems(rows);
	}

	render() {
		let cols = this.props.cols;
		let items = this.getItems();
		let selected = this.getSelected();

		let ctxMenuProps = {
			onColAdd: this.onColAdd.bind(this),
			onRowAdd: this.onRowAdd.bind(this),
			onColDelete: this.onColDelete.bind(this),
			onRowDelete: this.onRowDelete.bind(this),
			onColClean: this.onColClean.bind(this),
			onRowClean: this.onRowClean.bind(this),
		};

		return (
			<div
				className={"table-cont scroll-table draggable" + (this.props.className ? " " + this.props.className : "")}
				ref={this.table_ref}
				key={this.tableId}
			>
				<DragDropContext onDragEnd={this.onDragEnd} className="h-100">
					{
						this.props.header !== false &&
							<div className="table-header">
								<DraggableTableHead
									cellSpacing={this.props.cellSpacing}
									isHeader={true}
									sort={this.state.sort}
									setSort={(new_sort) => this.changeSort(new_sort)}
									cols={cols}
									maxCols={this.props.maxCols}
									onCBChange={this.mainCheckbox}
									allSelected={(selected?.length === items.length || this.props.onlySelected) && selected?.length > 0 && items.length > 0}
									checkbox={this.props.checkbox}
									onlySelected={this.props.onlySelected}
									onCellChange={this.props.onCellChange}
									cellsProps={this.props.cellsProps}
									isDragDisabled={!this.props.dragCols}
								/>
							</div>
					}
				</DragDropContext>
				<DragDropContext onDragEnd={this.onDragEnd} className="h-100">
					<div className="table-body" ref={this.body_ref}>
						<InfiniteScroll
							className="d-flex flex-wrap"
							scrollableTarget={this.body_ref?.current || false}
							dataLength={items?.length} //This is important field to render the next data
							next={this.props.getNextItems}
							hasMore={this.props.hasMore}
							loader={<LoadCont className="w-100" text={t("Commons.LOADING")}/>}
							height={"100%"}
							endMessage={
								this.props.endText !== false &&
									<div className="w-100 infinite-scroll-end-msg mt-4">
										<p style={{ textAlign: "center" }}>
											<b>
												{
													this.props.SearchResults?.length === 0 ?
														(this.props.noResultText ? this.props.noResultText : t("Table.NO_CONTENT"))
														:
														(this.props.endText ? this.props.endText : t("Table.END_OF_TABLE"))
												}
											</b>
										</p>
									</div>
							}
						>
							<DraggableTableBody
								maxCols={this.props.maxCols}
								maxRows={this.props.maxRows}
								cellSpacing={this.props.cellSpacing || 8}
								activeId={this.props.activeId}
								onlySelected={this.props.onlySelected}
								isSelected={this.isSelected}
								items={items}
								onItemClick={this.handleClick}
								onCellChange={this.props.onCellChange}
								handleCheckbox={this.handleCheckbox}
								checkbox={this.props.checkbox}
								cols={this.props.cols}
								itemId={this.props.itemId}
								tableId={this.tableId}
								cellsProps={this.props.cellsProps}
								isDragDisabled={!this.props.dragRows}
								ctxMenuProps={{...ctxMenuProps, ...this.props.ctxMenuProps}}
							/>
						</InfiniteScroll>
					</div>
				</DragDropContext>
			</div>
		);
	}
}

export default DraggableTable;

import { Card } from "components-v2/Card/card.component";
import "./cards.styles.css";
import { t } from "i18next";

export const Cards = ({ items, setPreviewPost }) => (
	<div className="cards-container">
		{!items ? (
			<div className="cards-container__empty">
				{t("Rss.EMPTY_ITEMS")}
			</div>
		) : (
			<>
				{items.map((item, idx) => {
					return (
						<Card key={idx} item={item} setPreviewPost={setPreviewPost} />
					);
				})}
			</>
		)}

	</div>
);

import React from "react";
import { useQuery } from "react-query";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import genRequest from "includes/request";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import PageLoad from "components/PageLoad/PageLoad";
import "./AnalyticsPage.css";
import StatsBody from "./components/StatsBody/StatsBody";

export default function AnalyticsPage(props) {
	const { account } = useAccount();
	const { isMenuOpen, setIsMenuOpen } = props;

	const fetchCompany = () => genRequest("Companies/" + account.CompanyId);

	const { isLoading, data } = useQuery(
		["Companies", account.CompanyId],
		fetchCompany
	);

	return (
		<div className="AnalyticsPage">
			<PageHeadBar
				title={t("Pages.ANALYTICS")}
				search={false}
				siteSelect={false}
				hideFavSites={true}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
			/>
			{isLoading ? <PageLoad /> : <StatsBody company={data} />}
		</div>
	);
}

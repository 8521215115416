import clsx from "clsx";
import { t } from "i18next";
import { useState, useEffect } from "react";
export const FilterFavSites = ({
	data,
	favSitesSelect,
	favSitesSelected,
	curSite,
	setCurSite,
}) => {
	useEffect(() => {
		if (favSitesSelected) {
			setFavSitesSelected([
				...new Set([...favSiteSelected, ...favSitesSelected]),
			]);
		}
	}, [favSitesSelected]);

	// useEffect(() => {
	// 	if (curSite && curSite.value != 0) {
	// 		setFavSitesSelected([
	// 			...new Set([...favSiteSelected, curSite.value]),
	// 		]);
	// 	}
	// }, [curSite]);

	const [favSiteSelected, setFavSitesSelected] = useState([]);
	const onClickFavSites = (site) => {
		const duplArr = [...favSiteSelected];
		const index = duplArr.indexOf(site.SiteId);
		if (index !== -1) {
			duplArr.splice(index, 1);
			setFavSitesSelected([...new Set([...duplArr])]);
			favSitesSelect([...new Set([...duplArr])]);
		} else {
			const favSite = {
				value: site.SiteId,
				label: site.Name + " - " + site.Town,
			};
			// setCurSite(favSite);
			setFavSitesSelected([
				...new Set([...favSiteSelected, site.SiteId]),
			]);
			favSitesSelect([...new Set([...favSiteSelected, site.SiteId])]);
		}
	};
	return (
		<div className="TagsList d-flex flex-wrap gap-1 categories-list">
			<div className="w-100">{t("Sites.FAV_SITES")}</div>
			{data.map((a, key) => {
				return (
					<div
						key={key}
						className={clsx(
							"TagLabel cursor-pointer",
							favSiteSelected.indexOf(a.SiteId) !== -1
								? "selected"
								: "favSiteUnselected"
						)}
						onClick={() => onClickFavSites(a)}
					>
						{a.Name + " - " + a.Town}
					</div>
				);
			})}
		</div>
	);
};

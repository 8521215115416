import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";
import Welcome from "components/Welcome/Welcome";
import "./Account.css";

/**
* Account component from AccountModel
* @see AccountModel
* @component
* @prop {object:JSON} user JSON object of the user stored in localStorage
* @prop {boolean} isActive if true, "cur-account" css class is added to the component
* @prop {function} onClick callback function on click
* @prop {function} handleRemove callback function for delete account button
* @example
*
* return (
*   <Account
*     user={<object:JSON>user}
*     isActive={<boolean>}
*     onClick={<function>}
*     handleRemove={<function>}
*   />
* );
*/
function Account(props) {
	const {account} = props;
	let user_img = account.Image?.Url_Mobile;
	let comp_logo = account.CompanyLogo?.Url_Mobile;

	function handleClick(e) {
		e.preventDefault();
		if (props.onClick)
			props.onClick(e);

		window.history.pushState({}, "Bienvenue", "/" + account.EmployesId);
	}

	if (!user_img)
		user_img = "/assets/logos/logo.png";

	return (
		<div
			className={`account ${props.isActive ? "active" : ""}`}
			id={account.EmployesId}
		>
			<a href={"/" + account.EmployesId} onClick={handleClick}>
				<div className="account-image" style={{"backgroundImage": "url(" + user_img}}>
					{comp_logo && <div className="account-company-logo" style={{"backgroundImage": "url(" + comp_logo}}></div>}
				</div>
				{
					props.isActive ?
						<Welcome className="mt-2 mb-1" forceAccount={account} refreshTime={1}/>
						:
						<div className="account-infos my-2">
							{account.FirstName}<br/>
							{account.LastName}
						</div>
				}
			</a>
			<div className="delete-account" onClick={props.handleRemove} title={t("Commons.DELETE")}>
				<FontAwesomeIcon className="delete-icon" icon={faTrashAlt}/>
				<div className={"delete-msg"}>{t("Commons.DELETE")}</div>
			</div>
		</div>
	);
}

export default Account;

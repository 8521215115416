import React from "react";
import { useQuery } from "react-query";
import "assets/logos/logo.png";
import genRequest from "includes/request";
import documents_treat from "includes/documents_treat";
import useAccount from "classes/Accounts/hooks/useAccount";
import { dateAdd } from "includes/formatDate";
import { t } from "i18next";

function UserImage({
	title,
	companyId,
	employeId,
	width,
	height,
	className,
	res,
	onClick,
}) {
	const { account } = useAccount();
	const fetchImage = async () => {
		let prom = new Promise((resolve, reject) => {
			genRequest("Documents/DocumentOfEmployeAndType/" + employeId + "/2")
				.then((images) => {
					if (images.length) {
						let encodeds = documents_treat(
							images,
							"LastChanged",
							true
						);
						resolve(encodeds[0]);
						return encodeds[0];
					}
					return fetchCompanyLogo();
				}, reject)
				.then((image) => {
					if (image) {
						resolve(image);
						return image;
					}
				}, reject);
		});
		return prom;
	};

	const fetchCompanyLogo = async () =>
		await genRequest(
			"Documents/DocumentOfCompanyAndType/" +
				(companyId ? companyId : account.CompanyId) +
				"/10"
		).then(
			(resp) => {
				return documents_treat(resp, "LastChanged", 1)[0];
			},
			() => false
		);

	const getImage = (image) => {
		if (!image?.Url_Origin) return "/assets/logos/logo.png";
		if (res && image[res]) return image[res];
		return image.Url_Origin;
	};

	const { isLoading, data, error } = useQuery(
		["Documents", "DocumentOfEmployeAndType", employeId, 2],
		fetchImage,
		{
			staleTime: 1000 * 60 * 10,
			enabled: employeId ? true : false,
		}
	);

	if (isLoading)
		return (
			<div
				className={
					"user-image loading " + (className ? " " + className : "")
				}
				style={{
					width,
				}}
			/>
		);

	if (error || !data) return false;

	return (
		<div
			title={title}
			className={
				"user-image" +
				(className ? " " + className : "") +
				(data?.DocType === 10 ? " user-comp-logo" : "")
			}
			onClick={onClick}
			style={{
				backgroundImage: "url(" + getImage(data) + ")",
				width,
				height: height || width,
			}}
		/>
	);
}

export function OnlineUserImage(props) {
	return (
		<div className="OnlineUserImage position-relative">
			<UserImage {...props} />
			<OnlineStatus
				lastConnexion={props.lastConnexion}
				delayInMinutes={props.delayInMinutes}
			/>
		</div>
	);
}

export function OnlineStatus({ lastConnexion, delayInMinutes }) {
	if (!lastConnexion) return false;

	let isOnline =
		new Date(lastConnexion) >
		dateAdd(new Date(), {
			minutes: delayInMinutes ? delayInMinutes * -1 : -5,
		});

	if (isOnline)
		return <div className="OnlineStatus" title={t("Contacts.ONLINE")} />;
	return false;
}

export default UserImage;

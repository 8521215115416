import React from "react";
import { t } from "i18next";
import SendStep from "components/EditDrawer/SendStep/SendStep";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function FormSendStep(props) {
	const form = props.object;
	const { account } = useAccount();
	const queries = [
		{
			queryKey: ["SendForm", "Form", form.title(), form.lastChanged()],
			queryFn: () => form.send(account),
			title: t("Forms.SENDING_FORM"),
		},
	];

	if (!props.isActive) return false;

	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

const AddEventTour = {
    "tourId": "c527452b-1887-4af8-a8fd-ebe1a0b3fffb",
    "title": "Comment ajouter un événement ?",
    "description": "",
    "steps": [
        {
            "title": "Bouton ajouter",
            "description": "Cliquez ici pour ajouter un nouvel événement",
            "target": ".PageHeadBar .headbar-add-btn",
            "disableBeacon": true,
            "page": "/agenda"
        },
        {
            "title": "Titre de l'événement",
            "description": "Entrez un titre pour l'événement",
            "target": ".edit-drawer div.inner-step-cont",
            "disableBeacon": true,
            "page": "/agenda"
        },
        {
            "title": "Dates",
            "description": "Entrez ici la date de début et de fin pour l'événement\nVous pouvez aussi définir l'événement pour la journée en actionnant le switch sur la droite",
            "target": ".edit-drawer .dates-cont",
            "disableBeacon": true,
            "page": "/agenda"
        },
        {
            "title": "Description",
            "description": "Entrez le description pour l'événement",
            "target": ".edit-drawer .description-step .inner-step-cont",
            "disableBeacon": true,
            "page": "/agenda"
        },
        {
            "title": "Image",
            "description": "Importer ou choisissez une image pour l'événement",
            "target": ".edit-drawer .step-cont.images-step",
            "disableBeacon": true,
            "page": "/agenda"
        },
        {
            "title": "Cibles",
            "description": "Sélectionnez les cibles pour l'événement",
            "target": ".edit-drawer .step-cont.sendOptions-step .tabs-cont",
            "disableBeacon": true,
            "page": "/agenda"
        }
    ]
}

export default AddEventTour;

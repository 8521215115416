export function rm_double(items, prop)
{
	let arr = [];
	let indexes = [];
	if (!items || !items.length)
		return (arr);
	for (let x = 0; x < items.length; x++)
	{
		if (indexes.indexOf(items[x][prop]) === -1) {
			arr.push(items[x]);
			indexes.push(items[x][prop]);
		}
	}
	return (arr);
}

export function rm_double_2D(items, prop)
{
	let arr = [];
	let indexes = [];
	if (!items || !items.length)
		return (arr);
	for (let x = 0; x < items.length; x++)
	{
		for (let y = 0; y < items[x]?.length; y++)
		{
			if (indexes.indexOf(items[x][y][prop]) === -1) {
				arr.push(items[x][y]);
				indexes.push(items[x][y][prop]);
			}
		}
	}
	return (arr);
}

import React, {useState} from "react";
import genRequest from "includes/request";
import {useQuery, useQueryClient} from "react-query";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import PostReactionsPopper from "../ReactionsPopper/ReactionsPopper";

export default function LikePostBtn(props) {
	const { account, getSettings } = useAccount();
	const { postId } = props;
	const [referenceElement, setReferenceElement] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const QCL = useQueryClient();
	let isUpdating = false;

	const fetchLikes = () => {
		let req = genRequest("LtEmployesLikedposts/PostId/" + postId)
			.then((resp) => {
				if (resp?.length)
				{
					let liked = resp.find((a) => (a.EmployesId === account.EmployesId));
					return ({
						likes: resp,
						isLiked: liked
					});
				}
				return ({
					likes: [],
					isLiked: false
				});
			}, () => {
				return ({
					likes: [],
					isLiked: false
				});
			});
		return (req);
	};

	function gestLikePost() {
		if (isUpdating)
			return (isUpdating);
		let req = likePost().then(() => {
			isUpdating = false;
			QCL.refetchQueries(["LtEmployesLikedposts", "PostId", postId]);
		}, () => {
			isUpdating = false;
			QCL.refetchQueries(["LtEmployesLikedposts", "PostId", postId]);
		});
		isUpdating = req;
		return (req);
	}

	const likePost = () => {
		if (!postId)
			return (false);
		if (data?.isLiked)
			return (unLikePost());
		const req = genRequest(
			"LtEmployesLikedposts",
			{
				EmployesId: account.EmployesId,
				PostId: postId
			},
		);
		return (req);
	};

	const unLikePost = () => {
		if (!postId)
			return (false);
		const req = genRequest(
			"LtEmployesLikedposts/EmployeIdAndPostId/" + account.EmployesId + "/" + postId,
			null,
			"delete"
		);
		return (req);
	};

	const {isLoading, data} = useQuery(["LtEmployesLikedposts", "PostId", postId], fetchLikes, { retry: 0, enabled: (postId ? true : false)});

	return (
		<div
			className={"post-likes-cont d-flex align-items-center cursor-pointer" + (props.className ? " " + props.className : "")}
			title={(data?.likes?.length ? data.likes.length : 0) + " " + t("Posts.LIKES", {count: data?.likes?.length})}
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
			ref={setReferenceElement}
		>
			<div className="d-flex align-items-center" onClick={gestLikePost}>
				<div>
					<FontAwesomeIcon icon={data?.isLiked ? faSolidHeart : faHeart} color={data?.isLiked ? "#ff0045" : ""}/>
				</div>
				{
					isLoading ?
						<div className="ms-1 d-flex align-items-center loading">
							<div style={{width: "10px", height: "5px"}}></div>
						</div>
						:
						<div className="post-likes-count ms-1">{data?.likes?.length ? data.likes?.length : 0}</div>
				}
			</div>
			{
				(isOpen && !props.noPopper && getSettings("Reactions")) &&
					<PostReactionsPopper
						postId={postId}
						close={() => setIsOpen(false)}
						referenceElement={referenceElement}
					/>
			}
		</div>
	);
}

import PageHeadBar from "components/HeadBar/PageHeadBar";
import React from "react";
import ReactionsTypeSelect from "components/CustomSelects/ReactionsTypeSelect";
import { t } from "i18next";
import Table from "components/Table/Table";
import { useQueryClient } from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import promiseToast from "components/Toasts/PromiseToast";
import "./ReactionsPage.css";

export default function ReactionsConfigPage(props) {
	const {account} = useAccount();
	const QCL = useQueryClient();

	const types = [
		{
			title: t("Pages.NEWS"),
			postTypeId: 1
		},
		{
			title: t("Pages.ARTICLES"),
			postTypeId: 3
		},
		{
			title: t("Pages.OFFERS"),
			postTypeId: 4
		},
		{
			title: t("Pages.PRO_OFFERS"),
			postTypeId: 14
		},
		{
			title: t("Pages.CAREERS"),
			postTypeId: 5
		}
	]

	const handleChange = (type, value) => {
		let prom = rmLt(type).then((resp) => {
			return (saveChanges(type, value))
		}).then(() => {
			QCL.resetQueries(["ReactionTypes", "Default", type, account.CompanyId])
		});
		promiseToast(prom);
	}

	const saveChanges = (postTypeId, rTypeId) => {
		let req = genRequest(
			`LT_DefaultReactionOfPostForCompany`,
			{
				ReactionTypeId: rTypeId,
				postTypeId: postTypeId,
				CompanyId: account.CompanyId
			},
			"post"
		);
		return (req);
	}

	const rmLt = (postTypeId) => {
		let req = genRequest(
			`LT_DefaultReactionOfPostForCompany/PostTypeIdAndCompanyId/${postTypeId}/${account.CompanyId}`,
			null,
			"delete"
		);
		return (req);
	}

	return (
		<div className="">
			<PageHeadBar
				{...props}
				search={false}
			/>
			<div className="mt-4 pb-5">
				<div className="bg-color-secondary p-2 radius-1 mb-4 d-flex flex-wrap gap-2 justify-content-between">
					{ t("Reactions.DEFINE_DEFAULT") }
				</div>
				<div>
					<Table
						columns={[
							{
								title: "Type",
								field: "title",
								classes: "reaction-title-cell",
								defaultSort: "asc"
							},
							{
								title: "Par défault",
								field: "selector",
								render: (data) => <DefaultReactionSelect item={data} onChange={handleChange}/>,
								sorting: false
							}
						]}
						sortBy={"title"}
						itemId={"type"}
						data={types}
						endText={false}
						onItemClick={false}
						options={{
							paging: false
						}}
					/>
				</div>				
			</div>
		</div>
	)
}

function DefaultReactionSelect(props) {
	const {item} = props;

	const handleChange = (val) => {
		props.onChange(item.postTypeId, val.value)
	}

	return (
		<div className="d-flex align-items-center gap-2 w-100 cursor-pointer">
			<ReactionsTypeSelect
				onChange={handleChange}
				type={item.postTypeId}
			/>
		</div>
	)
}

import React from "react";
import SendStep from "components/EditDrawer/SendStep/SendStep";
import { t } from "i18next";

export default function DocSendStep(props) {
	let doc = props.object;

	if (!props.isActive)
		return (false);

	const queries = [
		{
			queryKey: ["SendDoc", "Doc", doc.name(), doc.lastChanged()],
			// queryFn: (handleProgress) => doc.send(false, handleProgress),
			queryFn: () => doc.send(),
			title: t("Docs.SENDING_DOC"),
			required: true
		},
		{
			queryKey: ["SendDoc", "Notify", doc.name(), doc.lastChanged()],
			queryFn: () => doc.notify(),
			title: t("Commons.SENDING_NOTIF"),
			disabled: !doc.notification()
		}
	];

	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

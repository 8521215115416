import { getChartColors, getSpecs } from "./specs_getter";

export default function radius_charts_getter(chart, table) {
	let rows = table.querySelectorAll("tbody tr");

	if (!rows?.length)
		return (false);

	let values = {
		series: [],
		background: "transparent"
	};

	let inner_radius = chart.options("InnerThickness") || 100;
	let colors = getChartColors(table);

	let value = {
		name: "ROW",
		dataSource: [],
		xName: "x",
		yName: "y",
		width: 1,
		enableAnimation: false,
		innerRadius: (100 - Number(inner_radius)) + "%",
		legendShape: "Circle"
	};

	rows.forEach((row) => {
		let label = row.querySelector(".row-title input")?.value;
		let val = row.querySelector("td:nth-child(2) input")?.value;
		if (label && val.length)
			value.dataSource.push({
				x: label,
				y: Number(val)
			});

		value.palettes = colors;
	});

	if (value.dataSource?.length)
		values.series.push(value);

	// Object.assign(values, this.getSpecs());
	values = {...values, ...getSpecs(chart.SFChartType.type, chart)}

	return (values);
}

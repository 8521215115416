import React, {memo} from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import TableHead, { TableHeaderCell } from "./TableHead";
import "./TableHead.css";

const DraggableTableHead = memo((props) => {

	if (props.isDragDisabled)
		return (<TableHead {...props}/>);

	const handleDrop = (e) => {
		this.props.onDragEnd(e);
	};

	let handles = {
		onDrop: handleDrop,
		// onDragOverCapture: handleDragOver,
		// onDragLeaveCapture: (e) => handleDragOver(e, true),
	};

	return (
		<div
			className="TableHead"
			{...handles}
		>
			<Droppable
				droppableId="TableHead"
				direction="horizontal"
				isDragDisabled={props.isDragDisabled}
				isDropDisabled={false}
			>
				{
					(provided) => (
						<table
							style={{borderSpacing: props.cellSpacing}}
							ref={provided.innerRef}
						>
							<thead>
								<tr
									{...provided?.draggableProps}
								>
									{
										props.cols.map((col, index) => {
											if (props.maxCols && index >= props.maxCols)
												return (false);
											let col_txt = "" + (props.datas ? props.datas[col.name] : col.title) + "";
											if (!col_txt?.length)
												col_txt = col.title;
											if (col.hidden)
												return (false);
											let rowId = col.title + "-key-" + index;
											return (
												<DraggableTableHeaderCell
													key={rowId}
													rowId={rowId}
													{...col}
													item={col}
													allSelected={props.allSelected}
													checkbox={props.checkbox && index === 0}
													title={(col_txt ? col_txt : "")}
													isHeader={props.isHeader}
													setSort={props.setSort}
													sort={typeof col.sort !== "undefined" ? col.sort : props.sort }
													onCBChange={props.onCBChange}
													onCellChange={props.onCellChange}
													cellsProps={props.cellsProps}
													colIndex={index}
													rowIndex={0}
													isDragDisabled={props.isDragDisabled}
												/>
											);
										})
									}
									{provided.placeholder}
								</tr>
							</thead>
						</table>
					)
				}
			</Droppable>
		</div>
	);
});
DraggableTableHead.displayName = "DraggableTableHead";

const DraggableTableHeaderCell = memo((props) => {

	if (props.colIndex === 0 || props.isDragDisabled)
		return (<TableHeaderCell {...props}/>);

	function changeSort() {
		if (props.sort !== false)
		{
			props.setSort({
				sortBy: props.name,
				rev: !props.sort.rev
			});
		}
	}

	return (
		<Draggable
			draggableId={props.colId}
			index={props.colIndex}
			isDragDisabled={props.isDragDisabled}
		>
			{
				(provided) => {
					return (
						<th
							className={props.name.toLowerCase() + "-col" + (props.classes ? " " + props.classes : "")}
							ref={provided.innerRef}
							{...provided?.draggableProps}
						>
							<div className='d-flex align-items-center'>
								{
									props.rowIndex === 0 &&
									<div
										className={"col-handle"}
										{...provided?.dragHandleProps}
										onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
									/>
								}
								{
									props.checkbox
									&&
									<div className="table-checkbox">
										<input
											type="checkbox"
											className="checkbox"
											checked={props.allSelected || false}
											onClick={(e) => e.stopPropagation()}
											onChange={(e) => {e.stopPropagation(); props.onCBChange(e);}}
										/>
									</div>
								}
								<div className="w-100 d-flex justify-content-center flex-wrap">
									{
										props.headComponent ?
											<props.headComponent
												{...props.childProps}
												{...props.cellsProps}
												item={props.item}
												colIndex={props.colIndex}
												rowIndex={props.rowIndex}
												onCellChange={props.onCellChange}
												isHead={true}
											>
												{ (props.title !== "null" ? props.title : "") }
											</props.headComponent>
											:
											<div
												onClick={changeSort}
												className={
													"col-title " +
													(
														(props.sort !== false ?
															"sort"+
															(
																props.sort.sortBy === props.name ?
																	(props.sort.rev ? " desc" : " asc")
																	:
																	""

															)
															:
															""
														)
													)
												}
												name={props.name}
											>
												{props.title}
											</div>
									}
								</div>
								{provided.placeholder}
							</div>
						</th>
					);
				}
			}
		</Draggable>
	);
});
DraggableTableHeaderCell.displayName = "DraggableTableHeaderCell";

export default DraggableTableHead;

import React from "react";
import "../LoginPage.css";
import "../LoginForm/LoginForm.css"
import ForgetCont from "./ForgetForm";

export default function ForgetPage() {
	return (
		<div className="LoginPage LoginForm">
			<div className="login-wrapper">
				<div className="logo-cont"></div>
				<ForgetCont onCancel={() => window.location.replace("/")}/>
			</div>
		</div>
	);
}

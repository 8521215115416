import useAccount from "classes/Accounts/hooks/useAccount";
import MenuToggler from "components/MenuToggler/MenuToggler";
import useTemplate from "hooks/useTemplate";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./PagesGroupPage.css";
import { returnRightsFromName } from "includes/rightType";
import SiteLogo from "Pages/Sites/components/SiteLogo/SiteLogo";
import { CustomPageLink } from "../PageLink/PageLink";
import CssFilterConverter from 'css-filter-converter';

export default function PagesGroupPage(props) {
	const { title, pages, curPage } = props;
	const { account } = useAccount();
	// const { account } = useAccount();
	// // returnRightsFromName("AdminAppearance", account.Rights);
	// const checkRight = () => {
	// 	const rigthName = TypeRights.AdminForms;
	// 	try {
	// 		return returnRightsFromName(account.Rights, rigthName);
	// 	} catch (error) {
	// 		return true;
	// 	}
	// };
	useEffect(() => {
		// Add class to body when the component mounts
		document.body.classList.add('scroll-mobilenav');

		// Remove class from body when the component unmounts
		return () => {
			document.body.classList.remove('scroll-mobilenav');
		};
	}, []);

	if (!pages) return false;
	return (
		<div className="PagesGroupPage ">
			<div className="d-flex align-items-center PageHeadBar">
				<MenuToggler
					className="me-3"
					isMenuOpen={props.isMenuOpen}
					setIsMenuOpen={props.setIsMenuOpen}
				/>
				<CustomPageLink
					activeOnlyWhenExact={true}
					to="/"
					className="d-block"
				>
					<SiteLogo
						companyId={account.CompanyId}
						siteId={account.SiteId}
					/>
				</CustomPageLink>

			</div>
			<div className="PagesGroupTiles">
				{pages.map((a) => (
					<PagesGroupTile
						curPlatform={props.platform}
						isActive={curPage === a.url}
						key={a.url}
						{...a}
					/>
				))}
			</div>
		</div>
	);
}

export function PagesGroupTile(props) {
	const {
		title,
		icon,
		url,
		cName,
		isActive,
		role,
		curPlatform,
		platform,
		appFunction,
	} = props;
	const { isSup } = useAccount();
	const { template } = useTemplate();
	const { color } = CssFilterConverter.hexToFilter(template?.primaryColor());

	if (
		(role && isSup(role) < 0) ||
		(platform && platform !== curPlatform) ||
		(appFunction &&
			template.getAppFunction &&
			!template.getAppFunction(appFunction) &&
			curPlatform !== "admin")
	)
		return false;

	const style = {
		filter: color,
		backgroundImage: `url(${icon})`,
	}

	return (
		<div
			className={
				"PagesGroupTile col-12 col-sm-6" +
				(isActive ? " active" : "")
			}
		>
			<Link
				className={`nav-link ${cName}`}
				to={url}
			>
				<div className="container-icon-nav">
					<div
						className="nav-link-page-icon button-bg"
						style={style}
					></div>
				</div>
				<div className="font-medium">{title}</div>
			</Link>
		</div>
	);
}

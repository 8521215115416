import useAccount from "classes/Accounts/hooks/useAccount";
import Button from "components/Button/Button";
import { t } from "i18next";
import React, { useState } from "react";

export default function DefaultLinkType() {
	const {getSettings, setSettings} = useAccount();
	const [current, setCurrent] = useState(getSettings("DefaultLinkType"));

	function changePreset(state) {
		setCurrent(state);
		setSettings("DefaultLinkType", state);
	}

	return (
		<div className="d-flex gap-3 align-items-center">
			<div>
				<Button onClick={() => changePreset("sites")} className={(current === "sites" ? " btn-primary" : "")}>{t("Settings.BY_SITES")}</Button>
			</div>
			<div className="rounded-buttons">
				<Button onClick={() => changePreset("groups")} className={current === "groups" ? "btn-primary" : ""}>{t("Settings.BY_GROUPS")}</Button>
			</div>
		</div>
	);
}

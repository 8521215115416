import React from "react";
import LoadCont from "components/LoadCont/LoadCont";
import { useQuery } from "react-query";
import "./PostStats.css";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import genRequest from "includes/request";
import PostTile from "Pages/Posts/components/Tile/PostTile";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import BackButton from "components/Button/BackButton";
import Targets from "./components/Targets";
import ReactionsStats, { Likes } from "./components/Reactions";
import { CreatedFrom, Origin } from "./components/Origin";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function PostStats({postId, onClose}) {

	const {getSettings} = useAccount();

	const retrievePost = () => {
		if (!postId)
			return (false);
		let req = genRequest("Posts/" + postId);
		return (req);
	};

	const {isLoading, data} = useQuery(
		["Posts", postId],
		retrievePost,
		{
			enabled: (postId ? true : false)
		}
	);

	if (isLoading)
		return (<LoadCont/>);

	const reactions_enabled = getSettings("Reactions");

	return (
		<div className="PostStats">
			<div className="d-flex mb-3 align-items-center">
				<BackButton className="me-3" title={t("Posts.CLOSE_STATS")} onClick={onClose}/>
				<h4 className="font-bold m-0 p-0 mt-1">{t("Posts.POST_STATS")}</h4>
			</div>
			<PostTile
				datas={data}
				isActive={true}
				isReaded={true}
				className="w-100 post-tile"
			/>
			<div className="body">
				<Origin postId={postId} siteId={data.SiteId}/>
				<div className="">
					<Targets postId={postId} postTypeId={data.PostTypeId} isTargetPost={data.IsTargetPost}/>
					<Reached postId={postId}/>
					<Views count={data.ViewCount}/>
					{!reactions_enabled && <Likes count={data.LikesCount} postId={data.PostId} summary={true}/>}
				</div>
				{
					reactions_enabled &&
						<ReactionsStats
							postId={postId}
							onClick={false}
							likesCount={data.LikesCount}
						/>
				}
				<CreatedFrom value={data.Adress}/>
			</div>
		</div>
	);
}

function Reached({postId}) {

	return (
		<div className="d-flex">
			<div className="post-views-cont d-flex align-items-center" title={t("Posts.REACHED_USERS")}>
				<FontAwesomeIcon icon={faUser}/>
				<InfosFetcher
					queryFn={() => genRequest("LtEmployesViewposts/PostId/" + postId)}
					queryKey={["LtEmployesViewposts", "PostId", postId]}
					options={{
						retry: 0
					}}
					treat={(a) => t("Posts.READED", {count: a?.length})}
					className="ms-1"
					displayOnError={true}
				/>
			</div>
		</div>
	);
}

function Views({count}) {
	return (
		<div className="d-flex">
			<div className="post-views-cont d-flex align-items-center" title={count + " " + t("Posts.VIEWS")}>
				<FontAwesomeIcon icon={faEye}/>
				<div className="post-views-count ms-1">{count} {t("Posts.VIEWS")}</div>
			</div>
		</div>
	);
}

import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";
import Moment from "react-moment";
export default function ReportPreview({ report }) {
	let parsed = JSON.parse(report.Txt);

	return (
		<div className="overflow-auto">
			<div>
				<h3>{t('Reporting.TITLE')}</h3>
				<b>{report.Adress}</b>
				<br />
				<Moment format={t("Events.DATE_TIME_FORMAT")}>
					{report.PublishedDate}
				</Moment>
			</div>
			<hr />
			<div>
				<h5>{t('Reporting.REQUEST')}</h5>
				<b>{parsed?.method?.toUpperCase()} - {parsed?.status || parsed?.error?.status}</b>
				<div>{parsed.url}</div>
			</div>
			<hr />
			<UserPreview report={report} datas={parsed} />
			<hr />
			<details>
				<summary>{t('Reporting.DATAS')}</summary>
				{
					<pre>
						{
							parsed?.data ?
								JSON.stringify(parsed.data, null, 4)
								:
								"No Data"
						}
					</pre>
				}
			</details>
			<hr />
			<details>
				<summary>{t('Reporting.ERROR')}</summary>
				{
					<pre>
						{
							parsed?.error ?
								JSON.stringify(parsed.error, null, 4)
								:
								"No Data"
						}
					</pre>
				}
			</details>
		</div>
	);
}

function UserPreview({ report, datas }) {
	let userId = report.EmployesId;

	if (datas?.user?.TempValues)
		userId = datas.user.TempValues.EmployesId;
	return (
		<div>
			<h5>{t('Reporting.USER')}</h5>
			{
				datas?.user?.TempValues &&
				<>
					<AuthorPreview
						employeId={datas?.user.EmployesId}
						format={
							[
								"EmployeFirstName EmployeLastName / WFU",

							]
						}
						className="mb-2"
						modifiers={{
							EmployeLastName: (item) => item.toUpperCase()
						}}
					/>
					{t('Reporting.MSG1')}:
				</>
			}
			<AuthorPreview
				employeId={userId}
				format={
					[
						"EmployeFirstName EmployeLastName / WFU",

					]
				}
				className="mb-2"
				modifiers={{
					EmployeLastName: (item) => item.toUpperCase()
				}}
			/>

			<details>
				<summary>
					{t('Reporting.INFORMATIONS')}
				</summary>
				<pre>
					{
						datas?.user ?
							JSON.stringify(datas?.user, null, 4)
							:
							<InfosFetcher
								queryFn={() => genRequest("Employes/" + userId)}
								queryKey={["Employes", userId]}
								treat={(a) => JSON.stringify(a, null, 4)}
							/>
					}
				</pre>
			</details>
		</div>
	);
}

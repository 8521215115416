import React, { useState, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { motion } from "framer-motion";
import PageLink, { PageButton } from "../PageLink/PageLink";
import useUnread from "classes/Accounts/hooks/useUnread";
import genRequest from "includes/request";
import "./PagesGroup.css";
import { PICTOS_URL } from "includes/paths";
import useAccount from "classes/Accounts/hooks/useAccount";

function PageGroup(props) {
	const { getSettings } = useAccount();
	const { getUnread, updatePageTitle, setItems } = useUnread();
	const QCL = useQueryClient();
	const { item, platform, getAppFunction, template } = props;
	const [isOpen, setIsOpen] = useState({
		state: props.isActive,
		forced: false,
	});

	const wasActive = useRef();
	let items = item.items;

	React.useEffect(() => {
		if (!props.isActive && wasActive.current) setIsOpen(false);
		wasActive.current = props.isActive;
	}, [setIsOpen, props]);

	function handleClick(page, force, e) {
		setIsOpen({ state: false, forced: false });
		if (props.callback) props.callback(page, force, e);
	}

	function fetchChildNotifs(notifUrl, notifTypeId) {
		if (!notifUrl) return Promise.resolve(true);
		return genRequest(
			`Me/${notifUrl}${notifTypeId ? "/" + notifTypeId : ""}`
		);
	}

	function fetchNotifs() {
		let proms = [];
		if (items?.length)
			proms = items.map((a) => {
				if (!isEnabled(a)) return false;
				if (a.platform && a.platform !== platform) {
					QCL.setQueriesData(
						[
							"NotViewed",
							"Me",
							a.notifUrl,
							a.notifTypeId?.toString(),
						],
						[]
					);
					setItems(
						`${a.notifUrl}${
							a.notifTypeId ? "_" + a.notifTypeId : ""
						}`,
						[]
					);
					return false;
				}

				let req = fetchChildNotifs(a.notifUrl, a.notifTypeId).then(
					(resp) => {
						QCL.setQueryData(
							[
								"NotViewed",
								"Me",
								a.notifUrl,
								a.notifTypeId?.toString(),
							],
							resp
						);
						setItems(
							`${a.notifUrl}${
								a.notifTypeId ? "_" + a.notifTypeId : ""
							}`,
							resp
						);
						return resp;
					}
				);
				return req;
			});
		return Promise.allSettled(proms);
	}

	function fetchGroupNotifs() {
		let prom = new Promise((resolve, reject) => {
			fetchNotifs().then(() => {
				updatePageTitle();
				let check = checkNotifs();
				resolve(check);
				return check;
			}, reject);
		});
		return prom;
	}

	function toggleState(force_state, forced) {
		setIsOpen({
			state:
				typeof force_state !== "undefined"
					? force_state
					: !isOpen.state,
			forced: forced,
		});
	}

	function checkNotifs() {
		let unreadeds = [];

		if (items?.length) {
			items.forEach((child) => {
				if (!isEnabled(child)) return false;
				if (child.notifUrl)
					unreadeds = unreadeds.concat(
						getUnread(
							child.notifUrl +
								(child.notifTypeId
									? "_" + child.notifTypeId
									: "")
						)
					);
				return true;
			});
		}
		return unreadeds;
	}

	const contAnim = {
		hidden: {
			opacity: 1,
			transition: {
				duration: 0.5,
				ease: "easeInOut",
			},
		},
		show: {
			opacity: 1,
			transition: {
				duration: 0.5,
				staggerChildren: 0.1,
			},
		},
	};

	const itemAnim = {
		hidden: (i) => ({ y: -50 * i, opacity: 0, zIndex: 3 }),
		show: {
			y: 0,
			opacity: 1,
			zIndex: 3,
			transition: {
				duration: 0.5,
				ease: "easeInOut",
			},
		},
	};

	const wireAnim = {
		hidden: { height: "0%", zIndex: 1, opacity: 0 },
		show: {
			zIndex: 1,
			opacity: 1,
			height: "100%",
			transition: {
				duration: 0.5,
				ease: "easeInOut",
			},
		},
	};

	const getEnabledChilds = () => {
		let res = items.filter((a) => isEnabled(a));
		return res?.length ? res : false;
	};

	const isEnabled = (page_item) => {
		if (page_item.platform && page_item.platform !== platform) return false;
		if (page_item.settingName && !getSettings(page_item.settingName))
			return false;
		if (page_item.appFunction && getAppFunction) {
			let func = getAppFunction(page_item.appFunction);
			if (!func && props.platform !== "admin") return false;
		}
		return true;
	};

	const checkIsOpen = () => {
		if (!props.isActive && isOpen.state) return true;
		if (props.isActive && !isOpen.state && isOpen.forced) return false;
		if (props.isActive && isOpen.state) return true;
		if (props.isActive) return true;
	};

	// let data = checkNotifs();

	const { data } = useQuery(
		["NotViewed", "PagesGroup", item.name],
		fetchGroupNotifs,
		{
			staleTime: 1000,
			cacheTime: 1000,
		}
	);

	const enabled_childs = getEnabledChilds();

	if (!enabled_childs?.length) return false;

	const firstChild = enabled_childs[0];

	if (enabled_childs?.length === 1) {
		let isActive = props.cur === firstChild.url;
		return (
			<motion.div
				key={firstChild.url}
				variants={itemAnim}
				initial="hidden"
				animate="show"
			>
				<PageLink
					display={props.display}
					item={firstChild}
					isActive={isActive}
					parent={item}
					onClick={handleClick}
				/>
			</motion.div>
		);
	}

	return (
		<>
			{item.isVisible && (
				<div
					className={`PagesGroup ${item.name + "-pages-group"} ${
						props.isActive ? "active" : ""
					} ${checkIsOpen() ? "open" : ""}`}
				>
					<PageButton
						{...props}
						url={firstChild.url}
						notifs={data}
						item={{
							...item,
							url: items.find((item) => isEnabled(item)).url,
						}}
						isGroup={true}
						onClick={(e) => {
							setIsOpen({ state: false, forced: false });
							props.callback(firstChild, false, e);
						}}
						onArrowClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							toggleState(!checkIsOpen(), true);
						}}
					/>
					{
						<motion.div
							variants={contAnim}
							initial="hidden"
							animate="show"
							className="pages-group"
						>
							{props.isActive && (
								<motion.div
									variants={wireAnim}
									className="menu-wire"
									initial="hidden"
									animate="show"
								/>
							)}
							{checkIsOpen() &&
								items.map((page, index) => {
									let page_item = { ...page, parent: item };
									if (page_item.appMenu && template) {
										let menu = template.getMenus(
											page_item.appMenu
										);
										if (menu) {
											if (menu.Title?.length)
												page_item.title = menu.Title;
											if (menu.IconName?.length)
												page_item.icon =
													PICTOS_URL +
													encodeURIComponent(
														menu.IconName
													);
										}
									}
									page.title = page_item.title;
									if (!isEnabled(page_item)) return false;
									if (page_item.items)
										return (
											<PageGroup
												display={props.display}
												item={page_item}
												key={
													page.name + "-" + page.title
												}
												callback={handleClick}
											/>
										);
									else {
										let isActive =
											props.cur === page_item.url;
										return (
											<motion.div
												key={page_item.url}
												custom={index}
												variants={itemAnim}
												initial="hidden"
												animate="show"
											>
												<PageLink
													display={props.display}
													item={page_item}
													isActive={isActive}
													parent={item}
													onClick={handleClick}
												/>
											</motion.div>
										);
									}
								})}
						</motion.div>
					}
				</div>
			)}
		</>
	);
}

export default PageGroup;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export default function DefaultEndStep({text, children}) {
	return (
		<div className="text-center inner-step-cont">
			<div>{text}</div>
			<div style={{fontSize: "3.75em", color: "green"}}><FontAwesomeIcon icon={faCheckCircle}/></div>
			{children && <hr/>}
			{children}
		</div>
	);
}

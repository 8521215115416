import React from "react";
import { t } from "i18next";
import SendStep from "components/EditDrawer/SendStep/SendStep";

export default function AnimationSendStep(props) {
	let animation = props.object;
	let post = animation.linkedPost();

	if (!props.isActive)
		return (false);

	const queries = [
		{
			queryKey: ["SendAnimation", "Animation", animation.title(), animation.lastChanged()],
			queryFn: () => animation.send(),
			title: t("Events.SENDING_EVENT"),
			required: true
		},
		{
			queryKey: ["SendAnimation", "Images", post.postId(), post.lastChanged()],
			queryFn: () => post.sendImages(),
			title: t("Posts.SEND_IMAGES"),
			disabled: !post.images().length
		},
		{
			queryKey: ["SendAnimation", "Docs", post.postId(), post.lastChanged()],
			queryFn: () => post.sendDocs(),
			title: t("Docs.SENDING_DOCS"),
			disabled: !post.documents().length
		},
		{
			queryKey: ["SendAnimation", "SendNotif", "PushNotification", animation.title(), animation.lastChanged()],
			queryFn: () => animation.notify(),
			title: t("Commons.SENDING_NOTIF"),
			disabled: !(animation.notification() && !animation.isDraft())
		},
	];

	return (
		<SendStep
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
			queries={queries}
		/>
	);
}

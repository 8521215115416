import React, { useState } from "react";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import SendStep from "components/EditDrawer/SendStep/SendStep";
import useTemplate from "hooks/useTemplate";

export default function PostSendStep(props) {
	const { account, isAdmin } = useAccount();
	const { template } = useTemplate();
	const [isInitied, setIsInitied] = useState(false);
	let post = props.object;

	const checkAppFunction = React.useCallback(() => {
		let af = "NewsPage.PostQueueStyle";

		let typeId = post.postTypeId();
		if (typeId === 4)
			af = "OffersPage.PostQueueStyle";
		else if (typeId === 14)
			af = "ProOffers.PostQueueStyle";
		return (template.getAppFunction(af));
	}, [template, post]);

	React.useEffect(() => {
		if (!isInitied) {
			if (!post.draft()) {
				if (isAdmin() || checkAppFunction() === "Published")
					post.setAsPublished();
				else
					post.setAsToModerate();
			}
			setIsInitied(true);
		}
	}, [props, post, isInitied, setIsInitied, isAdmin, checkAppFunction]);

	const notify = () => {
		if (post.postTypeId() !== 13)
			return (post.notify());
		let url = "NewsNotifyCustomizeWithoutPost";
		let data = {
			Title: post.title(),
			Message: post.txt(),
			ListUsers: post.targets(),
			Author: account,
			TimeToNotify: post.publishedDate()
		};
		if (new Date(post.publishedDate()) > new Date())
			url = "NewsNotifyCustomizeDelayWithoutPost";
		return (post.notify(url, data));
	};

	if (!props.isActive || !isInitied)
		return (false);

	const queries = [
		{
			queryKey: ["SendPost", "Post", post.title(), post.lastChanged()],
			queryFn: () => post.send(),
			title: t("Posts.SEND_POST"),
			required: true
		},
		{
			queryKey: ["SendNotif", "Post", post.title(), post.lastChanged()],
			queryFn: notify,
			title: t("Commons.SENDING_NOTIF"),
			disabled: !(post.notification() && post.validationModeration() === true && post.draft() === false)
		},
		{
			queryKey: ["LinkUsers", "Post", post.title(), post.lastChanged()],
			queryFn: () => post.linkTargets(),
			title: t("Commons.TARGETS"),
			disabled: post.postTypeId() !== 13 || !post.targets()?.length
		},
		{
			queryKey: ["SendPost", "Images", post.postId(), post.lastChanged()],
			queryFn: () => post.sendImages(),
			title: t("Posts.SEND_IMAGES"),
			disabled: !post.images().length && !post.rssImages().length
		},
		{
			queryKey: ["SendPost", "Attachements", post.postId(), post.lastChanged()],
			queryFn: () => post.linkAttachements(),
			title: t("Posts.SENDING_ATTACHMENTS"),
			disabled: !post.attachements().length
		},
	];
	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			isDone={props.isDone}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

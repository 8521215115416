import { t } from "i18next";
import React from "react";
import { useQueryClient} from "react-query";
import "./IdentityTab.css";
import ImageSender from "../ImageSender/ImageSender";
import ColorPicker from "components/ColorPicker/ColorPicker";
import useAccount from "classes/Accounts/hooks/useAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

function IdentityTab({companyId, Templates, forceRefresh}) {

	const {getSettings} = useAccount();
	const queryClient = useQueryClient();

	function onChange() {
		queryClient.invalidateQueries(["Template", companyId]);
	}

	function updateColor(type, color) {
		let proms = [];
		if (Templates?.length)
		{
			if (
				(type === "primary" && Templates[0].primaryColor() === color)
				|| (type === "secondary" && Templates[0].secondaryColor() === color)
			)
				return (false);

			for (let x in Templates)
			{
				let temp = Templates[x];
				if (type === "primary")
					temp.primaryColor(color);
				else
					temp.secondaryColor(color);
				proms.push(temp.send());
			}
		}

		let prom = Promise.all(proms).then(() => {
			if (type === "primary")
				Templates[0]?.primaryColor(color);
			else
				Templates[0]?.secondaryColor(color);
			forceRefresh();
		});
		return (prom);
	}

	if (!Templates[0]?.primaryColor())
		return (false);

	let custom_color = getSettings("PrimaryColor");

	return (
		<>
			<div className="d-flex w-100 gap-3 logos-cont flex-wrap">
				<ImageSender className="mb-4 w-100" title={t("Appearance.LOGO")} description={t("Appearance.LOGO_DESC")} onChange={onChange} companyId={companyId} typeId={10}/>
				<ImageSender className="mb-4 w-100 theme-dark" title={t("Appearance.LOGO")} description={t("Appearance.LOGO_DARK_THEME_DESC")} onChange={onChange} companyId={companyId} typeId={17}/>
			</div>
			{/* {
				isSup("EloAdmin") >= 0 && getSettings("DevFunctions") &&
				<div className="d-flex w-100 gap-3 logos-cont flex-wrap">
					<ImageSender className="mb-4" title={t("Appearance.BANNER")} companyId={companyId} typeId={16}/>
					<ImageSender className="mb-4" title={t("Appearance.APP_ICON")} companyId={companyId} typeId={18}/>
				</div>
			} */}
			<div style={{fontSize: "1rem"}}>{t("Appearance.MAIN_COLOR")}</div>
			<div className="bg-color radius-1 w-50 p-1 pb-0">
				<ColorPicker
					mode='Picker'
					locale={t("Code")}
					value={Templates[0]?.primaryColor()}
					mainColor={Templates[0]?.primaryColor()}
					onChange={(color) => updateColor("primary", color)}
					enableOpacity={false}
				/>
			</div>
			{
				(custom_color && custom_color !== Templates[0]?.primaryColor()) &&
					<div className="d-flex align-items-center gap-2 text-infos mt-1">
						<FontAwesomeIcon icon={faWarning} color="orange"/>
						<div style={{lineHeight: "1rem", fontSize: "0.9rem"}}>
							{t("Appearance.CUSTOM_COLOR_WARN")}
						</div>
					</div>
			}
		</>
	);
}

export default IdentityTab;

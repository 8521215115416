import formatDate from "includes/formatDate";
import genRequest from "includes/request";

class WorkFunctionObject {

	#CompanyId = null;
	#WorkFunctionId = null;
	#WorkFunctionName = null;
	#LastChanged = formatDate();
	#LastChange_AuthorId = null;

	constructor (props)
	{
		this.#CompanyId = props?.CompanyId || null;
		this.#WorkFunctionId = props?.WorkFunctionId || null;
		this.#WorkFunctionName = props?.WorkFunctionName || "";
		this.#LastChanged = props?.LastChanged || formatDate();
		this.#LastChange_AuthorId = props?.LastChange_AuthorId || null;
	}

	send = () => {
		let that = this;
		let isNew = !(this.#WorkFunctionId > 0);
		let prom = new Promise((resolve, reject) => {
			let req = that.sendWfunc().then(() => {
				if (isNew)
					return (that.linkToCompany());
				resolve(that.values());
				return (that.values());
			}, reject).then(() => {
				resolve (that.values());
				return (that.values());
			}, reject);
			return (req);
		});
		return (prom);
	};

	sendWfunc = () => {
		let datas = {
			CompanyId: this.#CompanyId,
			WorkFunctionName: this.#WorkFunctionName,
		};
		if (this.#WorkFunctionId)
			datas.WorkFunctionId = this.#WorkFunctionId;
		let req = genRequest(
			"WorkFunctions" + (this.#WorkFunctionId ? "/" + this.#WorkFunctionId : ""),
			datas,
			(this.#WorkFunctionId ? "put" : "post")
		).then((resp) => {
			if (!this.#WorkFunctionId)
			{
				this.#CompanyId = resp.CompanyId;
				this.#WorkFunctionId = resp.WorkFunctionId;
				this.#WorkFunctionName = resp.WorkFunctionName;
				this.#LastChange_AuthorId = resp.LastChange_AuthorId;
				this.#LastChanged = resp.LastChanged;
			}
		});
		return (req);
	};

	linkToCompany = () => {
		let req = genRequest(
			"LtWorkFunctionCompanies",
			{
				WorkFunctionId: this.#WorkFunctionId,
				CompanyId: this.#CompanyId
			},
			"post"
		);
		return (req);
	};

	id = () => this.#WorkFunctionId;

	name = (setName) => {
		if (typeof setName !== "undefined")
			this.#WorkFunctionName = setName;
		return (this.#WorkFunctionName);
	};

	lastChanged = () => this.#LastChanged;

	LastChange_AuthorId = () => this.#LastChange_AuthorId;

	values = () => ({
		CompanyId: this.#CompanyId,
		WorkFunctionId: this.#WorkFunctionId,
		WorkFunctionName: this.#WorkFunctionName,
		LastChanged: this.#LastChanged,
		LastChange_AuthorId: this.#LastChange_AuthorId
	});
}

export default WorkFunctionObject;

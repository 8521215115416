import React, { createContext, useContext, useEffect, useState } from "react";

const FilterContext = createContext();

const FilterProvider = ({ children }) => {
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [searching, setSearching] = useState("");
	const [siteSelected, setSiteSelected] = useState(null);
	const [categoriesSelected, setCategoriesSelected] = useState(null);
	const [postTypeId, setPostTypeId] = useState(null);
	const [categoryIdsArray, setCategoryIdsArray] = useState([]);
	const [numberFilterActivated, setNumberFilterActivated] = useState(0);
	// Return the context provider with the value
	return (
		<FilterContext.Provider
			value={{
				isFilterOpen,
				setIsFilterOpen,
				searching,
				setSearching,
				siteSelected,
				setSiteSelected,
				categoriesSelected,
				setCategoriesSelected,
				postTypeId,
				setPostTypeId,
				categoryIdsArray,
				setCategoryIdsArray,
				numberFilterActivated,
				setNumberFilterActivated,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export function useFilterContext() {
	return useContext(FilterContext);
}

export { FilterContext, FilterProvider };

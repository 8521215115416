import React, {useState} from "react";
import { CustomSelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import {
	UserRights,
//	AdminRights,
	SuperAdminRights,
//	BasicRights,
	EloAdminRights,
	getRightsFromPerms,
	getPermsOfRights
} from "components/RightsTable/RightsList";
// import RightsTable from "components/RightsTable/RightsTable";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";

export default function UserRightsStep({object}) {

	const {isSup} = useAccount();
	const user = object;
	const Roles = [
		// {
		// 	label: t("Contacts.READER_ROLE"),
		// 	value: "UserNoPublisher",
		// 	bypassModeration: false,
		// 	rights: BasicRights
		// },
		{
			label: t("Contacts.USER_ROLE"),
			value: "UserPublisher",
			bypassModeration: false,
			rights: UserRights
		},
		// {
		// 	label: t("Contacts.ADMIN_ROLE"),
		// 	value: "SiteAdmin",
		// 	bypassModeration: true,
		// 	rights: AdminRights
		// },
		{
			label: t("Contacts.ADMIN_ROLE"),
			value: "SuperAdmin",
			bypassModeration: true,
			rights: SuperAdminRights
		},
		{
			label: t("Contacts.ELOADMIN"),
			value: "EloAdmin",
			bypassModeration: true,
			rights: EloAdminRights
		}
	];

	const initRights = () => {
		const cur_rights = user.rights(true);
		if (cur_rights)
			return (getRightsFromPerms(cur_rights));
		let rights = getRightsFromPerms(UserRights);
		let perms = getPermsOfRights(rights);
		user.setRights(perms);
		return (rights);
	};

	// eslint-disable-next-line
	const [Rights, setRights] = useState(initRights());

	function onRoleChange(selected)
	{
		let rights = getRightsFromPerms(selected.rights);
		let perms = getPermsOfRights(rights);
		user.role(selected.value);
		user.bypassModeration(selected.bypassModeration);
		user.setRights(perms);
		setRights(rights);
	}

	// function onChange(perms) {
	// 	user.setRights(perms)
	// }

	const getRoles = () => Roles.map(a => isSup(a.value) >= 0 ? a : false).filter(a => a);

	return (
		<>
			<div className="inner-step-cont">
				<div>{t("Contacts.USER_ROLE_TXT")}</div>
				<CustomSelect
					onChange={onRoleChange}
					options={getRoles()}
					initialValue={[Roles.find((a) => a.value === user.role()) || {value: user.role(), label: user.role()}]}
				/>
			</div>
			{/* <div className="inner-step-cont">
				<RightsTable
					className="dark-bg"
					rights={Rights}
					adderRights={adderRights}
					onChange={onChange}
				/>
			</div> */}
		</>
	);
}

export function checkUserRights(user) {
	let errors = [];

	if (!user.role())
		errors.push(t("Contacts.ERROR_ROLE"));
	return ((errors?.length ? errors : true));
}

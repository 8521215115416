import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import genRequest from "includes/request";
import Button from "components/Button/Button";
import useAccount from "classes/Accounts/hooks/useAccount";
import ReactionTypeEdit from "./components/ReactionTypeEdit/ReactionTypeEdit";
import ReactionTypeObject from "classes/Reactions/ReactionTypeObject";
import ReactionIcon from "./components/ReactionIcon/ReactionIcon";
import "./ReactionsPage.css";
import { t } from "i18next";

export default function AdminReactionsPage(props) {
	const QCL = useQueryClient();
	const { account, isAdmin } = useAccount();
	const [editObj, setEditObj] = useState(false);
	const [selected, setSelected] = useState([]);

	function handleAdd() {
		let obj = new ReactionTypeObject({
			CompanyId: account.CompanyId,
			ReactionDesignId: 1
		});
		setEditObj(obj);
	}

	function handleModify(react_obj) {
		let obj = new ReactionTypeObject(react_obj);
		obj.fetchLinkedReactions().then(() => {
			setEditObj(obj);
		});
	}

	function handleDelete() {
		if (!selected?.length)
			return (false);
		let proms = selected.map((a) => {
			let obj = new ReactionTypeObject(a);
			return (obj.delete());
		});
		let prom = Promise.all(proms).then(resetPage, resetPage);
		return (prom);
	}

	function resetPage() {
		setSelected([]);
		QCL.refetchQueries(["Reactions"]);
	}

	function handleCloseEdit() {
		setEditObj(false);
		resetPage();
	}

	return (
		<div>
			<PageHeadBar
				{...props}
				handleAdd={handleAdd}
				addTitle={t("Commons.ADD")}
				siteSelect={false}
				search={false}
				buttons={[
					{
						forceTitle: true,
						title: t("Commons.DELETE") + " " + t("Commons.ITEMS", { count: selected.length }),
						classes: "btn-red btn",
						onClick: handleDelete,
						enabled: selected.length ? true : false
					}
				]}
			/>
			<AsyncScrollTable
				queryKey={["Reactions", "ReactionTypes"]}
				queryFn={() => genRequest("ReactionTypes")}
				itemId="ReactionTypeId"
				sortBy="Name"
				onItemClick={handleModify}
				checkbox={isAdmin()}
				setSelected={setSelected}
				selected={selected}
				cols={[
					{
						title: "Name",
						name: "Name",
					},
					{
						title: "Description",
						name: "Description",
					},
					{
						title: "ReactionTypeId",
						name: "ReactionTypeId",
					},
					{
						title: "ReactionDesignId",
						name: "ReactionDesignId",
					},
					{
						title: "CompanyId",
						name: "CompanyId",
					},
					{
						title: "Aperçu",
						name: "ReactionTypeId",
						component: ReactionsListCell
					},
				]}
			/>
			{
				editObj &&
				<ReactionTypeEdit
					reaction={editObj}
					isOpen={editObj ? true : false}
					onClose={handleCloseEdit}
				/>
			}
		</div>
	);
}

export function ReactionsListCell(props) {
	const { item, onClick, countQueryKey, countQueryFn } = props;

	const { data, isLoading } = useQuery(
		["Reactions", "Typed", item.ReactionTypeId],
		() => genRequest("Reactions/Typed/" + item.ReactionTypeId)
	);

	if (isLoading || !data?.length)
		return (false);

	return (
		<div
			className="ReactionListCell d-flex justify-content-around align-items-center gap-1"
			style={props.style}
		>
			{
				data.map((a) => {
					return (
						<ReactionIcon
							key={a.ReactionId}
							{...a}
							onClick={onClick}
							countQueryKey={countQueryKey}
							countQueryFn={countQueryFn}
						/>
					);
				})
			}
		</div>
	);
}

export function ReactionButton(props) {

	const { data } = useQuery(
		props.countQueryKey,
		props.countQueryFn,
		{
			enabled: (props.countQueryKey && props.countQueryFn ? true : false)
		}
	);

	return (
		<Button
			className={"ReactionButton unstyled p-1" + (props.className ? " " + props.className : "")}
			onClick={props.onClick}
		>
			<ReactionIcon {...props} async={props.asyncIcon} style={props.iconStyle} />
			{
				data &&
				<div className="reac-count">
					{data?.length}
				</div>
			}
		</Button>
	);
}

import useAccount from "classes/Accounts/hooks/useAccount";
import React from "react";
import PostDocumentStep from "../Documents/PostDocumentsStep";
import PostLinkedFormStep from "../Forms/PostFormSteps";
import { TypeRights, returnRightsFromName } from "includes/rightType";

export default function AttachementsStep(props) {
	const { isAdmin, account } = useAccount();

	const checkRight = () => {
		const rigthName = TypeRights.AdminForms;
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	if (!props.isActive) return false;

	return (
		<div className="AttachementStep">
			<PostDocumentStep {...props} />
			{isAdmin() && checkRight() && <PostLinkedFormStep {...props} />}
		</div>
	);
}

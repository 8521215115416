import React, { useState } from "react";
import genRequest from "includes/request";
import { useQuery, useQueryClient } from "react-query";
import formatDate from "includes/formatDate";
import download_file from "includes/dl_file";
import Moment from "react-moment";
import "./AnimationPreview.css";
import { t } from "i18next";
import PostContent from "Pages/Posts/components/PostPreview/components/PostContent/PostContent";
import useAccount from "classes/Accounts/hooks/useAccount";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import Button from "components/Button/Button";
import useTemplate from "hooks/useTemplate";
import AsyncPostPreview from "Pages/Posts/components/PostPreview/AsyncPostPreview";
import UserRow from "components/User/UserRow/UserRow";
import { getRightFromTypeId } from "includes/mappingTypeIdName";

export default function AnimationPreview(props) {
	const event = props.event;
	const { account, isAdmin } = useAccount();
	const { getAppFunction } = useTemplate();

	const checkRight = () => {
		const rigthName = getRightFromTypeId(8);
		try {
			return (
				account.Rights &&
				account.Rights.length > 0 &&
				account.Rights.filter((right) => right.Name === rigthName)
					.length > 0
			);
		} catch (error) {
			return true;
		}
	};

	function genCalfile(post) {
		let extended = event.extendedProps;
		let ret = [
			"BEGIN:VCALENDAR",
			"CALSCALE:GREGORIAN",
			"PRODID:-//Onlyall.fr//" + event.title + "//FR",
			"VERSION:2.0",
			"BEGIN:VEVENT",
			"DTSTAMP:" + formatDate(extended?.LastChanged, "YMDThms"),
			"DTSTART:" + formatDate(event.start, "YMDThms"),
			"DTEND:" + formatDate(event.end, "YMDThms"),
			"SUMMARY:" + event.title,
			"DESCRIPTION:" + post?.Txt?.replace(/\n/gi, "\\n"),
			"UID:OnlyAllEvent-" +
				event.id +
				(post?.PostId ? "-" + post.PostId : ""),
			"END:VEVENT",
			"END:VCALENDAR",
		];
		return ret;
	}

	function exportEvent(post) {
		let file = genCalfile(post).join("\n");
		download_file(file, "Événement - " + event.title, "text/calendar");
	}

	function handleModify(anim) {
		if (props.handleModify) props.handleModify(anim);
	}

	function handleClone(anim) {
		if (props.handleClone) props.handleClone(anim);
	}

	function handleDelete(anim) {
		if (props.handleDelete) props.handleDelete(anim);
	}

	const canParticipate = () => {
		let d = event.extendedProps?.DisplayParticipation;
		if (getAppFunction("AnimationsPage.CanParticipate") === false) return d;
		if (d === null) return getAppFunction("AnimationsPage.CanParticipate");
		return d;
	};

	const { isLoading, data, error } = useQuery(
		["Posts", "PostOfAnimation", event.id],
		() => genRequest("Posts/PostOfAnimation/" + event.id),
		{
			onError: () => {
				let ret = event;
				ret.Txt = "";
				return ret;
			},
			retry: 0,
		}
	);

	const checkRightAndCorrectAuthor = () => {
		return checkRight() ? true : data.EmployesId === account.EmployesId;
	};

	if (isLoading)
		return (
			<div className="post-preview loading p-4">
				<div className="w-100 mb-4" style={{ height: "150px" }}></div>
				<div className="w-100"></div>
				<div className="w-50"></div>
				<div className="w-25"></div>
				<div className="w-35"></div>
			</div>
		);

	const isPassed = event.end < new Date();

	return (
		<div className="AnimationPreview">
			{!error ? (
				<AsyncPostPreview
					className="radius-2"
					stats={false}
					key={event.id}
					postId={data.PostId}
					animationId={event.id}
					responsive={props.responsive}
					extend={false}
					extendImages={false}
					header={<EventDates event={event} />}
					bottombar={false}
					topbar={false}
				/>
			) : (
				<FallbackAnimationPreview
					exportEvent={exportEvent}
					event={event}
				/>
			)}

			{canParticipate() && (
				<div className="cont mt-3 radius-2">
					<InfosFetcher
						queryFn={() =>
							genRequest(
								"Employes/EmployeWhoParticiatedToAnimation/" +
									event.id
							)
						}
						queryKey={[
							"Employes",
							"EmployeWhoParticiatedToAnimation",
							event.id,
						]}
						options={{
							retry: 0,
						}}
						displayOnError={true}
						treat={(resp) => (
							<div>
								<div className="font-bold text-uppercase">
									{t("Events.PARTICIPANT", {
										count: resp.length,
									})}
								</div>
								{resp?.length > 0 && (
									<div
										style={{
											maxHeight: "300px",
											overflow: "auto",
										}}
									>
										{resp.map((a) => {
											return (
												<UserRow
													async={true}
													key={a.EmployesId}
													employeId={a.EmployesId}
													status={false}
												/>
											);
										})}
									</div>
								)}
							</div>
						)}
					/>
					{!isPassed && (
						<div className="mt-2 font-regular">
							<ParticipationButton
								animationId={event.id}
								isPassed={isPassed}
							/>
						</div>
					)}
				</div>
			)}
			{(isAdmin() || (props.export !== false && !isPassed)) && (
				<div className="cont mt-3 d-flex flex-column gap-2">
					{props.export !== false && !isPassed && (
						<div>
							<Button
								className="btn btn-bg"
								onClick={() => exportEvent(data)}
							>
								{t("Events.EXPORT")}
							</Button>
						</div>
					)}
					{isAdmin() && (
						<>
							{checkRightAndCorrectAuthor() && (
								<div>
									<Button
										className="btn btn-blue"
										onClick={() => handleModify(event)}
									>
										{t("Events.MODIFY")}
									</Button>
								</div>
							)}
							{checkRight() && (
								<div>
									<Button
										className="btn btn-green"
										onClick={() => handleClone(event)}
									>
										{t("Commons.USE_AS_TEMPLATE")}
									</Button>
								</div>
							)}
							{checkRight() && (
								<div>
									<Button
										className="btn btn-red"
										onClick={() => handleDelete(event)}
									>
										{t("Commons.DELETE")}
									</Button>
								</div>
							)}
						</>
					)}
				</div>
			)}
		</div>
	);
}

function ParticipationButton({ animationId }) {
	const QCL = useQueryClient();
	const { account } = useAccount();
	const [parti, setParti] = useState(false);

	function handleParticipation() {
		let prom = null;
		if (parti) prom = cancelParticipation(parti.ParticipationId);
		else prom = participate();
		prom.then(() => {
			QCL.refetchQueries([
				"Employes",
				"EmployeWhoParticiatedToAnimation",
				animationId,
			]);
		});
		return prom;
	}

	const participate = () =>
		genRequest(
			"AnimationParticipations",
			{
				EmployesId: account.EmployesId,
				AnimationId: animationId,
				DateOfPublish: formatDate(),
			},
			"post"
		).then((resp) => setParti(resp));

	const cancelParticipation = (id) =>
		genRequest(
			"AnimationParticipations/ParticipationId/" + id,
			{},
			"delete"
		).then(() => setParti(false));

	const fetchParticipation = () =>
		genRequest(
			`AnimationParticipations/ParticipationOfEmployeForAnimation/${account.EmployesId}/${animationId}`
		);

	const { isLoading } = useQuery(
		[
			"AnimationParticipations",
			"ParticipationOfEmployeForAnimation",
			account.EmployesId,
			animationId,
		],
		fetchParticipation,
		{
			retry: 0,
			enabled: animationId ? true : false,
			onSuccess: (resp) => {
				setParti(resp ? resp : false);
			},
			onError: () => setParti(false),
		}
	);

	if (isLoading)
		return (
			<div className="loading secondary">
				<div className="p-2 py-3 w-100"></div>
			</div>
		);

	return (
		<Button
			className={"btn " + (parti ? "btn-red" : "btn-blue")}
			onClick={() => handleParticipation()}
		>
			{t(`Events.${parti ? "NO_PARTICIPATE" : "PARTICIPATE"}`)}
		</Button>
	);
}

function FallbackAnimationPreview({ event }) {
	let animation = event.extendedProps;

	return (
		<div className="FallbackAnimationPreview">
			<PostContent post={animation} />
			<EventDates event={event} />
			<div className="load-error mt-2">{t("Events.LOAD_ERROR_2")}</div>
		</div>
	);
}

function EventDates({ event }) {
	let format =
		"[" +
		t("Commons.FROM") +
		"] D MMMM YYYY [" +
		t("Commons.AT") +
		"] HH:mm";

	if (event.allDay) format = "[" + t("Commons.THE") + "] D MMMM YYYY";

	return (
		<div>
			<div>
				<Moment
					locale={t("Code")}
					titleFormat={format}
					format={format}
					withTitle
					className="event-date"
				>
					{event.start}
				</Moment>
			</div>
			{!event.allDay && (
				<div>
					<Moment
						locale={t("Code")}
						titleFormat={
							"[" +
							t("Commons.TO") +
							"] D MMMM YYYY [" +
							t("Commons.AT") +
							"] HH:mm"
						}
						format={
							"[" +
							t("Commons.TO") +
							"] D MMMM YYYY [" +
							t("Commons.AT") +
							"] HH:mm"
						}
						withTitle
						className="event-date"
					>
						{event.end}
					</Moment>
				</div>
			)}
		</div>
	);
}

import React, { useState } from "react";
import { DatePickerToggler } from "components/DatePIcker/DatePicker";
import { InnerStepCont } from "components/EditDrawer/EditDrawer";
import { t } from "i18next";

export default function AlertSendOptions(props) {
	const post = props.object;
	const [, setUpdate] = useState(0);

	function handleDiffered(value) {
		post.publishedDate(value);
		post.notification(true);
		setUpdate(Date.now());
	}

	return (
		<div>
			<InnerStepCont title={t("Commons.PUBLISH_OPTIONS")}>
				<DatePickerToggler
					className="mt-2"
					onChange={handleDiffered}
					startDate={new Date(post.publishedDate())}
					isChecked={post.isDiffered()}
					label={t("Commons.DEFERRED_PUBLISH")}
				/>
			</InnerStepCont>
		</div>
	);
}

import genRequest from "includes/request";

class GroupObject {
	#GroupId = null;
	#Name = null;
	#CompanyId = null;
	#Description = null;
	#DefaultHexaColor = null;
	#LastChanged_AuthorId = null;
	#LastChanged = null;

	#LinkTo = [];
	#DupGroupsPosts = [];

	constructor (props) {
		this.#GroupId = (props?.GroupId ? props.GroupId : null);
		this.#Name = (props?.Name ? props.Name : "");
		this.#CompanyId = (props?.CompanyId ? props.CompanyId : null);
		this.#Description = (props?.Description ? props.Description : "");
		this.#DefaultHexaColor = (props?.DefaultHexaColor ? props.DefaultHexaColor : null);
		this.#LastChanged_AuthorId = (props?.LastChanged_AuthorId ? props.LastChanged_AuthorId : null);
		this.#LastChanged = (props?.LastChanged ? props.LastChanged : null);
		this.#LinkTo = (props?.LinkTo ? props?.LinkTo : []);
		this.#DupGroupsPosts = (props?.DupGroupsPosts ? props?.DupGroupsPosts : []);
	}

	fetchUsers = () => {
		if (!this.#GroupId)
			return (Promise.resolve([]));
		return (genRequest("Employes/EmployeOfCategory/" + this.#GroupId));
	};

	send = () => {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			try {
				that.sendGroup()
					.then(() => {
						return (that.linkUsers());
					}, reject).then(() => {
						return (that.linkPostsFromGroups());
					}, reject).then(() => {
						resolve (that.values());
						return (true);
					});
			} catch (e) {
				reject (e);
				return (false);
			}
		});
		return (prom);
	};

	sendGroup = () => {
		let data = {
			GroupId: this.#GroupId,
			CompanyId: this.#CompanyId,
			Name: this.#Name,
			Description: this.#Description,
			DefaultHexaColor: this.#DefaultHexaColor,
		};
		if (!this.#GroupId)
			delete data.GroupId;
		let req = genRequest(
			"Groups" + (this.#GroupId ? "/" + this.#GroupId : ""),
			data,
			(this.#GroupId ? "put" : "post")
		).then((resp) => {
			if (!this.#GroupId)
				this.#GroupId = resp.GroupId;
		});
		return (req);
	};

	linkUsers = () => {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			try {
				this.unlinkEmployes().then(() => {
					if (!this.#LinkTo?.length)
						return (Promise.resolve());
					let proms = that.#LinkTo.map(a => this.linkUser(a.EmployesId));
					return (Promise.all(proms));
				}, reject).then(() => {
					resolve (that.values());
					return (true);
				}, reject);
			} catch (e) {
				reject (e);
				return (false);
			}
		});
		return (prom);
	};

	linkUser = (userId) => genRequest(
		"Lt_EmployeOfGroup", {
			EmployesId: userId,
			GroupId: this.#GroupId
		},
		"post"
	);

	linksTo = (links) => {
		if (typeof links !== "undefined")
			this.#LinkTo = links;
		return (this.#LinkTo);
	};

	unlinkEmployes = () => {
		if (!this.#GroupId)
			return (Promise.resolve());
		let prom = genRequest(
			"LTEmployeOfGroup/GroupId/" + this.#GroupId,
			null,
			"delete"
		);
		return (prom);
	};

	setDupGroups = (groups) => {
		if (typeof groups !== "undefined")
			this.#DupGroupsPosts = groups;
		return (this.#DupGroupsPosts);
	};

	linkPostsFromGroups = () => {
		if (!this.#DupGroupsPosts?.length)
			return (Promise.resolve());
		let req = genRequest(
			"Lt_PostTargetedToGroup/FromOtherGroups",
			{
				"CreatedGroupId": this.#GroupId,
				"TargetGroupToGetPost": this.#DupGroupsPosts
			},
			"post"
		);
		return (req);
	};

	groupId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#GroupId = setValue;
		return (this.#GroupId);
	};

	name = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#Name = setValue;
		return (this.#Name);
	};

	companyId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#CompanyId = setValue;
		return (this.#CompanyId);
	};

	description = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#Description = setValue;
		return (this.#Description);
	};

	defaultHexaColor = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#DefaultHexaColor = setValue;
		return (this.#DefaultHexaColor);
	};

	lastChanged_AuthorId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#LastChanged_AuthorId = setValue;
		return (this.#LastChanged_AuthorId);
	};

	lastChanged = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#LastChanged = setValue;
		return (this.#LastChanged);
	};

	values = () => ({
		GroupId: this.#GroupId,
		Name: this.#Name,
		CompanyId: this.#CompanyId,
		Description: this.#Description,
		DefaultHexaColor: this.#DefaultHexaColor,
		LastChanged_AuthorId: this.#LastChanged_AuthorId,
		LastChanged: this.#LastChanged
	});
}

export default GroupObject;


import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import React from "react";
import {useQueryClient} from "react-query";
import TableToolBar from "components/Table/TableToolbar/TableToolbar";
import { t } from "i18next";
import promiseToast from "components/Toasts/PromiseToast";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import useAccount from "classes/Accounts/hooks/useAccount";
import TargetsPopper from "./TargetsPopper";

export default function PostTableToolbar(props) {
	const {isSup} = useAccount();
	const {selectedRows, clearSelection} = props;
	const {confirmDelete} = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		if (!selectedRows?.length)
			return (false);
		let title = t("Posts.ASKDELETE", {
			title: selectedRows[0].Title,
			count: selectedRows.length
		})
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = [];
		for (let x in selectedRows)
			if (selectedRows[x]?.PostId)
				proms.push(new Promise((resolve, reject) => {
					setTimeout(() => {
						genRequest(
							"Posts/PostId" + selectedRows[x]?.PostId+ "AndDeleteDocument=falseAndDeleteComment=trueAndDeleteAnimation=trueAndDeleteParticipation=true",
							null,
							"delete"
						).then((resp) => {
							resolve(resp);
						}, reject);
					}, 500 * x);
				}));
		let req = Promise.all(proms).then(() => {
			QCL.resetQueries(["RetrievePosts"]);
			if (props.onDelete)
				props.onDelete();
			clearSelection();
		}, () => {
			QCL.resetQueries(["RetrievePosts"]);
		});
		promiseToast(
			req,
			{
				pending: t("Posts.DELETING", {count: selectedRows?.length})
			}
		);
		return (req);
	}


	return (
		<div>
			<TableToolBar
				{...props}
				buttons={[
					{
						title: "Définir les cibles",
						icon: faBullseye,
						value: "Cibles",
						popper: TargetsPopper,
						closeOnclickOutside: false,
						enabled: isSup("EloAdmin") >= 0
					}
				]}
				handleDelete={handleDelete}
			/>
		</div>
	);
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faTable } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button/Button";
import { t } from "i18next";
import useDisplay from "hooks/useDisplay";

export default function DisplayButton(props) {
	const { display, setDisplay } = useDisplay(props.display);
	const LastDisplay = React.useRef();

	React.useEffect(() => {
		if (LastDisplay.current !== props.display) setDisplay(props.display);
		LastDisplay.current = props.display;
	}, [props.display]);

	function changeDisplay() {
		let newDisp = "tiles";
		if (display === "table") newDisp = "tiles";
		else newDisp = "table";
		if (props.setDisplay) props.setDisplay(newDisp);
		setDisplay(newDisp);
	}

	if (document.body.clientWidth > 700)
	return (
		<div>
			<Button
				disabled={props.isDisabled}
				title={t("Commons.CHANGE_DISP")}
				className="btn change-disp"
				onClick={changeDisplay}
			>
				<FontAwesomeIcon icon={display !== "table" ? faTable : faTh} />
			</Button>
		</div>
	);

	else
	return null;
}

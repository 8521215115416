const PostsUITour = {
	tourId: "posts",
	title: "Page des publications",
	description: "News, articles, annonces, carrières ...",
	page: "/news",
	steps: [
		{
			target: ".PageHeadBar .headbar-add-btn",
			title: "Ajouter une publication",
			content: "Cliquez ici pour ajouter une publication",
			disableBeacon: true
		},
		{
			target: ".PageHeadBar .filter-button",
			title: "Bouton des filtres",
			content: "Cliquez ici pour filter la liste",
			spotlightClicks: true
		},
		{
			target: ".PageHeadBar .change-disp",
			title: "Changement d'affichage",
			content: "Cliquez ici pour changer l'affichage de la liste",
			spotlightClicks: true
		},
		{
			target: ".PostsList",
			title: "Liste des publications",
			content: "Cliquez sur une des publication pour l'ouvrir",
			spotlightClicks: true
		},
		{
			target: ".post-preview-cont",
			title: "Visualisation",
			content: "Visualisez la publication ouverte ici",
			spotlightClicks: true
		}
	]
};

export default PostsUITour;

import React, { Component } from "react";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { L10n } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";

import { t } from "i18next";
import "./ColorPicker.css";
import { gen_color_palette } from "includes/Colors";

class ColorPicker extends Component {

	constructor(props) {
		super (props);
		this.ref = React.createRef();

		const lang = {};
		lang[t("Code")] = {
			"colorpicker": {
				"Apply": t("Commons.APPLY"),
				"Cancel": t("Commons.CANCEL"),
			}
		};
		L10n.load(lang);
		this.basic_palette = ["#000000ff", "#f44336ff", "#e91e63ff", "#9c27b0ff",
			"#673ab7ff", "#2196f3ff", "#03a9f4ff", "#00bcd4ff", "#009688ff",
			"#ffeb3bff", "#ffffffff", "#ffebeeff", "#fce4ecff", "#f3e5f5ff",
			"#ede7f6ff", "#e3f2fdff", "#e1f5feff", "#e0f7faff", "#e0f2f1ff",
			"#fffde7ff", "#f2f2f2ff", "#ffcdd2ff", "#f8bbd0ff", "#e1bee7ff",
			"#d1c4e9ff", "#bbdefbff", "#b3e5fcff", "#b2ebf2ff", "#b2dfdbff",
			"#fff9c4ff", "#e6e6e6ff", "#ef9a9aff", "#f48fb1ff", "#ce93d8ff",
			"#b39ddbff", "#90caf9ff", "#81d4faff", "#80deeaff", "#80cbc4ff",
			"#fff59dff", "#ccccccff", "#e57373ff", "#f06292ff", "#ba68c8ff",
			"#9575cdff", "#64b5f6ff", "#4fc3f7ff", "#4dd0e1ff", "#4db6acff",
			"#fff176ff", "#b3b3b3ff", "#ef5350ff", "#ec407aff", "#ab47bcff",
			"#7e57c2ff", "#42a5f5ff", "#29b6f6ff", "#26c6daff", "#26a69aff",
			"#ffee58ff", "#999999ff", "#e53935ff", "#d81b60ff", "#8e24aaff",
			"#5e35b1ff", "#1e88e5ff", "#039be5ff", "#00acc1ff", "#00897bff",
			"#fdd835ff", "#808080ff", "#d32f2fff", "#c2185bff", "#7b1fa2ff",
			"#512da8ff", "#1976d2ff", "#0288d1ff", "#0097a7ff", "#00796bff",
			"#fbc02dff", "#666666ff", "#c62828ff", "#ad1457ff", "#6a1b9aff",
			"#4527a0ff", "#1565c0ff", "#0277bdff", "#00838fff", "#00695cff",
			"#f9a825ff", "#4d4d4dff", "#b71c1cff", "#880e4fff", "#4a148cff",
			"#311b92ff", "#0d47a1ff", "#01579bff", "#006064ff", "#004d40ff",
			"#f57f17ff"
		];
	}

	componentDidMount() {
		this.parent = this.ref.current?.reactElement?.parentElement;
		let elem = this.parent?.querySelector(".e-split-colorpicker");
		let btn = this.parent?.querySelector(".e-dropdown-btn");

		if (elem)
		{
			elem.addEventListener("mousedown", (e) => {
				e.stopImmediatePropagation();
				btn.click();
			});
		}
	}

	render(){
		return (
			<ColorPickerComponent
				// mode='Picker'
				locale={t("Code")}
				value={this.props.value}
				cssClass={"color-picker " + this.props.cssClass}
				className={"color-picker " + this.props?.className}
				change={(e) => this.props.onChange(e.value)}
				focusIn={() => {}}
				ref={this.ref}
				enableOpacity={this.props.enableOpacity}
				presetColors={{
					"custom": (this.props?.mainColor ? gen_color_palette(this.props?.mainColor, 30) : false),
					"basic": this.basic_palette
				}}
			/>
		);
	}
}

export default ColorPicker;

import React, {memo, createRef} from "react";
import DraggableTableRow from "./DraggableTableRow";

const TableRow = memo((props) => {
	const ref = createRef();

	if (props.dragRows)
		return (<DraggableTableRow {...props}/>);

	function mouseOver(e) {
		if (ref)
		{
			let target = ref.current;
			if (window.SCROLLTABLE_LASTMD !== target.lastMd)
			{
				target.lastMd = window.SCROLLTABLE_LASTMD;
				if (window.SCROLLTABLE_LASTMD)
					props.handleCheckbox(e);
			}
		}
	}

	return (
		<tr
			className={
				(props.isSelected ? "selected" : "") + " " +
				(props.isActive ? "active" : "")
			}
			onClick={props.onClick ? props.onClick : () => {}}
			onMouseOver={mouseOver}
			ref={ref}
		>
			{
				props.cols.map((col, index) => {
					if (props.maxCols && index > props.maxCols)
						return (false);
					let col_txt = "" + (props.datas ? props.datas[col.name] : col.title) + "";
					if (!col_txt?.length)
						col_txt = col.title;
					if (col.hidden)
						return (false);
					return (
						<TableCell
							{...col}
							col={col}
							index={index}
							item={props.datas}
							checkbox={props.checkbox && index === 0 && props.datas?.checkbox !== false ? true : false}
							key={props.tableId + "-" + col.name}
							title={(col_txt ? col_txt : "")}
							handleCheckbox={props.handleCheckbox}
							isSelected={props.datas?.isSelected || props.isSelected}
							tableId={props.tableId}
							onCellChange={props.onCellChange}
							cellsProps={props.cellsProps}
							rowIndex={props.rowIndex}
						/>
					);
				})
			}
		</tr>
	);
});
TableRow.displayName = "TableRow";

export const TableCell = memo((props) => {
	let title = (props.title?.length && props.title !== "null" && props.title !== null ? props.title : "");

	if (!title?.length && props.noValuePh)
		title = props.noValuePh;
	return (
		<td className={props.name.toLowerCase() + "-col" + (props.classes ? " " + props.classes : "")}>
			<div className={"d-flex align-items-center"}>
				{
					(props.checkbox !== false && !props.item.checkbox) ?
						<div className="table-checkbox me-2">
							<input
								type="checkbox"
								checked={props.isSelected || false}
								onClick={(e) => e.stopPropagation()}
								onChange={(e) => props.handleCheckbox(e)}
								className="checkbox"
							/>
						</div>
						:
						(props.checkbox !== false && typeof props.item.checkbox === "object" ? props.item.checkbox : false)
				}
				<div className="w-100 table-cell" name={props.name}>
					{
						props.component ?
							<props.component {...props.childProps} {...props.cellsProps} col={props.col} rowIndex={props.rowIndex} colIndex={props.index} item={props.item} onCellChange={props.onCellChange}>
								{ (title !== "null" ? title : "") }
							</props.component>
							:
							(title !== "null" ? title : "")
					}
				</div>
			</div>
		</td>
	);
});
TableCell.displayName = "TableCell";

export default TableRow;

import React, {useState} from "react";
import TableCtxMenu from "components/ScrollTable/TableCtxMenu";
import { t } from "i18next";
import { Droppable } from "react-beautiful-dnd";
import TableRow from "../TableRow/TableRow";
import { useContextMenu } from "react-contexify";
import "./TableBody.css";

const DraggableTableBody = (props) => {
	const {
		tableId,
		isSelected,
		activeId,
		items,
		onItemClick,
		handleCheckbox,
		checkbox,
		cols,
		itemId,
		isMouseDown,
		cellSpacing
	} = props;
	const {show} = useContextMenu();
	const [ctxProps, setCtxProps] = useState(false);

	const handleDrop = (e) => {
		this.props.onDragEnd(e);
	};

	let handles = {
		onDrop: handleDrop,
		// onDragOverCapture: handleDragOver,
		// onDragLeaveCapture: (e) => handleDragOver(e, true),
	};

	const handleCtxMenu = (event, click_props) => {
		setCtxProps({...click_props?.props, rows: items?.length, cols: cols?.length});
		show(event, click_props);
	};

	return (
		<div
			className="TableBody"
			{...handles}
		>
			<Droppable
				droppableId="TableBody"
				direction="vertical"
				isDragDisabled={props.isDragDisabled}
				isDropDisabled={false}
			>
				{
					(provided) => (
						<table
							style={{borderSpacing: cellSpacing}}
							ref={provided.innerRef}
						>
							<tbody>
								{
									items?.length ?
										items.map((a, index) => {
											if (props.maxRows && index >= props.maxRows)
												return (false);
											let is_selected = isSelected(a);
											if (props.onlySelected === true && !is_selected)
												return (false);
											let row_key = tableId + "-" + (itemId && a[itemId] ? a[itemId] : index);
											return (
												<TableRow
													// ref={provided.innerRef}
													{...provided.droppableProps}
													isSelected={is_selected !== false ? true : false}
													key={row_key}
													rowId={row_key}
													datas={a}
													cols={cols}
													maxCols={props.maxCols}
													onClick={() => onItemClick(a)}
													handleCheckbox={(e) => handleCheckbox(a, e)}
													checkbox={checkbox}
													isMouseDown={isMouseDown}
													isActive={a[itemId] === activeId}
													tableId={tableId}
													onCellChange={props.onCellChange}
													cellsProps={props.cellsProps}
													rowIndex={index}
													dragRows={!props.isDragDisabled}
													onCtxMenu={handleCtxMenu}
													ctxMenuProps={props.ctxMenuProps}
												/>
											);
										})
										:
										(
											<tr className="no-items">
												<td className="no-items-txt w-100 text-center">
													{t("Table.NO_CONTENT")}
												</td>
											</tr>
										)
								}
								{provided.placeholder}
							</tbody>
						</table>
					)
				}
			</Droppable>
			<TableCtxMenu id={"table-ctx-menu" + (props.tableId ? "-" + props.tableId : "")} {...props.ctxMenuProps} data={ctxProps}/>
		</div>
	);
};
DraggableTableBody.displayName = "DraggableTableBody";

export default DraggableTableBody;

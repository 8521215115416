import React from "react";
import { useQuery } from "react-query";
import { motion } from "framer-motion";
import genRequest from "includes/request";
import "./PostPreview.css";
import PostPreview from "./PostPreview";
// import PostMetaTags from "./components/MetaTags/PostMetaTags";

function AsyncPostPreview(props) {
	const retrievePost = () => {
		if (!props?.postId) return false;
		let req = genRequest("Posts/" + props.postId);
		return req;
	};

	/*
	 ** -- Render
	 */

	const { isLoading, data } = useQuery(
		["Posts", props?.postId],
		retrievePost,
		{
			enabled: props?.postId ? true : false,
		}
	);

	const anims = {
		hidden: {
			top: "100vh",
			opacity: 1,
		},
		show: {
			top: "0vh",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.5,
			},
		},
	};

	if (isLoading)
		return (
			<motion.div
				variants={anims}
				initial={props.firstShow ? "hidden" : "show"}
				animate="show"
				className={`post-preview-cont${
					props.isExtended ? " extended" : ""
				}`}
			>
				<div className="post-preview loading p-4">
					<div
						className="w-100 mb-4"
						style={{ height: "150px" }}
					></div>
					<div className="w-100"></div>
					<div className="w-50"></div>
					<div className="w-25"></div>
					<div className="w-35"></div>
				</div>
			</motion.div>
		);

	if (!data?.PostId) return false;

	return (
		<PostPreview
			{...props}
			async={false}
			post={data}
			asyncImages={true}
			firstShow={props.firstShow}
		/>
	);
}

export default AsyncPostPreview;

import React from "react";
import "./ProgressBar.css";

export default function ProgressBar({value, title}) {
	return (
		<div
			className="ProgressBar">
			<div
				className="progress-value"
				style={{
					width: value + "%"
				}}
			/>
		</div>
	)
}

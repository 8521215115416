import TableToolBar from "components/ScrollTable/components/TableToolBar/TableToolBar";
import promiseToast from "components/Toasts/PromiseToast";
import useSelect from "hooks/useSelect";
import { t } from "i18next";
import genRequest from "includes/request";
import React from "react";
import { useQueryClient } from "react-query";

export default function GroupsTableToolbar(props) {
	const {selected, setSelected} = props;
	const {confirmDelete} = useSelect();
	const QCL = useQueryClient();
	
	function handleDelete() {
		if (!selected?.length)
			return (false);
		let title = t("Groups.ASKDELETE", {
			count: selected?.length,
			name: selected[0].Name
		});
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selected.map((group) => genRequest("Groups?groupId=" + group.GroupId, null, "delete"));
		let prom = Promise.allSettled(proms).then(() => {
			QCL.resetQueries(["Groups", "OfCompany"]);
			setSelected([]);
		});
		promiseToast(prom, {pending: t("Commons.DELETING", {count: selected.length})});
		return (prom);
	}


	return (
		<TableToolBar
			selected={selected}
			setSelected={setSelected}
			handleDelete={handleDelete}
		/>
	)
}

import React from "react";
import Button from "components/Button/Button";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import "./StatsTopBar.css";
import { dateAdd } from "includes/formatDate";

export default function StatsTopBar(props) {
	const {setDates} = props;
	const {start, end} = props.dates;

	const sinceDeploy = () => {
		let date = dateAdd(new Date(), {days: -1});

		setDates({
			start: new Date(props.company.CreationDate),
			end: date,
		});
	};

	let isDeploy = (start.getTime() === new Date(props.company.CreationDate).getTime());

	return (
		<div className="d-flex StatsTopBar p-2 flex-column">
			<div className="d-flex mb-2">
				<div>
					<Button
						className={(isDeploy ? " btn-primary btn-h-grey" : " btn-grey btn-h-primary")}
						onClick={sinceDeploy}
						text={t("Analytics.SINCE_DEPLOY")}
					/>
				</div>
			</div>
			<div className="d-flex flex-wrap">
				<div className="me-2">
					<div>
						{t("Analytics.DATE_START")}
					</div>
					<StatsDatePicker
						className="date-picker"
						showTimeSelect={false}
						onChange={(val) => setDates({start: val, end})}
						date={start}
						selectsStart
						startDate={start}
						endDate={end}
					/>
				</div>
				<div>
					<div>
						{t("Analytics.DATE_END")}
					</div>
					<StatsDatePicker
						className="date-picker"
						showTimeSelect={false}
						onChange={(val) => setDates({start, end: val})}
						date={end}
						startDate={start}
						endDate={end}
						selectsEnd
						minDate={start}
						maxDate={dateAdd(new Date(), {days: -1})}
					/>
				</div>
			</div>
		</div>
	);
}

function StatsDatePicker(props) {
	return (
		<DatePicker
			selected={props.date}
			onChange={props.onChange}
			showTimeSelect={false}
			dateFormat={t("Events.DATE_INPUT_FORMAT")}
			locale={t("CodeMin")}
			className={"DatePicker " + (props.className)}
			{...props}
		/>
	);
}

import { useState } from "react";

function useMenuState(force_state) {
	const [isMenuOpen, setIsMenuOpen] = useState(typeof force_state !== "undefined" ? forceState(force_state) : getState());

	const changeState = (set_state) => {
		sessionStorage.setItem("MenuState", set_state);
		return (setIsMenuOpen(set_state));
	};

	return ({
		setIsMenuOpen: changeState,
		isMenuOpen
	});
}

const forceState = (state) => {
	sessionStorage.setItem("MenuState", state);
	return (state);
};

const getState = () => {
	let res = false;
	try {
		res = sessionStorage.getItem("MenuState");
		if (!res)
			return (false);
	} catch (e) {
		return (false);
	}
	return ((res === "true"));
};

export default useMenuState;

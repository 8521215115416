import React from "react";
import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import {useQueryClient} from "react-query";
import promiseToast from "components/Toasts/PromiseToast";
import { t } from "i18next";
import TableToolbar from "components/Table/TableToolbar/TableToolbar";

export default function ChartsTableToolbar(props) {
	const {selectedRows, clearSelection} = props;
	const {confirmDelete} = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		if (!selectedRows?.length)
			return (false);
		let title = t(
			"Charts.ASKDELETE",
			{
				count: selectedRows?.length,
				title: selectedRows[0].Title
			}
		)
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selectedRows.map((chart, x) => {
			let prom = false;
			if (chart?.ChartId)
				prom = new Promise((resolve, reject) => {
					setTimeout(() => {
						genRequest(
							"Charts?chartId=" + chart?.ChartId,
							null,
							"delete"
						).then((resp) => {
							resolve(resp);
						}, reject);
					}, 500 * x);
				});
			return (prom);
		});
		let prom = Promise.allSettled(proms).then(() => {
			QCL.resetQueries(["Charts"]);
			if (props.onDelete)
				props.onDelete();
			clearSelection();
		});
		promiseToast(
			prom,
			{
				pending: t("Charts.DELETING", {count: selectedRows?.length})
			}
		)
		return (prom);
	}

	return (
		<TableToolbar
			{...props}
			handleDelete={handleDelete}
		/>
	);
}

import React from "react";
import EditDrawer from "components/EditDrawer/EditDrawer";
import FormInput from "components/Inputs/FormInput";
import ReactionsBank from "./components/ReactionsBank/ReactionsBank";
import SendStep from "components/EditDrawer/SendStep/SendStep";
import { t } from "i18next";

export default function ReactionTypeEdit(props){
	const rtype = props.reaction;
	const steps = [
		{
			component: ReactionTypeIdentity,
			name: "identity",
			title: "Identité"
		},
		{
			component: RTypeSendStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("EditDrawer.SEND_STEP")
		},
		{
			component: RTypeEndStep,
			name: "preview",
			title: t("EditDrawer.END_STEP"),
			disableLink: true
		}
	];

	return (
		<EditDrawer
			steps={steps}
			object={props.reaction}
			onClose={props.onClose}
			isOpen={props.isOpen}
			title={t("Reactions", (rtype.reactionTypeId() ? "MODIFY_RTYPE" : "ADD_RTYPE"))}
		/>
	);
}

function ReactionTypeIdentity(props) {
	const reaction = props.object;

	return (
		<>
			<div className="inner-step-cont">
				<FormInput title={t("Table.NAME")} value={reaction.name()} onChange={reaction.name}/>
				<FormInput className="mt-3" title={t("Table.DESC")} value={reaction.description()} onChange={reaction.description}/>
			</div>
			<div className="inner-step-cont">
				<ReactionsBank max={5} selected={reaction.linkedReactions()} onSelect={(values) => reaction.linkedReactions(values)}/>
			</div>
		</>
	);
}

function RTypeSendStep(props) {
	const rType = props.object;

	if (!props.isActive)
		return (false);

	const queries = [
		{
			queryKey: ["SendReactionType", rType.name(), rType.lastChanged()],
			queryFn: () => rType.send(),
			title: t("Reactions.SEND_RTYPE"),
			required: true
		},
	];
	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

function RTypeEndStep() {
	return (<div>OK</div>);
}

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import "./UserCard.css";
import UserImage from "../Image/UserImage";
import genRequest from "includes/request";
import TagsList, { QueryTagsList } from "components/Tags/TagsList/TagsList";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import SaveUserBtn from "../SaveUserBtn/SaveUserBtn";
import { t } from "i18next";
import TagLabel from "components/Tags/Label/TagLabel";
import { dateAdd } from "includes/formatDate";
import useAccount from "classes/Accounts/hooks/useAccount";

function UserCard(props) {
	let user = props.user;
	const [hideContact, setHideContact] = useState(false);

	function handleClick() {
		if (props.onClick)
			props.onClick(user);
	}

	if (!user)
		return (false);

	const getSiteInfo = () => {
		return user.SiteName;
		// let town = user.siteInfo?.Town?.trim();
		// return (user.siteInfo?.Name + (town?.length ? (" - " + town) : ""));
	}
	return (
		<>
			{!hideContact && <div className={"user-card tile" + (props.className ? " " + props.className : "")} onClick={handleClick}>
				<div className="d-flex align-items-start justify-content-between mb-2">
					<div className="d-flex">
						<div className="me-2 position-relative">
							<div
								className={
									"user-image"
								}
								style={{
									backgroundImage: "url(https://files.onlyall.fr/" + user.AvatarUrl + ")",
								}}
							/>
							<OnlineStatus employeId={user?.EmployesId} lastConnexion={user?.LastConnexion} />
						</div>
						<div className="user-contacts d-flex flex-column justify-content-center">
							{
								user.Mail !== "" &&
								<div className="user-mail"><a href={"mailto:" + user.Mail}><FontAwesomeIcon className="user-contact-icon" icon={faEnvelope} /></a></div>
							}
							{
								user.Telephone !== "" &&
								<div className="d-flex align-items-center">
									<div className="user-whatsapp"><a href={"whatsapp:" + user.Telephone}><FontAwesomeIcon className="user-contact-icon" icon={faWhatsapp} /></a></div>
									<div className="user-phone"><a href={"tel:" + user.Telephone}><FontAwesomeIcon className="user-contact-icon" icon={faPhoneAlt} /></a></div>
								</div>
							}
						</div>
					</div>
					<div>
						<Role role={user?.Role} />
						<SaveUserBtn className={"unstyled ms-auto"} userIsSaved={user.saved} userId={user.EmployeId} />
					</div>
				</div>
				<div className="user-infos">
					<div className="user-site">
						{getSiteInfo()}
					</div>
					<div className="user-fullname font-bold">

						{user.hasOwnProperty("FirstName") ? user.FirstName.toLocaleLowerCase() + " " + user.LastName.toUpperCase() : user.EmployeFirstName.toLocaleLowerCase() + " " + user.EmployeLastName.toUpperCase()}
					</div>
					<div className="user-work-function mt-1">{user.WFU}</div>
					<div className="user-work-family">{user.WFY}</div>
				</div>
				{user.groups && user.groups.length > 0 &&
					<div className="pt-2 mt-auto overflow-auto TagsList d-flex flex-wrap gap-1">
						{user.groups.map((group, idx) =>
						(
							<TagLabel key={idx} {...group} />)
						)}
					</div>
				}
			</div>}
		</>
	);
}

export function OnlineStatus({ lastConnexion, delayInMinutes, employeId }) {
	const { account } = useAccount();

	if (!lastConnexion)
		return (false);
	let isOnline = new Date(lastConnexion) > dateAdd(new Date(), { minutes: (delayInMinutes ? (delayInMinutes * -1) : -5) });
	if (isOnline || employeId === account.EmployesId)
		return (
			<div
				className="OnlineStatus"
				title={t("Contacts.ONLINE")}
			/>
		);
	return (false);
}

function Role({ role }) {
	if (!role)
		return (false);
	let text = t("Contacts.USER_ROLE");
	let color = "grey";
	let isDev = false;

	if (["SiteAdmin", "SuperAdmin"].indexOf(role) !== -1) {
		text = t("Contacts.ADMIN_ROLE");
		color = "red";
	} else if (["EloAdmin"].indexOf(role) !== -1) {
		text = t("Contacts.ELOADMIN");
		isDev = true;
	} else
		return (false);
	return (
		<TagLabel className={"user-role" + (isDev ? " rainbow" : "")} color={color} text={text} />
	);
}

export function AsyncUserCard({ employeId }) {
	const { data, isLoading } = useQuery(
		["Employes/MinimalContactEmployesWithSiteFromId", employeId],
		() => genRequest("Employes/MinimalContactEmployesWithSiteFromId/" + employeId),
		{
			enabled: employeId ? true : false
		}
	);

	return (
		<UserCard user={data} isLoading={isLoading} />
	);
}

export default UserCard;

import React, { useState } from "react";
import ReactModal from "react-modal";

import "./CGU.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";

/**
* CGU Component
*
* @component
* @example
*
* return (
*	<CGU
*		handleAccept={<callback function on accept CGU>}
*		handleReject={<callback function on reject CGU>}
*	/>
* )
*/

function CGU(props) {

	const { getSettings, account } = useAccount();
	function getNavigatorLanguage() {
		let lang = navigator.language || navigator.userLanguage;

		if (account) {
			let from_settings = getSettings("Language");
			if (from_settings) return from_settings;
		}
		let from_local = localStorage.getItem("lang-code");
		if (from_local) return from_local;
		return lang;
	}

	const lang = getNavigatorLanguage();


	function handleReject() {
		if (props.handleReject)
			props.handleReject();
	}

	function handleAccept() {
		if (props.handleAccept)
			props.handleAccept();
	}

	return (
		<>
			{lang.includes('fr') ?
				<div className="cgu-cont">
					<div className="cgu-wrapper">
						<h2>Les Conditions Générales d’Utilisation de l’application Only All</h2><br />
						Les Conditions Générales d’Utilisation (“CGU”) stipulées dans le présent document régissent l’utilisation et
						tout accès à l’application Only All développée par Joonsoft (ci-après dénommée l’ « Application ») par
						l’Utilisateur qui devra se conformer à ces modalités.<br />
						<br />
						En accédant et en utilisant l’Application, l’Utilisateur confirme avoir pris connaissance et avoir accepté
						expressément les présentes CGU en vigueur au jour de l’accès à l’Application. <br />
						Joonsoft se réserve la possibilité de modifier, à tout moment, tout ou partie des CGU. Il appartient donc à
						l’Utilisateur de consulter régulièrement les CGU. Toute utilisation de l’Application après modification des CGU
						vaut acceptation pure et simple par l’Utilisateur des nouvelles CGU.<br />
						<br />
						L’Utilisateur confirme être une personne physique âgée d’au moins dix-huit ans. Il dispose de la pleine capacité
						juridique pour conclure les présentes CGU, et devenir un utilisateur de l’Application.<br />
						<br />
						Si une licence d’utilisation de l’Application a été achetée par l’intermédiaire d’un partenaire de Joonsoft,
						l’Utilisateur devra accepter de se conformer aux termes de l’accord conclu avec le partenaire. En cas
						d’incompatibilité entre les présentes conditions et l’un quelconque des accords passé avec le partenaire, ces
						CGU prévaudront.<br />
						<br />
						Si l’Utilisateur n’accepte pas les présentes CGU, il renonce à utiliser ou à accéder à l’Application.<br />
						<br />
						L’Utilisateur ne pourra commencer à utiliser l’Application qu’une fois que la requête d’utilisation de
						l’Application aura été demandée par l’Entreprise cliente et acceptée par Joonsoft et que cette dernière aura
						accordé à l’Utilisateur des droits d’accès à l’Application.<br />
						<br />
						<br />
						<h4>1. Définitions</h4>
						Dans le cadre de ces CGU, ces termes ont les significations suivantes :<br /><br />
						<b>“Abonnement”</b> désigne la souscription, par l’Entreprise cliente, permettant de bénéficier des Services, de
						l’accès à et l’utilisation de l’Application pour le nombre d’Utilisateurs qui aura été indiqué à Joonsoft ;<br />
						<b>“Application”</b> désigne le logiciel en ligne pour lequel un Abonnement a été souscrit par l’Entreprise
						cliente et décrit sur la page produit de onlyall.fr (site mis à jour périodiquement par Joonsoft);<br />
						<b>“Documentation”</b> désigne le manuel d’instructions, le guide d’utilisateur /ou toute autre documentation
						connexe (quel qu’en soit le support) portant sur l’Application et modifiable par Joonsoft à tout moment ;<br />
						<b>“Durée”</b> désigne la période durant laquelle l’Utilisateur peut accéder à et utiliser l’Application en
						fonction de l’Abonnement souscrit par l’Entreprise cliente auprès de Joonsoft ;<br />
						<b>“Entreprise cliente”</b> désigne toute entité ayant souscrit un Abonnement auprès de Joonsoft aux fins de
						mettre l’Application à disposition des Utilisateurs . <br />
						<b>“Logiciel Tierce Partie”</b> désigne tout logiciel appartenant à un tiers qui est intégré ou interagit avec
						l’Application ;<br />
						<b>“Joonsoft”</b> désigne l’entreprise Joonsoft, société par actions simplifiée, au capital de 30 000 euros,
						immatriculée au RCS de Lyon sous le numéro 851 933 895, ayant son siège social au 30 rue Sergent Blandan, 69001
						Lyon, France qui met à disposition de l’Entreprise cliente et des Utilisateurs l’Application et qui en détient
						l’entière propriété intellectuelle et auprès de qui il a été souscrit un Abonnement ;<br />
						<b>“Services”</b> désigne les services fournis par Joonsoft à l’Entreprise cliente et/ou l’Utilisateur , pendant
						la Durée et selon les termes des présentes CGU, à savoir l’accès à et l’utilisation de l’Application et de la
						Documentation uniquement pour un usage professionnel;<br />
						<b>“Utilisateur(s)”</b> désigne les personnes physiques autorisées à utiliser l’Application au titre de
						l’Abonnement auxquelles un identifiant utilisateur et un mot de passe ont été attribués et qui s’engagent à
						respecter les termes et conditions des présentes CGU ;<br />
						<br />
						<br />
						<h4>2. Accès à l’Application</h4>

						En application des présentes CGU, Joonsoft concède à l’Utilisateur un droit non-exclusif, non transmissible, non
						cessible d’utilisation de l’Application aux seules fins de l’utiliser conformément aux CGU.<br />
						<br />
						<ol>
							<li>Les conditions d’octroi de l’accès à l’Application par l’Utilisateur notamment concernant la Durée sont
								régies par l’Abonnement conclu par Joonsoft avec l’Entreprise cliente. A ce titre, l’accès à
								l’Application est conditionné par le respect des termes de l’Abonnement et des présentes CGU. En toute
								hypothèse, l’accès à l’Application dont bénéficie l’Utilisateur doit se faire exclusivement dans le
								cadre de son activité professionnelle.</li>
							<br />
							<li>L’Utilisateur certifie l’exactitude des informations qu’il renseigne. Toute fausse information,
								notamment en vue d'usurper l'identité d'un tiers, est de nature à entraîner la suppression définitive du
								compte sans aucune contrepartie de quelque nature que ce soit. En cas de changement, l’Utilisateur est
								tenu de mettre à jour les informations qu’il renseigne.</li>
							<br />
							<li>L’accès à l’Application se fait à l’aide d’un identifiant et d’un mot de passe. L’Utilisateur s’engage à
								conserver ce mot de passe confidentiel. Par conséquent, l’Utilisateur convient qu’il est entièrement
								responsable de l’utilisation qui pourra être faite de son mot de passe et se porte garant du respect de
								son caractère confidentiel, et par suite, de toute utilisation qui pourra être faite de son compte. En
								cas de soupçon d’utilisation non autorisée de son mot de passe par un tiers, l’Utilisateur s’engage à en
								informer Joonsoft dans les plus brefs délais et à lui fournir toute l’assistance que Joonsoft pourrait
								raisonnablement demander afin de prévenir tout accès non autorisé.</li>
							<br />
							<li>L’Utilisateur reconnaît que Joonsoft peut mettre à jour l’Application de temps à autre à des fins de
								correction d’erreur, d’amélioration des fonctionnalités ou autre, de temps à autre, à sa discrétion.
							</li>
						</ol>
						<br /><br />
						<h4>3. Obligations de l’Utilisateur</h4>
						1. L’Utilisateur s’engage à n’utiliser l’Application que pour son usage professionnel et à ne pas, de manière
						générale, se livrer à des actes, de quelques nature que ce soit, qui auraient pour effet de porter atteinte à
						l’ordre public, aux lois et règlements en vigueur, aux droits de Joonsoft, de l’Entreprise cliente, des autres
						Utilisateurs ou encore de tiers.<br />
						<br />
						En particulier, l’Utilisateur s’engage notamment à :<br />
						<br />
						<ul>
							<li>respecter toutes les lois et réglementations applicables et notamment le règlement intérieur de
								l’Entreprise cliente ou l’éventuel accord collectif lors de l’utilisation de l’Application;<br /></li>
							<li>utiliser l’Application dans le respect des présentes CGU et de manière loyale ;<br /></li>
							<li>obtenir et maintenir à jour les versions de l’Application, ainsi que celles de tout Logiciel Tierce
								Partie nécessaires à l’utilisation de l’Application ;<br /></li>
							<li>déclarer des informations complètes et exactes lors de son inscription, et à les mettre à jour en cas de
								changement.<br /></li>
						</ul>
						<br />
						2. L’Utilisateur ne doit pas :<br />
						<ul>
							<li>utiliser l’Application en violation de toute loi locale, nationale ou internationale, réglementation ou
								code de bonnes pratiques qui soit applicable, </li>
							<li>utiliser l’Application à des fins illégales ou frauduleuses,</li>
							<li>utiliser l’Application de façon à entraver le bon fonctionnement et la sécurité du réseau informatique
								de l’Entreprise cliente ;</li>
							<li>utiliser l’Application de façon à entrainer des conséquences préjudiciables à la bonne marche de
								l’Entreprise cliente ; </li>
							<li>lorsque cette fonctionnalité est ouverte, utiliser l’Application sans respecter le choix des autres
								Utilisateur d’accepter ou de refuser un message ; </li>
							<li>porter atteinte aux droits de tiers (autres Utilisateurs, Entreprise cliente, tiers) ou aux droits de
								Joonsoft, et notamment aux droits de propriété intellectuelle, tels que les droits d'auteur, droit des
								bases de données, de marque ou de brevet, ou aux droits de la personnalité, tels que le droit à la vie
								privée, à l'image ou encore à l'honneur ou à la dignité ;</li>
							<li>utiliser l’Application pour envoyer ou préparer l’envoi de toute publicité, tout matériel promotionnel
								ou autre forme de sollicitation à quiconque sauf si cela est autorisé au sein de l’Application ;</li>
							<li>utiliser ses mots de passe et/ou identifiant à des fins autres que leur stricte finalité
								d’authentification. A cet égard, l’Utilisateur ne doit pas, sans que cette liste ne soit limitative,
								communiquer, diffuser, partager, rendre accessible, de quelque façon que ce soit, ses éléments
								d’identification aux tiers ;</li>
							<li>usurper l’identité d’un tiers, ni tenter de se connecter à un compte autre que le sien ;</li>
							<li>extraire des données figurant dans l’Application afin d'en faire une réutilisation non autorisée;</li>
							<li>diffuser des messages à caractère violent ou de nature à porter gravement atteinte à la dignité humaine
								ou tout contenu qui pourrait être considéré comme une incitation à la réalisation de crimes et délits,
								la diffusion de messages insultants, injurieux, dénigrants, diffamatoires, racistes, révisionnistes,
								faisant l'apologie des crimes de guerre, pédophiles, relatifs à la vente d'organes, aux ventes de
								substances illicites ou de tout autre objet et/ou prestation illicites, faisant l'apologie du
								terrorisme, appelant au meurtre ou incitant au suicide, ayant un caractère pornographique ou pédophile,
								portant atteinte à la sécurité ou à l'intégrité d'un Etat ou d'un territoire, quel qu'il soit, sans que
								cette liste ne soit limitative ;</li>
							<li>utiliser l’Application aux fins de publicité en faveur de société tierces concurrentes de Joonsoft ;
							</li>
							<li>harceler, agresser ou provoquer de quelque manière que ce soit Joonsoft, l’Entreprise cliente, un autre
								Utilisateur ou un tiers. </li>
						</ul>
						<br />
						En outre, l’Utilisateur s'engage à ne pas porter atteinte ou essayer de porter atteinte à l'intégrité ou à la
						sécurité de l’Application. A ce titre, l’Utilisateur s’interdit notamment de :<br />
						<br />
						<ul>
							<li>attaquer l’Application via un déni de service ou déni de service distribué ;<br /></li>
							<li>télécharger, afficher, transmettre de quelque manière que ce soit tout contenu comprenant des virus
								informatiques, vers, chevaux de Troie, codes ou scripts susceptibles de nuire à l'intégrité ou à la
								confidentialité des systèmes et des données de Joonsoft, de l’Entreprise cliente, des autres
								Utilisateurs ou de tous tiers <br /></li>
							<li>tenter d’accéder sans autorisation, d’interférer, d’endommager, de modifier ou de perturber n’importe
								quelle partie de l’Application ou du système informatique de Joonsoft ;<br /></li>
							<li>sauf dispositions contraires prévues dans ces CGU ou autorisation par toute loi applicable qui ne peut
								être exclue, tenter de décompiler ou d’analyser par ingénierie inverse tout ou partie de l’Application,
								ou copier, modifier, adapter, transmettre ou distribuer tout ou partie de l’Application et/ou de la
								Documentation quel qu’en soit le support ;<br /></li>
							<li>accéder à toute partie de l’Application dans le but de développer un produit ou service concurrent à
								l’Application ; ou<br /></li>
							<li>proposer une licence, vendre, louer, transférer, assigner, distribuer, dévoiler, ou effectuer n’importe
								quelle autre exploitation commerciale, ou rendre l’Application disponible à n’importe quelle tierce
								personne n’étant pas inscrite comme Utilisateur.<br /></li>
						</ul>
						<br />
						3. Dans le cas où Joonsoft constate ou prend connaissance d’un quelconque manquement de l’Utilisateur à l’une
						des règles définies ci-avant, Joonsoft se réserve le droit, sans que cela n’engage sa responsabilité, de
						suspendre ou de supprimer le droit d’accès à l’Application conformément à l’article 8.2 ci-après..<br />
						<br />
						4. En outre, les contenus mis en ligne par les Utilisateurs est constituant un manquement aux règles définies
						ci-avant pourront être bloqués ou supprimés par Joonsoft. <br />
						<br /><br />
						<h4>4. Service d’une tierce partie</h4>
						1. L’Utilisateur reconnaît que les Services peuvent lui permettre d’accéder aux services ou contenus de sites
						Internet de tierces parties et qu’il agit dans ce cas à ses risques et périls. <br />
						<br />
						2.Joonsoft n’est pas gestionnaire de ces sites et Logiciels Terces Parties et ne peut en contrôler le contenu.
						En conséquence, Joonsoft ne peut en aucun cas être tenue pour responsable :<br />
						<ul>
							<li>Du contenu et des services mis à disposition sur ces Logiciels Tierces Parties et/ou sites tiers, et/ou
								<br /></li>
							<li>Des éventuelles collectes et transmissions de données, installation de cookies ou tout autre procédé
								tendant aux mêmes fins, effectués par ces sites ou applications, et/ou <br /></li>
							<li>Des problèmes de sécurité ou d'intégrité des données, matériels et logiciels que l’Utilisateur pourrait
								rencontrer ainsi que de toute conséquence ou dommage qui pourrait résulter du fait de l'utilisation de
								ces applications et/ou sites tiers.<br /></li>
						</ul>
						3. Joonsoft n’est pas tenue de servir d’hôte pour les Logiciels Tierces Parties et ne pourra en aucun cas être
						tenue responsable de toute indisponibilité ou dysfonctionnement de ces logiciels.<br />
						<br /><br />
						<h4>5. Propriété intellectuelle</h4>
						1. L’Application (et toute modification qui lui est apportée), la Documentation et tous les droits de Propriété
						Intellectuelle sont, et resteront, la propriété exclusive de Joonsoft. <br />
						<br />
						2. A l’exception du droit d’utilisation de l’Application accordé conformément à l’article 2.1 des présentes CGU,
						l’Utilisateur ne dispose d’aucun droit sur la Propriété Intellectuelle ou quelconque autre droit portant sur
						l’Application.<br />
						<br />3. Les contenus composant l’Application et les droits associés sont la propriété de Joonsoft ou de
						l’Entreprise cliente, notamment, sans que cette liste soit limitative, les marques, designs, apparences,
						structure, concepts, solutions techniques et graphiques, bases de données, logiciels et leurs codes sources,
						ainsi que l’agencement et la mise en forme de ces éléments, composant l’Application, et/ou étant à la base du
						fonctionnement de l’Application et/ou transitant par l’Application, et/ou mis à disposition par le biais de
						l’Application. <br />
						<br />4. Toute reproduction, représentation, adaptation, modification, publication, transmission, dénaturation,
						intégration dans un autre site, exploitation commerciale et/ou réutilisation totale ou partielle de
						l’Application sera considérée comme un acte de contrefaçon sanctionné par le Code de la Propriété
						Intellectuelle.<br />
						<br />5. L’Utilisateur concède à Joonsoft à titre non-exclusif sur les contenus mis en ligne sur l’Application,
						les droits d’auteur suivants :<br />
						<ul>
							<li>Le droit de reproduire, modifier, adapter, représenter et traduire tout ou partie des contenus sur tout
								support d'enregistrement, actuel ou futur, et notamment, sur tout serveur ou disque dur, en tout format
								et par tout procédé ou technologie connu(e) ou inconnu(e) à ce jour, et par tout type de medium de
								communication, notamment internet y compris les sites de tiers, intranet, ou support papiers ;<br /></li>
							<li>Le droit de concéder, à titre onéreux ou gratuit, des sous-licences des droits concédés au titre de la
								licence ci-dessus mentionnée à tout tiers de notre choix, dans le cadre des opérations mentionnées
								ci-dessous.<br /></li>
						</ul>
						Ces droits sont concédés à Joonsoft aux seules fin de la bonne exploitation de l’Application, pour toute , par
						tout procédé, et sur tout support, est strictement interdite et pourrait notamment constituer la durée légale
						des droits d’auteur applicable à ce jour et dans l’avenir et pour le monde entier. <br />
						<br /><br />
						<h4>6. Responsabilités</h4>
						1. Joonsoft ne donne aucune garantie sur le contenu de l’Application, lequel relève de la seule responsabilité
						de l’Entreprise cliente. En outre, Joonsoft ne garantit pas que l’Application est exempte d’anomalies, d’erreurs
						ou de bugs, ni que ceux-ci pourront être corrigés. <br />
						2. Joonsoft assure la disponibilité et la continuité de l’Application, étant entendu qu’elle ne saurait en aucun
						cas être tenue responsable de tout dysfonctionnement de l’Application pour cause de maintenance, de problèmes
						techniques, d’encombrement du réseau Internet ou de défaillance de tout autre système de télétransmission, qui
						pourraient empêcher le fonctionnement de l’Application.<br />
						3. Joonsoft ne garantit pas la continuité et la qualité des liaisons de communications avec l’Utilisateur.
						Ainsi, l’accès à l’Application peut être interrompu sans préavis pour une durée raisonnable, notamment pour des
						raisons de maintenance ou pour tout fait.<br />
						4. Joonsoft ne saurait être tenue responsable en cas d’une mauvaise exécution des Services causée par des
						informations mal renseignées ou qui n’auraient pas été mises à jour par l’Utilisateur et/ou l’Entreprise
						cliente.<br />
						E5. n cas de mise en jeu de la responsabilité de Joonsoft, en raison notamment d’un mauvais fonctionnement de
						l’Application, Joonsoft pourra faire échec à une telle action, à sa seule discrétion, en corrigeant l’anomalie
						ou en mettant à jour l’Application.<br />
						<br /><br />
						<h4>7. Durée et résiliation</h4>
						1. Les CGU prennent effet à la date où l’Utilisateur accepte les présentes CGU et, sauf résiliation anticipée
						selon les modalités des présentes CGU, restent valides pour toute la Durée de l’utilisation de l’Application par
						l’Utilisateur, laquelle Durée dépend de l’Abonnement souscrit par l’Entreprise cliente. <br />
						2. Joonsoft pourra suspendre ou résilier de plein droit l’accès à et l’utilisation de l’Application en cas de
						manquement de l’Utilisateur à à tout ou partie des obligations mentionnées dans les CGU. Cette résiliation
						interviendra avec effet immédiat. Dans ce cas, l’Utilisateur sera informé de la résiliation de son inscription
						par email et son accès à l’Application sera bloqué, sans droit à une quelconque indemnité.<br />
						3. À l’échéance ou résiliation des présentes conditions :<br />
						- la licence et les droits accordés aux Utilisateurs dans le cadre des présentes CGU cesseront immédiatement et
						Joonsoft supprimera immédiatement les accès et les droits d’utilisation de l’Application par l’Utilisateur ;<br />
						- l’Utilisateur devra cesser d’utiliser l’Application et devra rendre toute Documentation (et toutes copies
						existantes), appartenant à Joonsoft.<br />
						<br /><br />
						<h4>8. Force majeure</h4>
						Joonsoft se dégage de toute responsabilité vis-à-vis de l’Utilisateur en cas de Force Majeure. De façon
						expresse, sont considérés comme cas de force majeure ou cas fortuits, ceux définis à l’article 1218 du Code
						civil tel qu’interprété par la jurisprudence des cours et tribunaux français, tels que les agissements,
						omissions ou tout événement indépendant de sa volonté, de défaillance d’un fournisseur de services ou du réseau
						de transport, de toute défaillance ou interruption du réseau de télécommunication, d’attaque de déni de service
						distribué ou autre attaque malicieuse, de catastrophe naturelle, de guerre, émeute, détérioration malveillante,
						d’inondation, d’ouragan.<br />
						<br /><br />
						<h4>9. Modification de la Loi</h4>
						En cas d’évolution de la réglementation applicable aux Services proposés par Joonsoft, cette dernière se réserve
						le droit de modifier n’importe quelle disposition de ces CGU sous réserve d’en notifier l’Utilisateur moyennant
						un préavis écrit de trente (30) jours.<br />
						<br /><br />
						<h4>10. Général</h4>
						1. Toutes les clauses des CGU sont distinctes. Si une clause est déclarée nulle ou illégale, toutes les autres
						clauses demeureront valides et continueront de lier les Parties.<br />
						2. Aucune disposition des présentes CGU n’est destinée à, ou sous-entend, créer un partenariat entre les
						Parties, autoriser l’une ou l’autre des Parties à agir en tant qu’agent pour l’autre, attribuer à l’une ou
						l’autre des Parties l’autorité d’agir au nom et pour le compte de l’autre partie ou d’engager l’autre partie
						d’une quelconque façon que ce soit.<br />
						<br /><br />
						<h4>11. Droit applicable et tribunaux compétents</h4>
						1. Les présentes CGU et tout litige ou réclamation qui en découlent (y compris les différends et plaintes
						contractuelles) seront régis par le droit français.<br />
						2. Toute interprétation, contestation ou revendication les concernant et relative notamment à leur validité,
						leur exécution, leur interprétation ou leur rupture, sera soumise, en cas d’impossibilité pour les parties de
						trouver un accord amiable à l’expiration d’un délai d’un (1) mois à compter de la demande écrite de la Partie la
						plus diligente au tribunal de commerce de Lyon, nonobstant pluralité de parties à l’instance, appel en garantie,
						y compris pour les procédures d’urgence, les procédures conservatoires, en référé ou par requête. <br />
					</div>
					<div className="d-flex mt-3">
						<div className="btn btn-red me-2" onClick={handleReject}>Refuser</div>
						<div className="btn btn-green" onClick={handleAccept}>Accepter</div>
					</div>
				</div> :
				<div className="cgu-cont">
					<div className="cgu-wrapper">
						<h2>STANDARD TERMS OF
							USE FOR THE ONLYALL APPLICATION</h2><br />
						The General Conditions of Use (“GCU”) stipulated in this document govern the use and any
						access to the Onlyall application developed by Joon (hereinafter referred to as
						“application”) by the user who will have to comply to these modalities.<br />
						<br />
						By accessing and using the application, the user confirms having read and expressly
						accepted these GCU in force on the day of access to the application. <br />
						Joon reserves the right to modify, at any time, all or part of the GCU. It is therefore up to the
						user to consult the T and Cs regularly. Any use of the application after changing the
						GCUs implies outright acceptance by the user of the new GCUs.<br />
						<br />
						The user confirms being a natural person aged at least eighteen. It has full legal capacity to
						conclude these GCU, and become a user of the application.<br />
						<br />
						If a license to use the application has been purchased through a Joon partner, the user must
						agree to comply with the terms of the agreement concluded with the partner. In the
						event of incompatibility between these conditions and any of the agreements made with
						the partner, these GCUs will prevail.<br />
						<br />
						If the user does not accept these GCUs, he gives up using or accessing the application.<br />
						<br />
						The user can only start using the application once the application request for the application
						has been requested by the client company and accepted by Joon and the latter will
						have granted the User of the Rights of ‘Access to the application.<br />
						<br />
						<br />
						<h4>1. Definitions</h4>
						As part of these CGUs, these terms have the following meanings:<br /><br />
						<b>“Subscription”</b> means the subscription, by the client company, allowing to benefit from
						services, access to and use of the application for the number of users who have been
						indicated in Joon;<br />
						<b>“Application”</b> means the online software for which a subscription has been subscribed by
						the client company and described on the product page of www.onlyall.fr (site updated
						periodically by Joon);<br />
						<b>“Documentation”</b> means the instructions manual, the user guide /or any other related
						documentation (whatever the medium) relating to the application and modifiable by Joon
						at any time;<br />
						<b>“Duration”</b> means the period during which the user can access and use the application
						according to the subscription subscribed by the client company with Joon; <br />
						<b>“Client company”</b> means any entity that has subscribed to a subscription to JOON for the purpose
						of making the application available to users. <br />
						<b>“Tierce Party software”</b> means any software belonging to a third party which is integrated or
						interacts with the application;<br />
						<b>“Joon”</b> designates the company Joon, a simplified joint -stock company, with a capital of
						30,000 euros, registered in the RCS of Lyon under number 851 933 895, having its
						registered office at 30 rue Sergent Blandan, 69001 LYON, France which puts provision of the
						client company and users the application and which has full intellectual property
						and with whom a subscription has been subscribed;<br />
						<b>“Services”</b> designates the services provided by Joon to the client company and/or the user,
						during the duration and in the terms of these T and Cs, namely access to and the use of
						the application and documentation only for professional use;<br />
						<b>“User (s)”</b> means natural persons authorized to use the application under the subscription to
						which a user identifier and a password have been assigned and which undertake to
						respect the terms and conditions of these T and Cs.<br />
						<br />
						<br />
						<h4>2. ACCESS TO THE APPLICATION</h4>

						Pursuant to these T and Cs, Joon concedes to the user a non-exclusive, non-transferable,
						non-transferable right to use the application for the sole purpose of using it in accordance
						with the GCU.<br />
						<br />
						<ol>
							<li>The conditions for granting access to the application by the user, in particular
								concerning the duration are governed by the subscription concluded by Joon with the client
								company. As such, access to the application is conditioned by respecting the terms of the
								subscription and these GCU. In any event, access to the application from which the
								user benefits must be done exclusively within the framework of his professional activity.</li>
							<br />
							<li>The user certifies the accuracy of the information he provides. Any false information, in
								particular with a view to usurping the identity of a third party, is likely to lead to the
								definitive deletion of the account without any consideration of any kind. In the event of a
								change, the user is required to update the information he provides.</li>
							<br />
							<li>Access to the application is using an identifier and a password. The user undertakes to
								keep this confidential password. Consequently, the user agrees that it is entirely
								responsible for the use which may be made of their password and guarantees compliance
								with its confidential character, and consequently, of any use which can be made of its
								account. In the event of a suspicion of unauthorized use of his password by a third party, the
								user undertakes to inform Joon as soon as possible and to provide him with all the
								assistance that Joon could reasonably ask in order to warn everything unauthorized access.</li>
							<br />
							<li>The user acknowledges that Joon can update the application from time to error
								correction, improvement of functionalities or other, from time to time, at its discretion.
							</li>
						</ol>
						<br /><br />
						<h4>3. USER OBLIGATIONS</h4>
						1. The user undertakes to use the application only for their professional use and not to be of
						acts, in general, of any kind what would be involved in the effect of undermining Public
						order, to the laws and regulations in force, to the rights of Joon, the client company, other
						users or third parties.<br />
						<br />
						In particular, the user undertakes in particular to:<br />
						<br />
						<ul>
							<li>comply with all applicable laws and regulations and in particular the internal regulations of
								the client company or the possible collective agreement when using the application;<br /></li>
							<li>Use the application while respecting these GCUs and in a loyal way;<br /></li>
							<li>Obtain and keep the application versions up to date, as well as those of any third party
								software necessary for the use of the application;<br /></li>
							<li>Declare complete and accurate information when registering, and update it in the event of
								a change.<br /></li>
						</ul>
						<br />
						2. The user should not:<br />
						<ul>
							<li>Use the application in violation of any local, national or international law, regulation or
								code of good practices that are applicable, </li>
							<li>Use the application for illegal or fraudulent purposes,</li>
							<li>Use the application to hinder the proper functioning and security of the client
								company’s computer network;</li>
							<li>Use the application to cause consequences detrimental to the smooth running of the
								client company; </li>
							<li>When this feature is open, use the application without respecting the choice of other
								user to accept or refuse a message; </li>
							<li>undermine the rights of third parties (other users, client company, third parties) or the
								rights of Joon, and in particular intellectual property rights, such as copyright, database
								law, brand or patent, or personality rights, such as the right to privacy, image or honor or
								dignity;</li>
							<li>Use the application to send or prepare the sending of any advertising, any promotional
								material or other form of solicitation to anyone unless it is authorized within the
								application;</li>
							<li>Use your passwords and/or identifier for purposes other than their strict authentication
								purpose. In this regard, the user must not, without this list being limited, communicate,
								distribute, share, make accessible, in any way whatsoever, its elements of identification with
								third parties;</li>
							<li>Usurp the identity of a third party, nor try to connect to an account other than its own;</li>
							<li>Extract data in the application in order to make it unauthorized reuse;</li>
							<li>isseminate messages of a violent nature or likely to seriously affect human dignity or
								any content which could be considered as an incentive to carry out crimes and crimes,
								the dissemination of insulting, abusive, disparage, defamatory, racist, racist messages,
								racist, racist, racist, racist, racist, racist revisionists, praising war crimes, pedophiles,
								relating to the sale of organs, sales of illicit substances or any other object and/or illicit
								service, praising terrorism, calling for murder or inciting suicide, having a pornographic
								or pedophile character, affecting the security or integrity of a state or a territory, whatever
								it is, without this
								list is not limited;</li>
							<li>Use the application for advertising purposes in favor of the competing thirds of Joon;
							</li>
							<li>harass, attack or cause any way Joon, the client company, another user or a third party.
							</li>
						</ul>
						<br />
						In addition, the user undertakes not to undermine or try to infringe the integrity or safety of
						the application. As such, the user notably refrains from:<br />
						<br />
						<ul>
							<li>Attack the application via a denial of service or distributed service;<br /></li>
							<li>Download, display, transmit in any way any content comprising computer viruses,
								towards, Trojan horses, codes or scripts likely to harm the integrity or confidentiality of
								Joon
								systems and data, Client company, other users or all third party <br /></li>
							<li>Try to access without authorization, interfere, damage, modify or disturb any part of the
								application or the computer system of Joon;<br /></li>
							<li>Unless otherwise provided in these CGUs or authorization by any applicable law which
								cannot be excluded, try to decompile or analyze by reverse engineering all or part of the
								application, or copy, modify, adapt, transmit or distribute all or part of the application
								and/or documentation whatever the medium;<br /></li>
							<li>Access any part of the application in order to develop a product or service competing
								with the application; Or<br /></li>
							<li>Propose a license, sell, rent, transfer, assign, distribute, reveal, or perform any other
								commercial operation, or make the application available to any third person not being
								registered as a user.<br /></li>
						</ul>
						<br />
						3. In the event that Joon notes or becomes aware of any breach of the user in one of the
						rules defined above, Joon reserves the right, without it engaging his responsibility, to
						suspend or to Remove the right of access to the application in accordance with article 8.2
						below.<br />
						<br />
						4. In addition, the content put online by users is a breach of the rules defined above may
						be blocked or deleted by Joon. <br />
						<br /><br />
						<h4>4. SERVICE OF A THIRD PARTY</h4>
						1. The User acknowledges that the services can allow him to access the services or content
						of third parties websites and that he acts in this case at his own risk. <br />
						<br />
						2.Joon is not manager of these third party sites and software and cannot control its
						content. Consequently, Joon can in no way be held responsible:<br />
						<ul>
							<li>Content and services made available on these third parties and/or third -party sites,
								and/or
								<br /></li>
							<li>possible data collection and transmissions, installation of cookies or any other process
								tending to the same purposes, carried out by these sites or applications, and/or <br /></li>
							<li>Safety or integrity problems of data, hardware and software that the user could
								encounter as well as any consequence or damage that could result from the use of these applications and/or third -party sites.<br /></li>
						</ul>
						3. Joon is not required to serve as a host for third parties and may not be held responsible
						for any unavailability or malfunction of these software.<br />
						<br /><br />
						<h4>5. INTELLECTUAL PROPERTY</h4>
						1. The application (and any modification made to it), the documentation and all intellectual
						property rights are, and will remain, the exclusive property of Joon. <br />
						<br />
						2. With the exception of the right of use of the application granted in accordance with
						article 2.1 of these GCUs, the User has no rights on intellectual property or any other right
						on the application.<br />
						<br />3. Contents making up the application and associated rights are the property of Joon or the
						client company, in particular, without this list being limiting, brands, designs,
						appearances, structure, concepts, technical and graphic solutions, bases of data, software
						and their sources codes, as well as the layout and formatting of these elements,
						composing the application, and/or being the basis of the operation of the application and/or
						transitting by the application, and/or made available through the application. <br />
						<br />4. Any reproduction, representation, adaptation, modification, publication, transmission,
						denaturation, integration into another site, commercial exploitation and/or total or
						partial reuse of the application will be considered as an act of counterfeiting sanctioned by
						the code of ownership Intellectual.<br />
						<br />5. The user concedes to Joon in a non-exclusive basis on the content put online on the
						application, the following copyrights:<br />
						<ul>
							<li>The right to reproduce, modify, adapt, represent and translate all or part of the content
								on any recording medium, current or future, and in particular, on any server or hard
								drive, in any format and by any known process or technology ( e) or unknown to date, and
								by any type of communication medium, in particular the Internet including third -party
								sites, intranet, or paper support;<br /></li>
							<li>The right to concede, for a fee or free, the subsidians of the rights granted under the
								license above mentioned to any third party of our choice, within the framework of the
								operations mentioned below.<br /></li>
						</ul>
						These rights are conceded to Joon to the only end of the proper exploitation of the
						application, by any process and on any medium, is strictly prohibited and could in particular
						constitute the legal duration of copyright applicable to date and in the future And for the
						whole world. <br />
						<br /><br />
						<h4>6. RESPONSIBILITIES</h4>
						1. Joon gives no guarantee on the content of the application, which is the sole responsibility
						of the client company. In addition, Joon does not guarantee that the application is
						free from anomalies, errors or bugs, or that they can be corrected. <br />
						2. Joon ensures the availability and continuity of the application, it being understood that it
						cannot in any case be held responsible for any malfunction of the application due
						to maintenance, technical problems, congestion of the Internet or Internet network or
						failure of any other teletransmission system, which could prevent the operation of the
						application.<br />
						3. Joon does not guarantee the continuity and quality of communications with the user.
						Thus, access to the application can be interrupted without notice for a reasonable
						period, especially for maintenance reasons or for any fact.<br />
						4. JOON cannot be held responsible in the event of poor performance of the services caused
						by poorly informed information or which would not have been updated by the User
						and/or the Client Company.<br />
						5. In the event of Joon’s responsibility for the responsibility of Joon, due in particular to a
						malfunction of the application, Joon will be able to defeat such an action, at his sole
						discretion, by correcting the anomaly or by putting day the application.<br />
						<br /><br />
						<h4>7. DURATION AND TERMINATION</h4>
						1. The GCUs take effect on the date that the user accepts these GCUs and, unless early
						termination according to the terms of these Terms, remain valid for the duration of the
						use of the application by the user, which duration Depends on the subscription subscribed
						by the client company. <br />
						2. Joon may suspend or terminate access to and the use of the application in the event of a
						breach of the user to all or part of the obligations mentioned in the GCU. This
						termination will take place with immediate effect. In this case, the user will be informed of
						the termination of his registration by email and his access to the application will be
						blocked, without right to any compensation.<br />
						3. At the due date or termination of these conditions:
						- the license and the rights granted to users in the context of these CGUs will immediately
						cease and Joon will immediately delete the access and user rights of the user;
						- The user must stop using the application and will have to make any documentation (and all
						existing copies), belonging to Joon.<br />
						<br /><br />
						<h4>8. Force majeure</h4>
						Joon emerges from any responsibility vis-à-vis the user in case of force majeure. Expressly,
						are considered as force majeure or fortuitous cases, those defined in article 1218
						of the Civil Code as interpreted by the case law of French courses and courts, such as
						actions, omissions or any independent event of its Will, failure of a service provider or
						the transport network, any failure or interruption of the telecommunications network,
						denial of distributed service or other malicious attack, natural disaster, pandemic, war,
						riot , malicious deterioration, flooding, hurricane.<br />
						<br /><br />
						<h4>9. MODIFICATION OF THE LAW</h4>
						In the event of evolution of the regulations applicable to the services offered by Joon, the
						latter reserves the right to modify any provision of these GCUs subject to notify the
						user for a written notice of thirty (30) days .<br />
						<br /><br />
						<h4>10. GENERAL</h4>
						1. All GCU clauses are distinct. If a clause is declared zero or illegal, all the other clauses will
						remain valid and will continue to link the parties.<br />
						2. No provision of these Terms is intended to, or imply, create a partnership between the
						parties, authorize one or the other of the parties to act as an agent for the other,
						attribute to the ‘One or the other of the parties the authority of acting in the name and on
						behalf of the other party or to engage the other part of any way.<br />
						<br /><br />
						<h4>11. APPLICABLE LAW AND COMPETENT COURTS</h4>
						1. These CGUs and any dispute or complaint which result from it (including disputes and
						contractual complaints) will be governed by French law.<br />
						2. Any interpretation, dispute or claim concerning them and relating in particular to their
						validity, execution, interpretation or rupture, will be subject, in the event of impossibility
						for the parties to find an amicable agreement at the end of a period one (1) months from
						the written request of the most diligent party to the Commercial Court of Lyon,
						notwithstanding plurality of parties to the body, appeal in warranty, including for
						emergency procedures, procedures conservatories, in summary proceedings or by request. <br />
					</div>
					<div className="d-flex mt-3">
						<div className="btn btn-red me-2" onClick={handleReject}>{t('Commons.REJECT')}</div>
						<div className="btn btn-green" onClick={handleAccept}>{t("Desktop.ACCEPT")}</div>
					</div>
				</div>

			}

		</>
	);
}

export function CGUPopper(props) {
	const ref = React.useRef();

	return (
		<div>
			<ReactModal isOpen={true}>
				<div ref={ref}>
					<CGU {...props} />
				</div>
			</ReactModal>
		</div>
	)
}

export default CGU;

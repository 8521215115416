import React from "react";
import { dateAdd } from "includes/formatDate";
import "./UserRow.css";
import { t } from "i18next";
import AuthorPreview, { DatePreview } from "components/User/AuthorPreview/AuthorPreview";
import genRequest from "includes/request";
import { useQuery } from "react-query";

export default function UserRow(props) {
	const {user, firstConnexion, status} = props;

	if (props.async)
		return (<AsyncUserRow {...props}/>);

	let propKey = "LastConnexion";

	if (firstConnexion)
		propKey = "FirstConnexion";

	let isOnline = status !== false ? new Date(user[propKey]) > dateAdd(new Date(), {minutes: -5}) : false;

	return (
		<div className="UserRow d-flex align-items-center justify-content-between">
			<div className="d-flex align-items-center">
				{ props.children }
				<AuthorPreview
					className={"user-preview"}
					imgWidth={20}
					employeId={user.EmployesId}
					format={[
						"EmployeFirstName EmployeLastName",
					]}
					modifiers={{
						EmployeLastName: (item) => item.toUpperCase()
					}}
				/>
			</div>
			{
				status !== false &&
					<div className="last-conn">
						{
							isOnline ?
								<div className="d-flex align-items-center">
									<div className="me-2">
										{t("Analytics.ONLINE")}
									</div>
									<div className="online-dot"></div>
								</div>
								:
								<DatePreview
									date={user[propKey]}
									max={1}
								/>
						}
					</div>
			}
		</div>
	);
}

export function AsyncUserRow(props) {

	const fetchMinimalEmploye = () => genRequest("Employes/MinimalContactEmployesFromId/" + props.employeId);

	const {isLoading, data} = useQuery(
		["Employes", "MinimalContactEmployesFromId", props.employeId],
		fetchMinimalEmploye,
		{
			enabled: (props.employeId ? true : false),
			onSettled: props.onSettled
		}
	);

	if (!isLoading && data)
		return (
			<UserRow
				{...props}
				{...data}
				user={{
					...data,
					EmployesId: data.EmployeId,
					FirstName: data.EmployeFirstName,
					LastName: data.EmployeLastName
				}}
				async={false}
			/>
		);
	return (false);
}

import React, {memo, createRef} from "react";
import { Draggable } from "react-beautiful-dnd";
import "./TableRow.css";

const DraggableTableRow = memo((props) => {
	const {rowId, rowIndex} = props;
	const ref = createRef();

	function mouseOver(e) {
		if (ref)
		{
			let target = ref.current;
			if (window.SCROLLTABLE_LASTMD !== target.lastMd)
			{
				target.lastMd = window.SCROLLTABLE_LASTMD;
				if (window.SCROLLTABLE_LASTMD)
					props.handleCheckbox(e);
			}
		}
	}

	return (
		<Draggable
			draggableId={rowId}
			index={rowIndex}
			isDragDisabled={props.isDragDisabled}
		>
			{
				(provided) => {
					return (
						<tr
							className={
								(props.isSelected ? "selected" : "") + " " +
								(props.isActive ? "active" : "")
							}
							onClick={props.onClick}
							onMouseOver={mouseOver}
							// ref={ref}
							ref={(set_ref) => {
								provided?.innerRef(set_ref);
								ref.current = set_ref;
							}}
							{...provided?.draggableProps}
						>
							{
								props.cols.map((col, index) => {
									if (props.maxCols && index > props.maxCols)
										return (false);
									let col_txt = "" + (props.datas ? props.datas[col.name] : col.title) + "";
									if (!col_txt?.length)
										col_txt = col.title;
									if (col.hidden)
										return (false);
									return (
										<DraggableTableCell
											key={props.tableId + "-" + col.name}
											{...col}
											col={col}
											index={index}
											item={props.datas}
											checkbox={props.checkbox && index === 0 && props.datas?.checkbox !== false ? true : false}
											title={(col_txt ? col_txt : "")}
											handleCheckbox={props.handleCheckbox}
											isSelected={props.datas?.isSelected || props.isSelected}
											tableId={props.tableId}
											onCellChange={props.onCellChange}
											cellsProps={props.cellsProps}
											rowIndex={props.rowIndex}
											handle={provided?.dragHandleProps}
											onCtxMenu={props.onCtxMenu}
											ctxMenuProps={props.ctxMenuProps}
										/>
									);
								})
							}
							{provided.placeholder}
						</tr>
					);
				}
			}
		</Draggable>
	);
});
DraggableTableRow.displayName = "DraggableTableRow";

export const DraggableTableCell = (props) => {
	let title = (props.title?.length && props.title !== "null" && props.title !== null ? props.title : "");

	const handleCtxMenu = (event) => {
		if (event.altKey)
			return (false);
		props.onCtxMenu(event,
			{
				id: "table-ctx-menu" + (props.tableId ? "-" + props.tableId : ""),
				props: {
					colId: props.col.colId,
					colIndex: props.index,
					rowIndex: props.rowIndex
				}
			}
		);
	};

	if (!title?.length && props.noValuePh)
		title = props.noValuePh;
	return (
		<td
			className={props.name.toLowerCase() + "-col" + (props.classes ? " " + props.classes : "")}
			onContextMenu={handleCtxMenu}
		>
			<div className="d-flex align-items-center">
				{
					props.index === 0 &&
					<div
						className={"row-handle"}
						{...props.handle}
						onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
					/>
				}
				{
					(props.checkbox !== false && !props.item.checkbox) ?
						<div className="table-checkbox me-2">
							<input
								type="checkbox"
								checked={props.isSelected || false}
								onClick={(e) => e.stopPropagation()}
								onChange={(e) => props.handleCheckbox(e)}
								className="checkbox"
							/>
						</div>
						:
						(props.checkbox !== false && typeof props.item.checkbox === "object" ? props.item.checkbox : false)
				}
				<div className="w-100 table-cell" name={props.name}>
					{
						props.component ?
							<props.component {...props.childProps} {...props.cellsProps} col={props.col} rowIndex={props.rowIndex} colIndex={props.index} item={props.item} onCellChange={props.onCellChange}>
								{ (title !== "null" ? title : "") }
							</props.component>
							:
							(title !== "null" ? title : "")
					}
				</div>
			</div>
		</td>
	);
};
DraggableTableCell.displayName = "DraggableTableCell";

export default DraggableTableRow;

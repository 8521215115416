import React from "react";

import { t } from "i18next";
import "./AnimationEditDrawer.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import EditDrawer from "components/EditDrawer/EditDrawer";
import AnimationContentStep, { checkAnimContent } from "./components/AnimationContentStep/AnimationContentStep";
import AnimationsImagesStep, { checkAnimImages } from "./components/AnimationImagesStep/AnimationImagesStep";
import AnimationDescStep, { checkAnimDesc } from "./components/AnimationDescriptionStep/AnimationDescStep";
import AnimationSendOptionsStep from "./components/AnimationSendOptionsStep/AnimationSendOptionsStep";
import AnimationSendStep from "./components/AnimationSendStep/AnimationSendStep";
import AnimationEndStep from "./components/AnimationEndStep/AnimationEndStep";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";

export default function AnimationEditDrawer(props) {

	const { account } = useAccount();

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);
		return returnRightsFromName(account.Rights, rigthName) ? false : true;
	};
	const AnimationSteps = [
		{
			component: AnimationContentStep,
			name: "content",
			title: t("Posts.CONTENT_STEP"),
			onNext: checkAnimContent,
		},
		{
			component: AnimationDescStep,
			name: "description",
			title: t("Events.EVENT_DESC"),
			onNext: checkAnimDesc
		},
		{
			component: AnimationsImagesStep,
			name: "images",
			title: t("Posts.IMAGES_STEP"),
			onNext: checkAnimImages,
			disabled: (props.images === false)
		},

		{
			component: AnimationSendOptionsStep,
			name: "sendOptions",
			title: t("Commons.PUBLISH_OPTIONS"),
			disabled: checkRight(),
		},
		{
			component: AnimationSendStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("Posts.SEND_STEP")
		},
		{
			component: AnimationEndStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true
		}
	];

	if (!props.isOpen)
		return (false);

	return (
		<EditDrawer
			steps={AnimationSteps}
			onClose={props.onClose}
			onDraft={() => {
				props.animation.isDraft(true);
				return (true);
			}}
			object={props.animation}
			isOpen={props.isOpen}
			initQueries={props.initQueries}
			title={t("Events." + (props.animation.animationId() ? "MODIFY" : "ADD_TITLE"))}
		/>
	);
}

import React from "react";
import { t } from "i18next";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import genRequest from "includes/request";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import TagLabel from "components/Tags/Label/TagLabel";
import noAccent from "includes/no_accents";
import useAccount from "classes/Accounts/hooks/useAccount";
import ScrollTable from "components/ScrollTable/ScrollTable";
import UserImage from "components/User/Image/UserImage";
import Summary from "components/Summary/Summary";

export default function Targets(props) {
	const {postId, isTargetPost, postTypeId} = props;

	if (postTypeId === 13)
		return (<AlertTargets {...props}/>);

	const formatSiteResult = (items) => {

		let title = t(
			"Targets.published",
			{
				context: "sites",
				count: items.length
			}
		)

		let childs = items.sort((a, b) => {
			let a_name = noAccent((a.Name + (a.Town ? " - " + a.Town : "")).toLocaleLowerCase());
			let b_name = noAccent((b.Name + (b.Town ? " - " + b.Town : "")).toLocaleLowerCase());
			return (a_name < b_name ? -1 : 1);
		}).map((a) => {
			let name = a.Name + (a.Town ? " - " + a.Town : "");
			return (
				<TagLabel key={a.SiteId} text={name}/>
			);
		});
		return ({
			title,
			childs
		});
	};

	const formatGroupsResult = (items) => {

		let title = t(
			"Targets.published",
			{
				context: "groups",
				count: items.length
			}
		)

		let childs = items
			.sort((a, b) => (noAccent(a.Name.toLocaleLowerCase()) < noAccent(b.Name.toLocaleLowerCase()) ? -1 : 1))
			.map((a) => (<TagLabel text={a.Name} key={a.GroupId} color={a.DefaultHexaColor}/>));
		return ({
			title,
			childs
		});
	};

	return (
		<>
			<InfosFetcher
				queryFn={() => genRequest("Sites/AllSiteOfPost/" + postId)}
				queryKey={["Sites", "AllSiteOfPost", postId]}
				options={{
					retry: 0
				}}
				treat={(resp) => {
					if (!resp?.length || isTargetPost)
						return (false);
					let obj = formatSiteResult(resp);
					return (
						<Summary
							items={obj.childs}
							title={obj.title}
							icon={faBullseye}
						/>
					);
				}}
			/>
			<InfosFetcher
				queryFn={() => genRequest("Groups/GroupOfPost/" + postId)}
				queryKey={["Groups", "GroupOfPost", postId]}
				options={{
					retry: 0
				}}
				treat={(resp) => {
					if (!resp?.length)
						return (false);
					let obj = formatGroupsResult(resp);
					return ((
						<Summary
							items={obj.childs}
							title={obj.title}
							icon={faBullseye}
						/>
					));
				}}
			/>
		</>
	);
}

export function AlertTargets({postId}) {
	const {account} = useAccount();
	const TargetImage = ({item}) => {
		return (<UserImage width={30} employeId={item.EmployesId}/>);
	};

	return (
		<InfosFetcher
			queryKey={["LtEmployeAlerts", "PostId", postId]}
			queryFn={() => genRequest("LtEmployeAlerts/PostId/" + postId)}
			treat={(linkeds) => {
				let title = t("Commons.TARGETS", {count: linkeds?.length}).toLowerCase();
				if (!linkeds?.length)
					return (title);
				return (
					<InfosFetcher
						queryKey={["Employes", "EmployeOfCompany", account.CompanyId]}
						queryFn={() => genRequest("Employes/EmployeOfCompany/" + account.CompanyId)}
						treat={(users) => {
							let filtered = users.filter((a) => {
								return (linkeds.findIndex((link) => link.EmployesId === a.EmployesId) !== -1);
							});

							return (
								<Summary
									icon={faBullseye}
									items={
										<ScrollTable
											items={filtered}
											sortBy="FirstName"
											itemId="EmployesId"
											className="dark-bg"
											cellSpacing={"5px"}
											cols={[
												{
													title: " ",
													name: "targetImage",
													classes: "post-target-image-cell",
													sort: false,
													component: TargetImage
												},
												{
													title: t("User.FIRST_NAME"),
													name: "FirstName",
													classes: "post-title-cell"
												},
												{
													title: t("User.LAST_NAME"),
													name: "LastName",
													classes: "post-title-cell"
												}
											]}
										/>
									}
									title={title}
								/>
							);
						}}
					/>
				);
			}}
		/>
	);
}


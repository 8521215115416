import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import genRequest from "includes/request";
import UserRow from "components/User/UserRow/UserRow";
import Tabs from "components/Tabs/Tabs";
import { ReactionButton } from "Pages/Reactions/AdminReactionsPage";
import LoadCont from "components/LoadCont/LoadCont";
import { t } from "i18next";
import Summary from "components/Summary/Summary";
import ReactionIcon from "Pages/Reactions/components/ReactionIcon/ReactionIcon";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function ReactionsStats(props) {
	const treat_reactions = (reacts) => {
		let res = [];

		if (!reacts) return res;

		reacts.forEach((a) => {
			let index = res.findIndex((b) => b.ReactionId === a.ReactionId);

			if (index === -1)
				res.push({
					ReactionId: a.ReactionId,
					PostId: a.PostId,
					count: 1,
					reactions: [a],
				});
			else {
				res[index].count++;
				res[index].reactions.push(a);
			}
		});
		return res;
	};

	const fetchLikes = () =>
		genRequest("LtEmployesLikedposts/PostId/" + props.postId)
			.then((resp) => (resp ? resp : []))
			.catch((e) => []);

	const fetchReactions = () =>
		genRequest("LT_Employe_Reaction_Post/PostId/" + props.postId)
			.then((resp) => (resp ? resp : []))
			.catch((e) => []);

	const fetch = () => {
		let proms = [fetchLikes(), fetchReactions()];

		let prom = Promise.all(proms).then((resp) => {
			let all = [];
			if (resp[0]?.length) all.push(...resp[0]);
			if (resp[1]?.length) all.push(...resp[1]);
			return {
				All: all,
				Likes: resp[0],
				Reactions: resp[1],
				SortedReactions: treat_reactions(resp[1]),
				totalCount: resp[0]?.length + resp[1].length,
			};
		});
		return prom;
	};

	const { isLoading, data } = useQuery(
		["PostReactionsFetch", "PostId", props.postId],
		fetch
	);

	if (isLoading) return <div>{t("Commons.LOADING")}</div>;

	if (props.children)
		return !data.totalCount
			? 0
			: props.children({
				...data,
				postId: props.postId,
			});

	if (props.hideIfNull && !data.totalCount) {
		if (props.onHide) props.onHide();
		return false;
	}

	return (
		<div className="pb-3">
			<div style={{ fontSize: "16px" }} className="text-center mb-1">
				{t("Posts.REACTIONS", { count: data?.totalCount })}
			</div>
			{data.totalCount > 0 && (
				<ReactionsTable
					postId={props.postId}
					count={data.totalCount}
					reactions={data.SortedReactions}
					likes={data.Likes}
					all={data.All}
				/>
			)}
		</div>
	);
}

function ReactionsTable(props) {
	const { reactions, postId, likes } = props;
	const { account } = useAccount();
	const [tabs, setTabs] = useState([]);

	useEffect(() => {
		setTabs([
			{
				title: (
					<div className="d-flex gap-2 align-items-center justify-content-center w-100 h-100">
						{t("Analytics.ALL")}
					</div>
				),
				child: AllTab,
				childProps: {
					items: props.all.sort((a, b) =>
						a.LastChanged < b.LastChanged ? -1 : 1
					),
				},
				isVisible: true,
			},
			{
				title: (
					<div className="d-flex gap-2 align-items-center justify-content-center w-100 h-100">
						<FontAwesomeIcon
							icon={faHeart}
							color={"#ff0045"}
							style={{ fontSize: "16px" }}
						/>
						{likes.length}
					</div>
				),
				child: Likes,
				childProps: {
					count: likes.length,
					postId,
				},
				enabled: likes.length > 0,
				hoverTitle: "Likes",
				isVisible: true,
			},
		]);
		return () => {
			setTabs([]);
		}
	}, [account]);

	reactions.forEach((a) => {
		tabs.push({
			title: (
				<ReactionButton
					asyncIcon={true}
					reactionId={a.ReactionId}
					countQueryKey={[
						"LT_Employe_Reaction_Post",
						"PostIdAndReactionId",
						postId,
						a.ReactionId,
					]}
					countQueryFn={() =>
						genRequest(
							"LT_Employe_Reaction_Post/PostIdAndReactionId/" +
							postId +
							"/" +
							a.ReactionId
						)
					}
					iconStyle={{
						width: "25px",
					}}
					className="d-flex flex-row align-items-center gap-2 pe-none justify-content-center h-100"
				/>
			),
			child: ReactionUsers,
			childProps: {
				users: a.reactions,
			},
			isVisible: true,
		});
	});

	return <Tabs className="ReactionsTable light-bg" items={tabs} />;
}

function AllTab(props) {
	const { items } = props;

	const treat_reactions = (reacts) => {
		let res = [];

		if (!reacts) return res;

		reacts.forEach((a) => {
			let index = res.findIndex((b) => b.EmployesId === a.EmployesId);

			if (index === -1)
				res.push({
					EmployesId: a.EmployesId,
					PostId: a.PostId,
					count: 1,
					reactions: [a],
				});
			else {
				res[index].count++;
				res[index].reactions.push(a);
			}
		});
		return res;
	};

	if (!items?.length) return <div>{t("Posts.REACTIONS", { count: 0 })}</div>;

	let disp_items = treat_reactions(items);

	return (
		<div>
			{disp_items.map((a, key) => {
				return (
					<UserRow
						key={a.EmployesId}
						user={{ EmployesId: a.EmployesId }}
						status={false}
					>
						<div
							className="d-flex align-items-center justify-content-center me-2 gap-1"
							style={{}}
						>
							{a.reactions
								.sort((c, d) => {
									if (!c.ReactionId) return -1;
									return c.ReactionId < d.ReactionId ? -1 : 1;
								})
								.map((b, b_key) =>
									b.ReactionId ? (
										<ReactionIcon
											async={true}
											reactionId={b.ReactionId}
											style={{
												width: "20px",
											}}
										/>
									) : (
										<FontAwesomeIcon
											icon={faHeart}
											color={"red"}
										/>
									)
								)}
						</div>
					</UserRow>
				);
			})}
		</div>
	);
}

function ReactionUsers(props) {
	const { users } = props;

	return (
		<div>
			{users
				?.sort((a, b) => (a.LastChanged < b.LastChanged ? -1 : 1))
				.map((a) => (
					<UserRow
						key={a.EmployesId}
						user={{ EmployesId: a.EmployesId }}
						status={false}
					/>
				))}
		</div>
	);
}

export function Likes({ count, postId, summary }) {
	const { isLoading, data, error } = useQuery(
		["PostsStats", "LtEmployesLikedposts", "PostId", postId],
		() => genRequest("LtEmployesLikedposts/PostId/" + postId)
	);

	const genItems = () => {
		if (isLoading) return <LoadCont text="Chargement des likes" />;
		if (!data?.length || error) return <div>{t("Posts.NO_LIKES")}</div>;
		return (
			<div
				className="w-100"
				style={{ maxHeight: "300px", overflow: "auto" }}
			>
				{data
					?.sort((a, b) => (a.LastChanged < b.LastChanged ? -1 : 1))
					.map((a) => (
						<UserRow
							key={a.EmployesId}
							user={{ EmployesId: a.EmployesId }}
							status={false}
						/>
					))}
			</div>
		);
	};

	if (summary)
		return (
			<Summary
				title={count + " " + t("Posts.LIKES", { count })}
				icon={faHeart}
				items={genItems()}
			/>
		);
	return genItems();
}

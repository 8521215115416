import React from "react";
import { useQuery } from "react-query";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";
import "./SiteLogo.css";
import LoadCont from "components/LoadCont/LoadCont";

function SiteLogo(props) {
	const { imgAlt, siteId, companyId, className, url, theme } = props;
	const { height, width } = props;
	const fetch = () => {
		if (siteId) return fetchSiteLogo(theme === "dark" ? 17 : 10);
		else if (companyId) return fetchCompanyLogo(theme === "dark" ? 17 : 10);
		return false;
	};

	const fetchSiteLogo = async (typeId) =>
		await genRequest(
			"Documents/AllDocumentsOfSiteTyped/" +
				siteId +
				"/" +
				(typeId ? typeId : 10)
		).then(
			(resp) => {
				if (!resp?.length)
					return fetchCompanyLogo(theme === "dark" ? 17 : 10);
				return documents_treat(resp, "LastChanged", 1)[0];
			},
			() => {
				return fetchCompanyLogo(theme === "dark" ? 17 : 10);
			}
		);

	const fetchCompanyLogo = async (typeId, forced) =>
		await genRequest(
			"Documents/DocumentOfCompanyAndType/" +
				companyId +
				"/" +
				(typeId ? typeId : 10)
		).then(
			(resp) => {
				if (!forced && !resp?.length && typeId !== 10)
					return fetchCompanyLogo(false, true);
				return documents_treat(resp, "LastChanged", 1)[0];
			},
			() => false
		);

	const { isLoading, data } = useQuery(
		[
			"Documents",
			"AllDocumentsOfSiteTyped",
			siteId,
			theme === "dark" ? 17 : 10,
		],
		fetch,
		{
			retry: 1,
			enabled: typeof url === "undefined",
		}
	);

	if (isLoading)
		return (
			<div
				className={
					"site-logo align-items-center justify-content-center me-2 w-100" +
					(className ? " " + className : "")
				}
				style={{
					width,
					height,
				}}
			>
				<LoadCont />
			</div>
		);

	let img_url = data?.Url_Origin;
	if (!data) img_url = "assets/logos/logo.png";
	if (url) img_url = url;

	return (
		<div className={"site-logo" + (className ? " " + className : "")}>
			<img
				loading="lazy"
				alt={"Logo" + (imgAlt ? " " + imgAlt : "")}
				src={img_url}
				height={height}
				width={width}
			/>
		</div>
	);
}

export default SiteLogo;

import React, {useCallback, useRef, useState} from "react";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import '@toast-ui/editor/dist/i18n/fr-fr'
import { isChrome } from "includes/navigators";
import "./MarkdownEditor.css";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";
import { useQueryClient } from "react-query";
import MDETour from "./MDETour";

export default function MarkdownEditor (props)
{
	const [isInitied, setIsInitied] = useState(false);
	const {isAdmin, getSettings} = useAccount();
	const {getAppFunction} = useTemplate();
	const inner_ref = useRef();
	const QCL = useQueryClient();

	const getRef = useCallback(() => {
		if (props.useRef)
			return (props.useRef());
		return (inner_ref?.current)
	}, [props])

	React.useEffect(() => {
		if (!isInitied)
		{
			let ref = getRef();
			if (!ref || !ref.rootEl?.current)
				return (false);
			setIsInitied(true);
		}
	}, [isInitied, setIsInitied, props, getRef]);

	function createMDE_HeaderButton() {
		const button = document.createElement("button");
		button.className = "custom-header-btn toastui-editor-toolbar-icons";
		button.style.margin = "0";
		button.addEventListener("click", () => {
			let ref = getRef();
			if (button.classList.contains("active")) {
				ref.getInstance().exec("heading", {
					level: 0
				});
				button.classList.remove("active");
			} else
			{
				ref.getInstance().exec("heading", {
					level: 5
				});
				button.classList.add("active");
			}
		});
		return (button);
	}

	function genHelpButton() {
		const button = document.createElement("button");
		button.className = "help-button";
		button.style.margin = "0";
		button.innerHTML = "?"
		button.addEventListener("click", () => {
			QCL.setQueriesData(["Helper", "CurrentTour"], MDETour)
		})
		return (button);
	}

	const ToolBarItems = [
		[{
			el: createMDE_HeaderButton(),
			tooltip: t("MDE.HEADER_BTN"),
			command: "heading",
		}],
		["bold", "italic"],
		["ul", "ol", "outdent"],
		["link"],
		[
			"quote",
			{
				command: "codeBlock",
				tooltip: "Code block",
				className: "code toastui-editor-toolbar-icons",
				style: {
					display: getSettings("AdvancedMarkdown") ? "" : "none"
				}
			}
		],
		[
			{
				el: genHelpButton(),
				tooltip: "Aide",
				command: "heading",
			}
		]
	];

	return (
		<div className={"markdown-editor" + ((props.toolbar === false || props.plainText) ? " no-toolbar" : "")}>
			<Editor
				autofocus={props.autofocus || false}
				height={"auto"}
				minHeight={300}
				initialEditType={"wysiwyg"}
				previewStyle={"vertical"}
				hideModeSwitch={!getSettings("AdvancedMarkdown") || (!getAppFunction("PostsPage.CanUseMarkdown") && !isAdmin()) || props.plainText}
				placeholder={t("MDE.PH")}
				ref={props.useRef || inner_ref}
				theme={"dark"}
				initialValue={props.initialValue || ""}
				linkAttributes={{
					target: "blank_"
				}}
				toolbarItems={(props.toolbar !== false && !props.plainText) ? ToolBarItems : false}
				language={t("Code")}
				usageStatistics={false}
				hooks={{
					addImageBlobHook: function(/* blob, callback, event */) {
						if (isChrome()) {
							const text = navigator.clipboard.read("text/plain");
							if (text) {
								text.then((resp) => {
									resp[0].getType("text/plain").then((resp)=>{
										return (resp.text());
									}).then(() => {
										let ref = getRef();
										let instance = ref.editorInst;
										instance.insertText(resp);
									});
								});
							}
							return (true);
						}
					}
				}}
				onChange={() => {
					let ref = getRef();
					if (!ref)
						return (false);
					let instance = ref.editorInst;
					let txt = instance.getMarkdown()
					if (props.onChange)
						props.onChange(txt);
					return (txt);
				}}
				onCaretChange={()=>{
					let ref = getRef();
					if (!ref)
						return (false);
					let instance = ref.editorInst;
					let header_btn = document.querySelector(".custom-header-btn");
					if (instance && instance.isWysiwygMode()) {
						let infos = instance.getRangeInfoOfNode();
						if (infos && infos?.type === "heading" && header_btn) {
							header_btn.classList.add("active");
							return ;
						}
					}
					if (header_btn)
						header_btn.classList.remove("active");
				}}
			/>
		</div>
	);
}

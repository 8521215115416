const { default: genRequest } = require("includes/request");

class RssServiceImpl {
	getRssFeeds(companyId) {
		return genRequest("RssFeeds/RssTargetingCompany/" + companyId);
	}
	postRssFeed(data) {
		return genRequest("RssFeeds", data, "post");
	}
	deteleRssFeed(id) {
		return genRequest("RssFeeds/" + id, {}, "delete");
	}
	updateRssFeed(id, data) {
		return genRequest("RssFeeds/" + id, data, "put");
	}
	getFeedsFromUrl(url) {
		return genRequest(`RssFeeds/UrlToRssFeeds`, { Url: url }, "post");
	}
	getAllPostsRssFeeds(companyId) {
		return genRequest("Posts/AllRssOfCompanyToOnlyAllPost/" + companyId);
	}
	getAllPostsRssFeedsByUrl(url) {
		return genRequest(
			"Posts/RssFeedToOnlyAllPost",
			{ RssFeedurl: url },
			"post"
		);
	}
}

export const RssService = new RssServiceImpl();

import React, {memo, useState} from "react";
import Storage from "classes/Storage/Storage";
import "./TextParser.css";
import { getEmbbed, getEmbbedThumbnail } from "./EmbbedList";
import { t } from "i18next";

export function parseHashtags (text) {
	let links = text.match(/((^|[^(])#[a-zA-Z0-9]{3,}($|))/gi);

	let ret = text;
	if (links) {
		for (let x = 0; x < links.length; x++) {
			let link = links[x];
			ret = ret.replace(link, "[" + link + "](" + link + ")");
		}
	}
	return (ret);
}

export function ParseEmbbed (props) {
	let link_start = null;
	let host = null;
	const {href, text} = props;

	let emb_props = getEmbbed(href);

	if (!emb_props)
		return (<a target="_blank" {...props}>{text}</a>);

	let link_end = href.split(emb_props.split_start)[1];
	if (emb_props.replace && link_end)
		link_end = link_end.replace(emb_props.replace, "");
	if (link_end)
		link_end = link_end?.split(emb_props.split_end)[0];
	link_start = emb_props.link_start;
	host = emb_props.host;

	function handleLoad(e) {
		e.target.classList.remove("loading");
	}

	return (
		<Frame
			link={link_start + link_end}
			linkEnd={link_end}
			link_props={props}
			emb_props={emb_props}
			text={text}
			host={host}
			handleLoad={handleLoad}
		/>
	);
}

const Frame = memo(({link, linkEnd, host, link_props, text, handleLoad}) => {

	const setFramedCookies = (host) => {
		const storage = new Storage();
		storage.setItem("framed-cookies-" + host, true);
	};

	const getFrammedCookies = (host) => {
		const storage = new Storage();
		let item = storage.getItem("framed-cookies-" + host);
		return (item ? item : false);
	};

	const [CookiesAllow, setCookiesAllow] = useState(getFrammedCookies(host) || false);

	const genFrameStyle = (host, link_id) => {
		let emb = getEmbbedThumbnail(host, link_id);

		if (!emb)
			return ({});
		return ({
			backgroundColor: emb.color,
			backgroundImage: `url(${emb.default})`
		});
	};

	const allowCookies = () => {
		setFramedCookies(host);
		document.querySelectorAll("[aria-label='" + host+ "-framed-content']").forEach((a) => {
			let btn = a.querySelector(".allow-cookies-button");
			if (btn)
				btn.click();
		});
		setCookiesAllow(true);
	};

	if (!CookiesAllow)
		return (
			<span
				className="embbed-iframe cookie-blocked thumbnail"
				aria-label={host + "-framed-content"}
				style={genFrameStyle(host, linkEnd)}
			>
				<span className="cookies-prompt">
					<span className="cookies-prompt-box text-center">
						<span>{t("Desktop.ALLOW_COOKIES")} <b>{host}</b> {t("Desktop.TO_SHOW_CONTENT")}</span>
						<button
							className="allow-cookies-button btn btn-green mt-2"
							onClick={allowCookies}
						>
							{t("Desktop.ACCEPT")}
						</button>
					</span>
				</span>
			</span>
		);
	return (
		<iframe
			className="embbed-iframe loading"
			src={link}
			frameBorder="0"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			loading="lazy"
			onLoad={handleLoad}
			title={text ? text : link}
			aria-label={host + "-framed-content"}
		>
			<a {...link_props} target="_blank">{text ? text : link}</a>
		</iframe>
	);
});
Frame.displayName = "Frame";

import Button from "components/Button/Button";
import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";
import { useState } from "react";
import Select from "react-select";
import { RssService } from "service/rss.service";

export const RssForm = ({ postData }) => {
	const [rssUrlLoading, setRssUrlLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const defaultValue = [];

	const [formData, setFormData] = useState(defaultValue);
	const [selectedFeeds, setSelectedFeeds] = useState([]);
	const onSubmit = (e) => {
		e.preventDefault();
		postData(formData);
	};
	const searchFeedsFromUrl = async (value) => {
		setFormData(defaultValue);
		setSelectedFeeds([]);
		setErrorMsg("");
		const UrlRegEx =
			/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
		const regex = new RegExp(UrlRegEx);
		if (value.match(regex)) {
			setRssUrlLoading(true);
			try {
				const data = await RssService.getFeedsFromUrl(value);
				const feeds = Object.keys(data).map((key) => ({
					label: key,
					value: data[key],
				}));
				setSelectedFeeds(feeds);
				setRssUrlLoading(false);
			} catch (error) {
				setErrorMsg(t("Rss.ERROR_MSG"));
				setRssUrlLoading(false);
			}
		}
	};
	const onChange = (arr) => {
		const formatArr = arr.map((item) => ({
			Name: item.label.substring(0, 25),
			RssUrl: item.value,
			IsNotified: item.IsNotified,
		}));
		setFormData([...formatArr]);
	};
	const updateFormData = (index, key, value) => {
		const updatedFormData = [...formData];
		updatedFormData[index] = {
			...updatedFormData[index],
			[key]: value,
		};
		setFormData(updatedFormData);
	};
	return (
		<div className="panel-form">
			<form onSubmit={onSubmit}>
				<FormInput
					type="text"
					className="mb-2"
					placeholder={t("Rss.SEARCH_RSS_FEEDS")}
					onChange={(value) => searchFeedsFromUrl(value)}
				/>
				{errorMsg && <div className="text-center">{errorMsg}</div>}

				{rssUrlLoading ? (
					<div className="text-center">{t('Commons.LOADING')}</div>
				) : (
					<>
						{selectedFeeds.length > 0 && (
							<>
								{selectedFeeds.length > 0 && (
									<Select
										placeholder={t("Rss.SELECT_RSS_FEEDS")}
										className="mb-2 panel-form-dropdown"
										classNamePrefix="dropdown"
										options={selectedFeeds}
										onChange={onChange}
										isMulti={true}
									/>
								)}
								{formData.map((item, index) => (
									<>
										<div className="form-input">
											<input
												type="text"
												value={item.Name}
												className="mb-2 w-100"
												placeholder={t("Rss.RSS_NAME")}
												maxLength={25}
												onChange={(e) =>
													updateFormData(
														index,
														"Name",
														e.target.value
													)
												}
											/>
										</div>

										<div className="form-checkbox mb-2">
											<FormInput
												id="notify"
												type="checkbox"
												onClick={(e) =>
													updateFormData(
														index,
														"IsNotified",
														e.target.checked
													)
												}
											/>
											<label
												className="text-checkbox"
												htmlFor="notify"
											>
												{t("Rss.NOTIFY")}
											</label>
										</div>
									</>
								))}

								<Button
									className="panel-form-btn"
									type="submit"
								>
									{t("Rss.SUBMIT")}
								</Button>
							</>
						)}
					</>
				)}
			</form>
		</div>
	);
};

import useAccount from "classes/Accounts/hooks/useAccount";
import { CustomSelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { t } from "i18next";
import React from "react";

export default function RoleSelect(props) {

	const {isSup} = useAccount();
	const {initialValue, onSelect} = props;

	const Roles = [
		// {
		// 	label: t("Contacts.READER_ROLE"),
		// 	value: "UserNoPublisher",
		// 	bypassModeration: false
		// },
		{
			label: t("Contacts.USER_ROLE"),
			value: "UserPublisher",
			bypassModeration: false
		},
		// {
		// 	label: t("Contacts.ADMIN_ROLE"),
		// 	value: "SiteAdmin",
		// 	bypassModeration: true
		// },
		{
			label: t("Contacts.ADMIN_ROLE"),
			value: "SuperAdmin",
			bypassModeration: true
		},
		{
			label: t("Contacts.ELOADMIN"),
			value: "EloAdmin",
			bypassModeration: true
		}
	];

	const getRoles = () => Roles.map(a => isSup(a.value) >= 0 ? a : false).filter(a => a);

	return (
		<CustomSelect
			onChange={onSelect}
			options={getRoles()}
			initialValue={[Roles.find((a) => a.value === initialValue) || {value: initialValue, label: initialValue}]}
		/>
	)
}

import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import CategoriesSelect from "components/CustomSelects/CategoriesSelect";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import { t } from "i18next";

export default function ChartOptionsStep(props) {
	const chart = props.object;
	const { account } = useAccount();
	const [Notify, setNotify] = useState(chart.notification());

	function catsSelect(values) {
		chart.categories(values.map((a) => ({ CategoryId: (!a.isNew ? a.value : false), Name: a.label, isNew: a.isNew ? true : false })));
	}

	return (
		<div className="d-flexd flex-column gap-2">
			<div className="inner-step-cont">
				<CategoriesSelect
					noTitle={false}
					onSelect={catsSelect}
					initialValue={chart.categories ? chart.categories().map(a => ({ label: a.Name, value: a.CategoryId })) : false}
					type={"charts"}
					companyId={account.CompanyId}
				/>
			</div>
			<div className="inner-step-cont">
				<SwitchInput label={t("Commons.NOTIFY_USERS")} onlyState={true} checked={Notify} onChange={(val) => setNotify(chart.notification(val))} />
			</div>
		</div>
	);
}

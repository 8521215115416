import React from "react";
import "./LoadCont.css";

export default function LoadCont(props) {
	let width = 50;
	let border = 3;
	if (props.width)
	{
		border = border * (props.width / width);
		width = props.width;
	}

	return (
		<div
			className={"LoadCont" + (props.className ? " " + props.className : "")}
		>
			<div style={{
				width: width, height: width,
				borderWidth: border + "px"
			}} className="circle">
				<div
					style={{
						borderWidth: border + "px"
					}}
					className="circle">
					<div
						style={{
							borderWidth: border + "px"
						}}
						className="circle"/>
				</div>
			</div>
			{props.text}
		</div>
	);
}

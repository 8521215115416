import React from "react";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import FormQuestion from "../FormQuestion/FormQuestion";
import "./FormSection.css";
import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";

export default function FormSection(props) {
	const {section} = props;
	const {curSection, setCurSection} = props;
	const {curQuestion, setCurQuestion} = props;
	const {refreshForm, canDelete} = props;

	function handleClick()
	{
		setCurSection(section);
	}

	function handleDelete(e) {
		e.preventDefault();
		e.stopPropagation();
		section.delete();
		if (refreshForm)
			refreshForm();
	}

	function handleDragEnd(result) {
		const { source, destination } = result;
		const sourceIndex = source?.index;
		const destIndex = destination?.index;

		if (typeof sourceIndex === "undefined" || typeof destIndex === "undefined")
			return (false);
		if (result.type === "QuestionsList") {
			let quest = section.moveQuestion(sourceIndex, destIndex);
			setCurQuestion(quest, true);
		} else if (result.type === "OptionsList") {
			let sourceStepId = source.droppableId.replace("OptionsList-", "");
			const destStepId = destination.droppableId.replace("OptionsList-", "");

			if (sourceStepId !== destStepId)
				return (false);
			if (!isNaN(Number(sourceStepId)))
				sourceStepId = Number(sourceStepId);
			let question = section.questions(sourceStepId);
			if (question)
				question.moveOption(sourceIndex, destIndex);
		}
		if (refreshForm)
			refreshForm();
	}

	let ph = t(`Forms.${(section.Position === 0 ? "FORM_TITLE" : "SECTION_TITLE")}`);
	let isActive = curSection?.id === section.id;

	return (
		<div
			className={"FormSection" + (isActive ? " active" : "")} onClick={handleClick}
			data-form-section-id={section.id}
		>
			<div className='d-flex section-content'>
				<div className='w-100'>
					<div>
						<FormInput className='section-title w-100 mb-2' placeholder={ph} value={section.Title} onChange={(e) => section.Title = e}/>
					</div>
					<div>
						<FormInput textarea={"true"} className='section-text w-100' placeholder={t("Forms.DESC")} value={section.Txt} onChange={(e) => section.Txt = e}/>
					</div>
				</div>
				<div className='d-flex ml-3 justify-content-end section-toolbar'>
					{
						canDelete &&
							<div className='cursor-pointer section-toolbar-btn ms-2' title={t("Forms.RM_SECTION")} onClick={handleDelete}>
								<FontAwesomeIcon icon={faTrashAlt}/>
							</div>
					}
				</div>
			</div>
			<DragDropContext onDragEnd={handleDragEnd} className="h-100">
				<QuestionsList
					section={section}
					items={section.questions()}
					curQuestion={curQuestion}
					setCurQuestion={setCurQuestion}
					refreshForm={refreshForm}
				/>
			</DragDropContext>
		</div>
	);
}

function QuestionsList(props) {
	const {items, section} = props;
	const {curQuestion, setCurQuestion, refreshForm} = props;

	if (!items?.length)
		return (false);

	return (
		<Droppable droppableId={"QuestionList-" + section?.id } type="QuestionsList" direction="vertical">
			{
				(provided) => {
					return (
						<div className='QuestionsList mt-3'>
							<div ref={provided.innerRef}>
								{
									items.map((a, key) => {
										let isActive = curQuestion.id === a.id;
										return (
											<FormQuestion
												key={a.id}
												index={key}
												question={a}
												isActive={isActive}
												curQuestion={curQuestion}
												refreshForm={refreshForm}
												setCurQuestion={setCurQuestion}
											/>
										);
									})
								}
								{provided.placeholder}
							</div>
						</div>
					);
				}
			}
		</Droppable>
	);
}

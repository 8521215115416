import React, { Component } from "react";
import {unflatten} from "flat";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import { t } from "i18next";
import { toast } from "react-toastify";
import Button from "components/Button/Button";
import getAppFunctions from "./AppFunctions";
import "./AppFunctions.css";
import { RouterPrompt } from "components/RouterPrompt/RouterPrompt";

class AppFunctionsManager extends Component {

	constructor(props) {
		super (props);

		let AF = this.props.template.getAppFunctions(true);

		this.state = {
			items: this.initValues(),
			original: AF,
			hasChanged: false
		};

		this.changeValue = this.changeValue.bind(this);
		this.checkChanges = this.checkChanges.bind(this);
		this.resetAppFunctions = this.resetAppFunctions.bind(this);
		this.updateAppFunctions = this.updateAppFunctions.bind(this);
		this.isChecked = this.isChecked.bind(this);
		this.initValues = this.initValues.bind(this);
		this.initChildValues = this.initChildValues.bind(this);
		this.findItem = this.findItem.bind(this);
		this.saveToast = false;
	}

	componentWillUnmount() {
		if (this.saveToast)
			toast.dismiss(this.saveToast);
	}

	initValues() {
		let items = [];
		let AF = this.props.template.getAppFunctions(true);

		const AppFunctions = getAppFunctions();
		for (let x = 0; x < AppFunctions.length; x++)
		{
			let af = AppFunctions[x];

			if (af.settingsName && !this.props.getSettings(af.settingsName))
				continue;
			items.push({
				...af,
				id: af.id,
				label: t(`AppFunctions.${af.id?.replace(/\./gi, "_")}`),
				checked: this.isChecked(af, AF[af.id]),
				childs: this.initChildValues(af.childs)
			});
		}
		items = items.sort((a, b) => a.label < b.label ? -1 : 1);
		return (items);
	}

	initChildValues(items) {
		let ret = [];
		let AF = this.props.template.getAppFunctions(true);

		if (!items?.length)
			return (ret);
		for (let x = 0; x < items.length; x++)
		{
			let item = items[x];

			if (item.settingsName && !this.props.getSettings(item.settingsName))
				continue;
			ret.push({
				...item,
				id: item.id,
				label: t(`AppFunctions.${item.id.replace(/\./gi, "_")}`),
				checked: this.isChecked(item, AF[item.id]),
			});
		}
		return (ret);
	}

	isChecked(item, value) {
		let val = value;
		let ret = false;

		if (item.rootId)
			val = this.props.template.getValue(item.rootId);

		if ((!item.checkedValue && val) || (item.checkedValue && val === item.checkedValue))
			ret = (item.checkedValue ? item.checkedValue : true);
		if ((!item.notCheckedValue && !val) || (item.notCheckedValue && val === item.notCheckedValue))
			ret = (item.notCheckedValue ? item.notCheckedValue : false);
		return (ret);
	}

	checkChanges(vals) {
		for (let x in vals)
			if (vals[x]?.checked !== this.state.original[vals[x].id])
				return (true);
		return (false);
	}

	findItem(itemId) {
		let vals = this.state.items;

		for (let x = 0; x < vals.length; x++)
		{
			if (vals[x].id === itemId)
				return ({
					index: x
				});
			if (vals[x].childs?.length)
			{
				let chidIndex = vals[x].childs.findIndex((a) => a.id === itemId);
				if (chidIndex !== -1)
					return ({
						index: x,
						child: chidIndex
					});
			}
		}
		return (false);
	}

	changeValue(id, value) {
		let vals = this.state.items;
		let index = this.findItem(id);

		if (!index)
			return (false);

		let item = vals[index.index];
		if (index.child > -1)
			item = item.childs[index.child];

		let checked = value;
		if (item.checkedValue && checked)
			checked = item.checkedValue;
		else if (item.notCheckedValue && !checked)
			checked = item.notCheckedValue;
		if (index.child > -1)
		{
			vals[index.index].childs[index.child] = {
				...vals[index.index].childs[index.child],
				checked
			};
		} else {
			vals[index.index] = {
				...vals[index.index],
				checked
			};
		}

		this.setState({
			items: vals,
			hasChanged: this.checkChanges(vals)
		}, () => {
			if (!this.saveToast)
				this.saveToast = toast(
					<div className='CloseEditToast d-flex align-items-center justify-content-center flex-column text-center'>
						{t("Commons.ASK_SAVE")}
						<div className='d-flex gap-2 flex-wrap mt-2 align-items-center justify-content-center'>
							<div><Button className='btn-red' onClick={() => {this.resetAppFunctions();}}>{t("Commons.CANCEL")}</Button></div>
							<div><Button className='btn-green' onClick={() => {this.updateAppFunctions();}}>{t("Commons.SAVE")}</Button></div>
						</div>
					</div>
					,
					{
						autoClose: false,
						onClose: () => {
							this.saveToast = false;
						}
					}
				);
		});
		return (vals[id]);
	}

	unflatAF(vals) {
		let ret = {};
		vals.forEach((a) => {
			if (a.childs?.length)
				a.childs.forEach((b) => {
					ret[b.id] = b.checked;
					if (b.rootId)
					{
						ret[b.rootId] = {
							rootId: b.rootId,
							checked: b.checked
						};
					}
				});
			if (a.id)
				ret[a.id] = a.checked;
			if (a.rootId)
			{
				ret[a.rootId] = {
					rootId: a.rootId,
					checked: a.checked
				};
			}
		});
		ret = unflatten(ret);
		return (ret);
	}

	updateAppFunctions() {
		let af = this.unflatAF(this.state.items);
		let proms = [];

		if (this.props.Templates?.length)
		{
			for (let x in this.props.Templates)
			{
				this.props.Templates[x].setAppFunctions(af);
				proms.push(this.props.Templates[x].send());
			}
			this.props.setTemplate(this.props.Templates[0].values());
		}

		toast.dismiss(this.saveToast);
		this.saveToast = false;

		let prom = Promise.all(proms).then(() => {
			this.props.forceRefresh();
		});

		toast.promise(
			prom,
			{
				pending: t("Toasts.PROM_PENDING"),
				success: t("Toasts.PROM_SUCCESS"),
				error: t("Toasts.PROM_ERROR"),
				autoClose: 7000
			}
		);
		return (prom);
	}

	resetAppFunctions() {
		let items = this.initValues();

		toast.dismiss(this.saveToast);
		this.saveToast = false;
		this.setState({
			items,
			hasChanged: false
		});
	}

	render() {
		return (
			<div className={this.props.className}>
				<h5>{t("AppFunctions.FUNCTIONS")}</h5>
				<i>{t("AppFunctions.MSG")}</i>
				<div className="mt-3">
					<RouterPrompt
						when={this.state.hasChanged}
						onOK={() => {
							this.resetAppFunctions()
							return (Promise.resolve(true))
						}}
						onCancel={() => {}}
						lastGen={Date.now()}
					/>
					<div className="d-flex flex-column gap-3">
						{
							this.state.items.map((a, key) => {
								return (<AppFunctionsGroup key={key} onChange={(id, checked, isChild) => this.changeValue(id, checked, isChild)} item={a}/>);
							})
						}
					</div>
					{
						this.state.hasChanged &&
							<div className="d-flex mt-3 gap-1">
								<Button
									className="btn btn-red"
									onClick={this.resetAppFunctions}
								>
									{t("Commons.CANCEL")}
								</Button>
								<Button
									className="btn btn-green"
									onClick={this.updateAppFunctions}
								>
									{t("Commons.SAVE")}
								</Button>
							</div>
					}
				</div>
			</div>
		);
	}
}

const AppFunctionsGroup = (props) => {
	const {item} = props;

	const handleChange = (id, val) => {
		props.onChange(id, val);
	};

	return (
		<div className="AppFunctionsGroup">
			<div className="font-semibold">
				{
					item.id ?
						<AppFunctionSwitch item={item} onChange={(val) => handleChange(item.id, val)}/>
						:
						item.title
				}
			</div>
			{
				((!item.id || item.checked) && item.childs?.length > 0) &&
					<div className="AFGroupChilds d-flex flex-column mt-2 pt-2 gap-1">
						{
							item.childs?.map((a) => {
								return (<AppFunctionSwitch key={a.id} item={a} onChange={(val) => handleChange(a.id, val)}/>);
							})
						}
					</div>
			}
		</div>
	);
};

const AppFunctionSwitch = (props) => {

	const {item, onChange} = props;

	const handleChange = (state) => {
		onChange(state);
	};

	const isChecked = () => {
		if ((!item.checkedValue && item.checked) || (item.checkedValue && item.checked === item.checkedValue))
			return (true);
		if ((!item.notCheckedValue && !item.checked) || (item.notCheckedValue && item.checked === item.notCheckedValue))
			return (false);
		return (false);
	};

	return (
		<SwitchInput onChange={handleChange} onlyState={true} checked={isChecked()} label={item.label}/>
	);
};

export default AppFunctionsManager;

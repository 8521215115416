import useAccount from "classes/Accounts/hooks/useAccount";
import LoadCont from "components/LoadCont/LoadCont";
import genRequest from "includes/request";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import AccumulationChart from "components/Charts/AccumulationChart/AccumulationChart";
import useTemplate from "hooks/useTemplate";
import { gen_color_palette } from "includes/Colors";
import { PieSeries } from "@syncfusion/ej2-react-charts";
import useTheme from "hooks/useTheme";
import Button from "components/Button/Button";
import { t } from "i18next";
import { CustomSelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
export default function PostsOrigins(props) {
	const { account } = useAccount();
	const [Unified, setUnified] = useState(true);
	const [TypeId, setTypeId] = useState({
		label: t("Analytics.ALL"),
		value: 0,
	});

	const types = [
		{
			label: t("Analytics.ALL"),
			value: 0,
		},
		{
			label: t("Categories.TYPE-post-1"),
			value: 1,
		},
		{
			label: t("Categories.TYPE-post-3"),
			value: 3,
		},
		{
			label: t("Categories.TYPE-post-4"),
			value: 4,
		},
		{
			label: t("Categories.TYPE-post-14"),
			value: 14,
		},
		{
			label: t("Categories.TYPE-post-5"),
			value: 5,
		},
		{
			label: t("Categories.TYPE-post-8"),
			value: 8,
		},
	];

	const isMobile = (origin) => origin?.search(/(iOS|Android)/gi) >= 0;

	const treat_data = (posts) => {
		let ret = [];

		posts.forEach(({ Key }) => {
			const post = Key;
			let adress = post.Adress;
			let mobile = isMobile(adress);

			if (Unified)
				adress = adress?.length
					? mobile
						? "Mobile"
						: "Web"
					: "Non défini";
			let found = ret.findIndex((a) => {
				return (
					a.Adress === adress ||
					(!a.Adress?.length && !adress?.length)
				);
			});

			if (found === -1)
				ret.push({
					Adress: adress,
					Posts: [post],
				});
			else ret[found].Posts.push(post);
		});
		ret.forEach((a) => {
			a.percentage = ((a.Posts.length / posts.length) * 100).toFixed(2);
		});
		return ret;
	};

	// const getReq = () => {
	// 	if (TypeId?.value)
	// 		return {
	// 			queryKey: [
	// 				"RetrievePosts",
	// 				"Posts",
	// 				"PostsOfCompanyTyped",
	// 				TypeId?.value,
	// 				account.CompanyId,
	// 			],
	// 			queryFn: () =>
	// 				genRequest(
	// 					"Posts/PostsOfCompanyTyped/" +
	// 					account.CompanyId +
	// 					"/" +
	// 					TypeId?.value
	// 				),
	// 		};
	// 	return {
	// 		queryKey: [
	// 			"RetrievePosts",
	// 			"Posts",
	// 			"PostsOfCompany",
	// 			account.CompanyId,
	// 		],
	// 		queryFn: () =>
	// 			genRequest("Posts/PostsOfCompany/" + account.CompanyId),
	// 	};
	// };

	const getData = () => {
		return genRequest("Stats/Popular_Post_Of_PostType_In_Period_For_Company", (TypeId.value
			> 0 ? { PostTypeId: TypeId.value, TargetCompanyId: account.CompanyId } : { TargetCompanyId: account.CompanyId }), "POST")
	}

	const { isLoading, data, refetch, isFetching } = useQuery({
		queryKey: "getDataPostType",
		queryFn: getData
	});

	useEffect(() => {
		refetch();
	}, [TypeId.value]);


	// const { isLoading, data } = useQuery(getReq());

	return (
		<div className="stats-cont">
			<div className="d-flex gap-2 justify-content-between">
				<div className="stat-module-title">
					{t("Statistic_Page.Statistic_Origins")}
				</div>
				{(!isLoading && !isFetching) && <div>{data?.length} {t('Statistic_Page.Publication')}</div>}
			</div>
			<div className="d-flex gap-4 mt-2 flex-wrap">
				<div>
					{t("Statistic_Page.Statistic_Type")}:
					<div
						style={{
							width: "200px",
						}}
					>
						<CustomSelect
							options={types}
							value={TypeId}
							initialValue={TypeId}
							onChange={setTypeId}
							className="light-bg"
							style={{
								maxWidth: "100px",
							}}
						/>
					</div>
				</div>
				<div>
					{t("Statistic_Page.Sort")}:
					<div className="buttons-group" style={{ height: "37px" }}>
						<Button
							className={
								"w-auto h-100 " +
								(Unified ? "btn-primary" : "btn-bg")
							}
							onClick={() => setUnified(true)}
						>
							{t("Statistic_Page.Platform")}
						</Button>
						<Button
							className={
								"w-auto h-100 " +
								(!Unified ? "btn-primary" : "btn-bg")
							}
							onClick={() => setUnified(false)}
						>
							{t("Statistic_Page.Origin")}
						</Button>
					</div>
				</div>
			</div>
			{isLoading || isFetching ? (
				<LoadCont />
			) : (
				<Origins
					key={`${TypeId?.value ? "Typed-" + TypeId?.value : "NotTyped"
						}-${Unified ? "Unified" : "not-unified"}`}
					unified={Unified}
					stats={treat_data(data)}
				/>
			)}
		</div>
	);
}

function getChartSpecs(viewType) {
	let chartTypesServices = [{ Services: [PieSeries], Type: "Pie" }];

	let found = chartTypesServices.find((a) => a.Type === viewType);
	return found ? found : {};
}

function Origins({ stats, unified }) {
	const { template } = useTemplate();
	const { getTheme } = useTheme();
	let palettes = gen_color_palette(template.primaryColor(), stats?.length);
	if (!stats?.length)
		return (
			<div className="mt-4 w-100 text-center text-infos">
				{t("Commons.NO_PUBLICATIONS")}
			</div>
		);

	const genSeries = (stats) => {
		return [
			{
				dataSource: stats
					.map((row) => {
						let label =
							row.percentage +
							"% - " +
							(row.Adress || "Non défini");
						let val = row.percentage;
						return {
							x: label,
							y: Number(val),
						};
					})
					.sort((a, b) => (a.y > b.y ? -1 : 1)),
				xName: "x",
				yName: "y",
				innerRadius: "60%",
				tooltip: { enable: true },
				type: "Pie",
				name: "Origine des publications",
				palettes,
				legendShape: "Circle",
			},
		];
	};

	const genTile = (stats) => {
		let series = genSeries(stats);

		let chart = {
			ChartId: "Analytics-Origins" + (unified ? "-unified" : ""),
			series: series,
		};

		let values = {
			series,
			primaryXAxis: {
				title: "",
				valueType: "Category",
				interval: 1,
				majorGridLines: { width: 0 },
				labelStyle: {
					fontFamily: "Poppins-Light",
				},
			},
			primaryYAxis: {
				interval: 1,
				majorGridLines: { width: 1 },
				majorTickLines: { width: 0 },
				lineStyle: { width: 0 },
				// maximum: max_value,
				labelStyle: {
					fontFamily: "Poppins-Light",
				},
			},
			palettes,
			chartArea: { border: { width: 0 } },
		};

		let specs = getChartSpecs("Pie");
		let tile_theme = gestChartTheme(getTheme(), chart, values);

		return (
			<AccumulationChart
				chart={chart}
				values={values}
				specs={specs}
				theme={tile_theme}
			/>
		);
	};

	return genTile(stats);
}

function gestChartTheme(theme, chart, values) {
	let color = !theme || theme === "light" ? "black" : "white";

	if (!values) return false;
	let ret = Object.assign(values, {
		background: "transparent",
		legendSettings: {
			custom: true,
			position: "Right",
			width: "35%",
			textStyle: {
				color: color,
				fontFamily: "Poppins-Regular",
			},
		},
		subTitleStyle: {
			color: color,
		},
		labelStyle: {
			fontFamily: "Poppins-Light",
		},
		tooltip: {
			enable: true,
			textStyle: {
				fontFamily: "Poppins-Light",
			},
		},
	});

	if (ret.primaryXAxis)
		Object.assign(ret.primaryXAxis, {
			labelStyle: { color: color, fontFamily: "Poppins-Light" },
			titleStyle: { color: color, fontFamily: "Poppins-Light" },
		});

	if (ret.primaryYAxis)
		Object.assign(ret.primaryYAxis, {
			labelStyle: { color: color, fontFamily: "Poppins-Light" },
			titleStyle: { color: color, fontFamily: "Poppins-Light" },
		});

	delete ret.title;
	return ret;
}

import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import { toast } from "react-toastify";
import Button from "components/Button/Button";
import genRequest from "includes/request";
import { t } from "i18next";

export default function PasswordReset(props) {

	const { isSup, isAdmin } = useAccount();
	const { user } = props;

	const handleReset = () => {
		toast(
			<div>
				<div>
					{t("Contacts.RESET_PASS_MSG")} <b>{user.FirstName + " " + user.LastName}</b>?<br />
					{t("Contacts.RESET_PASS_MSG2")}
				</div>
				<div className="d-flex gap-2">
					<Button className="btn-red">{t("Commons.CANCEL")}</Button>
					<Button className="btn-green" onClick={resetPass}>{t("Commons.CONFIRM")}</Button>
				</div>
			</div>
		);
	};

	const resetPass = () => {
		let prom = genRequest("Account/ResetPassword/" + user.EmployesId, null, "post");

		toast.promise(
			prom,
			{
				pending: t("Toasts.PROM_PENDING"),
				success: t("Toasts.PROM_SUCCESS"),
				error: t("Toasts.PROM_ERROR"),
				autoClose: 7000
			}
		);
		return (prom);
	};

	if (!isAdmin() || isSup(user.Role) < 0)
		return (false);
	return (
		<div className={props.className}>
			<Button className="btn-bg btn-h-blue" onClick={handleReset}>
				{t("Contacts.RESET_PASS")}
			</Button>
		</div>
	);
}

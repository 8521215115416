import generateUUID from "includes/UUID";

export default function radius_charts_displayer(chart) {
	let values = chart.parsedChartValues();
	let serie_data = values.series[0]?.dataSource;
	let Cols = [];
	let Rows = [];

	for (let x = 0; x < 2; x++)
	{
		let id = x + "-" + generateUUID();
		Cols.push({
			colId: id,
			title: id,
			name: "col-" + id,
			type: "col"
		});
	}

	chart.options("InnerThickness", 100 - parseInt(values.series[0].innerRadius));

	serie_data.forEach((serie, index) => {
		let id = index + "-" + generateUUID();
		let row = {
			rowId: id,
			type: "row",
			color: values.series[0].palettes[index]
		};
		Cols.forEach((col, y) => {
			if (y > 0)
				col.value = serie.x;
			if (y === 0)
				row[col.name] = serie.x;
			else
				row[col.name] = serie.y;
		});
		Rows.push(row);
	});

	return ({
		Cols,
		Rows
	});
}

import React, {useState} from "react";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import SendStep from "components/EditDrawer/SendStep/SendStep";

export default function ChartSendStep(props) {
	const {isAdmin} = useAccount();
	const [isInitied, setIsInitied] = useState(false);
	let chart = props.object;

	React.useEffect(() => {
		if (!isInitied) {
			if (!chart.isDraft())
			{
				if (!isAdmin())
				{
					chart.notification(false);
					chart.isDraft(true);
				}
			} else
				chart.notification(false);
			setIsInitied(true);
		}
	}, [chart, isInitied, setIsInitied, isAdmin]);

	if (!props.isActive || !isInitied)
		return (false);

	const queries = [
		{
			queryKey: ["SendChart", "Chart", chart.title(), chart.lastChanged()],
			queryFn: () => chart.send(),
			title: t("Charts.SENDING"),
			required: true
		},
		{
			queryKey: ["SendNotif", "Chart", chart.title(), chart.lastChanged()],
			queryFn: () => chart.notify(),
			title: t("Commons.SENDING_NOTIF"),
			disabled: (!chart.notification() || chart.isDraft())
		}
	];
	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

import generateUUID from "includes/UUID";
import React, { useState } from "react";

// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import { faCodeBranch} from "@fortawesome/free-solid-svg-icons";

import "./AssignRow.css";
import { getAssignTypes, getNextTypes } from "../AssignTypes";
import { AssignationsList, TypeSelect } from "components/Assignator/Assignator";
import useAccount from "classes/Accounts/hooks/useAccount";
import Button from "components/Button/Button";
import { t } from "i18next";

export default function AssignRow(props) {
	const { item, onDelete, onUpdate, isActive, setCur, isSubAssign } = props;
	const {isSup} = useAccount();
	const [Values, setValues] = useState(item?.values ? item.values : []);
	let AssignTypes = getAssignTypes();

	if (!item)
		return (false);

	const next_types = getNextTypes(item.type.value, isSup);
	const canHaveChilds = ((!item.type.uniqueIfMultiple && next_types.length > 1) || (item.type.uniqueIfMultiple === true && Values.length <= 1));

	function handleDelete(e) {
		e.stopPropagation();
		e.preventDefault();
		if (onDelete)
			onDelete(item);
	}

	function handleAdd(addType, ev) {
		let types = getNextTypes(item.type.value, isSup);

		if (ev) {
			ev.stopPropagation();
			ev.preventDefault();
		}
		if (!types[1])
			return (false);
		let new_type = (types[1] ? types[1] : types[0]);
		let new_assign = {
			rowId: generateUUID(),
			baseType: new_type,
			type: new_type,
			deepLevel: item.deepLevel + 1,
			assigns: [],
			addType,
			ids: item.ids
		};
		new_assign.ids[item.type.itemId] = item[item.type.itemId];
		new_assign[item.type.itemId] = item[item.type.itemId];
		item.assigns.push(new_assign);
		updateItem(item);
	}

	function rmChild(child) {
		let assigns = item.assigns.map(a => {
			if (a.rowId === child.rowId)
				return (false);
			return (a);
		}).filter(a => a);
		item.assigns = assigns;
		updateItem(item);
	}

	function handleTypeChange(new_type) {
		item.assigns = [];
		item.values = [];
		item.type = new_type;
		setValues([]);
		updateItem(item);
	}

	function updateItem(item) {
		if (onUpdate)
			onUpdate(item);
	}

	function updateChild(child) {
		let dup = {...item};

		for (let assi of dup.assigns)
			if (assi.rowId === child.rowId)
			{
				assi = child;
				break ;
			}
		updateItem(dup);
	}

	function changeValues(values) {

		item.values = values;
		if ((item.type.uniqueIfMultiple && values.length > 1) || !values.length)
		{
			item.assigns = [];
			updateItem(item);
		}
		updateItemId(item.type?.itemId, values[0]?.value);
		setValues(values);
	}

	function updateItemId(key, value) {
		let dup = {...item};
		dup.ids[key] = value;
		dup[key] = value;
		if (dup.assigns?.length)
			dup.assigns = dup.assigns.map(a => {
				let dup_val = {...a};
				dup_val[key] = value;
				return (dup_val);
			}).filter(a => a);
		updateItem(dup);
	}

	return (
		<div className={`AssignRow${isActive ? " active" : ""}${!item.deepLevel ? " root-assign" : ""}`} onClick={() => setCur(item)}>
			<div className="row-head">
				<div className="row-type">{item.addType}</div>
			</div>
			<div className="row-body">
				<div className="row-body-head">
					<div className="d-flex align-items-center justify-content-between flex-row mb-2">
						<div className="d-flex align-items-center">
							<TypeSelect
								baseType={(isSubAssign ? item.baseType : false)}
								value={(isSubAssign ? item.type : item.type)}
								onChange={handleTypeChange}
								className="me-2"
								isSubAssign={isSubAssign}
							/>
							{
								item.type.maxItems && (!item.type.limitItemsAfterLevel || item.deepLevel > item.type.limitItemsAfterLevel) ?
									"(" + item.type.maxItems + " max)"
									:
									""
							}
						</div>
						<div className="row-buttons ms-2">
							{
								(item.deepLevel < AssignTypes.length - 1 && canHaveChilds && Values.length > 0) &&
									<div className="d-flex me-2">
										<Button addtype="AND" className="btn btn-blue flex-no-wrap d-flex" onClick={(e) => handleAdd("AND", e)}>
											<FontAwesomeIcon icon={faCodeBranch}/>
											<span className="ms-1">{t("Assignator.ADD_FILTER")}</span>
										</Button>
										{/* <Button addtype="OR" className="btn btn-blue" onClick={handleAdd}>Or</Button> */}
									</div>
							}
							<Button className="btn btn-red" onClick={handleDelete}>
								<FontAwesomeIcon icon={faTrashAlt}/>
							</Button>
						</div>
					</div>
					<AssignSelect item={item} onChange={changeValues}/>
				</div>
				{
					(canHaveChilds && Values.length > 0) &&
					<AssignationsList
						className="mt-2"
						items={item.assigns}
						isSubAssigns={true}
						{...props}
						onUpdate={updateChild}
						onDelete={rmChild}
					/>
				}
			</div>
		</div>
	);
}

function AssignSelect({item, onChange}) {
	let types = getAssignTypes();

	for (let type of types)
		if (type.value === item.type.value)
			return (<type.component {...item} {...item.ids} item={item} type={type} maxItems={type.maxItems} initialValue={item.values} onChange={onChange}/>);
	return (false);
}

const isBase64 = function (string) {
	let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

	try {
		let check = base64regex.test(string);
		return (check);
	} catch (e) {
		return (false);
	}
};

const gestBase64 = function (string) {
	try {
		if (isBase64(string)) {
			return atob(string);
		} else
			return (string);
	} catch (e) {
		return (string);
	}
};

const b64ToText = function (string) {
	try {
		let gest_string = gestBase64(string);
		if (!isBase64(gest_string))
			return (gest_string);
		return decodeURIComponent(atob(gest_string).split("").map(function (c) {
			return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(""));
	} catch (e) {
		return (false);
	}
};

const toBase64 = function (string) {
	return btoa(encodeURIComponent(string).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode("0x" + p1);
		}
	));
};

const b64ToBlob = (b64Data, contentType = "", sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, {
		type: contentType
	});
	return blob;
};

module.exports = {
	b64ToText,
	toBase64,
	isBase64,
	b64ToBlob
};

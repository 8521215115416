import React, { useState } from "react";
import { useQuery } from "react-query";
import { linkify } from "includes/linkify";
import {
	BarSeries,
	ColumnSeries,
	PieSeries,
} from "@syncfusion/ej2-react-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faChevronLeft,
	faChartPie,
	faTable,
	faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { faChartBar, faEdit } from "@fortawesome/free-regular-svg-icons";
import useTheme from "hooks/useTheme";
import { gen_color_palette } from "includes/Colors";
import useTemplate from "hooks/useTemplate";
import FormFetcher from "classes/Forms/components/FormFetcher/FormFetcher";
import "./FormAnswersPreview.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import Button from "components/Button/Button";
import ScrollTable from "components/ScrollTable/ScrollTable";
import exportFormAnswers from "./AnswersExporter";
import UserAnswerSelect from "./UserAnswerSelect";
import genRequest from "includes/request";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import AccumulationChart from "components/Charts/AccumulationChart/AccumulationChart";
import BasicChart from "components/Charts/BasicChart/BasicChart";
import NumberChart from "components/Charts/Number/NumberChart";
import getQuestionsTypes from "classes/Forms/QuestionsTypes";
import { TypeRights, returnRightsFromName } from "includes/rightType";

export default function FormAnswersPreview({ formId, handleEdit, handleFill }) {
	const [scrolled, setScrolled] = useState(false);

	function handleScroll(e) {
		let cont = e.target;
		let userCont = cont.querySelector(".user-select-cont");
		let header = cont.querySelector(".FormHeader");
		let sep = cont.querySelector(".header-sep");

		let bp = sep.offsetTop;
		if (cont.scrollTop < bp && scrolled) {
			userCont.style.width = null;
			userCont.style.margin = null;
			header.style.paddingBottom = null;
			setScrolled(false);
		} else if (cont.scrollTop > bp) {
			userCont.style.width = "calc(100% - 40px)";
			userCont.style.margin = "40px 20px 20px";
			userCont.style.top = cont.scrollTop + "px";
			if (!scrolled) setScrolled(userCont.offsetHeight);
		}
	}

	return (
		<div className="FormPreview" onScroll={handleScroll}>
			<FormFetcher formId={formId} withAnswers={true}>
				{(data) => {
					return (
						<FormAnswers
							handleEdit={handleEdit}
							handleFill={handleFill}
							scrolled={scrolled}
							form={data}
						/>
					);
				}}
			</FormFetcher>
		</div>
	);
}

function FormAnswers({ form, handleEdit, handleFill, scrolled }) {
	const { isAdmin, account } = useAccount();
	const { Sections, Questions, Answers } = form;
	const [curUser, setCurUser] = useState({
		value: 0,
		label: t("Forms.ALL_ANSWERS"),
	});
	const [Users, setUsers] = useState([]);
	let resp_length = Answers?.length;

	const checkRight = () => {
		const rigthName = TypeRights.AdminForms;
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	function exportAnswers() {
		exportFormAnswers(
			`${t("Forms.ANSWERS")} - ${form.Title}`,
			Questions,
			Answers,
			form.IsAnonymous,
			Users
		);
	}

	function getOptions() {
		let options = [];
		if (Users?.length)
			options = Users?.map((a, b) => ({
				value: a.EmployesId,
				label: form.IsAnonymous
					? t("Forms.USER") + " " + (b + 1)
					: a.FirstName + " " + a.LastName,
				user: a,
				isAnonymous: form.IsAnonymous,
			}))
				.sort((a, b) => (a.label < b.label ? -1 : 1))
				.map((a, b) => ({ ...a, index: b + 1 }));
		options.unshift({ value: 0, label: t("Forms.ALL_ANSWERS") });
		return options;
	}

	function getOthers(dir) {
		let options = getOptions();
		if (options?.length < 2) return false;
		if (curUser) {
			let opt = options[(curUser.index + dir) % options.length];
			if (opt?.value) return opt;
			else if (dir < 0) return options[options.length - 1];
			else return options[1];
		}
		return false;
	}

	let linkifiedElements = linkify(form.Txt);

	let prev = getOthers(-1);
	let next = getOthers(1);

	const { data, isLoading } = useQuery(
		["Employes", "EmployeAnsweredForm", form.FormId],
		() => genRequest("Employes/EmployeAnsweredForm/" + form.FormId),
		{
			enabled: !form.IsAnonymous,
			onSettled: (resp) => setUsers(resp),
			onError: () => setUsers([]),
		}
	);

	const isExpired = () => {
		if (form.AvailableToDate === null) return false;
		const givenDate = new Date(form.AvailableToDate);
		const currentDate = new Date();

		return givenDate < currentDate;
	};

	return (
		<div style={{ maxWidth: "1000px" }}>
			<div
				className={
					"FormHeader mb-3" +
					(scrolled && curUser.value !== 0 ? " scrolled" : "")
				}
				style={
					scrolled && curUser.value !== 0
						? { paddingBottom: scrolled + "px" }
						: {}
				}
			>
				<div className="d-flex flex-wrap justify-content-between mb-2">
					<div className="me-2">
						<div className="form-title font-bold">{form.Title}</div>
						<div className="form-desc">{linkifiedElements}</div>
					</div>
				</div>
				<div className="d-flex flex-wrap justify-content-between align-items-center mb-2 gap-2">
					<div className="d-flex form-infos">
						<div className="quest-count">
							{t("Forms.QUESTIONS", { count: Questions?.length })}
						</div>
						<div className="mx-2">-</div>
						<div className="anwsers-count">
							{t("Forms.ANSWERS", { count: resp_length })}
						</div>
					</div>
					<div className="d-flex ms-auto gap-1 gap-md-2 flex-wrap col-12 col-sm-auto">
						{isAdmin() && checkRight() && handleEdit && (
							<div className="col-12 col-sm-auto">
								<Button
									className="btn btn-blue gap-1"
									onClick={() => handleEdit(form)}
								>
									<FontAwesomeIcon
										icon={faEdit}
										style={{
											marginBottom: "1px",
											fontSize: "0.7rem",
										}}
									/>
									{t("Forms.EDIT_FORM")}
								</Button>
							</div>
						)}
						{isAdmin() &&
							checkRight() &&
							Answers?.length > 0 &&
							data?.length &&
							!isLoading && (
								<div className="col-12 col-sm-auto">
									<Button
										className="btn btn-blue gap-1"
										onClick={() => exportAnswers()}
									>
										<FontAwesomeIcon
											icon={faUpload}
											style={{
												marginBottom: "1px",
												fontSize: "0.7rem",
											}}
										/>
										{t("Forms.EXPORT_ANSWERS")}
									</Button>
								</div>
							)}
						<div className="col-12 col-sm-auto">
							{!isExpired() && (
								<Button
									className="btn btn-green"
									onClick={() => handleFill(form)}
								>
									{t("Forms.ANSWER_FORM")}
								</Button>
							)}
						</div>
					</div>
				</div>
				<hr className="header-sep" />
				<div
					className={
						"user-select-cont" +
						(curUser.value !== 0 ? " hasCurUser" : "")
					}
				>
					<div className="d-flex align-items-center justify-content-between mb-1">
						<div>{t("Forms.DISP_PER_USER")}</div>
						<div className="d-flex ms-2 gap-2">
							{prev && (
								<Button
									className="unstyled"
									onClick={() => setCurUser(prev)}
									title={t("Posts.NEXT_POST")}
								>
									<FontAwesomeIcon icon={faChevronLeft} />
								</Button>
							)}
							{next && (
								<Button
									className="unstyled"
									onClick={() => setCurUser(next)}
									title={t("Posts.NEXT_POST")}
								>
									<FontAwesomeIcon icon={faChevronRight} />
								</Button>
							)}
						</div>
					</div>
					<div>
						<UserAnswerSelect
							formId={form.FormId}
							isAnonymous={form.IsAnonymous}
							onChange={setCurUser}
							initialValue={curUser ? curUser : false}
							value={curUser ? curUser : false}
							externValue={true}
						/>
					</div>
				</div>
			</div>
			<SectionsList
				curUser={curUser}
				setCurUser={setCurUser}
				getOptions={getOptions}
				sections={Sections}
				isAnonymous={form.IsAnonymous}
			/>
		</div>
	);
}

function SectionsList(props) {
	const { sections } = props;

	if (sections.length < 2)
		return <QuestionsList {...props} questions={sections[0].Questions} />;
	return (
		<div className="d-flex flex-column gap-5">
			{sections.map((a, b) => {
				return (
					<div className="SectionViewer" key={a.SectionId}>
						{b > 0 && (
							<div className="section-viewer-head mb-3">
								<div className="section-tag mb-2">{`${a.Title}`}</div>
								{a.Txt?.length > 0 && (
									<div className="section-viewer-desc">
										{a.Txt}
									</div>
								)}
								<div className="form-infos mt-1">
									{t("Forms.QUESTIONS", {
										count: a.Questions?.length,
									})}
								</div>
							</div>
						)}
						<QuestionsList {...props} questions={a.Questions} />
					</div>
				);
			})}
		</div>
	);
}

function QuestionsList({
	questions,
	curUser,
	setCurUser,
	getOptions,
	isAnonymous,
}) {
	if (!questions?.length) return false;

	function handleUserSelect(userId) {
		let options = getOptions().filter((a) => a.value === userId);
		if (options?.length) setCurUser(options[0]);
		return options?.length ? options[0] : false;
	}

	return (
		<div className="QuestionsList">
			{questions.map((a) => (
				<QuestionViewer
					curUser={curUser?.value !== 0 ? curUser : false}
					setCurUser={handleUserSelect}
					question={a}
					key={a.QuestionId}
					isAnonymous={isAnonymous}
				/>
			))}
		</div>
	);
}

function QuestionViewer({ question, curUser, setCurUser, isAnonymous }) {
	const { Answers } = question;

	function getType(designId) {
		let QuestionsTypes = getQuestionsTypes();
		let check = QuestionsTypes.find((a) => a.QuestionDesignId === designId);
		return check ? check : false;
	}

	const type = getType(question.QuestionDesignId);

	function getAnswersCount() {
		if (!Answers?.length) return 0;
		let count = 0;
		let list = [];

		Answers.forEach((a) => {
			if (list.indexOf(a.EmployesId) === -1) {
				list.push(a.EmployesId);
				count++;
			}
		});
		return count;
	}

	function getUserAnswers() {
		if (!Answers?.length || !curUser.value) return false;
		let res = Answers.map((a) =>
			a.EmployesId === curUser.value ? a : false
		).filter((a) => a);
		return res?.length ? res : false;
	}

	let count = getAnswersCount();

	return (
		<div className="QuestionViewer">
			{question.ImgUrl && (
				<div className="question-image ml-auto mb-2">
					<img
						alt={question.Title}
						style={{
							maxHeight: "60vh",
							maxWidth: "100%",
							width: "auto",
						}}
						className="radius-1 d-block m-auto"
						src={question.ImgUrl}
					/>
				</div>
			)}
			<div className="question-title font-bold">{question.Title}</div>
			<div className="question-text mb-2">{linkify(question.Txt)}</div>
			{!curUser && (
				<div className="form-infos">
					{t("Forms.ANSWERS", { count })}
				</div>
			)}
			<div className="mt-2">
				{Answers?.length > 0 ? (
					<type.Viewer
						question={question}
						curUser={curUser}
						userAnswers={getUserAnswers()}
						setCurUser={setCurUser}
						isAnonymous={isAnonymous}
					/>
				) : (
					<div style={{ fontSize: "0.8rem", color: "#777777" }}>
						{t("Forms.NO_ANSWER")}
					</div>
				)}
			</div>
		</div>
	);
}

export function TextQuestionViewer({
	question,
	curUser,
	setCurUser,
	userAnswers,
	isAnonymous,
}) {
	const { Answers } = question;

	return (
		<div className="AnswersList">
			{curUser && !userAnswers ? (
				<div>{t("Forms.NO_USER_ANS")}</div>
			) : (
				Answers.map((ans) => {
					if (!curUser || curUser.value === ans.EmployesId)
						return (
							<div
								key={
									ans.QuesitonId +
									"-" +
									ans.OptionId +
									"-" +
									ans.EmployesId
								}
								className={
									"text-answer" +
									(curUser?.value !== ans.EmployesId
										? " cursor-pointer"
										: "")
								}
								onClick={() =>
									curUser.value !== ans.EmployesId
										? setCurUser(ans.EmployesId)
										: false
								}
							>
								{!isAnonymous && (
									<>
										<AuthorPreview
											employeId={ans.EmployesId}
											format={[
												"EmployeFirstName EmployeLastName",
											]}
											imgWidth={"20px"}
										/>
										<hr className="m-1" />
									</>
								)}
								{ans.Value}
							</div>
						);
					return false;
				})
			)}
		</div>
	);
}

export function RangeQuestionViewer({ question, curUser, userAnswers }) {
	const { Answers, Options } = question;
	let max = Options?.length ? Options[0].MaxValue : 0;
	let limits = getMinMax();

	function getMinMax() {
		if (!Answers?.length) return { min: 0, max: 0 };
		let sorted = Answers.sort((a, b) => Number(a.Value) - Number(b.Value));
		return {
			min: Number(sorted[0].Value),
			max: Number(sorted[sorted.length - 1].Value),
		};
	}

	function getMedian() {
		if (!Answers?.length) return 0;
		let total = 0;
		Answers.forEach((a) => (total += Number(a.Value)));
		return total / Answers.length;
	}

	function getCorrectColor(value) {
		let valPercent = ((value / max) * 100).toFixed(2);
		if (valPercent > 40 && valPercent < 70) {
			return "orange";
		} else if (valPercent >= 70) {
			return "green";
		}
		return "red";
	}

	if (curUser) {
		return (
			<div className="AnswersList d-flex justify-content-start">
				<div className="range-bloc d-flex flex-column text-center">
					{userAnswers?.length ? (
						<>
							<div className="mb-2">
								{t("Forms.ANSWERS", {
									count: userAnswers.length,
								})}
							</div>
							<NumberChart
								duration={800}
								fontSize={20}
								className={"range-chart"}
								color={getCorrectColor(userAnswers[0].Value)}
								value={userAnswers[0].Value}
								label={"/" + max}
								fixedTo={1}
							/>
						</>
					) : (
						<div>{t("Forms.NO_USER_ANS")}</div>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className="AnswersList d-flex justify-content-start">
			<div className="range-bloc d-flex flex-column text-center">
				<div className="mb-2">{t("Forms.LOWEST_ANS")}</div>
				<NumberChart
					fixedTo={1}
					duration={800}
					fontSize={20}
					className={"range-chart"}
					color={"red"}
					value={limits.min * 1}
					label={"/" + max}
				/>
			</div>
			<div className="range-bloc d-flex flex-column text-center mx-2">
				<div className="mb-2">{t("Forms.MEDIAN_ANS")}</div>
				<NumberChart
					fixedTo={1}
					duration={800}
					fontSize={20}
					className={"range-chart"}
					color={"orange"}
					value={getMedian()}
					label={"/" + max}
				/>
			</div>
			<div className="range-bloc d-flex flex-column text-center">
				<div className="mb-2">{t("Forms.HIGHEST_ANS")}</div>
				<NumberChart
					fixedTo={1}
					duration={800}
					fontSize={20}
					className={"range-chart"}
					color={"green"}
					value={limits.max}
					label={"/" + max}
				/>
			</div>
		</div>
	);
}

function IndividualChoiceQuestionViewer({ userAnswers }) {
	if (!userAnswers?.length)
		return <div className="text-answer">{t("Forms.NO_USER_ANS")}</div>;
	return (
		<div>
			{userAnswers
				.sort((a, b) => (a.OptionId < b.OptionId ? -1 : 1))
				.map((a) => {
					return (
						<div
							key={
								a.QuesitonId +
								"-" +
								a.OptionId +
								"-" +
								a.EmployesId
							}
							className={"text-answer"}
						>
							{a.Value}
						</div>
					);
				})}
		</div>
	);
}

export function ChoicesQuestionViewer({ question, curUser, userAnswers }) {
	const { getTheme } = useTheme();
	const { template } = useTemplate();
	const [viewType, setViewType] = useState("Column");
	const { Answers, Options } = question;
	let palettes = gen_color_palette(template.primaryColor(), Options?.length);
	let max_value = -Infinity;

	if (curUser)
		return <IndividualChoiceQuestionViewer userAnswers={userAnswers} />;

	function getSeries(noSummary) {
		if (viewType === "Pie")
			return [
				{
					dataSource: Options.map((opt) => {
						let count = Answers.map(
							(ans) => ans.OptionId === opt.OptionId
						).filter((a) => a).length;
						let ratio =
							Answers?.length > 0
								? ((count / Answers?.length) * 100).toFixed(1)
								: 0;
						// if (!count)
						// 	return (false)
						if (count > max_value) max_value = count;
						return {
							x:
								opt.Value +
								(!noSummary ? ` - ${count} (${ratio}%)` : ""),
							y: count,
							text:
								opt.Value +
								(!noSummary ? ` - ${count} (${ratio}%)` : ""),
							visible: count > 0,
						};
					})
						.filter((a) => a)
						.sort((a, b) => (a.x < b.x ? -1 : 1)),
					xName: "x",
					yName: "y",
					innerRadius: "60%",
					tooltip: { enable: true },
					type: viewType,
					name: question.Title,
					palettes,
					legendShape: "Circle",
				},
			];
		let series = Options.map((opt) => {
			let count = Answers.map(
				(ans) => ans.OptionId === opt.OptionId
			).filter((a) => a).length;
			let ratio =
				Answers?.length > 0
					? ((count / Answers?.length) * 100).toFixed(1)
					: 0;
			// if (!count)
			// 	return (false);
			if (count > max_value) max_value = count;
			return {
				name: opt.Value + (!noSummary ? ` - ${count} (${ratio}%)` : ""),
				dataSource: [
					{
						x: " ",
						y: count,
					},
				],
				xName: "x",
				yName: "y",
				width: 0.5,
				height: 0.5,
				optionId: opt.OptionId,
				marker: {
					dataLabel: {
						visible: true,
						position: "Top",
						font: {
							fontFamily: "Poppins-Light",
						},
					},
				},
				columnWidth: 0.5,
				columnSpacing: 0.2,
				legendShape: "Circle",
				type: viewType,
				visible: count > 0,
				count,
			};
		})
			.filter((a) => a)
			.sort((a, b) => (a.count < b.count ? -1 : 1));
		return series;
	}

	const getCont = () => {
		let series = getSeries(viewType === "Table");

		if (viewType === "Table")
			return <ChoiceQuestionTable series={series} />;

		let chart = {
			ChartId: "AnswerPreview-QuestionId-" + question.QuestionId,
			series: series,
		};

		let values = {
			series,
			primaryXAxis: {
				title: "",
				valueType: "Category",
				interval: 1,
				majorGridLines: { width: 0 },
				labelStyle: {
					fontFamily: "Poppins-Light",
				},
			},
			primaryYAxis: {
				interval: 1,
				majorGridLines: { width: 1 },
				majorTickLines: { width: 0 },
				lineStyle: { width: 0 },
				maximum: max_value,
				labelStyle: {
					fontFamily: "Poppins-Light",
				},
			},
			palettes,
			chartArea: { border: { width: 0 } },
		};

		let specs = getChartSpecs(viewType);

		specs.Directives = {
			dataLabel: {
				visible: true,
				position: "Inside",
				name: "y",
				font: {
					fontFamily: "Poppins-Light",
				},
			},
		};

		let tile_theme = gestChartTheme(getTheme(), chart, values);

		let maxHeight =
			series?.length * 60 < 500
				? (series?.length * 60).toString()
				: "500";
		// let maxWidth = (series?.length * 100 < 500 ? (series?.length * 100).toString() : "500")
		// if (viewType === "Column")
		// 	tile_theme.width = maxWidth
		if (viewType === "Bar") tile_theme.height = maxHeight;

		if (viewType === "Pie")
			return (
				<AccumulationChart
					chart={chart}
					values={values}
					specs={specs}
					theme={tile_theme}
					legendSettings={tile_theme.legendSettings}
				/>
			);
		return (
			<BasicChart
				chart={chart}
				values={values}
				specs={specs}
				theme={tile_theme}
				legendSettings={tile_theme.legendSettings}
			/>
		);
	};

	return (
		<div>
			<div className="d-flex">
				<div>
					<div className="mb-1">{t("Forms.VIEW")}</div>
					<div className="linked-buttons buttons-group">
						<Button
							onClick={() => setViewType("Column")}
							className={
								"unstyled" +
								(viewType === "Column" ? " active" : "")
							}
							title={t("Forms.BARS_VIEW")}
						>
							<FontAwesomeIcon icon={faChartBar} />
						</Button>
						<Button
							onClick={() => setViewType("Pie")}
							className={
								"unstyled" +
								(viewType === "Pie" ? " active" : "")
							}
							title={t("Forms.PIE_VIEW")}
						>
							<FontAwesomeIcon icon={faChartPie} />
						</Button>
						<Button
							onClick={() => setViewType("Table")}
							className={
								"unstyled" +
								(viewType === "Table" ? " active" : "")
							}
							title={t("Forms.TABLE_VIEW")}
						>
							<FontAwesomeIcon icon={faTable} />
						</Button>
					</div>
				</div>
			</div>
			<div className="mt-2">{getCont()}</div>
		</div>
	);
}

function ChoiceQuestionTable(props) {
	const { series } = props;
	let items = [];

	if (series?.length)
		items = series.map((a) => ({
			title: a.name,
			optionId: a.optionId,
			answers: a.dataSource[0].y,
		}));

	return (
		<div
			style={{
				maxWidth: "800px",
			}}
		>
			<ScrollTable
				items={items}
				cols={[
					{
						title: t("Forms.OPTION"),
						name: "title",
					},
					{
						title: t("Forms.ANSWERS"),
						name: "answers",
						classes: "font-bold",
					},
				]}
				sortBy="answers"
				revSort={true}
				itemId="optionId"
				className="AnswersTable light-bg"
				mouseover={false}
				endText={false}
			/>
		</div>
	);
}

function getChartSpecs(viewType) {
	let chartTypesServices = [
		{ Services: [ColumnSeries], Type: "Column" },
		{ Services: [BarSeries], Type: "Bar" },
		{ Services: [PieSeries], Type: "Pie" },
		// {Services: [], Type: "Text"}
	];

	let found = chartTypesServices.find((a) => a.Type === viewType);
	return found ? found : {};
}

function gestChartTheme(theme, chart, values) {
	let color = !theme || theme === "light" ? "black" : "white";

	if (!values) return false;
	let ret = Object.assign(values, {
		background: "transparent",
		legendSettings: {
			custom: true,
			position: "Right",
			width: "30%",
			textStyle: {
				color: color,
				fontFamily: "Poppins-Regular",
			},
		},
		subTitleStyle: {
			color: color,
		},
		labelStyle: {
			fontFamily: "Poppins-Light",
		},
		tooltip: {
			enable: true,
			textStyle: {
				fontFamily: "Poppins-Light",
			},
		},
	});

	if (ret.primaryXAxis)
		Object.assign(ret.primaryXAxis, {
			labelStyle: { color: color, fontFamily: "Poppins-Light" },
			titleStyle: { color: color, fontFamily: "Poppins-Light" },
		});

	if (ret.primaryYAxis)
		Object.assign(ret.primaryYAxis, {
			labelStyle: { color: color, fontFamily: "Poppins-Light" },
			titleStyle: { color: color, fontFamily: "Poppins-Light" },
		});

	delete ret.title;
	return ret;
}

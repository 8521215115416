import {
	AxesTitles,
	ChartColors,
	ChartRanges,
	ChartThickness,
	ChartValuesOption
} from "./ChartOptions";

const ChartOptionsList = [
	{
		id: "AxesTitle",
		component: AxesTitles
	},
	{
		id: "Thickness",
		component: ChartThickness,
		childs: [
			"InnerThickness",
			"OutterThickness"
		]
	},
	{
		id: "Ranges",
		component: ChartRanges
	},
	{
		id: "Colors",
		childs: [
			"PrimaryColor",
			"SecondaryColor"
		],
		component: ChartColors
	},
	{
		id: "Values",
		childs: [
			"Value",
			"Label"
		],
		component: ChartValuesOption
	}
];

export default ChartOptionsList;

import React from "react";
import TagsList, { QueryTagsList } from "components/Tags/TagsList/TagsList";
import genRequest from "includes/request";

export default function PostCategories(props) {

	const {post} = props;

	if (props.categories)
	{
		return (
			<TagsList
				items={props.categories}
			/>
		);
	}
	return (
		<QueryTagsList
			queryKey={
				(props.animationId) ?
					["Categories", "CategoriesOfAnimation", props.animationId]
					:
					["Categories", "Post", post.PostId]
			}
			queryFn={
				(props.animationId) ?
					() => genRequest("Categories/CategoriesOfAnimation/" + props.animationId)
					:
					() => genRequest("Categories/Post/" + post.PostId)
			}
			queryOptions={{
				enabled: (post?.PostId ? true : false),
				retry: 1,
			}}
		/>
	);
}

import React, {memo} from "react";
import {
	Category,
	ChartComponent,
	DataLabel,
	Inject,
	Legend,
	Tooltip,
	SeriesCollectionDirective,
	SeriesDirective
} from "@syncfusion/ej2-react-charts";

const BasicChart = memo(({chart, specs, values, theme}) => {
	let base_services = [DataLabel, Category, Legend, Tooltip];
	let services = specs?.Services || {};
	return (
		<ChartComponent
			id={"chartId-" + chart.ChartId}
			valueType='Category'
			primaryXAxis={values.primaryXAxis}
			primaryYAxis={values.primaryYAxis}
			palettes={values.palettes}
			title=""
			{...theme}
		>
			<Inject services={[...base_services, ...services]}/>
			<SeriesCollectionDirective>
				{
					values?.series?.map((serie, index) => {
						let parsed_datas = serie?.dataSource.map(a => {
							return ({
								x: Number(a.x),
								y: Number(a.y),
							});
						});
						return (
							<SeriesDirective
								key={index}
								dataSource={parsed_datas}
								type={specs.Type}
								{...serie}
							/>
						);
					})
				}

			</SeriesCollectionDirective>
		</ChartComponent>
	);
});
BasicChart.displayName = "BasicChart";

export default BasicChart;

import React from "react";
import {useQueryClient} from "react-query";
import { t } from "i18next";
import Button from "components/Button/Button";
import Moment from "react-moment";
import "moment/locale/fr";
import AsyncPostPreview from "Pages/Posts/components/PostPreview/AsyncPostPreview";

export default function PostEndStep(props) {

	let post = props.object;
	const queryClient = useQueryClient();

	if (!props.isActive)
		return (false);

	function genPreviewText() {
		if (post.isDiffered() && post.isPublished())
			return (
				<div className="d-flex">
					<Moment
						locale={t("Code")}
						titleFormat={t("Events.DATE_TIME_AT_FORMAT")}
						format={"[" + t("Posts.POST_DIFFERED") + "] " + t("Events.DATE_TIME_AT_FORMAT") + "."}
						withTitle
					>
						{post.publishedDate()}
					</Moment>
				</div>
			);
		else if (post.isPublished())
			return (t("Posts.POST_PUBLISHED"));
		else {
			if (!post.draft())
			{
				return (
					<div>
						<div>{t("Posts.POST_MODERATION")}</div>
						<div>{t("Posts.POST_MODERATION_2")}</div>
					</div>
				);
			} else {
				return (
					<div>
						<div>{t("Posts.POST_DRAFTED")}</div>
						<div><Button className="btn-primary" onClick={props.dispDrafts}>{t("Commons.SHOW_DRAFTS")}</Button></div>
					</div>
				);
			}
		}
	}

	queryClient.resetQueries(["FullPostnDoc", post.postId()]);

	return (
		<div className="d-flex flex-column flex-md-row justify-content-between overflow-auto inner-step-cont">
			<div className="ms-2">
				{
					genPreviewText()
				}
			</div>
			<AsyncPostPreview
				id="post-preview-after-send"
				className="mt-3 mt-md-0"
				extend={false}
				extendImages={false}
				responsive={false}
				postId={post.postId()}
				showComments={false}
			/>
		</div>
	);
}

import React, { Component } from "react";
import SitesTable from "./SitesTable/SitesTable";
import SitesTiles from "./SiteTiles/SiteTiles";

export default class SitesList extends Component {

	constructor (props) {
		super (props);
		this.ref = React.createRef();

		this.state = {
			Sites: (props.items ? props.items: []),
			Displayed: [],
			DispIndex: 0,
			DispStep: 20,
			HasMore: true,
		};

		this.getNextItems = this.getNextItems.bind(this);
	}

	componentDidMount() {
		this.getNextItems();
	}

	componentDidUpdate(old_props) {
		let old_sites = old_props.items || [];
		let new_sites = this.props.items || [];
		let addeds = (new_sites ? new_sites?.filter(o1 => !old_sites.some(o2 => o1.SiteId === o2.SiteId)) : []);
		let removed = (old_sites ? old_sites?.filter(o1 => !new_sites.some(o2 => o1.SiteId === o2.SiteId)) : []);

		if (old_props.state !== this.props.state || addeds.length || removed.length || old_sites?.length !== new_sites?.length)
		{
			this.setState({
				Sites: this.props.items,
				Displayed: [],
				DispIndex: 0,
				DispStep: 20,
				HasMore: (this.props.items?.length ? true : false),
				CurPost: false
			}, () => {
				this.getNextItems();
			});
		}
	}

	getNextItems(max_limit, callback) {
		let disp_count = 0;
		let total_count = 0;
		let items = [];
		let sites = this.state.Sites;

		for (let x = this.state.DispIndex; disp_count < this.state.DispStep && x < sites.length && (!max_limit || (max_limit && disp_count < max_limit)); x++)
		{
			let site = sites[x];
			total_count++;
			disp_count++;
			items.push(site);
		}

		this.setState({
			Displayed: [...this.state.Displayed, ...items],
			DispIndex: this.state.DispIndex + total_count,
			HasMore: (!this.state.Sites?.length || (!max_limit && disp_count < this.state.DispStep)) ? false : true
		}, callback);
		return (items);
	}

	render() {

		let childProps = {
			curSite: this.props.curSite,
			displayed: this.state.Displayed,
			hasMore: this.state.HasMore,
			handleItemClick: this.props.handleItemClick,
			getNextItems: this.getNextItems,
			search: this.props.search,
			checkbox: this.props.checkbox
		};

		return (
			<div className={"SitesList w-100"}>
				{
					this.props.display === "table" ?
						<SitesTable setSelected={this.props.setSelected} itemsLength={this.state.Displayed?.length} {...childProps}/>
						:
						<SitesTiles itemsLength={this.state.Displayed?.length} {...childProps}/>
				}
			</div>
		);
	}
}

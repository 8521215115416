/*
 ** - Colors
 */

export const randomColor = () => {
	let sample = "0123456789ABCDEF";
	let color = "#";
	for (let x = 0; x < 6; x++)
		color = color + sample[parseInt(Math.random() * 16)];
	return color;
};

export const getMedianColor = (color) => {
	if (!color)
		return (0);
	color = color.replace("#", "").match(/[0-9A-Fa-f]{2}/g, 2);
	let total = 0;
	for (let x = 0; x < color.length; x++) {
		total += parseInt(color[x], 16);
	}

	return total / color.length;
};

export const strToRGB = (strColor) => {
	strColor = strColor.replace(/(rgb\(|rgba\()/gi, "").split(",");
	let color = [parseInt(strColor[0]), parseInt(strColor[1]), parseInt(strColor[2])];
	return color;
};

export const hexToRgb = (hex) => {
	if (hex.indexOf("#") >= 0)
		hex = hex.substring(1);
	let bigint = parseInt(hex, 16);
	let r = (bigint >> 16) & 255;
	let g = (bigint >> 8) & 255;
	let b = bigint & 255;

	return r + "," + g + "," + b;
};

export const rgbToHex = (r, g, b) => {
	let red = intToHex(r);
	let green = intToHex(g);
	let blue = intToHex(b);
	return red + green + blue;
};

export const pickHex = (color1, color2, weight) => {
	let p = weight;
	let w = p * 2 - 1;
	let w1 = (w / 1 + 1) / 2;
	let w2 = 1 - w1;
	let rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
		Math.round(color1[1] * w1 + color2[1] * w2),
		Math.round(color1[2] * w1 + color2[2] * w2)
	];
	return rgb;
};

export const intToHex = (nbr) => {
	let hex = Number(nbr).toString(16);
	if (hex.length < 2)
		hex = "0" + hex;
	return hex;
};

export const gen_color_palette = (main_grad_color, steps = 30) => {
	let gradient = [
		[0, [0, 0, 0]],
		[50, hexToRgb(main_grad_color).split(",").map(a => Number(a))],
		[100, [255, 255, 255]]
	];
	let res = [];
	let sliderWidth = 500;
	for (let x = 1; x < 100; x += 100 / steps) {
		let colorRange = [];
		gradient.forEach((value, index) => {
			if (x <= value[0] && !colorRange.length) {
				colorRange = [index - 1, index];
				return (false);
			}
		});
		//Get the two closest colors
		let firstcolor = gradient[colorRange[0]][1];
		let secondcolor = gradient[colorRange[1]][1];
		//Calculate ratio between the two closest colors
		let firstcolor_x = sliderWidth * (gradient[colorRange[0]][0] / 100);
		let secondcolor_x = sliderWidth * (gradient[colorRange[1]][0] / 100) - firstcolor_x;
		let slider_x = sliderWidth * (x / 100) - firstcolor_x;
		let ratio = slider_x / secondcolor_x;
		let result = pickHex(secondcolor, firstcolor, ratio);
		res.push("#" + rgbToHex(result[0], result[1], result[2]));
	}
	return (res);
};

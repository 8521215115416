import React, {useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import Creatable from "react-select/creatable";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import { QuerySelect } from "./CustomAsyncSelect";
import noAccent from "includes/no_accents";

export function CreatableSelect(props) {
	const [initialValues, setInitialValues] = useState(props?.initialValue ? props.initialValue : []);
	const [options, setOptions] = useState(false);
	const [newOptions, setNewOptions] = useState([]);
	const QCL = useQueryClient();

	const handleSelect = (options, new_value) => {
		if (props?.max && (options?.length > props?.max))
			return (false);
		if (props.isMulti === true)
			multiSelect(options, new_value);
		else {
			if (props.onSelect)
				props.onSelect((options.value ? options.value : options));
			if (props.onChange)
				props.onChange(options);
			setInitialValues(options);
		}
	};

	function multiSelect(selected, new_value) {
		let new_values = selected;
		let option = new_value?.option;

		if (option?.value === "ALL")
			new_values = options?.filter((a => (a.value !== "ALL" ? a : false))).sort((a, b) => (a.label < b.label ? -1 : 1));
		if (props.onSelect)
			props.onSelect(new_values);
		if (props.onChange)
			props.onChange(new_values);
		setInitialValues(new_values);
	}

	function setValues (items) {
		let initValues = props.initialValue;
		if (!initValues?.length)
			return (false);
		if (typeof initValues === "string" || !initValues.length)
			initValues = [initValues];
		let res = items.map((option) => {
			let found = initValues.find(a => {
				return (a === option.value);
			});
			return (found || !initValues?.length ? option : false);
		}).filter(a => a);
		return (res);
	}

	function onCreateOption(value) {
		let val = {
			value,
			label: value,
			isNew: true
		};

		if (props.onCreateOption)
		{
			let req = props.onCreateOption(val);
			if (req.then)
				req.then((new_item) => {
					let selected = new_item;
					if (props.isMulti)
						selected = [...initialValues, new_item];
					handleSelect(selected);
					// setNewOptions([...newOptions, new_item]);
					if (props.queryKey)
						QCL.resetQueries(props.queryKey);
				});

		} else {
			let selected = [...initialValues, val];
			handleSelect(selected);
			setNewOptions([...newOptions, val]);
		}
	}

	function treatItems(items) {
		let ret = [];
		if (!items?.length)
			return (ret);
		if (props.treat)
			ret = items.map(a => {
				return (props.treat(a));
			}).filter(a => a);
		ret.push(...newOptions);
		ret = ret.sort((a, b) => {
			return (
				props?.sortByValue ?
					(a.value < b.value ? -1 : 1)
					:
					(noAccent(a.label.toLowerCase()) < noAccent(b.label.toLowerCase()) ? -1 : 1)
			);
		});
		if (props.all && props.isMulti !== false)
			ret.unshift(props.all);
		return (ret);
	}

	const req = useQuery(
		props.queryKey,
		props.queryFn,
		{
			onSuccess: (resp) => {
				let temp_items = treatItems(resp);
				setOptions(temp_items);
				if (!initialValues)
				{
					temp_items = setValues(temp_items);
					setInitialValues(temp_items);
					return (temp_items);
				} else if (initialValues?.length) {
					let new_values = initialValues.map(a => {
						let dup = a;
						for (let item of temp_items)
							if (item.value === a.value)
							{
								dup.label = item.label;
								return (dup);
							}
						return (false);
					}).filter(a => a);
					setInitialValues(new_values);
				}
				return (resp);
			}
		}
	);

	const {data, isLoading } = req;

	if (isLoading)
		return (<div>{t("Commons.LOADING")}</div>);

	let items = treatItems(data);

	return (
		<div className={"CustomAsyncSelect custom-select w-100" + (props.className ? " " + props.className : "")}>
			{
				props.title?.length &&
				<div className={"title" + (!props.text ? " mb-1" : "")}>
					{props.title}
				</div>
			}
			{
				props.text &&
					<div className="text mb-1">
						{props.text}
					</div>
			}
			<Creatable
				isMulti={props.isMulti}
				placeholder={props.placeholder}
				classNamePrefix="dropdown"
				options={items}
				isSearchable={true}
				menuPosition="fixed"
				menuPlacement={"auto"}
				closeMenuOnSelect={props.closeMenuOnSelect}
				value={initialValues}
				onChange={handleSelect}
				max={props.max}
				onCreateOption={onCreateOption}
				formatCreateLabel={props.formatCreateLabel ? props.formatCreateLabel : (userInput) => (t("Commons.CREATE") + ` "${userInput}"`)}
			/>
		</div>
	);
}

export function ConditionalSelect(props) {
	const {isAdmin} = useAccount();

	if (isAdmin() || props.allowCreate)
		return (<CreatableSelect {...props}/>);
	return (<QuerySelect {...props}/>);
}

export default CreatableSelect;

export const linkify = (inputText) => {
	const words = inputText.split(" ");

	return words
		.map((word, index) => {
			const hasProtocol = word.indexOf("://") > -1;
			const startsWithWww = word.indexOf("www.") === 0;

			if (hasProtocol || startsWithWww) {
				let url;
				if (startsWithWww) {
					url = `http://${word}`;
				} else {
					url = word;
				}

				return (
					<a
						key={index}
						href={url}
						target="_blank"
						rel="noopener noreferrer"
					>
						{word}
					</a>
				);
			}

			return word;
		})
		.reduce((prev, curr, index) => {
			if (index === 0) {
				return [curr];
			}

			return [...prev, " ", curr];
		}, []);
};

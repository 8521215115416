import PostObject from "classes/Posts/PostObject";
import formatDate from "includes/formatDate";
import genRequest from "includes/request";

class AnimationObject {

	#AnimationId = null;
	#CompanyId = null;
	#Title = null;
	#DateStart = null;
	#DateEnd = null;
	#LastChanged = null;
	#LastChange_AuthorId = null;
	#IsAllDay = null;
	#RecurenceRule = null;
	#Color = null;
	#IsDraft = null;
	#DisplayParticipation = null;
	#Post = null;
	#Notify = true;
	#LinkTo = [];
	#LinkedTo = [];
	#LinkedSites = [];
	#LinkedGroups = [];
	#LinkType = "sites";
	#IsNew = true;
	#Author = false;

	#Categories = [];

	constructor(props, force_notif) {
		if (props) {
			this.#AnimationId = typeof props?.AnimationId !== "undefined" ? props.AnimationId : null;
			this.#CompanyId = typeof props?.CompanyId !== "undefined" ? props.CompanyId : null;
			this.#Title = typeof props?.Title !== "undefined" ? props.Title : null;
			this.#DateStart = typeof props?.DateStart !== "undefined" ? props.DateStart : null;
			this.#DateEnd = typeof props?.DateEnd !== "undefined" ? props.DateEnd : null;
			this.#LastChanged = typeof props?.LastChanged !== "undefined" ? props.LastChanged : formatDate();
			this.#LastChange_AuthorId = typeof props?.LastChange_AuthorId !== "undefined" ? props.LastChange_AuthorId : null;
			this.#IsAllDay = typeof props?.IsAllDay !== "undefined" ? props.IsAllDay : false;
			this.#RecurenceRule = typeof props?.RecurenceRule !== "undefined" ? props.RecurenceRule : null;
			this.#Color = typeof props?.Color !== "undefined" ? props.Color : null;
			this.#IsDraft = typeof props?.IsDraft !== "undefined" ? props.IsDraft : false;
			this.#DisplayParticipation = typeof props?.DisplayParticipation !== "undefined" ? props.DisplayParticipation : false;

			this.#Notify = (typeof props?.Notify !== "undefined" ? props.Notify : true);
			this.#LinkTo = props?.LinkTo;
			this.#LinkType = props?.LinkType || "sites";
			this.#LinkedTo = props?.LinkedTo || [];
			this.#LinkedSites = props?.LinkedSites || [];
			this.#LinkedGroups = props?.LinkedGroups || [];

			this.#Author = props?.author;
			this.#Categories = props?.categories?.length ? props.categories : [];
			this.#Post = props?.post;
		}

		if (!this.#AnimationId)
			this.#IsNew = true;

		if (typeof force_notif !== "undefined")
			this.#Notify = force_notif;
		else {
			if (props.Notify)
				this.#Notify = true;
			else
				this.#Notify = this.#AnimationId ? false : true;
		}

		this.#Post = new PostObject({
			PostTypeId: 8,
			NotifyPost: false,
			IsTargetPost: true,
			PublishedDate: this.#DateStart,
			PriorityTo: this.#DateEnd,
			CompanyId: (props.CompanyId || null),
			...props?.author
		});
	}

	fetchForEdit(cloned) {
		let prom = new Promise((resolve) => {
			let proms = [
				this.fetchLinkedGroups(),
				this.fetchLinkedSites(),
				this.fetchLinkedPost(),
				this.fetchCategories(),
				this.fetchAuthor()
			];
			Promise.all(proms).then(() => {
				return (this.fetchImages(cloned));
			}).then(resolve);
		});
		return (prom);
	}

	fetchForClone = () => this.fetchForEdit(true);

	fetchAuthor() {
		if (!this.#LastChange_AuthorId)
			return (Promise.reject(false));
		let req = genRequest("Employes/" + this.#LastChange_AuthorId).then((resp) => {
			this.#Author = resp;
			return (resp);
		});
		return (req);
	}

	fetchLinkedGroups() {
		if (!this.#AnimationId)
			return (Promise.resolve(true));
		let req = genRequest("LtAnimationGroups/AnimationId/" + this.#AnimationId).then((resp) => {
			this.#LinkedGroups = (resp?.length ? resp : []);
		}, () => {
			this.#LinkedGroups = [];
		});
		return (req);
	}

	fetchLinkedSites() {
		if (!this.#AnimationId)
			return (Promise.resolve(true));
		let req = genRequest("LtAnimationSites/AnimationId/" + this.#AnimationId).then((resp) => {
			this.#LinkedSites = (resp?.length ? resp : []);
		}, () => {
			this.#LinkedSites = [];
		});
		return (req);
	}

	fetchLinkedPost() {
		if (!this.#AnimationId)
			return (Promise.resolve(true));
		let req = genRequest("Posts/PostOfAnimation/" + this.#AnimationId)
			.then((resp) => {
				this.#Post = new PostObject({Title: this.#Title, ...(resp ? resp : {}), CompanyId: this.#CompanyId});
				return (this.#Post);
			}, () => {
				this.#Post = new PostObject({CompanyId: this.#CompanyId, Title: this.#Title});
				return (this.#Post);
			});
		return (req);
	}

	fetchCategories() {
		let that = this;
		if (!this.#AnimationId)
			return (Promise.resolve(true));
		let req = genRequest("Categories/CategoriesOfAnimation/" + this.#AnimationId)
			.then((resp) => {
				that.#Categories = (resp?.length ? resp :[]);
				return (that.#Categories);
			});
		return (req);
	}

	fetchImages(cloned) {
		let that = this;
		if (!this.#Post)
			return (Promise.resolve(true));
		let prom = new Promise((resolve) => {
			let inner = this.#Post.fetchImages()
				.then(() => {
					return (that.#Post.fetchImagesFile(cloned));
				})
				.then(() => {
					resolve(that.images());
					return (that.images());
				}, resolve);
			return (inner);
		});
		return (prom);
	}

	images(setImages) {
		if (!this.#Post)
			return ([]);
		return (this.#Post.images(setImages));
	}

	categories(setCats) {
		if (typeof setCats !== "undefined")
		{
			this.#Categories = setCats;
			if (this.#Categories?.length)
				this.#Color = this.#Categories[0].DefaultHexaColor;
		}
		return (this.#Categories);
	}

	send() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			let xhr = that.sendAnim()
				.then(() => {
					return (that.linkAnimation());
				}, reject).then(() => {
					return (that.sendPost());
				}, reject).then(() => {
					resolve(true);
				}, reject);
			return (xhr);
		});
		return (prom);
	}

	sendPost() {
		let post = this.#Post;
		let that = this;
		let isNew = (post.postId() ? false : true);

		let prom = new Promise((resolve, reject) => {
			let inner_prom = post.sendPost().then(() => {
				if (!isNew)
				{
					resolve (true);
					return (true);
				}
				return (that.linkPost());
			}, reject).then(() => {
				resolve(true);
			}, reject);
			return (inner_prom);
		});
		return (prom);
	}

	linkPost() {
		let req = genRequest(
			"LtAnimationPosts",
			{
				AnimationId: this.#AnimationId,
				PostId: this.#Post.postId()
			},
			"post"
		);
		return (req);
	}

	notify() {
		let url = "AnimationNotifySitesExceptAuthor";
		let data = {};

		if (this.#LinkType === "sites")
		{
			url = (this.#IsNew ? "AnimationNotifySitesExceptAuthor" : "AnimationChangedNotifySitesExceptAuthor");
			data = {
				Message: this.#Title,
				ListSiteId: this.#LinkTo,
				Author: this.#Author,
				NewsId: this.#Post.postId()
			};
		}
		else if (this.#LinkTo) {
			url = (this.#IsNew ? "AnimationNotifyGroup" : "AnimationChangedNotifyGroup");
			data = {
				Message: this.#Title,
				ListGroupId: this.#LinkTo,
				Author: this.#Author,
				NewsId: this.#Post.postId()
			};
		}

		let req = genRequest(
			"PushNotification/" + url,
			data,
			"post"
		);
		return (req);
	}

	sendAnim() {
		let that = this;

		let anim = this.values();

		if (!this.#AnimationId)
			delete anim.AnimationId;
		let prom = genRequest(
			"Animations" + (this.#AnimationId ? "/" + this.#AnimationId : ""),
			anim,
			(this.#AnimationId ? "put" : "post")
		).then((resp) => {
			if (!that.#AnimationId)
				that.#AnimationId = resp.AnimationId;
		});
		return (prom);
	}

	linkAnimation() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			Promise.all([
				that.unlinkSites(),
				that.unlinkGroups(),
				that.unlinkCategories(),
				that.#Post.unlinkDocs()
			]).then(() => {
				let proms = [];
				if (this.#LinkType === "sites")
					proms.push(that.linkSites());
				else
					proms.push(that.linkGroups());
				proms.push(that.linkCategories());

				return (Promise.all(proms));
			}, reject).then(resolve, reject);
		});
		return (prom);
	}

	linkGroups() {
		if (!this.#LinkTo?.length)
			return (Promise.resolve(true));
		let req = genRequest(
			"LtAnimationGroups/PostSeveralLtAnimationGroups",
			{
				AnimationId: this.#AnimationId,
				GroupToPost: this.#LinkTo
			},
			"post"
		);
		return (req);
	}

	unlinkGroups = () => genRequest(
		"LtAnimationGroups/AnimationId/" + this.#AnimationId,
		null,
		"delete"
	);

	linkSites() {
		if (!this.#LinkTo?.length)
			return (Promise.resolve(true));
		let req = genRequest(
			"LtAnimationSites/PostSeveralLtAnimationSites",
			{
				AnimationId: this.#AnimationId,
				SitesToPost: this.#LinkTo
			},
			"post"
		);
		return (req);
	}

	unlinkSites = () => genRequest(
		"LtAnimationSites/AnimationId/" + this.#AnimationId,
		null,
		"delete"
	);

	linkCategories() {
		let categories = this.#Categories;

		if (!categories?.length)
			return (Promise.resolve(true));

		let prom = genRequest(
			"LtAnimationCategories/PostSeveralLtAnimationCategories",
			{
				AnimationId: this.#AnimationId,
				CategoriesToPost: categories.map(a => a.CategoryId)
			},
			"post"
		);
		return (prom);
	}

	unlinkCategories = () => genRequest(
		"LtAnimationCategories/AnimationId/" + this.#AnimationId,
		null,
		"delete"
	);

	linkedPost = () => this.#Post;

	animationId(setAnimationId) {
		if (typeof setAnimationId !== "undefined")
			this.#AnimationId = setAnimationId;
		return (this.#AnimationId);
	}

	companyId(setCompanyId) {
		if (typeof setCompanyId !== "undefined")
			this.#CompanyId = setCompanyId;
		return (this.#CompanyId);
	}

	title(setTitle) {
		if (typeof setTitle !== "undefined")
			this.#Title = setTitle;
		return (this.#Title);
	}

	txt(setTxt) {
		if (typeof setTxt !== "undefined")
			this.#Post.txt(setTxt);
		return (this.#Post.txt());
	}

	dateStart(setDateStart) {
		if (typeof setDateStart !== "undefined")
		{
			this.#DateStart = setDateStart;
			this.#Post.publishedDate(setDateStart);
		}
		return (this.#DateStart);
	}

	dateEnd(setDateEnd) {
		if (typeof setDateEnd !== "undefined")
		{
			this.#DateEnd = setDateEnd;
			this.#Post.priorityTo(setDateEnd);
		}
		return (this.#DateEnd);
	}

	lastChanged(setLastChanged) {
		if (typeof setLastChanged !== "undefined")
			this.#LastChanged = setLastChanged;
		return (this.#LastChanged);
	}

	lastChange_AuthorId(setLastChange_AuthorId) {
		if (typeof setLastChange_AuthorId !== "undefined")
			this.#LastChange_AuthorId = setLastChange_AuthorId;
		return (this.#LastChange_AuthorId);
	}

	isAllDay(setIsAllDay) {
		if (typeof setIsAllDay !== "undefined")
			this.#IsAllDay = setIsAllDay;
		return (this.#IsAllDay);
	}

	recurenceRule(setRecurenceRule) {
		if (typeof setRecurenceRule !== "undefined")
			this.#RecurenceRule = setRecurenceRule;
		return (this.#RecurenceRule);
	}

	color(setColor) {
		if (typeof setColor !== "undefined")
			this.#Color = setColor;
		return (this.#Color);
	}

	isDraft(setIsDraft) {
		if (typeof setIsDraft !== "undefined")
			this.#IsDraft = setIsDraft;
		return (this.#IsDraft);
	}

	notification(setNotif) {
		if (typeof setNotif !== "undefined")
		{
			this.#Notify = setNotif;
			this.#Post.notification(false);
		}
		return (this.#Notify);
	}
	
	participation(setParticipation) {
		if (typeof setParticipation !== "undefined")
		{
			this.#DisplayParticipation = setParticipation;
		}
		return (this.#DisplayParticipation);
	}

	linkType(set_type) {
		if (set_type && set_type !== this.#LinkType)
		{
			this.#LinkType = set_type;
			this.#Post.linkType(set_type);
		}
		return (this.#LinkType);
	}

	setLinkTo(items_list, link_type)
	{
		if (link_type)
			this.linkType(link_type);
		this.#LinkTo = items_list;
		return (this.#LinkTo);
	}

	links() {
		if (this.#LinkedSites?.length)
			return ({type: "sites", links: this.#LinkedSites.map(a => a.SiteId)});
		if (this.#LinkedGroups?.length)
			return ({type: "groups", links: this.#LinkedGroups.map(a => a.GroupId)});
		return ({type: "sites", links: []});
	}

	linkedSites = () => this.#LinkedSites;

	linkedGroups = () => this.#LinkedGroups;

	getLinksTo() {
		if (!(this.#LinkType && this.#LinkTo))
			return (false);
		return ({
			type: this.#LinkType,
			links: this.#LinkTo
		});
	}

	values() {
		let ret = {
			AnimationId: this.#AnimationId,
			CompanyId: this.#CompanyId,
			Title: this.#Title,
			DateStart: this.#DateStart,
			DateEnd: this.#DateEnd,
			LastChanged: this.#LastChanged,
			LastChange_AuthorId: this.#LastChange_AuthorId,
			IsAllDay: this.#IsAllDay,
			RecurenceRule: this.#RecurenceRule,
			Color: this.#Color,
			IsDraft: this.#IsDraft,
			DisplayParticipation: this.#DisplayParticipation,
			Notify: this.#Notify,
			LinkTo: this.#LinkTo,
			LinkType: this.#LinkType,
			LinkedTo: this.#LinkedTo,
		};
		return (ret);
	}
}

export default AnimationObject;

import SitePreview from "Pages/Sites/components/SitePreview/SitePreview";
import React from "react";

function EndSiteStep(props) {
	let site = props.object;

	if (!props.isActive)
		return (false);

	return (
		<div className="d-flex flex-column flex-md-row justify-content-between overflow-auto inner-step-cont">
			<SitePreview site={site.values()}/>
		</div>
	);
}

export default EndSiteStep;

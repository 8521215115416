import React from "react";
import { hexToCSSFilter } from "hex-to-css-filter";
import { FILES_URL } from "includes/paths";
import "./ReactionIcon.css";
import { useQuery } from "react-query";
import genRequest from "includes/request";

export default function ReactionIcon(props) {
	let icon = props;
	let url, hexFilter;

	const {data} = useQuery(
		["Reactions", props.reactionId],
		() => genRequest("Reactions/" + props.reactionId),
		{
			enable: props.async && props.reactionId,
			onSuccess: (resp) => {
				if (resp)
				{
					icon = resp;
					url = FILES_URL + resp.IconUrl;
					if (resp.HexaColor)
						hexFilter = hexToCSSFilter(resp.HexaColor);
				}
				return (resp);
			}
		}
	)

	if (props.async && !data)
		return (false)
	let src = data || props;
	url = FILES_URL + src.IconUrl;
	if (src.HexaColor)
		hexFilter = hexToCSSFilter(src.HexaColor);
	return (
		<div
			className={"ReactionIcon" + (props.className ? " " + props.className : "")}
			title={icon.Description}
			alt={icon.Description}
			style={props.style}
		>
			<img
				className={props.iconClasses}
				loading="lazy"
				draggable={false}
				src={url}
				style={{
					filter: hexFilter?.filter.replace(";", "")
				}}
				alt={icon.Description}
			/>
		</div>
	);
}

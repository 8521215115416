import React, {memo} from "react";
import "./TableHead.css";

const TableHead = memo((props) => {
	return (
		<table style={{borderSpacing: props.cellSpacing}} className="TableHead">
			<thead>
				<tr>
					{
						props.cols.map((col, index) => {
							if (props.maxCols && index >= props.maxCols)
								return (false);
							let col_txt = "" + (props.datas ? props.datas[col.name] : col.title) + "";
							if (!col_txt?.length)
								col_txt = col.title;
							if (col.hidden)
								return (false);
							return (
								<TableHeaderCell
									key={col.name}
									{...col}
									item={col}
									allSelected={props.allSelected}
									checkbox={props.checkbox && index === 0}
									title={(col_txt ? col_txt : "")}
									isHeader={props.isHeader}
									setSort={props.setSort}
									sort={typeof col.sort !== "undefined" ? col.sort : props.sort }
									onCBChange={props.onCBChange}
									onCellChange={props.onCellChange}
									cellsProps={props.cellsProps}
									colIndex={index}
									rowIndex={0}
								/>
							);
						})
					}
				</tr>
			</thead>
		</table>
	);
});
TableHead.displayName = "TableHead";

export const TableHeaderCell = memo((props) => {

	function changeSort() {
		if (props.sort !== false)
		{
			props.setSort({
				sortBy: props.name,
				rev: !props.sort.rev
			});
		}
	}

	return (
		<th className={props.name.toLowerCase() + "-col" + (props.classes ? " " + props.classes : "")}>
			<div className='d-flex align-items-center'>
				{
					props.checkbox
					&&
					<div className="table-checkbox">
						<input
							type="checkbox"
							className="checkbox"
							checked={props.allSelected || false}
							onClick={(e) => e.stopPropagation()}
							onChange={(e) => {e.stopPropagation(); props.onCBChange(e);}}
						/>
					</div>
				}
				<div className="w-100 d-flex justify-content-center flex-wrap">
					{
						props.headComponent ?
							<props.headComponent
								{...props.childProps}
								{...props.cellsProps}
								item={props.item}
								colIndex={props.colIndex}
								rowIndex={props.rowIndex}
								onCellChange={props.onCellChange}
								isHead={true}
							>
								{ (props.title !== "null" ? props.title : "") }
							</props.headComponent>
							:
							<div
								onClick={changeSort}
								className={
									"col-title " +
									(
										(props.sort !== false ?
											"sort" +
											(
												props.sort.sortBy === props.name ?
													(props.sort.rev ? " desc" : " asc")
													:
													""
											)
											:
											""
										)
									)
								}
								name={props.name}
							>
								{props.title}
							</div>
					}
				</div>
			</div>
		</th>
	);
});
TableHeaderCell.displayName = "TableHeaderCell";

export default TableHead;

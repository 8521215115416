import formatDate from "includes/formatDate";
import genRequest from "includes/request";

class CategoryObject {
	#CategoryId = null;
	#CompanyId = null;
	#Name = null;
	#DefaultHexaColor = null;
	#Description = null;
	#LastChanged = null;
	#LastChange_AuthorId = null;
	#CategoryType = null;
	#IsFavorite = false;
	#FavoritePosition = 0;

	#Types = [];

	constructor(props) {
		this.#CategoryId = props.CategoryId || null;
		this.#CompanyId = props.CompanyId || null;
		this.#Name = props.Name || "";
		this.#DefaultHexaColor = props.DefaultHexaColor || null;
		this.#Description = props.Description || "";
		this.#IsFavorite = props.IsFavorite || false;
		this.#FavoritePosition = props.FavoritePosition || "";
		this.#CategoryType = props.CategoryType || "documents";
		this.#LastChanged = props.LastChanged || formatDate();
		this.#LastChange_AuthorId = props.LastChange_AuthorId || null;
		this.#Types = props.Types || [];
	}

	send() {
		let that = this;
		let isNew = !(this.#CategoryId > 0);
		let prom = new Promise((resolve, reject) => {
			that.sendCat()
				.then((resp) => {
					if (isNew) return that.linkToCompany();
					resolve(resp);
					return resp;
				}, reject)
				.then(() => {
					resolve(that.values());
					return that.values();
				}, reject);
		});
		return prom;
	}

	duplicate(new_type) {
		let vals = this.values();
		delete vals.CategoryId;
		let dup = new CategoryObject({ ...vals, CategoryType: new_type });
		return dup.send();
	}

	sendCat() {
		let data = this.values();
		if (!this.#CategoryId) delete data.CategoryId;
		let req = genRequest(
			"Categories" + (this.#CategoryId ? "/" + this.#CategoryId : ""),
			data,
			this.#CategoryId ? "put" : "post"
		).then((resp) => {
			if (!this.#CategoryId) this.#CategoryId = resp.CategoryId;
		});
		return req;
	}

	linkToCompany = () =>
		genRequest(
			"LtCompanyCategories",
			{
				CompanyId: this.#CompanyId,
				CategoryId: this.#CategoryId,
			},
			"post"
		);

	categoryId(setValue) {
		if (typeof setValue !== "undefined") this.#CategoryId = setValue;
		return this.#CategoryId;
	}

	companyId(setValue) {
		if (typeof setValue !== "undefined") this.#CompanyId = setValue;
		return this.#CompanyId;
	}

	name(setValue) {
		if (typeof setValue !== "undefined") this.#Name = setValue;
		return this.#Name;
	}

	defaultHexaColor(setValue) {
		if (typeof setValue !== "undefined") this.#DefaultHexaColor = setValue;
		return this.#DefaultHexaColor;
	}

	description(setValue) {
		if (typeof setValue !== "undefined") this.#Description = setValue;
		return this.#Description;
	}

	isFavorite(setValue) {
		if (typeof setValue !== "undefined") this.#IsFavorite = setValue;
		return this.#IsFavorite;
	}

	favoritePosition(setValue) {
		if (typeof setValue !== "undefined") this.#FavoritePosition = setValue;
		return this.#FavoritePosition;
	}

	lastChanged(setValue) {
		if (typeof setValue !== "undefined") this.#LastChanged = setValue;
		return this.#LastChanged;
	}

	lastChange_AuthorId(setValue) {
		if (typeof setValue !== "undefined")
			this.#LastChange_AuthorId = setValue;
		return this.#LastChange_AuthorId;
	}

	categoryType(setValue) {
		if (typeof setValue !== "undefined") this.#CategoryType = setValue;
		return this.#CategoryType;
	}

	setTypes(types) {
		this.#Types = types;
		return this.#Types;
	}

	types = () => this.#Types;

	values = () => ({
		CategoryId: this.#CategoryId,
		CompanyId: this.#CompanyId,
		Name: this.#Name,
		DefaultHexaColor: this.#DefaultHexaColor,
		Description: this.#Description,
		LastChanged: this.#LastChanged,
		LastChange_AuthorId: this.#LastChange_AuthorId,
		CategoryType: this.#CategoryType,
		IsFavorite: this.#IsFavorite,
		FavoritePosition: this.#FavoritePosition,
	});
}

export default CategoryObject;

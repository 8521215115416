import SecureStorage from "secure-web-storage";
import CryptoJS from "crypto-js";

class Storage
{
	#SecStorage = null;
	constructor(secret_key, forceReload, storage)
	{
		let SECRET_KEY = "cfa5d51e-db2f-4608-9eb6-536686c34538";
		this.forceReload = forceReload;
		let store = (storage ? storage : localStorage);
		this.#SecStorage = new SecureStorage(store, {
			hash: function hash(key) {
				key = CryptoJS.SHA256(key, SECRET_KEY);
				return (key.toString());
			},
			encrypt: function encrypt(data) {
				data = CryptoJS.AES.encrypt(data, SECRET_KEY);
				data = data.toString();
				return (data);
			},
			decrypt: function decrypt(data) {
				data = CryptoJS.AES.decrypt(data, SECRET_KEY);
				data = data.toString(CryptoJS.enc.Utf8);
				return (data);
			}
		});
	}

	getItem = (key) => {
		let item = null;
		try {
			item = this.#SecStorage.getItem(key);
		} catch (e) {
			item = null;
		}
		return (item);
	};
	setItem = (key, data) => this.#SecStorage.setItem(key, data);
}

export default Storage;

import React from "react";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import Markdown from "markdown-to-jsx";
import "./PostContent.css";
import { ParseEmbbed } from "includes/TextParser/TextParser";

const PostContent = React.memo((props) => {
	function genTitle(post) {
		if (!post?.Title)
			return (false);
		if (post.PostTypeId !== 3)
			return (
				<div className="post-title mb-2 font-bold">
					{post.Title}
				</div>
			);

		let title = post.Title.split(/\/__CUT__\\/gi);
		return (
			<div>
				<div className="post-sub-title">
					{title.length > 1 ? title[0] : false}
				</div>
				<div className="post-title mb-2 font-bold">
					{title.length > 1 ? title[1] : title[0]}
				</div>
			</div>
		);
	}

	function Links(props) {
		const {href, children} = props;

		return (<ParseEmbbed {...props} key={href} text={children[0]}/>);
	}

	function Header(props) {
		const { children} = props;
		return (
			<div>
				<div>{("#").repeat(props.size) + children}</div>
				<br/>
			</div>
		);
	}

	function genText(post) {
		if (!post?.Txt)
			return (false);

		let text = post.Txt.replace(/\n{1}/gi, "\n\n");
		text = text.replace(/\/__CUT__\\/gi, "\n");

		return (
			<div className="post-text">
				<Markdown
					className="post-text"
					options={{
						overrides: {
							a: Links,
							h1: {
								component: Header,
								props: {size: 1}
							},
							h2: {
								component: Header,
								props: {size: 2}
							},
							h3: {
								component: Header,
								props: {size: 3}
							},
							h4: {
								component: Header,
								props: {size: 4}
							},
							h6: {
								component: Header,
								props: {size: 6}
							},
						},
						forceBlock: true
					}}
				>
					{text}
				</Markdown>
			</div>
		);
	}

	return (
		<div className={`PostContent ${props.className}`}>
			{
				props.author ?
					(
						<AuthorPreview
							employeId={props.post?.EmployesId}
							className="posts-list-author-preview mb-2"
							format={
								[
									"EmployeFirstName EmployeLastName / WFU",
									{date: props.post?.PublishedDate}
								]
							}
							modifiers={{
								EmployeLastName: (item) => item.toUpperCase()
							}}
						/>
					)
					:
					false
			}
			<div>
				{ genTitle(props.post) }
				{ genText(props.post) }
			</div>
		</div>
	);
});
PostContent.displayName = "PostContent";

export default PostContent;

import { AssignationsList } from "components/Assignator/Assignator";
import React from "react";
import "./AssignResume.css";

export default function AssignResume({item, setCur, isSubAssigns}) {

	function handleClick() {
		if (setCur)
			setCur(item);
	}

	function genTitle() {
		let ret = item.values?.length ?
			item.values?.map(a => a.label)
			:
			[]
		;
		return (ret.join("\n"));
	}

	return (
		<div className={"AssignResume" + (!isSubAssigns ? " root-assign" : "")} onClick={handleClick}>
			<div className="assign-label" title={genTitle()}>
				{item.values?.length || 0} {item?.type.label}
			</div>
			<AssignationsList isSubAssigns={true} forceResume={true} items={item.assigns || []}/>
		</div>
	);
}

import { FILES_URL } from "includes/paths";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";

export const getDefaultImage = (compId, postTypeId) => {
	let type;
	let ret_url = "";
	if (compId && postTypeId) {
		if (postTypeId === 1)
			type = "News";
		else if (postTypeId === 3)
			type = "Enterprise_Article";
		else if (postTypeId === 4)
			type = "Ad";
		else if (postTypeId === 5)
			type = "Career";
		else if (postTypeId === 13)
			return (false);
		else if (postTypeId === 14)
			type = "ProAd";

		ret_url = FILES_URL + compId + "/Default" + type + "Image.png";
	}
	return (ret_url);
};

export function fetchImages(companyId, postTypeId, postId) {
	if (!postId)
		return (Promise.reject(false));
	let req = genRequest("Documents/DocumentOfPostAndType/" + postId + "/3")
		.then((resp) => {
			return (documents_treat(resp, "Name"));
		}, () => {
			let defaultImage = getDefaultImage(companyId, postTypeId);
			if (postTypeId !== 8 && defaultImage) {
				return ([{
					Url_Origin: defaultImage,
					Url_Mobile: defaultImage,
					Url_Min: defaultImage,
					Url_Web: defaultImage,
				}]);
			}
			return ([]);
		});
	return (req);
}

const PagesTour = {
	tourId: "pages",
	title: "Présentation des pages",
	description: "",
	page: "/news",
	steps: [
		{
			target: "#main-menu .news-page-link",
			page: "/news",
			title: "News",
			content: "Cliquez ici pour acceder aux news",
			disableBeacon: true,
			spotlightClicks: true
		},
		{
			target: "#main-menu .alerts-page-link",
			page: "/alerts",
			title: "Alertes",
			content: "Envoyez des notifications push a vos utilisateurs",
			disableBeacon: true
		},
	]
};

export default PagesTour;

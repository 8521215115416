import React from "react";
import {useQuery} from "react-query";
import genRequest from "includes/request";
import PageLoad from "components/PageLoad/PageLoad";
import { t } from "i18next";

export default function FormFetcher(props) {
	const {formId} = props;
	let Form = null;

	const fetchForm = () => genRequest("Forms/FullFormOfId/" + formId + "/" + (props.withAnswers ? "true" : false));

	function fetch() {
		Form = null;
		let prom = new Promise((resolve, reject) => {
			function handleResolve(value) {
				resolve (value);
				return (value);
			}

			function handleReject(value) {
				reject (value);
				return (false);
			}

			fetchForm()
				.then((resp) => {
					if (!resp)
						handleReject(false);
					Form = treat_datas(resp);
					handleResolve (Form);
				});
		});
		return (prom);
	}

	function treat_datas(form) {
		let ret = form.Form;

		if (!ret)
			return (false);
		if (form.Sections?.length)
			ret.Sections = form.Sections.map(sec => {
				if (form.Questions?.length)
					sec.Questions = form.Questions.map((quest) => {
						if (quest.FormSectionId === sec.FormSectionId)
						{
							if (form.QuestionOptions?.length)
								quest.Options = form.QuestionOptions
									.filter((opt) => {
										if (opt.QuestionId === quest.QuestionId)
											return (opt);
										return (false);
									})
									.sort((a, b) => (a.Position < b.Position ? -1 : 1));
							if (props.withAnswers)
							{
								if (form.Answers)
								{
									quest.Answers = form.Answers
										.filter((ans) => {
											if (ans.QuestionId === quest.QuestionId)
												return (ans);
											return (false);
										})
										.sort((a, b) => (a.PublishedDate < b.PublishedDate ? -1 : 1));
								}
								else
									quest.Answers = [];
							}
						}
						return (quest.FormSectionId === sec.FormSectionId ? quest : false);
					}).filter(a => a).sort((a, b) => (a.Position < b.Position ? -1 : 1));
				else
					sec.Questions = [];
				return (sec);
			});
		else
			ret.Sections = [];
		ret.Questions = form.Questions.sort((a, b) => (a.Position < b.Position ? -1 : 1));

		if (form.Answers)
		{
			let users = [];

			form.Answers.forEach((ans) => {
				let user = users.find((user => user.EmployesId === ans.EmployesId));
				if (user)
					user.Answers.push(ans);
				else
					users.push({
						EmployesId: ans.EmployesId,
						Answers: [ans]
					});
			});
			ret.Answers = users;
		}
		return (ret);
	}

	const {isLoading, isFetching, data} = useQuery(
		["FormFetcher", "Forms", formId],
		fetch,
		{
			enabled: (formId ? true : false),
			// staleTime: 0,
			refetchOnWindowFocus: false
		}
	);

	if (isLoading || isFetching)
		return ( <PageLoad text={t("Forms.LOADING_FORM")}/> );

	return (props.children(data));
}

import LogoVimeo from "./assets/vimeo.png";
import LogoYoutube from "./assets/youtube.png";
import LogoDailymotion from "./assets/dailymotion.png";

const EmbbedList = [
	{
		host: "youtube",
		split_start: /\?v=|shorts\//,
		split_end: "&",
		link_start:"https://www.youtube.com/embed/",
		thumb_start: "https://img.youtube.com/vi/",
		thumb_end: "/0.jpg",
		default_thumb: LogoYoutube,
		color: "#FE010D"
	},
	{
		host: "dailymotion",
		split_start: "dailymotion.com/video/",
		split_end: "&",
		replace: "video/",
		link_start: "https://www.dailymotion.com/embed/video/",
		thumb_start: "https://www.dailymotion.com/thumbnail/video/",
		thumb_end: "",
		default_thumb: LogoDailymotion,
		color: "#005FD1"
	},
	{
		host: "vimeo",
		split_start: "vimeo.com/",
		split_end: "&",
		replace: "video/",
		link_start: "https://player.vimeo.com/video/",
		thumb_start: "",
		thumb_end: "",
		default_thumb: LogoVimeo,
		color: "#00ADEF"
	}
];

export const getEmbbed = (link) => {
	let found = EmbbedList.find((a) => (link.search(a.host) !== -1 || link === a.host));
	return (found ? found : false);
};

export const getEmbbedThumbnail = (host, link_id) => {
	let emb = getEmbbed(host);
	if (!emb)
		return (false);

	let thumb = emb.default_thumb;
	if (emb.thumb_start)
		thumb = emb.thumb_start + link_id + (emb.thumb_end ? emb.thumb_end : "");
	return ({
		color: emb.color,
		default: emb.default_thumb,
		thumb
	});
};

export default EmbbedList;

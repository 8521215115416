import React, { Component } from "react";
import { convertToFloatWithTwoDecimals } from "includes/numbers";
import "./NumberChart.css";

export default class NumberChart extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div
				className={
					"number-chart-value-cont d-flex" +
					(this.props.className ? " " + this.props.className : "")
				}
				style={{
					fontSize: this.font_size,
					lineHeight: "105%",
					color: this.props.color,
				}}
			>
				<div className="chart-value">
					{convertToFloatWithTwoDecimals(this.props.value)}
				</div>
				<div className="chart-label"> {this.props.label}</div>
			</div>
		);
	}
}

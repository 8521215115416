import React from "react";
import { t } from "i18next";
import SendStep from "components/EditDrawer/SendStep/SendStep";

export default function SendSiteStep(props) {
	const site = props.object;

	const queries = [
		{
			queryKey: ["SendSite", "Site", site.name(), site.lastChanged()],
			queryFn: () => site.send(),
			title: t("Sites.SEND_SITE"),
		},
		{
			queryKey: ["SendSite", "Logo", site.siteId(), site.lastChanged()],
			queryFn: () => site.sendLogo(),
			title: t("Posts.SEND_IMAGES"),
			disabled: !site.logo()
		},
	];

	if (!props.isActive)
		return (false);

	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

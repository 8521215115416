import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { linkify } from "includes/linkify";
import { t } from "i18next";
import FormQuestion from "../FormQuestion/FormQuestion";

export default function FormSection(props) {
	const { section, isAnonymous } = props;
	const { curSection } = props;
	const { curQuestion, setCurQuestion } = props;
	const { refreshForm } = props;

	function handleClick() {}

	let isActive = curSection?.FormSectionId === section?.FormSectionId;
	let questions = section.Questions;

	return (
		<div
			className={
				"FormSection d-flex flex-column gap-3" +
				(isActive ? " active" : "")
			}
			onClick={handleClick}
			data-form-section-id={section.FormSectionId}
		>
			<div className="d-flex section-content">
				<div className="w-100">
					<div className="section-title">{section.Title}</div>
					<div className="section-text">{linkify(section.Txt)}</div>
					<div
						className={`mt-3 p-1 px-2 radius-1 d-flex align-items-center ${
							isAnonymous ? "btn-green" : "btn-red"
						}`}
					>
						<FontAwesomeIcon
							icon={isAnonymous ? faEyeSlash : faEye}
							className="me-2"
						/>
						{isAnonymous ? (
							<div>{t("Forms.ANON_ANSWERS_WARN")}</div>
						) : (
							<div>{t("Forms.NON_ANON_ANSWERS_WARN")}</div>
						)}
					</div>
				</div>
			</div>
			<QuestionsList
				section={section}
				items={questions}
				curQuestion={curQuestion}
				setCurQuestion={setCurQuestion}
				refreshForm={refreshForm}
			/>
		</div>
	);
}

function QuestionsList(props) {
	const { items } = props;
	const { curQuestion, setCurQuestion, refreshForm } = props;

	if (!items?.length) return false;

	return (
		<div className="QuestionsList gap-3 d-flex flex-column">
			{items.map((a, key) => {
				let isActive = curQuestion?.QuestionId === a.QuestionId;
				return (
					<FormQuestion
						key={a.QuestionId}
						index={key}
						question={a}
						isActive={isActive}
						curQuestion={curQuestion}
						refreshForm={refreshForm}
						setCurQuestion={setCurQuestion}
					/>
				);
			})}
		</div>
	);
}

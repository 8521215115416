import { t } from "i18next";
import React from "react";
import ImageSender from "./ImageSender/ImageSender";

function LoadingImagesTab(props) {

	return (
		<>
			<ImageSender
				className="mb-4"
				title={<h5>{t("Appearance.WEB_LOAD")}</h5>}
				description={t("Images.RECOMMENDED_SIZE", {width: 1920, height: 1080})}
				companyId={props.companyId}
				typeId={12}
			/>
			<ImageSender
				className="mb-4"
				title={<h5>{t("Appearance.MOBILE_LOAD")}</h5>}
				description={t("Images.RECOMMENDED_SIZE", {width: 640, height: 960})}
				companyId={props.companyId}
				typeId={5}
			/>
		</>
	);
}

export default LoadingImagesTab;

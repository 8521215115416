import React, { useState } from "react";
import { useQuery } from "react-query";
import MoonLoader from "react-spinners/MoonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { WarningsCont } from "components/ErrorsCont/ErrorsCont";
import { t } from "i18next";
import { sendReport } from "includes/request";
import ProgressBar from "components/ProgressBar/ProgressBar";

export default function SendStep(props) {
	const [queries] = useState(initSendeds());
	const [Sendeds, setSended] = useState(new Array(queries.length).fill(false));
	const [warnings, setWarnings] = useState(false);

	const checkSend = React.useCallback(() => {
		let isSended = queries.every((a) => (a.disabled || a.isSended ? true : false));
		let isSendedWithErrors = queries.every((a) => (a.disabled || a.isSended || (!a.required && a.isError)));

		if (isSended) {
			setTimeout(() => {
				props.instance.nextStep();
				props.setFailedStep(false);
				props.setIsDone(true);
			}, 1000);
		} else if (isSendedWithErrors) {
			props.setIsDone(true);
			props.setFailedStep();
			setWarnings(props.sendedWarn || t("EditDrawer.SENDED_WARN"));
		}
	}, [props, queries]);

	function initSendeds() {
		if (!props.queries?.length)
			return ([]);
		let filtered = props.queries.filter(a => !a.disabled);
		return (filtered);
	}

	const setAsSended = (index, value) => {
		let dup = [...Sendeds];
		dup[index] = value;
		setSended(dup);
		checkSend();
	};

	const isEnabled = (query, key) => {
		if (query.isSended || props.isDone)
			return (false);
		for (let x = 0; x < queries?.length && x < key; x++)
			if (x < key && (queries[x].required && !queries[x].isSended && !queries[x].disabled))
				return (false);
		return (true);
	};

	const isError = (query, key) => {
		if (query.isSended)
			return (false);
		for (let x = 0; x < queries?.length && x < key; x++)
			if (x < key && (queries[x].required && queries[x].isError && !queries[x].disabled))
				return (true);
		return (false);
	};

	const handleRetry = (item, key) => {
		item.isSended = false;
		item.isError = false;
		setAsSended(key, false);
		props.setFailedStep(false);
		props.setIsDone(false);
	};

	if (!queries?.length)
		return (<div>{t("ErrorMsg.QueryError1")}</div>);

	return (
		<div>
			{
				warnings &&
				<WarningsCont
					errors={warnings}
					className="mb-2"
				/>
			}
			{
				queries.map((a, key) => {
					return (
						<SendingCont
							{...a}
							key={a.queryKey}
							enabled={isEnabled(a, key)}
							requiredError={isError(a, key)}
							onError={() => { a.isSended = false; a.isError = true; setAsSended(key, false); }}
							onSended={() => { a.isSended = true; a.isError = false; setAsSended(key, true); }}
							onRetry={() => handleRetry(a, key)}
						/>
					);
				})
			}
		</div>
	);
}

function SendingCont(props) {
	const [state, setState] = useState({
		isSended: false,
		isSending: false,
		progress: false
	});

	const { queryKey, queryFn, onSended, onError, enabled, title, requiredError } = props;

	function getIcon() {
		if (error || requiredError)
			return (<FontAwesomeIcon icon={faTimes} />);
		else if (status === "success")
			return (<FontAwesomeIcon icon={faCheck} />);
		else if ((isIdle || isLoading))
			return (<MoonLoader color={"white"} size={30} />);
	}

	const handleProgress = (progress_status) => {
		setState({
			...state,
			progress: {
				...progress_status,
				percent: Math.round(progress_status.progress * 100)
			}
		})
	}

	const handleSend = () => {
		setState({ ...state, isSending: true });
		if (!queryFn)
			return (Promise.reject(new Error("No queryFn property provided")));
		let prom = new Promise((resolve, reject) => {
			let call = queryFn(handleProgress);
			if (call && call.then)
				call.then((resp) => {
					resolve(resp);
					return (resp);
				}, reject);
			else
				resolve(true);
		}).then((resp) => {
			if (onSended)
				onSended(resp);
		});
		return (prom);
	};

	const { isLoading, isIdle, status, error } = useQuery(
		queryKey,
		handleSend,
		{
			enabled: (!state.isSending && !state.isSended) && (typeof enabled !== "undefined" && enabled) && !requiredError,
			staleTime: props.staleTime || 0,
			cacheTime: props.cacheTime || 0,
			retry: props.retry || 3,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchOnMount: false,
			retryOnMount: false,
			onSuccess: () => {
				setState({
					isSending: false,
					isSended: true,
					progress: false
				});
			},
			onError: (e) => {
				sendReport(e, {
					error: e,
					data: JSON.parse(e.config?.data),
					url: e.config?.url,
					method: e.config?.method
				});
				if (onError)
					onError(e);
			}
		}
	);

	return (
		<div className="d-flex flex-column SendingCont">
			<div className="d-flex sending-step-cont justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className={"state-icon me-2" + ((!state.isSended && (isIdle || isLoading)) ? " loading" : " done") + ((error || requiredError) ? " error" : "")}>
						{getIcon()}
					</div>
					<div>
						<div className="req-title">{title}</div>
						{
							(state.progress !== false && state.progress?.progress !== 1) &&
							<ProgressBar value={state.progress?.percent} />
						}
					</div>
				</div>

				{/* {
					error &&
						<div>
							<Button text={t("EditDrawer.RETRY_BTN")} className="btn-blue" onClick={handleRetry}/>
						</div>
				} */}
			</div>
			{
				error && <pre className="error-cont">
					<details>
						<summary>{t("Desktop.ERROR")}: {error?.response?.data?.ExceptionMessage}</summary>
						<hr />
						Datas: <br />
						{
							error?.response?.config?.data ?
								JSON.stringify(error?.response?.config?.data)?.replace(/(\\r|)\\n/gi, "\n", null, 4)
								:
								JSON.stringify(error?.response, null, 4)
						}
						<hr />
						Error:<br />
						{
							error?.response?.data ?
								JSON.stringify(error?.response?.data)?.replace(/(\\r|)\\n/gi, "\n", null, 4)
								:
								error.message
						}
					</details>
				</pre>
			}
		</div>
	);
}

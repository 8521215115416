import React from "react";
import EditDrawer from "components/EditDrawer/EditDrawer";
import IdentityStep, { checkIdentity } from "../GroupIdentity/GroupIdentityStep";
import useAccount from "classes/Accounts/hooks/useAccount";
import GroupMembersStep from "../GroupMembers/GroupMembersStep";
import GroupOptionsStep from "../GroupOptions/GroupOptionsSteps";
import "./GroupEdit.css";
import GroupSendStep from "../GroupSendStep/GroupSendStep";
import GroupTile from "../GroupTiles/Tile/GroupTile";
import { t } from "i18next";

function GroupEdit(props) {
	const { account } = useAccount();

	if (!props.isOpen)
		return (false);

	const GroupSteps = [
		{
			component: IdentityStep,
			name: "content",
			title: t("Commons.IDENTITY"),
			onNext: checkIdentity,
		},
		{
			component: GroupMembersStep,
			name: "members",
			title: t("Groups.MEMBERS"),
		},
		{
			component: GroupOptionsStep,
			name: "options",
			title: t("Commons.OPTIONS"),
			disabled: props.group.groupId()
		},
		{
			component: GroupSendStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("Commons.SEND_STEP")
		},
		{
			component: EndGroupStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true
		}
	];

	return (
		<div className="GroupEdit">
			<EditDrawer
				isOpen={props.isOpen}
				object={props.group}
				onClose={props.onClose}
				account={account}
				title={props.title}
				steps={GroupSteps}
				sendBtnText={t("EditDrawer.VALIDATE_BTN")}
			/>
		</div>
	);
}

function EndGroupStep(props) {
	if (!props.isActive)
		return (false);

	return (
		<GroupTile className="w-100 m-0" group={props.object?.values()}/>
	);
}

export default GroupEdit;

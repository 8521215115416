import React from "react";
import { Menu, Item, Separator, Submenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import "./ContextMenu.css" ;

export default function ContextMenu({id, items, className}) {

	if (!id)
	{
		console.error("No id provided for context menu");
		return (false);
	}

	const handleItemClick = (clickProps, item) => {
		if (item.onClick)
			item.onClick(clickProps);
	};

	const genMenu = (items, parent_key) => {
		if (!items)
			return (false);

		let ret = items.map((item, key) => {
			if (item.enabled === false)
				return (false);
			let item_key = (parent_key ? (parent_key + "-") : "") + key;
			if (item.items)
				return (genSubMenu(item, item_key));
			if (item.separator)
				return (<Separator key={key} />);
			return (genItem(item, item_key));
		});
		return (ret);
	};

	const genSubMenu = (menu, key) => {
		if (menu.enabled === false)
			return (false);
		return (
			<Submenu key={key} label={menu.label}>
				{
					genMenu(menu.items, key)
				}
			</Submenu>
		);
	};

	const genItem = (item, key) => (item.enabled !== false && <Item key={key} onClick={(a) => handleItemClick(a, item)}>{item.label}</Item>);

	return (
		<div className={`ContextMenu${className ? " " + className : ""}`}>
			<Menu id={id}>
				{ genMenu(items)}
			</Menu>
		</div>
	);
}

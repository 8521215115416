
import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import React from "react";
import {useQueryClient} from "react-query";
import { t } from "i18next";
import promiseToast from "components/Toasts/PromiseToast";
import TableToolbar from "components/Table/TableToolbar/TableToolbar";

export default function SitesTableToolbar(props) {
	const {selectedRows, clearSelection} = props;
	const {confirmDelete} = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		if (!selectedRows?.length)
			return (false);
		let title = t("Sites.ASKDELETE", {
			count: selectedRows?.length,
			name: selectedRows[0].Name,
		});
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selectedRows.map((site, i) => {
			if (!site.SiteId)
				return (false)
			return (
				new Promise((resolve, reject) => {
					setTimeout(() => {
						genRequest(
							`Sites/SiteId=${site.SiteId}AndDeleteAnimation=falseAndDeleteEmployes=falseAndDeletePost=falseAndDeleteDoc=false`,
							null,
							"delete"
						).then((resp) => {
							resolve(resp);
						}, reject);
					}, 100 * i);
				})
			)
		});
		let req = Promise.all(proms).then(() => {
			QCL.resetQueries(["Sites"]);
			if (props.onDelete)
				props.onDelete();
			clearSelection();
		});
		promiseToast(req);
		return (req);
	}

	return (
		<div>
			<TableToolbar
				{...props}
				handleDelete={handleDelete}
			/>
		</div>
	);
}

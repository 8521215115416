import ServError from "components/ServError/ServError";
import Page from "Pages/Page/Page";
import React from "react";
import {
	Switch,
	Route,
	useRouteMatch
} from "react-router-dom";
import NestedPagesMenu from "./Menu/NestedPagesMenu";
import "./NestedPages.css";

export default function NestedPages(props) {
	let { url } = useRouteMatch();
	let base_url = (props.baseUrl ? props.baseUrl : url);
	let pathname = base_url;

	if (!props.pages)
		return (false);
	return (
		<div className="NestedPages">
			<NestedPagesMenu items={props.pages} baseUrl={base_url} home={props.home}/>
			<Switch>
				{
					props.pages.map((sub_page, index) => {
						let paths = pathname + sub_page.url;
						if (sub_page.paths)
							paths = sub_page.paths.map((sub_path) => {
								return (pathname + sub_path);
							});
						return (
							<Route
								key={index}
								exact={sub_page.exact !== false ? true : false}
								path={paths}
							>
								<Page
									{...sub_page}
									title=""
									url={base_url + sub_page.url}
									{...sub_page.props}
								/>
							</Route>
						);
					})
				}
				<Route path="*">
					<ServError />
				</Route>
			</Switch>
		</div>
	);
}

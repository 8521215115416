import React, {useState} from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import genRequest from "includes/request";
import { t } from "i18next";

export default function EmployeSelector({selectedsUsers, setSelectedsUsers}) {
	const { account } = useAccount();
	const [selectedsSites, setSelectedsSites] = useState([]);
	const [selectedsFams, setSelectedsFams] = useState([]);
	const [users, setUsers] = useState([]);

	function showedUsers() {
		if (!users)
			return ([]);
		let ret = users.map(a => {
			if (filter(selectedsSites, "SiteId", a.SiteId, true))
				return (a);
			return (false);
		}).filter(a => a);
		return (ret);
	}

	function filter(src, col, value, rev_if_empty) {
		if (!src.length)
			return ((rev_if_empty ? true : false));
		for (let x in src)
			if (src[x][col] === value)
				return (src[x][col]);
		return (false);
	}

	return (
		<div className="d-flex h-100">
			<AsyncScrollTable
				companyId={account.CompanyId}
				itemId={"SiteId"}
				queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
				queryFn={() => genRequest("Sites/SitesOfCompany/" + account.CompanyId)}
				checkbox={true}
				className="light-bg"
				selected={selectedsSites}
				setSelected={setSelectedsSites}
				cols={[
					{
						name: "Name",
						title: t("User.SITE"),
						component: ({item}) => (<div>{[item.Name, item.Town].filter(a => a).join(" - ")}</div>)
					}
				]}
				sortBy="Name"
			/>
			<AsyncScrollTable
				companyId={account.CompanyId}
				itemId={"WorkFamillyId"}
				queryKey={["WorkFamillies", "WorkFamillyOfCompany", account.CompanyId]}
				queryFn={() => genRequest("WorkFamillies/WorkFamillyOfCompany/" + account.CompanyId)}
				checkbox={true}
				className="light-bg"
				selected={selectedsFams}
				setSelected={setSelectedsFams}
				filter={(a) => {
					if (filter(showedUsers(), "WorkFamillyId", a.WorkFamillyId))
						return (true);
					return (false);
				}}
				cols={[
					{
						name: "WorkFamillyName",
						title: t("User.WORK_FAM")
					}
				]}
				sortBy="WorkFamillyName"
			/>
			<AsyncScrollTable
				companyId={account.CompanyId}
				itemId={"EmployesId"}
				setSelected={setSelectedsUsers}
				selected={selectedsUsers}
				filter={(a) => {
					if (
						filter(selectedsFams, "WorkFamillyId", a.WorkFamillyId, true)
						&& filter(selectedsSites, "SiteId", a.SiteId, true)
					)
						return (true);
					return (false);
				}}
				queryKey={["Employes", "Employes", "EmployeOfCompany", account.CompanyId]}
				queryFn={() => genRequest("Employes/EmployeOfCompany/" + account.CompanyId)}
				queryOpt={{
					onSuccess: (resp) => {
						let ret = resp;
						if (resp.length)
							ret = resp.map(a => {
								a.FullName = a.LastName + " " + a.FirstName;
								return (a);
							});
						setUsers(resp);
						return (ret);
					}
				}}
				className="light-bg"
				checkbox={true}
				cols={[
					{
						name: "FullName",
						title: t("User.USER")
					}
				]}
				sortBy="FullName"
			/>
		</div>
	);
}

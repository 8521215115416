import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";

export default function FilledFormEndStep() {
	return (
		<div className="text-center inner-step-cont">
			<div>{t("SuccessMsg.FormMsg1")}</div>
			<div>{t("SuccessMsg.FormMsg2")}</div>
			<div style={{ fontSize: "3.75em", color: "green" }}><FontAwesomeIcon icon={faCheckCircle} /></div>
		</div>
	);
}

import React from "react";

export default function FormChoicesOption({unique, question, option, onChange, answers, isSelected}) {
	return (
		<div
			className="option-cont d-flex align-items-center"
			data-form-option-id={option?.OptionId}
		>
			<label className="d-flex align-items-center w-100 cursor-pointer option-label">
				<div>
					<input
						type={unique ? "radio" : "checkbox"}
						className='option-value me-2'
						name={"question-option" + question?.QuestionId}
						onChange={(e) => onChange({
							value: option?.OptionId,
							state: e.target.checked,
							...option,
							unique
						})}
						checked={isSelected(option.OptionId)}
					/>
				</div>
				<div className="option-value">{option.Value}</div>
			</label>
		</div>
	);
}

import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";
import {useParams} from "react-router-dom";
function ArticlesPage(props) {
	const {account, isAdmin} = useAccount();
	let { id } = useParams();

	return (
		<PostsPage
			title={props.title}
			companyId={account.CompanyId}
			typeId={3}
			baseUrl={props.baseUrl}
			forceCurId={id}
			add={isAdmin()}
			canComment={false}
			showComments={false}
			forceCompany={true}
			isMenuOpen={props.isMenuOpen}
			setIsMenuOpen={props.setIsMenuOpen}
			platform={props.platform}
		/>
	);
}

export default ArticlesPage;

const PageHeadBarHelper = [
	{
		target: ".PageHeadBar",
		title: "En-tête de page",
		content: "Ajouter un élément, favoris, brouillons, rechercher, filtrer",
		placement: "bottom-start"
	},
	{
		target: ".PageHeadBar .headbar-add-btn",
		title: "Bouton d'ajout",
		content: "Cliquez ici pour ajouter un élément",
		placement: "bottom-start"
	},
	{
		target: ".PageHeadBar .filter-button",
		title: "Bouton des filtres",
		content: "Cliquez ici pour filter la liste",
		placement: "bottom-start",
		spotlightClicks: true
	},
	{
		target: ".PageHeadBar .change-disp",
		title: "Changement d'affichage",
		content: "Cliquez ici pour changer l'affichage de la liste",
		placement: "bottom-start",
		spotlightClicks: true
	}
];

export default PageHeadBarHelper;

import React from "react";
import { CustomSelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import useTemplate from "hooks/useTemplate";
import { components } from "react-select";
import getChartTypes from "../../../../../../../classes/Charts/Types/ChartTypes";
import { hexToCSSFilter } from "hex-to-css-filter";
import { t } from "i18next";

const { Option } = components;

export default function ChartTypeSelect(props) {
	const {template} = useTemplate();
	let hexfilter = hexToCSSFilter(template.primaryColor());
	let filter_str = hexfilter.filter.replace(";", "");
	let types = getChartTypes();

	function genOptions() {
		let ret = types.map((a) => {
			return ({label: a.name, value: a.id, icon: a.icon, filter: filter_str});
		});
		return (ret);
	}

	return (
		<CustomSelect
			className="light-bg"
			components={{Option: CustomOption, Control: TypeLabel}}
			options={genOptions()}
			isMulti={false}
			title={t("Charts.TYPE")}
			filter={filter_str}
			{...props}
		/>
	);
}

const TypeLabel = ({ children, ...props }) => {
	let value = props.getValue();
	let icon = value[0];
	let filter = props.selectProps.filter;

	return (
		<components.Control {...props} className="ps-2">
			<TypeIcon filter={filter} {...icon}/> {children}
		</components.Control>
	);
};

const TypeIcon = (props) => {
	if (!props.icon)
		return (false);
	return (
		<img
			loading="lazy"
			src={props.icon}
			style={{
				width: props.width || 30,
				filter: props.filter,
			}}
			alt={props.label}
			className={props.className}
		/>
	);
};

const CustomOption = (props) => {
	return (
		<Option {...props} className="d-flex align-items-center p-1">
			<div
				className="d-flex justify-content-center align-column-center me-2 p-1"
				style={{
					backgroundColor: "var(--background-color)",
					borderRadius: "6px"
				}}
			>
				<img
					loading="lazy"
					src={props.data.icon}
					style={{
						width: 40,
						filter: props.data.filter,
					}}
					alt={props.data.label}
				/>
			</div>
			{props.data.label}
		</Option>
	);
};

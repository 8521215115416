import React, { useEffect, useState } from "react";
import OnlineUsers from "../OnlineUsers/OnlineUsers";
import MostLikedPosts from "../MostLikedPosts/MostLikedPosts";
import "./StatsBody.css";
import StatsChart from "../StatsChart/StatsCharts";
import AsyncPostPreview from "Pages/Posts/components/PostPreview/AsyncPostPreview";
import PostsOrigins from "../PostsOrigins/PostsOrigins";

export default function StatsBody(props) {
	const [CurPost, setCurPost] = useState(false);

	function handlePostClick(post) {
		setCurPost(post);
	}

	return (
		<div
			className={
				"StatsBody d-flex flex-column" +
				(CurPost ? " overflow-hidden" : "")
			}
		>
			<StatsChart company={props.company} />
			<div className="row">
				<div className="col-xl-6 mb-4">
					<MostLikedPosts max={15} onClick={handlePostClick} />
				</div>
				<div className="col-xl-6 mb-4">
					<OnlineUsers max={50} />
				</div>
			</div>
			<PostsOrigins />
			{CurPost && (
				<AsyncPostPreview
					className="stats-post-preview"
					postId={CurPost.PostId}
					forceExtend={true}
					resetCur={() => setCurPost(false)}
					showComments={true}
				/>
			)}
		</div>
	);
}

import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import Button from "components/Button/Button";

export default function SaveFavSites({ siteId, text, className }) {
	const QCL = useQueryClient();
	const { account } = useAccount();
	const [isSaved, setIsSaved] = useState(false);

	function handleSave(e) {
		let prom = null;
		e.preventDefault();
		e.stopPropagation();
		if (isSaved) prom = unsave(isSaved.ParticipationId);
		else prom = saveSite();
		prom.then(() => {
			QCL.refetchQueries("Lt_Employe_Liked_Site");
		});
		return prom;
	}

	const saveSite = () =>
		genRequest(
			"Lt_Employe_Liked_Site",
			{
				EmployesId: account.EmployesId,
				SiteId: siteId,
			},
			"post"
		).then((resp) => setIsSaved(resp));

	const unsave = () =>
		genRequest(
			"LtEmployeLikedSite/EmployeIdAndSiteId/" +
				account.EmployesId +
				"/" +
				siteId,
			null,
			"delete"
		).then(() => setIsSaved(false));

	const fetchSave = () =>
		genRequest(`LtEmployeLikedSite/ForEmploye/${account.EmployesId}`);

	const { isLoading } = useQuery(
		["Lt_Employe_Liked_Site", account.EmployesId, siteId],
		fetchSave,
		{
			retry: 0,
			enabled: siteId ? true : false,
			onSettled: (resp) =>
				setIsSaved(
					resp.filter((site) => site.SiteId === siteId).length > 0
				),
		}
	);

	if (isLoading)
		return (
			<div className="loading secondary">
				<div className="p-2 py-3 w-100"></div>
			</div>
		);

	return (
		<Button
			className={
				"SaveUserBtn unstyled d-flex w-auto align-items-center" +
				(isSaved ? " saved" : "") +
				(className ? ` ${className}` : "")
			}
			onClick={handleSave}
		>
			{text ? text : false}
			<FontAwesomeIcon
				className={
					(text ? "ms-2 " : "") +
					"favorite-icon" +
					(isSaved ? " saved" : "")
				}
				title={
					isSaved
						? t("Commons.DEL_FAVORITE")
						: t("Commons.ADD_FAVORITE")
				}
				color={isSaved ? "#ffc107" : ""}
				icon={isSaved ? faSolidStar : faStar}
			/>
		</Button>
	);
}

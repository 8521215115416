import React from "react";
import { Members } from "Pages/Groups/Groups";
import "./GroupTile.css";
import { t } from "i18next";

function GroupTile(props) {
	const {group} = props;
	if (!group)
		return (false);
	const {Name, Description, DefaultHexaColor} = group;

	return (
		<div className={"tile GroupTile" + (props.className ? " " + props.className : "")}onClick={props.onClick}>
			<div className="d-flex justify-content-between align-items-center">
				<div className="group-name">{Name}</div>
				<div
					className="color-dot"
					style={{
						backgroundColor: DefaultHexaColor
					}}
				/>
			</div>
			<div className="group-desc">
				{Description}
			</div>
			<hr className="my-2"/>
			<div>
				<div className="members-txt">{t("Groups.MEMBERS")}</div>
				<Members item={props.group} max={props.max}/>
			</div>
		</div>
	);
}

export default GroupTile;

import React from "react";
import { t } from "i18next";
import EditDrawer from "components/EditDrawer/EditDrawer";
import ChartTypeStep, { checkChartType } from "./steps/TypeStep/ChartTypeStep";
import ChartEditStep, { checkChartValues } from "./steps/EditStep/ChartEditStep";
import ChartOptionsStep from "./steps/OptionsStep/ChartOptionsStep";
import ChartSendStep from "./steps/EditStep/ChartSendStep";
import ChartEndStep from "./steps/EditStep/ChartEndStep";
import useAccount from "classes/Accounts/hooks/useAccount";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";

export default function ChartEditDrawer(props) {
	const isNew = !props.chart?.chartId();
	const { account } = useAccount();

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);
		return returnRightsFromName(account.Rights, rigthName) ? false : true;
	};
	const steps = [
		{
			component: ChartTypeStep,
			name: "content",
			title: t("Charts.TYPE_STEP"),
			onNext: checkChartType,
		},
		{
			component: ChartEditStep,
			name: "chart-edit",
			title: t("Charts.VALUES_STEP"),
			onNext: checkChartValues,
			props: {
				isNew
			}
		},
		{
			component: ChartOptionsStep,
			name: "chart-options",
			title: t("Commons.PUBLISH_OPTIONS"),
			disabled: checkRight()
		},
		//CHART SEND
		{
			component: ChartSendStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("Posts.SEND_STEP")
		},
		{
			component: ChartEndStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true,
			props: {
				dispDrafts: props.dispDrafts
			}
		}
	];

	return (
		<EditDrawer
			steps={steps}
			initialStep={!isNew ? 2 : 1}
			className="ChartEditDrawer"
			onClose={props.onClose}
			object={props.chart}
			isOpen={true}
			title={t("Charts.ADD_CHART")}
			initQueries={props.initQueries}
			onDraft={(draft_props) => {
				let chart = draft_props.object;
				chart.isDraft(true);
				return (true);
			}}
		/>
	);
}

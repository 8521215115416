import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";

export default function MyPosts(props) {
	const {account} = useAccount();

	return (
		<div className={"d-flex h-100 w-100 overflow-hidden"}>
			<PostsPage
				// add={true}
				checkbox={true}
				typeId={props.typeId}
				canComment={props.canComment}
				showComments={props.showComments}
				platform={"admin"}
				employeId={account.EmployesId}
				baseUrl={props.baseUrl}
				menuToggler={false}
				headBarLogo={false}
			/>
		</div>
	);
}

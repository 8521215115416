import React, {useState} from "react";
import { DatePickerToggler } from "components/DatePIcker/DatePicker";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Moment from "react-moment";
import { t } from "i18next";
import SwitchInput from "components/Inputs/Switch/SwitchInput";

export default function FormOptionsStep(props) {
	const form = props.object;
	const [errors, setErrors] = useState([]);
	const [isAnonymous, setIsAnonymous] = useState(form.isAnonymous());
	const [isDisabled] = useState(form.id() && form.isAnonymous() && form.hasAnswers());

	function checkErrors()
	{
		setErrors([]);
		let err = [];
		let pubDate = form.publishedDate();
		let avDate = form.availableToDate();

		if (avDate && avDate < pubDate)
			err.push(
				<div className="font-bold">
					{t("Forms.LIMIT_DATE_ERROR")}&nbsp;"
					<Moment className="" format={t("Events.DATE_TIME_FORMAT")}>
						{pubDate}
					</Moment>"
				</div>
			);
		setErrors(err);
	}

	return (
		<>
			<ErrorsCont className="mb-3" errors={errors}/>
			{
				<div className="inner-step-cont">
					<SwitchInput
						label={t("Forms.ANON_ANSWERS")}
						checked={isAnonymous}
						onChange={!isDisabled ? (val) => setIsAnonymous(form.isAnonymous(val)) : null}
						onlyState={true}
						disabled={isDisabled}
					/>
					{
						isDisabled &&
							<p className="mt-1 text-infos">
								{ t("Forms.CHANGE_ANON_ERROR_MSG") }
							</p>
					}
				</div>
			}
			<DatePickerToggler className="mb-3" onChange={(val) => {form.publishedDate(val); checkErrors();}} label={t("Forms.DEFFERED_PUBLISH")}/>
			<DatePickerToggler
				nullable={true}
				onChange={(val) => {form.availableToDate(val); checkErrors();}}
				label={t("Forms.ANS_LIMIT_DATE")}
				isChecked={form.availableToDate()?.length > 0}
				startDate={form.availableToDate() ? new Date(form.availableToDate()) : new Date()}
			/>
		</>
	);
}

import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";
import Document from "classes/Documents/DocumentModel";

export default function DocsTiles(props) {
	let ref = useRef();

	function handleScroll() {
		let el = ref.current?.el;
		if (el.offsetHeight >= el.scrollHeight - 10 && props.hasMore)
			props.getNextItems(30);
	}

	React.useEffect(() => {
		let el = ref.current?.el;

		handleScroll();
		el.addEventListener("mousewheel", handleScroll, { passive: true });
		return () => {
			el.removeEventListener("mousewheel", handleScroll);
		};
	}, []);

	return (
		<div
			id="posts-cont"
			className={
				"DocsTiles posts-cont xd w-100" +
				(!props.curPost ? " no-cur" : "")
			}
		>
			<InfiniteScroll
				className="d-flex flex-wrap overflow-x-hidden"
				ref={ref}
				dataLength={props.displayed?.length} //This is important field to render the next data
				next={props.getNextItems}
				hasMore={props.hasMore}
				loader={<LoadCont className="w-100" text={t("Docs.LOADING")} />}
				height={"100%"}
				endMessage={
					<div className="w-100 infinite-scroll-end-msg mt-4">
						<p style={{ textAlign: "center" }}>
							<b>
								<EndMsg
									searching={props.searching}
									items={props.displayed}
								/>
							</b>
						</p>
					</div>
				}
			>
				{props.displayed.map((doc) => {
					return (
						<Document
							onlyLiked={props.DispFavs}
							typeId={doc.TypeId}
							key={doc.DocumentId}
							datas={doc}
							catsFilter={props.dispCats}
							isActive={
								props.curDoc &&
								props.curDoc.DocumentId === doc.DocumentId
							}
							onClick={props.handleDocClick}
						/>
					);
				})}
			</InfiniteScroll>
		</div>
	);
}

const EndMsg = (props) => {
	if (!props.items?.length) {
		if (props.searching) return t("Docs.NO_RESULT");
		return t("Docs.NO_DOC");
	}
	return t("Docs.LIST_END");
};

import React, { useRef, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// import InfiniteScroll from 'react-infinite-scroller';
import { t } from "i18next";
import UserCard from "components/User/Card/UserCard";

export default function UsersTiles(props) {
	let ref = useRef(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [data, setData] = useState([]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [totalData, setTotalData] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const hasMore = data.length < totalData;

	useEffect(() => {
		if (props.filters && Object.keys(props.filters).length > 0) {
			setIsLoading(true);
			setCurrentPage(1);
			props
				.request(1)
				.then(async (result) => {
					const users = await props.treatUsers(result.Items);
					setTotalData(result.TotalCount);
					setData(users);

				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			// reset the data to clear the list
			loadNextPage(true);
		}
	}, [props.filters]);

	const loadNextPage = (reset) => {
		setIsLoading(true);
		props
			.request(currentPage + 1)
			.then(async (result) => {
				const users = await props.treatUsers(result.Items);
				setData(prevData => {
					if (reset) {
						// Clear the data if it's the first page
						return users;
					} else {
						// Append new users to existing data
						return [...prevData, ...users];
					}
				});
				setTotalData(result.TotalCount);
				setCurrentPage(prevPage => prevPage + 1);
			})
			.finally(() => {
				if (ref.current && ref.current.scrollTo) {
					// Restore scroll position after loading new items
					ref.current.scrollTo(0, scrollPosition);
				}
				setIsLoading(false);
			});
	};

	const handleScroll = () => {
		if (ref.current) {
			setScrollPosition(ref.current.scrollTop);
		}
	};

	return (
		<>
			{data.length > 0 ? (
				<div id="users-cont" className={"users-cont xd w-100"}>
					<InfiniteScroll
						className="user-tiles-scroll d-flex flex-wrap overflow-x-hidden gap-3 gap-md-4"
						ref={ref}
						dataLength={data.length}
						next={loadNextPage}
						hasMore={hasMore}
						onScroll={handleScroll}
						loader={<div className="container_user_tiles"><h4 className="loading">{t("Commons.LOADING")}</h4></div>}
						height={"100%"}
						endMessage={
							<div className="w-100 infinite-scroll-end-msg mt-4">
								<p style={{ textAlign: "center" }} >
									<b>
										{data.length === 0
											? t("Contacts.NO_USERS")
											: t("Contacts.LIST_END")}
									</b>
								</p>
							</div>
						}
					>
						{data.map((user, idx) => (
							<UserCard
								key={idx}
								user={user}
								groupsFilter={props.groupsFilter}
								onClick={props.handleClick}
							/>
						))}
					</InfiniteScroll>
				</div>
			) : (data.length === 0 && !isLoading ? <div className="container_user_tiles"><h4 className="p-3">{t("Contacts.NO_USERS")}</h4></div> : <div className="container_user_tiles"><h4 className="loading">{t("Commons.LOADING")}</h4></div>)}
		</>
	);
}

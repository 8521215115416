// eslint-disable-next-line
export default function custom_charts_getter(chart, table) {
	let rows = table.querySelectorAll("tbody tr");
	let values = [];

	if (!rows?.length)
		return (false);

	rows.forEach((row) => {
		let val = row.querySelector("td:nth-child(2) input")?.value;
		let label = row.querySelector(".row-title input")?.value;
		let value = {
			color: row.querySelector(".color-picker input")?.value,
			value: Number(val),
			label: label,
			textColor: "#000000"
		};

		if (val?.length && label?.length)
			values.push(value);
	});
	return (values);
}

import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUnread from "classes/Accounts/hooks/useUnread";
import genRequest from "includes/request";
import { useState } from "react";
import DocPreview from "../DocPreview/DocPreview";
import "./DocIconDownloader.css";

const DocIconDownloader = ({ doc }) => {
    const { isReaded, setAsReaded } = useUnread();
    const [CurDoc, setCurDoc] = useState(false);
    const { Name } = doc;

    function getDoc() {
        incrementViews().then(() => {
            setAsReaded("NotViewedDocUtils", doc.DocumentId);
        });
        setCurDoc(doc);
        // if (props.onClick)
        //     props.onClick(doc);
    }

    const incrementViews = () => {
        let req = genRequest(
            "Documents/IncrementView",
            doc.DocumentId,
            "put"
        ).then((resp) => {
            return (resp);
        }).catch(() => {
        });
        return (req);
    };
    return (
        <>
            {CurDoc && <DocPreview id="doc-preview" className="doc-preview" onClose={() => setCurDoc(false)} document={CurDoc} documentId={CurDoc.DocumentId} />}
            <div className="doc-btn-viewer" onClick={getDoc}>
                <FontAwesomeIcon icon={faFilePdf} />
                <div className="doc-btn-viewer_text">{Name}</div>
            </div>
        </>
    );
}

export default DocIconDownloader;
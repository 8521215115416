import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";
import { t } from "i18next";
import {useParams} from "react-router-dom";

function CareerPage(props) {
	const {account, isSup} = useAccount();
	let { id } = useParams();

	return (
		<PostsPage
			baseUrl={props.baseUrl}
			title={props.title}
			companyId={account.CompanyId}
			typeId={5}
			forceCurId={id}
			add={isSup("SiteAdmin") >= 0}
			commentBtnTxt={t("Posts.CAREER_APPLY")}
			canComment={true}
			showComments={false}
			childProps={{
				images: false
			}}
			isMenuOpen={props.isMenuOpen}
			setIsMenuOpen={props.setIsMenuOpen}
			platform={props.platform}
		/>
	);
}

export default CareerPage;

import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import Company from "classes/Companies/CompanyObject";
import CompanyPage from "components/CompanyPage/CompanyPage";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import { AsyncUsersList } from "components/Users/UsersList/UsersList";
import genRequest from "includes/request";
import { t } from "i18next";
import "./Companies.css";
import CompanyEditDrawer from "./components/CompanyEdit/CompanyEdit";

export default function CompaniesPages(props) {

	const { isSup } = useAccount();
	const { isMenuOpen, setIsMenuOpen } = props;
	const [editObj, setEditObj] = useState(false);
	const [curComp, setCurComp] = useState(false);

	const cols = [
		{
			title: t("Companies.NAME"),
			name: "Name",
			classes: "comp-name-cell",
		},
		{
			name: "users",
			title: "Users",
			component: UsersCell
		}
	];

	function handleAdd() {
		let comp = new Company();

		setEditObj(comp);
	}

	function handleCloseEdit() {
		setEditObj(false);
	}

	if (isSup("EloAdmin") < 0)
		return (false);

	return (
		<div className="CompaniesPage">
			<PageHeadBar
				title={t("Pages.COMPANIES")}
				search={false}
				siteSelect={false}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				handleAdd={handleAdd}
				addTitle={t("Companies.ADD_NETWORK")}
			/>
			<AsyncScrollTable
				queryKey={["Companies"]}
				queryFn={() => genRequest("Companies")}
				cols={cols}
				itemId="CompanyId"
				sortBy="Name"
				onItemClick={(comp) => setCurComp(comp)}
			/>
			<CompanyEditDrawer
				isOpen={editObj}
				onClose={handleCloseEdit}
				company={editObj}
			/>
			{
				curComp &&
				<PreviewDrawer
					companyId={curComp?.CompanyId}
					isOpen={curComp}
					onClose={() => setCurComp(false)}
					child={CompanyPage}
					fullScreen={true}
					childProps={{
						companyId: curComp.CompanyId,
						baseUrl: "/companies"
					}}
				/>
			}
		</div>
	);
}

function UsersCell({ item }) {
	return (
		<AsyncUsersList
			queryFn={() => genRequest("Employes/MinimalContactEmployesOfCompany/" + item.CompanyId)}
			queryKey={["Employes", "MinimalContactEmployesOfCompany", item.CompanyId]}
			max={15}
			width={20}
			total
			treat={(a) => ({ ...a, EmployesId: a.EmployeId, FirstName: a.EmployeFirstName, LastName: a.EmployeLastName })}
		/>
	);
}

import React from "react";
import UserCard from "components/User/Card/UserCard";
import { useQueryClient } from "react-query";

export default function UserEndStep(props) {
	const user = props.object;
	const QCL = useQueryClient();

	if (!props.isActive || !user.employesId())
		return (false);
	
	QCL.resetQueries(["Documents", "DocumentOfEmployeAndType", user.employesId(), 2]);

	return (
		<UserCard className="w-100 m-0 pe-none" user={user.values()}/>
	);
}

import React from "react";
import { t } from "i18next";
import ScrollTable from "components/ScrollTable/ScrollTable";
import Moment from "react-moment";

export default function AnimationsDraftsTable({events, onItemClick}) {
	return (
		<ScrollTable
			cols={[
				{
					title: t("Table.TITLE"),
					name: "Title",
					classes: "post-title-cell"
				},
				{
					title: t("Events.START_DATE"),
					name: "DateStart",
					classes: "post-date-cell",
					component: DateCell,
					childProps: {
						itemKey: "DateStart"
					}
				},
				{
					title: t("Events.END_DATE"),
					name: "DateEnd",
					classes: "post-date-cell",
					component: DateCell,
					childProps: {
						itemKey: "DateEnd"
					}
				},
				{
					title: t("Commons.LAST_CHANGED"),
					name: "LastChanged",
					classes: "post-date-cell",
					component: DateCell,
					childProps: {
						itemKey: "LastChanged"
					}
				}
			]}
			sortBy="LastChanged"
			revSort={true}
			itemId={"AnimationId"}
			items={events}
			onItemClick={onItemClick}
		/>
	);
}

function DateCell(props) {
	const {item} = props;

	if (!props.itemKey || !item[props.itemKey])
		return (false);
	return (
		<div className="d-flex align-items-center">
			<Moment format={t("Events.DATE_TIME_FORMAT")}>
				{item[props.itemKey]}
			</Moment>
		</div>
	);
}

import Storage from "classes/Storage/Storage";
import { t } from "i18next";
import { useQueryClient } from "react-query";

/**
* @class
* @example
* const { unread } = useUnread();
* return (
* 	<div>{ account.FirstName }</div>
* );
*/
function useUnread(storage) {

	const queryClient = useQueryClient();

	if (!storage)
		storage = new Storage(null, null, sessionStorage);

	const setItems = (type, items) => {
		storage.setItem("Unreaded_" + type, items);
		//queryClient.fetchQuery(["UpdateNotifCount"]);
		updatePageTitle();
	};

	const getUnread = (type) => {
		let res = [];
		try {
			res = storage.getItem("Unreaded_" + type);
			if (!res)
				res = [];
		} catch (e) { res = []; }
		return (res);
	};

	const setAsReaded = (type, id) => {
		let items = getUnread(type);
		let index = items.indexOf(id);

		if (index !== -1)
		{
			items.splice(index, 1);
			queryClient.refetchQueries(["NotViewed", "Me", ...type.split("_")]);
			setItems(type, items);
		}
	};

	function getNotifCount() {
		let total = 0;

		queryClient.getQueriesData(["NotViewed", "Me"]).forEach(a => {
			if (a[1]?.length)
				total += a[1].length;
		});
		return (total);
	}

	function updatePageTitle(title, force_count) {
		let old_title = storage.getItem("page_title");
		let new_title = title;
		let favicon_url = "/assets/icons/favicon.ico";
		let notif_count = getNotifCount();

		if (typeof force_count !== "undefined")
			notif_count = force_count;
		if (!new_title)
			new_title = old_title;
		if (notif_count)
			favicon_url = "/assets/icons/favicon-notif.ico";
		document.querySelectorAll(".favicon").forEach((e) => {
			e.href = favicon_url;
		});
		document.title = (notif_count ? "(" + notif_count + ") | " : "") + "OnlyAll | " + (new_title ? new_title : t("Commons.WELCOME"));
		storage.setItem("page_title", new_title);
		return (notif_count);
	}

	const isReaded = (type, id) => {
		let items = getUnread(type);
		return (!(items.indexOf(id) >= 0));
	};

	return ({
		setItems,
		updatePageTitle,
		setAsReaded,
		getUnread,
		isReaded
	});
}

export default useUnread;

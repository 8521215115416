import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default  function Summary(props) {
	const {icon, items, title, className} = props;

	return (
		<details className={className}>
			<summary>
				<span className="">
					{icon && <FontAwesomeIcon icon={icon} className="me-1"/>}
					<span>{ title }</span>
				</span>
			</summary>
			<div className={`my-2 d-flex flex-wrap gap-1`}>
				{items || props.children}
			</div>
		</details>
	);
}

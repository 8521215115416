import FormGenerator from "classes/Forms/components/FormGenerator/FormGenerator";
import { t } from "i18next";
import React from "react";

export default function FormGenStep(props) {
	let form = props.object;

	return (
		<FormGenerator form={form} parentScroll={".edit-drawer"}/>
	);
}

export function checkForm(form) {
	document.querySelectorAll(".field-error").forEach(a => a.classList.remove("field-error"));
	let check = form.checkValues();
	if (check === true)
		return (true);
	let elem = document.querySelector(check.selector);
	if (elem)
		elem.classList.add("field-error");
	let error = t("Forms." + check.error);
	return ([error]);
}

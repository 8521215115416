import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";
import { t } from "i18next";

export default function BackButton(props) {
	return (
		<Button {...props} className={"w-auto btn-bg-light" + (props.className ? " " + props.className : "")}>
			<FontAwesomeIcon icon={faChevronLeft} className="me-1" />
			<div>{props.value || t("Commons.BACK")}</div>
		</Button>
	);
}

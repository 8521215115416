export default function getDomPath(el, options) {
	var stack = [];
	while ( el.parentNode != null ) {
	  var sibCount = 0;
	  var sibIndex = 0;
	  for ( var i = 0; i < el.parentNode.childNodes.length; i++ ) {
		var sib = el.parentNode.childNodes[i];
		if ( sib.nodeName === el.nodeName ) {
		  if ( sib === el ) {
			sibIndex = sibCount;
		  }
		  sibCount++;
		}
	  }
	  let classes = el.classList.value?.length > 0 ? el.classList.value.replace(/(^|\s)/gi, ".") : "";
	  let nodeName = options?.noNodeName ? "" : el.nodeName.toLowerCase();
	  if (options?.removeClass)
		classes = classes.replace(options.removeClass, "")
	  if ( el.hasAttribute('id') && el.id !== '' ) {
		stack.unshift(nodeName + '#' + el.id);
	  } else if ( sibCount > 1 && !options?.noSiblings ) {
		stack.unshift(nodeName + classes + ':eq(' + sibIndex + ')');
	  } else {
		stack.unshift(nodeName + classes);
	  }
	  el = el.parentNode;
	}
  
	return stack.slice(1); // removes the html element
  }
  
  
import useAccount from "classes/Accounts/hooks/useAccount";
import LoadCont from "components/LoadCont/LoadCont";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";
import { useQuery } from "react-query";
import "./PageLoad.css";
import Storage from "classes/Storage/Storage";

function PageLoad(props) {
	const { account } = useAccount();
	const storage = new Storage();

	function retrieveImage() {
		let req = genRequest(
			"Documents/DocumentOfCompanyAndType/" + account?.CompanyId + "/12",
			null,
			"get"
		).then((images) => {
			if (images.length) return documents_treat(images, "LastChanged")[0];
			return false;
		});
		return req;
	}

	const { data } = useQuery(
		["Documents", "DocumentOfCompanyAndType", account?.CompanyId, 12],
		retrieveImage,
		{
			refetchOnWindowFocus: false,
			enabled: props.background !== false && account?.CompanyId > 0,
			onSettled: (resp) => {
				if (!resp) return storage.getItem("comp_image");
				storage.setItem("comp_image", resp);
				return resp;
			},
		}
	);

	let bgImage = data?.Url_Origin;
	if (props.forceUrl?.length) bgImage = props.forceUrl;
	if (props.fallbackUrl && (!data || !bgImage)) bgImage = props.fallbackUrl;

	return (
		<div
			className={
				"page-load-cont" +
				(props.className ? " " + props.className : "")
			}
			style={{ ...props?.style }}
		>
			{props.background !== false && (
				<div
					className={"page-load-bg" + (bgImage ? " hasImage" : "")}
					style={{
						backgroundImage: bgImage
							? "url(" + bgImage + ")"
							: false,
					}}
				/>
			)}
			<div className="inner-load">
				<div className="spinner-cont">
					<LoadCont />
				</div>
				<div>
					<div
						className="load-msg mt-2"
						style={{ color: props.textColor }}
					>
						{props.text ? props.text : t("Commons.PAGE_LOAD")}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PageLoad;

import React, { useState } from "react";
import { t } from "i18next";
import PasswordInput from "components/Inputs/PasswordInput";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import FormInput from "components/Inputs/FormInput";
import "./LoginForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AccountObject from "classes/Accounts/Account/AccountObject";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";
import LoadCont from "components/LoadCont/LoadCont";
import ForgetCont from "../ForgetPage/ForgetForm";

export default function LoginForm(props) {
	const { isLogingIn, setIsLogingIn, user, onCancel, onConnected, setCurrent } = props;
	const [Username, setUsername] = useState(user?.Login || "");
	const [Password, setPassword] = useState("");
	const [SavePass, setSavePass] = useState(false);
	const [Error, setError] = useState(false);
	const [ResetPass, setResetPass] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		setError(false);
		if (!Username || !Password)
			return (setError(t("Login.EMPTY_FORM")));
		let account = new AccountObject(user ? user : {});
		setIsLogingIn(true);
		let prom = account.connect(Username, Password)
			.then(() => {
				if (onConnected)
					onConnected(account, SavePass);
			}, handleErrors);
		return (prom);
	}

	function handleErrors(e) {
		setIsLogingIn(false);
		if (!e)
			return (setError("Unknow Error"));
		let trad = t("Login." + e.code);
		if (e.response?.data) {
			let data = e.response.data;
			let check = t("Login." + data.error);
			if (check)
				return (setError(check));
		}
		if (trad)
			return (setError(trad));
		return (setError([e?.code, e?.message, e?.response?.data?.error, e?.response?.data?.error_description]));
	}

	const show_user_field = true;

	if (isLogingIn)
		return (<LoadCont />);

	return (
		<div className="LoginForm">
			{
				ResetPass ?
					<ForgetCont
						onCancel={() => setResetPass(false)}
						mail={(user?.Mail ? user?.Mail : "")}
						showMailField={!user?.Mail && ""}
						// disabled while there is not request for reset with login
						// mail={(user?.Login ? user?.Login : Username)}
						// showMailField={!user?.Login && Username}
						onAccountChange={() => setCurrent({})}
					/>
					:
					<>
						<form onSubmit={handleSubmit}>
							<div>
								<div className="inputs-wrapper">
									<label className={`d-flex align-items-center mb-1 ${(show_user_field ? "" : " ")}`}>
										<FontAwesomeIcon icon={faUser} />
										<FormInput
											className="ms-1 w-100 login-input"
											placeholder={t("Login.LOGIN_PH")}
											type="text"
											onChange={setUsername}
											autoFocus={show_user_field}
											onChangeCapture={setUsername}
											value={Username}
											disabled={user?.Login ? true : false}
										/>
									</label>
									<label className="d-flex align-items-center">
										<FontAwesomeIcon icon={faLock} />
										<PasswordInput className="ms-1 w-100 login-input" placeholder={t("Login.PASS_PH")} autoFocus={show_user_field && Username?.length} value={Password} onChange={setPassword} />
									</label>
								</div>
								<SwitchInput
									label={t("Login.SAVE_PASS")}
									className={"mt-4 remember-pass-cont user-select-none"}
									checked={SavePass}
									onChange={(e) => setSavePass(e.target.checked)}
								/>
							</div>
							<ErrorsCont
								className="mt-3"
								errors={Error}
								listed={false}
							/>
							<div className="d-flex align-items-center mt-3">
								{
									(onCancel && user) &&
									<div>
										<Button href="/#" type="button" className="unstyled me-2 back-btn" onClick={onCancel}>
											<FontAwesomeIcon className={"arrow-icon"} icon={faArrowLeft} />
										</Button>
									</div>
								}
								<Button className="btn-conn" type="submit">{isLogingIn ? t("Login.CONNECTING") : t("Login.CONNECT")}</Button>
							</div>
						</form>
						<div className="text-center mt-2">
							<Button className="unstyled" onClick={() => setResetPass(true)}>{t("Login.FORGOTTEN_IDS")}</Button>
						</div>
					</>
			}
		</div>
	);
}

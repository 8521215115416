import generateUUID from "includes/UUID";
import { getAssignTypes } from "./AssignTypes";

export default function assignsBuilder(affects) {
	let Assigns = [];

	affects.forEach((a) => {
		let values = getAffectValues(a);
		if (!values?.length)
			return (false);
		values.forEach((val) => {
			genAffect(Assigns, val);
		});
	});
	return (Assigns);
}

const genAffect = (assigns, affect) => {
	let {indexes, value} = affect;
	let cur_index = indexes[0];

	if (!indexes)
		return (false);
	if (!assigns[cur_index])
		assigns[cur_index] = genAssign(affect);
	else if (indexes.length === 1)
	{
		if (assigns[cur_index].values.findIndex((a) => a.value === value) === -1)
			assigns[cur_index].values.push(({
				value,
				label: value,
				CompanyId: affect.CompanyId
			}));
	}
	let new_indexes = indexes.slice(1, indexes.length);
	let new_affect = {
		...affect,
		indexes: new_indexes
	};
	if (new_indexes.length)
		genAffect(assigns[cur_index].assigns, new_affect);
};

const genAssign = (affect) => {
	let {type, baseType, deepLevel, value} = affect;

	let ret = {
		baseType,
		type,
		rowId: generateUUID(),
		addType: "AND",
		deepLevel,
		assigns: [],
		values: [{
			value: value,
			label: value,
			CompanyId: affect.CompanyId
		}],
		ids: {
			CompanyId: affect.CompanyId
		},
		affect
	};
	ret[type.itemId] = affect[type.itemId];
	return (ret);
};

const getAffectValues = (affect) => {
	let ids = getAffectationIds(affect);
	let indexes = getAffectationIndexes(affect);

	if (!indexes?.length)
		return (false);
	let ret = indexes.map((a, b) => {
		let slice = indexes.slice(0, b + 1) ;
		return ({
			...ids[b],
			AffectationGroupId: slice.join("-"),
			indexes: slice
		});
	});
	return (ret);
};

const getAffectationIds = (affect) => {
	let deepLevel = 0;
	let types = getAssignTypes();
	let ret = types.map((a, b) => {
		if (affect[a.itemId] > 0)
		{
			return ({
				baseType: types[b - 1] ? types[b - 1] : false,
				type: a,
				value:	affect[a.itemId],
				CompanyId: affect.CompanyId,
				deepLevel: deepLevel++
			});
		}
		return (false);
	}).filter(a => a);
	return (ret);
};

const getAffectationIndexes = (affect) => {
	if (affect?.AffectationGroupId === undefined || affect?.AffectationGroupId === null)
		return (false);
	let split = affect.AffectationGroupId.split("-");
	if (split.length)
		return (split.map((a) => parseInt(a)));
	return (false);
};

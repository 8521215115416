/* eslint-disable */
import React from "react";
import { useQuery } from "react-query";
import genRequest from "includes/request";
import Table, { itemsSort } from "components/Table/Table";
import LoadCont from "components/LoadCont/LoadCont";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import { t } from "i18next";
import noAccent from "includes/no_accents";
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
export default function Playground(props) {
	let { isLoading, error, data } = useQuery(
		["Employes", "EmployeOfCompany", 1],
		() => genRequest("Employes/EmployeOfCompany/1"),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	if (isLoading) return <LoadCont />;

	return (
		<div style={{ maxWidth: "100%" }}>
			<Table
				columns={[
					{ title: "EID", field: "EmployesId" },
					{
						title: "FirstName",
						field: "FirstName",
						customSort: (a, b) => itemsSort(a, b, "FirstName"),
					},
					{
						title: "LastName",
						field: "LastName",
						customSort: (a, b) => itemsSort(a, b, "LastName"),
					},
					{
						title: "WFam",
						field: "WorkFamillyId",
						render: (data) => (
							<ProfessionsCell item={data} type="WFuncName" />
						),
					},
					{
						title: "WFam",
						field: "WorkFamillyId",
						render: (data) => (
							<ProfessionsCell item={data} type="WFuncName" />
						),
					},
				]}
				options={{
					selection: true,
				}}
				actions={[
					{
						tooltip: "Delete",
						title: "delete",
						onClick: (data, e) => console.log(data, e),
					},
					{
						tooltip: "Copy",
						title: "copy",
						icon: faCopy,
						popper: test,
						// onClick: (data, e) => console.log(data, e)
					},
				]}
				data={data}
			/>
		</div>
	);
}

const test = () => <div>ok</div>;

const ProfessionsCell = React.memo(({ item, type }) => {
	return (
		<InfosFetcher
			queryKey={[
				"Employes",
				"MinimalContactEmployesFromId",
				item?.EmployesId,
			]}
			queryFn={() =>
				genRequest(
					"Employes/MinimalContactEmployesFromId/" + item?.EmployesId
				)
			}
			treat={(data) => {
				let value = data.WFY;
				if (type === "WFuncName") value = data.WFU;
				item[type] = value;
				return value;
			}}
		/>
	);
});
ProfessionsCell.displayName = "ProfessionsCell";

/* eslint-enable */

import React, { Fragment, useEffect, useState } from "react";
import "./Tabs.css";

export default function Tabs(props) {
	const { items, onChange, passProps, allowOverflow, activeIndex } = props;
	const [isActive, setIsActive] = useState(0);

	useEffect(() => {
		const firstItemVisible = items.findIndex((item) => item.isVisible);
		setIsActive(firstItemVisible);
	}, [items]);

	const handleTabChange = (tab) => {
		const findId = items.findIndex((item) => tab === item);
		setIsActive(findId);
	};

	const renderComponent = (item) => {
		const Child = item.child;
		return Child && <Child {...passProps} {...item.childProps} />;
	};

	return (
		<div
			className={
				"tabs-cont" +
				(allowOverflow ? " allow-overflow" : "") +
				(props.className ? " " + props.className : "")
			}
		>
			<div className="tabs-head">
				{items.map((item, idx) => (
					<Fragment key={idx}>
						{item.isVisible && (
							<div
								className={
									"tab-title" +
									(isActive === idx ? " active" : "")
								}
								onClick={() => handleTabChange(item)}
								title={item.hoverTitle || ""}
							>
								{item.title}
							</div>
						)}
					</Fragment>
				))}
			</div>
			<div className="tabs-body">
				{items.map((item, idx) => (
					<Fragment key={idx}>
						{item.isVisible && (
							<>{isActive === idx && renderComponent(item)}</>
						)}
					</Fragment>
				))}
			</div>
		</div>
	);
}

function TabsHead({ items, active, onChange }) {
	const cont_ref = React.useRef();

	if (!items?.length) return false;

	const handleScroll = (e) => {
		let cont = cont_ref?.current;

		e.stopPropagation();
		if (cont) cont.scrollLeft = cont.scrollLeft + e.deltaY;
	};

	const handleTabChange = (tab) => {
		if (onChange) onChange(tab);
		if (tab.hash) window.location.hash = tab.hash;
		active.set(tab);
	};

	return (
		<div className="tabs-head" ref={cont_ref} onWheel={handleScroll}>
			{items.map((a, index) => {
				if (a.enabled === false) return false;
				let isActive = active?.cur?.id === a.id ? true : false;
				if (!active?.cur) isActive = index === 0 ? true : false;
				return (
					<div
						key={index}
						className={"tab-title" + (isActive ? " active" : "")}
						onClick={() => handleTabChange(a)}
						title={a.hoverTitle || ""}
					>
						{a.title}
					</div>
				);
			})}
		</div>
	);
}

function TabsBody({ active, passProps }) {
	if (!active.cur) return false;

	let Child = active.cur.child;

	return (
		<div className="tabs-body">
			{Child && <Child {...passProps} {...active.cur.childProps} />}
		</div>
	);
}

function getItem(items, id, enabled) {
	if (!items?.length) return false;
	if (
		typeof id === "undefined" ||
		id < 0 ||
		(enabled && items[id].enabled === false)
	)
		return items[0];
	return items[id];
}

import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import "./NestedPagesMenu.css";

export default function NestedPagesMenu(props) {
	const cont_ref = React.useRef();

	let { url } = useRouteMatch();
	let location = useLocation();
	let base_url = props.baseUrl ? props.baseUrl : url;
	let cur_sub_page = location.pathname.replace(props.baseUrl, "");

	React.useEffect(() => {
		let cont = cont_ref?.current;

		if (cont) {
			let child = cont.querySelector("[url='" + cur_sub_page + "']");
			if (child)
				cont.scrollLeft =
					-(cont.clientWidth / 2 + child.clientWidth / 2) +
					child.offsetLeft +
					child.clientWidth;
		}
	}, []);

	const handleScroll = (e) => {
		let cont = cont_ref?.current;

		e.stopPropagation();
		if (cont) cont.scrollLeft = cont.scrollLeft + e.deltaY;
	};

	if (!cur_sub_page.length) cur_sub_page = props.home;

	return (
		<div
			className={
				"NestedPagesMenu d-flex mb-3" +
				(props.className ? " " + props.className : "")
			}
			ref={cont_ref}
			onWheel={handleScroll}
		>
			{props.items.map((sub_page) => {
				return (
					<div key={sub_page.url} className="me-3 sub-page-link-cont">
						<Link
							to={`${base_url}${sub_page.url}`}
							className={
								"sub-page-link" +
								(cur_sub_page.indexOf(sub_page.url) === 0
									? " active"
									: "")
							}
						>
							{sub_page.title}
						</Link>
					</div>
				);
			})}
		</div>
	);
}

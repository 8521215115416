import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAccount from "classes/Accounts/hooks/useAccount";
import TagLabel from "components/Tags/Label/TagLabel";
import genRequest from "includes/request";
import React from "react";
import { useQuery } from "react-query";
import "./HighlightedCategories.css";

export default function HighlightedCategories(props) {
	const { account, getSettings } = useAccount();

	const handleClick = (item) => {
		if (props.onItemClick) props.onItemClick(item);
	};

	const fetch = () =>
		genRequest(
			"Categories/CompanyTyped/" + account.CompanyId + "/" + props.typeId
		);

	const { isLoading, data } = useQuery(
		["HighlightedCategories", account.CompanyId, props.typeId],
		fetch,
		{
			enabled: getSettings("FavCategories") !== false,
		}
	);

	if (!getSettings("FavCategories")) return false;
	if (isLoading)
		return (
			<FontAwesomeIcon
				icon={faCircleNotch}
				className="fa-spin"
				color={"var(--color-primary)"}
			/>
		);
	if (!data) return false;
	return (
		<div
			className={"HighlightedCategories d-flex gap-2 " + props.className}
		>
			{data.map((a) => {
				if (!a.IsFavorite) return false;
				let isSelected = props.selected?.find(
					(b) => b === a.CategoryId
				);
				let color = !props.selected?.length
					? a.DefaultHexaColor
					: isSelected
					? a.DefaultHexaColor
					: "grey";
				return (
					<TagLabel
						text={a.Name}
						color={color}
						onClick={() => handleClick(a)}
						style={{
							fontSize: "0.7rem",
							// maxWidth: "unset",
							// width: "100%",
							display: "block",
						}}
					/>
				);
			})}
		</div>
	);
}

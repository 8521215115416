import { t } from "i18next";

const getAppFunctions = () => [
	{
		title: t("Pages.CONTACTS"),
		id: "ContactPage",
	},
	{
		title: t("Pages.ARTICLES"),
		id: "CorporatePage.Articles",
	},
	{
		title: t("Pages.CHARTS"),
		id: "CorporatePage.Charts",
	},
	{
		title: t("Pages.DOCUMENTS"),
		id: "CorporatePage.DocsUtils",
		// childs: [
		// 	{
		// 		id: "DocumentsPage.AllowSharing"
		// 	}
		// ]
	},
	{
		title: t("Pages.RSS"),
		id: "PeoplePage.Rss",
	},
	{
		title: t("Pages.AGENDA"),
		id: "PeoplePage.Agenda",
		childs: [
			{
				id: "AnimationsPage.CanParticipate",
			},
		],
	},
	{
		title: t("Pages.CAREERS"),
		id: "PeoplePage.Career",
	},
	{
		title: t("Pages.OFFERS"),
		id: "PeoplePage.ViewAd",
		childs: [
			{
				id: "PeoplePage.PostAd",
			},
			{
				id: "OffersPage.PostQueueStyle",
				rootId: "PostQueueStyleAd",
				checkedValue: "Draft",
				notCheckedValue: "Published",
			},
		],
	},
	{
		title: t("Pages.PRO_OFFERS"),
		id: "PeoplePage.ViewProAd",
		childs: [
			{
				id: "PeoplePage.PostProAd",
			},
			{
				id: "ProOffers.PostQueueStyle",
				rootId: "PostQueueStyleProAd",
				checkedValue: "Draft",
				notCheckedValue: "Published",
			},
		],
	},
	{
		title: t("Pages.PROFILE"),
		childs: [
			{
				id: "ProfilePage.PersonalDocumentPage.MyAd",
			},
			{
				id: "ProfilePage.PersonalDocumentPage.MyNews",
			},
			{
				id: "ProfilePage.PersonalDocumentPage.PaySlip",
			},
			{
				id: "ProfilePage.PersonalDocumentPage.SavedNews",
			},
			{
				id: "ProfilePage.PersonalProfilPage.CanChangePassword",
			},
			{
				id: "ProfilePage.PersonalProfilPage.CanChangeProfil",
			},
		],
	},
	{
		title: t("Pages.NEWS"),
		childs: [
			{
				id: "NewsPage.PostQueueStyle",
				rootId: "PostQueueStyleNews",
				checkedValue: "Draft",
				notCheckedValue: "Published",
			},
			{
				title: "Commentaires des news",
				id: "NewsPage.Comments",
			},
		],
	},
	{
		title: t("AppFunctions.PostsPage"),
		childs: [
			{
				title: "CanUserUseMarkdown",
				id: "PostsPage.CanUseMarkdown",
			},
		],
	},
	{
		title: t("Pages.SITES"),
		id: "SitesPage",
	},
	{
		title: "Formulaires",
		id: "Forms",
	},
];

export default getAppFunctions;

import React, { useState, useRef, useCallback } from "react";
import FormInput from "components/Inputs/FormInput";
import ChartEditTable from "./ChartEditTable/ChartEditTable";
import ChartTypeSelect from "./ChartTypeSelect/ChartTypeSelect";
import ChartOptions from "./ChartOptions/ChartOptions";
import "./ChartEditStep.css";
import useTheme from "hooks/useTheme";
import generateUUID from "includes/UUID";
import { t } from "i18next";
import ChartTile from "components/Charts/Tile/ChartTile";

export default function ChartEditStep(props) {
	const { getTheme } = useTheme();
	const TM = useRef();
	const [Update, setUpdate] = useState({
		LastUpdate: false,
		PreviewNeedRefresh: false,
	});
	const initValues = useRef(null);
	const chart = props.object;
	const type = chart.SFChartType;

	const genInitValues = useCallback(() => {
		if (props.isNew || !type?.valuesDisplayer) return false;
		return type.valuesDisplayer(chart);
	}, [type, props, chart]);

	React.useEffect(() => {
		chart.options("TempId", generateUUID(), true);
		if (initValues?.current === null) {
			initValues.current = genInitValues();
		} else {
			if (Update.PreviewNeedRefresh) {
				setUpdate({
					LastUpdate: Date.now(),
					PreviewNeedRefresh: false,
				});
			}
		}
	}, [Update, setUpdate, chart, genInitValues]);

	if (!props.isActive) return false;

	const handleChange = (no_TM) => {
		clearTimeout(TM.current);

		TM.current = setTimeout(
			() => {
				setUpdate({
					LastUpdate: Date.now(),
					PreviewNeedRefresh: false,
				});
			},
			no_TM ? 0 : 1000
		);
	};

	const handleTableChange = (values, no_TM) => {
		if (values.type && values?.type.type !== type.type) {
			chart.chartvalues("");
			return false;
		}
		chart.chartvalues(JSON.stringify(values.Values));
		document
			.querySelectorAll("style[id*='chartId-']")
			.forEach((a) => a.remove());
		if (!values.forced || values.fromDrag) handleChange(no_TM);
	};

	const onOptionsChange = (values) => {
		if (values) handleTableChange({ Values: values }, true);
		else forcePreviewRefresh();
	};

	const changeType = (new_type) => {
		chart.chartTypeId(new_type.value);
		forcePreviewRefresh();
	};

	const getInitType = () => {
		let type = chart.SFChartType;

		if (!type) return false;
		return {
			label: type.name,
			value: type.id,
			icon: type.icon,
		};
	};

	const forcePreviewRefresh = () => {
		setUpdate({
			LastUpdate: Date.now(),
			PreviewNeedRefresh: true,
		});
	};

	const genEditCont = () => {
		let edit_props = {
			chart,
			// lastUpdate: LastUpdate,
			onChange: handleTableChange,
			onReorder: handleChange,
			header: type?.header,
			maxCols: type?.maxCols,
			maxRows: type?.maxRows,
			initValues: initValues.current,
		};
		let EditComponent = type.editComponent
			? type.editComponent
			: ChartEditTable;

		if (type.editComponent === false) return false;

		return (
			<div className="inner-step-cont chart-edit-component gap-2 d-flex flex-column">
				<div>
					<h5>{t("Charts.VALUES")}</h5>
					<div className="text-infos">{t("Charts.CTX_MENU_MSG")}</div>
				</div>
				<EditComponent
					{...edit_props}
					forceRefresh={Update.PreviewNeedRefresh}
				/>
			</div>
		);
	};

	return (
		<div className="d-flex flex-column gap-4">
			<div className="chart-edit-infos d-flex flex-column gap-2">
				<div className="props-cont d-flex gap-3 flex-wrap inner-step-cont">
					<ChartTypeSelect
						initialValue={getInitType()}
						onChange={changeType}
					/>
					<FormInput
						className="chart-title w-100"
						title={t("Charts.TITLE")}
						value={chart.title()}
						onChange={(val) => {
							chart.title(val);
							handleChange();
						}}
					/>
				</div>
				<div className="d-flex flex-wrap gap-4 justify-content-between align-items-start">
					<ChartOptions
						className="inner-step-cont mb-0"
						style={{ flex: "1 1 400px" }}
						chart={chart}
						type={type}
						onChange={onOptionsChange}
						lastUpdate={Update.LastUpdate}
					/>
					{chart.chartvalues() && (
						<div
							className="inner-step-cont d-flex flex-column justify-content-center align-items-center"
							style={{ flex: "1 1 500px" }}
						>
							<div className="w-100 align-items-end">
								<h5>{t("Charts.PREVIEW")}</h5>
							</div>
							<ChartTile
								key={chart.chartId()}
								// lastUpdate={Update.LastUpdate}
								loading={Update.PreviewNeedRefresh}
								chart={{
									...chart.chartvalues(),
									lastUpdate: Update.LastUpdate,
								}}
								typeId={chart.chartTypeId()}
								data={{
									...chart.values(),
									ChartId:
										chart.chartId() ||
										chart.options("TempId"),
									lastUpdate: Update.LastUpdate,
								}}
								theme={getTheme()}
								editing={true}
							/>
						</div>
					)}
				</div>
			</div>
			{genEditCont()}
		</div>
	);
}

export function checkChartValues(item) {
	let type = item.chartTypeId();
	let values = item.parsedChartValues();
	let error = t("Charts.NO_VALUE_ERROR");
	let check = true;
	let infos = infos_check(item);

	if (infos !== true) return infos;
	if (!values) return error;
	if (type === 4) check = target_chart_checker(values);
	else if (type === 7) check = text_chart_checker(values);
	else if (type === 19) check = gauge_chart_checker(values);
	else check = sf_chart_checker(values);
	if (check !== true && check?.length) return check;
	return check || error;
}

function infos_check(item) {
	if (!item.title()?.length) return t("Charts.NO_TITLE_ERROR");
	return true;
}

function gauge_chart_checker(values) {
	if (!values?.axes?.length) return false;
	let axe = values.axes[0];
	if (axe?.minimum === null || axe.maximum === null) return false;
	for (let x in axe.pointers) {
		let val = axe.pointers[x].value;
		if (typeof val === "undefined" || !val?.length)
			return t("Charts.NO_VALUE_ERROR");
	}
	return true;
}

function sf_chart_checker(values) {
	if (!values?.series?.length) return false;
	let series = values.series;
	for (let x in series) {
		let datas = series[x].dataSource;
		if (!datas?.length) return false;
		for (let y in datas)
			if (!datas[y].x || datas[y].y === null) return false;
	}
	return true;
}

function target_chart_checker(values) {
	if (!values?.length) return false;
	return true;
}

function text_chart_checker(values) {
	if (!values?.length || !values[0].value?.length)
		return t("Charts.NO_VALUE_ERROR");
	return true;
}

import SearchBar from "components/SearchBar/SearchBar";
import FiltersDrawer from "./FiltersDrawer/FiltersDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useFilterContext } from "providers/FilterProvider";
import "./FilterMenuMobile.css";
import Button from "components/Button/Button";
import { usePostContext } from "providers/PostProvider";
import { useEffect } from "react";

const FilterMenuMobile = (props) => {
    const { isFilterOpen, setIsFilterOpen, setSearching, searching, setSiteSelected, siteSelected, setCategoriesSelected,
        categoryIdsArray } = useFilterContext();
    const { postTypeId, propsPageHead } = usePostContext();
    let catsQueryFn = null;

    const propsFromPageHead = JSON.parse(propsPageHead);
    let catsType = "post-" + postTypeId;

    const anim = {
        hidden: {
            opacity: 0,
            height: "auto",
            padding: "0px",
            overflow: "hidden",
            transition: {
                ease: "easeOut",
                duration: 0.2,
            },
        },
        show: {
            opacity: 1,
            padding: "15px",
            opacity: 1,
            transition: {
                ease: "easeOut",
                duration: 0.2,
            },
        },
    }
    if (propsFromPageHead.catsType) {
        catsType = propsFromPageHead.catsType;
    }
    return (
        <div className="FilterMenu">
            <FontAwesomeIcon className="closeBtn" icon={faXmark} color="grey" onClick={() => setIsFilterOpen(!isFilterOpen)} />
            <SearchBar onChange={setSearching} timeout={500} initValue={searching} />
            <FiltersDrawer
                isMobile
                {...propsFromPageHead}
                catsType={catsType}
                siteSelect={setSiteSelected}
                curSite={siteSelected}
                selectCategory={setCategoriesSelected}
                dispCats={categoryIdsArray}
                anim={anim}
                isOpen={isFilterOpen}
                favSitesSelect={props.favSitesSelect}
                className="w-100 mt-2"
            />
            <div className="footerZone">
                <Button className="btn-primary" onClick={() => setIsFilterOpen(!isFilterOpen)}>Appliquer</Button>
            </div>
        </div>
    );
}

export default FilterMenuMobile;
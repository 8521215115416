import React from "react";
import ImagesSender from "Pages/Posts/components/ImagesSender/ImagesSender";

export default function PostImagesStep(props) {
	let cur_post = props.object;

	if (!props.isActive)
		return (false);

	const onEditorOpen = () => {
		props.setAbsolutePos();
		props.refreshPreview();
	};

	const onEditorClose = () => {
		props.resetAbsolutePos();
		props.refreshPreview();
	};

	return (
		<div className="inner-step-cont">
			<ImagesSender
				images={cur_post.images()}
				rssImages={cur_post.rssImages()}
				removeRssImage={() => {
					cur_post.removeRssImages();
					props.refreshPreview();
				}}
				setPostImages={(imgs) => {
					cur_post.images(imgs);
					props.refreshPreview();
				}}
				isRss={props.isRss}
				onEditorOpen={onEditorOpen}
				onEditorClose={onEditorClose}
			/>
		</div>
	);
}

export function checkPostImages() {
	// set default image
	// let images = post.images();

	// if (!images.length)
	// 	return ([
	// 		"La publication doit avoir une image"
	// 	])
	return (true);
}

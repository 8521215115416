import { t } from "i18next";
import type_9_icon from "../assets/9.png";
import type_10_icon from "../assets/10.png";
import type_16_icon from "../assets/16.png";
import type_12_icon from "../assets/12.png";
import type_13_icon from "../assets/13.png";
import type_14_icon from "../assets/14.png";
import type_15_icon from "../assets/15.png";
import type_17_icon from "../assets/17.png";
import type_18_icon from "../assets/18.png";
import type_4_icon from "../assets/4.png";
import type_19_icon from "../assets/19.png";
import type_7_icon from "../assets/7.png";
import bar_charts_getter from "./getters/bar_charts_getter";
import radius_charts_getter from "./getters/radius_charts_getter";
import gauge_chart_getter from "./getters/gauge_charts_getter";
import text_chart_getter from "./getters/text_chart_getter";
import custom_charts_getter from "./getters/custom_charts_getter";
import bar_charts_displayer from "./displayer/bar_charts_displayer";
import radius_charts_displayer from "./displayer/radius_charts_displayer";
import gauge_charts_displayer from "./displayer/gauge_charts_displayer";
import custom_charts_displayer from "./displayer/custom_charts_displayer";
import text_charts_displayer from "./displayer/text_charts_displayer";

/*
	Available options from ChartOptions.jsx :
		"AxesTitle"

		"Thickness"
		-> "InnerThickness"
		-> "OutterThickness"

		"Ranges"

		"Colors"
		-> "PrimaryColor"
		-> "SecondaryColor"

		"Values"
		-> "Value"
		-> "Label"
*/

const bar_charts_options = ["AxesTitle"];

const getChartTypes = () => [
	{
		id: 9,
		type: "Bar",
		group: "Barres",
		groupName: t("Charts.bars_groupName"),
		name: t("Charts.bars"),
		icon: type_9_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 10,
		type: "Column",
		groupName: t("Charts.bars_groupName"),
		group: "Barres",
		name: t("Charts.columns"),
		icon: type_10_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 16,
		type: "StackingColumn100",
		groupName: t("Charts.bars_groupName"),
		group: "Barres",
		name: t("Charts.columns_100"),
		icon: type_16_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 14,
		type: "Area",
		groupName: t("Charts.area"),
		group: "Aires",
		name: t("Charts.area"),
		icon: type_14_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 12,
		type: "SplineArea",
		groupName: t("Charts.area"),
		group: "Aires",
		name: t("Charts.rounded_area"),
		icon: type_12_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 13,
		type: "StackingArea",
		groupName: t("Charts.area"),
		group: "Aires",
		name: t("Charts.stacked_area"),
		icon: type_13_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 15,
		type: "StackingArea100",
		groupName: t("Charts.area"),
		group: "Aires",
		name: t("Charts.stacked_area_100"),
		icon: type_15_icon,
		valuesGetter: bar_charts_getter,
		valuesDisplayer: bar_charts_displayer,
		options: bar_charts_options,
	},
	{
		id: 17,
		type: "Bar",
		group: "Arrondis",
		groupName: t("Charts.rounded"),
		name: t("Charts.pie"),
		icon: type_17_icon,
		valuesGetter: radius_charts_getter,
		valuesDisplayer: radius_charts_displayer,
		maxCols: 1,
		header: false,
	},
	{
		id: 18,
		type: "Bar",
		group: "Arrondis",
		groupName: t("Charts.rounded"),
		name: t("Charts.round"), // Donut
		icon: type_18_icon,
		valuesGetter: radius_charts_getter,
		valuesDisplayer: radius_charts_displayer,
		maxCols: 1,
		header: false,
		options: ["InnerThickness"],
	},
	{
		id: 4, // TARGET CHART
		type: "Bar",
		group: "Arrondis",
		groupName: t("Charts.rounded"),
		name: t("Charts.round_gauge"),
		icon: type_4_icon,
		valuesGetter: custom_charts_getter,
		valuesDisplayer: custom_charts_displayer,
		header: false,
		maxCols: 1,
	},

	{
		id: 19,
		type: "Bar",
		group: "Jauges & texte",
		groupName: t("Charts.gauges_and_text"),
		name: t("Charts.gauge"),
		icon: type_19_icon,
		options: ["Ranges", "Values", "Thickness", "Colors"],
		editComponent: false,
		valuesGetter: gauge_chart_getter,
		valuesDisplayer: gauge_charts_displayer,
	},
	{
		id: 7,
		type: "Text",
		group: "Jauges & texte",
		groupName: t("Charts.gauges_and_text"),
		name: t("Charts.text"),
		icon: type_7_icon,
		options: ["Values", "PrimaryColor"],
		editComponent: false,
		valuesGetter: text_chart_getter,
		valuesDisplayer: text_charts_displayer,
	},
	//		{ id: 20, Type: "Bar", Name: "Quota", Icon: chart_icons_path + "12.png" },
];

export function getChartTypesGroups() {
	let types = getChartTypes();

	const groupedByGroup = types.reduce((acc, item) => {
		const groupName = item.groupName;

		// If the group doesn't exist in the accumulator, add it
		if (!acc[groupName]) {
			acc[groupName] = {
				name: groupName,
				items: [],
			};
		}

		// Add the current item to the group array
		acc[groupName].items.push(item);

		return acc;
	}, {});

	// Convert object to array
	const groupedArray = Object.values(groupedByGroup);

	return groupedArray;
}

export function getChartType(typeId) {
	let types = getChartTypes();
	let found = types.find((a) => a.id === typeId);
	return found ? found : false;
}

export default getChartTypes;

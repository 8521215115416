import React, { useState, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import ImageEditorComponent from "components/ImageEditor/ImageEditorComponent";
import generateUUID from "includes/UUID";
import genRequest from "includes/request";
import formatDate from "includes/formatDate";
import { t } from "i18next";
import documents_treat from "includes/documents_treat";
import "./UserImage.css";
import "assets/logos/logo.png";
import LoadCont from "components/LoadCont/LoadCont";
import noAccent from "includes/no_accents";

export default function EditableUserImage(props) {
	const [CurFile, setCurFile] = useState(false);
	const [CurImage, setCurImage] = useState(false);
	const qClient = useQueryClient();
	const UUID = generateUUID();
	const image_ref = useRef();

	const retrieveImage = async () => {
		if (!props.user?.EmployesId) return false;

		let prom = genRequest(
			"Documents/DocumentOfEmployeAndType/" + props.user.EmployesId + "/2"
		).catch(() => {});
		return prom;
	};

	const removeImage = (documentId) =>
		genRequest(
			"Documents/DocumentId=" + documentId + "AnddeleteExpenses=true",
			null,
			"delete"
		);

	function sendImage(file) {
		let date = formatDate(new Date());
		let baseName = "new_user_image" + generateUUID();
		let name =
			(props?.user
				? props.user.FirstName + "_" + props.user.LastName
				: baseName) +
			"_" +
			date +
			"_Profile_Image";
		name = noAccent(name).toLowerCase().replace(/\s/gi, "_");
		let b64 = file.split(",")[1];
		let data = {
			FileDocString: b64,
			//Url_Origin: b64,
			Name: name,
			DocTypeId: 2,
			CompanyId: props?.user ? props.user.CompanyId : null,
			SiteId: props?.user ? props.user.SiteId : null,
			EmployesId: props?.user ? props.user.EmployesId : null,
			PostId: null,
			IsTargetDoc: false,
			IsUploaded: true,
		};

		if (props.handleSend) {
			data.Url_Origin = file;
			setCurImage(data);
			setCurFile(file);
			return props.handleSend(data);
		}

		if (CurImage) removeImage(CurImage.DocumentId);

		let req = sendFile(data)
			.then((resp) => {
				data.DocumentId = resp;
				return linkImage(resp);
			})
			.then(() => {
				setCurImage(false);
				qClient.refetchQueries([
					"Documents",
					"DocumentOfEmployeAndType",
					props.employesId,
					2,
				]);
			});
		return req;
	}

	const sendFile = (data) =>
		genRequest("Documents/DocumentFileFromString", data, "post");

	function linkImage(documentId) {
		let data = {
			DocumentId: documentId,
			EmployeId: props.user.EmployesId,
		};

		let req = genRequest("LtDocumentEmployes", data, "post");
		return req;
	}

	function handleFile(ev) {
		ev.stopPropagation();
		ev.preventDefault();
		let files = ev.target.files;

		if (!files.length) return false;
		setCurFile(files[0]);
	}

	function handleSetCurFile(cur_file) {
		document.querySelector("#input-file-id-" + UUID).value = "";
		setCurFile(cur_file);
	}

	function handleDrag(state) {
		let cName = "drag-over";
		let img = image_ref?.current;

		if (state) img.classList.add(cName);
		else img.classList.remove(cName);
	}

	const { isLoading } = useQuery(
		[
			"Documents",
			"DocumentOfEmployeAndType",
			props.employesId,
			2,
			"EditableImage",
		],
		retrieveImage,
		{
			enabled:
				!CurImage?.IsUploaded && props?.user?.EmployesId ? true : false,
			retry: 0,
			onSuccess: (resp) => {
				if (resp.length) {
					let encodeds = documents_treat(resp, "LastChanged", true);
					setCurImage(encodeds[0]);
					return encodeds;
				}
				return resp;
			},
		}
	);

	if (isLoading)
		return (
			<div className="user-image me-2">
				<LoadCont />
			</div>
		);

	return (
		<div>
			<div
				ref={image_ref}
				onDragEnter={(e) => handleDrag(true, e)}
				onDragLeave={(e) => handleDrag(false, e)}
				onDrop={(e) => handleDrag(false, e)}
				className="user-image editable-user-image me-2"
				style={{
					backgroundImage:
						"url(" +
						(CurImage && CurImage.Url_Origin
							? CurImage.Url_Origin
							: "assets/logos/logo.png") +
						")",
					width: props.width,
				}}
			>
				<input
					id={"input-file-id-" + UUID}
					type="file"
					onChange={handleFile}
					title={t("Images.IMAGE_OVERLAY")}
				/>
				<div className="hover-cont">
					<FontAwesomeIcon icon={faPen} />
					<div>{t("Commons.MODIFY")}</div>
				</div>
				<div className="drag-cont">
					<FontAwesomeIcon icon={faArrowDown} />
					<div>{t("Commons.IMPORT")}</div>
				</div>
			</div>
			{CurFile && (
				<ImageEditorComponent
					ratio={1}
					file={CurFile}
					onSave={sendImage}
					onCancel={() => {
						handleSetCurFile(false);
					}}
					setCurFile={handleSetCurFile}
					className={CurFile ? "d-block" : "d-none"}
				/>
			)}
		</div>
	);
}

import React from "react";
import "./MenuToggler.css";

export default function MenuToggler(props) {

	const {isMenuOpen, setIsMenuOpen} = props;

	function handleChange(value) {
		if (setIsMenuOpen)
			setIsMenuOpen(value);
	}

	return (
		<div
			className={
				"MenuToggler toggle-nav-menu-btn" + (isMenuOpen ? " open" : "")
				+ (props.className ? " " + props.className : "")
			}
			onClick={() => handleChange(!isMenuOpen)}
		>
			<hr/>
		</div>
	);
}

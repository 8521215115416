import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import FunctionSwitch from "./FunctionSwitch";
import Button from "components/Button/Button";
import { useQueryClient } from "react-query";

export default function DevFunctions({ handleRefresh }) {
	const { isSup, getSettings, setSettings } = useAccount();
	const QCL = useQueryClient();

	if (isSup("ElodAdmin") < 0) return false;

	return (
		<div>
			<FunctionSwitch
				onChange={handleRefresh}
				label={"Afficher les fonctionalités en développement"}
				name={"DevFunctions"}
			/>
			<FunctionSwitch
				onChange={handleRefresh}
				label={"Changer de compagnie OnlyAll"}
				name={"CompanyChanger"}
			/>
			<FunctionSwitch
				onChange={handleRefresh}
				label={"Tutoriels"}
				name={"Tutorials"}
			/>
			{getSettings("Tutorials") && (
				<Button
					onClick={() => {
						setSettings("IntroPopperReaded", false);
						QCL.resetQueries(["Template"]);
					}}
					className="w-auto mt-1 mb-2"
				>
					Rejouer l'intro
				</Button>
			)}
			<FunctionSwitch
				onChange={handleRefresh}
				label={"Réactions"}
				name={"Reactions"}
			/>
			<FunctionSwitch
				onChange={handleRefresh}
				label={"Catégories mises en avant"}
				name={"FavCategories"}
			/>
		</div>
	);
}

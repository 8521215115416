import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faEdit } from "@fortawesome/free-regular-svg-icons";
import UserImage from "components/User/Image/UserImage";
import "./UserPreviewDrawer.css";
import { t } from "i18next";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import genRequest from "includes/request";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import SaveUserBtn from "components/User/SaveUserBtn/SaveUserBtn";
import Button from "components/Button/Button";
import { OnlineStatus } from "components/User/Card/UserCard";
import { DatePreview } from "components/User/AuthorPreview/AuthorPreview";
import PasswordReset from "./PasswordReset";

export default function UserPreviewDrawer(props) {

	let user = props.user;

	const handleModify = () => {
		if (props.handleModify)
			props.handleModify(user);
	};

	return (
		<div className="UserPreviewDrawer">
			<div className="user-preview d-flex mb-2">
				<div className="user-image-cont d-flex flex-column align-items-center">
					<UserImage
						className="me-2"
						companyId={user.CompanyId}
						employeId={user.EmployesId}
						title={user.FirstName + " " + user.LastName}
					/>
					<div className="d-flex flex-column gap-2 mb-2">
						<SaveUserBtn className={"radius-5"} text={t("Commons.FAVORITE")} userId={user.EmployesId} />
						{
							props.handleModify &&
							<Button className="radius-5 w-auto btn-primary" onClick={handleModify}>
								<div className="d-flex align-items-center">
									{t("Commons.MODIFY")}
									<FontAwesomeIcon className="ms-2" icon={faEdit} />
								</div>
							</Button>
						}
					</div>
				</div>
				<div className="user-infos">
					<div>
						<div className="d-flex align-items-center gap-2">
							<OnlineStatus lastConnexion={user?.LastConnexion} employeId={user.EmployesId} />
							<div className="d-flex flex-wrap user-fullname font-bold">
								<div>{user.FirstName}&nbsp;</div>
								<div>{user.LastName.toUpperCase()}</div>
							</div>
						</div>
						<QueryTagsList
							className="mt-1"
							queryKey={["Groups", "GroupsOfEmploye", user.EmployesId]}
							queryFn={() => genRequest("Groups/GroupOfEmploye/" + user.EmployesId)}
							enabled={(user && user.EmployesId) ? true : false}
						/>
						<InfosFetcher
							queryFn={() => genRequest("Sites/" + user.SiteId)}
							queryKey={["Sites", user.SiteId]}
							treat={(infos) => {
								return (infos.Name + " - " + infos.Town);
							}}
						/>
						<InfosFetcher
							queryFn={() => genRequest("Employes/MinimalContactEmployesFromId/" + user.EmployesId)}
							queryKey={["Employes", "MinimalContactEmployesFromId", user.EmployesId]}
							treat={(infos) => {
								return (
									<>
										<div className="user-work-function">{infos.WFU}</div>
										<div className="user-work-family">{infos.WFY}</div>
									</>
								);
							}}
						/>
						<div className="user-work-function">{user.WFU}</div>
						<div className="user-work-family">{user.WFY}</div>
					</div>
					<div className="user-contacts">
						{
							user.Display_Mail && user.Mail &&
							<div className="user-contact user-mail">
								<a href={"mailto:" + user.Mail}><FontAwesomeIcon className="user-contact-icon" icon={faEnvelope} />{user.Mail}</a>
							</div>
						}
						{
							user.Display_Phone && user.Telephone &&
							<div className="">
								<div className="user-contact user-whatsapp">
									<a href={"whatsapp:" + user.Telephone}><FontAwesomeIcon className="user-contact-icon" icon={faWhatsapp} />Whatsapp</a>
								</div>
								<div className="user-contact user-phone">
									<a href={"tel:" + user.Telephone}><FontAwesomeIcon className="user-contact-icon" icon={faPhoneAlt} />{user.Telephone}</a>
								</div>
							</div>
						}
						{
							!user.Display_Mail && !user.Display_Phone &&
							<div className="no-contact-info-msg">{t("Contacts.HIDDEN_INFOS")}</div>
						}
					</div>
					<div className="d-flex gap-1 user-work-family mt-2">
						{t("User.LAST_CONN")}
						:
						{
							user.LastConnexion ?
								<DatePreview
									date={user.LastConnexion}
									max={1}
								/>
								:
								" " + t("User.NO_CONN")
						}
					</div>
				</div>
			</div>
			<PasswordReset user={user} className="mt-3" />
		</div>
	);
}

import { t } from "i18next";
import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import formatDate from "includes/formatDate";
import SwitchInput from "components/Inputs/Switch/SwitchInput";

registerLocale("fr", fr);

function CustomDatePicker(props) {
	const [curDate, setCurDate] = useState(props.date || new Date());

	function handleChange(date) {
		setCurDate(date);
		if (props.handleChange)
			props.handleChange(date);
	}

	return (
		<DatePicker
			selected={curDate}
			onChange={handleChange}
			showTimeSelect={props.showTimeSelect !== false ? true : false}
			dateFormat={
				t(`Events.${(props.showTimeSelect !== false ? "DATE_TIME_INPUT_FORMAT" : "DATE_INPUT_FORMAT")}`)
			}
			locale={t("CodeMin")}
			timeFormat="p"
			timeIntervals={15}
			{...props}
			className={"DatePicker " + (props.className)}
		/>
	);
}

export function DatePickerToggler({onChange, label, nullable, startDate, className, isChecked}) {
	const now = new Date();
	const [isOpen, setIsOpen] = useState(isChecked || false);
	const [curDate, setCurDate] = useState((startDate ? startDate : now));

	function propagateChange(value, state)
	{
		if (onChange)
			onChange(value, state);
	}
	function handleChange(val, state) {
		if (val) {
			if (val === true)
			{
				setIsOpen(true);
				propagateChange(formatDate(curDate), true);
			} else {
				propagateChange(formatDate(val), true);
				setCurDate(val);
			}
		} else {
			setIsOpen(false);
			propagateChange((nullable ? null : formatDate()), false);
		}
		if (typeof state !== "undefined" && state !== isOpen)
			setIsOpen(state);
	}

	return (
		<div className={"inner-step-cont" + (className ? " " + className : "")}>
			<SwitchInput checked={isOpen} label={label} onChange={(e) => handleChange(e.target.checked)}/>
			{
				isOpen &&
					<CustomDatePicker
						className="mt-2"
						handleChange={handleChange}
						startDate={now}
						selected={curDate}
						minDate={now}
					/>
			}
		</div>
	);
}

export default CustomDatePicker;

import React, {useState} from "react";
import ScrollTable from "components/ScrollTable/ScrollTable";
import { t } from "i18next";
import EmployeSelector from "components/Users/UsersSelector/UsersSelector";

function GroupMembersStep(props) {
	const group = props.object;
	const [selectedsUsers, setSelectedsUsers] = useState(group.linksTo());
	const [isSelectOpen, setIsSelectOpen] = useState(false);

	function changeSelected(sels) {
		group.linksTo(sels);
		setSelectedsUsers(sels);
	}

	return (
		<div
			className="inner-step-cont d-flex flex-column h-100 overflow-hidden"
			style={{maxHeight: "80%"}}
		>
			<div className="d-flex justify-content-between align-items-center w-100 mb-2">
				<div className="d-flex align-items-baseline">
					<div className="user-count me-1">{selectedsUsers.length}</div><div>{t("Groups." + (selectedsUsers.length ? "USERS_IN_GROUP" : "USER_IN_GROUP"))}</div>
				</div>
				<div>
					{
						isSelectOpen ?
							<div className="btn btn-grey" onClick={() => setIsSelectOpen(false)}>{t("Commons.BACK")}</div>
							:
							<div className="btn btn-blue" onClick={() => setIsSelectOpen(true)}>{t("Commons.ADD")}</div>
					}
				</div>
			</div>
			<div className="d-flex h-100 flex-column">
				{
					isSelectOpen ?
						<EmployeSelector setSelectedsUsers={changeSelected} selectedsUsers={selectedsUsers}/>
						:
						<div className="h-100 overflow-hidden d-flex">
							<ScrollTable
								itemId="EmployesId"
								items={selectedsUsers}
								setSelected={changeSelected}
								selected={selectedsUsers}
								className="light-bg h-100 d-flex flex-column overflow-hidden"
								mouseover={false}
								onItemClick={() => {}}
								cols={[
									{
										name: "LastName",
										title: t("User.LAST_NAME")
									},
									{
										name: "FirstName",
										title: t("User.FIRST_NAME")
									}
								]}
								filter={(a) => {
									a.isSelected = true;
									return (a);
								}}
								sortBy="LastName"
								revSort={false}
								checkbox={true}
							/>
						</div>
				}
			</div>
		</div>
	);
}

export default GroupMembersStep;

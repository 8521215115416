import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";
import FormSectionModel from "./FormSectionModel";

class FormObject {
	#FormId = null;
	#EmployesId = null;
	#Title = null;
	#Txt = null;
	#PublishedDate = null;
	#SavePublishedDate = null;
	#AvailableToDate = null;
	#IsDraft = true;
	#IsAnonymous = true;
	#IsAvailable = true;
	#AnswerVisibleForUser = true;
	#LastChanged = null;
	#LastChange_AuthorId = null;

	#Sections = [];
	#Questions = [];
	#LinkTo = [];
	#CompanyId = false;
	#HasAnswers = false;

	constructor(obj) {
		let basic_form = BasicForm();
		let date = formatDate();

		this.#FormId = obj?.FormId ? obj.FormId : basic_form.FormId;
		this.#EmployesId = obj?.EmployesId
			? obj.EmployesId
			: basic_form.EmployesId;
		this.#Title = obj?.Title ? obj.Title : basic_form.Title;
		this.#Txt = obj?.Txt ? obj.Txt : basic_form.Txt;
		this.#SavePublishedDate = date;
		this.#PublishedDate = obj?.PublishedDate ? obj.PublishedDate : date;
		this.#AvailableToDate = obj?.AvailableToDate
			? obj.AvailableToDate
			: null;
		this.#IsDraft =
			typeof obj?.IsDraft !== "undefined" ? obj?.IsDraft : true;
		this.#IsAnonymous =
			typeof obj?.IsAnonymous !== "undefined" ? obj?.IsAnonymous : true;
		this.#IsAvailable = obj?.IsAvailable ? obj?.IsAvailable : true;
		this.#AnswerVisibleForUser = obj?.AnswerVisibleForUser
			? obj?.AnswerVisibleForUser
			: true;
		this.#LastChanged = obj?.LastChanged
			? obj.LastChanged
			: basic_form.LastChanged;
		this.#LastChange_AuthorId = obj?.LastChange_AuthorId
			? obj.LastChange_AuthorId
			: basic_form.LastChange_AuthorId;
		this.#CompanyId = obj?.CompanyId ? obj.CompanyId : basic_form.CompanyId;
		this.#HasAnswers = obj?.HasAnswers ? obj.HasAnswers : false;

		if (obj?.Sections)
			this.#Sections = obj.Sections.map((a) => {
				return new FormSectionModel(a, this);
			});
		else
			this.#Sections = basic_form.Sections.map((a) => {
				return new FormSectionModel(a, this);
			});
		this.#LinkTo = [];
	}

	fetchForEdit() {
		let proms = [this.fetchAssignations()];
		return Promise.all(proms);
	}

	fetchAssignations() {
		let req = genRequest(
			`Lt_FormAffectation/AffectationsOfForm/${this.#FormId}`
		).then((resp) => {
			this.#LinkTo = resp?.length ? resp : [];
			return this.#LinkTo;
		});
		return req;
	}

	send(account) {
		let that = this;
		const isAnUpdate = this.#FormId ? true : false;

		let prom = new Promise((resolve, reject) => {
			this.sendForm()
				.then(() => {
					return that.linkForm();
				}, reject)
				.then(() => {
					that.sendSections(true);
				}, reject)
				.then(() => {
					that.sendNotifications(account, isAnUpdate);
				}, reject)
				.then(() => {
					resolve(true);
				}, reject);
		});
		return prom;
	}

	sendNotifications(account, isAnUpdate) {
		if (!isAnUpdate) {
			if (this.#SavePublishedDate === this.#PublishedDate) {
				let req = genRequest(`PushNotification/FormsNotify`, {
					Title: this.#Title,
					FormId: this.#FormId,
					Author: account,
				});
				return req;
			} else {
				let req = genRequest(`PushNotification/FormsNotifyDelay`, {
					Message: this.#Title,
					targetForm: {
						FormId: this.#FormId,
						LastChange_AuthorId: this.#LastChange_AuthorId,
						...this.values(),
					},
					Author: account,
					TimeToNotify: this.#PublishedDate,
				});
				return req;
			}
		}
		return true;
	}

	sendForm() {
		let that = this;
		let values = this.values();
		if (!values.FormId) delete values.FormId;
		let req = genRequest(
			"Forms" + (this.#FormId ? "/" + this.#FormId : ""),
			values,
			this.#FormId ? "put" : "post"
		).then((resp) => {
			if (!that.#FormId) that.id(resp.FormId);
			return resp;
		});
		return req;
	}

	linkForm() {
		let links = this.#LinkTo;
		if (!links?.length)
			links = [
				{
					EmployeId: this.#EmployesId,
					CompanyId: this.#CompanyId,
				},
			];
		let affects = links.map((a) => ({
			...a,
			FormId: this.#FormId,
		}));
		let req = genRequest(
			"Lt_FormAffectation/MultiplePOST",
			affects,
			"post"
		);
		return req;
	}

	affectations(affects) {
		if (typeof affects !== "undefined") this.#LinkTo = affects;
		return this.#LinkTo;
	}

	sendSections() {
		let proms = [];

		for (let sec of this.#Sections) proms.push(sec.send());
		return Promise.all(proms);
	}

	sections(sec_id, with_index) {
		if (!sec_id) return this.#Sections;
		for (let [key, sec] of this.#Sections) {
			if (sec.id === sec_id) {
				if (with_index)
					return {
						index: parseInt(key),
						question: sec,
					};
				else return sec;
			}
		}
	}

	curSection(new_sec, from_quest) {
		if (new_sec) {
			if (new_sec !== this.cur_section) {
				this.cur_section = new_sec;
				if (!from_quest) this.curQuestion(false, true);
			}
		} else if (new_sec === false) {
			this.cur_section = false;
			this.cur_question = false;
		}
		return this.cur_section;
	}

	addSection(index) {
		let default_obj = {
			Position: this.#Sections.length,
			TempId: generateUUID(),
			Title: "",
			Txt: "",
			FormId: this.#FormId,
		};
		if (index) default_obj.Position = index;
		let sec_obj = new FormSectionModel(default_obj, this);
		if (!index) this.#Sections.push(sec_obj);
		else this.#Sections.splice(index, 0, sec_obj);
		this.reorderSections();
		return sec_obj;
	}

	removeSection(secId) {
		for (let x in this.#Sections)
			if (this.#Sections[x].id === secId) {
				let deleted = this.#Sections.splice(x, 1);
				if (this.#Sections.length) this.curSection(this.#Sections[0]);
				else this.curSection(false);
				this.reorderSections();
				return deleted;
			}
		return false;
	}

	reorderSections() {
		let pos = 0;
		for (let x in this.#Sections) this.#Sections[x].Position = pos++;
	}

	questions(quest_id, with_index) {
		if (!quest_id)
			return this.#Sections.map((a) => {
				return a.questions();
			});
		for (let [key, quest] of this.#Questions) {
			if (quest.id === quest_id) {
				if (with_index)
					return {
						index: parseInt(key),
						question: quest,
					};
				else return quest;
			}
		}
	}

	curQuestion(new_quest, from_sec) {
		if (
			(new_quest && new_quest !== this.cur_question) ||
			new_quest === false
		) {
			this.cur_question = new_quest;
			if (!from_sec && new_quest) this.curSection(new_quest.parent, true);
		}
		return this.cur_question;
	}

	checkValues() {
		if (!this.#Sections.length)
			return {
				section: null,
				error: "FORM_NO_SECTION_ERROR",
			};
		this.#Title = this.#Sections[0].Title;
		this.#Txt = this.#Sections[0].Txt;
		if (!this.#Title?.length)
			return {
				section: null,
				selector:
					"[data-form-section-id='" +
					this.#Sections[0].id +
					"'] .section-title",
				error: "FORM_EMPTY_TITLE",
			};
		for (let sec of this.#Sections) {
			let check = sec.checkValues();
			if (check !== true)
				return {
					section: sec,
					...check,
				};
		}
		return true;
	}

	title = () => this.#Title;

	id(value, noTemp) {
		if (value) {
			this.#FormId = value;
			for (let sec of this.#Sections) sec.formId(value);
		}
		return this.#FormId
			? this.#FormId
			: noTemp !== true
			? this.TempId
			: false;
	}

	lastChanged = () => this.#LastChanged;

	isDraft(set_value) {
		if (typeof set_value !== "undefined") this.#IsDraft = set_value;
		return this.#IsDraft;
	}

	isAnonymous(set_value) {
		if (typeof set_value !== "undefined") this.#IsAnonymous = set_value;
		return this.#IsAnonymous;
	}

	isAvailable(set_value) {
		if (typeof set_value !== "undefined") this.#IsAvailable = set_value;
		return this.#IsAvailable;
	}

	publishedDate(set_value) {
		if (typeof set_value !== "undefined") this.#PublishedDate = set_value;
		return this.#PublishedDate;
	}

	availableToDate(set_value) {
		if (typeof set_value !== "undefined") this.#AvailableToDate = set_value;
		return this.#AvailableToDate;
	}

	hasAnswers = () => this.#HasAnswers;

	values = () => {
		return {
			FormId: this.#FormId,
			EmployesId: this.#EmployesId,
			Title: this.#Title,
			Txt: this.#Txt,
			PublishedDate: this.#PublishedDate,
			AvailableToDate: this.#AvailableToDate,
			IsDraft: this.#IsDraft,
			IsAnonymous: this.#IsAnonymous,
			IsAvailable: this.#IsAvailable,
			LastChanged: this.#LastChanged,
			AnswerVisibleForUser: this.#AnswerVisibleForUser,
			LastChange_AuthorId: this.#LastChange_AuthorId,
		};
	};
}

export function BasicForm() {
	let date = formatDate();

	return {
		LastChanged: date,
		AvailableToDate: date,
		PublishedDate: date,
		TempId: generateUUID(),
		Title: "",
		Txt: "",
		Sections: [
			{
				Position: 0,
				TempId: generateUUID(),
				Title: "",
				Txt: "",
				Questions: [
					{
						TempId: generateUUID(),
						Title: "",
						Txt: "",
						QuestionDesignId: 1,
					},
				],
			},
		],
	};
}

export default FormObject;

import React, { useState } from "react";

export default function FormInput(props) {
	const [Value, setValue] = useState(props.value ? props.value : "");
	const getValue = () => (typeof props.value !== "undefined" && props.externvalue ? props.value : Value);

	function handleChange(e) {
		let prop = e.target.attributes.prop?.value;
		let value = e.target.value;
		if (!props.onChange)
			return (false);
		if (prop)
			props.onChange(prop, value);
		else
			props.onChange(value);
		if (!props.externvalue)
			setValue(value);
	}

	function auto_grow(e) {
		let elem = e.target;

		if (props.autogrow !== false)
			elem.style.height = elem.scrollHeight + "px";
	}

	let value = getValue();

	let child_props = {
		...props,
		className: "w-100" + (props.childClassName ? " " + props.childClassName : ""),
		title: (props.titlevalue === "true" ? value : props.title),
		placeholder: props.placeholder ? props.placeholder : props.title,
		value,
		onChange: handleChange
	};

	let elem = <input {...child_props}/>;
	if (props.textarea && (props.textarea === "true" || props.textarea === true))
		elem = <textarea {...child_props} onChangeCapture={auto_grow}/>;
	return (
		<div className={"form-input" + (props.className ? " " + props.className : "")}>
			{props.title && <div className="mb-1">{props.title}</div>}
			<div>{elem}</div>
		</div>
	);
}

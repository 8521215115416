import React, {/*useState*/ } from "react";
import { useQuery } from "react-query";

import PostTile from "../../Pages/Posts/components/Tile/PostTile";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import useUnread from "classes/Accounts/hooks/useUnread";
import { useMediaQuery } from "react-responsive";

export default function Post(props) {
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

	const { account } = useAccount();
	const { isReaded, setAsReaded } = useUnread();
	// const [hasResponse, setHasResponse] = useState(false);
	let PostId = props.datas?.PostId;
	let EmployesId = props.datas?.EmployesId;
	let PostTypeId = props.datas?.PostTypeId;
	let isPostReaded = isReaded("NotViewedPost_" + PostTypeId, PostId);

	// const retrieveResponse = async () => {

	// 	let req = await genRequest("LtNewsResponse/PostId/" + PostId)
	// 	.then((resp) => {
	// 		setHasResponse(resp);
	// 		return (resp);
	// 	}).catch((e) => {
	// 		setHasResponse(false);
	// 	})
	// 	return (req);
	// }

	const retrieveCategories = async () => await genRequest("Categories/Post/" + PostId);

	const incrementViews = () => {
		if (EmployesId && account.EmployesId === EmployesId && isPostReaded)
			return (Promise.resolve(true));
		let req = genRequest(
			"Posts/IncrementPostViewCount/" + PostId,
			null,
			"put"
		).then((resp) => {
			return (resp);
		}).catch(() => { });
		return (req);
	};

	function handleClick() {
		if (!props.isActive || props.isFirstDisplayed) {
			if (!isPostReaded)
				incrementViews().then(() => {
					setAsReaded(
						"NotViewedPost_" + PostTypeId,
						PostId
					);
				});
		}
		if (props.onClick)
			props.onClick(this);
	}

	let post = props.datas;

	const { isLoading, data } = useQuery(
		["Categories", "Post", PostId],
		retrieveCategories
	);

	if (props.dispCats?.length) {
		let cats = data;
		if (!cats?.length)
			return (false);
		let check = false;
		for (let x in cats)
			if (props.dispCats.indexOf(cats[x].CategoryId) !== -1)
				check = true;
		if (!check)
			return (false);
	}

	if (props.childComponent)
		return (
			<props.childComponent
				companyId={account?.CompanyId}
				direction={props.direction}
				isFirstDisplayed={props.isFirstDisplayed}
				isActive={props.isActive}
				datas={post}
				dispImages={props.dispImages}
				// hasResponse={hasResponse}
				onClick={handleClick}
				isLoading={isLoading}
			/>
		);
	return (
		<PostTile
			isMobile={isMobile}
			companyId={account?.CompanyId}
			direction={props.direction}
			isFirstDisplayed={props.isFirstDisplayed}
			isActive={props.isActive}
			isReaded={isPostReaded}
			datas={post}
			dispImages={props.dispImages}
			// hasResponse={hasResponse}
			onClick={handleClick}
			isLoading={isLoading}
		/>
	);
}

const AddPostTour = {
	tourId: "posts",
	title: "Ajouter une publication",
	description: "",
	page: "/news",
	steps: [
		{
			target: ".PageHeadBar .headbar-add-btn",
			title: "Ajouter une publication",
			content: "Cliquez ici pour ajouter une publication",
			disableBeacon: true,
			spotlightClicks: true
		},
		{
			target: ".edit-drawer .steps-links",
			title: "Étapes de publication",
			content: "Cliquez sur l'une des étapes pour y accéder",
			disableBeacon: true
		},
	]
};

export default AddPostTour;

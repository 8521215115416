import { useState } from "react";
import Storage from "classes/Storage/Storage";

function useToken() {
	const storage = new Storage(null, null, sessionStorage);

	const getToken = () => {
		const userToken = storage.getItem("token");
		return (userToken?.access_token);
	};

	const [token, setToken] = useState(getToken());

	const saveToken = userToken => {
		storage.setItem("token", userToken);
		setToken(userToken.token);
	};

	return ({
		setToken: saveToken,
		token
	});
}

export default useToken;

import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./TableToolBar.css";
import Button from "components/Button/Button";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import BackButton from "components/Button/BackButton";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";
import { Tooltip } from "@material-ui/core";
import ReactModal from "react-modal";
import { useUsersContext } from "Pages/Contacts/providers/UsersProvider";

export default function CustomTableToolbar(props) {
	const [activeMenu, setActiveMenu] = useState(false);
	const { totalUsers, usersToExport, setUsersToExport } = useUsersContext();

	const cont_ref = useRef(null);

	useEffect(() => {
		if (props?.selectedRows?.length && usersToExport < totalUsers) {
			setUsersToExport(props.selectedRows.length);
		}
	}, [props.selectedRows]);

	if (!props?.selectedRows?.length)
		return (false);

	return (
		<div className="TableToolBar ms-1" ref={cont_ref}>
			<div className="d-flex gap-2">
				<div className="container_btn">
					<Button className="btn-primary gap-2 ms-1 less-width" onClick={() => props.clearSelection([])}>
						<FontAwesomeIcon icon={faTimes} />
						{t("Commons.ITEMS", { count: usersToExport > 0 ? usersToExport : props?.selectedRows?.length })}
					</Button>
					<Button className="btn-green gap-2 ms-1 more-width" onClick={() => { setUsersToExport(totalUsers) }}>
						{t("Commons.SELECT_ALL")}
					</Button>
				</div>

				<Sep />
				<ToolBarButons
					items={props.buttons}
					active={activeMenu}
					setActive={setActiveMenu}
					selected={props.selectedRows}
				/>
			</div>
			{
				props.handleDelete &&
				<div>
					<Button className="btn-red gap-2" onClick={props.handleDelete}>
						<div>
							{t("Commons.DELETE")}
						</div>
						<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				</div>
			}
		</div>
	);
}

const Sep = (props) => (<div className={"sep" + (props.className ? " " + props.className : "")}></div>);

function ToolBarButons(props) {
	const { active, setActive, items } = props;

	const focus = (item) => {
		if (active.title !== item.title)
			setActive(item);
		else
			setActive(false);
	};

	if (!items)
		return (false);

	return (
		<div className="d-flex gap-2">
			{
				items.map((a, key) => {
					if (a.enabled === false || a.title === "delete")
						return (false);
					return (
						<ToolBarButon
							key={key}
							item={a}
							isFocused={active ? true : false}
							setIsFocused={focus}
							display={active?.title === a.title}
							selected={props.selected}
							setSelected={props.setSelected}
						/>
					);
				})
			}
		</div>
	);
}

function ToolBarButon(props) {
	const { item, setIsFocused, selected } = props;
	const { display } = props;
	const [TM, setTM] = useState(false);

	const onClick = (e) => {
		if (item.onClick) {
			item.onClick(selected, e);
		} else {
			showPopper(e);
		}
	}

	const showPopper = (e) => {
		e.stopPropagation();
		resetTm();
		setIsFocused(item);
	};

	const resetTm = (new_tm) => {
		clearTimeout(TM);
		setTM((new_tm ? new_tm : false));
	};

	const handleClose = (resp) => {
		setIsFocused(false);
		if (item.onClose)
			item.onClose(resp);
	}

	return (
		<div className="ToolbarButton">
			<Tooltip title={item.tooltip || item.title}>
				<div>
					<Button className="gap-1" onClick={onClick}>
						{item.icon && <FontAwesomeIcon icon={item.icon} />}
						<div className="btn-title">{item.value}</div>
					</Button>
				</div>
			</Tooltip>
			{
				display &&
				<ButtonPopper
					{...item}
					item={item.popper}
					selected={props.selected}
					setSelected={props.setSelected}
					handleClose={handleClose}
					setIsFocused={setIsFocused}
				/>
			}
		</div>
	);
}

function ButtonPopper(props) {
	const [isLoading, setIsLoading] = useState(false);

	const ref = React.useRef();

	useOutsideAlerter(ref, () => {
		if (props.closeOnclickOutside !== false)
			props.setIsFocused(false)
	});

	if (!props.item)
		return (false);

	return (
		<div>
			<ReactModal
				isOpen={true}
			>
				<div ref={ref} className="toolbar-popper-cont">
					<div className="d-flex gap-2 align-items-center mb-3">
						<BackButton onClick={props.handleClose} />
						<h4>{props.popperTitle || props.title}</h4>
					</div>
					<div className="popper-inner-cont">
						<props.item
							handleClose={props.handleClose}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
							{...props}
						/>
					</div>
					{
						isLoading &&
						<div className="popper-load">
							<LoadCont
								text={props.isLoading?.length ? props.isLoading : false}
							/>
						</div>
					}
				</div>
			</ReactModal>
		</div>
	);
}

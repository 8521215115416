import React from "react";
import "./SwitchInput.css";

/**
* @component
* @description Display a switch with a label if provided
* @example
*
* return (
*	<SwitchInput
*	 onChange={<function>}
*	 checked={<boolean>}
*	 label={"Sample string"} // optional
*	 reverse={<boolean>} // optional - Show the label before the switch
*	/>
* )
*/

function SwitchInput(props) {

	function handleChange(e) {
		e.stopPropagation();
		if (props.onChange && !props.disabled)
			props.onChange(props.onlyState ? e.target.checked : e);
	}

	return (
		<div className="switchElement" onClick={props.onClick}>
			<label
				className={
					"SwitchInput d-flex align-items-center"
					+ (props.reverse ? " flex-row-reverse" : "")
					+ (props.className ? " " + props.className : "")
					+ (props.disabled ? " disabled" : "")
				}
			>
				<input
					type="checkbox"
					className={"switch " + (props.reverse ? "ms-2" : "me-2")}
					checked={props.checked}
					onChange={handleChange}
					disabled={props.disabled}
				/>
				<div>
					{props.label}
				</div>
			</label>
		</div>
	);
}

export default SwitchInput;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./Inputs.css";
import { t } from "i18next";
import Button from "components/Button/Button";

/**
* @component
* @description Display a password field with a "show/hide" button
* @example
*
* return (
*	<PasswordInput
*	 onChange={<function>}
*	 placeholder={"Sample string"} // optional
*	 formId={<int|UUID>} // optional - use to link multiple password fields togethers
*	/>
* )
*/
function PasswordInput(props) {
	const [visible, setVisible] = useState(false);
	let timeout = false;
	let timeout_duration = 5000;

	function showPass() {
		if (timeout)
			clearTimeout(timeout);
		if (visible)
			return (setVisible(false));
		setVisible(true);
		timeout = setTimeout(() => {
			setVisible(false);
		}, timeout_duration);
	}

	function hidePass() {
		if (timeout)
			clearTimeout(timeout);
		setVisible(false);
	}

	return (
		<div className={props.className}>
			{props.title && <div className="mb-1">{props.title}</div>}
			<div className="d-flex align-items-center password-input-cont">
				<input
					{...props}
					className={"w-100 " + (props.fieldClassName ? props.fieldClassName : "")}
					placeholder={props.placeholder ? props.placeholder : props.title}
					type={visible ? "text" : "password"}
					onChange={(e) => props.onChange(e.target.value)}
					onMouseLeave={hidePass}
				/>
				<Button className="unstyled w-auto p-1" type="button" onClick={showPass} title={t("Password.DISPLAY")}>
					<FontAwesomeIcon icon={faEye} className="cursor-pointer"/>
				</Button>
			</div>
		</div>
	);
}

export default PasswordInput;

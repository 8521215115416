import { getSpecs } from "./specs_getter";

export default function bar_charts_getter(chart, table) {
	let rows = table.querySelectorAll("tbody tr");
	let cols = table.querySelectorAll("thead tr > *:not(:first-child)");
	let chartTypeId = chart.chartTypeId();
	let SFType = chart.SFChartType.type;
	let colors = [];
	let values = {
		series: [],
		background: "transparent",
		palettes: colors
	};

	if (!rows?.length || !cols.length)
		return (false);

	rows.forEach((row) => {
		let value = {
			name: row.querySelector(".row-title input").value,
			dataSource: [],
			xName: "x",
			yName: "y",
			width: 1,
			marker: {
				dataLabel: {
					visible: true,
					position: "Top",
					font: {
						fontWeight: "600",
						color: "#ffffff",
						fontFamily: "Poppins-Regular"
					}
				}
			},
			legendShape: "Circle"
		};

		if (!(chartTypeId >= 17 && chartTypeId <= 18))
			value.type = SFType;

		cols.forEach((col, c_index) => {
			let col_name = col.querySelector("input").value;
			if (!col_name)
				col_name = "Colonne " + (c_index + 1);

			let val = row.querySelector(`td:nth-child(${c_index + 2}) input`).value;
			if (val?.length)
				value.dataSource.push({
					x: col_name,
					y: Number(val)
				});
		});

		if (["Area", "SplineArea"].indexOf(SFType) !== -1)
			value.opacity = 0.5;

		if (value.dataSource?.length)
		{
			values.series.push(value);
			let color = row.querySelector(".color-picker input").value;
			colors.push(color);
		}
	});
	values = {...values, ...getSpecs(SFType, chart), palettes: colors};
	return (values);
}

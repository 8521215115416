import React from "react";
import { useQuery } from "react-query";
import genRequest from "includes/request";
import documents_treat from "includes/documents_treat";
import "./PostDocumentsDrawer.css";
import PostDocumentsDrawer from "./PostDocumentsDrawer";
import { t } from "i18next";

export default function AsyncPostDocumentsDrawer(props) {

	const { postId } = props;

	const fetchDocs = () => {
		let req = genRequest("Documents/DocumentOfPostAndType/" + postId + "/4")
			.then((resp) => documents_treat(resp, "PublishedDate"));
		return (req);
	};

	const { isLoading, data } = useQuery(
		["Documents", "DocumentOfPostAndType", postId, 4],
		fetchDocs,
		{
			enabled: (postId ? true : false),
		}
	);

	if (isLoading)
		return (
			<div>
				{t('Docs.LOADING')}
			</div>
		);
	if (!data)
		return (false);

	return (
		<PostDocumentsDrawer
			{...props}
			documents={data}
			async={false}
		/>
	);
}

import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { useQueries } from "react-query";
import StepWizard from "react-step-wizard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./EditDrawer.css";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import LoadCont from "components/LoadCont/LoadCont";
import StepsNav from "./StepsNav/StepsNav";
import StepsLinks from "./StepsLinks/StepsLinks";
import EditPreview from "./EditPreview/EditPreview";
import useEditing from "./useEditing";
import { RouterPrompt } from "components/RouterPrompt/RouterPrompt";
import { useMediaQuery } from "react-responsive";

export default function EditDrawer(props) {
	const isInitied = useRef(false);
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

	let proms = [];

	if (props.initQueries?.length) {
		proms = props.initQueries.map((a) => {
			let dup = { ...a };
			dup.enabled = a.enabled !== false && !isInitied?.current;
			dup.retryOnMount = false;
			dup.refetchOnMount = false;
			dup.refetchOnWindowFocus = false;
			dup.refetchOnReconnect = false;
			return dup;
		});
	}

	const queries = useQueries(proms);
	const allDone = queries.every((query) => query.isLoading === false);

	// if (!allDone)
	// 	return (false)
	if (!isInitied?.current && allDone) isInitied.current = true;

	const anims = {
		hidden: {
			top: "100vh",
			opacity: 1,
		},
		show: {
			top: "0vh",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.2,
			},
		},
	};

	return (
		<>
			<motion.div
				variants={anims}
				initial="hidden"
				animate="show"
				className={`edit-drawer ${props.isOpen ? " open" : ""} ${props.className
					}` + (isMobile ? " mobile" : "")}
			>
				{allDone ? (
					<>
						<div className="drawer-title">{props.title}</div>
						{allDone && props.isOpen && props.steps && (
							<StepsInnerCont
								{...props}
								onClose={props.onClose}
								onDraft={props.onDraft}
								steps={props.steps}
								object={props.object}
								initialStep={props.initialStep}
								editPreview={props.editPreview}
								autoSave={props.autoSave}
							/>
						)}
						{!allDone && <div>{t("Commons.LOADING")}</div>}
					</>
				) : (
					<LoadCont text={t("Commons.LOADING")} />
				)}
			</motion.div>
		</>
	);
}

function StepsInnerCont(props) {
	const { account, isSup } = useAccount();
	const [instance, setInstance] = useState();
	const [curStep, setCurStep] = useState({
		previousStep: 1,
		activeStep: props.initialStep ? props.initialStep : 1,
	});
	const [failedStep, setFailedStep] = useState(false);
	const [errors, setErrors] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [steps] = useState(
		props.steps.filter((a) => (a.disabled ? false : a))
	);
	const [lastAutoSave, setLastAutoSave] = useState(Date.now());
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const { getEditing, setEditing } = useEditing();
	const previewGenTM = useRef();
	const [lastPreviewGen, setLastPreviewGen] = useState(Date.now());

	function onStepChange(step) {
		setFailedStep(false);
		setCurStep(step);
		absolutePosStep(false);
		if (props.autoSave?.callback) {
			props.autoSave.callback();
			setLastAutoSave(Date.now());
		}
		return false;
	}

	function checkStep(checker, index) {
		if (checker) {
			let check_res = checker(props.object, account, isSup);
			if (check_res !== true) {
				setFailedStep(index);
				setErrors(check_res);
				return false;
			}
		}
		return true;
	}

	function goToStep(stepId) {
		for (let x = 0; x < steps.length; x++) {
			if (x > stepId) {
				instance.goToStep(x);
				return false;
			}
			let check = checkStep(steps[x].onNext, x);
			if (!check) {
				instance.goToStep(x + 1);
				return false;
			}
		}
	}

	function goToSendStep() {
		if (steps) {
			let step = steps
				.map((a, index) => {
					if (a.sendStep) return { ...a, index };
					return false;
				})
				.filter((a) => a)[0];

			if (step) goToStep(step.index);
			else console.error("No Send Step found");
		}
	}

	// To do more test to see if this function is usefull
	function handleDraft() {
		let step_check = false;
		if (props.onDraft) {
			let check = props.onDraft(props);
			if (check) step_check = goToSendStep();
		} else {
			step_check = goToSendStep();
		}
		if (step_check) setEditing(false);
	}

	function handleClose() {
		setEditing(false);
		props.onClose(isDone);
		return Promise.resolve(true);
	}

	function absolutePosStep(step) {
		let node = instance?._reactInternals?.child?.stateNode;
		if (!node?.lastChild) return false;
		if (step) node.lastChild.classList.add("fullscreen");
		else node.lastChild.classList.remove("fullscreen");
	}

	const openPreview = () => {
		// if (props.autoSave)
		// 	props.autoSave.callback().then((resp) => {
		// 		setIsPreviewOpen(true);
		// 	})
		// else
		setIsPreviewOpen(true);
	};

	function refreshPreview() {
		clearTimeout(previewGenTM.current);
		previewGenTM.current = setTimeout(() => {
			setLastPreviewGen(Date.now());
		}, 1000);
	}

	// To do more test to see RouterPrompt is usefull
	return (
		<div className={"edit-drawer-inner-cont" + (isDone ? " is-done" : "")}>
			<RouterPrompt
				when={getEditing() && !isDone}
				onOK={handleClose}
				onDraft={props.onDraft ? handleDraft : false}
				onCancel={() => { }}
				lastGen={Date.now()}
			/>
			{instance && (
				<StepsLinks
					instance={instance}
					checkStep={checkStep}
					curStep={curStep}
					steps={steps}
					failedStep={failedStep}
					isDone={isDone}
				/>
			)}
			<StepWizard
				className="wizard-cont d-flex flex-column h-100"
				onStepChange={onStepChange}
				initialStep={props.initialStep}
				nav={
					<StepsNav
						{...props}
						steps={steps}
						onClose={props.onClose}
						onDraft={props.onDraft}
						isDone={isDone}
						object={props.object}
						preview={props.editPreview}
						isPreviewOpen={isPreviewOpen}
						setIsPreviewOpen={setIsPreviewOpen}
						openPreview={openPreview}
						checkStep={checkStep}
					/>
				}
				instance={setInstance}
			>
				{steps.map((step, index) => {
					return (
						<div
							className={"step-cont " + step.name + "-step"}
							key={step.name}
						>
							{errors && failedStep === index && (
								<ErrorsCont
									className="mx-1 mb-2"
									errors={errors}
								/>
							)}
							<step.component
								instance={instance}
								isDone={isDone}
								setIsDone={setIsDone}
								setFailedStep={(value) =>
									setFailedStep(
										typeof value !== "undefined"
											? value
											: index
									)
								}
								isActive={instance?.state.activeStep === index}
								index={index}
								stepName={step.name}
								object={props.object}
								refreshPreview={refreshPreview}
								setAbsolutePos={() => absolutePosStep(step)}
								resetAbsolutePos={() => absolutePosStep(false)}
								{...step.props}
							/>
						</div>
					);
				})}
			</StepWizard>
			{props.editPreview && isPreviewOpen && !isDone && (
				<EditPreview
					component={props.editPreview}
					object={props.object}
					instance={instance}
					active={instance?.state.activeStep}
					setIsPreviewOpen={setIsPreviewOpen}
					autoSave={props.autoSave}
					lastAutoSave={lastAutoSave}
					lastPreviewGen={lastPreviewGen}
				/>
			)}
		</div>
	);
}

export function InnerStepCont(props) {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<div className={"InnerStepCont" + (isOpen ? " open" : "")}>
			<div
				className="step-cont-head d-flex gap-3 align-items-center"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className="step-cont-arrow">
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				<div className="step-cont-title">{props.title}</div>
			</div>
			<div className={isOpen ? "d-flex flex-column" : "d-none"}>
				{props.children}
			</div>
		</div>
	);
}

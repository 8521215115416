import generateUUID from "includes/UUID";

export default function bar_charts_displayer(chart) {
	let values = chart.parsedChartValues();
	let nbr_cols = bar_chart_nbr_cols(values.series);
	let Cols = [];
	let Rows = [];

	for (let x = 0; x <= nbr_cols; x++)
	{
		let id = x + "-" + generateUUID();
		Cols.push({
			colId: id,
			title: id,
			name: "col-" + id,
			type: "col"
		});
	}

	values.series.forEach((serie, index) => {
		let id = index + "-" + generateUUID();
		let row = {
			rowId: id,
			type: "row",
			color: values.palettes[index]
		};
		Cols.forEach((col, y) => {
			if (y > 0)
				col.value = serie.dataSource[y - 1]?.x;
			if (y === 0)
				row[col.name] = serie.name;
			else
				row[col.name] = serie.dataSource[y - 1]?.y;
		});
		Rows.push(row);
	});

	return ({
		Cols,
		Rows,
		Options: setOptions(chart, values)
	});
}

const setOptions = (chart, values) => {
	if (values.primaryXAxis)
		chart.options("AxeX", values.primaryXAxis.title);
	if (values.primaryYAxis)
		chart.options("AxeY", values.primaryYAxis.title);
	return (chart.options());
};

export const bar_chart_nbr_cols = (series) => {
	let max = 0;

	series.forEach((a) => {
		if (a.dataSource.length > max)
			max = a.dataSource.length;
	});
	return (max);
};

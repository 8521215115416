import React, { useState } from "react";

import { rm_double } from "includes/arrays";

// Font awesome
import { t } from "i18next";
import FormsTable from "Pages/Forms/components/FormsTable/FormsTable";
import ScrollTable from "components/ScrollTable/ScrollTable";
import Button from "components/Button/Button";
import ReactModal from "react-modal";
import BackButton from "components/Button/BackButton";

function PostLinkedFormStep(props) {

	const post = props.object;
	const [selected, setSelected] = useState(post.linkedForms());
	const [adding, setAdding] = useState(false);

	function handleSelect(elems) {
		let dup = rm_double(elems, "FormId");
		post.linkedForms(dup);
		setSelected(dup);
		props.refreshPreview();
	}

	let items = post.linkedForms();

	return (
		<div className="PostDocumentsStep">
			<div className="inner-step-cont">
				<div className="font-bold">{t("Pages.FORMS")}</div>
				<div className="d-flex justify-content-between">
					<div>{t("Forms.LINKED_FORMS", {count: items.length})} </div>
					<div>
						<Button
							className={"btn btn-" + (adding ? "grey" : "primary")}
							value="Ajouter"
							onClick={() => setAdding(!adding)}
						>
							{adding ? t("Commons.BACK") : t("Commons.ADD")}
						</Button>
					</div>
				</div>
				{(items.length || adding) && <hr/>}
				<FormsList
					mouseover={false}
					forms={items}
					selected={selected}
					setSelected={handleSelect}
				/>
				{
					adding &&
					<ReactModal isOpen={true}>
						<FormSelector
							mouseover={true}
							selected={selected}
							setSelected={handleSelect}
							curDoc={adding}
							handleClose={() => setAdding(false)}
						/>
					</ReactModal>
				}
			</div>
		</div>
	);
}

export function FormsList(props) {
	const {forms, search, selected, setSelected, mouseover, onItemClick} = props;

	if (!forms?.length)
		return (false);

	const cols = [
		{
			name: "Title",
			title: t("Table.NAME")
		}
	];

	return (
		<div className="FormsList">
			<ScrollTable
				items={forms}
				cols={cols}
				itemId={"FormId"}
				sortBy="Name"
				className="dark-bg"
				search={search}
				selected={selected}
				setSelected={setSelected}
				checkbox={true}
				mouseover={mouseover}
				onItemClick={onItemClick}
			/>
		</div>
	);
}

function FormSelector(props) {
	const {selected, setSelected} = props;

	return (
		<div className="light-bg">
			<div className="d-flex gap-2 mb-3 align-items-center">
				<BackButton onClick={props.handleClose}/>
				<div>{t("Forms.FORM_SELECTION")}</div>
			</div>
			<div className="mb-2">
				{t("Forms.LINKED_FORMS", {count: selected?.length})}
			</div>
			<FormsTable
				toolbar={false}
				mouseover={true}
				hideSelected={true}
				selected={selected}
				setSelected={setSelected}
				canDelete={false}
				onSelectionChange={(resp) => setSelected([...selected, ...resp])}
				selectionKey={["PostLinkedForm-Selection"]}
			/>
		</div>
	);
}

export default PostLinkedFormStep;

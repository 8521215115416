import genRequest from "includes/request";
import generateUUID from "includes/UUID";

export default class FormAnswerModel {

	constructor(obj, parent) {

		this.Parent = parent;
		this.OptionId = (obj?.OptionId ? obj.OptionId : null);
		this.EmployesId = (obj?.EmployesId ? obj.EmployesId : null);
		this.QuestionId = (obj?.QuestionId ? obj.QuestionId : null);
		this.Value = (obj?.Value ? obj.Value : "");
		this.IsFreeAnswer = (obj?.IsFreeAnswer ? obj.IsFreeAnswer : true);
		this.IsNumeric = (obj?.IsNumeric ? obj.IsNumeric : false);
		this.IsBool = (obj?.IsBool ? obj.IsBool : false);
		this.IsFile = (obj?.IsFile ? obj.IsFile : false);
		this.LastChanged = (obj?.LastChanged ? obj.LastChanged : null);
		this.LastChange_AuthorId = (obj?.LastChange_AuthorId ? obj.LastChange_AuthorId : null);
		this.IsAnonymous = (obj?.IsAnonymous ? obj.IsAnonymous : false);

		this.TempId = (obj?.TempId ? obj.TempId : generateUUID());
	}

	send() {
		let prom = new Promise((resolve, reject) => {
			this.sendAnswer()
				.then(() => {
					resolve (true);
				}, reject);
		});
		return (prom);
	}

	sendAnswer() {
		let that = this;
		let req = genRequest(
			( this.OptionId && this.EmployesId ?
				"ModifyAnswer/EmployeId=" + this.EmployesId + "AndOptionId=" + this.OptionId
				:
				"FormAnswers"
			),
			this.values(),
			(this.OptionId && this.EmployesId ? "put" : "post")
		).then((resp) => {
			if (!that.EmployesId)
				that.EmployesId = resp.EmployesId;
		});
		return (req);
	}

	values() {
		let obj = {
			OptionId: this.OptionId,
			EmployesId: this.EmployesId,
			QuestionId: this.QuestionId,
			Value: this.Value,
			IsFreeAnswer: this.IsFreeAnswer,
			IsNumeric: this.IsNumeric,
			IsBool: this.IsBool,
			IsFile: this.IsFile,
			LastChanged: this.LastChanged,
			LastChange_AuthorId: this.LastChange_AuthorId,
			IsAnonymous: this.IsAnonymous
		};
		return (obj);
	}

	value(new_val) {
		if (typeof new_val !== "undefined")
			this.Value = new_val;
		return (this.Value);
	}

	formId(value) {
		if (value)
			this.FormId = value;
		return (this.FormId);
	}

	questionId(value) {
		if (value)
			this.QuestionId = value;
		return (this.QuestionId);
	}

	get parentForm() {
		if (!this.Parent)
			return (false);
		return (this.Parent.parentForm);
	}

	get id() {
		return ((this.OptionId ? this.OptionId : this.TempId));
	}

	set id(value) {
		if (value)
			this.OptionId = value;
	}
}

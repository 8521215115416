import Storage from "classes/Storage/Storage";
import genRequest from "includes/request";
import { useQuery } from "react-query";
import { t } from "i18next";
import PageLoad from "components/PageLoad/PageLoad";
import { SERV_URL } from "includes/paths";
import ServError from "components/ServError/ServError";

const ServerCheck = (props) => {
	const storage = new Storage();
	const get_load_img = () => storage.getItem("comp_image");

	const { isLoading, error } = useQuery(["ServerPing"], () =>
		genRequest(SERV_URL, null, "get", { forceUrl: true })
	);

	if (error)
		return (
			<ServError
				title={t("Server.PING_ERROR")}
				text={
					<>
						<div>{t("Server.TIMEOUT")}</div>
						<div>{t("Server.RETRY_LATER")}</div>
					</>
				}
				logoStyle={{ opacity: 0.7 }}
			/>
		);

	if (isLoading)
		return (
			<div
				id="load-cont"
				className="h-100 w-100 d-flex align-items-center justify-content-center wrapper"
			>
				<PageLoad
					text={t("Server.CONNECTING")}
					fallbackUrl={get_load_img()?.Url_Origin}
				/>
			</div>
		);
	return props.children;
};

export default ServerCheck;

import React, { Component } from "react";
import formatDate, { reverseDate, dateAdd, daysToMonths } from "includes/formatDate";
import "./StatsChart.css";
import { t } from "i18next";
import moment from "moment";
import "moment/locale/fr";

export default class StatsChartCanvas extends Component {

	constructor(props) {
		super(props);

		let cols = this.getColumns();

		this.state = {
			can: null,
			ctx: null,
			unit: cols.unit
		};

		this.theme = (props.theme !== "dark" ? {
			columns: "#FFFFFF",
			text: "#000000",
			bg: "#FFFFFF00"
		} : {
			columns: "#1f1f1f",
			text: "#FFFFFF",
			bg: "#FFFFFF00"
		});

		this.lineWidth = 80;
		this.innerSpace = 10;
		this.cont_ref = React.createRef();
		this.can_ref = React.createRef();

		this.clearCanvas = this.clearCanvas.bind(this);
		this.getColumns = this.getColumns.bind(this);
		this.drawColumn = this.drawColumn.bind(this);
		this.drawGraph = this.drawGraph.bind(this);
		this.getDayText = this.getDayText.bind(this);
		this.getRanges = this.getRanges.bind(this);
		this.getStats = this.getStats.bind(this);
		this.calcTotalDays = this.calcTotalDays.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {

		let can = this.can_ref.current;
		if (can) {
			let cols = this.getColumns();

			can.width = can.parentElement.clientWidth + "px";
			can.height = can.parentElement.clientHeight;
			let ctx = can.getContext("2d");
			this.setState({
				ctx,
				can,
				unit: cols.unit
			}, () => {
				this.drawGraph();
			});
		}

		if (this.cont_ref.current)
			this.cont_ref.current.addEventListener("mousewheel", this.handleScroll);
	}

	componentDidUpdate() {
		this.drawGraph();
	}

	clearCanvas() {
		let ctx = this.state.ctx;
		if (ctx && this.state.can) {
			ctx.fillStyle = this.theme.bg;
			ctx.fillRect(0, 0, this.state.can.width, this.state.can.height);
		}
	}

	getColumns() {
		let totals = this.calcTotalDays();
		let columns = totals.days;
		let unit = "day";

		if (totals.days < 32) {
			columns = totals.days + 1;
			unit = "day";
		} else if (totals.weeks < 8) {
			columns = totals.months;
			unit = "month";
		} else if (totals.months < 25) {
			columns = totals.months;
			unit = "month";
		} else {
			columns = Math.ceil(totals.years) + 1;
			unit = "year";
		}

		return ({
			columns,
			unit
		});
	}

	getStats(curDate, unit) {
		let format;
		const weeks = this.props.weeks;
		if (unit === "day")
			format = "D/M/Y";
		else if (unit === "week")
			format = "W/Y";
		else if (unit === "month")
			format = "M/Y";
		else if (unit === "year")
			format = "Y";
		let date = formatDate(curDate, format);
		let total = {
			FirstConnexions: 0,
			MostLikedPost: null,
			PostsOfTheDay: 0,
			ThreeMostPosters: 0,
			TotalAdsPostedSinceBegining: 0,
			TotalCareerPostedSinceBegining: 0,
			TotalConnexions: 0,
			TotalNewsPostedSinceBegining: 0,
			startDate: curDate
		};

		for (let x = 0; x < weeks.length; x++) {
			const week = weeks[x];
			if (week.Date) {
				let cur_date = formatDate(reverseDate(week.Date), format);
				if (cur_date === date && format.search("D") === -1) {
					total.FirstConnexions += week.FirstConnexions.length;
					// if (week.MostLikedPost != null)
					// 	total.MostLikedPost = week.MostLikedPost;
					total.PostsOfTheDay += week.PostsOfTheDay.length;
					// total.ThreeMostPosters = week.ThreeMostPosters;
					// total.TotalAdsPostedSinceBegining = week.TotalAdsPostedSinceBegining;
					// total.TotalCareerPostedSinceBegining = week.TotalCareerPostedSinceBegining;
					total.TotalConnexions += week.TotalConnexions.length;
					// total.TotalNewsPostedSinceBegining = week.TotalNewsPostedSinceBegining;
				} else if (cur_date === date) {
					return (week);
				}
			}
			// }
		}
		return (total);
	}

	calcTotalDays() {
		let max_day = dateAdd(new Date(), { days: -2 });
		let start = new Date(this.props.startDate);
		let end = new Date(this.props.endDate);
		if (end >= max_day)
			end = max_day;
		let time_diff = end.getTime() - start.getTime();

		return ({
			days: Math.ceil(time_diff / (1000 * 3600 * 24)),
			weeks: Math.ceil(time_diff / (7 * 24 * 60 * 60 * 1000)),
			months: Math.ceil(time_diff / (2e3 * 3600 * 365.25)),
			years: Math.ceil(time_diff / (1000 * 3600 * 24 * 365.25)) - 1,
		});
	}

	drawGraph() {
		let { columns, unit } = this.getColumns();
		if (!columns)
			return (0);

		let can = this.state.can;
		let lineWidth = this.lineWidth;
		let innerSpace = this.innerSpace;
		let ctx = this.state.ctx;
		// console.log("this.props.startDate", this.props.startDate);
		let date = new Date(this.props.startDate);
		let ranges = this.getRanges();
		let theme = this.theme;

		this.clearCanvas();

		let textBottom = 30;
		can.width = (lineWidth + innerSpace) * (columns);
		can.style.width = (lineWidth + innerSpace) * (columns) + "px";

		ctx.font = "12px Poppins-Light";

		for (let x = 0; x < columns; x++) {
			ctx.fillStyle = theme.columns;
			this.drawColumn(
				x * lineWidth + (innerSpace / 2 + x * innerSpace), 10,
				lineWidth, can.height - 20,
				10,
				1, 0
			);
			ctx.fillStyle = theme.text;

			let txt = this.getDayText(date, unit);
			ctx.fillText(txt.up, x * lineWidth + (innerSpace / 2 + x * innerSpace) + (lineWidth / 2 - ctx.measureText(txt.up).width / 2), can.height - (textBottom + 12));
			ctx.fillText(txt.down, x * lineWidth + (innerSpace / 2 + x * innerSpace) + (lineWidth / 2 - ctx.measureText(txt.down).width / 2), can.height - (textBottom));
			let stats = this.getStats(date, unit);

			this.dispDay(x, stats, ranges, unit, this.props.value);
			if (x < columns)
				date = dateAdd(date, {
					years: (unit === "year" ? 1 : 0),
					months: (unit === "month" ? 1 : 0),
					weeks: (unit === "week" ? 1 : 0),
					days: (unit === "day" ? 1 : 0),
				});
			else
				date = new Date(this.props.endDate);
		}
	}

	getDayText(date, unit) {
		let txt = {
			up: "",
			down: ""
		};

		moment.locale(t("CodeMin"));
		let m_date = moment(date);

		if (unit === "day") {
			txt.up = m_date.format("dddd");
			txt.down = date.getDate() + " " + m_date.format("MMM");
		} else if (unit === "week") {
			txt.up = t("Events.WEEK") + date.getWeek();
			txt.down = date.getFullYear();
		} else if (unit === "month") {
			txt.up = m_date.format("MMMM");
			txt.down = date.getFullYear();
		} else if (unit === "year") {
			txt.up = t("Events.YEAR");
			txt.down = date.getFullYear();
		} else
			return (false);
		return (txt);
	}

	dispDay(offset, stats, ranges, unit, value) {
		let x = offset;
		let ctx = this.state.ctx;
		let space = this.state.can;
		let theme = this.theme;
		let lineWidth = this.lineWidth;
		let innerSpace = this.innerSpace;
		let valueY, valueX, txtValue;
		ctx.lineWidth = 2;

		for (let field in ranges) {
			txtValue = null;
			if ((!value && field !== "All") || (value && field === value)) {
				if (stats && ((unit === "day" && stats[field].length && stats[field].length > 0) ||
					(unit !== "day" && stats[field]))) {
					if (unit === "day") {
						valueY = (space.height - 100) * (stats[field].length / ranges.All.range);
						txtValue = stats[field].length;
					} else {
						valueY = (space.height - 100) * (stats[field] / ranges.All.total);
						txtValue = stats[field];
					}
				} else {
					valueY = 0;
				}
				valueY += 60;
				valueX = x * lineWidth + (innerSpace / 2 + x * innerSpace) + lineWidth / 2;
				ctx.fillStyle = ranges[field].color;
				ctx.strokeStyle = ranges[field].color;

				if (ranges[field].LastY && ranges[field].LastX) {

					ctx.beginPath();
					ctx.moveTo(ranges[field].LastX, space.height - ranges[field].LastY);
					ctx.lineTo(valueX, space.height - valueY);
					ctx.closePath();
					ctx.stroke();

					ctx.beginPath();
					ctx.arc(ranges[field].LastX, space.height - ranges[field].LastY, 5, 0, 2 * Math.PI);
					ctx.closePath();
					ctx.fill();

					ctx.strokeStyle = theme.columns;
					ctx.beginPath();
					ctx.arc(valueX, space.height - valueY, 6, 0, 2 * Math.PI);
					ctx.closePath();
					ctx.fill();
					ctx.stroke();

					ctx.beginPath();
					ctx.arc(ranges[field].LastX, space.height - ranges[field].LastY, 6, 0, 2 * Math.PI);
					ctx.closePath();
					ctx.stroke();
					ctx.strokeStyle = theme.text;
					ctx.fillStyle = theme.text;
				} else {
					ctx.fillStyle = ranges[field].color;
					ctx.strokeStyle = theme.columns;
					ctx.beginPath();
					ctx.arc(valueX, space.height - valueY, 6, 0, 2 * Math.PI);
					ctx.closePath();
					ctx.fill();
					ctx.beginPath();
					ctx.arc(valueX, space.height - valueY, 6, 0, 2 * Math.PI);
					ctx.closePath();
					ctx.stroke();
				}
				if (txtValue) {
					ctx.strokeStyle = theme.text;
					ctx.fillStyle = theme.text;
					ctx.fillText(txtValue,
						valueX - ctx.measureText(txtValue).width / 2,
						(
							(valueY < ranges[field].LastY && valueY > 80) ?
								space.height - valueY + 20
								: space.height - valueY - 10
						)
					);

				}

				ranges[field].LastX = valueX;
				ranges[field].LastY = valueY;
			}

		}
	}

	drawColumn(x, y, width, height, radius, fill, stroke) {
		let ctx = this.state.ctx;
		if (typeof stroke === "undefined")
			stroke = true;
		if (typeof radius === "undefined")
			radius = 5;
		if (typeof radius === "number")
			radius = {
				tl: radius,
				tr: radius,
				br: radius,
				bl: radius
			};
		else {
			let defaultRadius = {
				tl: 0,
				tr: 0,
				br: 0,
				bl: 0
			};
			for (let side in defaultRadius)
				radius[side] = radius[side] || defaultRadius[side];
		}
		ctx.beginPath();
		ctx.moveTo(x + radius.tl, y);
		ctx.lineTo(x + width - radius.tr, y);
		ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
		ctx.lineTo(x + width, y + height - radius.br);
		ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
		ctx.lineTo(x + radius.bl, y + height);
		ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
		ctx.lineTo(x, y + radius.tl);
		ctx.quadraticCurveTo(x, y, x + radius.tl, y);
		ctx.closePath();
		if (fill)
			ctx.fill();
		if (stroke)
			ctx.stroke();
	}

	getRanges() {
		let weeks = this.props.weeks;
		let values = {
			FirstConnexions: {
				min: 0,
				max: 0,
				range: 0,
				total: 0,
			},
			PostsOfTheDay: {
				min: 0,
				max: 0,
				range: 0,
				total: 0
			},
			TotalConnexions: {
				min: 0,
				max: 0,
				range: 0,
				total: 0
			},
			All: {
				min: 0,
				max: 0,
				range: 0,
				total: 0
			}
		};

		values.FirstConnexions.color = "#B52653"; //rouge
		values.PostsOfTheDay.color = "#DE742D"; //Orange
		values.TotalConnexions.color = "#1B4378"; //Bleu

		let start = new Date(this.props.startDate);
		let end = new Date(this.props.endDate);

		// console.log("weeks", weeks);

		for (let x = 0; x < weeks.length; x++) {
			// for (let y = 0; y < weeks[x].length; y++) {
			let day = weeks[x];
			let cur_date = new Date(reverseDate(day.Date) + "T00:00");

			if (day && (cur_date >= start && cur_date <= end)) {
				for (let z in values) {
					if (day[z]) {
						if (day[z].length > values[z].max)
							values[z].max = day[z].length;
						else if (day[z].length < values[z].min)
							values[z].min = day[z].length;

						if (day[z].length > values.All.max)
							values.All.max = day[z].length;
						else if (day[z].length < values.All.min)
							values.All.min = day[z].length;
						if (z !== "All") {
							values[z].range = values[z].max - values[z].min;
							if (values[z].range > values.All.range)
								values.All.range = values.All.max - values.All.min;
							values[z].total += day[z].length;
							if (values[z].total > values.All.total)
								values.All.total = values[z].total;
						}
					}
				}
			}
			// }
		}
		return (values);
	}

	handleScroll(e) {
		let cont = this.cont_ref?.current;

		e.stopPropagation();
		if (cont) {
			if (cont.scrollLeft + cont.clientWidth < cont.scrollWidth)
				e.preventDefault();
			cont.scrollLeft = cont.scrollLeft + e.deltaY;
		}
	}

	handleClick(e) {
		let event = e.nativeEvent;
		let column = (event.offsetX - (event.offsetX % (this.lineWidth + this.innerSpace))) / (this.lineWidth + this.innerSpace);
		let unit = this.state.unit;

		if (this.state.unit === "day")
			return (false);
		let curStart = new Date(this.props.startDate);
		if (unit === "year")
			curStart = new Date(curStart.setMonth(0, 1));
		let start = dateAdd(curStart, {
			years: (unit === "year" ? column : 0),
			months: (unit === "month" ? column : 0),
			weeks: (unit === "week" ? column : 0),
			days: (unit === "day" ? column : 0),
		});

		let end = dateAdd(start, {
			months: (unit === "year" ? 12 : 0),
			days: (unit === "month" ? daysToMonths(start) - 1 : (unit === "year" ? -1 : 0)),
		});

		if (unit === "year")
			unit = "month";
		else if (unit === "month")
			unit = "day";
		else if (unit === "week")
			unit = "month";

		this.setState({
			unit
		}, () => {
			if (this.props.setDates)
				this.props.setDates({
					start,
					end
				});
		});
	}

	render() {
		return (
			<div ref={this.cont_ref} className="StatsChartCanvas w-100">
				<canvas className={this.state.unit !== "day" ? "cursor-pointer" : ""} ref={this.can_ref} onClick={this.handleClick} />
			</div>
		);
	}
}

import React, { Component } from "react";
import formatDate from "includes/formatDate";
import PostsTilesList from "../PostsTilesList/PostsTilesList";
import PostsTable from "../PostsTable/PostsTable";
import "./PostsList.css";

export default class PostsList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			Posts: (props.posts ? props.posts : []),
			Displayed: [],
			DispIndex: 0,
			DispStep: (document.body.clientHeight < 1000 && document.body.clientWidth < 800) ? 10 : 20,
			HasMore: true,
			DispFavs: false,
			DispCats: []
		};
		this.getNextsPosts = this.getNextsPosts.bind(this);
	}

	componentDidMount() {
		this.sortPosts();
		if (this.props.display !== "table") {
			this.getNextsPosts();
		} else {
			this.checkDelayed();
		}
	}

	componentDidUpdate(old_props) {
		let old_posts = old_props.posts;
		let new_posts = this.props.posts;
		let addeds = (new_posts ? new_posts?.filter(o1 => !old_posts.some(o2 => o1.PostId === o2.PostId)) : []);
		let removed = (old_posts ? old_posts?.filter(o1 => !new_posts.some(o2 => o1.PostId === o2.PostId)) : []);

		if (old_props.state !== this.props.state || addeds.length || removed.length || old_posts?.length !== new_posts?.length) {
			this.setState({
				...this.state,
				Posts: this.props.posts,
				Displayed: [],
				DispIndex: 0,
				HasMore: true,
				CurPost: false
			}, () => {
				this.sortPosts();
				if (this.props.display !== "table") {
					this.getNextsPosts();
				} else {
					this.checkDelayed();
				}
			});
		}
	}

	checkDelayed() {
		const posts = this.state.Posts;
		const items = [];
		posts.forEach((post) => {
			if (this.checkDate(post) && this.checkState(post) && this.checkSite(post)) {
				items.push(post);
			}
		});

		this.setState({
			Displayed: this.state.Displayed.concat(items)
		});

	}

	sortPosts(sort_by, asc_order) {
		if (!sort_by)
			sort_by = "PriorityTo";
		let posts = this.state.Posts.sort((a, b) => {
			if (a[sort_by] < b[sort_by])
				return ((asc_order ? -1 : 1));
			return ((asc_order ? 1 : -1));
		});
		this.setState({
			Posts: posts
		});
	}

	checkState(post) {
		let curDispState = this.props.state;

		if ((!this.props?.typeId || (post.PostTypeId === this.props.typeId))
			&& ((curDispState === "waiting" && post.ValidationModeration === false && post.Draft === false && !post.ValidationModeration)
				|| (curDispState === "moderated" && post.ModerationComment && post.ValidationModeration === false && post.Draft === true)
				|| (curDispState === "published" && post.ValidationModeration === true && post.Draft === false)
				|| (curDispState === "drafts" && post.Draft === true && !post.ModerationComment && !post.ValidationModeration))
		)
			return (true);
		return (false);
	}

	checkDate(post) {
		let date = formatDate();
		return (date >= post.PublishedDate || this.props.state !== "published" || this.props.isAdmin || post.EmployesId === this.props.account?.EmployesId);
	}

	checkSite(post) {
		let siteId = this.state.CurSite?.value;
		if (siteId)
			if (post.SiteId !== siteId)
				return (false);
		return (true);
	}

	getNextsPosts(max_limit) {
		let disp_count = 0;
		let total_count = 0;
		let items = [];
		let posts = this.state.Posts;

		for (let x = this.state.DispIndex; disp_count < this.state.DispStep && x < posts.length && (!max_limit || (max_limit && disp_count < max_limit)); x++) {
			let post = posts[x];
			total_count++;
			if (this.checkDate(post) && this.checkState(post) && this.checkSite(post)) {
				disp_count++;
				items.push(post);
			}
		}

		this.setState({
			Displayed: this.state.Displayed.concat(items),
			DispIndex: this.state.DispIndex + total_count,
			HasMore: (!max_limit && disp_count < this.state.DispStep) ? false : true
		});
		return (items);
	}

	render() {
		let childProps = {
			curPost: this.props.curPost,
			displayed: this.state.Displayed,
			hasMore: this.state.HasMore,
			handlePostClick: this.props.handlePostClick,
			getNextItems: this.getNextsPosts,
			dispCats: this.props.dispCats,
			saveds: this.props.Saveds,
			search: this.props.search,
			state: this.props.state,
			typeId: this.props.typeId,
			checkbox: this.props.checkbox,
			items: this.state.Posts
		};

		return (
			<div className={"PostsList d-flex overflow-hidden h-100"}>
				{
					this.props.display === "table" ?
						<PostsTable setSelected={this.props.setSelected} itemsLength={this.state.Displayed?.length} {...childProps} />
						:
						<PostsTilesList itemsLength={this.state.Displayed?.length} {...childProps} />
				}
			</div>
		);
	}
}

import React from "react";
import { t } from "i18next";
import { CustomSelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";

export default function CategoriesTypesDropDown(props) {
	const { curType, setCurType } = props;

	const types = [
		{
			label: t("Categories.TYPE-post-1"),
			value: "post-1",
		},
		{
			label: t("Categories.TYPE-documents"),
			value: "documents",
		},
		{
			label: t("Categories.TYPE-charts"),
			value: "charts",
			highlightable: false,
		},
		{
			label: t("Categories.TYPE-post-3"),
			value: "post-3",
		},
		{
			label: t("Categories.TYPE-post-4"),
			value: "post-4",
		},
		// {
		// 	label: t("Categories.TYPE-post-13"),
		// 	value: "post-13",
		//	highlightable: false,
		// },
		{
			label: t("Categories.TYPE-post-14"),
			value: "post-14",
		},
		{
			label: t("Categories.TYPE-post-5"),
			value: "post-5",
		},
		{
			label: t("Categories.TYPE-post-8"),
			value: "post-8",
			highlightable: false,
		},
	];

	const getTypes = () => {
		let ret = types
			.sort((a, b) => (a.label < b.label ? -1 : 1))
			.filter(
				(a) =>
					a.isEnabled !== false &&
					(!props.onlyHighlightable || a.highlightable !== false)
			);

		if (props.all)
			ret.unshift({
				label: "Toutes les catégories",
				value: "ALL",
			});
		return ret;
	};

	function handleSelect(value) {
		setCurType(value);
	}

	return (
		<CustomSelect
			{...props}
			isMulti={props.isMulti === true ? true : false}
			placeholder={props.placeholder}
			className={props.className}
			options={getTypes()}
			isSearchable={true}
			closeMenuOnSelect={props.closeMenuOnSelect}
			value={curType}
			initialValue={curType}
			onChange={handleSelect}
		/>
	);
}

import { t } from "i18next";
import React from "react";

export default function FormTextOption({type}) {
	return (
		<div className="option-cont">
			{
				type.Type === "LongText" ?
					<textarea className="w-100" placeholder={t("Forms.LONG_TEXT_EX")} disabled/>
					:
					<input className="w-100" placeholder={t("Forms.SHORT_TEXT_EX")} type="text" disabled/>
			}
		</div>
	);
}

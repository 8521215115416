import React, {useState} from "react";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import Button from "components/Button/Button";

export default function SaveDocBtn({docId, text, className}) {
	const QCL = useQueryClient();
	const {account} = useAccount();
	const [isSaved, setIsSaved] = useState(false);

	function handleSave(e) {
		let prom = null;
		e.preventDefault();
		e.stopPropagation();
		if (isSaved)
			prom = unsave();
		else
			prom = saveDoc();
		prom.then(() => {
			QCL.refetchQueries(["LT_Employe_Saved_Document", "DocumentIdAndEmployeId", docId, account.EmployesId]);
		});
		return (prom);
	}

	const saveDoc = () => genRequest(
		"LT_Employe_Saved_Document",
		{
			EmployeId: account.EmployesId,
			DocumentId: docId,
		},
		"post"
	).then((resp) => setIsSaved(resp));

	const unsave = () => genRequest("LT_Employe_Saved_Document/EmployeIdAndDocumentId/" + account.EmployesId + "/" + docId, null, "delete").then(() => setIsSaved(false));

	const fetchSaved = () => genRequest(`LT_Employe_Saved_Document/DocumentIdAndEmployeId/${docId}/${account.EmployesId}`);

	const {isLoading} = useQuery(
		["LT_Employe_Saved_Document", "DocumentIdAndEmployeId", docId, account.EmployesId],
		fetchSaved,
		{
			retry: 0,
			enabled: (docId ? true : false),
			refetchOnWindowFocus: false,
			onSettled: (resp) => setIsSaved(resp ? resp : false)
		}
	);

	if (isLoading)
		return (
			<div className="loading secondary">
				<div className="p-2 py-3 w-100"></div>
			</div>
		);

	return (
		<Button className={"SaveDocBtn unstyled d-flex w-auto align-items-center" + (isSaved ? " saved" : "") + (className ? ` ${className}` : "")} onClick={handleSave}>
			{text ? text : false}
			<FontAwesomeIcon
				className={(text ? "ms-2 " : "") + "favorite-icon" + (isSaved ? " saved" : "")}
				title={isSaved ? t("Commons.DEL_FAVORITE") : t("Commons.ADD_FAVORITE")}
				color={isSaved ? "#ffc107" : ""}
				icon={isSaved ? faSolidStar : faStar}
			/>
		</Button>
	);
}

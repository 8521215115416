export default function gauge_charts_displayer(chart) {
	let values = chart.parsedChartValues();
	if (!values)
		return (false);
	let axe = values.axes[0];
	if (!axe)
		return (false);
	let pointers = axe?.pointers;
	if (!pointers)
		return (false);

	chart.options("Value", Number(pointers[0].value));
	chart.options("Label", axe.annotations[0].label);
	chart.options("Min", axe.minimum);
	chart.options("Max", axe.maximum);
	chart.options("InnerThickness", parseInt(pointers[0].pointerWidth));
	chart.options("OutterThickness", parseInt(axe.ranges[0].endWidth));
	chart.options("PrimaryColor", pointers[0].color);
	chart.options("SecondaryColor", axe.ranges[0].color);
}

import Button from "components/Button/Button";
import { t } from "i18next";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function promiseToast(req, opt) {
	let tst = toast.promise(
		req,
		{
			pending: opt?.pending || t("Toasts.PROM_PENDING"),
			success: opt?.success || t("Toasts.PROM_SUCCESS"),
			error: opt?.error || {
				render({data}) {
					return (<ErrorToast data={data}/>);
				}
			},
			autoClose: 2000,
			closeOnClick: true,
			...opt,
		}
	);
	return (tst);
}

function ErrorToast(props) {
	const {account} = useAccount();

	const openDialog = () => {
		let id = Sentry.lastEventId();
		Sentry.showReportDialog({
			eventId: id,
			user: {
				id: account?.EmployesId,
				email: account?.Mail,
				name: (!account ? null : (`${account.FirstName} ${account.LastName}`))
			}
		});
	}

	return (
		<div>
			{ t("Toasts.PROM_ERROR")}
			<Button className="btn-yellow" onClick={openDialog}>
				{t("Reporting.SEND_REPORT")}
			</Button>
		</div>
	);
}

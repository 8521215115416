import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";
import {t} from "i18next";
import React from "react";
import FunctionSwitch from "./FunctionSwitch";

export default function AppSettings({handleRefresh}) {
	const {isAdmin} = useAccount();
	const {getAppFunction} = useTemplate();

	return (
		<FunctionSwitch
			onChange={handleRefresh}
			label={t("Settings.ADVANCED_MD")}
			name={"AdvancedMarkdown"}
			disabled={!getAppFunction("PostsPage.CanUseMarkdown") && !isAdmin()}
		/>
	);
}

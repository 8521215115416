import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";
import React from "react";
import { useQuery } from "react-query";
import "./CompanyBanner.css";

export default function CompanyBanner(props) {
	const { companyId, height } = props;

	function fetchImages() {
		if (!companyId) return Promise.reject(false);
		let prom = fetchBanner().then((resp) => {
			if (!resp?.length) return fetchCompanyLoad();
			return resp;
		});
		return prom;
	}

	function fetchBanner() {
		if (!companyId) return Promise.reject(false);
		let req = genRequest(
			"Documents/DocumentOfCompanyAndType/" + companyId + "/16"
		).then(
			(resp) => {
				return documents_treat(resp, "LastChanged");
			},
			() => false
		);
		return req;
	}

	const fetchCompanyLoad = async () =>
		await genRequest(
			"Documents/DocumentOfCompanyAndType/" + companyId + "/12"
		).then(
			(resp) => {
				return documents_treat(resp, "LastChanged");
			},
			() => false
		);

	const { isLoading, data } = useQuery(
		["Documents", "DocumentOfCompanyAndType", companyId, 16],
		fetchImages
	);

	if (isLoading) {
		return <div className="CompanyBanner loading"></div>;
	}

	let url = null;

	if (data?.length) url = data[0].Url_Origin;

	return (
		<div
			className="CompanyBanner"
			style={{
				backgroundImage: "url(" + url + ")",
				height: height,
			}}
		>
			{props.children}
		</div>
	);
}

import formatDate from "includes/formatDate";
import genRequest from "includes/request";

class ReactionTypeObject {
	#ReactionTypeId = null;
	#Name = "";
	#Description = "";
	#ReactionDesignId = 1;
	#CompanyId = null;
	#LastChanged = null;
	#LastChange_AuthorId = null;

	#LinkedReactions = [];

	constructor(props) {
		this.#initValues(props);
	}

	#initValues = (obj) => {
		let date = formatDate();
		this.#ReactionTypeId = (obj?.ReactionTypeId ? obj.ReactionTypeId : null);
		this.#Name = (obj?.Name ? obj.Name : "");
		this.#Description = (obj?.Description ? obj.Description : "");
		this.#ReactionDesignId = (obj?.ReactionDesignId ? obj.ReactionDesignId : 1);
		this.#CompanyId = (obj?.CompanyId ? obj.CompanyId : null);
		this.#LastChanged = (obj?.LastChanged ? obj.LastChanged : date);
		this.#LastChange_AuthorId = (obj?.LastChange_AuthorId ? obj.LastChange_AuthorId : null);
	};

	send = () => {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			that.sendType()
				.then(() => {
					return (that.unlinkReactions());
				}, reject)
				.then(() => {
					return (that.linkReactions());
				}, reject)
				.then(resolve, reject);
		});
		return (prom);
	};

	sendType = () => {
		let req = genRequest(
			"ReactionTypes" + (this.#ReactionTypeId ? `/${this.#ReactionTypeId}` : ""),
			this.values(),
			this.#ReactionTypeId ? "put" : "post"
		).then((resp) => {
			if (!this.#ReactionTypeId)
				this.#initValues(resp);
		});
		return (req);
	};

	unlinkReactions = () => (
		!this.#ReactionTypeId ?
			Promise.resolve(true)
			:
			genRequest("Lt_ReactionTypeOfReaction/OfReactionType/" + this.#ReactionTypeId, null, "delete")
	);

	linkReactions = () => {
		if (!this.#LinkedReactions?.length)
			return (Promise.resolve(true));
		let vals = this.#LinkedReactions.map((a) => ({
			ReactionTypeId: this.#ReactionTypeId,
			ReactionId: a.ReactionId,
		}));
		let prom = genRequest(
			"Lt_ReactionTypeOfReaction/MultiplePOST",
			vals,
			"post"
		);
		return (prom);
	};

	fetchLinkedReactions = () => {
		let that = this;
		let req = genRequest("Reactions/Typed/" + this.#ReactionTypeId).then((resp) => {
			that.#LinkedReactions = resp;
		});
		return (req);
	};

	linkedReactions = (setLinked) => {
		if (typeof setLinked !== "undefined")
			this.#LinkedReactions = setLinked;
		return (this.#LinkedReactions);
	};

	delete = () => {
		let prom = new Promise((resolve, reject) => {
			if (!this.#ReactionTypeId)
				resolve (true);
			this.unlinkReactions()
				.then(() => {
					let req = genRequest(
						"ReactionTypes/" + this.#ReactionTypeId,
						null,
						"delete"
					);
					return (req);
				}, reject)
				.then(resolve, reject);
		});
		return (prom);
	};

	reactionTypeId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#ReactionTypeId = setValue;
		return (this.#ReactionTypeId);
	};

	name = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#Name = setValue;
		return (this.#Name);
	};

	description = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#Description = setValue;
		return (this.#Description);
	};

	reactionDesignId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#ReactionDesignId = setValue;
		return (this.#ReactionDesignId);
	};

	companyId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#CompanyId = setValue;
		return (this.#CompanyId);
	};

	lastChanged = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#LastChanged = setValue;
		return (this.#LastChanged);
	};

	lastChange_AuthorId = (setValue) => {
		if (typeof setValue !== "undefined")
			this.#LastChange_AuthorId = setValue;
		return (this.#LastChange_AuthorId);
	};

	values = () => {
		let ret = {
			ReactionTypeId: this.#ReactionTypeId,
			Name: this.#Name,
			Description: this.#Description,
			ReactionDesignId: this.#ReactionDesignId,
			CompanyId: this.#CompanyId,
			LastChanged: this.#LastChanged,
			LastChange_AuthorId: this.#LastChange_AuthorId,
		};

		if (!ret.ReactionTypeId)
			delete ret.ReactionTypeId;
		return (ret);
	};
}

export default ReactionTypeObject;

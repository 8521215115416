import { convertToFloatWithTwoDecimals } from "includes/numbers";
import React, { Component } from "react";
import "./TargetChart.css";

export default class TargetChart extends Component {
	constructor(props) {
		super(props);

		let vals = this.props?.parsedValues;
		this.state = {
			canvas: false,
			context: false,
			values: vals?.length
				? vals.sort((a, b) => {
						let x = a["value"];
						let y = b["value"];
						return x < y ? 1 : x > y ? -1 : 0;
				  })
				: [],
			max: this.getMax(),
		};

		this.canvasRef = React.createRef();
		this.clearCanvas = this.clearCanvas.bind(this);
		this.draw = this.draw.bind(this);
	}

	componentDidMount() {
		let that = this;
		this.initCanvas().then(() => {
			if (that.state.context) {
				try {
					that.draw(null);
				} catch (e) {
					return false;
				}
			} else {
				console.error("No Context for TargetChart");
			}
		});
	}

	initCanvas() {
		let prom = new Promise((resolved, reject) => {
			if (this.canvasRef.current) {
				let canvas = this.canvasRef.current;
				if (!canvas) {
					console.error(
						"Impossible de récupérer le canvas '" +
							this.props.ChartId +
							"'"
					);
					reject(false);
					return false;
				}
				canvas.height = 300;

				let ctx = canvas.getContext("2d");
				if (!ctx) {
					console.error(
						"Impossible de récupérer le context de '" +
							this.props.ChartId +
							"'"
					);
					reject(false);
					return false;
				}
				this.setState(
					{
						canvas,
						context: ctx,
					},
					() => {
						resolved(true);
					}
				);
			} else {
				reject(false);
				return false;
			}
		});
		return prom;
	}

	getMax() {
		let max = -Infinity;
		for (let x in this.props.parsedValues)
			if (this.props.parsedValues[x].value > max)
				max = this.props.parsedValues[x].value;
		return max;
	}

	clearCanvas() {
		let ctx = this.state.context;
		let canvas = this.state.canvas;

		ctx.fillStyle = "#FFFFFF";
		ctx.clearRect(0, 0, canvas.width, canvas.height);
	}

	draw(highlight) {
		let canvas = this.state.canvas;
		let ctx = this.state.context;
		let maxValue = this.state.max;
		let length = 0;
		let padding = 20;
		let innerSpace = 5;
		let lineWidth =
			(canvas.width / 2 - 30) /
			(this.props.parsedValues.length + innerSpace);
		if (lineWidth > 30) lineWidth = 30;
		let iter = 0;
		let start = -90 * (Math.PI / 180);

		ctx.imageSmoothingQuality = "high";
		ctx.lineWidth = lineWidth;
		ctx.lineCap = "round";
		this.clearCanvas();

		for (let x = 0; x < this.state.values.length; x++) {
			let value = this.state.values[x].value;
			let color = this.state.values[x].color;
			length = 2 * Math.PI * (value / maxValue);
			ctx.strokeStyle = color;
			ctx.lineWidth = lineWidth;
			if (highlight !== null && highlight === x)
				ctx.lineWidth = lineWidth * 1.2;
			else if (highlight !== null && x !== highlight)
				ctx.strokeStyle = color + "77";
			ctx.beginPath();
			ctx.arc(
				canvas.width / 2,
				canvas.height / 2,
				canvas.height / 2 -
					iter * lineWidth -
					padding -
					innerSpace * iter,
				0 + start,
				length + start
			);
			ctx.stroke();
			ctx.closePath();

			ctx.strokeStyle = color + "15";
			ctx.beginPath();
			ctx.arc(
				canvas.width / 2,
				canvas.height / 2,
				canvas.height / 2 -
					iter * lineWidth -
					padding -
					innerSpace * iter,
				length + start,
				2 * Math.PI + start
			);
			ctx.stroke();
			ctx.closePath();
			iter++;
		}
	}

	genLegend() {
		let values = this.props.parsedValues;
		let inner = (
			<div className="chart-legend">
				{values
					.sort((a, b) => (a.value < b.value ? 1 : -1))
					.map((a, index) => {
						if (!a?.value) return false;
						return (
							<div
								className="value-legend"
								key={index}
								onMouseEnter={() => this.draw(index)}
								onMouseLeave={() => this.draw(null)}
							>
								<div className="d-flex align-items-center">
									<div
										className="value-color me-2"
										style={{ backgroundColor: a.color }}
									></div>
									<div className="value-title">{a.label}</div>
								</div>
								<div className="chart-value">
									{convertToFloatWithTwoDecimals(a.value)}
								</div>
							</div>
						);
					})}
			</div>
		);
		return inner;
	}

	render() {
		return (
			<div className="d-flex flex-column align-items-center">
				<canvas ref={this.canvasRef} />
				{this.genLegend()}
			</div>
		);
	}
}

import download_file from "includes/dl_file";
import formatDate from "includes/formatDate";
import { t } from "i18next";

export default function exportFormAnswers(
	file_name,
	Questions,
	Answers,
	anonymous,
	Users
) {
	var universalBOM = "\uFEFF";
	let header = Questions.sort((a, b) =>
		a.Position < b.Position ? -1 : 1
	).map((a) => `"${a.Title}"`);
	if (!anonymous && Users)
		header.unshift(t("User.FIRST_NAME"), t("User.LAST_NAME"));
	header.unshift("Date");
	let separator = ";";
	let list = genList(Questions, Answers, anonymous, Users, separator);
	const csv = [
		header.join(separator), // header row first
		...list.map((row) => row.join(separator)),
	].join("\r\n");
	download_file(
		universalBOM + csv,
		file_name || "Answers export",
		"text/csv;charset=utf-8;"
	);
}

function genList(questions, answers, anonymous, users, separator) {
	let ret = [];

	const replacer = (key, value) => (value === null ? "" : value);

	answers.forEach((a) => {
		let row = [formatDate(a.Answers[0]?.LastChanged, "D-M-Y h:m")];
		if (!anonymous && users?.length) {
			let user = users.find((u) => a.EmployesId === u.EmployesId);
			if (user) row.push(user.FirstName, user.LastName);
		}
		questions.forEach((q) => {
			let found = a.Answers.filter(
				(ans) => ans.QuestionId === q.QuestionId
			);
			found = sortAnswerOptions(q.Options, found);
			row.push(
				found
					? JSON.stringify(
							found.map((r) => r.Value).join(", "),
							replacer
					  )
					: ""
			);
		});
		ret.push(row);
	});
	return ret;
}

function sortAnswerOptions(options, answers) {
	let ret = answers.sort((a, b) => {
		let a_fi = options.findIndex((o) => o.OptionId === a.OptionId);
		let b_fi = options.findIndex((o) => o.OptionId === b.OptionId);
		return a_fi < b_fi ? -1 : 1;
	});
	return ret;
}

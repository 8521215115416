import React from "react";
import SendStep from "components/EditDrawer/SendStep/SendStep";

export default function UserSendStep(props) {
	const user = props.object;
	const date = Date.now();

	const queries = [
		{
			queryKey: ["SendUser", "Register", date],
			queryFn: () => user.register(),
			title: "Enregistrement de l'utilisateur",
			disabled: !user.isNew(),
			required: true
		},
		{
			queryKey: ["SendUser", date],
			queryFn: () => user.send(),
			title: "Envoi des informations",
			required: true
		},
		{
			queryKey: ["SendUser", "MailConfirm", date],
			queryFn: () => user.sendConfirmMail(),
			title: "Envoi du mail de confirmation",
			disabled: !user.isNew(),
			required: true
		},
		{
			queryKey: ["SendUser", "Groups", date],
			queryFn: () => user.sendGroups(),
			title: "Gestion des groupes",
			disabled: !user.linkedGroups()?.length && !user.groupsToLink()?.length
		},
		// {
		// 	queryKey: ["SendUser", "Rights", date],
		// 	queryFn: () => user.sendRights(),
		// 	title: "Gestion des droits",
		// },
		{
			queryKey: ["SendUser", "Image", date],
			queryFn: () => user.sendImage(),
			title: "Envoi de l'image",
			disabled: !user.image()
		}
	];

	if (!props.isActive)
		return (false);

	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			isDone={props.isDone}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

import React from "react";
import { useQuery, useQueryClient } from "react-query";
import genRequest from "includes/request";
import { t } from "i18next";
import Comments from "Pages/Posts/components/Comments/Comments";
import "./PostComments.css";
import BackButton from "components/Button/BackButton";
import { useMediaQuery } from "react-responsive";

export default function PostComments(props) {
	const { postId, isOpen, onClose } = props;
	const { canComment, showComments } = props;
	const QCL = useQueryClient();
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

	const { isLoading, data } = useQuery(
		["Comments", "CommentOfPost", postId],
		() => genRequest("Comments/CommentOfPost/" + postId),
		{
			enabled: (postId ? true : false),
			onSettled: (resp) => {
				if (!resp)
					return ([]);
				return (resp.sort((a, b) => a.PublishedDate > b.PublishedDate ? -1 : 1));
			}
		}
	);

	if (isLoading)
		return (<div>{t("Posts.LOADING_COMMENTS")}</div>);

	return (
		<div className={"post-comments-drawer" + (isOpen ? " open" : "") + (isMobile ? " containerCommentMobile" : "")}>
			<div className="d-flex mb-3 align-items-center">
				<BackButton className="me-3" title={t("Posts.CLOSE_COMMENTS")} onClick={onClose} />
				{!isMobile && <h4 className="font-bold m-0 p-0 mt-1">{t("Posts.COMMENTS")}</h4>}
			</div>
			<Comments
				isMobile={isMobile}
				className="d-flex justify-content-between flex-column post-comments-wrapper"
				items={data}
				postId={postId}
				onComment={() => QCL.refetchQueries(["Comments", "CommentOfPost", postId])}
				canComment={canComment}
				showComments={showComments}
			/>
		</div>
	);
}

import React from "react";
import ImagesSender from "Pages/Posts/components/ImagesSender/ImagesSender";

export default function AnimationsImagesStep(props) {
	let anim = props.object;
	let post = anim.linkedPost();

	if (!props.isActive)
		return (false);

	const onEditorOpen = () => {
		props.setAbsolutePos();
	};

	const onEditorClose = () => {
		props.resetAbsolutePos();
	};

	return (
		<div className="inner-step-cont">
			<ImagesSender images={post.images()} setPostImages={post.images} onEditorOpen={onEditorOpen} onEditorClose={onEditorClose}/>
		</div>
	);
}

export function checkAnimImages() {
	// set default image
	// let images = post.images();

	// if (!images.length)
	// 	return ([
	// 		"La publication doit avoir une image"
	// 	])
	return (true);
}

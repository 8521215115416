import React, { useRef, useState } from "react";
import ColorPicker from "components/ColorPicker/ColorPicker";
import FormInput from "components/Inputs/FormInput";
import useTemplate from "hooks/useTemplate";
import { t } from "i18next";
import ChartOptionsList from "./ChartOptionsList";

export default function ChartOptions(props) {
	const { chart, type, onChange } = props;
	const TM = useRef();
	const [LastUpdate, setLastUpdate] = useState(false);

	const handleChange = (callback) => {
		callback();
		setLastUpdate(Date.now());
		clearTimeout(TM.current);
		let values = false;
		if (type.editComponent === false && type.valuesGetter)
			values = type.valuesGetter(chart);
		TM.current = setTimeout(() => {
			onChange(values);
		}, 1000);
	};

	const genOptions = () => {
		const opt_props = {
			chart: chart,
			onChange: handleChange,
		};
		if (!type?.options?.length) return false;
		let ret = type.options.map((a) => {
			let isChild = false;
			let opt = ChartOptionsList.find((b) => {
				if (a === b.id) return true;
				if (b.childs && b.childs.find((c) => a === c))
					return (isChild = true);
				return false;
			});
			if (!opt?.component) return false;
			return (
				<opt.component
					key={a}
					lastUpdate={LastUpdate}
					partial={isChild}
					name={a}
					{...opt_props}
				/>
			);
		});
		return ret;
	};

	if (!type?.options?.length) return false;

	return (
		<div
			className={`ChartOptions d-flex flex-column gap-3 ${props.className}`}
			style={props.style}
		>
			<h5>{t("Charts.OPTIONS")}</h5>
			{genOptions()}
		</div>
	);
}

/*
 ** -- Components
 */

export function AxesTitles({ chart, onChange }) {
	return (
		<div className="props-cont d-flex gap-3 flex-wrap">
			<FormInput
				title={t("Indicators_creation.Indicator_Column_Title")}
				value={chart.options("AxeX")}
				onChange={(value) =>
					onChange(() => chart.options("AxeX", value))
				}
			/>
			<FormInput
				title={t("Indicators_creation.Indicator_Value_Unit")}
				value={chart.options("AxeY")}
				onChange={(value) =>
					onChange(() => chart.options("AxeY", value))
				}
			/>
		</div>
	);
}

export function ChartThickness({ chart, partial, name, onChange }) {
	return (
		<div className="props-cont d-flex gap-3 flex-wrap">
			{(!partial || name === "InnerThickness") && (
				<FormInput
					title={t("Indicators_creation.Indicator_Donut_Thickness")}
					type="number"
					min="0"
					max="100"
					value={chart.options("InnerThickness", 20, true)}
					onChange={(value) =>
						onChange(() => chart.options("InnerThickness", value))
					}
				/>
			)}
			{(!partial || name === "OutterThickness") && (
				<FormInput
					title={t("Indicators_creation.Indicator_Gauge_Secondary_Thickness")}
					type="number"
					min="0"
					max="150"
					value={chart.options("OutterThickness", 40, true)}
					onChange={(value) =>
						onChange(() => chart.options("OutterThickness", value))
					}
				/>
			)}
		</div>
	);
}

export function ChartRanges({ chart, onChange }) {
	// set default values
	chart.options("Min", "0", true);
	chart.options("Max", "100", true);

	return (
		<div className="props-cont d-flex gap-3 flex-wrap">
			<FormInput
				title="Minimum"
				type="number"
				value={chart.options("Min", 0, true) || 0}
				max={chart.options("Max") - 1}
				onChange={(value) =>
					onChange(() => chart.options("Min", Number(value)))
				}
			/>
			<FormInput
				title="Maximum"
				type="number"
				value={chart.options("Max", 100, true)}
				min={chart.options("Min") + 1}
				onChange={(value) =>
					onChange(() => chart.options("Max", Number(value)))
				}
			/>
		</div>
	);
}

export function ChartColors({ chart, partial, name, onChange }) {
	const { template } = useTemplate();

	return (
		<div className="props-cont d-flex gap-3 flex-wrap">
			{(!partial || name === "PrimaryColor") && (
				<div>
					<div>{t("Indicators_creation.Indicator_Gauge_Text_Color")}</div>
					<ColorPicker
						value={chart.options(
							"PrimaryColor",
							template.primaryColor(),
							true
						)}
						cssClass="light-bg p-1 radius-1"
						onChange={(val) =>
							onChange(() => chart.options("PrimaryColor", val))
						}
					/>
				</div>
			)}
			{(!partial || name === "SecondaryColor") && (
				<div>
					<div>{t("Indicators_creation.Indicator_Gauge_Text_Secondary_Color")}</div>
					<ColorPicker
						value={chart.options("SecondaryColor", "#f0f0f0", true)}
						cssClass="light-bg p-1 radius-1"
						onChange={(val) =>
							onChange(() => chart.options("SecondaryColor", val))
						}
					/>
				</div>
			)}
		</div>
	);
}

export function ChartValuesOption({ chart, partial, name, onChange }) {
	return (
		<div className="props-cont d-flex gap-3 flex-wrap">
			{(!partial || name === "Value") && (
				<FormInput
					title={t("Indicators_creation.Indicator_Value")}
					externvalue={"true"}
					value={chart.options("Value")}
					onChange={(val) =>
						onChange(() => chart.options("Value", val))
					}
				/>
			)}
			{(!partial || name === "Label") && (
				<FormInput
					title={t("Indicators_creation.Indicator_Gauge_Text_Unit")}
					externvalue={"true"}
					value={chart.options("Label")}
					onChange={(val) =>
						onChange(() => chart.options("Label", val))
					}
				/>
			)}
		</div>
	);
}

import genRequest from "includes/request";
import { FormTile } from "Pages/Forms/components/FormTiles/FormTiles";
import React from "react";
import {useQuery} from "react-query";
import "./PostLinkedForm.css";

export default function PostLinkedForms(props) {
	const {postId, className, onClick, forms } = props;
	const {isLoading, data} = useQuery(
		["Post", "LinkedForms", postId],
		() => genRequest("Forms/FormAffectedToPost/" + postId),
		{
			enabled: (postId ? true : false)
		}
	);

	function handleClick(form) {
		if (onClick)
			onClick(form);
	}

	if (isLoading)
		return (
			<div className="loading">
				<div className="w-100"></div>
				<div className="w-25"></div>
				<div className="w-50"></div>
			</div>
		);

	let items = forms ? forms : data;
	if (!items?.length)
		return (false);

	return (
		<div className={"PostLinkedForms d-flex flex-column gap-2" + (className ? " " + className : "")}>
			{
				items?.map((a) => <FormTile className={"fixed-tile m-0"} form={a} key={a.FormId} onClick={() => handleClick(a)}/>)
			}
		</div>
	);
}

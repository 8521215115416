import React from "react";
import DocumentsController from "classes/Documents/DocumentsController";
import DocumentsPage from "Pages/Documents/components/Page/DocumentsPage";
import useDisplay from "hooks/useDisplay";
import useEditing from "components/EditDrawer/useEditing";
import useTemplate from "hooks/useTemplate";
import useAccount from "classes/Accounts/hooks/useAccount";

function DocsPage(props) {
	const { isAdmin } = useAccount();
	const { setEditing, getEditing } = useEditing();
	const { isMenuOpen, setIsMenuOpen } = props;
	const { display, setDisplay } = useDisplay();
	const { getAppFunction } = useTemplate();

	return (
		<DocumentsController
			typeId={4}
			title={props.title}
			childComponent={DocumentsPage}
			childProps={{
				isMenuOpen,
				setIsMenuOpen,
				isAdmin: isAdmin,
				display: display,
				setDisplay: setDisplay,
				platform: props.platform,
				getEditing,
				setEditing,
				getAppFunction,
			}}
		/>
	);
}

export default DocsPage;

import React from "react";
import { t } from "i18next";
import TableRow from "../TableRow/TableRow";
import "./TableBody.css";

export default function TableBody(props) {
	const {
		tableId,
		isSelected,
		activeId,
		items,
		onItemClick,
		handleCheckbox,
		checkbox,
		cols,
		itemId,
		isMouseDown,
		cellSpacing
	} = props;

	return (
		<table style={{borderSpacing: cellSpacing}} className={"TableBody" + (onItemClick === false ? " no-hover" : "")}>
			<tbody>
				{
					items?.length ?
						items.map((a, index) => {
							if (props.maxRows && index >= props.maxRows)
								return (false);
							let is_selected = isSelected(a);
							if (props.onlySelected === true && !is_selected)
								return (false);
							return (
								<TableRow
									isSelected={is_selected !== false ? true : false}
									key={tableId + "-" + (itemId && a[itemId] ? a[itemId] : index)}
									datas={a}
									cols={cols}
									maxCols={props.maxCols}
									onClick={onItemClick ? () => onItemClick(a) : false}
									handleCheckbox={(e) => handleCheckbox(a, e)}
									checkbox={checkbox}
									isMouseDown={isMouseDown}
									isActive={a[itemId] === activeId}
									tableId={tableId}
									onCellChange={props.onCellChange}
									cellsProps={props.cellsProps}
									rowIndex={index}
								/>
							);
						})
						:
						<tr className="no-items"><td className="no-items-txt w-100 text-center">{t("Table.NO_CONTENT")}</td></tr>
				}
			</tbody>
		</table>
	);
}

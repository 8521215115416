import React, { useState } from "react";
import Joyride from "react-joyride";
import { t } from "i18next";
import useCurPage from "hooks/useCurPage";
import { useQuery, useQueryClient } from "react-query";

export default function Helper() {
	const [CurTour, setCurTour] = useState(false);
	const { setCurPage } = useCurPage();
	const QCL = useQueryClient();

	function loadTour(tour) {
		if (tour.page && window.location.pathname !== tour.page)
			setCurPage(tour.page);
		setCurTour(tour);
	}

	function stepCallback(step) {
		if (!step) {
			QCL.setQueryData(["Helper", "CurrentTour"], false);
			setCurTour(false);
		}
		if (["close", "reset"].indexOf(step.action) >= 0) {
			if (CurTour.onClose) CurTour.onClose();
			QCL.setQueryData(["Helper", "CurrentTour"], false);
			setCurTour(false);
		}
		if (
			step.action === "update" &&
			step.step?.page &&
			step.step.page !== window.location.pathname
		)
			setCurPage(step.step.page);
	}

	useQuery(["Helper", "CurrentTour"], () => {}, {
		onSuccess: (resp) => {
			if (resp) loadTour(resp);
		},
		refetchOnWindowFocus: false,
	});

	if (!CurTour) return false;

	return (
		<Joyride
			callback={stepCallback}
			steps={CurTour.steps}
			continuous={true}
			disableBeacon={true}
			disableOverlayClose={true}
			locale={{
				back: t("Joyride.BACK"),
				close: t("Joyride.CLOSE"),
				last: t("Joyride.LAST"),
				next: t("Joyride.NEXT"),
				open: t("Joyride.OPEN"),
				skip: t("Joyride.SKIP"),
			}}
			styles={{
				options: {
					primaryColor: "var(--color-primary)",
					backgroundColor: "var(--background-color-secondary)",
					arrowColor: "var(--background-color-secondary)",
					textColor: "var(--font-color)",
					textAlign: "left",
				},
			}}
		/>
	);
}

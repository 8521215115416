import React from "react";
import { t } from "i18next";
import SendStep from "components/EditDrawer/SendStep/SendStep";

export default function GroupSendStep(props) {
	const group = props.object;
	const queries = [
		{
			queryKey: ["SendGroup", "Group", group.name(), group.lastChanged()],
			queryFn: () => group.send(),
			title: t("Groups.SEND_GROUP")
		}
	];

	if (!props.isActive)
		return (false);

	return (
		<SendStep
			queries={queries}
			instance={props.instance}
			setIsDone={props.setIsDone}
			setFailedStep={props.setFailedStep}
		/>
	);
}

import useAccount from "classes/Accounts/hooks/useAccount";
import CategoryObject from "classes/Categories/CategoryObject";
import { ConditionalSelect } from "components/Inputs/CustomAsyncSelect/CustomCreatableSelect";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";

export default function CategoriesSelect(props) {
	const { isAdmin } = useAccount();

	function handleAdd(cat) {
		let obj = new CategoryObject({
			Name: cat.label,
			CategoryType: props.type,
			Description: "",
			CompanyId: props.companyId,
		});
		let req = obj.send().then((resp) => {
			let ret = {
				label: resp.Name,
				value: resp.CategoryId,
			};
			if (props.onCreate) props.onCreate(ret);
			return ret;
		});
		return req;
	}

	return (
		<div
			className={
				"categories-drop-cont" +
				(props.className ? ` ${props.className}` : "")
			}
		>
			{!props.noTitle && <div>{t("Commons.CATEGORIES")}</div>}
			<ConditionalSelect
				placeholder={t("Commons.SELECT_CATEGORIES")}
				classNamePrefix="dropdown"
				queryKey={[
					"Categories",
					"CompanyTyped",
					props.companyId,
					props.type,
				]}
				queryFn={() =>
					genRequest(
						"Categories/CompanyTyped/" +
						props.companyId +
						"/" +
						props.type
					)
				}
				treat={(cat) => ({
					...cat,
					value: cat.CategoryId,
					label: cat.Name,
					color: cat.DefaultHexaColor,
				})}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onCreateOption={isAdmin() ? handleAdd : false}
				{...props}
				className={"bg-color w-100 rounded"}
			/>
		</div>
	);
}

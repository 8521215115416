import React from "react";
import { t } from "i18next";
import FormInput from "components/Inputs/FormInput";

export default function CompanyInfosStep(props) {
	let company = props.object;
	return (
		<div className="inner-step-cont">
			<FormInput onChange={company.title} className="w-100 p-2" title={t("Companies.NAME")} value={company.name()}/>
		</div>
	);
}

export function checkCompanyInfos() {
	return (true);
}

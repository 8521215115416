import React from "react";
import { useQueryClient } from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import useUnread from "classes/Accounts/hooks/useUnread";
import Button from "components/Button/Button";
import "./PlateformChanger.css";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShield, faUser } from "@fortawesome/free-solid-svg-icons";
import { useAccountContext } from "providers/AccountProvider";

export default function PlateformChanger({ platform, setPlatform, isReduced }) {
	const { isAdmin } = useAccount();
	const { updatePageTitle } = useUnread();
	const { setUserOrAdmin } = useAccountContext();
	const QCL = useQueryClient();

	function handleChange(new_plat) {
		if (new_plat === platform) return false;
		setPlatform(new_plat);
		setUserOrAdmin(new_plat);
		QCL.setQueriesData(["NotViewed"], false);
		QCL.invalidateQueries(["NotViewed"]);
		updatePageTitle(null, 0);
	}

	if (!isAdmin()) return false;

	return (
		<div className="PlateformChanger">
			<Button
				title={t("Commons.USER")}
				className={`${
					platform === "Desktop"
						? "btn-primary"
						: "btn-transparent btn-h-bg"
				}`}
				onClick={() => handleChange("Desktop")}
			>
				{isReduced ? (
					<FontAwesomeIcon icon={faUser} />
				) : (
					t("Commons.USER")
				)}
			</Button>
			<Button
				title={t("Commons.ADMIN")}
				className={`${
					platform === "admin"
						? "btn-primary"
						: "btn-transparent btn-h-bg"
				}`}
				onClick={() => handleChange("admin")}
			>
				{isReduced ? (
					<FontAwesomeIcon icon={faShield} />
				) : (
					t("Commons.ADMIN")
				)}
			</Button>
		</div>
	);
}

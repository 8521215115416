import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Draggable } from "react-beautiful-dnd";
import generateUUID from "includes/UUID";
import { t } from "i18next";
import Button from "components/Button/Button";

export default function FormChoicesOption({unique, question, isAdder, option, handleUpdate}) {
	const [value, setValue] = useState(option?.Value ? option.Value : "");

	function handleAdd(e) {
		if (!isAdder || (e.type === "keypress" && e.key !== "Enter") || (isAdder && !value?.length))
			return (false);
		question.newOption({Value: value});
		setValue("");
		if (handleUpdate)
			handleUpdate();
	}

	function handleChange(val) {
		if (!isAdder && option)
			option.value(val);
		setValue(val);
	}

	function handleDelete() {
		option.delete();
		if (handleUpdate)
			handleUpdate();
	}

	return (
		<Draggable
			isDragDisabled={isAdder}
			draggableId={option?.id ? option.id.toString() : generateUUID()}
			index={typeof option?.Position !== "undefined" ? option.Position : 0} >
			{(provided) => {
				return (
					<div>
						<div
							ref={provided?.innerRef}
							{...provided?.draggableProps}
							key={option?.id}
						>
							<div
								className="option-cont d-flex align-items-center"
								data-form-option-id={option?.id}
							>
								<div
									className={"option-handle me-2" + (isAdder ? " d-none" : "")}
									{...provided?.dragHandleProps}
									onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
								/>
								<div className="d-flex align-items-center mr-2 w-100">
									<input
										type={unique ? "radio" : "checkbox"}
										className="option-value mr-2"
										disabled
										name={"question-option" + question?.id}
									/>
									<input
										type="text"
										className="w-100 option-value"
										placeholder={t("Forms.ADD_OPTION")}
										onKeyPress={handleAdd}
										value={value}
										onChange={(e) => handleChange(e.target.value)}
									/>
								</div>
								<div className="option-toolbar ms-2">
									{
										(isAdder !== true ?
											<div className='cursor-pointer option-toolbar-btn' onClick={handleDelete}>
												<FontAwesomeIcon color={"red"} icon={faTrashAlt}/>
											</div>
											:
											<Button
												className="btn btn-primary"
												onClick={handleAdd}
											>
												{t("Commons.ADD")}
											</Button>
										)
									}
								</div>
							</div>
						</div>
						{provided.placeholder}
					</div>
				);
			}}
		</Draggable>
	);
}

const MDETour = {
	tourId: "MDE-UI",
	title: "Editeur de texte",
	description: "Fonctionnalitées de l'éditeur de texte",
	steps: [
		{
			target: ".markdown-editor button.custom-header-btn",
			title: "En-tête",
			content: "Cliquez pour transformer la ligne ou la selection actuelle en en-tête",
			disableBeacon: true,
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.bold",
			title: "Gras",
			content: "Cliquez ici pour passer la ligne ou la selection actuelle en gras",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.italic",
			title: "Italique",
			content: "Cliquez ici pour passer la ligne ou la selection actuelle en italic",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.bullet-list",
			title: "Liste à points",
			content: "Cliquez ici pour créer une liste à points",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.ordered-list",
			title: "Liste ordonnée",
			content: "Cliquez ici pour créer une liste ordonée",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.outdent",
			title: "Réduire l'indentation",
			content: "Cliquez ici pour réduire le décalage du texte, ou supprimer le formatage en liste",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.link",
			title: "Lien externe",
			content: "Cliquez ici pour ouvrir le module d'ajout de lien",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.quote",
			title: "Citation",
			content: "Cliquez ici pour insérer une citation ou transformer la selection actuelle en citation",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor button.code",
			title: "Bloc de code",
			content: "Cliquez ici pour intégrer un bout de code informatique",
			spotlightClicks: true
		},
		{
			target: ".markdown-editor .toastui-editor-mode-switch",
			title: "Mode d'édition",
			content: "Cliquez ici pour changer de mode d'édition ou afficher le formatage du texte",
			spotlightClicks: true
		}		
	]
};

export default MDETour;

import React from "react";
import { useQuery } from "react-query";
import genRequest from "includes/request";

import EditableUserImage from "components/User/Image/EditableUserImage";
import PasswordChangerForm from "components/Forms/PasswordChangerForm";
import ProfileForm from "components/Forms/ProfileChangerForm";
import "./ProfilePage.css";
import { t } from "i18next";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";

export default function ProfilePage() {
	const { account, isAdmin } = useAccount();
	const { template } = useTemplate();

	const fetchMe = () => genRequest("Employes/GetCompleteEmpData/" + account.EmployesId);

	const { isLoading, data, error } = useQuery(
		["Employes", "GetCompleteEmpData", account.EmployesId],
		fetchMe
	);

	if (isLoading)
		return (<div>{t("Commons.LOADING")}</div>);

	if (error)
		return (<div>{t("MyOnlyAll.PROFILE_ERROR")}</div>);

	let user = data?.Employe;

	return (
		<div className="profile-cont d-block d-md-flex">
			<div className="d-flex flex-column me-3 mb-3">
				<div className="d-flex align-items-center justify-content-between flex-wrap">
					<div className="mb-3">
						<EditableUserImage user={user} employesId={account.EmployesId} />
					</div>
					<div>
						<QueryTagsList
							className="mb-3"
							queryKey={["Groups", "GroupsOfEmploye", account.EmployesId]}
							queryFn={() => genRequest("Groups/GroupOfEmploye/" + account.EmployesId)}
							enabled={(account && account.EmployesId) ? true : false}
						/>
					</div>
				</div>
				<ProfileForm
					editable={template.getAppFunction("ProfilePage.PersonalProfilPage.CanChangeProfil")}
					user={data?.Employe}
					company={data?.Company}
					site={data?.Site}
					wfy={data.WFY}
					wfu={data.WFU}
				/>
			</div>
			{
				(template.getAppFunction("ProfilePage.PersonalProfilPage.CanChangePassword") !== false || isAdmin()) &&
				<PasswordChangerForm disabled={account.IsTemp} disabledMsg={t("MyOnlyAll_Page.MyOnlyAll_Password_No_Change")} />
			}
		</div>
	);
}

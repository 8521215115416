import React from "react";
import { t } from "i18next";
import EditDrawer from "components/EditDrawer/EditDrawer";
import DocContentStep, { checkDocument } from "./steps/DocContentStep";
import DocSendStep from "./steps/DocSendStep";
import DocEndStep from "./steps/DocEndStep";
import "./DocEditDrawer.css";
import DocSendOptionsStep from "./steps/DocSendOptionsStep";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function DocEditDrawer(props) {

	const { account } = useAccount();

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);
		return returnRightsFromName(account.Rights, rigthName) ? false : true;
	};
	const steps = [
		{
			component: DocContentStep,
			name: "content",
			title: t("Posts.CONTENT_STEP"),
			onNext: checkDocument,
		},
		{
			component: DocSendOptionsStep,
			name: "options",
			title: t("Commons.PUBLISH_OPTIONS"),
			disabled: checkRight(),
			// onNext: checkDocument,
		},
		{
			component: DocSendStep,
			name: "send",
			disableNav: true,
			sendStep: true,
			title: t("Posts.SEND_STEP")
		},
		{
			component: DocEndStep,
			name: "preview",
			title: t("Commons.END_STEP"),
			disableLink: true
		}
	];

	if (!props.isOpen)
		return (false);

	return (
		<EditDrawer
			steps={steps}
			onClose={props.onClose}
			object={props.document}
			isOpen={props.isOpen}
			title={t("Docs.ADD_DOC")}
			initQueries={props.initQueries}
		/>
	);
}

import React, { Component } from "react";
import DocPreview from "../DocPreview/DocPreview";
import "./DocumentsPage.css";
import { t } from "i18next";
import noAccent from "includes/no_accents";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import DocumentObject from "classes/Documents/DocumentObject";
import DocEditDrawer from "../DocEditDrawer/DocEditDrawer";
import DocsList from "../DocsList/DocsList";
import HighlightedCategories from "components/HighlightedCategories/HighlightedCategories";

class DocumentsPage extends Component {
	constructor(props) {
		super(props);

		this.TypeId = props.typeId;

		this.state = {
			Docs: props.docs ? props.docs : [],
			Displayed: [],
			DispIndex: 0,
			DispStep: 30,
			HasMore: true,
			CurDoc: false,
			EditDoc: false,
			SearchResults: false,
			Search: "",
			DispCats: [],
			DispFavs: false,
		};

		this.LastDisplay = props.display;
		this.LastPlatform = props.platform;
		this.token_obj = JSON.parse(sessionStorage.getItem("token"));
		this.handleDocClick = this.handleDocClick.bind(this);
		this.selectCategory = this.selectCategory.bind(this);
		this.resetCatsFilter = this.resetCatsFilter.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleModify = this.handleModify.bind(this);
		this.handleCloseEdit = this.handleCloseEdit.bind(this);
	}

	componentDidMount() {
		this.sortDocs();
	}

	componentDidUpdate(old_props) {
		if (old_props.docs !== this.props.docs) {
			this.setState(
				{
					Docs: this.props.docs ? this.props.docs : [],
					SearchResults: false,
					Displayed: [],
					HasMore: true,
					DispIndex: 0,
				},
				() => {
					this.sortDocs();
				}
			);
		}
		this.LastDisplay = this.props.display;
		if (this.props.platform !== this.LastPlatform) {
			if (
				this.props.platform === "admin" &&
				this.props.display !== "table"
			)
				this.props.setDisplay("table");
			else if (
				this.props.platform !== "admin" &&
				this.props.display !== "tiles"
			)
				this.props.setDisplay("tiles");
		}
		this.LastPlatform = this.props.platform;
	}

	sortDocs(sort_by, asc_order) {
		if (!sort_by) sort_by = "LastChanged";
		let docs = this.state.Docs.sort((a, b) => {
			if (a[sort_by] < b[sort_by]) return asc_order ? -1 : 1;
			return asc_order ? 1 : -1;
		});
		this.setState({
			Docs: docs,
		});
	}

	selectCategory(cat) {
		let categories = this.state.DispCats;
		let cat_id = cat.id || cat.CategoryId;

		let check = this.state.DispCats.indexOf(cat_id);
		if (check === -1) categories.push(cat_id);
		else
			categories = categories
				.map((a) => {
					if (a === cat_id) return false;
					return a;
				})
				.filter((a) => a);
		this.setState({
			DispCats: categories,
		});
	}

	resetCatsFilter() {
		this.setState({
			DispCats: [],
		});
	}

	getDocs(name_filter) {
		let filter_reg = new RegExp(noAccent(name_filter), "gi");
		let ret = this.state.Docs.filter((doc) => {
			if (!name_filter || noAccent(doc.Name).search(filter_reg) !== -1)
				return doc;
			return false;
		}).filter((a) => a);
		return ret;
	}

	handleDocClick(doc) {
		this.setState({
			CurDoc: doc,
		});
	}

	handleSearch(val) {
		let docs = val?.length ? this.getDocs(val) : false;

		this.setState({
			Search: val,
			SearchResults: docs,
			Displayed: [],
			HasMore: true,
			DispIndex: 0,
		});
	}

	handleDelete(doc) {
		if (this.props.handleDelete) this.props.handleDelete(doc);
	}

	handleAdd() {
		let obj = new DocumentObject({
			CompanyId: this.props.account.CompanyId,
			SiteId: this.props.account.SiteId,
			DocTypeId: 4,
			IsShareable: this.props.getAppFunction(
				"DocumentsPage.AllowSharing"
			),
			account: this.props.account,
		});
		this.setState({
			EditDoc: obj,
			CurDoc: false,
		});
		this.props.setEditing(true);
	}

	handleModify(doc) {
		let obj = new DocumentObject({
			...doc,
			CompanyId: this.props.account.CompanyId,
			account: this.props.account,
		});
		this.setState({
			EditDoc: obj,
			CurDoc: false,
		});
		this.props.setEditing(true);
	}

	handleCloseEdit() {
		this.setState(
			{
				EditDoc: false,
			},
			() => {
				if (this.props.refreshList) this.props.refreshList();
			}
		);
		this.props.setEditing(false);
	}

	render() {
		var SiteSelectRender = false;
		var HandleAddRender = false;

		if (this.props && typeof this.props.isAdmin === 'function') {
			SiteSelectRender = this.props.isAdmin() ? this.props.setSite : false;
			// HandleAddRender = this.props.isAdmin() ? this.handleAdd : false;
		}

		return (
			<div className="docs-page-inner-cont">
				{this.props.headBar !== false && (
					<PageHeadBar
						typeId={"documentTypeId"}
						search={this.handleSearch}
						curSite={this.props.curSite}
						siteSelect={SiteSelectRender}
						title={this.props.title}
						menuToggler={this.props.menuToggler}
						favState={this.props.savedState}
						dispCats={this.state.DispCats}
						selectCategory={this.selectCategory}
						resetCatsFilter={this.resetCatsFilter}
						catsType={"documents"}
						buttons={[
							{
								title: t("Commons.FAVORITES"),
								classes:
									"btn-favs-docs btn" +
									(this.props.savedState
										? " btn-primary"
										: ""),
								onClick: () =>
									this.props.setSaved(!this.props.savedState),
							},
						]}
						isMenuOpen={this.props.isMenuOpen}
						setIsMenuOpen={this.props.setIsMenuOpen}
						handleAdd={this.handleAdd}
						addTitle={t("Docs.ADD_DOC")}
						filters={true}
						display={this.props.display}
						setDisplay={this.props.setDisplay}
					/>
				)}
				<div className="d-flex flex-column h-100 mt-2 overflow-hidden">
					{this.state.CurDoc && (
						<DocPreview
							typeId={"documentTypeId"}
							id="doc-preview"
							className="doc-preview"
							onClose={this.handleDocClick}
							documentId={this.state.CurDoc.DocumentId}
							handleModify={
								this.props?.isAdmin ? this.handleModify : false
							}
						/>
					)}
					{this.props.display !== "table" && (
						<HighlightedCategories
							typeId="documents"
							onItemClick={(item) => {
								this.selectCategory(item);
							}}
							selected={this.dispCats}
						/>
					)}
					{!this.state.EditDoc && (
						<DocsList
							isAdmin={this.props.isAdmin}
							docs={this.state.SearchResults || this.state.Docs}
							handleDocClick={this.handleDocClick}
							dispCats={this.state.DispCats}
							display={this.props.display}
							searching={this.state.Search?.length}
						/>
					)}
				</div>
				{this.state.EditDoc && (
					<DocEditDrawer
						typeId={this.TypeId}
						document={this.state.EditDoc}
						isOpen={true}
						onClose={this.handleCloseEdit}
						initQueries={[
							{
								queryKey: [
									"DocumentEdit",
									"FetchForEdit",
									Date.now(),
								],
								queryFn: () =>
									this.state.EditDoc.fetchForEdit(
										"data:application/pdf;base64,"
									),
							},
						]}
					/>
				)}
			</div>
		);
	}
}

export default DocumentsPage;

import React, { useState } from "react";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import "./LoginFooter.css";
import Button from "components/Button/Button";
import LanguageSwitcher from "i18n/LanguageSwitcher/LanguageSwitcher";

export default function LoginFooter({setLangCode}) {
	const [isLangOpen, setIsLangOpen] = useState(false);

	return (
		<div className="loggin-footer">
			<div className="d-flex align-items-center footer-bar">
				<div className="me-1">
					<a rel="noreferrer" href="https://onlyall.fr" target={"_blank"}>OnlyAll</a> © <a href="https://joonsoft.fr" rel="noreferrer" target={"_blank"}>JoonSoft</a> | v{process.env.REACT_APP_VERSION} |</div>
				<Button className="unstyled toggle-language-menu-btn w-auto" onClick={() => (setIsLangOpen(!isLangOpen))}><FontAwesomeIcon className="btn-icon me-1" icon={faLanguage}/>{t("Settings.LANGUAGE")}</Button>
			</div>
			<div className={"language-menu-cont" + (isLangOpen ? " open" : "")} onMouseLeave={() => (setIsLangOpen(false))}>
				<LanguageSwitcher handleChange={setLangCode}/>
			</div>
		</div>
	);
}

import React, {useState} from "react";
import { t } from "i18next";
import FormInput from "components/Inputs/FormInput";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";
import PostObject from "classes/Posts/PostObject";
import useAccount from "classes/Accounts/hooks/useAccount";
import promiseToast from "components/Toasts/PromiseToast";

export default function ModerationCont(props) {
	const {account} = useAccount();
	const {post} = props;
	const [rejectState, setRejectState] = useState({
		errors: false,
		reason: false,
		rejecting: false,
		isOpen: false
	});

	function initReject(state, rejected) {
		setRejectState({
			errors: false,
			reason: "",
			rejecting: false,
			isOpen: (typeof state !== "undefined" ? state : true)
		});
		if (!state && props.onClose)
			props.onClose(rejected);
	}

	function rejectPost() {
		const {reason, rejecting} = rejectState;
		setRejectState({...rejectState, errors: false});
		let dup = new PostObject({
			...post,
			IsRejected: true,
			LastChange_AuthorId: account.EmployesId
		});
		if (!reason?.length)
			return (
				setRejectState({...rejectState, errors: t("Posts.MOD_REASON")})
			);
		if (rejecting)
			return (false);
		setRejectState({...rejectState, errors: false, rejecting: true});
		let req = dup.rejectPost(reason).then(() => {
			initReject(false, true);
		});
		promiseToast(req);
		return (req);
	}

	return (
		<div className="d-flex flex-column w-100 moderation-comment-cont">
			<div className="font-bold">
				{t("Posts.MOD_REASON")}
			</div>
			<FormInput
				style={{minHeight: "100px"}}
				textarea={"true"}
				value={rejectState.reason}
				onChange={(val)=> setRejectState({...rejectState, reason: val})}
				className="w-100"
				disabled={rejectState.rejecting ? true : false}
			/>
			<ErrorsCont className="mb-1 font-bold" errors={rejectState.errors}/>
			<div className="d-flex">
				<Button
					className={`w-100 me-2 ${rejectState.rejecting ? " disabled loading" : ""}`}
					onClick={rejectState.rejecting ? null : () => initReject(false)}
				>
					{t("Commons.CANCEL")}
				</Button>
				<Button
					className={`btn-black w-100 ${rejectState.rejecting ? " disabled loading" : ""}`}
					onClick={rejectState.rejecting ? null : () => rejectPost()}
				>
					{t("Commons.REJECT")}
				</Button>
			</div>
		</div>
	);
}

import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAccount from "classes/Accounts/hooks/useAccount";
import DocumentObject from "classes/Documents/DocumentObject";
import {AsyncGallery} from "Pages/Posts/components/ImagesSender/components/Gallery/Gallery";
import formatDate from "includes/formatDate";
import React from "react";
import { useState } from "react";
import "./ImageUploader.css";
import { t } from "i18next";

export default function ImageUploader(props) {
	const {onSelect, onUpload, docTypeId} = props;
	const {account} = useAccount();

	const handleFileUpload = (items) => {
		let prom = treat_file(items[0]).then((resp) => {
			if (onUpload)
				onUpload(resp)
		});
		return (prom)
	}

	const treat_file = (file) => {
		const promise = new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				let ext = file.name.split(".").reverse()[0];
				const image = new DocumentObject({
					Name: "FormQuestionImage" + formatDate() + "." + ext,
					DocTypeId: docTypeId || 1,
					DocumentFile: reader.result,
					File: file,
					EmployeId: account.EmployesId,
					CompanyId: account.CompanyId
				});
				image.send().then(() => {
					return (image.fetchDocUrl())
				}).then(() => {
					let values = image.values();
					resolve(values);
					return (values);
				})			
			}, false);
			reader.readAsDataURL(file)
		})
		return (promise);
	}

	const onGalerySelect = (item) => {
		if (onSelect)
			onSelect(item);
	}

	return (
		<div className="ImageUploader d-flex flex-column gap-4">
			<div >
				<div>{t("Files.IMPORT_FROM_PC")}</div>
				<FilesInput accept=".png, .jpeg, .jpg" onChange={handleFileUpload}/>
			</div>
			<div>
				<div>{t("Files.SELECT_FROM_GALLERY")}</div>
				<AsyncGallery
					readOnly={true}
					onItemClick={onGalerySelect}
				/>
			</div>
		</div>
	)
}

function FilesInput(props) {

	const {onChange, multiple} = props;
	const input_ref = React.useRef();
	const cont_ref = React.useRef();
	// eslint-disable-next-line
	const [hasFile, setHasFile] = useState(false);

	const handleFile = (ev) => {
		let files = ev.target.files;

		setHasFile(true);
		if (onChange)
			onChange(files);
	}

	// eslint-disable-next-line
	const clear = () => {
		setHasFile(false);
		input_ref.current.value = false
	}

	const handleDrag = (type) => {

		let cont = cont_ref.current;

		if (type !== false)
			cont.classList.add("over")
		else
			cont.classList.remove("over")
	}

	return (
		<div
			className="FilesInput"
			onDragEnter={() => handleDrag("enter") }
			onDragLeave={() => handleDrag(false) }
			onDragEnd={() => handleDrag(false)}
			ref={cont_ref}
		>
			<div className="FI-placeholder d-flex p-3 justify-content-between align-items-center">
				{t("Files.FILE_PH")}
				<div className="btn-primary upload-btn">
					<FontAwesomeIcon icon={faUpload}/>
				</div>
			</div>
			<input
				type={"file"}
				accept={props.accept}
				ref={input_ref}
				onChange={handleFile}
				multiple={multiple}
				onDragEnd={() => handleDrag(false)}
				onDrop={() => handleDrag(false)}
				
			/>
		</div>
	)
}

import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import React from "react";
import { useQueryClient } from "react-query";
import Button from "components/Button/Button";
import useTheme from "hooks/useTheme";
import { t } from "i18next";
import "./ChartPreviewDrawer.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import ChartTile from "components/Charts/Tile/ChartTile";
import { TypeRights, returnRightsFromName } from "includes/rightType";

export default function ChartPreviewDrawer(props) {
	const { theme } = useTheme();
	const { isAdmin, account } = useAccount();
	const { chart } = props;
	const { confirmDelete } = useSelect();
	const QCL = useQueryClient();

	const checkRight = () => {
		const rigthName = TypeRights.AdminChart;
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	function handleDelete() {
		confirmDelete(
			delchart,
			null,
			"L'indicateur \"" +
				chart.Title +
				'" sera supprimé, voulez vous continuer ?'
		);
	}

	function delchart() {
		if (!chart?.ChartId) return Promise.reject(false);
		let prom = genRequest(
			"Charts?chartId=" + chart?.ChartId,
			null,
			"delete"
		).then(() => {
			QCL.resetQueries(["Charts"]);
			if (props.onDelete) props.onDelete();
		});
		return prom;
	}

	return (
		<div className="ChartPreviewDrawer me-4">
			<ChartTile
				className="w-100 mb-3"
				data={chart}
				theme={theme}
				key={chart.ChartId}
			/>
			{isAdmin() && checkRight() && (
				<div className="d-flex flex-column gap-2">
					{props.handleModify && (
						<Button
							className="btn-blue"
							onClick={() => props.handleModify(chart)}
						>
							{t("Commons.MODIFY")}
						</Button>
					)}
					<Button
						className="btn-red"
						onClick={() => handleDelete(chart)}
					>
						{t("Commons.DELETE")}
					</Button>
				</div>
			)}
		</div>
	);
}

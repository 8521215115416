export default function download_file(data, filename, type) {
	let file = new Blob([data], {
		type: type,
	});
	if (window.navigator.msSaveOrOpenBlob)
		// IE10+
		window.navigator.msSaveOrOpenBlob(file, filename);
	else {
		// Others
		let a = document.createElement("a"),
			url = URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function () {
			if (a) document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}, 0);
	}
}

export function gen_csv(header, list, options, separator) {
	let items = [];

	if (list?.length) items = list;

	if (options) if (options.filter) items = items.filter(options.filter);
	if (!separator) separator = ";";

	const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
	// const header = Object.keys(items[0]) // Uncomment for all fields
	if (!header) header = Object.keys(items[0]);
	const csv = [
		header.join(separator), // header row first
		...items.map((row) =>
			header
				.map((fieldName) => JSON.stringify(row[fieldName], replacer))
				.join(separator)
		),
	].join("\r\n");
	return csv;
}

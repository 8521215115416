import { t } from "i18next";
import AlertSendOptions from "./AlertSendOptions/AlertSendOptions";
import PostContentStep, { checkPostContent } from "../Content/PostContentStep";
import PostEndStep from "../EndStep/PostEndStep";
import PostSendStep from "../SendStep/PostSendStep";
import AlertTargetsStep, { checkAlertStep } from "./AlertTargetsStep";

export default function getAlertsSteps(props)
{
	const EditAlertSteps = [
		{
			component: PostContentStep,
			name: "content",
			title: t("Posts.CONTENT_STEP"),
			onNext: checkPostContent,
			props: {
				categories: false
			}
		},
		{
			component: AlertTargetsStep,
			name: "targetsStep",
			title: t("Commons.TARGETS"),
			onNext: checkAlertStep
		},
		{
			component: AlertSendOptions,
			name: "sendOptions",
			title: t("Commons.PUBLISH_OPTIONS"),
		},
		{
			component: PostSendStep,
			name: "send",
			// disableLink: true,
			disableNav: true,
			sendStep: true,
			title: t("Posts.SEND_STEP")
		},
		{
			component: PostEndStep,
			name: "preview",
			title: t("Posts.PREVIEW_STEP"),
			disableLink: true,
			props: {
				dispDrafts: props.dispDrafts
			}
		}
	];
	return (EditAlertSteps);
}

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAccount from "classes/Accounts/hooks/useAccount";
import { Panel } from "components-v2/Panel/panel.component";
import RssTableToolbar from "components-v2/Rss/rssToolbar.component";
import { RssForm } from "components-v2/Rss/rssform.component";
import Button from "components/Button/Button";
import Table from "components/Table/Table";
import "components-v2/Rss/rss.styles.css";
import { t } from "i18next";
import { useAccountContext } from "providers/AccountProvider";
import { useEffect, useState } from "react";
import { RssService } from "service/rss.service";
import { getWriteRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";

export const RssPage = () => {
	const [rssItems, setRssItems] = useState([]);
	const [showEdit, setShowEdit] = useState(false);
	const { accountObj } = useAccountContext();
	const { isAdmin, isSup, account } = useAccount();
	const [addBtnVisible, setAddBtnVisible] = useState(true);

	const getRssFeeds = async (CompanyId) => {
		const data = await RssService.getRssFeeds(CompanyId);
		setRssItems(data.filter((item) => item.IsDeletable === true));
	};

	const postData = async (data) => {
		const { CompanyId } = accountObj.account;
		const dataToPost = data.map((item) =>
			RssService.postRssFeed({
				...item,
				CompanyId,
				isDeletable: true,
			})
		);
		setShowEdit(false);
		await Promise.all([...dataToPost]);
		getRssFeeds(CompanyId);
	};

	useEffect(() => {
		const { CompanyId } = accountObj.account;
		getRssFeeds(CompanyId);
		return () => {
			setRssItems([]);
		}
	}, []);

	const cols = [
		{
			title: t("Rss.NAME"),
			field: "Name",
		},
		{
			title: "Url",
			field: "RssUrl",
		},
		{
			title: t("Rss.IS_NOTIFIED"),
			field: "IsNotified",
			className: "small-width",
			render: ({ IsNotified }) => (
				<div className="tac">
					<input
						type="checkbox"
						className="checkboxTable"
						checked={IsNotified}
						onChange={(e) => { }}
					/>
				</div>
			),
			editComponent: (data) => {
				const { value, onChange } = data;
				return (
					<div className="tac">
						<input
							type="checkbox"
							className="checkboxTable"
							checked={value}
							onChange={(e) => onChange(e.target.checked)}
						/>
					</div>
				);
			},
		},
	];

	const checkRight = () => {
		const rigthName = getWriteRightFromTypeId("rssTypeId");
		setAddBtnVisible(returnRightsFromName(account.Rights, rigthName));
	};

	useEffect(() => {
		if (account.Rights) {
			checkRight();
		}
	}, [account.Rights]);

	const isEditable = (user) =>
		isAdmin() && (isSup("EloAdmin") >= 0 || isSup(user.Role) >= 0);

	const editActions = {
		isEditable: (data) => isEditable(data),
		isEditHidden: (data) => !isEditable(data),
		isDeletable: (data) => isEditable(data),
		// isDeleteHidden: (data) => console.log(data),
		onRowEdit: () => { },
		onRowUpdate: (data) => {
			return RssService.updateRssFeed(data.Id, data).then(() => {
				getRssFeeds(accountObj.account.CompanyId);
			});
		},
		onRowDelete: (data) => {
			return RssService.deteleRssFeed(data.Id).then(() => {
				getRssFeeds(accountObj.account.CompanyId);
			});
		},
	};

	return (
		<div className=" mb-4">
			<div className="headbar-right-cont d-flex justify-content-between align-items-center flex-wrap mb-lg-0 gap-2 w-100">
				<h1 className="page-title">{t("Rss.RSS_ADMIN")}</h1>
				<div className="d-flex gap-2">
					{addBtnVisible && <Button
						className="headbar-add-btn btn btn-primary gap-2"
						onClick={() => setShowEdit(!showEdit)}
					>
						<div className="add-btn-title">{t("Rss.ADD_FEED")}</div>
						<FontAwesomeIcon icon={faEdit} />
					</Button>
					}
				</div>
			</div>

			{showEdit && (
				<Panel
					title={t("Rss.RSS_POPUP_TITLE")}
					styleOpen={{ top: 0 }}
					onClose={() => setShowEdit(false)}
				>
					<RssForm postData={postData} />
				</Panel>
			)}
			<div className="mt-4 height-custom">
				<Table
					columns={cols}
					data={rssItems}
					options={{
						selection: isAdmin(),
						paging: false,
					}}
					// onRowClick={handleClick}
					overloads={{
						Toolbar: (cprops, options) => (
							<RssTableToolbar
								{...cprops}
								{...options}
								resetFc={() =>
									getRssFeeds(accountObj.account.CompanyId)
								}
							/>
						),
					}}
					selectionKey={["SelectedEmployes"]}
					editable={isAdmin() ? editActions : false}
				/>
			</div>
			{/* <Cards items={rssItems} /> */}
			{/* <TableV2 data={rssItems} /> */}
		</div>
	);
};

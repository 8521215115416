import React, { useState } from "react";
import useAccount, { getCurAccount } from "classes/Accounts/hooks/useAccount";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import genRequest from "includes/request";
import { useQueryClient } from "react-query";
import "./CompanyChanger.css";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";
import { t } from "i18next";

function CompanyChanger(props) {

	const { account, setAccount } = useAccount();
	const [error, setError] = useState(false);
	const [curValue, setCurValue] = useState(account.CompanyId);
	const QCL = useQueryClient();

	const fetchAdminsOfCompany = (compId) => genRequest("Employes/AdminsOfCompany/" + compId);

	function changeCompany(comp) {
		let original = getCurAccount(true);
		let companyId = comp.value;
		setError(false);
		if (companyId === original.CompanyId)
			return (resetOriginal());
		let prom = fetchAdminsOfCompany(companyId).then((resp) => {
			if (resp?.length) {
				let EloAdmins = resp.map(a => (a.Role === "EloAdmin" ? a : false)).filter(a => a);
				if (EloAdmins?.length) {
					original.TempValues = EloAdmins[0];
					setAccount(original);
					setCurValue(companyId);
					QCL.resetQueries(["Template"]);
					return (true);
				}
			}
			setError("Aucun GodAdmin n'est disponible pour la compagnie " + (comp.label ? "\"" + comp.label + "\"" : "sélectionnée") + ", veuillez en créer un");
			setCurValue(companyId);
			return (false);
		});
		return (prom);
	}

	const resetOriginal = () => {
		let original = getCurAccount(true);
		original.TempValues = false;
		original.IsTemp = false;
		setCurValue(original.CompanyId);
		setAccount(original);
		QCL.resetQueries(["Template"]);
		return (true);
	};

	return (
		<div className={"CompanyChanger" + (props.className ? " " + props.className : "")}>
			{
				account.IsTemp && props.resetBtn !== false &&
				<Button title="Revenir sur le réseau OnlyAll initialement relié au compte lors de la connexion" className="btn-bg btn-h-primary mb-2" onClick={resetOriginal}>OnlyAll {t('Settings.DEFAULT')}</Button>
			}
			<QuerySelect
				classNamePrefix="dropdown"
				initialValue={[{ value: curValue }]}
				onChange={changeCompany}
				closeMenuOnSelect={true}
				queryKey={["Companies"]}
				queryFn={() => genRequest("Companies")}
				menuPlacement={props.menuPlacement}
				treat={(item) => {
					return ({
						value: item.CompanyId,
						label: item.Name
					});
				}}
			/>
			<ErrorsCont className="mt-2 font-bold" errors={error} />
		</div>
	);
}

export default CompanyChanger;

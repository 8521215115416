import { useState } from "react";

function useDisplay(force) {
	const [display, setDisplay] = useState(getDisplay(force));

	const changeDisplay = (set_disp) => {
		sessionStorage.setItem("Display", set_disp);
		setDisplay(set_disp);
		return (set_disp);
	};

	return ({
		setDisplay: changeDisplay,
		display
	});
}

const getDisplay = (force) => {
	if (force)
	{
		sessionStorage.setItem("Display", force);
		return (force);
	}
	let res = "tiles";
	try {
		res = sessionStorage.getItem("Display");
		if (!res)
			res = "tiles";
	} catch (e) {
		return ("tiles");
	}
	return (res);
};

export default useDisplay;

import React from "react";
import genRequest from "includes/request";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { components } from "react-select";
import { ReactionsListCell } from "Pages/Reactions/AdminReactionsPage";
import { useQuery } from "react-query";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";

const { Option } = components;

export default function ReactionsTypeSelect(props) {
	const {account} = useAccount();

	const getInitValue = () => {
		if (props.initialValue)
			return (props.initialValue);
		if (!props.type)
			return (false);
		if (!data?.length)
			return (false)
		return ({
			label: "",
			value: data[0]?.ReactionTypeId
		})
	}

	const {isLoading, data} = useQuery(
		["ReactionTypes", "Default", props.type, account.CompanyId],
		() => genRequest(
			`LT_DefaultReactionOfPostForCompany/ofPostTypeIdAndCompanyId/${props?.type}/${account.CompanyId}`),
		{
			enabled: props.type > 0,
			onSettled: (resp) => {
				if (props.onDefaultLoaded)
					props.onDefaultLoaded(resp[0])
			}
		}
	)

	if (isLoading)
		return (<div>{t("Commons.LOADING")}</div>)

	return (
		<QuerySelect
			queryKey={["ReactionTypes"]}
			queryFn={() => genRequest("ReactionTypes")}
			treat={(a) => {
				return ({
					label: "",
					value: a.ReactionTypeId
				});
			}}
			components={{Option: ReactionsOption, Control: ReactionsLabel}}
			{...props}
			initialValue={getInitValue()}
			isClearable={true}
			delimiter={false}
		/>
	);
}

const ReactionsLabel = ({ children, ...props }) => {
	let value = props.getValue();

	return (
		<components.Control {...props}>
			{value?.length > 0 ? <ReactionsList typeId={value[0].value}/> : false} {children}
		</components.Control>
	);
};

const ReactionsOption = (props) => {
	return (
		<Option {...props} className="d-flex align-items-center gap-2">
			<ReactionsList typeId={props.data.value}/> {props.data.label}
		</Option>
	);
};

const ReactionsList = ({typeId}) => (
	<ReactionsListCell
		item={{ReactionTypeId: typeId}}
		style={{
			maxWidth: "200px",
			width: "auto"
		}}
	/>
);

import React from "react";
import Button from "components/Button/Button";
import { t } from "i18next";
import { toast } from "react-toastify";

export default function confirmToast(text, onConfirm, onCancel) {
	let tst = toast(
		<div className="ConfirmToast">
			<div>{text}</div>
			<div className="d-flex gap-1">
				{onCancel && <Button className='btn-red' onClick={onCancel}>{t("Commons.CANCEL")}</Button>}
				{onConfirm && <Button className='btn-green' onClick={onConfirm}>{t("Commons.CONFIRM")}</Button>}
			</div>
		</div>,
		{
			autoClose: false,
			draggable: false
		}
	);
	return (tst);
}

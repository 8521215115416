import React from "react";
import { t } from "i18next";
// import "./CompanyEditDrawer.css";
import EditDrawer from "components/EditDrawer/EditDrawer";
import CompanyInfosStep, { checkCompanyInfos } from "./steps/CompanyInfos/CompanyInfos";

export default function CompanyEditDrawer(props) {

	const CompanySteps = [
		{
			component: CompanyInfosStep,
			name: "content",
			title: t("Posts.CONTENT_STEP"),
			onNext: checkCompanyInfos,
		},
		// {
		// 	component: PostImagesStep,
		// 	name: "images",
		// 	title: t("Posts.IMAGES_STEP"),
		// 	onNext: checkPostImages,
		// 	disabled: (props.images === false)
		// },
		// {
		// 	component: AttachementsStep,
		// 	name: "documents",
		// 	title: t("Posts.ATTACH_STEP"),
		// 	disabled: (props.linkedDocs === false),
		// },
		// {
		// 	component: PostSendOptionsStep,
		// 	name: "sendOptions",
		// 	title: t("Commons.PUBLISH_OPTIONS"),
		// 	disabled: (isSup("SiteAdmin") >= 0 ? false : true),
		// },
		// {
		// 	component: PostSendStep,
		// 	name: "send",
		// 	disableLink: true,
		// 	disableNav: true,
		// 	sendStep: true,
		// 	title: t("Posts.SEND_STEP")
		// },
		// {
		// 	component: PostEndStep,
		// 	name: "preview",
		// 	title: t("Posts.PREVIEW_STEP"),
		// 	disableLink: true
		// }
	];

	if (!props.isOpen)
		return (false);

	return (
		<EditDrawer
			steps={CompanySteps}
			onClose={props.onClose}
			object={props.company}
			isOpen={props.isOpen}
			account={props.account}
			title={"Companie"}
		/>
	);
}

import React, { useState } from "react";
import Storage from "classes/Storage/Storage";
import { toast } from "react-toastify";
import useTheme from "hooks/useTheme";
import Button from "components/Button/Button";
import { t } from "i18next";

function useEditing(forceValue) {
	const storage = new Storage(null, null, sessionStorage);
	const [editing, setEditing] = useState(typeof forceValue !== "undefined" ? initEditing(storage, forceValue) : getEditing(storage));
	const {theme} = useTheme();

	const changeEdit = (set_editing) => {
		storage.setItem("Editing", set_editing);
		setEditing(set_editing);
		return (set_editing);
	};

	const checkEditing = (onOk, onCancel, onDraft) => {
		let check_edit = getEditing(storage);
		let prom = new Promise((resolve, reject) => {
			if (check_edit === true)
			{
				toast(
					<CloseEditToast
						onOk={() => resolve((onOk ? onOk() : reject(false)))}
						onCancel={() => resolve((onCancel ? onCancel() : reject(false)))}
						onDraft={onDraft ? () => resolve((onDraft ? onDraft() : reject(false))) : false}
					>
						{ t("Editing.UNSAVED_WARN") }
					</CloseEditToast>, {
						autoClose: false,
						theme: (theme ? theme : "light"),
						draggable: false
					}
				);
				return (false);
			}
			else {
				resolve(true);
				return (true);
			}
		});
		return (prom);
	};

	return ({
		setEditing: changeEdit,
		getEditing: () => getEditing(storage),
		checkEditing,
		editing
	});
}

const initEditing = (storage, set_editing) => {
	storage.setItem("Editing", set_editing);
	return (set_editing);
};

const getEditing = (storage) => {
	try {
		let res = storage.getItem("Editing");
		return (res ? res : false);
	} catch (e) {
		return (false);
	}
};

export function CloseEditToast(props) {
	const {onOk, onCancel, onDraft} = props;

	function handleDraft() {
		if (onDraft)
			onDraft();
	}

	function handleCancel() {
		if (onCancel)
			onCancel();
	}

	function handleOk() {
		if (onOk)
			onOk();
	}

	return (
		<div className='CloseEditToast d-flex align-items-center justify-content-center flex-column text-center'>
			{props.children}
			<div className='d-flex gap-2 flex-wrap mt-2 align-items-center justify-content-center'>
				{ onOk && <div><Button className='btn-red' onClick={() => handleOk()}>{t("Editing.NO_SAVE")}</Button></div> }
				{ onDraft && <div><Button className='btn-yellow' onClick={() => handleDraft()}>{t("Editing.DRAFT_SAVE")}</Button></div> }
				{ onCancel && <div><Button className='btn-green' onClick={() => handleCancel()}>{t("Editing.CONTINUE")}</Button></div> }
			</div>
		</div>
	);
}

export default useEditing;

import useAccount from "classes/Accounts/hooks/useAccount";
import Table from "components/Table/Table";
import { AsyncUsersList } from "components/Users/UsersList/UsersList";
import { t } from "i18next";
import genRequest from "includes/request";
import React from "react";
import SiteLogo from "../SiteLogo/SiteLogo";
import SitesTableToolbar from "./SitesTableToolbar";

export default function SitesTable(props) {
	const {isAdmin} = useAccount();

	const cols = [
		{
			title: t("Table.TITLE"),
			field: "Name",
			classes: "site-name-cell",
			render: (data) => <TitleCell item={data}/>
		},
		{
			title: t("Sites.COUNTRY"),
			field: "Country",
			classes: "site-country-cell",
		},
		{
			title: t("Sites.TOWN"),
			field: "Town",
			classes: "site-town-cell",
		},
		{
			title: t("Sites.MEMBERS"),
			field: "SiteId",
			classes: "site-members-cell",
			render: (data) => <UsersCell item={data}/>,
			sorting: false
		},
	];

	return (
		<div className="SitesTable d-flex flex-column h-100">
			<Table
				onRowClick={(e, item) => props.handleItemClick(item)}
				columns={cols}
				data={props.displayed}
				activeRowId={props.curSite?.SiteId}
				rowIdKey="SiteId"
				options={{
					selection: isAdmin() || props.checkbox
				}}
				overloads={{
					Toolbar: (cprops, options) => (
						<SitesTableToolbar
							{...cprops}
							{...options}
						/>
					)
				}}
			/>
		</div>
	);
}

const TitleCell = ({item}) => {

	return (
		<div className="d-flex align-items-center">
			<SiteLogo
				siteId={item.SiteId}
				companyId={item.CompanyId}
				width={"30px"}
			/>
			<div>
				{ item.Name }
			</div>
		</div>
	)
}

const UsersCell = ({item}) => {
	return (
		<AsyncUsersList
			queryKey={["Employes", "EmployeOfSite", item.SiteId]}
			queryFn={() => genRequest("Employes/EmployeOfSite/" + item.SiteId)}
			max={10}
			width={20}
		/>
	)
}

const ImageEditorTour = {
	tourId: "ImageEditor-UI",
	title: "Editeur d'image",
	description: "Fonctionnalitées de l'éditeur d'image",
	steps: [
		{
			target: ".image-editor-cont .tie-btn-crop",
			title: "Recadrage",
			content: "Cliquez ici pour recadrer une image au format normalisé (14:11)",
			disableBeacon: true,
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-flip",
			title: "Inverser",
			content: "Cliquez ici pour inverser l'orientation horizontale ou verticale de l'image",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-rotate",
			title: "Rotation",
			content: "Cliquez ici pour effectuer une rotation de l'image",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-draw",
			title: "Dessiner",
			content: "Cliquez ici pour dessiner sur l'image",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-text",
			title: "Liste ordonnée",
			content: "Cliquez ici pour créer une liste ordonée",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-zoomIn",
			title: "Zoom +",
			content: `Cliquez ici pour activer l'outil zoom +.
			Sélectionnez ensuite la zone du zoom sur l'image en restant appuyé sur le clic gauche et en déplacant la souris.
			Vous pouvez réduire le zoom une foi effectué en cliquant sur l'outil "Zoom -"
			`,
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-zoomOut",
			title: "Zoom -",
			content: "Cliquez ici pour réduire le zoom actuel",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-hand",
			title: "Déplacer",
			content: "Cliquez ici pour déplacer la zone de l'image lorsqu'un zoom est appliqué",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-history",
			title: "Historique de actions",
			content: "Cliquez ici pour afficher l'historique des actions effectuées (recadrage, rotation, dessin, ...)",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-undo",
			title: "Annuler l'action effectué",
			content: "Cliquez ici pour annuler la dernière action effectuée",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-redo",
			title: "Rétablir l'action effectuée",
			content: "Cliquez ici pour rétablir la dernière action annulée",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-reset",
			title: "Rétablir l'image d'origine",
			content: "Cliquez ici pour rétablir l'image d'origine importée a l'ouverture de l'éditeur",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-delete",
			title: "Supprimer la modification",
			content: "Aprés avoir sélectionné un dessin ou un texte ajouté à l'image, cliquez ici pour le supprimer",
			spotlightClicks: true
		},
		{
			target: ".image-editor-cont .tie-btn-deleteAll",
			title: "Rétablir l'image d'origine",
			content: "Cliquez ici pour supprimer tous les dessins ou textes ajoutés à l'image",
			spotlightClicks: true
		}
	]
};

export default ImageEditorTour;

import formatDate from "includes/formatDate";
import genRequest from "includes/request";

class WorkFamillyObject {

	#CompanyId = null;
	#WorkFamillyId = null;
	#WorkFamillyName = null;
	#LastChanged = formatDate();
	#LastChange_AuthorId = null;

	constructor (props)
	{
		this.#CompanyId = props?.CompanyId || null;
		this.#WorkFamillyId = props?.WorkFamillyId || null;
		this.#WorkFamillyName = props?.WorkFamillyName || "";
		this.#LastChanged = props?.LastChanged || formatDate();
		this.#LastChange_AuthorId = props?.LastChange_AuthorId || null;
	}

	send = () => {
		let that = this;
		let isNew = !(this.#WorkFamillyId > 0);
		let prom = new Promise((resolve, reject) => {
			let req = that.sendWfam().then(() => {
				if (isNew)
					return (that.linkToCompany());
				resolve(that.values());
				return (that.values());
			}, reject).then(() => {
				resolve (that.values());
				return (that.values());
			}, reject);
			return (req);
		});
		return (prom);
	};

	sendWfam = () => {
		let datas = {
			CompanyId: this.#CompanyId,
			WorkFamillyName: this.#WorkFamillyName,
		};
		if (this.#WorkFamillyId)
			datas.WorkFamillyId = this.#WorkFamillyId;
		let req = genRequest(
			"WorkFamillies" + (this.#WorkFamillyId ? "/" + this.#WorkFamillyId : ""),
			datas,
			(this.#WorkFamillyId ? "put" : "post")
		).then((resp) => {
			if (!this.#WorkFamillyId)
			{
				this.#CompanyId = resp.CompanyId;
				this.#WorkFamillyId = resp.WorkFamillyId;
				this.#WorkFamillyName = resp.WorkFamillyName;
				this.#LastChange_AuthorId = resp.LastChange_AuthorId;
				this.#LastChanged = resp.LastChanged;
			}
		});
		return (req);
	};

	linkToCompany = () => {
		let req = genRequest(
			"LtWorkFamillyCompanies",
			{
				WorkFamillyId: this.#WorkFamillyId,
				CompanyId: this.#CompanyId
			},
			"post"
		);
		return (req);
	};

	id = () => this.#WorkFamillyId;

	name = (setName) => {
		if (typeof setName !== "undefined")
			this.#WorkFamillyName = setName;
		return (this.#WorkFamillyName);
	};

	lastChanged = () => this.#LastChanged;

	LastChange_AuthorId = () => this.#LastChange_AuthorId;

	values = () => ({
		CompanyId: this.#CompanyId,
		WorkFamillyId: this.#WorkFamillyId,
		WorkFamillyName: this.#WorkFamillyName,
		LastChanged: this.#LastChanged,
		LastChange_AuthorId: this.#LastChange_AuthorId
	});
}

export default WorkFamillyObject;

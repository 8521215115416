import React from "react";
import ContextMenu from "components/ContextMenu/ContextMenu";
import { t } from "i18next";

export default function TableCtxMenu(props) {
	const {id, data} = props;

	let event_items = [
		{
			label: t("Table.ADD_COL"),
			items: [
				{
					label: t("Table.BEFORE"),
					onClick: (ev) => props.onColAdd(ev?.props, true),
					enabled: data.colIndex > 1
				},
				{
					label: t("Table.AFTER"),
					onClick: (ev) => props.onColAdd(ev?.props, false),
				},
			]
		},
		{
			label: t("Table.ADD_ROW"),
			items: [
				{
					label: t("Table.BEFORE"),
					onClick: (ev) => props.onRowAdd(ev?.props, true),
				},
				{
					label: t("Table.AFTER"),
					onClick: (ev) => props.onRowAdd(ev?.props, false),
				},
			]
		},
		{
			label: t("Table.CLEAN"),
			items: [
				{
					label: t("Table.CLEAN_ROW"),
					onClick: (ev) => props.onRowClean(ev?.props)
				},
				{
					label: t("Table.CLEAN_COL"),
					onClick: (ev) => props.onColClean(ev?.props)
				},
			]
		},
		{
			label: t("Table.DELETE"),
			enabled: (data.rows > 1 || (data?.colIndex > 0 && data.cols > 2)),
			items: [
				{
					label: t("Table.DELETE_ROW"),
					onClick: (ev) => props.onRowDelete(ev?.props),
					enabled: data.rows > 1
				},
				{
					label: t("Table.DELETE_COL"),
					onClick: (ev) => props.onColDelete(ev?.props),
					enabled: data?.colIndex > 0 && data.cols > 2
				},
			]
		}
	];

	return (<ContextMenu id={id} className="TableCtxMenu" items={event_items}/>);
}

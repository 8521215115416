import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";
import FormOptionModel from "./FormsAnswerOptionsModels/FormOptionModel";
import getQuestionsTypes from "./QuestionsTypes";

/*
	Question Types:
		1 -	Txt
		2 -	ShortText
		3 -	Checkboxes
		4 -	Range
		5 - Buttons
*/

export default class FormQuestionModel {

	constructor(obj, parent) {
		let date = formatDate();

		this.Parent = parent;
		this.QuestionId = (obj?.QuestionId ? obj.QuestionId : null);
		this.FormId = (obj?.FormId ? obj.FormId : null);
		this.FormSectionId = (obj?.FormSectionId ? obj.FormSectionId : null);
		this.QuestionDesignId = (obj?.QuestionDesignId ? obj.QuestionDesignId : 1);
		this.Type = this.getQuestionsType(this.QuestionDesignId);
		this.EmployeId = (obj?.EmployeId ? obj.EmployeId : null);
		this.Title = (obj?.Title ? obj.Title : "");
		this.Txt = (obj?.Txt ? obj.Txt : "");
		this.ImgUrl = (obj?.ImgUrl ? obj.ImgUrl : "");
		this.AnswerRequired = (obj?.AnswerRequired ? obj.AnswerRequired : false);
		this.Multiple = (obj?.Multiple ? obj.Multiple : this.Type.Multiple);
		this.PublishedDate = (obj?.PublishedDate ? obj.PublishedDate : date);
		this.Position = (obj?.Position ? obj.Position : this.Parent.questions().length);
		this.LastChanged = (obj?.LastChanged ? obj.LastChanged : date);
		this.LastChanged_AuthorId = (obj?.LastChanged_AuthorId ? obj.LastChanged_AuthorId : null);
		this.Options = [];

		this.TempId = (obj?.TempId ? obj.TempId : generateUUID());

		if (!this.FormSectionId && this.Parent)
			this.FormSectionId = this.Parent.id;

		if (obj && obj.Options) {
			this.Options = obj.Options.map((opt) => {
				return (this.newOption(opt));
			});
		} else {
			if ([1, 6].indexOf(this.QuestionDesignId) !== -1 || !this.Options?.length)
			{
				this.Options = [
					this.newOption()
				];
			}
		}

		this.Elem = null;
	}

	send() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			this.sendQuestion()
				.then(() => {
					that.sendOptions();
				}, reject).then(() => {
					resolve (true);
				}, reject);
		});
		return (prom);
	}

	sendQuestion() {
		let that = this;
		let req = genRequest(
			"FormQuestions" + (this.QuestionId ? "/" + this.QuestionId : ""),
			this.values(),
			(this.QuestionId ? "put" : "post")
		).then((resp) => {
			if (!that.QuestionId)
				that.id = resp.QuestionId;
		});
		return (req);
	}

	sendOptions() {
		let proms = [];

		for (let option of this.Options)
		{
			option.QuestionId = this.QuestionId;
			option.FormId = this.FormId;
			proms.push(option.send());
		}
		return (Promise.all(proms));
	}

	newOption(opt_obj)
	{
		if (!this.Type)
			return (false);
		let obj = new FormOptionModel(opt_obj, this);
		obj.TempId = generateUUID();
		this.Options.push(obj);
		this.reorderOptions();
		return (obj);
	}

	removeOption(optId) {
		let index = this.Options.findIndex((a) => a.id === optId);
		if (index !== -1)
		{
			let deleted = this.Options.splice(index, 1);
			this.reorderOptions();
			return (deleted);
		}
		return (false);
	}

	getQuestionsType(type_id)
	{
		let QuestionsTypes = getQuestionsTypes();
		let type = QuestionsTypes.find((a) => a.QuestionDesignId === type_id);
		return (type ? type : false);
	}

	changeType(new_type)
	{
		if (new_type)
		{
			let new_options = [];
			if (this.Type.Multiple && this.Type.Multiple === new_type.Multiple)
			{
				new_options = this.Options.map((answ) => {
					let values = answ.values();
					values.OptionId = false;
					values.TempId = false;
					return (new FormOptionModel(values, this));
				});
			} else if (!new_type.Multiple)
				new_options.push(new FormOptionModel(null, this));
			this.Options.splice(0, this.Options.length);
			this.Options = new_options;
			this.Type = new_type;
			this.Multiple = new_type.Multiple;
			this.QuestionDesignId = new_type.QuestionDesignId;
		}
	}

	moveOption(from_index, to_index) {
		const [removed] = this.Options.splice(from_index, 1);
		this.Options.splice(to_index, 0, removed);
		this.reorderOptions();
	}

	reorderOptions() {
		let pos = 0;
		for (let x in this.Options)
			this.Options[x].Position = pos++;
	}

	order(new_pos) {
		if (new_pos)
			this.Position = new_pos;
		return (this.Position);
	}

	move(new_sec) {
		this.Parent.removeQuestion(this.QuestionId, true);
		this.Parent = new_sec;
		this.FormSectionId = new_sec.id;
		new_sec.questions().push(this);
	}

	clone() {
		return (this.Parent.cloneQuestion(this.id));
	}

	delete() {
		return (this.Parent.removeQuestion(this.id));
	}

	sectionId(new_id) {
		if (new_id)
			this.FormSectionId = new_id;
		return (this.FormSectionId);
	}

	formId(value) {
		if (value)
		{
			this.FormId = value;
			for (let opt of this.Options)
				opt.formId(value);
		}
		return (this.FormId);
	}

	get elem() {
		if (!this.Elem)
			this.genDom();
		return (this.Elem);
	}

	get parentForm() {
		if (!this.Parent)
			return (false);
		return (this.Parent.parentForm);
	}

	get parent() {
		return (this.Parent);
	}

	get id() {
		return (this.QuestionId ? this.QuestionId : this.TempId);
	}

	set id(value) {
		this.QuestionId = value;
		for (let opt of this.Options)
			opt.questionId(value);
	}

	checkValues() {
		if (!this.Title?.length)
			return ({
				error: "FORM_QUESTION_EMPTY_TITLE",
				selector: "[data-form-question-id='" + this.id + "'] .question-title-cont"
			});
		if (this.Multiple)
		{
			if (!this.Options.length)
				return ({
					error: "FORM_MULTIPLE_ANSWERS_MISSING_OPTION",
					selector: "[data-form-question-id='" + this.id + "'] .question-options-cont"
				});
			for (let opt of this.Options)
				if (!opt.value())
					return ({
						selector: "[data-form-option-id='" + opt.id + "']",
						error: "FORM_ANSWER_MISSING_VALUE"
					});
		}
		return (true);
	}

	values(with_opts) {
		let obj = {
			FormId: this.FormId,
			FormSectionId: this.FormSectionId,
			QuestionDesignId: this.QuestionDesignId,
			Type: this.Type,
			EmployeId: this.EmployeId,
			Title: this.Title,
			Txt: this.Txt,
			ImgUrl: this.ImgUrl,
			AnswerRequired: this.AnswerRequired,
			Multiple: this.Multiple,
			PublishedDate: this.PublishedDate,
			Position: this.Position,
			LastChanged: this.LastChanged,
			LastChanged_AuthorId: this.LastChanged_AuthorId,
		};
		if (this.QuestionId)
			obj.QuestionId = this.QuestionId;
		if (with_opts)
			obj.Options = this.Options.map(ans => {
				return (ans.values());
			});
		return (obj);
	}
}

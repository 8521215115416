import { t } from "i18next";
import {useQuery} from "react-query";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Table.css";
import TableHead from "./components/TableHead/TableHead";
import TableBody from "./components/TableBody/TableBody";
import noAccent from "includes/no_accents";
import LoadCont from "components/LoadCont/LoadCont";
import generateUUID from "includes/UUID";

export default class ScrollTable extends Component {

	constructor (props) {
		super (props);

		this.state = {
			selected: props.selected || [],
			lastMd: false,
			sort: {
				sortBy: props.sortBy,
				rev: props.revSort,
			}
		};

		this.body_ref = React.createRef();
		this.table_ref = props.tableRef || React.createRef();
		this.tableId = generateUUID();

		this.mouseDown = this.mouseDown.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.changeSort = this.changeSort.bind(this);
		this.mainCheckbox = this.mainCheckbox.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.getSelected = this.getSelected.bind(this);
		this.isSelected = this.isSelected.bind(this);
		this.updateSelected = this.updateSelected.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.autoLoadNext = this.autoLoadNext.bind(this);
		this.getNextItems = this.getNextItems.bind(this);
	}

	componentDidMount() {
		if (this.props.mouseover !== false) {
			this.table_ref?.current.addEventListener("mousedown", this.mouseDown);
			this.table_ref?.current.addEventListener("mouseup", this.mouseUp);
			this.table_ref?.current.addEventListener("mouseleave", this.mouseUp);
		}
	}

	componentDidUpdate() {
		this.autoLoadNext();
	}

	autoLoadNext() {
		let body = this.body_ref.current;
		if (!body || !this.props.hasMore)
			return (false);

		let scroll_cont = body.querySelector(".infinite-scroll-component");
		if (scroll_cont && scroll_cont.scrollHeight <= scroll_cont.clientHeight + 100)
			this.getNextItems();
	}

	mouseDown(e) {
		if (this.props.checkbox && e.target.type === "checkbox")
			window.SCROLLTABLE_LASTMD = Date.now();
	}

	mouseUp() {
		if (this.props.checkbox)
			window.SCROLLTABLE_LASTMD = false;
	}

	changeSort(new_sort)
	{
		if (new_sort.sortBy !== this.state.sort.sortBy)
			new_sort.rev = false;
		this.setState({sort: new_sort});
	}

	mainCheckbox(e) {
		let items = this.getItems();
		let checked = e.target.checked;
		let new_items = [];

		if (checked && !this.props.onlySelected)
			new_items = [...items];
		this.updateSelected(new_items);
	}

	handleCheckbox(item, e) {
		if (e)
			e.stopPropagation();
		let new_items = [];
		let found = this.isSelected(item);

		let selected = this.getSelected();
		if (found === false)
			new_items = [...selected, {...item}];
		else {
			let dup = Array.from(selected);
			dup.splice(found.index, 1);
			new_items = [...dup];
		}
		this.updateSelected(new_items);
	}

	getSelected() {
		if (this.props.setSelected)
			return (this.props?.selected?.length ? this.props.selected : []);
		return (this.state.selected?.length ? this.state.selected : []);
	}

	isSelected(item) {
		let dup = this.getSelected();
		let index = 0;
		let check = dup.find((a, b) => {
			index = b;
			if (
				(!this.props.itemId && a === item)
				|| (a[this.props.itemId] === item[this.props.itemId])
			)
				return (a);
			return (false);
		});
		return (check ? {item: check, index} : false);
	}

	updateSelected(items) {
		if (this.props.setSelected)
			this.props.setSelected(items);
		else
			this.setState({ selected: items });
	}

	handleClick(item) {
		if (this.props.onItemClick)
			this.props.onItemClick(item);
		else if (this.props.checkbox !== false)
			this.handleCheckbox(item);
	}

	getItems() {
		let items = [...this.props.items];
		let cols = this.props.cols;
		if (!items)
			return ([]);
		if (this.state.sort.sortBy)
		{
			items = items.sort((a, b) => {
				let a_el = a[this.state.sort.sortBy];
				let b_el = b[this.state.sort.sortBy];

				if (typeof a_el === "number" && typeof b_el === "number")
				{
					if (a_el < b_el)
						return ((this.state.sort.rev ? 1 : -1));
					return ((this.state.sort.rev ? -1 : 1));
				}
				if (noAccent(a[this.state.sort.sortBy]).toLocaleLowerCase() < noAccent(b[this.state.sort.sortBy]).toLocaleLowerCase())
					return ((this.state.sort.rev ? 1 : -1));
				return ((this.state.sort.rev ? -1 : 1));
			});
		}

		if (this.props.filter && items.length)
			items = items.filter(this.props.filter);

		if (this.props.search?.length)
		{
			let reg = new RegExp(noAccent(this.props.search), "gi");
			items = items.filter(a => {
				for (let x in cols)
					if (noAccent(a[cols[x].name]).match(reg))
						return (a);
				return (false);
			});
		}

		if (this.props.max)
			items = items.slice(0, this.props.max);
		return (items);
	}

	getNextItems(...args) {
		if (this.props.getNextItems)
			this.props.getNextItems(...args)
	}

	hasMore() {
		if (typeof this.props.hasMore !== "undefined")
			return (this.props.hasMore);
		return (this.state.HasMore)
	}

	render() {
		let cols = this.props.cols;
		let items = this.getItems();
		let selected = this.getSelected();

		return (
			<div
				className={"table-cont scroll-table" + (this.props.className ? " " + this.props.className : "")}
				ref={this.table_ref}
				key={this.tableId}
			>
				{
					this.props.header !== false &&
						<div className="table-header">
							<TableHead
								cellSpacing={this.props.cellSpacing}
								isHeader={true}
								sort={this.state.sort}
								setSort={(new_sort) => this.changeSort(new_sort)}
								cols={cols}
								maxCols={this.props.maxCols}
								onCBChange={this.mainCheckbox}
								allSelected={(selected?.length === items.length || this.props.onlySelected) && selected?.length > 0 && items.length > 0}
								checkbox={this.props.checkbox}
								onlySelected={this.props.onlySelected}
								onCellChange={this.props.onCellChange}
								cellsProps={this.props.cellsProps}
							/>
						</div>
				}
				<div className="table-body" ref={this.body_ref}>
					<InfiniteScroll
						className="d-flex flex-wrap"
						scrollableTarget={this.body_ref?.current || false}
						dataLength={items?.length}
						next={this.getNextItems}
						hasMore={this.hasMore()}
						loader={<LoadCont className="w-100" text={t("Commons.LOADING")}/>}
						height={"100%"}
						endMessage={
							this.props.endText !== false &&
								<div className="w-100 infinite-scroll-end-msg mt-4">
									<p style={{ textAlign: "center" }}>
										<b>
											{
												this.props.SearchResults?.length === 0 ?
													(this.props.noResultText ? this.props.noResultText : t("Table.NO_CONTENT"))
													:
													(this.props.endText ? this.props.endText : t("Table.END_OF_TABLE"))
											}
										</b>
									</p>
								</div>
						}
					>
						<TableBody
							maxCols={this.props.maxCols}
							maxRows={this.props.maxRows}
							cellSpacing={this.props.cellSpacing || 8}
							activeId={this.props.activeId}
							onlySelected={this.props.onlySelected}
							isSelected={this.isSelected}
							items={items}
							onItemClick={this.props.onItemClick !== false ? this.handleClick : false}
							onCellChange={this.props.onCellChange}
							handleCheckbox={this.handleCheckbox}
							checkbox={this.props.checkbox}
							cols={this.props.cols}
							itemId={this.props.itemId}
							tableId={this.tableId}
							cellsProps={this.props.cellsProps}
							dragRows={true}
						/>
					</InfiniteScroll>
				</div>
			</div>
		);
	}
}

export function AsyncScrollTable(props) {

	const {isLoading, data} = useQuery(
		props.queryKey,
		props.queryFn,
		props.queryOpt,
	);

	if (isLoading)
		return (<div>{t("Commons.LOADING")}</div>);

	return (
		<ScrollTable {...props} items={data}/>
	);
}

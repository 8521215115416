import React from "react";
import { t } from "i18next";
import DocUploader from "../../DocUploader/DocUploader";
import useTemplate from "hooks/useTemplate";

export default function DocContentStep(props) {
	let doc = props.object;
	const { template } = useTemplate();

	return (
		<div className="inner-step-cont">
			<DocUploader
				primaryColor={template?.primaryColor()}
				companyId={doc.companyId()}
				focusOnLoaded={false}
				onChange={() => { }}
				doc={doc}
			/>
		</div>
	);
}

export function checkDocument(doc) {
	let errors = [];

	if (!doc.name())
		errors.push(t("Files.SET_NAME"));
	if (!doc.documentId() && !doc.documentFile())
		errors.push(t("Files.SELECT_FILE"));

	return (errors?.length ? errors : true);
}

import Button from "components/Button/Button";
import { t } from "i18next";
import React from "react";
import "./ServError.css";

export default function ServError(props) {
	return (
		<div
			className="cont-serv-error"
			style={{
				color: props.color,
			}}
		>
			<div className="broken-logo" style={props.logoStyle}></div>
			<div className="mt-4 error-title">
				{props.title || t("Desktop.ERROR_404")}
			</div>
			<div className="mt-1">
				{props.text || t("Desktop.PAGE_NOT_FOUND")}
			</div>
			{props.reloadBtn && (
				<div className="mt-1">
					<Button
						className="btn-blue"
						onClick={() => {
							sessionStorage.clear();
							window.location.reload();
						}}
					>
						{t("Desktop.RELOAD_PAGE")}
					</Button>
				</div>
			)}
		</div>
	);
}

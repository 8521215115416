import SwitchInput from "components/Inputs/Switch/SwitchInput";
import { t } from "i18next";
import React, { useState } from "react";

function OptionsStep(props) {
	const site = props.object;
	const [isCheked, setIsChecked] = useState(true);

	function change(elem) {
		let val = elem.target.checked;
		setIsChecked(val);
		site.duplicate(val);
	}

	return (
		<div className="inner-step-cont">
			<div className="d-flex">
				<SwitchInput
					disabled={true}
					className="w-100"
					checked={isCheked}
					onChange={change}
					label={t("Sites.DUP_POSTS")}
				/>
			</div>
		</div>
	);
}

export default OptionsStep;

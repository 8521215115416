import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";
import React, {useState} from "react";

export default function FormRangeOption({question}) {
	const default_max = 20;

	function getOption() {
		if (!question.Options?.length)
			return (question.newOption({MinValue: 1, MaxValue: default_max}));
		let opt = question.Options[0];
		return (opt);
	}

	const [min, setMin] = useState(getOption().MinValue);
	const [max, setMax] = useState(getOption().MaxValue || default_max);

	function setMinValue(val) {
		let opt = getOption();
		opt.MinValue = val;
		if (val >= max)
			setMax(val + 1);
		setMin(val);
	}

	function setMaxValue(val) {
		let opt = getOption();
		opt.MaxValue = val;
		if (val <= min)
			setMin(val - 1);
		setMax(val);
	}

	return (
		<div className="option-cont d-flex w-100">
			<div className="d-flex flex-column w-100 me-2">
				<div>{t("Forms.RANGE_MIN")}</div>
				<div>
					<FormInput
						childClassName="bg-color-secondary"
						externvalue={"true"}
						onChange={(value) => setMinValue(Number(value))}
						value={min}
						max={max}
						type="number"
					/>
				</div>
			</div>
			<div className="d-flex flex-column w-100">
				<div>{t("Forms.RANGE_MAX")}</div>
				<div>
					<FormInput
						childClassName="bg-color-secondary"
						externvalue={"true"}
						onChange={(value) => setMaxValue(Number(value))}
						value={max}
						min={min}
						type="number"
					/>
				</div>
			</div>
		</div>
	);
}

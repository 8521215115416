import Button from "components/Button/Button";
import "./panel.styles.css";

export const Panel = ({ children, title, styleOpen, onClose }) => {
	return (
		<div className="panel" style={styleOpen}>
			<div className="panel-wrapper">
				<div className="panel-cross" onClick={onClose}>
					×
				</div>
				<div className="drawer-title">{title}</div>
				{children}
			</div>
		</div>
	);
};

import React, {memo} from "react";
import {
	Inject
} from "@syncfusion/ej2-react-charts";

import {
	CircularGaugeComponent,
	AxesDirective,
	AxisDirective,
	PointersDirective,
	PointerDirective,
	RangeDirective,
	RangesDirective,
	Annotations,
	AnnotationsDirective,
	AnnotationDirective
} from "@syncfusion/ej2-react-circulargauge";

const GaugeComponent = memo(({chart, values, theme}) => {
	if (!values?.axes?.length)
		return (false);
	return (
		<CircularGaugeComponent
			centerY={chart.ChartTypeId === 19 ? "72%": ""}
			{...theme}
		>
			<Inject services={[Annotations]}/>
			<AxesDirective>
				{
					values.axes?.map((axe, axe_index) => {
						return (
							<AxisDirective key={axe_index} {...axe}>
								<RangesDirective>
									{
										axe.ranges.map((range, index) => {
											return (
												<RangeDirective key={index} {...range}/>
											);
										})
									}
								</RangesDirective>
								<PointersDirective>
									{
										axe.pointers.map((pointer, index) => {
											return (
												<PointerDirective key={index} {...pointer}/>
											);
										})
									}
								</PointersDirective>
								<AnnotationsDirective>
									{
										axe.annotations.map((annot, index) => {
											return (
												<AnnotationDirective key={index} {...annot}/>
											);
										})
									}
								</AnnotationsDirective>
							</AxisDirective>
						);
					})
				}
			</AxesDirective>
		</CircularGaugeComponent>
	);
});
GaugeComponent.displayName = "GaugeComponent";

export default GaugeComponent;

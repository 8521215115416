export default function gauge_chart_getter(chart) {
	let values = {
		background: "transparent",
		color: "yellow"
	};

	let label = [chart.options("Value"), chart.options("Label")].filter(a => a).join(" ");
	if (chart.chartTypeId() === 19) {
		values.axes = [{
			annotations: [{
				content: `<div style="color:#666666;font-size:35px;">${label}</div>`,
				label: chart.options("Label"),
				angle: 0,
				radius: "110%",
				zIndex: 1
			}],
			lineStyle: {
				width: 0
			},
			startAngle: 300,
			endAngle: 60,
			radius: "80%",
			labelStyle: {
				font: {
					size: "0px"
				}
			},
			majorTicks: {
				width: 0
			},
			minorTicks: {
				height: 0
			},
			minimum: chart.options("Min"),
			maximum: chart.options("Max"),
			ranges: [{
				start: chart.options("Min"),
				end: chart.options("Max"),
				radius: "90%",
				startWidth: chart.options("OutterThickness") || 30,
				endWidth: chart.options("OutterThickness") || 30,
				color: chart.options("SecondaryColor")
			}],
			pointers: [{
				type: "RangeBar",
				value: chart.options("Value"),
				radius: "90%",
				animation: {
					duration: 0
				},
				color: chart.options("PrimaryColor"),
				pointerWidth: chart.options("InnerThickness") || 30
			}, {
				radius: "90%",
				value: chart.options("Value"),
				color: "#424242",
				animation: {
					duration: 0
				},
				pointerWidth: 9,
				cap: {
					radius: 10,
					color: "#424242",
					border: {
						width: 0
					}
				}
			}]
		}];
	} else if (chart.chartTypeId() === 20) {
		values.axes = [{
			annotations: [{
				content: `<div style="color:#666666;font-size:35px;">${label}GB</div>`,
				label,
				angle: 180,
				radius: "0%",
				zIndex: 1
			}, {
				content: "<div style=\"color:#757575;font-size:15px;\">Used</div>",
				angle: 180,
				radius: "25%",
				textStyle: {
					fontFamily: "Roboto",
					color: "#9E9E9E",
					fontStyle: "Bold",
					fontWeight: "Regular",
					size: "14px"
				},
				zIndex: 1
			}],
			lineStyle: {
				width: 0
			},
			startAngle: 180,
			endAngle: 180,
			radius: "80%",
			labelStyle: {
				font: {
					size: "0px"
				}
			},
			majorTicks: {
				width: 0
			},
			minorTicks: {
				height: 0
			},
			minimum: chart.options("Min") || 0,
			maximum: chart.options("Max") || 100,
			ranges: [{
				start: chart.options("Min") || 0,
				end: chart.options("Max") || 100,
				radius: "80%",
				startWidth: chart.options("OutterThickness") || 30,
				endWidth: chart.options("OutterThickness") || 30,
				color: chart.options("SecondaryColor")
			}],
			pointers: [{
				type: "RangeBar",
				value: chart.options("Value") || 50,
				radius: "80%",
				color: chart.options("PrimaryColor"),
				animation: {
					duration: 0
				},
				pointerWidth: chart.options("InnerThickness") || 30
			}]
		}];
	}
	return (values);
}

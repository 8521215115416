import Button from "components/Button/Button";
import useTemplate from "hooks/useTemplate";
import React, { useState } from "react";
import { getChartTypesGroups } from "../../../../../../classes/Charts/Types/ChartTypes";
import { hexToCSSFilter } from "hex-to-css-filter";
import "./ChartTypeStep.css";
import { t } from "i18next";
/* eslint-disable */

export default function ChartTypeStep(props) {

	const chart = props.object;
	const groups = getChartTypesGroups();
	const [curType, setCurType] = useState(chart.chartTypeId() || false)
	const { template } = useTemplate()
	let hexfilter = hexToCSSFilter(template.primaryColor(), {
		acceptanceLossPercentage: 100,
		maxChecks: 1,
	})
	let filter_str = hexfilter.filter.replace(";", "");

	if (!props.isActive)
		return (false);

	const handleChange = (typeId) => {
		chart.chartTypeId(typeId);
		setCurType(typeId);
		props.instance.nextStep();
	}

	return (
		<div className="inner-step-cont gap-2">
			<div>
				{t("Indicators_creation.Indicator_Type")}
			</div>
			<hr />
			<div className="d-flex flex-column gap-4">
				{
					groups.map((a, b) => (
						<TypesGroup
							key={b}
							{...a}
							cssFilter={filter_str}
							curType={chart.chartTypeId()}
							setCurType={handleChange}
						/>
					))
				}
			</div>
		</div>
	)
}

function TypesGroup(props) {
	const { name, items } = props;
	const { curType, setCurType } = props;

	return (
		<div className="TypeGroup">
			<h4>{name}</h4>
			<div className="d-flex gap-3 flex-wrap">
				{
					items.map((a, b) => (
						<TypeTile
							key={b}
							{...a}
							cssFilter={props.cssFilter}
							isActive={curType === a.id}
							onClick={() => setCurType(a.id)}
						/>
					))
				}
			</div>
		</div>
	)
}

function TypeTile(props) {
	const { id, type, name, icon } = props;
	const { isActive, onClick } = props;
	return (
		<div className={"TypeTile p-1" + (isActive ? " active" : "")} >
			<Button className="unstyled flex-column" onClick={onClick}>
				<div className="type-image mx-auto" style={{
					backgroundImage: "url(" + icon + ")",
					filter: props.cssFilter
				}} />
				<div className="type-name">{name}</div>
			</Button>
		</div>
	)
}

export function checkChartType(item) {

	if (!item.chartTypeId())
		return ([t("ErrorMsg.Chart")]);
	return (true);
}

import { useState } from "react";
import { toast } from "react-toastify";
import { rm_double } from "includes/arrays";
import genRequest from "includes/request";
import { useQueryClient } from "react-query";
import Storage from "classes/Storage/Storage";
import formatDate from "includes/formatDate";
import { JSONDiff } from "includes/JSON";
import * as Sentry from "@sentry/react";

/**
 * @class
 * @example
 * const { account } = useAccount();
 * return (
 * 	<div>{ account.FirstName }</div>
 * );
 */
function useAccount() {
	const [account, setAccount] = useState(getCurAccount());
	const queryClient = useQueryClient();
	const storage = new Storage();
	const sess_storage = new Storage(null, null, sessionStorage);

	const getAccounts = () => {
		const accounts_string = storage.getItem("Accounts");
		let accounts = [];
		try {
			accounts = accounts_string;
		} catch (e) {
			accounts = [];
		}
		return accounts ? accounts : [];
	};

	const getLocalAccount = (login) => {
		let accounts = getAccounts();
		let cur_account = getCurAccount();
		let ret = accounts.find(
			(a) => a.Login === login || a.Login === cur_account?.Login
		);
		return ret ? ret : {};
	};

	const unread = (type, data) => {
		if (!type) return false;
		let session_account = getCurAccount();
		if (typeof data !== "undefined") {
			session_account["unreaded_" + type] = data;
			if (session_account) setCurAccount(session_account);
		}
		return session_account["unreaded_" + type];
	};

	/**
	 * Save current account un sessionStorage
	 * @param {object:JSON} userAccount
	 */
	const setCurAccount = (userAccount) => {
		let values = { ...account, ...userAccount };
		sess_storage.setItem("account", values);
		Sentry.setUser(getMinimalInfos(values));
		setAccount(values);
	};

	const getMinimalInfos = (user) => {
		if (!user) return null;
		let ret = {
			id: user.EmployesId,
			CompanyId: user.CompanyId,
			SiteId: user.SiteId,
			AspNetUserId: user.AspNetUserId,
			UserTemplateId: user.UserTemplateId,
			WorkFamillyId: user.WorkFamillyId,
			WorkFunctionId: user.WorkFunctionId,
			FirstConnexion: user.FirstConnexion,
			LastConnexion: user.LastConnexion,
			Role: user.Role,
			Token_Regeneration: user.Token_Regeneration,
			ByPass_Moderation: user.ByPass_Moderation,
			IsVisibleInApp: user.IsVisibleInApp,
			ClientVersion: process.env.REACT_APP_VERSION,
		};
		if (user.TempValues) ret.TempUser = getMinimalInfos(user.TempValues);
		return ret;
	};

	const saveInLocal = (userAccount) => {
		let accounts = getAccounts();

		if (accounts && accounts.length)
			for (let x in accounts) {
				if (accounts[x].Login === userAccount.Login) {
					accounts[x] = userAccount;
					break;
				}
			}
		else accounts.push(userAccount);
		storage.setItem("Accounts", accounts);
	};

	/**
	 * Add posts to unreadeds list
	 * @param {PostModel[]} posts
	 * @return {unreadeds[]}
	 */
	const setNewPosts = (posts) => {
		let local_account = getLocalAccount();
		let unreadeds = getUnreadedPosts();

		for (let x in posts)
			if (
				posts[x].EmployesId !== local_account.EmployesId &&
				posts[x].Draft === false &&
				posts[x].PostTypeId !== 8 // don"t save events
			)
				unreadeds = addNewPost(unreadeds, posts[x]);
		local_account.Unreadeds = unreadeds;
		saveInLocal(local_account);
		if (local_account) setCurAccount(local_account, true);
		return unreadeds;
	};

	const addNewPost = (posts, post) => {
		let unreadeds = posts;

		if (!unreadeds || !unreadeds.length) unreadeds = [];

		if (isReaded(post.PostId))
			unreadeds.push({
				PostId: post.PostId,
				PostTypeId: post.PostTypeId,
			});
		return unreadeds;
	};

	/**
	 * Return array of unreaded posts datas from account saved in localStorage
	 * @param {int} typeId get only unreaded posts for a specific PostTypeId
	 * @return {unreadeds[]}
	 * @example
	 * const { getUnreadedPosts } = useAccount();
	 *
	 * const unreadeds = getUnreadedPosts();
	 * // return example
	 * [{
	 *   PostId: 19856,
	 *   PostTypeId: 1
	 *  },
	 *  {
	 *   PostId: 20420,
	 *   PostTypeId: 4
	 *  },
	 *  ...
	 * ]
	 */
	const getUnreadedPosts = (typeId) => {
		let local_account = getLocalAccount();
		let cur_account = getCurAccount();
		let readeds = getReadedsPosts();
		let unreadeds = [];

		if (!local_account.Unreadeds) local_account.Unreadeds = [];
		if (!cur_account.Unreadeds) cur_account.Unreadeds = [];
		unreadeds = unreadeds.concat(
			local_account?.Unreadeds,
			cur_account?.Unreadeds
		);
		unreadeds = rm_double(unreadeds, "PostId");
		unreadeds = unreadeds.filter((a) => {
			for (let x in readeds)
				if (readeds[x].PostId === a.PostId) return false;
			if (typeId && a.PostTypeId !== typeId) return false;
			return true;
		});
		return unreadeds;
	};

	const getReadedsPosts = () => {
		let cur_account = getCurAccount();
		return cur_account.Readeds ? cur_account.Readeds : [];
	};

	/**
	 * Set a post as readed using postId
	 * @param {PostModel} post { PostId: 1654, PostTypeId: 1 }
	 * @return {boolean}
	 * @example
	 * const { setAsReaded } = useAccount();
	 *
	 * setAsReaded(<postId>);
	 */
	const setAsReaded = (post) => {
		let unreadeds = getUnreadedPosts();
		let local_account = getLocalAccount();
		let readeds = getReadedsPosts();

		if (!post) return false;
		for (let x in unreadeds)
			if (unreadeds[x].PostId === post.PostId) {
				unreadeds.splice(x, 1);
				local_account.Unreadeds = unreadeds;
			}
		readeds.push(post);
		local_account.Readeds = rm_double(readeds, "PostId");
		saveInLocal(local_account);
		setCurAccount(local_account);
		queryClient.invalidateQueries(["FetchLastsPosts"]);
		return true;
	};

	/**
	 * Check if the post with the provided postId has been readed
	 * @param {int} postId
	 * @param {boolean} only_cur_sess check only unreaded new posts from current session
	 * @return {boolean}
	 * @example
	 * const { isReaded } = useAccount();
	 *
	 * if (isReaded(<postId>))
	 *   return ("Readed")
	 * else
	 *   return ("Post not readed")
	 * });
	 */
	const isReaded = (postId, only_cur_sess) => {
		let unreadeds;

		if (only_cur_sess) unreadeds = getReadedsPosts();
		else {
			unreadeds = getUnreadedPosts();
		}
		let ret = unreadeds.find((a) => a.PostId === postId);
		return ret && only_cur_sess ? true : false;
	};

	const fetchInfos = (update_if_diff) => {
		let prom = Promise.all([fetchRole(), fetchRights(), fetchMe()]).then(
			(resp) => {
				let ret = {
					Role: resp[0],
					Rights: resp[1],
					Infos: resp[2][0],
				};

				if (update_if_diff) checkInfosDiff(ret.Infos);
				return ret;
			}
		);
		return prom;
	};

	const checkInfosDiff = (new_account) => {
		let cur = getCurAccount();

		if (cur.IsTemp) return false;
		if (JSONDiff(new_account, cur, ["LastConnexion"])) {
			let acc = { ...cur, ...new_account };
			setCurAccount(acc);
			saveInLocal(acc);
			queryClient.resetQueries(["Template", "CurPage"]);
		}
	};

	const fetchMe = async () => genRequest("Me");

	/**
	 * Fetch user role
	 * @return {promise} Return a string with the role when resolved
	 * @example
	 * const { fetchRole } = useAccount();
	 *
	 * fetchRole().then((role) => {
	 *   return (role)
	 * }, (e) => {
	 *   return ("Error fetching role", e);
	 * });
	 */
	const fetchRole = async () => {
		let prom = genRequest("Account/UserInfo").then((resp) => {
			let new_account = account;
			new_account.Role = resp.Role;
			return new_account.Role;
		});
		return prom;
	};

	/**
	 * Fetch user rights
	 * @return {promise} Return JSON rights
	 * @example
	 * const { fetchRights } = useAccount();
	 *
	 * fetchRights().then((role) => {
	 *   return (role)
	 * }, (e) => {
	 *   return ("Error fetching rights", e);
	 * });
	 */
	const fetchRights = () => genRequest("Me/GetUserRights");

	/**
	 * Check if the current user is superior or equal to provided role
	 * @return {int} -1 if inferior, 0 if equal, 1 if superior
	 * @example
	 * const { account, isSup } = useAccount();
	 *
	 * if (isSup("SiteAdmin"))
	 *   return ( <div>Welcome, { account.FirstName }</div> );
	 * else
	 *   return ( <div>FORBIDDEN</div> );
	 */
	const isSup = (role) => {
		let myRole = getCurAccount().Role;

		if (!role) return 1;

		if (myRole === "EloAdmin") return 1;

		if (role === "UserPublisher" || role === "UserNoPublisher") return 1;

		if (myRole === role) return 0;

		if (myRole === "SiteAdmin")
			if (
				role === "AllReaderPublisherRole" ||
				role === "AllReaderNoPublisherRole" ||
				role === "SuperAdmin"
			)
				return -1;

		if (myRole === "SuperAdmin")
			if (
				role === "AllReaderPublisherRole" ||
				role === "AllReaderNoPublisherRole" ||
				role === "SuperAdmin" ||
				role === "SiteAdmin"
			)
				return 1;
		return -1;
	};

	const isAdmin = () => isSup("SiteAdmin") >= 0;

	const setSettings = (obj, value) => {
		let acc = getCurAccount();

		if (!acc.Settings) acc.Settings = {};
		if (typeof obj === "string") {
			acc.Settings[obj] = value;
		} else {
			acc.Settings = obj;
		}
		setCurAccount(acc);
		saveInLocal(acc);
	};

	const getSettings = (name) => {
		let acc = getCurAccount();
		let setts = acc.Settings;
		if (!setts) return name ? false : {};
		if (name) return setts[name] ? setts[name] : false;
		return setts ? setts : false;
	};

	const resetToken = () => {
		let acc = getCurAccount();
		acc.Token_Regeneration = true;
		if (acc.Token) {
			acc.Token = false;
			setCurAccount(acc);
			saveInLocal(acc);
		}
		return updateInfos(acc);
	};

	function updateLastCo() {
		let new_account = getCurAccount(true);
		let date = formatDate();
		new_account.LastConnexion = date;
		if (!new_account.FirstConnexion) new_account.FirstConnexion = date;
		setCurAccount(new_account);
		return genRequest("Me/UpdateLastConnexion", null, "post");
	}

	function updateInfos(values) {
		return genRequest("Me", values, "put");
	}

	/**
	 * Clear sessionStorage and reload the page
	 * @example
	 * const { account, isSup, disconnect } = useAccount();
	 *
	 * if (isSup("SiteAdmin"))
	 *   return ( <div>Welcome, { account.FirstName }</div> );
	 * else { // The user is disconnected if he is not at least SiteAdmin
	 *   disconnect();
	 *   return (false);
	 * }
	 */
	const disconnect = (message, timeout) => {
		if (message) toast(message, { autoClose: timeout || 5000 });
		setTimeout(() => {
			sessionStorage.clear();
			window.location.replace("/#disconnected");
		}, timeout || 0);
	};

	return {
		account: account,
		setAccount: setCurAccount,
		setNewPosts,
		getUnreadedPosts,
		setAsReaded,
		isReaded,
		unread,
		isSup,
		isAdmin,
		fetchInfos,
		fetchRole,
		fetchRights,
		disconnect,
		setSettings,
		getSettings,
		resetToken,
		updateLastCo,
	};
}

export function getCurAccount(forceCur) {
	const sess_storage = new Storage(null, null, sessionStorage);
	const accountString = sess_storage.getItem("account");
	if (!accountString) return {};

	let userAccount = accountString;
	if (userAccount.TempValues && !forceCur)
		return {
			...userAccount.TempValues,
			Settings: userAccount.Settings,
			IsTemp: true,
		};
	return userAccount;
}

export default useAccount;

import React from "react";
import Assignator from "components/Assignator/Assignator";

export default function FormAssignStep(props) {
	let form = props.object;

	if (!props.isActive)
		return (false);

	function handleUpdate(values) {
		form.affectations(values);
	}

	return (
		<Assignator initAssigns={form.affectations()} assigns={form.affectations()} handleUpdate={handleUpdate}/>
	);
}

export function checkAssigns() {
	return (true);
}

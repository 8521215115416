import preg_replace from "./preg_replace";
import { isSafari } from "./navigators";

export default function formatDate(date, format) {
	let date_obj;

	if (!date)
		date_obj = new Date();
	else
		date_obj = new Date(date);

	if (isSafari() && typeof date == "string")
		date_obj.setTime(date_obj.getTime() + date_obj.getTimezoneOffset() * 60 * 1000);
	let find = ["Y", "M", "W", "D", "h", "m", "s"];
	let replace = [date_obj.getFullYear(),
		(((date_obj.getMonth() + 1 < 10) ? "0" : "") + (date_obj.getMonth() + 1)),
		(((getWeek(date_obj) + 1 < 10) ? "0" : "") + (getWeek(date_obj) + 1)),
		(((date_obj.getDate() < 10) ? "0" : "") + date_obj.getDate()),
		(((date_obj.getHours() < 10) ? "0" : "") + date_obj.getHours()),
		(((date_obj.getMinutes() < 10) ? "0" : "") + date_obj.getMinutes()),
		(((date_obj.getSeconds() < 10) ? "0" : "") + date_obj.getSeconds())
	];

	if (format)
		return preg_replace(find, replace, format);
	else
		return (
			date_obj.getFullYear() + "-"
			+ (((date_obj.getMonth() + 1 < 10) ? "0" : "")
			+ (date_obj.getMonth() + 1)) + "-"
			+ (((date_obj.getDate() < 10) ? "0" : "")
			+ date_obj.getDate()) + "T"
			+ (((date_obj.getHours() < 10) ? "0" : "")
			+ date_obj.getHours()) + ":"
			+ (((date_obj.getMinutes() < 10) ? "0" : "")
			+ date_obj.getMinutes()) + ":"
			+ (((date_obj.getSeconds() < 10) ? "0" : "")
			+ date_obj.getSeconds())
		);
}

function getWeek(input_date)
{
	let date = new Date(input_date.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
	// January 4 is always in week 1.
	let week1 = new Date(date.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 -
		3 + (week1.getDay() + 6) % 7) / 7);
}

export function dateAdd (src, obj) {
	let date = new Date(src);
	if (obj) {
		if (obj.years)
			date.setFullYear(date.getFullYear() + obj.years);
		if (obj.months)
			date.setMonth(date.getMonth() + obj.months);
		if (obj.weeks)
			date.setDate(date.getDate() + 7 * obj.weeks);
		if (obj.days)
			date.setDate(date.getDate() + obj.days);
		if (obj.hours)
			date.setHours(date.getHours() + obj.hours);
		if (obj.minutes)
			date.setMinutes(date.getMinutes() + obj.minutes);
		return date;
	}
	return (false);
}

export function reverseDate(date, langFrom) {
	date = date.split(/-|\//);
	if (langFrom === "en")
		return (date[1] + "-" + date[2] + "-" + date[0] + "");
	else if (langFrom === "enBdd")
		return (date[1] + "-" + date[0] + "-" + date[2] + "");
	else if (langFrom === "fr")
		return (date[2] + "-" + date[0] + "-" + date[1] + "");
	else
		return (date[2] + "-" + date[1] + "-" + date[0] + "");
}

export function dayName(dayId, short) {
	let days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	let daysShort = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
	if (days[dayId])
	{
		if (short)
			return (daysShort[dayId]);
	}
	else
		return (days[dayId]);
	return (0);
}

export function monthName(monthId, short) {
	let months = [null,
		"Janvier", "Fevrier", "Mars",
		"Avril", "Mai", "Juin",
		"Juillet", "Août", "Septembre",
		"Octobre", "Novembre", "Décembre"
	];
	let monthsShort = [null,
		"Jan", "Fev", "Mars",
		"Avril", "Mai", "Juin",
		"Juil", "Août", "Sept",
		"Oct", "Nov", "Déc"
	];
	if (months[monthId])
	{
		if (short)
			return (monthsShort[monthId]);
	}
	else
		return (months[monthId]);
	return (0);
}

export const daysToMonths = (date) => {
	let month = date.getMonth();
	let year = date.getFullYear();

	return (new Date(year, month + 1, 0).getDate());
};

const UserProps = [
	{
		id: "LastName",
		title: "Nom",
		visible: true,
	},
	{
		id: "FirstName",
		title: "Prénom",
		visible: true,
	},
	{
		id: "Login",
		title: "Login",
		visible: true,
	},
	{
		id: "Role",
		title: "Role",
		visible: true,
	},
	{
		id: "CompanyId",
		title: "CompanyId",
		role: "EloAdmin",
	},
	{
		id: "SiteName",
		title: "Site",
		visible: true,
	},
	{
		id: "SiteId",
		title: "Identifiant du site",
		role: "EloAdmin",
	},
	{
		id: "EmployesId",
		title: "Identifiant de l'utilisateur",
		visible: true,
	},
	{
		id: "WFY",
		title: "Famille",
		visible: true,
	},
	// {
	// 	id: "WorkFamillyId",
	// 	title: "WorkFamillyId",
	// 	role: "EloAdmin"
	// },
	{
		id: "WFU",
		title: "Fonction",
		visible: true,
	},
	// {
	// 	id: "WorkFunctionId",
	// 	title: "WorkFunctionId",
	// 	role: "EloAdmin"
	// },
	// {
	// 	id: "Display_Mail",
	// 	title: "Visibilité du mail",
	// 	visible: true
	// },
	// {
	// 	id: "Display_Phone",
	// 	title: "Visibilité du téléphone",
	// 	visible: true
	// },
	{
		id: "Mail",
		title: "Mail",
		visible: true,
	},
	{
		id: "Telephone",
		title: "Telephone",
		visible: true,
	},
	// {
	// 	id: "FirstConnexion",
	// 	title: "Première connexion",
	// 	visible: true,
	// },
	{
		id: "LastConnexion",
		title: "Dernière connexion",
		visible: true,
	},
	// {
	// 	id: "HiredDate",
	// 	title: "HiredDate",
	// 	visible: false,
	// },
	// {
	// 	id: "Hobby",
	// 	title: "Hobby",
	// 	visible: true,
	// },
	// {
	// 	id: "IsVisibleInApp",
	// 	title: "IsVisibleInApp",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "LanguageId",
	// 	title: "LanguageId",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "LastChange_AuthorId",
	// 	title: "LastChange_AuthorId",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "LastChanged",
	// 	title: "LastChanged",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "Sex",
	// 	title: "Sex",
	// 	visible: false,
	// },
	// {
	// 	id: "Token_Regeneration",
	// 	title: "Token_Regeneration",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "AspNetUserId",
	// 	title: "AspNetUserId",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "ByPass_Moderation",
	// 	title: "ByPass_Moderation",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "CompanyRankId",
	// 	title: "CompanyRankId",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "UserTemplateId",
	// 	title: "UserTemplateId",
	// 	role: "EloAdmin",
	// },
	// {
	// 	id: "Workplace",
	// 	title: "Workplace",
	// 	visible: false,
	// },
];

export const getUserProps = (isSup) => {
	return UserProps.filter(
		(a) => a.visible !== false && (!a.role || (isSup && isSup(a.role) >= 0))
	);
};

export default UserProps;

/**
*	Return a string without accent
* @param {string} str
*/
function noAccent (str) {
	if (!str)
		return ("");
	if (typeof str !== "string")
		return (str.toString());
	let accent = [
		/[\300-\306]/g, /[\340-\346]/g, // A, a
		/[\310-\313]/g, /[\350-\353]/g, // E, e
		/[\314-\317]/g, /[\354-\357]/g, // I, i
		/[\322-\330]/g, /[\362-\370]/g, // O, o
		/[\331-\334]/g, /[\371-\374]/g, // U, u
		/[\321]/g, /[\361]/g, // N, n
		/[\307]/g, /[\347]/g, // C, c
	];
	let noaccent = ["A", "a", "E", "e", "I", "i", "O", "o", "U", "u", "N", "n", "C", "c"];
	let ret = str;

	for (let i = 0; i < accent.length; i++)
		ret = ret.replace(accent[i], noaccent[i]);
	return (ret);
}

export default noAccent;

import React, { useEffect, useState, useCallback, useRef } from "react";
import Button from "components/Button/Button";
import FormInput from "components/Inputs/FormInput";
import "./ChartEditTable.css";
import generateUUID from "includes/UUID";
import ColorPicker from "components/ColorPicker/ColorPicker";
import useTemplate from "hooks/useTemplate";
import { gen_color_palette, randomColor } from "includes/Colors";
import DraggableTable from "components/ScrollTable/DraggableTable";
import { t } from "i18next";

export default function ChartEditTable(props) {
	const { template } = useTemplate();
	const palette = gen_color_palette(template.primaryColor(), 8);
	const [Cols, setCols] = useState([]);
	const [Rows, setRows] = useState([]);
	const table_ref = useRef();
	const scrollTo = useRef();
	const Sizes = useRef({
		x: 3,
		y: 3,
	});
	const dragEnd = useRef();
	const initied = useRef();
	const force_update = useRef();
	const [LastGen, setLastGen] = useState(false);

	const handleSetRows = (rows) => {
		force_update.current = Date.now();
		setRows(rows);
	};

	const handleSetCols = (cols) => {
		force_update.current = Date.now();
		setCols(cols);
	};

	const updateCell = useCallback(
		(force_regen) => {
			let values = false;
			let getter = props.chart.SFChartType.valuesGetter;

			if (!table_ref?.current) return false;
			if (getter) values = getter(props.chart, table_ref.current);
			dragEnd.current = false;
			setLastGen(Date.now());
			if (props.onChange)
				props.onChange({
					// Cols: Cols,
					type: props.chart.SFChartType,
					forced: force_regen,
					Values: values,
					fromDrag: dragEnd.current,
				});
		},
		[props]
	);

	const genRow = useCallback(
		(force_cols, force_rows) => {
			let rows = force_rows || Rows;
			let row = {
				rowId: generateUUID(),
				component: EditCell,
				headComponent: EditCell,
				type: "row",
				color: palette[rows.length] || randomColor(),
			};
			let cols = force_cols || Cols;
			cols.forEach((a) => {
				row[a.name] = null;
			});
			return row;
		},
		[Rows, Cols, palette]
	);

	const addRow = () => {
		let rows = [...Rows, genRow()];
		Sizes.current = {
			...Sizes.current,
			y: rows.length,
		};
		setRows(rows);
		setScrollTo(null, Infinity);
	};

	const genCol = useCallback(
		(force_rows) => {
			let id = generateUUID();
			let rows = force_rows || Rows;
			let colName = "col-" + id;
			let col = {
				colId: id,
				title: id,
				name: colName,
				component: EditCell,
				headComponent: EditCell,
				type: "col",
			};

			let new_rows = rows.map((a) => {
				return {
					...a,
					[colName]: null,
				};
			});
			return { col, rows: new_rows };
		},
		[Rows]
	);

	const addCol = () => {
		let ret = genCol();
		setRows(ret.rows);
		let cols = [...Cols, ret.col];
		Sizes.current = {
			x: cols.lenght,
			y: ret.rows.length,
		};
		setCols(cols);
		setScrollTo(Infinity, null);
	};

	const genTable = useCallback(
		(nbr_cols, nbr_rows) => {
			let cols = [];
			let rows = [];

			for (let x = 0; x < nbr_rows; x++)
				rows.push(genRow(cols, rows, x + 1));
			for (let x = 0; x < nbr_cols; x++) {
				let ret = genCol(rows, cols, x + 1);
				rows = ret.rows;
				cols.push(ret.col);
			}
			Sizes.current = {
				x: cols.lenght,
				y: rows.lenght,
			};
			setCols(cols);
			setRows(rows);
		},
		[setCols, setRows, genCol, genRow]
	);

	const setScrollTo = (x, y) => (scrollTo.current = { x, y });

	const autoScroll = (x, y) => {
		let table = table_ref?.current;

		if (!table) return false;
		if (x !== undefined && x !== null)
			table.scrollLeft = x === Infinity ? table.scrollWidth + 1000 : x;
		if (y !== undefined && y !== null)
			table.scrollTop = y === Infinity ? table.scrollHeight + 1000 : y;
		scrollTo.current = false;
	};

	const onDragEnd = (e) => {
		if (e.source?.droppableId !== e.destination?.droppableId) return false;
		force_update.current = Date.now();
		dragEnd.current = true;
		if (e.source?.droppableId === "TableHead") reorderCols(e);
		else reorderRows(e);
	};

	const reorderCols = (e) => {
		let newCols = [...Cols];
		let from_index = e.source?.index;
		let to_index = e.destination?.index;

		const [removed] = newCols.splice(from_index, 1);
		newCols.splice(to_index, 0, removed);
		setCols(newCols);
		if (props.onReorder)
			props.onReorder({
				type: "cols",
				items: newCols,
			});
	};

	const reorderRows = (e) => {
		let newRows = [...Rows];
		let from_index = e.source?.index;
		let to_index = e.destination?.index;

		const [removed] = newRows.splice(from_index, 1);
		newRows.splice(to_index, 0, removed);
		setRows(newRows);
		if (props.onReorder)
			props.onReorder({
				type: "rows",
				items: newRows,
			});
	};

	const initTable = useCallback(() => {
		if (props.initValues) {
			let cell_props = {
				component: EditCell,
				headComponent: EditCell,
			};
			setCols(
				props.initValues.Cols.map((a) => ({ ...a, ...cell_props }))
			);
			setRows(
				props.initValues.Rows.map((a) => ({ ...a, ...cell_props }))
			);
		} else genTable(3, 3);
	}, [setCols, setRows, genTable, props]);

	useEffect(() => {
		if (!initied?.current) {
			initTable();
			initied.current = true;
		}
		if (scrollTo.current)
			autoScroll(scrollTo.current.x, scrollTo.current.y);
		if (force_update.current || props.forceRefresh) {
			force_update.current = false;
			updateCell(true);
		}
	}, [props, genTable, updateCell, initTable]);

	return (
		<div className="ChartEditTable">
			<div
				className="d-flex gap-2"
				style={{ margin: "0px -0.5rem", width: "calc(100% + 0.5rem)" }}
			>
				<DraggableTable
					tableRef={table_ref}
					className="sticky dark-bg"
					cols={Cols}
					setCols={handleSetCols}
					items={Rows}
					setItems={handleSetRows}
					cellSpacing={"0.5rem"}
					itemId={"rowId"}
					onCellChange={updateCell}
					endText={false}
					cellsProps={{
						chart: props.chart,
						lastGen: LastGen,
					}}
					lastGen={LastGen}
					header={props.header}
					maxCols={props.maxCols}
					maxRows={props.maxRows}
					dragRows={true}
					dragCols={true}
					onItemClick={false}
					checkbox={false}
					onDragEnd={onDragEnd}
					ctxMenuHandles={{
						genColumn: genCol,
						genRow,
					}}
				/>
				{props.addCol !== false &&
					(!props.maxCols || Cols.length < props.maxCols) && (
						<div className="mt-2">
							<Button
								className="table-add-btn btn-bg"
								onClick={addCol}
							>
								+
							</Button>
						</div>
					)}
			</div>
			{props.addRow !== false &&
				(!props.maxRows || Rows.length < props.maxRows) && (
					<div className="ms-0 mt-0">
						<Button
							className="table-add-btn btn-bg"
							onClick={addRow}
						>
							+
						</Button>
					</div>
				)}
		</div>
	);
}

const EditCell = (props) => {
	const { item, chart } = props;
	const { col, colIndex, rowIndex, isHead, onCellChange } = props;

	const getValue = () => {
		if (item.type === "col") return item.value || "";
		return item[col.name] || "";
	};

	const handleChange = (value) => {
		if (typeof value !== "undefined") {
			if (item.type === "col") item.value = value;
			else item[col.name] = value;
		}
		onCellChange(false);
	};

	const genPH = () => {
		let axeY = chart.options("AxeY");
		if (item.type === "col" && rowIndex === 0)
			return chart.options("AxeX") || "Colonne " + colIndex;
		if (item.type === "row") {
			if (colIndex === 0) return "Label";
			if (axeY) return axeY;
		}
		return t("Indicators_creation.Indicator_Value");
	};

	if (colIndex === 0 && !isHead)
		return (
			<RowTitle
				onChange={handleChange}
				getValue={getValue}
				genPH={genPH}
				{...props}
			/>
		);
	return (
		<FormInput
			type={colIndex > 0 && !isHead ? "number" : ""}
			className="w-100"
			value={getValue()}
			onChange={handleChange}
			placeholder={genPH()}
			externvalue={"true"}
		/>
	);
};

const RowTitle = (props) => {
	const { onChange, getValue, genPH } = props;
	const { template } = useTemplate();
	const { item } = props;

	const changeColor = (val) => {
		if (item.color !== val) {
			item.color = val;
			onChange();
		}
	};

	return (
		<div className="row-title d-flex justify-content-between align-items-center">
			<FormInput
				className="w-100"
				value={getValue()}
				onChange={onChange}
				placeholder={genPH()}
			/>
			<ColorPicker
				mainColor={template.primaryColor()}
				onChange={changeColor}
				value={item.color}
				enableOpacity={false}
			/>
		</div>
	);
};

export const Card = (props) => {
	const { Title, Txt, RssThumbmail, IsDeletable } = props.item;
	return (
		<>
			{!IsDeletable && (
				<div
					className="post-tile p-2 gap-2 tile-head-display no-image"
					onClick={() => props.setPreviewPost(props.item)}
				>
					<div className="TileImage">
						<div
							className="post-image w-100"
							style={{ backgroundImage: `url(${RssThumbmail})` }}
						></div>
					</div>
					<div className="post-content">
						<div className="post-title font-bold">{Title}</div>
						<div className="post-text mt-1">{Txt}</div>
					</div>
				</div>
			)}
		</>
	);
};

import React, {useState} from "react";
import { toast } from "react-toastify";
import MarkdownEditor from "components/MardownEditor/MarkdownEditor";
import { t } from "i18next";
import Button from "components/Button/Button";
import LoadCont from "components/LoadCont/LoadCont";

export default function PostComplementStep(props) {
	const [isDeleting, setIsDeleting] = useState(false);
	let post = props.object;
	let resp = post.response();
	let hasResponse = post?.postId() && resp.postId();

	const confirmDelete = () => {
		toast(
			<RmComplementToast
				onConfirm={handleDelete}
				onCancel={() => {}}
			/>
		);
	};

	const handleDelete = () => {
		if (!hasResponse)
			return (false);
		setIsDeleting(true);
		let req = post.deleteResponse()
			.then(() => {
				hasResponse = post?.postId() && resp.postId();
				setIsDeleting(false);
				props.refreshPreview();
			}, resetResponse);
		return (req);
	};

	const resetResponse = () => {
		hasResponse = post?.postId() && resp.postId();
		resp = post.setResponse("", null, true);
		setIsDeleting(false);
		props.refreshPreview();
	};

	if (!props.isActive)
		return (false);

	if (isDeleting)
		return (<LoadCont text={t("Posts.DELETING_COMPLEMENT")}/>);

	return (
		<div className="inner-step-cont">
			<div className="mt-3 px-2">
				<div className="d-flex justify-content-between">
					<h4>
						{t("Posts.POST_RESPONSE")}
					</h4>
					{
						hasResponse &&
							<div>
								<Button className="btn-red" onClick={confirmDelete}>{t("Commons.DELETE")}</Button>
							</div>
					}
				</div>
				<MarkdownEditor
					initialValue={resp ? resp.txt() : ""}
					onChange={(value) => {
						let resp = post.response();
						if (!resp)
							post.setResponse("");
						post.setResponse(value);
						props.refreshPreview();
					}}
				/>
			</div>
		</div>
	);
}

const RmComplementToast = ({onCancel, onConfirm}) => (
	<div>
		<h4>{t("Posts.DELETE_COMPLEMENT")}</h4>
		<div>{t("Posts.DELETE_COMPLEMENT_MSG")}</div>
		<div>{t("Posts.DELETE_COMPLEMENT_MSG1")}</div>
		<div className="d-flex gap-2 mt-2">
			<Button className="btn-bg" onClick={onCancel}>{t("Commons.CANCEL")}</Button>
			<Button className="btn-red" onClick={onConfirm}>{t("Commons.CONFIRM")}</Button>
		</div>
	</div>
);

import AddEventTour from "./tours/events/AddEventTour";
import PageHeadBarHelper from "./tours/general/PageHeadHelper";
import UIHelperTour from "./tours/general/UIHelperTour";
import PagesTour from "./tours/PagesTour";
import AddPostTour from "./tours/posts/AddPostTour";
import PostsUITour from "./tours/posts/PostsUITour";

const TutorialsSummary = [
	{
		title: "Pages",
		descriptions: "Présentation générale des pages",
		tour: PagesTour
	},
	{
		title: "Interface",
		description: "Présentation général de l'interface d'OnlyAll",
		tour: UIHelperTour,
		video: true,
		childs: [
			{
				title: "Menu lateral",
				childs: [
					{
						title: "Présentation des pages"
					},
					{
						title: "Page de profil",
						description: "Modifier vos informations et accédez à vos contenus"
					},
					{
						title: "Menu du profil",
						description: "Accédez a la page des réglages, thême sombre, langue ..."
					}
				]
			},
			{
				title: "En-tête de page",
				tour: PageHeadBarHelper
			}
		]
	},
	{
		title: "Configuration",
		description: "Apparence, métiers, groupes, catégories, sites, réactions",
		childs: [
			{
				title: "Apparence",
				description: "Identité visuelle, images de chargement, fonctionnalitées",
				childs: [
					{
						title: "Comment changer de logo ?"
					},
					{
						title: "Comment changer les images de chargement ?"
					},
					{
						title: "Comment gérer les fonctionnalitées de l'application ?"
					},
					{
						title: "Comment modifier le nom des menus de l'application ?"
					}			
				]
			},
			{
				title: "Métiers",
				description: "Familles et fonctions",
				childs: [
					{
						title: "Comment ajouter une famille de métier ?"
					},
					{
						title: "Comment ajouter une fonction métier ?"
					},
					{
						title: "Comment modifier une famille ou fonction métier ?"
					},
					{
						title: "Comment supprimer une famille ou fonction métier ?"
					},
					{
						title: "Comment assigner une famille ou un métier à un utilisateur ?"
					}
				]
			},
			{
				title: "Groupes d'utilisateurs",
				description: "",
				childs: [
					{
						title: "Comment créer un nouveau groupe d'utilisateurs ?"
					},
					{
						title: "Comment définir ou gérer les membres d'un groupe d'utilisateurs ?"
					},
					{
						title: "Comment modifier un groupe d'utilisateurs ?"
					},
					{
						title: "Comment supprimer un groupe d'utilisateurs ?"
					}
				]
			},
			{
				title: "Catégories",
				description: "Catégorisation des publications, documents, évènements et indicateurs",
				childs: [
					{
						title: "Comment créer une nouvelle catégorie ?"
					},
					{
						title: "Comment définir le type d'une catégorie ?"
					},
					{
						title: "Comment modifier une catégorie ?"
					},
					{
						title: "Comment dupliquer une catégorie ?"
					},
					{
						title: "Comment supprimer une catégorie ?"
					}
				]
			},
			{
				title: "Sites",
				description: "",
				childs: [
					{
						title: "Comment créer un nouveau site ?"
					},
					{
						title: "Comment modifier un site ?"
					},
					{
						title: "Comment supprimer un site ?"
					},
					{
						title: "Comment ajouter un utilisateur à un site ?"
					}
				]
			},
			{
				title: "Réactions",
				description: "",
				childs: [
					{
						title: "Comment définir les réactions par défaut"
					}
				]
			}
		]
	},
	{
		title: "Publications",
		description: "News, articles, annonces, carrières, alertes",
		tour: PostsUITour,
		childs: [
			{
				title: "News",
				childs: [
					{
						title: "Comment rédiger une news ?",
						description: "Apprenez a créer une publication",
						tour: AddPostTour,
						video: true
					},
					{
						title: "Comment modifier une news ?"
					},
					{
						title: "Comment enregistrer une news en brouillon ?"
					},
					{
						title: "Comment modérer une news ?",
						childs: [
							{
								title: "Comment activer la modération des news ?"
							},
							{
								title: "Comment refuser une news ?"
							},
							{
								title: "Comment accepter une news ?"
							},
						]
					},
					{
						title: "Comment supprimer une news ?"
					},
					{
						title: "Comment définir les cibles d'une news ?",
						description: "Publication par sites ou par groupes"
					},
					{
						title: "Comment définir les option de publication d'une news ?",
						description: "Publication différée, publication prioritaire, notification ..."
					}
				]
			},
			{
				title: "Articles",
				childs: [
					{
						title: "Comment rédiger un article ?"
					},
					{
						title: "Comment modifier un article ?"
					},
					{
						title: "Comment supprimer un article ?"
					}
				]
			},
			{
				title: "Alertes",
				childs: [
					{
						title: "Comment créer une alerte ?"
					},
					{
						title: "Comment définir les cibles d'une alerte ?"
					}
				]
			},
			{
				title: "Commentaires",
				childs: [
					{
						title: "Comment activer/desactiver les commentaires des publications ?"
					},
					{
						title: "Comment voir les commentaires d'une publication ?"
					},
					{
						title: "Comment modifier mon commentaire ?"
					},
					{
						title: "Comment supprimer un commentaire d'une publication ?"
					}
				]
			},
			{
				title: "Statistiques de la publication",
				childs: [
					{
						title: "Comment voir les statistiques d'une publication ?"
					}
				]
			},
			{
				title: "Recadreur d'image",
				childs: [
					{
						title: "Comment recadrer une image ?"
					}
				]
			},
			{
				title: "Editeur de texte",
				childs: [
					{
						title: "Comment utiliser l'éditeur de texte ?"
					},
					{
						title: "Comment activer l'éditeur de texte avancé ?"
					}
				]
			},
			{
				title: "Pièces jointes",
				description: "Documents et formulaires reliés à la publication",
				childs: [
					{
						title: "Comment relier des documents à une publication ?"
					},
					{
						title: "Comment relier des formulaires à une publication ?"
					}
				]
			},
			{
				title: "Options de publication",
				childs: [
					{
						title: "Comment définir les cibles d'une publication ?",
						description: "Publication par sites ou par groupes"
					},
					{
						title: "Comment publier en différé ?",
					},
					{
						title: "Comment passer une publication en prioritaire ?",
					},
					{
						title: "Comment gérer la notification d'une publication ?"
					},
					{
						title: "Comment sélectionner le type de réaction d'une publication ?"
					}
				]
			},
			{
				title: "Modération des publications",
				childs: [
					{
						title: "Comment gérer la modération des publications ?"
					},
					{
						title: "Comment voir les publications en attente de validation ?"
					},
					{
						title: "Comment voir les publications modérés ?"
					},
					{
						title: "Comment valider une publication ?"
					},
					{
						title: "Comment refuser une publication ?"
					}
				]
			}
		]
	},
	{
		title: "Documents",
		childs: [
			{
				title: "Comment ajouter un document ?"
			},
			{
				title: "Comment modifier un document ?"
			},
			{
				title: "Comment supprimer un document ?"
			},
			{
				title: "Comment définir les cibles pour un document ?",
				description: "Publication par sites ou par groupes"
			},
		]
	},
	{
		title: "Indicateur",
		childs: [
			{
				title: "Comment ajouter un indicateur ?"
			},
			{
				title: "Comment modifier un indicateur ?"
			},
			{
				title: "Comment supprimer un indicateur ?"
			},
			{
				title: "Comment selectionner des catégories pour un indicateur ?",
			}
		]
	},
	{
		title: "Agenda",
		description: "Gestion d'évènements",
		childs: [
			{
				title: "Comment ajouter un évènement ?",
				tour: AddEventTour
			},
			{
				title: "Comment modifier un évènement ?"
			},
			{
				title: "Comment supprimer un évènement ?"
			},
			{
				title: "Comment selectionner des catégories pour un évènement ?",
			},
			{
				title: "Comment gérer la participation des utilisateurs aux évènements ?",
			},
			{
				title: "Comment utiliser un évènement comme modèle ?",
				description: "Dupliquer un évènement"
			}
		]
	},
	{
		title: "Contacts",
		description: "Gestion des utilisateurs",
		childs: [
			{
				title: "Comment ajouter un utilisateur ?"
			},
			{
				title: "Comment modifier un utilisateur ?"
			},
			{
				title: "Comment supprimer un utilisateur ?"
			},
			{
				title: "Comment selectionner le site d'un utilisateur ?",
			},
			{
				title: "Comment selectionner des groupes pour un utilisateur ?",
			},
			{
				title: "Comment selectionner la famille et fonction métier pour un utilisateur ?",
			},
			{
				title: "Comment changer le rôle d'un utilisateur ?",
			},
			{
				title: "Comment ajouter un document personnel à un utilisateur ?",
			},
			{
				title: "Comment réinitialiser le mot de passe d'un utilisateur ?",
			},
			{
				title: "Comment contacter un utilisateur ?",
			},
			{
				title: "Exportation des contacts",
				childs: [
					{
						title: "Comment exporter la liste des utilisateurs ?",
					},
					{
						title: "Comment selectionner des utilisateurs a exporter ?"
					},
					{
						title: "Comment exporter la liste des utilisateurs actifs / inactifs ?"
					}
				]
			}
		]
	},
	{
		title: "Formulaires",
		childs: [
			{
				title: "Comment créer un nouveau formulaire ?"
			},
			{
				title: "Comment modifier un formulaire ?"
			},
			{
				title: "Comment supprimer un formulaire ?"
			},
			{
				title: "Comment utiliser l'éditeur de formulaires ?"
			},
			{
				title: "Comment définir les options de publication pour un formulaire",
				description: "Anonymisation des réponses, publication différée, date limite de réponse"
			},
			{
				title: "Comment définir les cibles pour un formulaire ?"
			},
			{
				title: "Comment répondre à un formulaire ?"
			},
			{
				title: "Comment afficher les réponses d'un formulaire ?"
			},
			{
				title: "Comment exporter les réponses d'un formulaire ?"
			},
		]
	},
	{
		title: "Statistiques",
		childs: [
			{
				title: "Comment afficher les statistiques depuis le déploiement de l'application ?"
			},
			{
				title: "Comment afficher les statistiques pour une période ?"
			},
			{
				title: "Comment afficher les publications populaires ?"
			},
			{
				title: "Comment afficher le nombre d'activation de compte ?"
			},
			{
				title: "Comment afficher le nombre de connexions ?"
			}
		]
	},
	{
		title: "Profil",
		childs: [
			{
				title: "Comment modifier mes informations personnelles ?"
			},
			{
				title: "Comment gérer la visibilitée mon adresse mail / téléphone ?"
			},
			{
				title: "Comment modifier mon mot de passe ?"
			},
			{
				title: "Comment afficher mes publications ?",
				description: "News, annonces, carrières"
			},
			{
				title: "Comment afficher mes documents personnel ?",
			}
		]
	}
]

export default TutorialsSummary;

import React from "react";
import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import {useQueryClient} from "react-query";
import { t } from "i18next";
import promiseToast from "components/Toasts/PromiseToast";
import TableToolbar from "components/Table/TableToolbar/TableToolbar";

export default function FormsTableToolbar(props) {
	const {selectedRows, clearSelection} = props;
	const {confirmDelete} = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		if (!selectedRows?.length)
			return (false);
		let title = t(
			"Forms.ASKDELETE",
			{
				count: selectedRows?.length,
				title: selectedRows[0].Title
			}
		)
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selectedRows.map((form) => genRequest("Forms/" + form.FormId, null, "delete"));

		let prom = Promise.allSettled(proms).then(() => {
			QCL.resetQueries(["Forms"]);
			clearSelection();
		});

		promiseToast(
			prom,
			{
				pending: t("Forms.DELETING", {count: selectedRows?.length})
			}
		);
		return (prom);
	}

	return (
		<TableToolbar
			{...props}
			handleDelete={props.canDelete !== false ? handleDelete : false}
		/>
	);
}

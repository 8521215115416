import React from "react";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import genRequest from "includes/request";
import { t } from "i18next";

export const getAssignTypes = () => [
	{
		value: "companies",
		label: t("Companies.COMPANIES"),
		itemId: "CompanyId",
		component: CompaniesAssignRow,
		uniqueIfMultiple: true,
		minRole: "EloAdmin",
	},
	{
		value: "sites",
		label: t("Sites.SITES"),
		itemId: "SiteId",
		component: SitesAssignRow,
	},
	{
		value: "groups",
		label: t("Groups.GROUPS"),
		itemId: "GroupId",
		component: GroupsAssignRow,
	},
	{
		value: "wfams",
		label: t("WorkFamillies.WORK_FAMILLIES"),
		itemId: "WFYId",
		maxItems: 10, // max number of selected items
		limitItemsAfterLevel: 2, // limit max items after certain deppLevel
		component: WFamsAssignRow,
	},
];

function CompaniesAssignRow(props) {
	const { type, item } = props;
	let maxLimit =
		type.maxItems &&
		(!type.limitItemsAfterLevel ||
			item.deepLevel > type.limitItemsAfterLevel)
			? type.maxItems
			: false;

	return (
		<QuerySelect
			all={
				maxLimit === false
					? {
							value: "ALL",
							label: t("Companies.ALL_COMPANIES"),
					  }
					: false
			}
			className="assign-drop w-100"
			queryKey={["Companies"]}
			queryFn={() => genRequest("Companies")}
			closeMenuOnSelect={false}
			treat={(a) => {
				return {
					label: a.Name,
					value: a.CompanyId,
					CompanyId: a.CompanyId,
				};
			}}
			max={maxLimit}
			onChange={props?.onChange}
			isMulti={true}
			initialValue={props?.initialValue}
		/>
	);
}

function SitesAssignRow(props) {
	const { CompanyId, type, item } = props;
	let maxLimit =
		type.maxItems &&
		(!type.limitItemsAfterLevel ||
			item.deepLevel > type.limitItemsAfterLevel)
			? type.maxItems
			: false;

	return (
		<QuerySelect
			all={
				maxLimit === false
					? {
							value: "ALL",
							label: t("Commons.ALL_SITES"),
					  }
					: false
			}
			className="assign-drop w-100"
			queryKey={["Sites", "SitesOfCompany", CompanyId]}
			queryFn={() => genRequest("Sites/SitesOfCompany/" + CompanyId)}
			closeMenuOnSelect={false}
			treat={(a) => {
				return {
					label: a.Name + (a.Town ? " - " + a.Town : ""),
					value: a.SiteId,
					CompanyId: a.CompanyId,
				};
			}}
			max={maxLimit}
			onChange={props?.onChange}
			isMulti={true}
			initialValue={props?.initialValue}
		/>
	);
}

function GroupsAssignRow(props) {
	const { CompanyId, type, item } = props;
	let maxLimit =
		type.maxItems &&
		(!type.limitItemsAfterLevel ||
			item.deepLevel > type.limitItemsAfterLevel)
			? type.maxItems
			: false;

	return (
		<QuerySelect
			all={
				maxLimit === false
					? {
							value: "ALL",
							label: t("Groups.ALL_GROUPS"),
					  }
					: false
			}
			className="assign-drop w-100"
			queryKey={["Groups", "OfCompany", CompanyId]}
			queryFn={() => genRequest("Groups/ofCompany/" + CompanyId)}
			closeMenuOnSelect={false}
			treat={(a) => {
				return {
					label: a.Name,
					value: a.GroupId,
					CompanyId: CompanyId,
				};
			}}
			max={maxLimit}
			onChange={props?.onChange}
			isMulti={true}
			initialValue={props?.initialValue}
		/>
	);
}

function WFamsAssignRow(props) {
	const { CompanyId, type, item } = props;

	let maxLimit =
		type.maxItems &&
		(!type.limitItemsAfterLevel ||
			item.deepLevel > type.limitItemsAfterLevel)
			? type.maxItems
			: false;
	return (
		<QuerySelect
			all={
				maxLimit === false
					? {
							value: "ALL",
							label: t("WorkFamillies.ALL_FAMS"),
					  }
					: false
			}
			className="assign-drop w-100"
			queryKey={["WorkFamillies", "WorkFamillyOfCompany", CompanyId]}
			queryFn={() =>
				genRequest("WorkFamillies/WorkFamillyOfCompany/" + CompanyId)
			}
			closeMenuOnSelect={false}
			treat={(a) => {
				return {
					label: a.WorkFamillyName,
					value: a.WorkFamillyId,
					CompanyId: CompanyId,
				};
			}}
			max={maxLimit}
			onChange={props?.onChange}
			isMulti={true}
			initialValue={props?.initialValue}
		/>
	);
}

export function getNextTypes(curType, isSup) {
	let AssignTypes = getAssignTypes();
	let type = curType ? curType : AssignTypes[0].value;

	let index = AssignTypes.findIndex((a) => a.value === type);

	if (index === -1) return false;

	let items = AssignTypes.slice(index, AssignTypes.length);
	let nexts = items
		.map((a) => {
			if (!isSup || isSup(a.minRole) >= 0) return a;
			return false;
		})
		.filter((a) => a);
	return nexts;
}

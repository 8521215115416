import React, { useState } from "react";
import { motion } from "framer-motion";
import { t } from "i18next";
import FormInput from "components/Inputs/FormInput";
import ColorPicker from "components/ColorPicker/ColorPicker";
import "./CategoryEditDrawer.css";
import CategoriesTypesDropDown from "../CategoriesTypesDropDown/CategoriesTypesDropDown";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";
import promiseToast from "components/Toasts/PromiseToast";

export default function CategoryEditDrawer(props) {
	const { editing, cancelEdit, template, onClose } = props;
	const [isSending, setIsSending] = useState(false);
	const [errors, setErrors] = useState([]);
	const [curTypes, setCurTypes] = useState(initTypes());
	const multipleSelect = editing.categoryId() ? false : true;

	function initTypes() {
		let type = editing.categoryType();
		let ret = [
			{
				label: t("Categories.TYPE-" + type),
				value: type,
			},
		];
		return ret;
	}

	function changeType(types) {
		if (!types || (multipleSelect && !types?.length)) {
			editing.categoryType(false);
			editing.setTypes([]);
			setCurTypes([]);
		} else if (multipleSelect) {
			editing.categoryType(types[0].value);
			editing.setTypes(types.slice(1, types.length));
		} else {
			editing.categoryType(types.value);
			editing.setTypes([]);
		}
		setCurTypes(types);
	}

	function checkValues() {
		setErrors([]);
		let ret = [];
		let vals = editing.values();

		if (!vals.Name) ret.push("Veuillez entrer un nom pour la catégorie");
		if (!vals.CategoryType)
			ret.push("Veuillez sélectionner au moin un type pour la catégorie");
		if (ret?.length) {
			setErrors(ret);
			return false;
		}
		if (handleSend) handleSend();
	}

	function handleSend() {
		setIsSending(true);
		let req = editing.send();

		promiseToast(req);
		req.then(() => {
			let dup_types = editing.types();
			if (dup_types?.length > 1) {
				let proms = dup_types.map((a) => editing.duplicate(a.value));
				return Promise.all(proms);
			}
			return Promise.resolve(true);
		}).then(
			() => {
				if (onClose) onClose(false);
				setIsSending(false);
			},
			(e) => {
				setIsSending(false);
				return e;
			}
		);
	}

	const anims = {
		hidden: {
			top: "100vh",
			opacity: 1,
		},
		show: {
			top: "0vh",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.2,
			},
		},
	};

	return (
		<motion.div
			variants={anims}
			initial="hidden"
			animate="show"
			className="p-4 p-md-5 CategoryEditDrawer edit-cont d-flex w-100 mb-2"
		>
			<div className="edit-title mb-4 text-uppercase">
				{t(
					"Categories." +
						(editing && editing.categoryId()
							? "MODIFY_CATEGORY"
							: "ADD_CATEGORY")
				)}
			</div>
			<ErrorsCont className="mb-2" errors={errors} />
			<div className="inner-step-cont">
				<FormInput
					className="mb-2 w-100"
					value={editing.name()}
					onChange={(val) => editing.name(val)}
					title={t("Table.NAME")}
				/>
				<FormInput
					className="mb-2 w-100"
					value={editing.description()}
					onChange={(val) => editing.description(val)}
					title={t("Table.DESC")}
				/>
				<div className="picker-cont mb-2" style={{ width: "100px" }}>
					<div>{t("Table.COLOR")}</div>
					<ColorPicker
						mainColor={template?.primaryColor()}
						className="light-bg"
						onChange={(val) => {
							editing.defaultHexaColor(val);
						}}
						initialValue={editing.defaultHexaColor()}
						enableOpacity={false}
						value={editing.defaultHexaColor()}
					/>
				</div>
				<div>
					<div>{t("Categories.TYPE")}</div>
					<CategoriesTypesDropDown
						className={"light-bg"}
						curType={curTypes}
						setCurType={changeType}
						isMulti={multipleSelect}
						closeMenuOnSelect={false}
					/>
				</div>
			</div>
			<div className="d-flex mt-4 justify-content-between">
				<div className="w-50 me-2">
					<Button
						className={
							"btn btn-red" +
							(isSending ? " disabled loading" : "")
						}
						onClick={cancelEdit}
					>
						{t("Commons.CANCEL")}
					</Button>
				</div>
				<div className="w-50">
					<Button
						className={
							"btn btn-blue" +
							(isSending ? " disabled loading" : "")
						}
						onClick={checkValues}
					>
						{t("Commons.ADD")}
					</Button>
				</div>
			</div>
		</motion.div>
	);
}

import useAccount from "classes/Accounts/hooks/useAccount";
import WorkFunctionObject from "classes/Professions/WorkFunctionObject";
import { ConditionalSelect } from "components/Inputs/CustomAsyncSelect/CustomCreatableSelect";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";

export default function WorkFunctionSelect(props) {
	const {isAdmin} = useAccount();

	function handleAddWFU(new_item) {
		let obj = new WorkFunctionObject({
			WorkFunctionName: new_item.label,
			CompanyId: props.companyId
		});
		let req = obj.send().then((resp) => {
			return ({
				label: resp.WorkFunctionName,
				value: resp.WorkFunctionId,
				WorkFunctionId: resp.WorkFunctionId,
			});
		});
		return (req);
	}

	return (
		<ConditionalSelect
			placeholder={t("WorkFunctions.SELECT_FUNC")}
			className={"bg-color w-100 rounded"}
			classNamePrefix="dropdown"
			queryKey={["WorkFunctions", "WorkFunctionOfCompany", props.companyId]}
			queryFn={() => genRequest("WorkFunctions/WorkFunctionOfCompany/" + props.companyId)}
			treat={(wfu) => ({
				value: wfu.WorkFunctionId,
				WorkFunctionId: wfu.WorkFunctionId,
				label: wfu.WorkFunctionName
			})}
			isSearchable={true}
			onCreateOption={isAdmin() ? handleAddWFU : false}
			{...props}
		/>
	);
}

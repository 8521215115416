import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";

export default class FormOptionModel {

	constructor(obj, parent) {
		let date = formatDate();

		this.Parent = parent;
		this.OptionId = (obj?.OptionId ? obj.OptionId : null);
		this.QuestionId = (obj?.QuestionId ? obj.QuestionId : null);
		this.FormId = (obj?.FormId ? obj.FormId : null);
		this.Value = (obj?.Value ? obj.Value : "");
		this.MinValue = (obj?.MinValue ? obj.MinValue : 0);
		this.MaxValue = (obj?.MaxValue ? obj.MaxValue : 0);
		this.IntervalValue = (obj?.IntervalValue ? obj.IntervalValue : 0);
		this.ImgUrl = (obj?.ImgUrl ? obj.ImgUrl : null);
		this.IsValid = (obj?.IsValid ? obj.ImgUrl : 0);
		this.IsFreeAnswer = (obj?.IsFreeAnswer ? obj.IsFreeAnswer : true);
		this.Position = (obj?.Position ? obj.Position : 0);
		this.LastChanged = (obj?.LastChanged ? obj.LastChanged : date);
		this.LastChanged_AuthorId = (obj?.LastChanged_AuthorId ? obj.LastChanged_AuthorId : null);

		this.TempId = (obj?.TempId ? obj.TempId : generateUUID());
	}

	send() {
		let prom = new Promise((resolve, reject) => {
			this.sendOption()
				.then(() => {
					resolve (true);
				}, reject);
		});
		return (prom);
	}

	sendOption() {
		let that = this;
		let req = genRequest(
			"PossibleOptions" + (this.OptionId ? "/" + this.OptionId : ""),
			this.values(),
			(this.OptionId ? "put" : "post")
		)
			.then((resp) => {
				if (!that.OptionId)
					that.id = resp.OptionId;
			});
		return (req);
	}

	order(new_pos) {
		if (new_pos)
			this.Position = new_pos;
		return (this.Position);
	}

	delete() {
		this.Parent.removeOption(this.id);
	}

	values() {
		let obj = {
			FormId: this.FormId,
			QuestionId: this.QuestionId,
			Position: this.Position,
			Value: this.Value,
			MinValue: this.MinValue,
			MaxValue: this.MaxValue,
			IntervalValue: this.IntervalValue,
			ImgUrl: this.ImgUrl,
			IsValid: this.IsValid,
			IsFreeAnswer: this.IsFreeAnswer,
			LastChanged: this.LastChanged,
			LastChanged_AuthorId: this.LastChanged_AuthorId,
		};
		if (this.OptionId)
			obj.OptionId = this.OptionId;
		return (obj);
	}

	value(new_val) {
		if (typeof new_val !== "undefined")
			this.Value = new_val;
		return (this.Value);
	}

	formId(value) {
		if (value)
			this.FormId = value;
		return (this.FormId);
	}

	questionId(value) {
		if (value)
			this.QuestionId = value;
		return (this.QuestionId);
	}

	get elem() {
		return (this.Elem);
	}

	get parentForm() {
		if (!this.Parent)
			return (false);
		return (this.Parent.parentForm);
	}

	get id() {
		return ((this.OptionId ? this.OptionId : this.TempId));
	}

	set id(value) {
		if (value)
			this.OptionId = value;
	}
}

import React from "react";
import {useQuery} from "react-query";
import "./DocPreview.css";
import genRequest from "includes/request";
import { treat_document } from "includes/documents_treat";
import PageLoad from "components/PageLoad/PageLoad";
import DocPreview from "./DocPreview";

export default function AsyncDocPreview(props) {
	const {isLoading, data, error} = useQuery(
		["Documents", props.documentId],
		() => genRequest("Documents/" + props.documentId).then((resp) => treat_document(resp)),
		{
			enabled: props.documentId ? true : false,
			retry: 0
		}
	);

	if (!props.documentId)
		return (false);
	if (isLoading)
		return (<div className={"pdf-viewer-cont" + (props.isOpen ? " open" : "") + (props.className ? " " + props.className : "")}><PageLoad/></div>);
	if (props.frameOnly)
		return (
			<iframe className={"pdf-viewer" + (props.className ? " " + props.className : "")} title={data.Name} src={data.Url_Origin + "?" + encodeURIComponent(data.LastChanged)}/>
		);
	return (
		<DocPreview
			{...props}
			async={false}
			document={data}
			error={error}
			isLoading={isLoading}
		/>
	);
}

import React, { useState } from "react";
import { t } from "i18next";
import TagLabel from "components/Tags/Label/TagLabel";
import useAccount from "classes/Accounts/hooks/useAccount";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import genRequest from "includes/request";
import "./UsersTable.css";
import UsersTableToolbar from "./ToolBar/UsersTableToolbar";
import WorkFamilySelect from "components/CustomSelects/WorkFamilySelect";
import WorkFunctionSelect from "components/CustomSelects/WorkFunctionSelect";
import SitesSelect from "components/CustomSelects/SitesSelect";
import RoleSelect from "components/CustomSelects/RoleSelect";
import EmployeObject from "classes/Employes/EmployeObject";
import { useQueryClient } from "react-query";
import { usePopper } from "react-popper";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import promiseToast from "components/Toasts/PromiseToast";
import CustomTable, { itemsSort } from "components/Table/CustomTable";

export default function UsersTable(props) {
	const { account, isAdmin, isSup } = useAccount();
	const QCL = useQueryClient();

	const cols = [
		{
			title: t("User.FIRST_NAME"),
			field: "FirstName",
			classes: "post-title-cell",
			customSort: (a, b) => itemsSort(a, b, "FirstName"),
		},
		{
			title: t("User.LAST_NAME"),
			field: "LastName",
			classes: "post-title-cell",
			customSort: (a, b) => itemsSort(a, b, "LastName"),
		},
		{
			title: t("User.WORK_FAM"),
			field: "WorkFamillyId",
			classes: "post-wfam-cell",
			customSort: (a, b) => itemsSort(a, b, "WFY"),
			render: (data) => <ProfessionsCell item={data} type="WFY" />,
			editComponent: (data) => {
				return (
					<WorkFamilySelect
						companyId={data.rowData.CompanyId}
						onSelect={(val) => data.onChange(val)}
						initialValue={
							data.rowData.WorkFamillyId
								? [
									{
										label: "",
										value: data.rowData.WorkFamillyId,
									},
								]
								: []
						}
					/>
				);
			},
		},
		{
			title: t("User.WORK_FUNC"),
			field: "WorkFunctionId",
			classes: "post-wfunc-cell",
			customSort: (a, b) => itemsSort(a, b, "WFU"),
			render: (data) => <ProfessionsCell item={data} type="WFU" />,
			editComponent: (data) => {
				return (
					<WorkFunctionSelect
						companyId={data.rowData.CompanyId}
						onSelect={(val) => {
							data.onChange(val);
						}}
						initialValue={
							data.rowData.WorkFunctionId
								? [
									{
										label: "",
										value: data.rowData.WorkFunctionId,
									},
								]
								: []
						}
					/>
				);
			},
		},
		{
			title: t("User.ROLE"),
			field: "Role",
			classes: "post-role-cell",
			render: (data) => <RoleCell item={data} />,
			editComponent: (data) => {
				return (
					<RoleSelect
						companyId={data.rowData.CompanyId}
						onSelect={(val) => data.onChange(val)}
						initialValue={data.rowData.Role}
					/>
				);
			},
			customSort: (a, b) => itemsSort(a, b, "Role"),
		},
		{
			title: t("User.SITE"),
			field: "SiteId",
			classes: "post-site-cell",
			render: (data) => <SiteCell item={data} />,
			editComponent: (data) => {
				return (
					<SitesSelect
						companyId={data.rowData.CompanyId}
						onSelect={(val) => data.onChange(val)}
						initialValue={
							data.rowData.SiteId
								? [{ label: "", value: data.rowData.SiteId }]
								: []
						}
					/>
				);
			},
			customSort: (a, b) => itemsSort(a, b, "SiteName"),
		},
		{
			title: "Groupes",
			field: "groups",
			classes: "groups-cell",
			render: (data) => {
				return data.groups
					// .sort((a, b) => (a.Name < b.Name ? -1 : 1))
					.map((a, idx) => (
						<TagLabel
							key={idx}
							{...a}
						/>
					))
			},
			editComponent: (data) => {
				return (
					<QuerySelect
						placeholder={t("Groups.SELECT_GROUPS")}
						className={"w-100 rounded"}
						classNamePrefix="dropdown"
						queryKey={[
							"Groups",
							"OfCompany",
							data.rowData.CompanyId,
						]}
						queryFn={() =>
							genRequest(
								"Groups/OfCompany/" + data.rowData.CompanyId
							)
						}
						treat={(grp) => ({
							value: grp.GroupId,
							GroupId: grp.GroupId,
							label: grp.Name,
							Name: grp.Name,
						})}
						all={{
							label: t("Groups.ALL_GROUPS"),
							value: "ALL",
						}}
						isMulti={true}
						isSearchable={true}
						closeMenuOnSelect={false}
						onSelect={(vals) => {
							let ret = [];
							if (vals?.length)
								ret = vals.map((a) => ({
									...a,
									GroupId: a.value,
								}));
							data.onChange(ret);
							return ret;
						}}
						initialValue={data.rowData.Groups?.map((a) => ({
							label: a.Name,
							value: a.GroupId,
						}))}
					/>
				);
			},
			sorting: false,
		},
	];

	function handleClick(e, item) {
		props.handleClick(item);
	}

	let items = props.displayed;

	if (!items || !items.length) items = [];

	const isEditable = (user) =>
		isAdmin() && (isSup("EloAdmin") >= 0 || isSup(user.Role) >= 0);

	const editActions = {
		isEditable: (data) => isEditable(data),
		isEditHidden: (data) => !isEditable(data),
		isDeletable: (data) =>
			isEditable(data) && data.EmployesId !== account.EmployesId,
		isDeleteHidden: (data) =>
			!isEditable(data) || data.EmployesId === account.EmployesId,
		onRowEdit: () => { },
		onRowUpdate: (data, old) => {
			let obj = new EmployeObject({ ...data, OldRole: old.Role });
			obj.role(data.Role);
			obj.groupsToLink(data.Groups);
			let prom = obj
				.send()
				.then(() => {
					return obj.sendGroups();
				})
				.then(() => {
					QCL.resetQueries(["Employes"]);
					QCL.resetQueries([
						"Groups",
						"GroupsOfEmploye",
						data.EmployesId,
					]);
				});
			promiseToast(prom);
			return prom;
		},
		onRowDelete: (data) => {
			let req = genRequest(
				`Employes/EmployeId=${data.EmployesId
				}AndDeleteComment=${true}AndDeleteMessage=${true}AndDeletePost=${false}AndDeleteParticipationAnimation=${true}AndDeleteDocument=${true}AndDeleteExpensesReport${true}`,
				null,
				"delete"
			).then(() => {
				QCL.resetQueries(["Employes"]);
			});
			return req;
		},
	};

	return (
		<div
			className={
				"UsersTable w-100" +
				(props.curUser?.EmployesId ? " has-cur" : "")
			}
		>
			<CustomTable
				filters={props.filters}
				query={props.request}
				getNextItems={props.getNextItems}
				totalUsers={props.totalUsers}
				columns={cols}
				options={{
					selection: isAdmin(),
				}}
				treatUsers={props.treatUsers}
				data={props.items}
				onRowClick={handleClick}
				overloads={{
					Toolbar: (cprops, options) => (
						<UsersTableToolbar {...cprops} {...options} />
					),
				}}
				selectionKey={["SelectedEmployes"]}
				editable={isAdmin() ? editActions : false}
			/>
		</div>
	);
}

// const WFU = React.memo((props) => {
//     const { type, item } = props;
//     <div className="professions-cell">
// 			{type === "WFuncName" ? item.WFU : item.WFY}
// 		</div>
// }

const ProfessionsCell = React.memo((props) => {
	const { type, item } = props;
	return <div className="professions-cell">{item[type]}</div>;
});
ProfessionsCell.displayName = "ProfessionsCell";

const RoleCell = React.memo(({ item }) => {
	let role = item.Role;

	if (!role) return false;
	let text = t("Contacts.USER_ROLE");
	let color = "grey";
	let isDev = false;

	if (["SiteAdmin", "SuperAdmin"].indexOf(role) !== -1) {
		text = t("Contacts.ADMIN_ROLE");
		color = "red";
	} else if (["EloAdmin"].indexOf(role) !== -1) {
		text = t("Contacts.ELOADMIN");
		isDev = true;
	}
	item.RoleName = text;
	return (
		<TagLabel
			className={"user-role" + (isDev ? " rainbow" : "")}
			color={color}
			text={text}
		/>
	);
});
RoleCell.displayName = "RoleCell";

const SiteCell = React.memo(({ item }) => {
	return (
		<InfosFetcher
			queryFn={() => genRequest("Sites/" + item.SiteId)}
			queryKey={["Sites", item.SiteId]}
			treat={(infos) => {
				let town = infos?.Town?.trim();
				let value = infos.Name + (town?.length ? " - " + town : "");
				item.Site = value;
				item.SiteName = value;
				return value;
			}}
		/>
	);
});
SiteCell.displayName = "SiteCell";

const GroupsCell = React.memo(({ item }) => {
	return (
		<InfosFetcher
			className="pt-2 mt-auto overflow-auto"
			queryKey={["Groups", "GroupsOfEmploye", item.EmployesId]}
			queryFn={() =>
				genRequest("Groups/GroupOfEmploye/" + item.EmployesId)
			}
			enabled={item && item.EmployesId ? true : false}
			treat={(groups) => {
				item.Groups = groups;
				return <TargetsPopper items={groups} />;
			}}
		/>
	);
});

function TargetsPopper(props) {
	const { items } = props;
	const [isOpen, setIsOpen] = useState(false);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement);

	return (
		<div
			ref={setReferenceElement}
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<TagLabel text={t("Groups.GROUP", { count: items.length })} />
			{isOpen && (
				<div
					className="targets-popper"
					ref={setPopperElement}
					onMouseLeave={props.closePopper}
					style={styles.popper}
					{...attributes.popper}
				>
					<div className="popper d-flex flex-wrap flex-row gap-1">
						{items
							.sort((a, b) => (a.Name < b.Name ? -1 : 1))
							.map((a) => (
								<TagLabel
									key={a.GroupId}
									text={a.Name}
									color={a.DefaultHexaColor}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);
}

import React, {useState} from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import SwitchInput from "components/Inputs/Switch/SwitchInput";

export default function FunctionSwitch(props) {
	const {getSettings, setSettings} = useAccount();
	const [current, setCurrent] = useState(getSettings(props.name) || false);

	const handleChange = (val) => {
		setCurrent(val);
		setSettings(props.name, val);
		if (props.onChange)
			props.onChange();
	};

	return (
		<SwitchInput
			label={props.label}
			checked={current}
			onChange={handleChange}
			onlyState={true}
			disabled={props.disabled}
		/>
	);
}

import React from "react";
import { useQuery } from "react-query";

import BackButton from "components/Button/BackButton";
import { t } from "i18next";
import "./EditPreview.css";
import LoadCont from "components/LoadCont/LoadCont";

/* eslint-disable */
export default function EditPreview(props) {

	const query = () => {
		if (props.autoSave?.callback)
			return (props.autoSave.callback());
		return (Promise.resolve(true));
	}

	const {isLoading, data} = useQuery(
		["EditPreview", props.lastAutoSave],
		query,
		{
			refetchOnMount: true
		}
	)

	if (isLoading)
		return (
			<div className="EditPreview">
				<LoadCont/>
			</div>
		)

	return (
		<div className="EditPreview gap-2">
			<div>
				<BackButton onClick={() => props.setIsPreviewOpen(false)}>
					{t("Commons.CLOSE")}
				</BackButton>
			</div>
			{<props.component object={props.object}/>}
		</div>
	)
}

/* eslint-enable */

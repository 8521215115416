import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import FormInput from "components/Inputs/FormInput";
import React, {useState} from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./FormRangeOption.css";
import { t } from "i18next";

export default function FormRangeOption({answers, option, onChange}) {
	const {MinValue, MaxValue} = option;
	const [value, setValue] = useState(answers?.length ? parseInt(answers[0].Value) : MinValue);
	const [Error, setError] = useState(checkValue(value));
	
	function checkValue(val) {
		if (val < MinValue || val > MaxValue)
			return(t("Forms.FORM_ANSWER_OUT_OF_RANGE", {min: MinValue, max: MaxValue}))
		return (false);
	}

	function handleChange(val) {
		setError(checkValue(val))
		checkValue(val);
		setValue(val);
		onChange({
			...option,
			Value: val,
			unique: true
		});
	}

	return (
		<div>
			<ErrorsCont errors={Error}/>
			{
				(answers?.length && (answers[0].Value || answers[0].Value === 0)) ?
					<div>
						{t("Forms.YOUR_ANSWER")}:
						<FormInput
							externvalue={"true"}
							type="number"
							min={MinValue}
							max={MaxValue}
							className="font-bold"
							value={value}
							onChange={handleChange}
						/>
					</div>
				: 
					t("Forms.FORM_SELECT_VALUE")
			}
			<div className="w-100 my-4">
				<InputRange
					onChange={handleChange}
					value={value}
					className="w-100"
					type="range"
					minValue={MinValue}
					maxValue={MaxValue}
					step={1}
				/>
			</div>
		</div>
	);
}

export function formRangOptionChecker(answers, options) {
	let option = options[0];
	let check = answers.find((a) => {
		let val = Number(a.Value);
		return (val < option.MinValue || val > option.MaxValue)
	})
	if (check)
	return(t("Forms.FORM_ANSWER_OUT_OF_RANGE", {min: option.MinValue, max: option.MaxValue}))
	return (true);
}

import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
import getQuestionsTypes from "classes/Forms/QuestionsTypes";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import "./FormQuestion.css";
import FormInput from "components/Inputs/FormInput";
import { t } from "i18next";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button/Button";
import ReactModal from "react-modal";
import ImageUploader from "components/ImageUploader/ImageUploader";
import BackButton from "components/Button/BackButton";
import { FILES_URL } from "includes/paths";

export default function FormQuestion(props) {
	const {
		isActive,
		setCurQuestion,
		curQuestion,
		question,
		refreshForm,
		index,
	} = props;
	const [type, setType] = useState(question.Type);
	const [requiered, setRequiered] = useState(question?.AnswerRequired);
	const [upload, setUpload] = useState(false);

	function handleClick(e) {
		e.stopPropagation();
		if (question.id !== curQuestion.id) setCurQuestion(question);
	}

	function handleUpdate() {
		if (refreshForm) refreshForm();
	}

	function handleTypeChange(new_type) {
		question.changeType(new_type);
		setType(new_type);
	}

	function handleClone(e) {
		e.stopPropagation();
		if (question?.clone) {
			let new_quest = question?.clone();
			setCurQuestion(new_quest);
			handleUpdate();
		}
	}

	function handleDelete(e) {
		e.stopPropagation();
		if (question?.delete) question?.delete();
		handleUpdate();
	}

	function handleRequiered(e) {
		e.stopPropagation();
		question.AnswerRequired = e.target.checked;
		setRequiered(e.target.checked);
	}

	function selectImage(img_url) {
		question.ImgUrl = img_url.Url_Origin;
		handleUpdate();
		setUpload(false);
	}

	function handleUploadedImage(img) {
		question.ImgUrl = FILES_URL + img.Url_Origin;
		handleUpdate();
		setUpload(false);
	}

	function uploadImage(item) {
		setUpload(!upload);
	}

	function delete_image() {
		question.ImgUrl = "";
		handleUpdate();
	}

	return (
		<Draggable
			draggableId={question.id.toString()}
			questionId={question.id}
			key={index}
			index={index}
		>
			{(provided) => {
				return (
					<>
						<div
							key={index}
							ref={provided?.innerRef}
							{...provided?.draggableProps}
						>
							<div
								className={
									"FormQuestion mb-2" +
									(requiered ? " requiered" : "") +
									(isActive ? " active" : "")
								}
								data-form-question-id={question.id}
								onClick={handleClick}
							>
								{upload && (
									<ImageUploaderModal
										isOpen={upload}
										close={() => setUpload(false)}
										onSelect={selectImage}
										onUpload={handleUploadedImage}
										docTypeId={19}
									/>
								)}
								<div
									className="question-handle"
									{...provided?.dragHandleProps}
									onClick={(e) => e.stopPropagation()}
								/>
								<div className="d-flex flex-column w-100 mb-3">
									<div className="col-12 d-flex justify-content-between flex-column flex-md-row align-items-end align-items-md-center">
										<div className="d-flex align-items-center col-12 col-md-8 mb-2 mb-md-1 gap-2 justify-content-between justify-content-md-start">
											<div className="dropdown-select-type ">
												<TypesDropdown
													type={type}
													onChange={handleTypeChange}
												/>
											</div>
											{/* UNCOMMENT if the mobile handle it */}
											{/* <div>
												<Button style={{width: "38px", height: "38px"}} onClick={uploadImage}>
													<FontAwesomeIcon icon={faImage}/>
												</Button>
											</div> */}
										</div>
										<div
											className="requiered-switch-cont align-items-center"
											title={t("Forms.REQUIERED_TXT")}
										>
											<SwitchInput
												label={t("Forms.REQUIERED")}
												reverse={true}
												checked={requiered}
												onChange={handleRequiered}
											/>
										</div>
									</div>
									<div className="col-12 mt-3 pr-0">
										{question.ImgUrl && (
											<div className="question-image">
												<Button
													className="btn-red rm-question-image-btn w-auto"
													title="Supprimer l'image"
													onClick={delete_image}
												>
													<FontAwesomeIcon
														icon={faTrashAlt}
													/>
												</Button>
												<img
													alt={question.Title}
													width={"100%"}
													src={question.ImgUrl}
												/>
											</div>
										)}
										<div className="question-title-cont">
											<FormInput
												type="text"
												className="question-title w-100"
												placeholder={t(
													"Forms.QUEST_TITLE"
												)}
												value={question.Title}
												onChange={(val) =>
													(question.Title = val)
												}
											/>
										</div>
										<div className="mt-1">
											<FormInput
												type="text"
												className="question-text w-100"
												placeholder={t(
													"Forms.QUEST_INFOS"
												)}
												value={question.Txt}
												onChange={(val) =>
													(question.Txt = val)
												}
											/>
										</div>
									</div>
								</div>
								<div className="question-options-cont mb-3">
									<QuestionOptions
										question={question}
										options={question.Options}
										type={question.Type}
										handleUpdate={handleUpdate}
									/>
									{isActive && (
										<AddOption
											handleUpdate={handleUpdate}
											question={question}
											type={question.Type}
										/>
									)}
								</div>
								<div className="question-toolbar mt-3 pt-2">
									<div
										className="question-toolbar-btn"
										title={t("Forms.CLONE_QUEST")}
										onClick={handleClone}
									>
										<FontAwesomeIcon icon={faClone} />
									</div>
									<div
										className="question-toolbar-btn"
										title={t("Forms.RM_QUEST")}
										onClick={handleDelete}
									>
										<FontAwesomeIcon icon={faTrashAlt} />
									</div>
								</div>
							</div>
						</div>
						{provided.placeholder}
					</>
				);
			}}
		</Draggable>
	);
}

function ImageUploaderModal(props) {
	const { onSelect, onUpload, close, docTypeId } = props;
	return (
		<div>
			<ReactModal isOpen={props.isOpen}>
				<div>
					<BackButton className="mb-4" onClick={close} />
					<ImageUploader
						onSelect={onSelect}
						onUpload={onUpload}
						docTypeId={docTypeId}
					/>
				</div>
			</ReactModal>
		</div>
	);
}

function AddOption({ type, question, handleUpdate }) {
	if (!type?.Multiple) return false;
	return (
		<div className="AddOption mt-2">
			<type.Component
				isAdder={true}
				index={0}
				unique={type.UniqueChoice}
				handleUpdate={handleUpdate}
				question={question}
			/>
		</div>
	);
}

function QuestionOptions({ question, options, type, handleUpdate }) {
	if (!options.length) return false;
	return (
		<Droppable
			droppableId={"OptionsList-" + question.id}
			direction="vertical"
			type="OptionsList"
		>
			{(provided) => {
				return (
					<div className="question-options mt-2">
						<div ref={provided.innerRef}>
							{options.map((a, key) => {
								if (!type?.Component) return false;
								return (
									<type.Component
										index={key}
										handleUpdate={handleUpdate}
										unique={type.UniqueChoice}
										key={a.id}
										option={a}
										question={question}
										type={type}
									/>
								);
							})}
						</div>
						{provided.placeholder}
					</div>
				);
			}}
		</Droppable>
	);
}

function TypesDropdown({ onChange, type }) {
	let QTypes = getQuestionsTypes();
	let types = QTypes.map((a) => {
		return {
			label: a.Title,
			value: a.QuestionDesignId,
			...a,
		};
	});

	return (
		<Select
			placeholder={t("Forms.QUEST_TYPES")}
			classNamePrefix="dropdown"
			options={types}
			value={{
				label: type.Title,
				value: type.QuestionDesignId,
			}}
			onChange={onChange}
		/>
	);
}

import React from "react";
import FormSection from "classes/Forms/components/FormViewer/components/FormSection/FormSection";
import EditDrawer from "components/EditDrawer/EditDrawer";
import { t } from "i18next";
import "./FormFiller.css";
import FilledFormSendStep from "./components/FilledFormSendStep";
import FilledFormEndStep from "./components/FilledFormEndStep";
import FormFetcher from "classes/Forms/components/FormFetcher/FormFetcher";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import FormAnswerModel from "classes/Forms/FormAnswerModel";
import {getQuestionType} from "classes/Forms/QuestionsTypes";

export default function FormFiller(props) {
	const {form} = props;

	if (!props.isOpen)
		return (false);

	return (
		<div className="FormFiller h-100 overflow-hidden">
			<FormFetcher
				formId={form.FormId}
				{...props}
			>
				{
					(data) => {
						return (<FormFillerBody {...props} form={data}/>);
					}
				}
			</FormFetcher>
		</div>
	);
}

function FormFillerBody(props) {
	const {	account } = useAccount();
	const {form} = props;

	const disp_answers = () => {
		form.Questions.forEach((a) => {
			let q = getQuestion(a.QuestionId);
			let ans = getQuestionAnswer(a.QuestionId);
			if (!ans)
				return (false);
			q.Answers = ans.map((b) => new FormAnswerModel(b))
		})
	}

	const getQuestion = (qId) => {
		for (let x in form.Sections)
		{
			let found = form.Sections[x].Questions?.find((b) => (b.QuestionId === qId ? b : false));
			if (found)
				return (found);
		}
		return (false);
	}

	const getQuestionAnswer = (qId) => {
		if (!form.UserAnswers?.length)
			return (false);
		let found = form.UserAnswers.filter((a) => a.QuestionId === qId);
		return (found || false);
	}

	const fetchAnswers = () => {
		let req = genRequest(`FormAnswer/OfEmployeAndForm/${account.EmployesId}/${form.FormId}`).then((resp) => {
			form.UserAnswers = resp;
			disp_answers();
		})
		return (req);
	}

	let steps = [];

	if (form?.Sections?.length)
		steps = form.Sections.map((a, b) => {
			return ({
				component: FormSection,
				name: "FormSection-" + b,
				title: (form.Sections?.length > 1 ? t("Forms.SECTION_INDEX", {index: b + 1}) : t("Forms.QUESTIONS")),
				props: {
					isAnonymous: form.IsAnonymous,
					section: a
				},
				onNext: () => checkSection(a, form)
			});
		});

	steps.push({
		name: "send",
		disableNav: true,
		sendStep: true,
		title: t("Posts.SEND_STEP"),
		component: FilledFormSendStep
	}, {
		name: "preview",
		title: t("Posts.PREVIEW_STEP"),
		disableLink: true,
		component: FilledFormEndStep
	});

	if (!form)
		return (false);

	return (
		<EditDrawer
			steps={steps}
			onClose={props.onClose}
			object={form}
			isOpen={props.isOpen}
			title={form.Title}
			initQueries={[
				{
					queryKey: ["Form", "FormAnswer", "OfEmployeAndForm", account.EmployesId, form.FormId, Date.now()],
					queryFn: fetchAnswers
				}
			]}
		/>
	);
}

function checkSection(section, form) {
	document.querySelectorAll(".field-error").forEach(a => a.classList.remove("field-error"));
	if (!section?.Questions?.length)
		return (true);

	const checkQuestion = (q) => {
		let type = getQuestionType(q.QuestionDesignId);
		if (!type)
			return ("Type de question non reconnu, veuillez sélectionner un type existant");
		if (!type.fillValidation)
			return (true);
		return (type.fillValidation(q.Answers, q.Options));
	}

	const error = (quest, text) => {
		let elem = document.querySelector("[data-form-question-id='" + quest.QuestionId + "'")
		let drawer = document.querySelector(".edit-drawer");

		elem?.classList.add("field-error");

		if (elem && (drawer.scrollTop < (elem.offsetTop - 30 - elem.offsetHeight) || drawer.scrollTop > elem.offsetTop))
			drawer.scrollTop = elem.offsetTop - (drawer.offsetHeight / 2 - elem.offsetHeight/2);

		return (
			text ||
			t("Forms.REQUIERED_ERR", {title: quest.Title})
		);
	}

	for (let quest of section.Questions)
	{
		if (quest.AnswerRequired)
		{
			if (!quest.Answers?.length)
				return (error(quest));
			else {
				let resp = quest.Answers.map(a => (a.Value || a.Value === 0 || a.Value?.length ? a : false)).filter(a => (a === false));
				if (resp.length)
					return (error(quest));
			}
		} else if (quest.Answers?.length) {
			let check = checkQuestion(quest);
			if (check !== true)
				return (error(quest, check));
		}
	}
	return (true);
}

import React, { useState } from "react";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import "./Tutorials.css";
import Button from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBuilding, faNewspaper, faUser } from "@fortawesome/free-regular-svg-icons";
import { faCogs, faChartBar, faUsers, faHome, faChevronRight, faMoneyCheck, faFilm } from "@fortawesome/free-solid-svg-icons";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import TutorialsSummary from "./Summary";
import SearchBar from "components/SearchBar/SearchBar";
import noAccent from "includes/no_accents";
import { useQueryClient } from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";

const Categories = [
	{
		title: "Sommaire",
		description: "Accéder aux différentes parties des tutoriels",
		icon: faHome
	},
	{
		title: "Configuration",
		description: "Apparence, identitée visuelle et configuration des fonctionalitées",
		icon: faCogs
	},
	{
		title: "News",
		description: "Création, modification, administration",
		icon: faNewspaper
	},
	{
		title: "Alertes",
		description: "Création, modification, administration",
		icon: faBell
	},
	{
		title: "Entreprise",
		description: "Création, modification, administration",
		icon: faBuilding
	},
	{
		title: "People",
		description: "Création, modification, administration",
		icon: faUsers
	},
	{
		title: "Formulaires",
		description: "Création, modification, administration",
		icon: faWpforms
	},
	{
		title: "Profile",
		description: "Création, modification, administration",
		icon: faUser
	},
	{
		title: "Statistiques",
		description: "Création, modification, administration",
		icon: faChartBar
	},
]

export default function TutorialsPage(props) {
	const {account, isSup} = useAccount();
	const [Search, setSearch] = useState(false);
	const [ShowDev, setShowDev] = useState(false)
	const QCL = useQueryClient();

	const loadTour = (tour) => {
		QCL.setQueryData(["Helper", "CurrentTour"], tour)
	}

	const addTour = () => {
		QCL.setQueryData(["TourEditor", "AddTour"], { tour: false });
	}

	const editTour = (tour) => {
		QCL.setQueryData(["TourEditor", "AddTour"], {tour});
	}

	const isElo = isSup("EloAdmin") >= 0;

	return (
		<div>
			<PageHeadBar
				{...props}
				search={false}
				handleAdd={isElo ? addTour : false}
				addTitle="Nouveau tutoriel"
				headButtons={[
					{
						enabled: isElo,
						title: "Tutoriels a intégrer",
						onClick: () => setShowDev(!ShowDev),
						classes: (ShowDev ? "btn-cyan" : "")
					}
				]}
			/>
			{
				ShowDev ?
					<PostsPage
						title={false}
						filters={false}
						companyId={account.CompanyId}
						typeId={22}
						add={false}
						showComments={false}
						baseUrl={props.baseUrl}
						display="table"
						onModify={(post) => {
							try {
								let tour = JSON.parse(post.Txt)
								editTour({...tour, PostId: post.PostId});
							} catch (e) {
								return (false)
							}
						}}
					/>
				:
					<div className="mt-3">
						<div className="mb-3">
							<SearchBar className="w-100" value={Search} onChange={setSearch}/>
						</div>
						<div className="GroupsList gap-3 mb-3">
							{
								Categories.map((a, key) => <GroupTile {...a} key={key}/>)
							}
						</div>
						<HelperMenu
							search={Search}
							items={TutorialsSummary}
							loadTour={loadTour}
							editTour={editTour}
						/>
					</div>
			}
		</div>
	)
}

function HelperMenu(props) {
	const {items, search, loadTour} = props;

	const checkSearch = (item) => {
		if (!search?.length)
			return (true)
		let check = false;
		if (item.childs)
			check = item.childs.findIndex((a) => checkSearch(a)) !== -1
		if (!check)
		{
			let text = noAccent([item.title, item.description].join("\n")).toLowerCase();
			check = text.indexOf(noAccent(search).toLowerCase()) !== -1;
		}		
		return (check);
	}

	return (
		<div className="HelperMenu d-flex flex-column gap-2">
			{
				items.sort((a, b) => a.title < b.title ? -1 : 1).map((a, key) => {
					if (!checkSearch(a))
						return (false)
					if (!a?.childs)
						return (
							<HelperItem
								key={key}
								{...a}
								checkSearch={checkSearch}
								searching={search?.length > 0}
								loadTour={loadTour}
								editTour={props.editTour}
							/>
						);
					return (
						<HelperGroup
							key={key}
							{...a}
							checkSearch={checkSearch}
							searching={search?.length > 0}
							loadTour={loadTour}
							editTour={props.editTour}
						/>
					);
				})
			}
		</div>
	)
}

function HelperGroup(props) {
	const {
		title,
		childs,
		description,
		searching,
		checkSearch,
		tour,
		video,
		loadTour
	} = props;
	const [IsOpen, setIsOpen] = useState(searching || false);

	React.useEffect(() => {
		if (searching && !IsOpen)
			setIsOpen(true)
	}, [setIsOpen, IsOpen, searching]);

	return (
		<div className="HelperGroup" data-open={IsOpen || searching}>
			<div className="helper-group-header d-flex gap-2 w-100" onClick={() => setIsOpen(!IsOpen)}>
				<div className="helper-group-arrow">
					<FontAwesomeIcon icon={faChevronRight}/>
				</div>
				<div className="w-100 d-flex justify-content-between align-items-center">
					<div>
						<strong>{title}</strong>
						<div className="helper-group-description">{description}</div>
					</div>
					<ItemLinks tour={tour} video={video} loadTour={loadTour} editTour={props.editTour}/>
				</div>
			</div>
			{
				IsOpen &&
					<div className="group-childs">
						{
							childs.sort((a, b) => a.title < b.title ? -1 : 1).map((a, key) => {
								return (
									<HelperItem
										key={key}
										{...a}
										checkSearch={checkSearch}
										searching={searching}
										loadTour={loadTour}
										editTour={props.editTour}
									/>
								);
							})
						}
					</div>
			}
		</div>
	)
}

function HelperItem(props) {
	const {title, childs, tour, description, video, checkSearch, searching, loadTour} = props;

	if (searching && !checkSearch(props))
		return (false)
	if (childs)
		return (<HelperGroup {...props}/>);
	return (
		<div className="HelperItem d-flex justify-content-between align-items-center">
			<div>
				<strong>{title}</strong>
				<div className="helper-item-description">{description}</div>
			</div>
			<ItemLinks tour={tour} video={video} loadTour={loadTour} editTour={props.editTour}/>
		</div>
	)
}

function ItemLinks(props) {
	const {tour, video, loadTour} = props;

	if (!tour && !video)
		return (false);
	return (
		<div className="helper-items-links d-flex gap-2 align-items-center">
			{
				tour &&
					<Button
						title="Lancer le tutoriel avec les infos-bulles"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (e.altKey)
								props.editTour(tour)
							else
								loadTour(tour, e)
						}}
					>
						<FontAwesomeIcon icon={faMoneyCheck}/>
					</Button>
			}
			{
				video &&
					<Button title="Lancer le tutoriel vidéo">
						<FontAwesomeIcon icon={faFilm}/>
					</Button>
			}
		</div>
	)
}

function GroupTile(props) {
	return (
		<div className="GroupTile" title={props.description} onClick={props.onClick}>
			<div className="helper-group-icon">
				<FontAwesomeIcon icon={props.icon}/>
			</div>
			<div>{props.title}</div>
		</div>
	);
}

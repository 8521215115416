import React from "react";
import {useQueryClient} from "react-query";
import DefaultEndStep from "components/EditDrawer/EndStep/DefaultEndStep";
import { t } from "i18next";

export default function DocEndStep(props) {
	const QCL = useQueryClient();
	let doc = props.object;

	if (!props.isActive)
		return (false);

	QCL.resetQueries(["Document", doc.documentId()]);

	return (
		<div className="DocEndStep">
			<DefaultEndStep text={t("Docs.SENDED")}/>
		</div>
	);
}

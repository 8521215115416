import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import FormObject from "classes/Forms/FormObject";
import useEditing from "components/EditDrawer/useEditing";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import { useQueryClient } from "react-query";
import FormEdit from "./components/FormEdit/FormEdit";
import FormsTable from "./components/FormsTable/FormsTable";
import "./Forms.css";
import { t } from "i18next";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import FormFiller from "./components/FormFiller/FormFiller";
import useDisplay from "hooks/useDisplay";
import FormsTiles from "./components/FormTiles/FormTiles";
import formatDate from "includes/formatDate";
import FormAnswersPreview from "classes/Forms/components/FormAnswersPreview/FormAnswersPreview";

export default function FormsPage(props) {
	const { setEditing } = useEditing();
	const [search, setSearch] = useState("");
	const [editObject, setEditObject] = useState(false);
	const [CurForm, setCurForm] = useState(false);
	const [FillingForm, setFillingForm] = useState(false);
	const QCL = useQueryClient();
	const { account, isAdmin } = useAccount();
	const { display, setDisplay } = useDisplay("tiles");
	const [ShowDrafts, setShowDrafts] = useState(false);
	const last_display = React.useRef();

	React.useEffect(() => {
		if (props.platform !== last_display.current) {
			if (props.platform === "admin" && props.display !== "table")
				setDisplay("table");
			else if (props.platform !== "admin" && props.display !== "tiles")
				setDisplay("tiles");
		}
		last_display.current = props.platform;
	}, []);

	function handleAdd() {
		let form = new FormObject({
			EmployesId: account.EmployesId,
			PublishedDate: formatDate(),
			IsDraft: false,
			CompanyId: account.CompanyId,
		});

		setEditObject(form);
		setEditing(true);
	}

	function reftech() {
		QCL.resetQueries(["Forms", "FormAffectedToCompany", account.CompanyId]);
	}

	function handleClick(form) {
		if (isAdmin()) setCurForm(form);
		else handleFill(form);
		return form;
	}

	function changeDisplay() {
		if (display === "table") setDisplay("tiles");
		else setDisplay("table");
	}

	function handleEdit(form) {
		let obj = new FormObject({
			...form,
			CompanyId: account.CompanyId,
			HasAnswers: form.Answers?.length,
		});
		obj.isDraft(false);
		obj.fetchForEdit().then(() => {
			setEditObject(obj);
			setEditing(true);
		});
	}

	function closeEdit() {
		setEditObject(false);
		setEditing(false);
		reftech();
	}

	function handleFill(form) {
		setFillingForm(form);
		setEditing(true);
	}

	function closeFilling() {
		setFillingForm(false);
		setEditing(false);
		reftech();
	}

	function dispDrafts() {
		if (editObject) closeEdit();
		if (CurForm) setCurForm(false);
		setShowDrafts(true);
	}

	return (
		<div className="FormsPage">
			<PageHeadBar
				typeId={"formTypeId"}
				siteSelect={false}
				handleAdd={handleAdd}
				addTitle={t("Forms.ADD_FORM")}
				search={setSearch}
				title={t("Pages.FORMS")}
				display={display}
				setDisplay={changeDisplay}
				isMenuOpen={props.isMenuOpen}
				setIsMenuOpen={props.setIsMenuOpen}
				buttons={[
					{
						title: t("Posts.DRAFT_BTN"),
						classes: ShowDrafts ? "btn-primary" : "",
						onClick: () => setShowDrafts(!ShowDrafts),
						enabled: isAdmin(),
					},
				]}
			/>
			{display === "table" ? (
				<FormsTable
					onClick={handleClick}
					search={search}
					showDrafts={ShowDrafts}
				/>
			) : (
				<FormsTiles onClick={handleClick} showDrafts={ShowDrafts} />
			)}
			{!editObject && CurForm && !FillingForm && (
				<PreviewDrawer
					child={FormAnswersPreview}
					isOpen={CurForm?.FormId ? true : false}
					onClose={() => setCurForm(false)}
					childProps={{
						formId: CurForm?.FormId,
						handleEdit,
						handleFill,
					}}
					fullScreen={true}
				/>
			)}
			<FormFiller
				onClose={closeFilling}
				isOpen={FillingForm?.FormId ? true : false}
				form={FillingForm}
				childProps={{ formId: FillingForm?.FormId }}
			/>
			<FormEdit
				onClose={closeEdit}
				title={
					editObject && editObject?.id(null, true)
						? t("Forms.EDIT_FORM")
						: t("Forms.ADD_FORM")
				}
				form={editObject}
				isOpen={editObject}
				dispDrafts={dispDrafts}
			/>
		</div>
	);
}

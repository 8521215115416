import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import CustomAsyncSelect from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import { t } from "i18next";

function GroupOptionsStep(props) {
	const group = props.object;
	const {account} = useAccount();

	return (
		<div className="inner-step-cont d-flex flex-column">
			<div className="mb-2">{ t("Groups.DUP_POSTS") }</div>
			<CustomAsyncSelect
				url={"Groups/OfCompany/" + account.CompanyId}
				isMulti={true}
				timeout={0}
				treat={(a) => {
					return ({
						label: a.Name,
						value: a.GroupId
					});
				}}
				onSelect={(items) => {
					if (items.length)
						group.setDupGroups(items.map((a) => a.value));
					else
						group.setDupGroups([]);
				}}
			/>
		</div>
	);
}

export default GroupOptionsStep;

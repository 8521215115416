import React, {memo} from "react";
import {
	Inject,
	AccumulationLegend,
	AccumulationChartComponent,
	AccumulationSeriesCollectionDirective,
	AccumulationSeriesDirective,
	PieSeries,
	AccumulationTooltip,
	AccumulationDataLabel
} from "@syncfusion/ej2-react-charts";

const AccumulationChart = memo(({chart, specs, values, theme}) => {
	if (!values?.series?.length)
		return (false);
	return (
		<AccumulationChartComponent
			id={"chartId-" + chart.ChartId}
			{...theme}
		>
			<Inject services={[PieSeries, AccumulationLegend, AccumulationDataLabel, AccumulationTooltip]}/>
			<AccumulationSeriesCollectionDirective>
				{
					values.series.map((serie, index) => {
						let parsed_datas = serie?.dataSource.map(a => {
							return ({
								x: Number(a.x),
								y: Number(a.y),
							});
						});
						return (
							<AccumulationSeriesDirective
								key={index}
								dataSource={parsed_datas}
								type={specs.Type}
								{...specs?.Directives}
								{...serie}
							/>
						);
					})
				}
			</AccumulationSeriesCollectionDirective>
		</AccumulationChartComponent>
	);
});
AccumulationChart.displayName = "AccumulationChart";
export default AccumulationChart;

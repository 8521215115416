import React from "react";
import ContextMenu from "components/ContextMenu/ContextMenu";
import { t } from "i18next";

export default function CalendarCtxMenu(props) {
	const {id} = props;
	const basic_items = [
		{
			label: t("Events.ADD_TITLE"),
			onClick: (ev) => props.handleAdd({
				DateStart: new Date(ev?.props?.day).setHours(8, 0, 0, 0)
			})
		}
	];

	const event_items = [
		{separator: true},
		{
			label: t("Events.SHOW"),
			onClick: (ev) => props.handleEventClick(ev?.props.calEvent)
		},
		{
			label: t("Events.MODIFY"),
			onClick: (ev) => props.handleModify(ev?.props.calEvent)
		},
		{
			label: t("Commons.USE_AS_TEMPLATE"),
			onClick: (ev) => props.handleClone(ev?.props.calEvent)
		},
		{
			label: t("Commons.DELETE"),
			onClick: (ev) => props.handleDelete(ev?.props.calEvent)
		}
	];

	return (<ContextMenu id={id} items={(props.forEvent ? [...basic_items, ...event_items] : basic_items)}/>);
}

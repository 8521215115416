import { useState } from "react";
import Storage from "classes/Storage/Storage";

export default function useAccounts() {
	const storage = new Storage();
	const [Accounts, setAccounts] = useState(getAccounts());

	function saveAccounts(accounts) {
		storage.setItem("Accounts", accounts);
		setAccounts(accounts);
	}

	function getAccounts() {
		const accounts_string = storage.getItem("Accounts");
		let accounts = [];
		try {
			accounts = accounts_string;
		} catch (e) {
			accounts = [];
		}
		return (accounts);
	}

	function getAccount(login_or_id) {
		let accounts = getAccounts();
		if (!accounts?.length)
			return (false);
		let check = accounts.find((a) => (a.Login === login_or_id || a.EmployesId === login_or_id));
		return (check ? check : false);
	}

	function saveAccount(account) {
		let accounts = getAccounts();
		if (!accounts)
			accounts = [];
		let find_index = accounts.findIndex((a) => (a.Login === account.Login || a.EmployesId === account.EmployesId));
		if (find_index >= 0)
			accounts[find_index] = account;
		else
			accounts.push(account);
		saveAccounts(accounts);
	}

	function removeAccount(login_or_id) {
		let accounts = getAccounts();
		if (!accounts?.length)
			return (true);
		let new_list = accounts.filter((a) => !(a.Login === login_or_id || a.EmployesId === login_or_id));
		saveAccounts(new_list);
		return (new_list);
	}

	return ({
		Accounts,
		getAccounts,
		getAccount,
		saveAccount,
		removeAccount
	});
}

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useAccount from "classes/Accounts/hooks/useAccount";
import PostsPage from "Pages/Posts/components/PostsPage/PostsPage";
import useTemplate from "hooks/useTemplate";
import { RssProvider } from "providers/RssProvider";

export default function NewsPage(props) {
	const { account, isAdmin } = useAccount();
	let { id } = useParams();
	const { getAppFunction } = useTemplate();

	let can_comment = getAppFunction("NewsPage.Comments") || isAdmin();

	// RssPostsPage

	return (
		<RssProvider>
			<PostsPage
				title={props.title}
				companyId={account.CompanyId}
				typeId={1}
				forceCurId={id}
				add={true}
				showComments={can_comment}
				baseUrl={props.baseUrl}
				isMenuOpen={props.isMenuOpen}
				setIsMenuOpen={props.setIsMenuOpen}
				platform={props.platform}
			/>
		</RssProvider>
	);
}
